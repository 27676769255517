const html = `<p style="text-align: justify;">Przed dokonaniem wyceny projektu IT kluczowe jest doprecyzowanie wymagań klienta. Rozmowa z klientem na etapie zbierania informacji przedofertowych, pomoże w zastosowaniu właściwej strategii wyceny. Istnieją dwie gł&oacute;wne metody wyceny koszt&oacute;w realizacji projekt&oacute;w IT, tj. metoda top-down (wycena rynkowa) oraz metoda botom-up (wycena oparta na kosztach dostarczenia rezultatu).</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Metoda wyceny top-down (wycena rynkowa)</strong></p>
<p style="text-align: justify;">Wycena top-down polega na wyliczeniu ostatecznej ceny w oparciu o wartość rynkową podobnych prac, czyli na przykładzie cen konkurencji, np. dostępnych publicznie w informacjach dotyczących rozstrzygniętych przetargach. Dodatkowym sposobem określania wyceny rynkowej mogą być wcześniejsze zam&oacute;wienia klient&oacute;w. &nbsp;Zalety tej metody to m.in.:</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Możliwość uzyskania wyższej marży&nbsp;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Szybki proces wyceny&nbsp;&nbsp; &nbsp;&nbsp;</p>
<p style="text-align: justify;">Przesłankami zastosowania tej metody są m.in.:&nbsp;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Relatywnie mała konkurencja</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Wysoka pozycja rynkowa i dobra opinia wykonawcy prac (klient jest w stanie zapłacić wyższą cenę, jeśli ma gwarancję wyższej jakości)</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Metoda wyceny bottom-up (wycena oparta na kosztach dostarczenia rezultatu)</strong></p>
<p style="text-align: justify;">Wycena bottom-up polega na wyliczeniu ostatecznej ceny w oparciu o zakres i koszty dostarczenia rozwiązania. Metoda ta dotyczy większości wycen projekt&oacute;w IT. Zalety tej metody to m.in.:</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Możliwość większego zaangażowania zespołu</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Zmniejszenie ryzyka przekroczenia zakładanych koszt&oacute;w realizacji</p>
<p style="text-align: justify;">Za wady tej metody uznać można m.in.:</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Długi czas realizacji wyceny</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Utrata możliwości rynkowych ustalenia wyższej ceny</p>
<p style="text-align: justify;">Przesłankami do zastosowania tej metody są:</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Duża konkurencja na rynku</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Chęć zwiększenia swojej pozycji na rynku poprzez możliwość ustalenia niższej niż konkurencja &nbsp;ceny za usługę</p>
<p style="text-align: justify;">● &nbsp; &nbsp;Potrzeba pewności pokrycia koszt&oacute;w realizacji projektu</p>`;

export default html;
