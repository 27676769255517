import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoriesImages, reportsShortKeys } from "../../../constants/games";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import GameType from "../../../interfaces/game/GameType";
import { AppDispatch, AppState } from "../../../store/store";
import StyledButton from "../../components/StyledButton";
import FinishedIcon from "../../../assets/images/icons/finished_icon.svg";
import FinishedDisabledIcon from "../../../assets/images/icons/finished_disabled_icon.svg";
import CupIcon from "../../../assets/images/icons/cup_icon.svg";
import { getWindowSize } from "../../../utils/utils";
import CategoryType from "../../../interfaces/game/CategoryType";
import { getGames } from "../../../services/game.service";
import DigitalProfileReport from "../../../pdfs/DigitalProfileReport";
import { usePDF } from "@react-pdf/renderer";
import * as htmlToImage from "html-to-image";
import UserType from "../../../interfaces/user/UserType";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  Text,
  LabelList,
  Line,
  ComposedChart,
} from "recharts";
import {
  addOrEditDigitalProfile,
  getUser,
  sendDigitalProfileMail,
} from "../../../services/user.service";

interface DigitalProfileReportButtonProps {
  afterDownload: () => void;
  userName: string;
  games: GameType[];
  scoresPerCategory: {
    [key: string]: ReportScoresType;
  };
  reports: { key: string; sum: number }[];
  int1: string;
  user: UserType;
}

interface ReportScoresType {
  int1: {
    doneTime: number;
    score: number;
    test: number;
  };
  int2_3: number;
  int4_5: number;
}

interface ChartData {
  name: string;
  uv: number;
  pv: number;
}

const reportsKeys: { [key: string]: number } = {
  "1_knowledge_1": 1,
  "1_skill_1": 2,
  "1_skill_2": 3,
  "2_knowledge_1": 4,
  "2_knowledge_2": 5,
  "2_knowledge_3": 6,
  "2_skill_1": 7,
  "2_skill_2": 8,
  "2_skill_3": 9,
  "2_skill_4": 10,
  "3_knowledge_1": 11,
  "3_skill_1": 12,
  "3_skill_2": 13,
  "4_knowledge_1": 14,
  "4_skill_1": 15,
  "4_skill_2": 16,
  "5_knowledge_1": 17,
  "5_knowledge_2": 18,
  "5_skill_1": 19,
  "5_skill_2": 20,
};

const gamesKeys: { [key: string]: number } = {
  "3d_1": 1,
  "3d_2": 2,
  ai_ml_1: 3,
  ai_ml_2: 4,
  bi_1: 5,
  bi_2: 6,
  blockchain_1: 7,
  blockchain_2: 8,
  cloud_1: 9,
  cloud_2: 10,
  e_commerce_1: 11,
  e_commerce_2: 12,
  iot_1: 13,
  iot_2: 14,
  rpa_1: 15,
  rpa_2: 16,
  sm_1: 17,
  sm_2: 18,
  vr_ar_1: 19,
  vr_ar_2: 20,
};

const categoryKeys: { [key: string]: number } = {
  "3d": 1,
  ai_ml: 2,
  bi: 3,
  blockchain: 4,
  cloud: 5,
  e_commerce: 6,
  iot: 7,
  rpa: 8,
  sm: 9,
  vr_ar: 10,
};

const categoriesNames: { [key: string]: string } = {
  "3d": "3D",
  ai_ml: "AI/ML",
  bi: "BI",
  blockchain: "Blockchain",
  cloud: "Cloud",
  e_commerce: "E-commerce",
  iot: "IOT",
  rpa: "RPA",
  sm: "SM",
  vr_ar: "VR",
};

const DigitalProfileReportButton = (props: DigitalProfileReportButtonProps) => {
  const {
    afterDownload,
    games,
    user,
    userName,
    scoresPerCategory,
    reports,
    int1,
  } = props;
  const dispatch: AppDispatch = useDispatch();
  const [chart1, setChart1] = useState<string>("");
  const [chart2, setChart2] = useState<string>("");
  const [updateStarted, setUpdateStarted] = useState<boolean>(false);

  const getInt2 = () => {
    let keyOfTheBest = "";
    let bestScore = 0;
    Object.keys(scoresPerCategory).forEach((key) => {
      if (scoresPerCategory[key].int2_3 > bestScore) {
        bestScore = scoresPerCategory[key].int2_3;
        keyOfTheBest = key;
      }
    });
    return categoriesNames[keyOfTheBest];
  };

  const getInt3 = () => {
    let keyOfTheWorst = "";
    const keys = Object.keys(scoresPerCategory);
    let worstScore = scoresPerCategory[keys[0]]
      ? scoresPerCategory[keys[0]].int4_5
      : 100000000000000;
    keys.forEach((key) => {
      if (scoresPerCategory[key].int2_3 < worstScore) {
        worstScore = scoresPerCategory[key].int2_3;
        keyOfTheWorst = key;
      }
    });
    return categoriesNames[keyOfTheWorst];
  };

  const getInt4 = () => {
    let keyOfTheBest = "";
    let bestScore = 0;
    Object.keys(scoresPerCategory).forEach((key) => {
      if (scoresPerCategory[key].int4_5 > bestScore) {
        bestScore = scoresPerCategory[key].int4_5;
        keyOfTheBest = key;
      }
    });
    return categoriesNames[keyOfTheBest];
  };

  const getInt5 = () => {
    let keyOfTheWorst = "";
    const keys = Object.keys(scoresPerCategory);
    let worstScore = scoresPerCategory[keys[0]]
      ? scoresPerCategory[keys[0]].int4_5
      : 100000000000000;
    keys.forEach((key) => {
      if (scoresPerCategory[key].int4_5 < worstScore) {
        worstScore = scoresPerCategory[key].int4_5;
        keyOfTheWorst = key;
      }
    });
    return categoriesNames[keyOfTheWorst];
  };

  const [instance, updateInstance] = usePDF({
    document: (
      <DigitalProfileReport
        userName={userName}
        games={games}
        int1={int1}
        int2={getInt2()}
        int3={getInt3()}
        int4={getInt4()}
        int5={getInt5()}
        reports={reports}
        chart1={chart1}
        chart2={chart2}
      />
    ),
  });

  const blobToBase64 = function (
    blob: any,
    callback: (base64: string) => void
  ) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      callback(dataUrl as string);
    };
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    const chart1 = document.getElementById("barChart1");
    const chart2 = document.getElementById("barChart2");
    if (chart1) {
      htmlToImage.toPng(chart1).then(function (dataUrl: string) {
        setChart1(dataUrl);
      });
    }
    if (chart2) {
      htmlToImage.toPng(chart2).then(function (dataUrl: string) {
        setChart2(dataUrl);
      });
    }
  }, []);

  useEffect(() => {
    if (chart1 && chart2) {
      updateInstance();
      setUpdateStarted(true);
    }
  }, [chart1, chart2]);

  useEffect(() => {
    if (instance.url && !instance.loading && updateStarted) downloadPDF();
    if (instance.blob && !instance.loading && updateStarted)
      blobToBase64(instance.blob, (base64: string) => {
        const splittedBase64 = base64.split(",");
        dispatch(
          addOrEditDigitalProfile({
            content: splittedBase64[1] ? splittedBase64[1] : base64,
            userId: user.id,
          })
        );
      });
  }, [instance, updateStarted]);

  const downloadPDF = async () => {
    var a = document.createElement("a");
    a.href = instance.url!;
    a.download = "Profil cyfrowy.pdf";
    a.click();
    afterDownload();
  };

  return (
    <StyledButton type="contained" onClick={() => {}} label={"Ładowanie"} />
  );
};

const DigitalProfileScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [numberOfCards, setNumberOfCards] = useState<number>(0);
  const [unfinishedGames, setUnfinishedGames] = useState<number>(0);
  const [unfinishedCategories, setUnfinishedCategories] = useState<number>(0);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
  const [int1, setInt1] = useState<string>("");
  const [capabilityArray, setCapabilityArray] = useState<
    { score: number; key: string }[]
  >([]);
  const [scoresPerCategory, setScoresPerCategory] = useState<{
    [key: string]: ReportScoresType;
  }>({});
  const [scoresPerGame, setScoresPerGame] = useState<
    { key: string | number; score: number }[]
  >([]);
  const [reports, setReports] = useState<{ key: string; sum: number }[]>([]);

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const categories: CategoryType[] = useSelector(
    (state: AppState) => state.gameReducer.categories
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const categoriesNames: { [key: string]: string } = useSelector(
    (state: AppState) => state.gameReducer.categoriesNames
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  useEffect(() => {
    dispatch(getGames(false, true));
  }, []);

  useEffect(() => {
    if (games) {
      const unfinishedCategories = categories.filter((category) =>
        category.games.some(
          (game) => !game.userGame || !game.userGame.isFinished
        )
      );
      const unfinishedGames = games.filter(
        (game) => !game.userGame || !game.userGame.isFinished
      );
      setUnfinishedGames(unfinishedGames.length);
      setUnfinishedCategories(unfinishedCategories.length);
    }
  }, [games, categories]);

  useEffect(() => {
    calculateNumberOfCards(getWindowSize());
    const handleWindowResize = () => {
      calculateNumberOfCards(getWindowSize());
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (games) getReportsScores();
  }, [games]);

  const getReportsScores = () => {
    let scoresPerCategory: { [key: string]: ReportScoresType } = {};
    let scoresPerGame: { key: string; score: number }[] = Array(20);
    let reports: { key: string; sum: number }[] = Array(20);
    let mainKnowledgeTest = 0;
    games!.forEach((game) => {
      if (game.userGame) {
        const userGame = game.userGame;
        if (!scoresPerCategory[game.categoryKey!]) {
          scoresPerCategory = {
            ...scoresPerCategory,
            [game.categoryKey!]: {} as ReportScoresType,
          };
        }
        if (scoresPerCategory[game.categoryKey!].int2_3 === undefined)
          scoresPerCategory[game.categoryKey!].int2_3 = userGame.score;
        else scoresPerCategory[game.categoryKey!].int2_3 += userGame.score;

        if (scoresPerCategory[game.categoryKey!].int1 === undefined)
          scoresPerCategory[game.categoryKey!].int1 = {
            score: userGame.score * 20,
            doneTime: 0,
            test: 0,
          };
        else
          scoresPerCategory[game.categoryKey!].int1 = {
            ...scoresPerCategory[game.categoryKey!].int1,
            score:
              scoresPerCategory[game.categoryKey!].int1.score +
              userGame.score * 20,
          };

        const idx = gamesKeys[game.key] - 1;
        scoresPerGame[idx] = {
          key: game.key,
          score: userGame.score,
        };

        let doneTime = 0;
        let knowledgeTest = 0;
        userGame.subtasks.forEach((subtask) => (doneTime += subtask.doneTime));
        userGame.subtasks.forEach(
          (subtask) => (knowledgeTest += subtask.knowledgeTest)
        );
        scoresPerCategory[game.categoryKey!].int1 = {
          ...scoresPerCategory[game.categoryKey!].int1,
          doneTime:
            scoresPerCategory[game.categoryKey!].int1.doneTime + doneTime,
          test:
            scoresPerCategory[game.categoryKey!].int1.test +
            userGame.correctAnswers +
            userGame.correctQuestions +
            knowledgeTest,
        };
        const int4_5_score =
          doneTime * 0.3 +
          userGame.score * 0.6 +
          (userGame.correctAnswers +
            userGame.correctQuestions +
            knowledgeTest) *
            0.1;
        mainKnowledgeTest +=
          userGame.correctAnswers + userGame.correctQuestions + knowledgeTest;
        if (scoresPerCategory[game.categoryKey!].int4_5 === undefined) {
          scoresPerCategory[game.categoryKey!].int4_5 = int4_5_score;
        } else scoresPerCategory[game.categoryKey!].int4_5 += int4_5_score;

        userGame.subtasks.forEach((subtask) =>
          subtask.reports.forEach((report) => {
            const splitReport = report.reportKey.split("_");
            const length = splitReport.length;
            const shortKey = `${splitReport[length - 4]}_${
              splitReport[length - 2]
            }_${splitReport[length - 1]}`;

            const idx = reportsKeys[shortKey] - 1;
            if (reports[idx])
              reports[idx] = {
                key: shortKey,
                sum: reports[idx].sum + report.reportScore,
              };
            else reports[idx] = { key: shortKey, sum: report.reportScore };
          })
        );
      }
    });

    mainKnowledgeTest = mainKnowledgeTest / 10;

    let int1Array: any[] = Array(10);
    Object.keys(scoresPerCategory).forEach((key) => {
      int1Array[categoryKeys[key]] = { ...scoresPerCategory[key].int1, key };
    });
    int1Array = int1Array
      .sort((a, b) => a.doneTime - b.doneTime)
      .map((item, idx) => ({
        ...item,
        doneTime: (idx + 1) * 10,
      }));
    int1Array = int1Array.sort((a, b) => b.score - a.score);
    let int1 = "";
    int1Array.forEach((item, idx) => {
      if (item.doneTime === 100 && item.test > mainKnowledgeTest)
        int1 = item.key;
    });

    const capabilityArray = int1Array
      .map((item) => ({
        score: item.doneTime + item.test,
        key: item.key,
      }))
      .sort((a, b) => a.score - b.score)
      .map((item, idx) => ({
        ...item,
        score: (idx + 1) * 10,
      }));
    if (!int1) {
      const mappedInt1Array = int1Array
        .map((item) => ({
          score: item.score * 0.75 + item.doneTime * 0.15 + item.test * 0.1,
          key: item.key,
        }))
        .sort((a, b) => b.score - a.score);
      int1 = mappedInt1Array[0] ? mappedInt1Array[0].key : "";
    }
    setInt1(categoriesNames[int1]);

    const sortedReports = reports
      .sort((a, b) => b.sum - a.sum)
      .map((report) => {
        const reportData = reportsShortKeys.find(
          (item) => item.key === report.key
        );
        return {
          key: reportData ? reportData.label : report.key,
          sum: report.sum,
        };
      });

    const sortedGame = scoresPerGame
      .sort((a, b) => b.score - a.score)
      .map((game) => ({
        key: gamesKeys[game.key],
        score: game.score,
      }));
    setScoresPerCategory(scoresPerCategory);
    setScoresPerGame(sortedGame);
    setReports(sortedReports);
    setCapabilityArray(capabilityArray);
  };

  const calculateNumberOfCards = (windowSize: {
    innerWidth: number;
    innerHeight: number;
  }) => {
    const { innerWidth } = windowSize;
    const cardWidth = 220;
    const wrapperPadding = 120 * 2; //TODO 240 to defaultowy padding, jeszcze do zmiany
    const cardsMargin = 24; //TODO
    let initNumber = Math.floor((innerWidth - wrapperPadding) / cardWidth);
    const restOfComponent =
      innerWidth - wrapperPadding - initNumber * cardWidth;
    const spaceNeededForMargins = (initNumber - 1) * cardsMargin;

    if (restOfComponent - spaceNeededForMargins < 0) {
      setNumberOfCards(initNumber - 1);
    } else setNumberOfCards(initNumber);
  };

  const renderGameCard = (game: GameType) => {
    const category = gamesData.find(
      (category) => category.key === game.categoryKey
    )!;
    const gameData = category.games.find((item) => game.key === item.key)!;
    const finished = game.userGame && game.userGame.isFinished ? true : false;

    return (
      <div className={`gameCard ${finished ? "finished" : ""}`} key={game.id}>
        <div className="category">
          {category && categoriesImages[category.key] && (
            <img src={categoriesImages[category.key]} alt={category.key} />
          )}
          {category.categoryName}
          <div className="icons">
            <img
              src={finished ? FinishedIcon : FinishedDisabledIcon}
              alt="isFavourite"
            />
          </div>
        </div>
        <div className="title">{gameData.gameTitle}</div>
        <StyledButton
          label={t("myGamesScreen.goToGame")}
          onClick={() => navigate(`/game/${game.id}`)}
          type="contained"
          color="dark"
        />
      </div>
    );
  };

  const renderCategories = useMemo(() => {
    return categories.map((category) => {
      return (
        <div className="category">
          {categoriesImages[category.key] && (
            <img
              className="categoryIcon"
              src={categoriesImages[category.key]}
              alt={category.key}
            />
          )}
          <span className="categoryName">{categoriesNames[category.key]}</span>
          <div className="icons">
            {category.games.map((game) => (
              <img
                src={
                  game.userGame && game.userGame.isFinished
                    ? FinishedIcon
                    : FinishedDisabledIcon
                }
                key={`gameIcon_${game.id}`}
                className="finishedIcon"
                alt="isFavourite"
              />
            ))}
          </div>
        </div>
      );
    });
  }, [categories, games]);

  const getChart1Data = () => {
    let data: ChartData[] = [];
    Object.keys(scoresPerCategory).forEach((key) => {
      const scores = scoresPerCategory[key];
      const pvItem = capabilityArray.find((item) => item.key === key);
      data.push({
        name: categoriesNames[key],
        uv: Math.fround(scores.int2_3 / 2),
        pv: pvItem ? pvItem.score : 0,
      });
    });
    return data;
  };

  const getChart2Data = () => {
    let data: ChartData[] = [];
    const sumOfScores: number = scoresPerGame.reduce((a, b) => a + b.score, 0);
    let currentSumOfScores = 0;
    scoresPerGame.forEach((game) => {
      currentSumOfScores += game.score;
      data.push({
        name: String(game.key),
        uv: game.score,
        pv: Math.fround((currentSumOfScores / sumOfScores) * 100),
      });
    });
    return data;
  };

  const afterDownload = () => {
    setIsDownloadingPDF(false);
  };

  return (
    <div className="digitalProfileScreen">
      {scoresPerCategory && scoresPerGame && capabilityArray && (
        <>
          <div id="barChart1" style={{ width: "fit-content" }}>
            <BarChart
              width={720}
              height={380}
              data={getChart1Data()}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend verticalAlign="top" iconType="square" height={36} />
              <Bar dataKey="uv" stackId="a" fill="#82ca9d" name="Kompetencje*">
                <LabelList dataKey="uv" position="center" />
              </Bar>
              <Bar dataKey="pv" stackId="a" fill="#8884d8" name="Zdolności*">
                <LabelList dataKey="pv" position="center" />
              </Bar>
            </BarChart>
          </div>
          <div id="barChart2" style={{ width: "fit-content" }}>
            <ComposedChart
              width={720}
              height={380}
              data={getChart2Data()}
              // layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" type="category" />
              <YAxis type="number" />
              <Tooltip />
              <Legend verticalAlign="top" iconType="square" height={36} />
              <Bar dataKey="uv" fill="#82ca9d" name="Kompetencje*" />
              <Line type="monotone" dataKey="pv" stroke="#ff7300" />
              {/* <Bar dataKey="pv" stackId="a" fill="#8884d8" name="Zdolności*">
            <LabelList dataKey="pv" position="center" />
          </Bar> */}
            </ComposedChart>
          </div>
        </>
      )}
      <div className="content">
        <div className="headerSection">
          <span>{"Profil cyfrowy"}</span>
          <StyledButton
            type="contained"
            label={"Wyślij e-mail z profilem cyfrowym"}
            disabled={!user!.digitalProfileId}
            onClick={() => dispatch(sendDigitalProfileMail(user!.id))}
          />
        </div>
        <div className="mainSection">
          <span className="info">{t("digitalProfileScreen.info")}</span>
          <div className="categoriesWrapper">
            <div className="categories">{renderCategories}</div>
            {!isDownloadingPDF ? (
              <StyledButton
                type="contained"
                icon={CupIcon}
                label={"Pobierz profil cyfrowy"}
                disabled={!!(unfinishedGames && unfinishedCategories)}
                onClick={() => setIsDownloadingPDF(true)}
                tooltipText="Po wygenenrowaniu profilu historia twoich gier zostanie usunięta. Jednoczeście, będziesz mógł zacząć tworzyć profil od początku."
              />
            ) : games && user ? (
              <DigitalProfileReportButton
                scoresPerCategory={scoresPerCategory}
                int1={int1}
                userName={`${user.firstName} ${user.lastName}`}
                games={games}
                reports={reports}
                afterDownload={afterDownload}
                user={user!}
              />
            ) : null}
          </div>
          {unfinishedGames && unfinishedCategories ? (
            <div className="lackOfFinishedGamesInfo">
              {`Ukończ jeszcze `}
              <span className="blue">{`${unfinishedGames} zadań `}</span>
              {`w `}
              <span className="blue">{`${unfinishedCategories} różnych kategoriach`}</span>
              {`, by wygenerować indywidualny profil cyfrowy.`}
            </div>
          ) : null}
          {games && (
            <div
              className="games"
              style={{ gridTemplateColumns: `repeat(${numberOfCards}, 1fr)` }}
            >
              {numberOfCards && games.map((game, idx) => renderGameCard(game))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DigitalProfileScreen;
