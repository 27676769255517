const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Stanowisko/usługodawca&nbsp;</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">PM / Kierownik projektu</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Koordynowanie realizacji projektu, a w szczeg&oacute;lności koordynowanie pracy podwykonawc&oacute;w.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Senior Digital Marketing Specialist / Starszy specjalista ds marketingu cyfrowego&nbsp;&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konfiguracja platform i narzędzi, wsp&oacute;łtworzenie strategii marketingowej, wsparcie PM w koordynacji prac podwykonawc&oacute;w&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Digital strategist / Strateg medi&oacute;w cyfrowych</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Stworzenie strategii marketingowej i gł&oacute;wnych założeń komunikacji oraz taktyki działań marketingowych&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Senior HR Specialist / Starszy specjalista ds. HR</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Przeprowadzenie audytu kompetencji zespołu klienta i stworzenie rekomendacji kierunk&oacute;w rozwoju zasob&oacute;w ludzkich</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Agencja copywriterska</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Produkcja treści (na potrzeby content marketingu), tj. wyprodukowanie warstwy tekstowej kreacji [materiały w r&oacute;żnej formie do opublikowania w celach marketingowych] do umieszczenia w mediach społecznościowych]</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Agencja graficzna&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Produkcja grafik (na potrzeby content marketingu), tj. wyprodukowanie warstwy wizualnej kreacji [materiały w r&oacute;żnej formie do opublikowania w celach marketingowych] do umieszczenia w mediach społecznościowych&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Agencja social media&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Przeprowadzenie kampanii płatnych w social mediach (social ads, zasięgowe, wizerunkowe/sprzedażowe, remarketingowe), prowadzenie działań komunikacyjnych w social mediach (publikacje, moderacja), analiza wynik&oacute;w.</span></p>
<br /><br /></td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Lead Designer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Koncepcja kreatywna, elementy graficzne do publikacji w social mediach, storyboardy do video, prototypowanie i projekt graficzny landing page sprzedażowego.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Social Media Manager</span><span style="font-weight: 400;"><br /><br /></span></p>
</td>
<td>
<p><span style="font-weight: 400;">Opracowanie content plan&oacute;w na podstawie strategii, prowadzenie komunikacji, moderacja komentarzy fan&oacute;w, konfiguracja kampanii płatnych, raportowanie.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Frontend Developer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wdrożenie, testowanie i instalacja na serwerze produkcyjnym landing page sprzedażowego.</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1">
<tbody>
<tr style="height: 44px;">
<td style="height: 44px;" colspan="2">
<p><span style="font-weight: 400;">Stanowisko: PM / Kierownik projektu</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 107px;">
<td style="height: 107px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="height: 107px;">
<p><span style="font-weight: 400;">2016-2017 Account Manager</span></p>
<p><span style="font-weight: 400;">2017-2019 Koordynator ds. realizacji kampanii reklamowych&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">2019-2022 Product Owner i PM w branży FMCG</span></p>
<p><span style="font-weight: 400;">2022&nbsp; - obecnie Project Manager w agencji interaktywnej</span></p>
</td>
</tr>
<tr style="height: 83px;">
<td style="height: 83px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="height: 83px;">
<p><span style="font-weight: 400;">MS excel ****</span></p>
<p><span style="font-weight: 400;">Trello ****</span></p>
<p><span style="font-weight: 400;">Asana ***</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">PRINCE 2</span></p>
</td>
</tr>
<tr style="height: 74px;">
<td style="height: 74px;">
<p><strong>Wiedza</strong></p>
</td>
<td style="height: 74px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Znajomość reali&oacute;w realizacji kampanii w mediach społecznościowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Umiejętność zarządzania projektami</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Budżetowanie&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie harmonogram&oacute;w&nbsp;</span></li>
</ul>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Mgr zarządzania&nbsp;</span></p>
</td>
</tr>
<tr style="height: 61px;">
<td style="height: 61px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="height: 61px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Komunikatywność</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dobra organizacja pracy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Skupienie na realizacji cel&oacute;w (goal oriented)&nbsp;</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Senior Digital Marketing Specialist/ Starszy specjalista ds marketingu cyfrowego&nbsp;&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013 -2016&nbsp; Marketing specialist w agencji marketingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2016-2020 Senior digital specialist w agencji marketingowej</span></p>
<p><span style="font-weight: 400;">2020 &ndash; obecnie Własna działalność gospodarcza: konsultant marketingowy</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Platformy biznesowe medi&oacute;w społecznościowych*****</span></p>
<p><span style="font-weight: 400;">Photoshop**</span></p>
<p><span style="font-weight: 400;">Canva *****</span></p>
<p><span style="font-weight: 400;">Hootsuite*****</span></p>
<p><span style="font-weight: 400;">Meta Ads****</span></p>
<p><span style="font-weight: 400;">Google Ads***</span></p>
<p><span style="font-weight: 400;">Google Analytics 4****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Dimaq Professional</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Planowanie i konfiguracja kampanii płatnych w mediach społecznościowych i wyszukiwarkach</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Obsługa medi&oacute;w społecznościowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Copywriting</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Seo-writing</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Podstawowe umiejętności związane z projektowaniem graficznym</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr zarządzanie i marketing</span></p>
<p><span style="font-weight: 400;">Studia podyplomowe: Marketing Internetowy</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Kreatywność&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Digital strategist /Strateg medi&oacute;w cyfrowych&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2015 Senior marketing specialist w agencji marketingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2015-2020 Head of B2B digital strategy w agencji marketingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 &ndash; obecnie Head of marketing w startupie technologicznym</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Narzędzia analityczne Google, Meta *****</span></p>
<p><span style="font-weight: 400;">Platformy biznesowe medi&oacute;w społecznościowych *****&nbsp;</span></p>
<p><span style="font-weight: 400;">Adobe XD ***&nbsp;</span></p>
<p><span style="font-weight: 400;">Meta Ads ****&nbsp;</span></p>
<p><span style="font-weight: 400;">Google Ads ***</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Dimaq Professional</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie strategii marki i opracowywanie taktyki działania w mediach cyfrowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie założeń komunikacji marketingowej.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dob&oacute;r kanał&oacute;w komunikacji.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Planowanie harmonogram&oacute;w i budżetu kampanii reklamowych.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Audytowanie działań i kompetencji marketingowych</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr inż. ochrony środowiska&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Strategiczne myślenie</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Organizacja pracy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Kreatywność</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Senior HR Specialist /Starszy specjalista ds. HR</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2014 HR specialist w firmie z sektora ICT</span></p>
<p><span style="font-weight: 400;">2014-2019&nbsp; Dyrektor HR w agencji marketingowej</span></p>
<p><span style="font-weight: 400;">2019 &ndash; obecnie Audytor HR w agencji head-hunterskiej</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">MS excel ****</span></p>
<p><span style="font-weight: 400;">Programy księgowo-kadrowe ****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Znajomość aktualnych przepis&oacute;w prawnych dotyczących kadr i płac (prawo pracy)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">umiejętność przeprowadzania audyt&oacute;w kompetencyjnych w branżach: ICT, marketingowej, e commerce i motoryzacyjnej</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr zarządzania</span></p>
<p><span style="font-weight: 400;">Studia podyplomowe: zarządzanie zasobami ludzkimi</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Analityczne myślenie</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Wysoka komunikatywność</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Umiejętności menedżerskie</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Łatwość nawiązywania relacji międzyludzkich</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Przedmiot działalności firmy zewnętrznej/podwykonawcy: Copywriting na potrzeby stworzenia kreacji&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Nazwa firmy</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Portfolio i referencje&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Wiodąca marka IT &ndash; tworzenie tekst&oacute;w do komunikacji w social media</span></p>
<p><span style="font-weight: 400;">Startup B2B - tworzenie treści e book&oacute;w, treści www i social media)</span></p>
<p><span style="font-weight: 400;">Firma programistyczna &ndash;pisanie tekst&oacute;w do email marketingu&nbsp;</span></p>
<p><strong>Załącznik:</strong><span style="font-weight: 400;">&nbsp; Referencje od 4 klient&oacute;w z branży prokrewnej do zleconego projektu</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy pracownik&oacute;w&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Kurs webwritingu</span></p>
<p><span style="font-weight: 400;">Kurs seo writing</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Specjalizacja firmy</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie tekst&oacute;w na potrzeby marketingu cyfrowego start-up&oacute;w&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Efektywna Komunikacja w mediach społecznościowych</span></li>
</ul>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie treści scenariusz, e-book&oacute;w i innych tekst&oacute;w wykorzystywanych w strategii content marketingowej</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Przedmiot działalności firmy zewnętrznej/podwykonawcy: Projektowanie wszystkich element&oacute;w graficznych kreacji&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Nazwa firmy</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Portfolio/referencje&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Średniej wielkości firma IT &ndash; tworzenie grafik do komunikacji w social media</span></p>
<p><span style="font-weight: 400;">Startup B2C &ndash; projektowanie graficzne ebook&oacute;w, strony www i materiał&oacute;w na social media</span></p>
<p><span style="font-weight: 400;">Firma programistyczna &ndash;projekt&oacute;w graficznych mailing&oacute;w, kreacji social media, element&oacute;w identyfikacji wizualnej marek, animacji, ebook&oacute;w i www z wykorzystaniem takich narzędzi jak: Adobe XD, Figma, Photoshop, Adobe Illustrator, Adobe Premiere</span></p>
<p><strong>Załącznik:</strong><span style="font-weight: 400;">&nbsp; Referencje od 4 klient&oacute;w z branży prokrewnej do zlecanego projektu</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy pracownik&oacute;w&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Dimaq Pro</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Specjalizacja firmy</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Grafiki na social media&nbsp;&nbsp;</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Przedmiot działalności firmy zewnętrznej/podwykonawcy: Konfiguracja, obsługa i analiza wynik&oacute;w kampanii płatnych w mediach społecznościowych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Nazwa firmy</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Portfolio i referencje&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Średniej wielkości firma produkcyjna&ndash; konfiguracja, obsługa i analiza wynik&oacute;w kampanii płatnych w mediach społecznościowych</span></p>
<p><span style="font-weight: 400;">Firma szkoleniowa &ndash;konfiguracja, obsługa i analiza wynik&oacute;w kampanii płatnych w mediach społecznościowych</span></p>
<p><span style="font-weight: 400;">Zakład stolarski &ndash;analiza wynik&oacute;w kampanii płatnych w mediach społecznościowych</span></p>
<br />
<p><strong>Załącznik:</strong><span style="font-weight: 400;">&nbsp; Referencje od 3 klient&oacute;w z branży pokrewnej do zleconego projektu</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy pracownik&oacute;w&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Meta Blueprint Professional - 401-101</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Specjalizacja firmy&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">prowadzenia i raportowania kampanii płatnych na platformach: Meta, LinkedIn, Twitter, TikTok</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
