const html = `<h3 style="text-align: center;"><strong>Wykaz technologii wykorzystywanych w projektach z obszaru cloud computingu wraz z opisem i przykładami</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Element/moduł rozwiązania/ technologia&nbsp;</strong></p>
</td>
<td>
<p><strong>Opis elementu/technologii&nbsp;</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Pakiet aplikacji chmurowych oferowanych w modelu SaaS</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia tworzenia dokument&oacute;w, budowy arkuszy kalkulacyjnych czy tworzenia prezentacji. Jest to zestaw aplikacji do prac biurowych.&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Microsoft Word Online</span></p>
<p><span style="font-weight: 400;">Google Slides</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Identity and access management (IAM), administracja platformami modern workplace (Google Workplace, Microsoft 365)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie do telekonferencji i dysk wsp&oacute;łdzielony</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia prowadzenie wideokonferencji z wykorzystaniem przeglądarki lub zainstalowanej aplikacji. Dysk wsp&oacute;łdzielony umożliwia przechowywanie plik&oacute;w oraz zarządzanie dostępem do danych na poziomie organizacji</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady narzędzi do telekonferencji:</strong></p>
<p><span style="font-weight: 400;">Google Meet</span></p>
<p><span style="font-weight: 400;">Zoom</span></p>
<p><span style="font-weight: 400;">Microsoft Teams</span></p>
<p><strong>Przykłady dysk&oacute;w wsp&oacute;łdzielonych:</strong></p>
<p><span style="font-weight: 400;">Google Drive</span></p>
<p><span style="font-weight: 400;">OneDrive</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Identity and access management (IAM), administracja platformami modern workplace (Google Workplace, Microsoft 365)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Aplikacja finansowo księgowa oferowana w modelu SaaS</span></p>
</td>
<td>
<p><span style="font-weight: 400;"> Umożliwia usprawnienie standardowych proces&oacute;w kadrowo-księgowych umożliwiając uruchomienie aplikacji bez konieczności jej utrzymywania na swoich serwerach</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Comarch ERP</span></p>
<p><span style="font-weight: 400;">inFakt</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Obsługa aplikacji SaaS na poziomie administratora</span></p>
</td>
</tr>
<tr>
<td><br />
<p><span style="font-weight: 400;">Usługa do zarządzania tożsamością i uprawnieniami w chmurze &ndash; (IAM, Identity and access management)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Usługa chmurowa umożliwia tworzenie kont użytkownik&oacute;w oraz przypisywanie uprawnień do poszczeg&oacute;lnych usług lub komponent&oacute;w w chmurze.</span></p>
<br />
<p><strong>Przykłady:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Google IAM</span></p>
<p><span style="font-weight: 400;">Azure Active Directory</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Cloud security, zarządzanie tożsamością</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Platforma do uruchamiania aplikacji Kubernetes</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozwiązanie do uruchamiania i zarządzania aplikacjami skonteneryzowanymi/ napisanymi w architekturze mikroserwis&oacute;w</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p>Azure Kubernetes Service</p>
<p>Google Kubernetes Engine</p>
</td>
<td>
<p><span style="font-weight: 400;">CI/CD, tworzenie pipeline&rsquo;ow, development aplikacji</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Maszyna wirtualna&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Usługa umożliwiająca uruchomienie dowolnego systemu operacyjnego z udostępnionymi zasobami obliczeniowymi i np. zainstalowanie aplikacji ze specyficznymi wymaganiami</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Amazon EC2</span></p>
<p><span style="font-weight: 400;">Maszyny wirtualne w Azure</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Sieci komputerowe, zasoby obliczeniowe, RBAC</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Usługa do przechowywania dużej ilości nieustrukturyzowanych danych (przestrzeń obiektowa)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia ekonomicznie opłacalne przechowywanie dużej ilości obiekt&oacute;w.&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Amazon S3</span></p>
<p><span style="font-weight: 400;">Azure Blob Storage</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Podstawy cloud computingu</span></p>
</td>
</tr>
<tr>
<td>
<p>Baza danych NOSQL</p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia przechowywanie nieustrukturyzowanych danych. Bazy danych NoSQL są nazywane nierelacyjnymi w celu podkreślenia faktu, że potrafią obsłużyć ogromne ilości szybko zmieniających się, nieustrukturyzowanych danych innymi sposobami niż relacyjna (SQL) baza danych z wierszami i tabelami.</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong></p>
<p>Azure Cosmos DB</p>
<p>MongoDB</p>
</td>
<td>
<p><span style="font-weight: 400;">Relacyjne bazy danych, nierelacyjne bazy danych, zapytania SQL</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Usługa r&oacute;wnoważenia ruchu sieciowego (load balancer)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia rozłożenie ruchu sieciowego przetwarzanego przez aplikacją na większą ilość węzł&oacute;w obliczeniowych zapewniając tym samym wysoką dostępność usługi&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong></p>
<p>GCP Cloud Load Balancing</p>
<p>Azure Front Door</p>
<p>Azure Application Gateway</p>
</td>
<td>
<p><span style="font-weight: 400;">Podstawy sieci komputerowych, podstawy cloud security</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Baza danych SQL</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Usługa umożliwiająca przechowywanie i przetwarzanie danych w formie ustrukturyzowanej. Bazą danych steruje zwykle&nbsp;</span><a href="https://www.oracle.com/pl/database/what-is-database/#WhatIsDBMS"><span style="font-weight: 400;">system zarządzania bazami danych (DBMS)</span></a><span style="font-weight: 400;">. Dane i system DBMS oraz powiązane z nimi aplikacje razem tworzą system bazodanowy, często nazywany w skr&oacute;cie bazą danych.</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p>Amazon RDS</p>
<p>Azure SQL Database</p>
</td>
<td>
<p><span style="font-weight: 400;">Relacyjne bazy danych, nierelacyjne bazy danych, zapytania SQL</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Udziały plikowe</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwiają tworzenie udział&oacute;w plik&oacute;w w chmurze, dostępnych za pośrednictwem protokoł&oacute;w SMB i NFS będących standardem&nbsp;rynkowym</span><span style="font-weight: 400;">.</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong></p>
<p>GCP Filestore</p>
<p>Azure Files</p>
</td>
<td>
<p><span style="font-weight: 400;">Maszyny wirtualne, podstawy sieci</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
