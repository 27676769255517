import courses from "./courses_import";

const coursesMap: {
  [key: string]: { key: string; article: string }[];
} = {
  ai_ml: [
    {
      key: "ai_ml_course_1",
      article: courses.ai_ml_course_1,
    },
    {
      key: "ai_ml_course_2",
      article: courses.ai_ml_course_2,
    },
    {
      key: "ai_ml_course_3",
      article: courses.ai_ml_course_3,
    },
    {
      key: "ai_ml_course_4",
      article: courses.ai_ml_course_4,
    },
  ],
  bi: [
    {
      key: "bi_course_1",
      article: courses.bi_course_1,
    },
    {
      key: "bi_course_2",
      article: courses.bi_course_2,
    },
    {
      key: "bi_course_3",
      article: courses.bi_course_3,
    },
    {
      key: "bi_course_4",
      article: courses.bi_course_4,
    },
  ],
  blockchain: [
    {
      key: "blockchain_course_1",
      article: courses.blockchain_course_1,
    },
    {
      key: "blockchain_course_2",
      article: courses.blockchain_course_2,
    },
    {
      key: "blockchain_course_3",
      article: courses.blockchain_course_3,
    },
    {
      key: "blockchain_course_4",
      article: courses.blockchain_course_4,
    },
  ],
  cloud: [
    {
      key: "cloud_course_1",
      article: courses.cloud_course_1,
    },
    {
      key: "cloud_course_2",
      article: courses.cloud_course_2,
    },
    {
      key: "cloud_course_3",
      article: courses.cloud_course_3,
    },
    {
      key: "cloud_course_4",
      article: courses.cloud_course_4,
    },
  ],
  e_commerce: [
    {
      key: "e_commerce_course_1",
      article: courses.e_commerce_course_1,
    },
    {
      key: "e_commerce_course_2",
      article: courses.e_commerce_course_2,
    },
    {
      key: "e_commerce_course_3",
      article: courses.e_commerce_course_3,
    },
    {
      key: "e_commerce_course_4",
      article: courses.e_commerce_course_4,
    },
  ],
  iot: [
    {
      key: "iot_course_1",
      article: courses.iot_course_1,
    },
    {
      key: "iot_course_2",
      article: courses.iot_course_2,
    },
    {
      key: "iot_course_3",
      article: courses.iot_course_3,
    },
    {
      key: "iot_course_4",
      article: courses.iot_course_4,
    },
  ],
  rpa: [
    {
      key: "rpa_course_1",
      article: courses.rpa_course_1,
    },
    {
      key: "rpa_course_2",
      article: courses.rpa_course_2,
    },
    {
      key: "rpa_course_3",
      article: courses.rpa_course_3,
    },
    {
      key: "rpa_course_4",
      article: courses.rpa_course_4,
    },
  ],
  sm: [
    {
      key: "sm_course_1",
      article: courses.sm_course_1,
    },
    {
      key: "sm_course_2",
      article: courses.sm_course_2,
    },
    {
      key: "sm_course_3",
      article: courses.sm_course_3,
    },
    {
      key: "sm_course_4",
      article: courses.sm_course_4,
    },
  ],
  vr_ar: [
    {
      key: "vr_ar_course_1",
      article: courses.vr_ar_course_1,
    },
    {
      key: "vr_ar_course_2",
      article: courses.vr_ar_course_2,
    },
    {
      key: "vr_ar_course_3",
      article: courses.vr_ar_course_3,
    },
    {
      key: "vr_ar_course_4",
      article: courses.vr_ar_course_4,
    },
  ],
  "3d": [
    {
      key: "3d_course_1",
      article: courses._3d_course_1,
    },
    {
      key: "3d_course_2",
      article: courses._3d_course_1,
    },
    {
      key: "3d_course_3",
      article: courses._3d_course_1,
    },
    {
      key: "3d_course_4",
      article: courses._3d_course_1,
    },
  ],
};

export default coursesMap;
