const html = `<h3><strong>Opis propozycji usług dodatkowych po wdrożeniu modelu AI/ML wraz z uzasadnieniem</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja&nbsp;</strong></p>
</td>
<td>
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie aplikacji do raportowania typu Business Intelligence, dzięki kt&oacute;rej możliwe byłoby robienie analiz sprzedażowych, na potrzeby proces&oacute;w logistycznych i produkcji.&nbsp;</span></p>
<br /><br /></td>
<td>
<p><span style="font-weight: 400;">Znamy już dane firmy i widzimy, że można z nich tworzyć raporty ułatwiające podejmowanie racjonalnych decyzji w obszarze zarządzania</span><strong>.&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Udoskonalenie narzędzia prognostycznego, dzięki zakupowi danych o detalicznej sprzedaży w Polsce od firmy zajmującej się zbieraniem tego typu danych i dodaniu ich do modelu.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Uwzględnienie tych danych w modelu prognostycznym lepiej odzwierciedla działania konkurencji oraz zachowania konsument&oacute;w, dzięki czemu trafność prognoz wzrośnie.&nbsp;</span></p>
<br /><br /></td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie nowego systemu CRM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Nowy system CRM m&oacute;głby gromadzić więcej danych, kt&oacute;re następnie wykorzystane by były do trenowania i tworzenia coraz bardziej doskonałych modeli prognostycznych.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
