const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="3" style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkania projektowe przeprowadzane w czasie realizacji projektu z obszaru marketingu cyfrowego</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Rodzaj spotkania</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Osoby uczestniczące</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Częstotliwość</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkania audytowe&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Osoby odpowiedzialne za dane obszary&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>W zależności od potrzeb&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkanie celem prezentacji raportu 1 (14)</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Specjalista ds marketing +zesp&oacute;ł klienta&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>14tydz</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkanie celem prezentacji raport 2&nbsp;(24)</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Specjalista ds marketing +zesp&oacute;ł klienta</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>24tydz</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkanie statusowe&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Cały zesp&oacute;ł</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1x tydz.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
