const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada technologia Big Data</strong></p>
</td>
</tr>
<tr>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp z jednego miejsca do danych z wybranych system&oacute;w firmowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp do narzędzi typu open source umożliwiających szybki dostęp do danych przetworzonych zgodnie z aktualnymi wymaganiami i potrzebami analityka/Data Scientista</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp do narzędzia umożliwiającego automatyczne odszyfrowywanie danych wrażliwych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">oparcie rozwiązania, w tym data lake o usługi chmurowe oferowane przez obecnego dostawcę chmury</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">wykorzystanie repozytorium danych typu data lake, umożliwiające przechowywanie obiekt&oacute;w</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">integracja danych z kilku r&oacute;żnych źr&oacute;deł&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">pobranie danych z CRM z wykorzystaniem API</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">swobodny i szybki a jednocześnie bezpieczny&nbsp; dostęp analityk&oacute;w do wszystkich danych firmowych</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
