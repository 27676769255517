const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<h3><strong>Budowa modelu analitycznego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Struktura modelu analitycznego (tabeli wielowymiarowej, kostka OLAP)</strong></p>
</td>
<td>
<p><strong>Przykłady</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Miary </strong><span style="font-weight: 400;">(wartości policzalne odnoszące się do&nbsp; wymiar&oacute;w)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Data</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dokument finansowy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dokument magazynowy</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wymiary </strong><span style="font-weight: 400;">(informacje, dane podlegające analizie)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Sprzedaż</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Rozmowy telefoniczne&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Atrybuty miary&nbsp; </strong><span style="font-weight: 400;">(konkretne cechy/wartości miary)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Data. Kwartał w roku finansowym</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
