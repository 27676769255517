const html = `<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację usługi polegającej na wytworzeniu oprogramowania, wykorzystującego technologię blockchain</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki zastosowaniu blockchain :</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">niezadowolenie użytkownik&oacute;w końcowych urządzeń produkowanych przez klienta w związku z pobraniem przez urządzenie nieautoryzowanej aktualizacji, w wyniku czego urządzenie przestawało działać&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">przechwytywanie przez haker&oacute;w danych z urządzeń&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">przechwytywanie przez haker&oacute;w sterowania urządzeniami&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">niski poziom dywersyfikacji (zr&oacute;żnicowanie) źr&oacute;deł dochod&oacute;w firmy&nbsp;&nbsp;</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Usługa polegać będzie na wytworzeniu systemu informatycznego umożliwiającego aktualizację oprogramowania urządzeń IoT, wykorzystującego technologię blockchain oraz zdecentralizowane bazy danych jako repozytorium (miejsce przechowywania plik&oacute;w), z kt&oacute;rym łączą się urządzenia i pobierają najnowsze wersje oprogramowania. W efekcie podniesiony zostanie&nbsp; poziomu bezpieczeństwa oraz wydajności procesu aktualizacji przy jednoczesnym zredukowaniu ryzyka atak&oacute;w hakerskich lub przejęcia kontroli nad urządzeniami przez nieuprawnionych aktor&oacute;w.</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wdrożenie funkcji walidowania (sprawdzania) zawartości plik&oacute;w przez urządzenia, przed instalacją</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">umożliwienie urządzeniom IoT dostępu poprzez backend aplikacji, stanowiącej gateway (tj. aplikację kt&oacute;ra komunikuje się z siecią blockchain) do sieci blockchain. Pozwoli to uniknąć łączenia każdego urządzenia bezpośrednio z siecią. Wszystkie urządzenia mające dostęp do aplikacji uzyskają poprzez gateway dostęp do bazy danych blockchian</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zintegrowanie bazy danych blockchain z aplikacją napisaną w języku C</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie</span></strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">:</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające wspierające wdrażanie blockchain.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia</span></strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">:</span></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">skuteczna ochrona przed atakami hakerskimi na urządzenia i infrastrukturę klienta</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">uruchomienie nowego kanału przychod&oacute;w&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zwiększenie efektywności procesu aktualizowania oprogramowania urządzeń </span></li>
</ul>`;

export default html;
