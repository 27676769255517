const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<h3><strong>Ustalenia będące efektem szczeg&oacute;łowej analizy biznesowej przeprowadzonej w ramach realizacji projektu z obszaru Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Dokumentacja wymagań biznesowych</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Czas przywr&oacute;cenia danych w przypadku awarii lub cyberataku ma być bardzo szybki (max 24h).</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;"> Zwiększenie liczby użytkownik&oacute;w i danych gromadzonych na platformie ma nie wpływać na jej wydajność i stabilność.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Ilość rekord&oacute;w w CRM wynosi kilkadziesiąt tysięcy.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
