const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<h3><strong>Definicje termin&oacute;w używanych w kontekście analizy i raportowania danych&nbsp;</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza o prezentacji danych&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość sposob&oacute;w prezentowania danych w postaci graficznej (r&oacute;żne rodzaje graf&oacute;w i wykres&oacute;w) oraz umiejętność dopasowania rodzaju wykresu do rodzaju danych, w ten spos&oacute;b by jak najbardziej precyzyjnie zaprezentować potrzebną do wyciągnięcia wniosk&oacute;w wiedzę.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Warstwa pośrednia (stage, STG)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Warstwa w bazie danych (hurtowni danych) tworzona w celu uniknięcia odpytywania za często źr&oacute;dła danych. Pozwala na zarządzanie danymi z r&oacute;żnych źr&oacute;deł, kt&oacute;re dostępne są w dokładnie takiej samej formie jak w źr&oacute;dle.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Model hurtowni danych</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Rodzaj systemu zarządzania danymi, model bazy danych przygotowany pod raportowanie, do kt&oacute;rego podłącza się silnik analityczny (bazy danych), dostarczający dane do aplikacji raportujących.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Baza danych&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Zbi&oacute;r danych zapisanych zgodnie z regułami (silnik pod hurtownię).</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
