const html = `<h3 style="text-align: center;"><strong>Opis propozycji usługi dodatkowej wraz z uzasadnieniem</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja&nbsp;</strong></p>
</td>
<td>
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie szkoleń w kolejnych obszarach&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">W związku z tym, że szkolenie spełnia swoją funkcję, warto rozszerzyć zastosowanie aplikacji na kolejne obszary szkoleń. Ze względu na zdobyte już doświadczenie we wsp&oacute;łpracy, kolejny projekt zostanie zrealizowany w kr&oacute;tszym czasie niż pierwszy.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zakup dodatkowego sprzętu celem umożliwienie szkolenia więcej niż jednej osobie r&oacute;wnocześnie&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Ze względu na charakter licencji, kt&oacute;ra może być wykorzystywana na jednym komputerze i goglach, warto zastanowić się nad inwestycją w dodatkową infrastrukturę. Pozwoli to na zwiększenie liczby szkolonych os&oacute;b.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wprowadzenie narzędzia do obsługi większej liczby szkoleń (aplikacja do zarządzania szkoleniami VR i analityki)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Aplikacja pozwoli Państwu na zbudowanie platformy do zarządzania szkoleniami, zwiększenie efektywności szkoleń i zmniejszenie czasu niezbędnego na zarządzanie procesami szkoleniowymi.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie wirtualnych pokaz&oacute;w 3D kolejnych produkt&oacute;w.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">W związku z tym, że aplikacja spełnia swoją funkcję, warto rozszerzyć zastosowanie aplikacji na kolejne maszyny. Ze względu na zdobyte już doświadczenie we wsp&oacute;łpracy, kolejny projekt zostanie zrealizowany w kr&oacute;tszym czasie niż pierwszy.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zakup dodatkowego sprzętu celem umożliwienie użycia aplikacji większej ilości os&oacute;b jednocześnie.&nbsp;&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Ze względu na charakter licencji, kt&oacute;ra może być wykorzystywana na jednym komputerze i goglach, warto zastanowić się nad inwestycją w dodatkową infrastrukturę. Pozwoli to na zwiększenie liczby oglądających produkty os&oacute;b.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie szkolenia VR z obsługi linii produkcyjnej pracownikom produkcyjnym klienta</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Szkolenie VR pracownik&oacute;w linii produkcyjnej pozwoli Państwu na zmniejszenie koszt&oacute;w szkoleń i zwiększenie mocy produkcyjnych dzięki większej ilości dobrze przeszkolonych pracownik&oacute;w.&nbsp;&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
