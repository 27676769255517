const html = `<h3 style="text-align: center;"><strong>Wykaz stanowisk w&nbsp; projekcie z obszaru cloud computingu wraz z zakresami obowiązk&oacute;w</strong><strong> i CV</strong><br /><br /></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Stanowisko&nbsp;</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Inż rozwiązań workplace&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Konfiguracja i wdrożenie narzędzi zgodnie z wymaganiami i potrzebami klienta, migracje danych.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Pre-sell Architekt modern workplace&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Zbieranie wymagań,&nbsp; odpowiedzialność za wyjaśnienie funkcjonalności i oceny wpływu decyzji klienta na koszt rozwiązania, doradztwo w zakresie wyboru rozwiązania, prowadzenie szkoleń dla klienta.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Inżynier rozwiązań chmurowych&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Konfiguracja i wdrożenie usług chmurowych zgodnie z dokumentacją i opracowaną architekturą.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Architekt rozwiązań chmurowych</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Zbieranie wymagań dotyczących rozwiązania, projektowanie docelowej architektury rozwiązania, koordynacja fazy implementacji projektu.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Developer</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Zaprojektowanie oraz przypisanie aplikacji do modelu cloud-native, wsparcie w uruchomieniu aplikacji w chmurze.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr style="height: 48.7188px;">
<td style="height: 48.7188px;" colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Inż rozwiązań workplace&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 59px;">
<td style="height: 59px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="height: 59px;">
<p><span style="font-weight: 400;">2015 - 2017&nbsp; - Administrator system&oacute;w operacyjnych&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">2017-obecnie &ndash; Inż. Rozwiązań workplace /Inż systemowy/ Inż ds. wdrożeń&nbsp;</span></p>
</td>
</tr>
<tr style="height: 107px;">
<td style="height: 107px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="height: 107px;">
<p><span style="font-weight: 400;">MS 365 ****</span></p>
<p><span style="font-weight: 400;">Google Workspace ***</span></p>
<p><span style="font-weight: 400;">Windows OS ***</span></p>
<p><span style="font-weight: 400;">Azure AD ***</span></p>
</td>
</tr>
<tr style="height: 61px;">
<td style="height: 61px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="height: 61px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">AZ 900 (rozumienie technologii chmurowych, 3 tyg)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">MS 900</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Google Workspace Certification</span></li>
</ul>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Inż. telekomunikacji</span></p>
</td>
</tr>
<tr style="height: 83px;">
<td style="height: 83px;">
<p><strong>Wiedza/ umiejętności&nbsp;</strong></p>
</td>
<td style="height: 83px;">
<p><span style="font-weight: 400;">Znajomość rozwiązań modern workplace&nbsp;</span></p>
<p><span style="font-weight: 400;">Zarządzanie tożsamością w środowisku chmurowym&nbsp;</span></p>
<p><span style="font-weight: 400;">Znajomość podstaw infrastruktury i bezpieczeństwa IT&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Szybkie nabywanie nowej wiedzy i umiejętności, komunikatywność</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Pre-sell Architekt modern workplace</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2020-obecnia - Pre sell Architect modern workplace</span></p>
<p><span style="font-weight: 400;">2016-2020 - Architekt rozwiązań MS&nbsp;</span></p>
<p><span style="font-weight: 400;">2014-2016 - Inż. Sieciowy&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Google Cloud***</span></p>
<p><span style="font-weight: 400;">MS Azure **</span></p>
<p><span style="font-weight: 400;">MS 365 ***</span></p>
<p><span style="font-weight: 400;">Google Workspace***</span></p>
<p><span style="font-weight: 400;">Security *</span></p>
<p><span style="font-weight: 400;">Sieci komputerowe**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">AZ 900</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Cloud Digital Leader&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr inż. informatyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność identyfikacji potrzeb klienta&nbsp;</span></p>
<p><span style="font-weight: 400;">Umiejętność dopasowania konkretnego rozwiązania do potrzeb klienta&nbsp;</span></p>
<p><span style="font-weight: 400;">Umiejętność zbudowania wysokopoziomowej architektury rozwiązania&nbsp;</span></p>
<p><span style="font-weight: 400;">Umiejętność przedstawienia proponowanego rozwiązania wraz ze wskazaniem korzyści biznesowych&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">komunikatywność, umiejętność przekazywania wiedzy technicznej osobom nietechnicznym&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Inżynier chmurowy</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2015 - 2020&nbsp; - Administrator system&oacute;w operacyjnych&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 - obecnie &ndash; Inżynier chmurowy</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Microsoft Azure ***</span></p>
<p><span style="font-weight: 400;">Windows OS ***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">AZ 900 (rozumienie technologii chmurowych, 3 tyg)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">AZ800 &ndash; Administering Windows Server Hybrid Core Infrastructure</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr Inż. Informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/ umiejętności&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość chmury Azure na poziomie administratora</span></p>
<p><span style="font-weight: 400;">Znajomość administrowania systemami serwerowymi Windows</span></p>
<p><span style="font-weight: 400;">Znajomość podstaw infrastruktury IT</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność szybkiego uczenia się, komunikatywność</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Architekt Rozwiązań chmurowych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2018-obecnie - Architekt Rozwiązań Chmurowych</span></p>
<p><span style="font-weight: 400;">2015-2018 - Architekt IT</span></p>
<p><span style="font-weight: 400;">2008-2015 &ndash; Inżynier ds. infrastruktury</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Google Cloud*****</span></p>
<p><span style="font-weight: 400;">MS Azure *****</span></p>
<p><span style="font-weight: 400;">Linux/Windows***</span></p>
<p><span style="font-weight: 400;">Security ***</span></p>
<p><span style="font-weight: 400;">Sieci komputerowe****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">AZ 305 - Designing Microsoft Azure Infrastructure Solutions</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">GCP Professional Cloud Architect</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr inż. informatyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność analizy wymagań&nbsp;</span></p>
<p><span style="font-weight: 400;">Umiejętność dopasowania konkretnego rozwiązania do potrzeb klienta</span></p>
<p><span style="font-weight: 400;">Umiejętność zbudowania wysokopoziomowej oraz niskopoziomowej architektury rozwiązania&nbsp;</span></p>
<p><span style="font-weight: 400;">Koordynacja prac wdrożeniowych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">komunikatywność, umiejętność planowania zadań,&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr style="height: 45.4375px;">
<td style="height: 45.4375px;" colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Deweloper</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 83px;">
<td style="height: 83px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="height: 83px;">
<p><span style="font-weight: 400;">2022-obecnie &ndash; Senior Developer</span></p>
<p><span style="font-weight: 400;">2019-2022 &ndash; Senior Developer</span></p>
<p><span style="font-weight: 400;">2017-2019&ndash; Młodszy Programista&nbsp;</span></p>
</td>
</tr>
<tr style="height: 107px;">
<td style="height: 107px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="height: 107px;">
<p><span style="font-weight: 400;">Java*****</span></p>
<p><span style="font-weight: 400;">MS Azure **</span></p>
<p><span style="font-weight: 400;">Linux OS***</span></p>
<p><span style="font-weight: 400;">Konteneryzacja ****</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Wykształcenie</strong></p>`;

export default html;
