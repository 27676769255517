const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="5">
<h3><strong>Harmonogram realizacji projektu z obszaru Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Nazwa zadania projektowego (kamienie milowe projektu)&nbsp;&nbsp;</strong></p>
</td>
<td>
<p><strong>Nr tygodnia, w kt&oacute;rym następuje rozpoczęcie zadania&nbsp;</strong></p>
</td>
<td>
<p><strong>Ilość tygodni trwania zadania&nbsp;</strong></p>
</td>
<td>
<p><strong>Skład osobowy zespołu realizującego zadanie&nbsp;&nbsp;</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analiza biznesowo-systemowa</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt BI/BD</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wypracowanie finalnej architektury platformy Big Data. Dokument zawierający opis wymagań oraz usług, aplikacji i technologii wykorzystanych do budowy rozwiązania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Utworzenie warstwy ROW (warstwa danych w data lake) i pobranie do niej danych ze źr&oacute;deł</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">12</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista ETL, Programista Spark/Python</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Codzienny, zgodny z wytycznymi,&nbsp; proces pobierania danych do warstwy ROW.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Utworzenie warstwy CLEANSED (warstwa analityczna/przejściowa) i pobranie do niej danych w warstwy ROW</span></p>
</td>
<td>
<p><span style="font-weight: 400;">7</span></p>
</td>
<td>
<p><span style="font-weight: 400;">8</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista ETL, Programista Spark/Python</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Codzienny, zgodny z wytycznymi,&nbsp; proces przeliczania danych przez warstwę CLEANSED.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Utworzenie warstwy CURATED (warstwa hurtowni danych/DWH) i pobranie do niej danych z warstwy CLEANSED</span></p>
</td>
<td>
<p><span style="font-weight: 400;">18</span></p>
</td>
<td>
<p><span style="font-weight: 400;">18</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista ETL, Programista Spark/Python</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Codzienny, zgodny z wytycznymi,&nbsp; proces przeliczania danych przez warstwę DWH.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Budowa narzędzia do automatycznego przetwarzania danych zgodnie z potrzebami i wymaganiami analityk&oacute;w/Data Scientist&oacute;w</span></p>
</td>
<td>
<p><span style="font-weight: 400;">37</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista Spark/Python</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Aplikacja umożliwia właściwą realizację oczekiwanego scenariusza użycia.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Budowa narzędzia do automatycznego odszyfrowywania danych wrażliwych </span></p>
</td>
<td>
<p><span style="font-weight: 400;">41</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista Spark/Python</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Aplikacja umożliwia właściwą realizację oczekiwanego scenariusza użycia.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przekazanie produktu klientowi do testowania, naprawa błęd&oacute;w, wdrożenie produkcyjne. Szkolenie.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">45</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista Spark/Python, Architekt BI/BD</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport z test&oacute;w i protok&oacute;ł odbioru prac. Lista obecności na szkoleniu.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
