const html = `<p style="text-align: justify;"><strong>Gł&oacute;wne obowiązki:</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">tworzenie raport&oacute;w i wizualizacji danych w r&oacute;żnych formatach, takich jak tabelki, wykresy, grafy, mapy, dashboardy itp. z wykorzystaniem aplikacji BI</p>
    </li>
    <li>
        <p style="text-align: justify;">nadanie dostęp&oacute;w do raport&oacute;w</p>
    </li>
    <li>
        <p style="text-align: justify;">budowa modelu analitycznego (we wsp&oacute;łpracy z ETL developerem i Architektem)</p>
    </li>
    <li>
        <p style="text-align: justify;">testowanie i utrzymywanie aplikacje BI, aby zapewnić, że działają one poprawnie i spełniają wymagania użytkownik&oacute;w&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">optymalizacja wydajności aplikacji BI, tak aby użytkownicy mogli szybko i skutecznie analizować dane</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Wymagane technologie:</strong></p>
<p style="text-align: justify;">Power BI, Tabelu, QlikView, SQL, Azure Analysis Services, bazy danych (Oracle, MySQL, SQL Server, PostgreSQL), obsługa gł&oacute;wnych narzędzi ETL</p>
<p style="text-align: justify;"><strong>Pozostałe wymagania: </strong></p>
<ul>
    <li>
        <p style="text-align: justify;">wiedza na temat modeli hurtowni danych&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">poczucie estetyki</p>
    </li>
    <li>
        <p style="text-align: justify;">umiejętność prezentacji danych (dopasowanie grafu do rodzaju danych</p>
    </li>
    <li>
        <p style="text-align: justify;">podstawy statystyki i umiejętność przeprowadzania analizy danych, </p>
    </li>
</ul>`;

export default html;
