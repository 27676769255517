const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)&nbsp;</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Osoby odpowiedzialne/realizujące(zesp&oacute;ł)</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analiza biznesowa szczeg&oacute;łowych potrzeb kienta&nbsp;&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Analityk biznesowy&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokument zawierający wymagania biznesowe klienta (wykaz niezbędnych moduł&oacute;w sklepu), proponowaną do wdrożenia i dostosowania gotową </span><span style="font-weight: 400;">platformę e-commerce</span><span style="font-weight: 400;">, wykaz potrzebnych narzędzi wspierających działanie platformy.</span></p>
<p><span style="font-weight: 400;">Wstępne szacowanie koszt&oacute;w realizacji projektu.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Integracja wybranej platformy e-commerce z systemem ERP i WMS klienta&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">12</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Senior backend deweloper&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Podstawowa wersja sklepu pobierająca i wysyłająca dane do/z system&oacute;w klienta.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie projekt&oacute;w gł&oacute;wnych ekran&oacute;w sklepu internetowego (makiety klikalne)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">17</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">UX designer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Makiety klikalne sklepu</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Prace w zakresie utworzenia i dostosowania platformy e-commerce do indywidualnych potrzeb klienta (wdrożenie niezbędnych moduł&oacute;w)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">21</span></p>
</td>
<td>
<p><span style="font-weight: 400;">12</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Mid backend deweloper, Junior backend deweloper&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Działające wybrane przez klienta moduły sklepu internetowego&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie&nbsp; designu sklepu (grafiki dostosowane do ekran&oacute;w: mobile, tablet, desktop)na podstawie makiet&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">33</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">UI designer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Finalna wersja grafik sklepu, uwzględniająca trzy rozmiary ekran&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie designu (frontendu sklepu internetowego)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">37</span></p>
</td>
<td>
<p><span style="font-weight: 400;">6</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Mid frontend deweloper</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wdrożona finalna wersja graficzna sklepu internetowego.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Testy funkcjonalne i wydajnościowe&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">43</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">QA Engineer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport z test&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie produkcyjne i szkolenie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">47</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">PM, Senior backend deweloper, Mid backend deweloper, Junior backend deweloper, Mid frontend deweloper</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokumentacja szkolenia.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
