const html = `<h2><strong>CV członk&oacute;w zespołu realizującego projekt z obszaru Big Data</strong></h2>
<p>&nbsp;</p>
<table border="1" cellpadding="10">
<tbody>
<tr style="height: 35.7188px;">
<td style="height: 35.7188px;" colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Architekt BI/BD&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 83px;">
<td style="height: 83px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="height: 83px;">
<p><span style="font-weight: 400;">2013-2015 Python Deweloper</span></p>
<p><span style="font-weight: 400;">&nbsp;2015 - 2020&nbsp; Data Engineer</span></p>
<p><span style="font-weight: 400;">2020 &ndash;&nbsp; obecnie Architekt&nbsp;</span></p>
</td>
</tr>
<tr style="height: 179px;">
<td style="height: 179px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="height: 179px;">
<p><span style="font-weight: 400;">SQL****</span></p>
<p><span style="font-weight: 400;">Power BI****</span></p>
<p><span style="font-weight: 400;">Azure Data Factory****</span></p>
<p><span style="font-weight: 400;">Azure Analysis Services****</span></p>
<p><span style="font-weight: 400;">DAX*</span></p>
<p><span style="font-weight: 400;">Azure DevOps****</span></p>
<p><span style="font-weight: 400;">Python***</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;"> Azure Foundation, Azure Data Engineer</span></p>
</td>
</tr>
<tr style="height: 179px;">
<td style="height: 179px;">
<p><strong>Wiedza</strong></p>
</td>
<td style="height: 179px;">
<p><span style="font-weight: 400;">Teoria struktur danych</span></p>
<p><span style="font-weight: 400;">Modela danych i sposoby ich&nbsp; przetwarzania</span></p>
<p><span style="font-weight: 400;">Hurtownie danych</span></p>
<p><span style="font-weight: 400;">Procesy ETL</span></p>
<p><span style="font-weight: 400;">Procesy CI/CD</span></p>
<p><span style="font-weight: 400;">Znajomość reguł programowania KIS (keep it simple) i DRY (don&rsquo;t repeat yourself)</span></p>
<p><span style="font-weight: 400;">Znajomość zasad optymalizacji kosztowej i wydajnościowej usług chmurowych&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Mgr informatyki</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Komunikatywność, umiejętność prezentacji pomysł&oacute;w/wyjaśnienia , prowadzenie dyskusji</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Programista Python/Spark</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2015 Junior Python Deweloper</span></p>
<p><span style="font-weight: 400;">2015 - 2019 Mid Python deweloper</span></p>
<p><span style="font-weight: 400;">2019 &ndash;&nbsp; Senior Spark deweloper&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">AWS Glue***</span></p>
<p><span style="font-weight: 400;">AirFlow*****</span></p>
<p><span style="font-weight: 400;">QklikReplicate***</span></p>
<p><span style="font-weight: 400;">Git***</span></p>
<p><span style="font-weight: 400;">Jira****</span></p>
<p><span style="font-weight: 400;">&nbsp;IDE (visual studio code)****</span></p>
<p><span style="font-weight: 400;">Python Spark ****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> Rozumienie zasad ETL i przetwarzania danych big data, cloud computing, zasada budowy data lake, programowanie obiektowe w Spark</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Skrupulatność, analityczne myślenie</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<p><br /><br /></p>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Programista ETL</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> 2021-2022 &ndash; stażysta w firmie IT&nbsp;</span></p>
<p><span style="font-weight: 400;">2022- obecnie - ETL deweloper</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">T-SQL ****</span></p>
<p><span style="font-weight: 400;">PL/SQL****</span></p>
<p><span style="font-weight: 400;">Azure Data Factory ***</span></p>
<p><span style="font-weight: 400;">Git ***</span></p>
<p><span style="font-weight: 400;">Azure SQL Sever ****</span></p>
<p><span style="font-weight: 400;">SQL management studio***&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Modele hurtowni danych (fakt/miara, wymiar)</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Lic. informatyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Analityczne myślenie , inżynierski umysł</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
