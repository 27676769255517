const html = `<h3 style="text-align: center;"><strong>Wykaz technologii użytych w projekcie VR wraz z opisem i przykładami</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Element/moduł rozwiązania/ technologia</strong></p>
</td>
<td>
<p><strong>Opis elementu/technologii</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Silnik do tworzenia animacji, scen, gier 3D</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia szybkie tworzenie scen i animacji 3D, w tym z wykorzystaniem GUI (prosty w obsłudze interfejs graficzny, umożliwiający tworzenie&nbsp; scen i animacji bez programowania).</span></p>
<p><span style="font-weight: 400;">Silnik gry komputerowej to gł&oacute;wna część kodu gry dostępna wraz ze środowiskiem programistycznym zaprojektowanym dla os&oacute;b i zespoł&oacute;w tworzących gry komputerowe. Silnik odpowiada za interakcje pomiędzy elementami gry.</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Unity, Construct3, Bluepront, C++, Unreal Engine</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Obsługa GUI (graphical user interface) danego silnika, JavaScript</span></p>
</td>
</tr>
<tr>
<td>
<p>Gogle VR (headset)</p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia przeniesienie się do </span><span style="font-weight: 400;">wirtualnej rzeczywistości, gdzie dają możliwość rozejrzenia się dookoła.&nbsp;</span></p>
<p><span style="font-weight: 400;">Do gogli trafiają obrazy, generowane przez komputer, konsolę lub smartfon. Wyświetlane są one na dw&oacute;ch ekranach lub na dw&oacute;ch częściach jednego ekranu. </span><span style="font-weight: 400;">Obrazy są niemal identyczne, ale jeden jest nieznacznie przesunięty względem drugiego. Dzięki temu i zakrzywieniu umieszczonych przed ekranami soczewek, daje to efekt realistycznej tr&oacute;jwymiarowości.</span></p>
<p><span style="font-weight: 400;">Gogle VR, dzięki specjalnym kontrolerom pozwalają wpływać na otoczenie w wirtualnej rzeczywistości. Aby wykonać określoną akcję w wirtualnej rzeczywistości, na przykład spojrzeć w g&oacute;rę, należy naprawdę unieść głowę, mając w tym czasie na oczach gogle VR. Ten rodzaj interakcji daje efekt realności rzeczywistości wirtualnej.&nbsp;</span></p>
<br />
<p><strong>Przykłady:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Meta Oculus Quest 2, Valve Index, HTC Vive Pro 2, HP Reverb G2, Pimax Vision 8K Plus&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Uruchomienie okular&oacute;w, podłączenie okular&oacute;w do urządzenia.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Kontrolery VR</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwiają interakcję i podejmowanie działań w VR.</span></p>
<p><span style="font-weight: 400;">Bezprzewodowe kontrolery poszerzają możliwości sterowania i interakcji w grach czy symulacjach. Poza podstawowymi rodzajami kontroler&oacute;w ruchu, występują kontrolery np. imitujące r&oacute;żne urządzenia, </span><span style="font-weight: 400;">jak np. sprzęt sportowy, broń.&nbsp; Większość kontroler&oacute;w określić można jako ręczne sterowniki VR. Kontrolery te są używane i kontrolowane ręcznie i używają gł&oacute;wnie przycisk&oacute;w, tj. tzw. gamepad&oacute;w. Mogą śledzić ruchu, gesty, pozycje.</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Oculus Touch, Samsung Rink, SteelSeries Stratus XL, 3D Rudder Foot VR</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Uruchomienie i skonfigurowanie kontrolera z wybranymi urządzeniami.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Oprogramowanie do modelowania i renderowania grafik 3D</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia modelowanie i renderowania obraz&oacute;w oraz animacji tr&oacute;jwymiarowych o niekonwencjonalnym interfejsie użytkownika. Umożliwia także tworzenie prezentacji interaktywnych na własnym silniku graficznym, a także produkowanie reklam telewizyjnych oraz gier komputerowych.</span></p>
<br />
<p><span style="font-weight: 400;"><strong>Przykłady:</strong> Blender, 3D Painter, 3D Studio Max.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Obsługa danego program do modelowania</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
