import i18n from "../i18n/i18n";

export const gamesModes = [
  {
    name: "Nauka",
    key: "learn",
    modeSpecification: [
      "Podpowiedzi [otrzymywanie podpowiedzi o kolejnych krokach, które należy podjąć]",
      "Testy wiedzy [zdawanie testów wiedzy po otwarciu artykułów w bazie wiedzy]",
      "Brak licznika czasu [gra nie jest przerywana po upływie czasu przewidzianego na zadanie]",
    ],
  },
  {
    name: "Test",
    key: "test",
    modeSpecification: [
      "Brak podpowiedzi [gracz nie otrzymuje wskazówek o kolejnych krokach, które należy podjąć]",
      "Blokada bazy wiedzy [brak możliwości wyświetlania artykułów w bazie wiedzy]",
      "Licznik czasu [przerwanie gry po upływie czasu przewidzianego na zadanie]",
    ],
  },
];

export const gameSkills = [
  {
    gameModeKey: "learn",
    skills: [
      "MYŚLENIE ANALITYCZNE",
      "KREATYWNOŚĆ",
      "WYSZUKIWANIE INFORMACJI (RESEARCH) I PRZETWARZANIE ICH",
      "SAMODZIELNOŚĆ W DZIAŁANIU",
      "DĄŻENIE DO OSIĄGNIĘĆ / ZORIENTOWANIE NA CEL",
      "ORIENTACJA NA KLIENTA",
      "ORGANIZACJA WŁASNEJ PRACY",
    ],
  },
  {
    gameModeKey: "test",
    skills: [
      "ROZWIĄZYWANIE PROBLEMÓW",
      "ROZWIJANIE I WYKORZYSTYWANIE MYŚLENIA MATEMATYCZNEGO",
      "UMIEJĘTNOŚĆ KONSEKWENTNEGO I WYTRWAŁEGO UCZENIA SIĘ",
    ],
  },
];

export const subscriptionTime = [
  {
    name: "3 miesiące",
    key: "3",
  },
  {
    name: "1 rok",
    key: "12",
  },
];

export const productsData = [
  {
    id: "c3153a9d-2ec1-46b8-be82-3aeb1eac6253",
    label: "Pakiet S",
    code: "org_3m_10u",
    benefits: [
      "dostęp do wszystkich funkcjonalności konta ORGANIZACJA",
      "dostęp do wszystkich materiałów edukacyjnych (raporty, bazy wiedzy, kursy)",
      "10 licencji dostępu do konta GRACZ",
    ],
  },
  {
    id: "5887ec96-f84b-4746-a33e-827a5d4b8455",
    label: "Pakiet M",
    code: "org_3m_50u",
    benefits: [
      "dostęp do wszystkich funkcjonalności konta ORGANIZACJA",
      "dostęp do wszystkich materiałów edukacyjnych (raporty, bazy wiedzy, kursy)",
      "50 licencji dostępu do konta GRACZ",
    ],
  },
  {
    id: "24e06b34-edaa-4346-9c26-8e6e050d9152",
    label: "Pakiet L",
    code: "org_3m_100u",
    benefits: [
      "dostęp do wszystkich funkcjonalności konta ORGANIZACJA",
      "dostęp do wszystkich materiałów edukacyjnych (raporty, bazy wiedzy, kursy)",
      "100 licencji dostępu do konta GRACZ",
    ],
  },
  {
    id: "2d96030a-adf9-4b85-be50-48d2abc5e6eb",
    label: "Pakiet S",
    code: "org_12m_10u",
    benefits: [
      "dostęp do wszystkich funkcjonalności konta ORGANIZACJA",
      "dostęp do wszystkich materiałów edukacyjnych (raporty, bazy wiedzy, kursy)",
      "10 licencji dostępu do konta GRACZ",
    ],
  },
  {
    id: "7a1c6fd4-f1dc-4159-9df9-a47716d54c8a",
    label: "Pakiet M",
    code: "org_12m_50u",
    benefits: [
      "dostęp do wszystkich funkcjonalności konta ORGANIZACJA",
      "dostęp do wszystkich materiałów edukacyjnych (raporty, bazy wiedzy, kursy)",
      "50 licencji dostępu do konta GRACZ",
    ],
  },
  {
    id: "f85ac0e1-add9-46fe-80c9-90068012e112",
    label: "Pakiet L",
    code: "org_12m_100u",
    benefits: [
      "dostęp do wszystkich funkcjonalności konta ORGANIZACJA",
      "dostęp do wszystkich materiałów edukacyjnych (raporty, bazy wiedzy, kursy)",
      "100 licencji dostępu do konta GRACZ",
    ],
  },
  {
    id: "37e2797c-84f3-4d13-be81-08e4e7788e74",
    label: "Licencja indywidualna - 1 miesiąc",
    code: "ind_1m",
  },
  {
    id: "c18d3bcc-263d-4420-a42a-63ebc8353333",
    label: "Licencja indywidualna - 3 miesiące",
    code: "ind_3m",
  },
  {
    id: "aa016191-4b5c-4140-9daf-c6824381c9bd",
    label: "Licencja indywidualna - 6 miesięcy",
    code: "ind_12m",
  },
];

export const buyProductFormType = [
  {
    value: "privatePerson",
    text: i18n.t("buyProductScreen.privatePerson"),
  },
  {
    value: "company",
    text: i18n.t("buyProductScreen.company"),
  },
];

export const buyProductExtendAccessOptions = [
  {
    value: "extendUsersAccounts",
    text: i18n.t("buyProductScreen.extendUsersAccounts"),
  },
  {
    value: "deleteUsersAccounts",
    text: i18n.t("buyProductScreen.deleteUsersAccounts"),
  },
];
