const html = `<h3 style="text-align: center;"><strong>Modelowy harmonogram&nbsp; projektu wdrożenia platformy IoT&nbsp;</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)&nbsp;</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Osoby odpowiedzialne/realizujące(zesp&oacute;ł)</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Retencja danych (pozyskanie danych z urządzeń, czujnik&oacute;w i źr&oacute;deł trzecich)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4-16 (zależy od r&oacute;żnorodności urządzeń (wystawiających dane) lub czujnik&oacute;w i rodzaj&oacute;w danych)&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt cloud/IT/OT (projektuje spos&oacute;b dostępu do danych, analizuje dokumentację techniczną), Inż. Danych (normalizuje dane), Developerzy (pozyskujący dane, mają wpływ na wyb&oacute;r technologii, język&oacute;w programowania)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Ustawienie strumieni pozyskiwania danych ze wszystkich potrzebnych źr&oacute;deł&nbsp; (po wystawieniu danych przez źr&oacute;dła platforma jest w stanie je przechwycić)&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Budowa warstwy sieciowej oraz/lub edgowej&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt cloud (zaprojektowanie warstwy, tj. wskazanie usług z kt&oacute;rych rozwiązanie będzie korzystać, zdefiniowanie ścieżek danych ciepłych i zimnych), DevOps (zestawienie infrastruktury i skonfigurowanie zgodnie z wytycznymi architekta),&nbsp; ETL Developer (zajmuje się przetwarzaniem danych, zmienianiem kontraktu danych)&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dane zebrane na etapie retencji danych mogą być persystentnie przechowywane (<em>persistant storage</em>, tj. przechowywanie nie w pamięci a w bazach danych, tzw. fizycznie, są skopiowane min. 3 razy)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Stworzenie interfejsu po stronie chmury do odbioru danych i konfiguracja warstwy przechowywania danych (z użyciem gotowych serwis&oacute;w chmurowych)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4-16 (zależy od r&oacute;żnorodności urządzeń (wystawiających dane) lub czujnik&oacute;w i rodzaj&oacute;w danych)&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt cloud/IT/OT (projektuje spos&oacute;b dostępu do danych, analizuje dokumentację techniczną), Inż. Danych (normalizuje dane), Developerzy (pozyskujący dane, mają wpływ na wyb&oacute;r technologii, język&oacute;w programowania)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Ustawienie strumieni pozyskiwania danych ze wszystkich potrzebnych źr&oacute;deł&nbsp; (po wystawieniu danych przez źr&oacute;dła platforma jest w stanie je przechwycić)&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie warstwy przetwarzania danych&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po zakończeniu poprzednich etap&oacute;w&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">8-24 (w zależności od projektu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt cloud (wyb&oacute;r silnika przetwarzania danych, projektowanie algorytm&oacute;w domenowych, funkcji przetwarzania danych&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Implementacja algorytm&oacute;w domenowych oraz wdrożenie modeli predykcyjnych dla określonych urządzeń/czujnik&oacute;w&nbsp;</span></p>
</td>
</tr>
<tr>
<td><br />
<p><span style="font-weight: 400;">Wdrożenie warstwy prezentacji danych&nbsp;</span></p>
<br /><br /></td>
<td>
<p><span style="font-weight: 400;">W trakcie lub po zakończeniu poprzednich etap&oacute;w (możliwe prace na mock upach)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">8-24 (w zależności od projektu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architect cloud (definiowanie kontraktu danych), Analityk biznesowy (definiowanie widok&oacute;w i element&oacute;w API potrzebnych dla konkretnej domeny, określenie czego potrzebuje klient w zakresie widok&oacute;w analitycznych), UX designer (przygotowanie widok&oacute;w dla oceny klienta na bazie tego co przygotował analityk), cloud developer, UI developer (przygotowanie API i gotowych widok&oacute;w do prezentowania danych)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zestaw widok&oacute;w z danymi/ raporty analityczne oraz dokumentacja API dające możliwość uzyskania wstępnie przetworzonych lub surowych danych przechowywanych na platformie&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
