const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="5">
<h3 style="text-align: center;"><strong>Harmonogram realizacji projektu z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Nazwa zadania projektowego (kamienie milowe projektu)&nbsp;&nbsp;</strong></p>
</td>
<td>
<p><strong>Nr tygodnia, w kt&oacute;rym następuje rozpoczęcie zadania&nbsp;</strong></p>
</td>
<td>
<p><strong>Ilość tygodni trwania zadania&nbsp;</strong></p>
</td>
<td>
<p><strong>Skład osobowy zespołu realizującego zadanie&nbsp;&nbsp;</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analiza zastanej sytuacji i dostarczonych danych; profilowanie grupy docelowej</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport z analizy</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie strategii marki i gł&oacute;wnych założeń komunikacji marki; dob&oacute;r kanał&oacute;w komunikacji w social media</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokument z opisem strategii</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie koncepcji kreatywnej</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Creative Director&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Przykłady projekt&oacute;w graficznych, storyboard&oacute;w [szkice przykładowych ujęć z kluczowych scen materiał&oacute;w wideo] i tekst&oacute;w</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie ramowego planu działań na 6 m-cy</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokument z planem działania na 6 m-cy</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie rekomendacji narzędzi</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Lista wykorzystywanych narzędzi</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Konfiguracja wybranych narzędzi</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Narzędzia gotowe do pracy</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie content plan&oacute;w</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Social Media Manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Szczeg&oacute;łowy plan publikacji na każdy miesiąc</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Projektowanie UI/UX landing page sprzedażowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Lead Designer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Prototyp/Schematy funkcjonalne strony www</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Projektowanie graficzne landing page sprzedażowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Lead Designer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Projekty graficzne strony www</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przekazanie zapotrzebowania na treści wideo</span></p>
</td>
<td>
<p><span style="font-weight: 400;">6</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Lista niezbędnych materiał&oacute;w wideo wraz z ich opisem</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie, testowanie i instalacja na serwerze produkcyjnym landing page sprzedażowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">7</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Frontend Developer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Opublikowany landing page gotowy do działania</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Nadz&oacute;r nad zgodnością realizacji treści wideo z content planem</span></p>
</td>
<td>
<p><span style="font-weight: 400;">7</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pliki wideo przekazane przez Klienta wykonawcy</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Prowadzenie działań komunikacyjnych w social mediach (publikacje, moderacja)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">11</span></p>
</td>
<td>
<p><span style="font-weight: 400;">24</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Social Media Manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Realizacja założeń strategii i content plan&oacute;w</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przeprowadzenie kampanii płatnych w social mediach (social ads, zasięgowe, wizerunkowe/sprzedażowe, remarketingowe)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">11</span></p>
</td>
<td>
<p><span style="font-weight: 400;">24</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Social Media Manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Realizacja założeń strategii i content plan&oacute;w w zakresie działań płatnych</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Raportowanie okresowe (milestones x3 w trakcie 6 miesięcy)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">17, 23, 29</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Social Media Manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3 raporty okresowe</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie raportu końcowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">36</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport końcowy</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
