import React from "react";
import { useTranslation } from "react-i18next";
import LinkTextButton from "../../components/LinkTextButton";
import RegistrationForm from "./RegistrationForm";

const RegistrationScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="registerScreen">
      <div className="logoWrapper">
        <img
          className="logo"
          alt="DigiLeader"
          src="https://digileader.cloud/wp-content/uploads/2021/05/digi-leader-logo.png"
        />
      </div>
      <div className="newAccountTextWrapper">
        {t("loginScreen.createAnAccount")}
      </div>
      <RegistrationForm />
      <div className="alreadyHaveAnAccountContainer">
        <span>{t("loginScreen.alreadyHaveAnAccount")}</span>
        <LinkTextButton label={t("loginScreen.login")} href="login" />
      </div>
    </div>
  );
};

export default RegistrationScreen;
