const html = `<p style="text-align: justify;">Scrum to najpopularniejsza metodologia określająca ramy postępowania w zakresie zarządzania projektami IT, realizująca założenia podejścia zwinnego (agile) w produkcji oprogramowania. W Scrumie istnieje coś w rodzaju dżentelmeńskiej umowy. Polega ona na tym, że Product Owner zamraża część wymagań, obiecując że w danej iteracji (sprincie) ich nie zmieni, tak żeby Developerzy mogli je przekuć w funkcjonalności produktu. Developerzy dają Product Ownerowi wolną rękę w pracy nad pozostałymi, niezamrożonymi wymaganiami. Ten cykl pracy, zwany sprintem, ma stałą długość i pozwala nadać rytm pracy. Na koniec każdej iteracji powstaje coś gotowego i potencjalnie &bdquo;wydawalnego&rdquo;, realizującego określoną potrzebę biznesową. &nbsp;Wymagania są segregowane względem wartości (istotności) w produkcie, przez Product Ownera, na liście zwanej Product Backlogiem.&nbsp;</p>
<p style="text-align: justify;"><strong>Role w zespole scrumowym:</strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Product Owner odpowiada za podejmowanie decyzji co do rozwoju produktu i wykorzystaniu czasu Developer&oacute;w tak, żeby zbudować jak największą wartość. Musi myśleć jak analityk biznesowy, marketer, klient i użytkownik końcowy, aby spełnić wymagania klienta</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Developerzy odpowiadają za zaplanowanie, organizację i wykonanie pracy. Developerzy to nie tylko programiści, ale r&oacute;wnież testerzy czy projektanci</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Scrum Master odpowiada za wprowadzenie Scrum w życie, zrozumienie i przestrzeganie zasad frameworku. Wspiera zesp&oacute;ł poprzez facylitację i usuwanie przeszk&oacute;d (ang. impediments)</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Wydarzenia w trakcie realizacji projektu scrumowego: </strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Sprint &mdash; iteracja nie dłuższa niż jeden miesiąc, w ramach kt&oacute;rej zwarte są pozostałe wydarzenia</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Sprint Planning &mdash; na początku każdego Sprintu patrzymy co jest najbardziej wartościową rzeczą do zrobienia i prognozujemy jak będzie wyglądała praca w tej iteracji</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Daily Scrum &mdash; codziennie Developerzy spotykają się, żeby sprawdzić stan pracy i wsp&oacute;lnie zdecydować, co należy dalej zrobić</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Sprint Review &mdash; pod koniec Sprintu sprawdzamy, jak wygląda przyrost produktu i zbieramy feedback, żeby interesariusze mieli jasny obraz faktycznego postępu i dostosować wymagania do ich oczekiwań</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Sprint Retrospective &mdash; na koniec Sprintu zatrzymujemy się na chwilę, przyglądamy się procesowi, narzędziom, interakcjom, żeby zdecydować jak usprawnić proces</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Artefakty w scrumie:</strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Product Backlog &mdash; lista rzeczy do zrobienia, kt&oacute;re umożliwią stworzenie produktu, jedyne miejsce, w kt&oacute;rym developerzy sprawdzają zakres prac. Product Backlog powinien być zawsze aktualny, żeby jasno pokazywać, ile jeszcze jest rzeczy do zrobienia&nbsp;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Sprint Backlog &mdash; lista rzeczy do zrobienia w danym Sprincie. Sprint Backlog powinien być zawsze aktualny, żeby jasno pokazywać jaki jest postęp pracy</p>
<p style="text-align: justify;">● &nbsp; &nbsp;Product Increment &mdash; ukończony, przetestowany i zintegrowany przyrost funkcjonalności i właściwości produktu, kt&oacute;ry pokazuje, co faktycznie zostało zrobione </p>`;

export default html;
