import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FullGameType from "../../interfaces/game/FullGameType";
import GameType from "../../interfaces/game/GameType";
import UserGameType from "../../interfaces/game/UserGameType";
import { getWindowSize } from "../../utils/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface Props {
  games: (GameType | UserGameType | FullGameType)[];
  renderGameCard: (
    game: GameType | UserGameType | FullGameType,
    idx: number
  ) => any;
}

const GamesShow = (props: Props) => {
  const { t } = useTranslation();
  const { games, renderGameCard } = props;
  const [numberOfCards, setNumberOfCards] = useState<number>(0);
  const [firstGame, setFirstGame] = useState<number>(0);
  const [withButtons, setWithButtons] = useState<boolean>(false);

  useEffect(() => {
    calculateNumberOfCards(getWindowSize());
    const handleWindowResize = () => {
      calculateNumberOfCards(getWindowSize());
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (numberOfCards > 0 && games.length > 0) {
      setWithButtons(games.length > numberOfCards);
      setFirstGame(0);
    }
  }, [games, numberOfCards]);

  const calculateNumberOfCards = (windowSize: {
    innerWidth: number;
    innerHeight: number;
  }) => {
    const { innerWidth } = windowSize;
    const cardWidth = 300;
    const wrapperPadding = 120 * 2; //TODO 240 to defaultowy padding, jeszcze do zmiany
    const cardsMargin = 24; //TODO
    let initNumber = Math.floor((innerWidth - wrapperPadding) / cardWidth);
    const restOfComponent =
      innerWidth - wrapperPadding - initNumber * cardWidth;
    const spaceNeededForMargins = (initNumber - 1) * cardsMargin;
    if (restOfComponent - spaceNeededForMargins < 0) {
      setNumberOfCards(initNumber - 1);
    } else setNumberOfCards(initNumber);
  };

  const renderCards = () => {
    let gamesToRender = games
      .slice(withButtons ? firstGame : 0, firstGame + numberOfCards)
      .map((game, idx) => renderGameCard(game, idx));
    const emptyCards = numberOfCards - games.length;
    for (let i = 0; i < emptyCards; i++) {
      gamesToRender.push(
        <div className="gameCard empty" key={`emptyCard_${i + 1}`} />
      );
    }
    return gamesToRender;
  };

  const renderNextButton = (side: "left" | "right") => {
    const disabled =
      side === "left"
        ? firstGame === 0
        : firstGame + numberOfCards === games.length;
    const onClick =
      side === "left"
        ? () => setFirstGame(firstGame - 1)
        : () => setFirstGame(firstGame + 1);

    return (
      <div
        className={`nextButton ${side} ${disabled ? "disabled" : ""}`}
        onClick={onClick}
      >
        {side === "left" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </div>
    );
  };

  return (
    <div className="gamesShow">
      {withButtons && renderNextButton("left")}
      {renderCards()}
      {withButtons && renderNextButton("right")}
    </div>
  );
};

export default GamesShow;
