const html = `<h3 style="text-align: center;">Modelowy harmonogram projektu wdrożenia narzędzia wykorzystującego jako bazę danych blockchain</h3>
<table border="1" cellpadding="5">
<tbody>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)&nbsp;</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Osoby odpowiedzialne/realizujące(zesp&oacute;ł)</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Analiza biznesowo-techniczna&nbsp;</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">1</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">4-6</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Analityk biznesowy, Architekt IT</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Low level design oraz dokumentacja wymagań budowanego rozwiązania</span></p>
</td>
</tr>
<tr style="height: 61px;">
<td style="height: 61px;">
<p><span style="font-weight: 400;">Przygotowanie mock-up&oacute;w aplikacji</span></p>
</td>
<td style="height: 61px;">
<p><span style="font-weight: 400;">3</span></p>
</td>
<td style="height: 61px;">
<p><span style="font-weight: 400;">4-6</span></p>
</td>
<td style="height: 61px;">
<p><span style="font-weight: 400;">UX/UI Designer</span></p>
</td>
<td style="height: 61px;">
<p><span style="font-weight: 400;">Projekty ekran&oacute;w budowanej aplikacji (jeżeli są wymagane w danym projekcie)</span></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Development</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">4-6</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">12-24</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Developer front-end, Developer back-end, Developer warstwy smart-contract, DevOps</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Kod źr&oacute;dłowy budowanego rozwiązania</span></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Testy</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">16-30</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">4-6</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Testerzy QA, Developerzy (do poprawek błęd&oacute;w)</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Pozytywna realizacja scenariuszy testowych</span></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Wdrożenie</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">20-36</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">2-4</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">DevOps</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Aplikacja gotowa do użytku przez użytkownik&oacute;w końcowych.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
