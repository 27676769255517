const html = `<p><strong>SWOT&nbsp; zastosowania technologii AI/ML</strong></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">STRENGTHS (mocne strony)&nbsp;</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">analiza ogromnych wolumen&oacute;w danych (nie do realizacji przez człowieka)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">możliwość wsparcia lub automatyzacji działań ludzkich&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">w wielu przypadkach większa trafność decyzji w por&oacute;wnaniu do działań człowieka&nbsp;</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">WEAKNESSES (słabe strony)&nbsp;</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wymaga dużej ilości danych&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">relatywnie wysoki koszt wdrożenia&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">koszt wdrożenia może w wybranych przypadkach nie uzasadniać inwestycji (nie przekładać się na korzyści)</span></li>
</ul>
<h3><span style="font-weight: 400;">OPPORTUNITIES (szanse)</span></h3>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">możliwość budowania przewag konkurencyjnych, szczeg&oacute;lnie w przypadku wdrożenia przed konkurentami</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">obniżenie koszt&oacute;w działalności firmy&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zwiększenie efektywności przedsiębiorstwa&nbsp;</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">THREATS (zagrożenia)</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">niepowodzenie we wdrożeniu technologii (brak oczekiwanych wynik&oacute;w)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">nieplanowane zwiększenie koszt&oacute;w projektu ze względu np. na niską jakość i dostępność danych&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">konieczność ponoszenia cyklicznych koszt&oacute;w na utrzymanie i rozwijanie technologii</span></li>
</ul>`;

export default html;
