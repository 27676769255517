const html = `<h3><strong>Wykaz stanowisk w zespole ML/AI wraz z zakresem obowiązk&oacute;w i CV</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<p><strong>Stanowisko</strong></p>
</td>
<td>
<p><strong>Zakres odpowiedzialności&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">ML inżynier&nbsp;</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">programowanie modeli ML i ich wdrożenie na produkcję</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Data Scientist</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">przeprowadzanie analiz danych klienta, ocena danych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">opracowanie propozycji modelu prognostycznego</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Data Engineer</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">inżynieria danych, tj. wyciągnięcie danych ze źr&oacute;deł i programowania ETL</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">PM</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">zarządzanie zadaniami w projekcie, budżetem i harmonogramem</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table style="width: 936px;" border="1" cellpadding="10">
<tbody>
<tr style="height: 48px;">
<td style="width: 917px; height: 48px;" colspan="2">
<p><span style="font-weight: 400;">Data Scientist&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="width: 94.8594px; height: 35px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="width: 822.141px; height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 59px;">
<td style="width: 94.8594px; height: 59px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="width: 822.141px; height: 59px;">
<p><span style="font-weight: 400;">2015 - 2017 &nbsp; Analityk finansowy&nbsp;</span></p>
<p><span style="font-weight: 400;">2017 &ndash; obecnie&nbsp; Data Scientist /Machine Learning Engineer</span></p>
</td>
</tr>
<tr style="height: 288px;">
<td style="width: 94.8594px; height: 288px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="width: 822.141px; height: 288px;">
<p><span style="font-weight: 400;">Power BI **</span></p>
<p><span style="font-weight: 400;">Tableau *</span></p>
<p><span style="font-weight: 400;">SQL ****</span></p>
<p><span style="font-weight: 400;">Python ***</span></p>
<p><span style="font-weight: 400;">Python:</span></p>
<p><span style="font-weight: 400;">NumPy ****</span></p>
<p><span style="font-weight: 400;">Pandas *****</span></p>
<p><span style="font-weight: 400;">Scikit-Learn*****</span></p>
<p><span style="font-weight: 400;">Seaborn****</span></p>
<p><span style="font-weight: 400;">Deep Learning (PyTorch, Keras, TensorFlow) ****</span></p>
<p><span style="font-weight: 400;">Jupyter *****</span></p>
</td>
</tr>
<tr style="height: 113px;">
<td style="width: 94.8594px; height: 113px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="width: 822.141px; height: 113px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Data Analyst (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Data Scientist (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Statistical Inference (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">IBM Data Science Certificate (Coursera)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Google Data Analytics Certificate (Coursera)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">John Hopkins Data Science Specialization (Coursera)</span></li>
</ul>
</td>
</tr>
<tr style="height: 48px;">
<td style="width: 94.8594px; height: 48px;">
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td style="width: 822.141px; height: 48px;">
<p><span style="font-weight: 400;">Finanse przedsiębiorstw, modelowanie predykcyjne, maszynowe uczenie, wizualizacja danych, analiza statystyczna, modele ekonometryczne&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="width: 94.8594px; height: 35px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="width: 822.141px; height: 35px;">
<p><span style="font-weight: 400;">Mgr ekonomii, specjalizacja: ekonometria&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="width: 94.8594px; height: 35px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="width: 822.141px; height: 35px;">
<p><span style="font-weight: 400;">Analityczne myślenie, umiejętność pracy w zespole, umiejętność szybkiego uczenia&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="10">
<tbody>
<tr style="height: 48px;">
<td style="height: 48px;" colspan="2">
<p><span style="font-weight: 400;">Data Engineer/Inżynier Danych</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="height: 35px;">&nbsp;</td>
</tr>
<tr style="height: 107px;">
<td style="height: 107px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="height: 107px;">
<p><span style="font-weight: 400;">2010-2012 &ndash; Junior Developer&nbsp;</span></p>
<p><span style="font-weight: 400;">2012 -2014 &ndash; Mid Developer&nbsp;</span></p>
<p><span style="font-weight: 400;">2014- 2018 &ndash; Senior Developer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">2018 &ndash; Inżynier Danych&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 323px;">
<td style="height: 323px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="height: 323px;">
<p><span style="font-weight: 400;">SQL *****</span></p>
<p><span style="font-weight: 400;">ETL *****</span></p>
<p><span style="font-weight: 400;">Snowflake****</span></p>
<p><span style="font-weight: 400;">Spark***</span></p>
<p><span style="font-weight: 400;">Hadoop***</span></p>
<p><span style="font-weight: 400;">Hive**</span></p>
<p><span style="font-weight: 400;">Presto**</span></p>
<p><span style="font-weight: 400;">Oracle DB**</span></p>
<p><span style="font-weight: 400;">PostgreSQL**</span></p>
<p><span style="font-weight: 400;">PL/SQL****</span></p>
<p><span style="font-weight: 400;">Python *****</span></p>
<p><span style="font-weight: 400;">Cloud (Azure, AWS, Databricks)***</span></p>
<p><span style="font-weight: 400;">Azure Synapse****</span></p>
</td>
</tr>
<tr style="height: 74px;">
<td style="height: 74px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="height: 74px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Data Engineer (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Big Data with PySpark (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">IBM Data Warehouse Engineer Certificate(Coursera)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">IBM Data Engineer Certificate (Coursera)</span></li>
</ul>
</td>
</tr>
<tr style="height: 131px;">
<td style="height: 131px;">
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td style="height: 131px;">
<p><span style="font-weight: 400;">Modelowanie danych&nbsp;</span></p>
<p><span style="font-weight: 400;">Wiedza o hurtowniach danych&nbsp;</span></p>
<p><span style="font-weight: 400;">Migracja danych</span></p>
<p><span style="font-weight: 400;">Performance tuning</span></p>
<p><span style="font-weight: 400;">Optymalizacja kodu w SQL</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Mgr informatyki&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="height: 35px;">
<p><span style="font-weight: 400;">Analityczne myślenie, umiejętność pracy w zespole, umiejętność szybkiego uczenia</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Machine Learning Engineer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2016 &ndash; 2017 Junior software developer&nbsp;</span></p>
<p><span style="font-weight: 400;">2017 - 2019&nbsp; Software developer&nbsp;</span></p>
<p><span style="font-weight: 400;">2019&ndash; obecnie Machine Learning Engineer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Java****</span></p>
<p><span style="font-weight: 400;">SQL ***</span></p>
<p><span style="font-weight: 400;">Python ****</span></p>
<p><span style="font-weight: 400;">Flask/Django****</span></p>
<p><span style="font-weight: 400;">MLOps****</span></p>
<p><span style="font-weight: 400;">Python:</span></p>
<p><span style="font-weight: 400;">NumPy ****</span></p>
<p><span style="font-weight: 400;">Pandas *****</span></p>
<p><span style="font-weight: 400;">Scikit-Learn*****</span></p>
<p><span style="font-weight: 400;">Deep Learning (PyTorch, Keras, TensorFlow) ****</span></p>
<p><span style="font-weight: 400;">Jupyter *****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Machine Learning Scientist with Python (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Machine Learning Fundamentals (Data camp track)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Machine Learning Specialization (Coursera)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Deep Learning Specialization (Coursera)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Machine Learning Specialization (Coursera)</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość zagadnień produkcyjnego wdrożenia modeli ML&nbsp;</span></p>
<p><span style="font-weight: 400;">Techniczne umiejętności budowy modeli ML&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr matematyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność pracy w zespole, analityczne myślenie&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">PM/Success Manager</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Piotr Kamiński</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2016 &ndash; 2017 nauczyciel j. Angielskiego&nbsp;</span></p>
<p><span style="font-weight: 400;">2017 - 2019 Project Owner&nbsp;</span></p>
<p><span style="font-weight: 400;">2019-2020 Power BI Analyst&nbsp;</span></p>
<p><span style="font-weight: 400;">2021&ndash;&nbsp; PM w branży IT</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Jira***</span></p>
<p><span style="font-weight: 400;">Power Bi***</span></p>
<p><span style="font-weight: 400;">Excel***</span></p>
<p><span style="font-weight: 400;">Trello***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Scrum Master</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość metodyki agile zarządzania projektami</span></p>
<p><span style="font-weight: 400;">Wiedza o sposobach przetwarzania i analizowania danych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr filologii angielskiej&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Umiejętności miękkie&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność pracy w zespole, dobra organizacja pracy&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
