const html = `<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację usługi z obszaru wdrożenia </span></strong><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">technologii ML/AI</span></strong></p>
<p><br /><br /></p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki zastosowaniu technologii ML/AI:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Duża konkurencja w branży, szczeg&oacute;lnie ze strony międzynarodowych platform e-commrce korzystających z efekt&oacute;w skali oraz lepszej pozycji negocjacyjnej wśr&oacute;d dostawc&oacute;w książek (przekłada się to na wysokość marży). Sytuacja ta wymusza obniżanie koszt&oacute;w proces&oacute;w&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Rosnące koszty pracownicze utrudniają osiągnięcie konkurencyjnych cen przy jednoczesnym zachowaniu marży. Redukcja zatrudnienia związana z automatyzacją procesu jest szansą na zachowanie marży i obniżenie koszt&oacute;w proces&oacute;w biznesowych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Utrata klient&oacute;w związana ze złymi doświadczeniami podczas procesu zakupowego</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W efekcie realizacji projektu powstanie Proof of Value (PoV) zaawansowanego systemu do klasyfikowania wiadomość e-maili kierowanych na skrzynkę gromadząca skargi/problemy klient&oacute;w dużej księgarni internetowej. Działanie systemu oparte zostanie na indywidualnie opracowanym modelu NLP. Co godzinę system przeprowadzi analizę treści nowych maili przesłanych na skrzynkę. Do każdego maila przypisana zostanie jedna z określonych kategorii oraz poziom priorytetu. Rozwiązanie przetworzy i zapisze wygenerowane dane w hurtowni danych, z kt&oacute;rych systemy klienta służące do wsparcia proces&oacute;w obsługi klienta, pobiorą odpowiednie dane. Wyniki (maile) opatrzone wysokim priorytetem uruchomią automatyczną realizację odpowiedniego procesu przez narzędzie RPA. Wyniki (maile) opatrzone niskim i średnim priorytetem, odpowiednio zakolejkowane, przekazane zostaną wskazanym specjalistom ds. obsługi klienta.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Automatyzacja czasochłonnych proces&oacute;w manualnego kategoryzowania maili, w tym proces&oacute;w problematycznych, kt&oacute;re np. wymagają zmiany kategorii.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Automatyczne segregowanie e-maili wg. kategorii, takich jak: ofertowanie, zwrot towaru, reklamacja wskutek błędu dostawcy, reklamacja w skutek niezgodności towaru z zam&oacute;wieniem.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Automatyczne kolejkowanie e-maili (przypisanie mailom odpowiedniego poziom&oacute;w priorytetu).</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające tworzenie rozwiązań wykorzystujących ML/AI.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zmniejszenie koszt&oacute;w operacyjnych dzięki automatyzacji procesu kategoryzowania e-maili</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zwiększenie satysfakcji klient&oacute;w dzięki szybszemu czasowi reakcji na zgłoszenia</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Redukcja personelu (mniejsze potrzeby rekrutacyjne w sytuacji trudnego rynku pracy) </span></li>
</ul>`;

export default html;
