import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Outlet,
  Routes,
  Navigate,
  unstable_HistoryRouter as HistoryRouter,
  useNavigate,
} from "react-router-dom";
import {
  clearErrorMessage,
  clearTnfoMessage,
} from "./reducers/message.reducer";
import StyledSnackbar from "./screens/components/StyledSnackbar";
import PageTemplate from "./screens/hoc/PageTemplate";
import PageNotFoundScreen from "./screens/PageNotFoundScreen";
import DigitalProfileScreen from "./screens/postAuth/digitalProfile/DigitalProfileScreen";
import GameConfiguratorScreen from "./screens/postAuth/gameConfigurator/GameConfiguratorScreen";
import GameScreen from "./screens/postAuth/gameScreen/GameScreen";
import MainScreen from "./screens/postAuth/mainScreen/MainScreen";
import MyGamesScreen from "./screens/postAuth/myGamesScreen/MyGamesScreen";
import ActivateAcountScreen from "./screens/preAuth/ActivateAcountScreen";
import LoginScreen from "./screens/preAuth/LoginScreen/LoginScreen";
import RegistrationScreen from "./screens/preAuth/RegistrationScreen/RegistrationScreen";
import ResetPasswordScreen from "./screens/preAuth/ResetPasswordScreen/ResetPasswordScreen";
import { AppDispatch, AppState } from "./store/store";
import gamesDataJson from "./mocks/gamesData.json";
import { setGamesData } from "./reducers/game.reducer";
import { RoleType } from "./enums/RoleType";
import OrganizationPanelScreen from "./screens/postAuth/organizationPanel/OrganizationPanelScreen";
import BuyProductScreen from "./screens/postAuth/buyProduct/BuyProductScreen";
import { getUser, getUserEducator } from "./services/user.service";
import FAQScreen from "./screens/postAuth/FAQScreen/FAQScreen";
import { Settings } from "luxon";
import PaymentStatusScreen from "./screens/postAuth/buyProduct/PaymentStatusScreen";
import { CircularProgress } from "@mui/material";
import StatutePDf from "./constants/pdfs/statute.pdf";
import PrivacyPolicyPDF from "./constants/pdfs/privacyPolicy.pdf";

function App() {
  const userRole = useSelector((state: AppState) => state.userReducer.role);

  if (userRole) {
    if (userRole === RoleType.USER) {
      return (
        <Routes>
          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/register">
            <Route path=":groupId" element={<Navigate to="/" />} />
            <Route path="" element={<Navigate to="/" />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<MainScreen />} />
            <Route path="/game/:id" element={<GameScreen />} />
            <Route path="/games" element={<MyGamesScreen />} />
            <Route path="/configurator" element={<GameConfiguratorScreen />} />
            <Route path="/digital_profile" element={<DigitalProfileScreen />} />
            <Route path="/subscription" element={<BuyProductScreen />} />
            <Route path="/payment-status" element={<PaymentStatusScreen />} />
            <Route path="/faq" element={<FAQScreen />} />
            <Route path="/activate" element={<Navigate to="/dashboard" />} />
          </Route>
          <Route path="/*" element={<PageNotFoundScreen />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/register">
            <Route path=":groupId" element={<Navigate to="/" />} />
            <Route path="" element={<Navigate to="/" />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<MainScreen />} />
            <Route path="/game/:id" element={<GameScreen />} />
            <Route path="/panel" element={<OrganizationPanelScreen />} />
            <Route path="/configurator" element={<GameConfiguratorScreen />} />
            <Route path="/subscription" element={<BuyProductScreen />} />
            <Route path="/payment-status" element={<PaymentStatusScreen />} />
            <Route path="/faq" element={<FAQScreen />} />
            <Route path="/activate" element={<Navigate to="/dashboard" />} />
          </Route>
          <Route path="/*" element={<PageNotFoundScreen />} />
        </Routes>
      );
    }
  } else {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register">
            <Route path=":groupId" element={<RegistrationScreen />} />
            <Route path="" element={<RegistrationScreen />} />
          </Route>
          <Route path="/reset-password" element={<ResetPasswordScreen />} />
          <Route path="/activate" element={<ActivateAcountScreen />} />
        </Route>
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }
}

interface LayoutProps {}

const Layout = (props: LayoutProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [rehydrated, setRehydrated] = useState(false);
  const userId = useSelector((state: AppState) => state.userReducer.userId);
  const role = useSelector((state: AppState) => state.userReducer.role);
  const infoMessage = useSelector(
    (state: AppState) => state.messageReducer.infoMessage
  );
  const errorMessage = useSelector(
    (state: AppState) => state.messageReducer.errorMessage
  );

  useEffect(() => {
    if (dispatch) {
      setRehydrated(true);
      dispatch(setGamesData(gamesDataJson));
      Settings.defaultLocale = "pl";
    }
  }, [dispatch]);

  useEffect(() => {
    if (userId && role && dispatch && rehydrated) {
      dispatch(getUser(userId));
      if (role === RoleType.USER) dispatch(getUserEducator(userId));
    }
  }, [userId, role, dispatch, rehydrated]);

  return (
    <>
      {rehydrated ? (
        <div className="layout">
          {!!userId ? (
            <PageTemplate>
              <Outlet />
            </PageTemplate>
          ) : (
            <Outlet />
          )}
          <div className="footer">
            <div className="leftSide">
              <span className="section">{t("footer.company")}</span>
              <span className="section link">{t("footer.email")}</span>
            </div>
            <div className="rightSide">
              <a
                className="section clickable"
                href={PrivacyPolicyPDF}
                download={t("footer.privacyPolicy")}
              >
                {t("footer.privacyPolicy")}
              </a>
              <a
                className="section clickable"
                href={StatutePDf}
                download={t("footer.statute")}
              >
                {t("footer.statute")}
              </a>
            </div>
          </div>
          <StyledSnackbar
            open={!!infoMessage}
            onClose={() => {
              dispatch(clearTnfoMessage());
            }}
            message={infoMessage}
            type="success"
          />
          <StyledSnackbar
            open={!!errorMessage}
            type="error"
            onClose={() => dispatch(clearErrorMessage())}
            message={errorMessage}
          />
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default App;
