import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { LanguageType } from "../../../enums/LanguageType";
import { RoleType } from "../../../enums/RoleType";
import { registerUser } from "../../../services/user.service";
import { AppDispatch } from "../../../store/store";
import {
  emailPattern,
  getInputRules,
  passwordPattern,
} from "../../../utils/utils";
import StyledButton from "../../components/StyledButton";
import StyledCheckbox from "../../components/StyledCheckbox";
import StyledTextInput from "../../components/StyledTextInput";
import StatutePDf from "../../../constants/pdfs/statute.pdf";
import PrivacyPolicyPDf from "../../../constants/pdfs/privacyPolicy.pdf";

interface FormValues {
  firstName: string;
  lastName: string;
  organizationName: string;
  phoneNumber: string;
  email: string;
  password: string;
  repeatPassword: string;
  // newsletterAgreement: boolean;
  statuteAgreement: boolean;
}

const RegistrationForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { groupId } = useParams();
  const [isOrganization, setIsOrganization] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    watch,
    clearErrors,
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    dispatch(
      registerUser({
        ...data,
        language: LanguageType.PL,
        userRole: isOrganization ? RoleType.EDUCATOR : RoleType.USER,
        privileges: ["PRIVILEGE_EDIT"],
        groupId,
      })
    );
  };

  const renderSatuteAgreementLabel = () => (
    <div>
      <span className="label">{"Akceptuję "}</span>
      <a
        className="label clickable"
        href={StatutePDf}
        download={t("footer.statute")}
      >
        {"regulamin"}
      </a>
      <span className="label">{" i "}</span>
      <a
        className="label clickable"
        href={PrivacyPolicyPDf}
        download={t("footer.privacyPolicy")}
      >
        {"politykę prywatnóści"}
      </a>
    </div>
  );

  // const renderNewsletterAgreementLabel = () => (
  //   <span className="label">{"Tak, chcę zapisać się do newslettera."}</span>
  // );

  const validatePasswords = () => {
    const password = watch("password");
    const repeatPassword = watch("repeatPassword");
    if (password !== repeatPassword) return "Hasła nie są identyczne";
    else {
      clearErrors("password");
      clearErrors("repeatPassword");
      return true;
    }
  };

  return (
    <form className="preAuthForm">
      <Controller
        name="firstName"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            onChange={onChange}
            error={errors.firstName && errors.firstName.message}
            label={"Imię"}
          />
        )}
        rules={getInputRules(true, 32)}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            onChange={onChange}
            error={errors.lastName && errors.lastName.message}
            label={"Nazwisko"}
          />
        )}
        rules={getInputRules(true, 32)}
      />
      {!groupId && (
        <StyledCheckbox
          onChange={() => setIsOrganization(!isOrganization)}
          label={
            <span className="label">{"Załóż konto jako organizacja"}</span>
          }
          value={isOrganization}
        />
      )}
      {isOrganization && (
        <Controller
          name="organizationName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledTextInput
              value={value}
              onChange={onChange}
              error={errors.organizationName && errors.organizationName.message}
              label={"Nazwa organizacji"}
            />
          )}
          rules={getInputRules(true, 256)}
        />
      )}
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            onChange={onChange}
            error={errors.phoneNumber && errors.phoneNumber.message}
            label={"Telefon"}
          />
        )}
        rules={getInputRules(true, 13)}
      />
      <Controller
        name="email"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            onChange={onChange}
            error={errors.email && errors.email.message}
            label={"Email"}
          />
        )}
        rules={{
          ...getInputRules(true, 60),
          pattern: {
            value: emailPattern,
            message: "Nieprawidłowy format",
          },
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            type="password"
            onChange={onChange}
            error={errors.password && errors.password.message}
            label={"Hasło"}
          />
        )}
        rules={{
          ...getInputRules(true, 20, 8),
          validate: validatePasswords,
          pattern: {
            value: passwordPattern,
            message:
              "Hasło powinno składać się z przynajmniej 8 znaków i powinno zawierać małą i dużą literę, cyfrę i znak specjalny",
          },
        }}
      />
      <Controller
        name="repeatPassword"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            type="password"
            onChange={onChange}
            error={errors.repeatPassword && errors.repeatPassword.message}
            label={"Powtórz hasło"}
          />
        )}
        rules={{ ...getInputRules(true, 20, 8), validate: validatePasswords }}
      />
      <Controller
        name="statuteAgreement"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledCheckbox
            onChange={onChange}
            value={value}
            error={errors.statuteAgreement && errors.statuteAgreement.message}
            label={renderSatuteAgreementLabel()}
          />
        )}
        rules={getInputRules(true)}
      />
      {/* <Controller
        name="newsletterAgreement"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledCheckbox
            onChange={onChange}
            value={value}
            label={renderNewsletterAgreementLabel()}
          />
        )}
      /> */}
      <StyledButton
        label={t("loginScreen.register")}
        onClick={handleSubmit(onSubmit)}
        type="contained"
        buttonType="submit"
      />
    </form>
  );
};

export default RegistrationForm;
