const html = `<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.1pt; border: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="text-align: center; border: none;"><strong><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto; color: black;">Zadania szczeg&oacute;łowe członk&oacute;w zespołu realizującego projekt z obszaru e-commerce</span></strong></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p><strong><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto;">Stanowisko</span></strong></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><strong><span style="font-family: Roboto;">Zadania</span></strong></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Senior backend developer</span></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Integracja wybranej platformy e-commerce z systemem ERP i WMS klienta</span></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">UX designer</span></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Przygotowanie projekt&oacute;w gł&oacute;wnych ekran&oacute;w sklepu internetowego (makiety klikalne)</span></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Senior backend developer</span></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Prace w zakresie utworzenia i dostosowania platformy e-commerce do indywidualnych potrzeb klienta (wdrożenie niezbędnych moduł&oacute;w)</span></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">UI designer</span></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Przygotowanie designu sklepu (grafiki dostosowane do ekran&oacute;w: mobile, tablet, desktop) na podstawie makiet </span></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Mid frontend developer</span></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Wdrożenie designu (frontendu sklepu internetowego)</span></p>
</td>
</tr>
<tr>
<td style="width: 226.55pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">QA Engineer</span></p>
</td>
<td style="width: 226.55pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="302">
<p style="border: none;"><span style="font-family: Roboto;">Testy funkcjonalne i wydajnościowe</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
