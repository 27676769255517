const html = `<p style="text-align: justify;">Wyr&oacute;żnić możemy trzy gł&oacute;wne sposoby dostarczania i realizacji prac IT przez wykonawc&oacute;w tych usług. Określić je też można jako modele realizacji prac IT, determinujące zasady wsp&oacute;łpracy i rozliczeń z klientem. Są to:</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Outsourcing i body leasing pracownik&oacute;w IT</p>
<p style="text-align: justify;">Body leasing pracownik&oacute;w IT (outsourcing kandydat&oacute;w do innych podmiot&oacute;w) to wypożyczenie ekspert&oacute;w do realizacji projektu klienta. Stosuje się go, gdy firma realizująca projekt nie posiada pracownik&oacute;w z określonymi kompetencjami. Body leasing to dosłownie wypożyczenie pracownika na określony czas i nie ma znaczenia (z punktu widzenia zawieranej umowy), czym pracownik będzie się zajmował oraz jakie cele osiągnie. Przesłanki zastosowania body leasingu to m.in.:</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;realizacja czasowych projekt&oacute;w, wykraczających poza standardową działalność przedsiębiorstwa</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;zatrudnienie pracownika na stałe nie ma sensu (wymagałoby przeprowadzenia drogiego i czasochłonnego procesu rekrutacji)</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;potrzebne jest świeże spojrzenie na &bdquo;projekt&rdquo;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;potrzeba czasowego zwiększenia możliwości przerobowych/wdajności pracy&nbsp;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;brak w strukturach wewnętrznych bardzo konkretnych umiejętności</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Model Fixed Price&nbsp;</p>
<p style="text-align: justify;">Fixed price to model wsp&oacute;łpracy, w kt&oacute;rym z g&oacute;ry, na etapie negocjacji umowy ustala się stałą cenę za realizację całości projektu, wynikającą z przyjętego zakresu prac i harmonogramu. Przy takich założeniach relatywnie duże ryzyko ponosi wykonawca prac, szczeg&oacute;lnie w przypadku braku właściwego zrozumienia potrzeby klienta i szczeg&oacute;łowego opisu produktu na etapie wyceny. Z czasem okazać się może, że z r&oacute;żnych przyczyn prace są bardziej skomplikowane niż zakładano, a co za tym idzie wymagają większej liczby godzin pracy zespołu. Klienci zabezpieczają się na wypadek niedotrzymania warunk&oacute;w umowy przewidująć nakładanie kar pienięznych. Wykonawca, może także w umowie przewidzieć możliwość zmiany ceny w sytuacji zaistnienia określonych przesłanek.&nbsp;</p>
<p style="text-align: justify;">Przesłanki zastosowania modelu wsp&oacute;łpracy na zasadach fixed price to m.in.:&nbsp;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;jasno ustalone priorytety klienta</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;możliwość dokładnego oszacowanie całkowitego kosztu projektu</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;prefeerencje klienta, kt&oacute;remu zależy na przeniesieniu odpowiedzialności za projekt na partnera technologicznego, rozliczanego z efektu finalnego</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Model Time and Material (T&amp;M)</p>
<p style="text-align: justify;">Time and Material to podejście do realizacji prac IT, w kt&oacute;rym zamiast określenia stałej ceny za realizację projektu, umowa określa przybliżony zakres i czacochłonność prac, członk&oacute;w zesp&oacute;ł developerskiego wraz z wyceną ich stawek godzinowych i ew. koszty dodatkowych materiał&oacute;w/infrastruktury. Time and Material to model wsp&oacute;łpracy, w kt&oacute;rym klient płaci za faktycznie wykonaną przez specjalist&oacute;w pracę. Jeśli założony zakres prac nie będzie wystarczający do realizacji założonego celu, klient może zdecydować, czy chce przedłużyć wsp&oacute;lpracę i zrealizować dodatkowe prace. Przesłanki zastosowania tego modelu to m.in.:</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Wymagania i priorytety klienta są zmienne i trudne lub niemożliwe do ustalenia na początkowym etapie</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Nie jest możliwe dokładne oszacowanie całkowitego koszt projektu</p>
<p style="text-align: justify;">● &nbsp; &nbsp;Klient chce mieć swobodę do zmiany specyfikacji projektu (np. dodania nowych element&oacute;w)</p>`;

export default html;
