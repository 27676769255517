import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PageNotFoundScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="pageNotFoundScreen">
      <div className="header">{"Ups, nie ma takiej strony"}</div>
      <div className="info">
        {"Przejdź do "}
        <a onClick={() => navigate("/dashboard")}>{"strony głównej"}</a>
        {" aplikacji lub "}
        <a onClick={() => navigate("/login")}>{"strony logowania"}</a>
        {"."}
      </div>
    </div>
  );
};

export default PageNotFoundScreen;
