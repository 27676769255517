const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać i uzyskać podczas spotkania rozpoczynającego realizację projektu z obszaru cloud computing</span></strong></p>
</td>
</tr>
<tr style="height: 1.8085937499999964pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Gł&oacute;wnym celem projektu jest wdrożenie platformy chmurowej do zapewnienia pracy zespołowej w Waszej firmie. Narzędzie pozwoli Wam na skuteczniejszą wsp&oacute;łpracę i komunikację wewnętrzną i z klientami. Możliwa będzie też praca w systemie zdalnym.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 55.68874104817708pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Gł&oacute;wne zadania projektowe w projekcie zakładają wykonanie następujących prac:</span></p>
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza wymagań szczeg&oacute;łowych.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Konfiguracja dostępu do platformy do pracy zdalnej.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie aplikacji wszystkich pracownik&oacute;w firmy.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Migracja poczty firmowej do chmury.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szkolenie dla pracownik&oacute;w.</span></li>
</ol>
</td>
</tr>
<tr style="height: 12pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zaprezentuję teraz harmonogram projektu wraz z zakładanymi przez nas terminami. Za chwilę om&oacute;wimy kto jest odpowiedzialny za poszczeg&oacute;lne zadania.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oto dwaj pracownicy naszej firmy, kt&oacute;rzy wdrażać będą rozwiązanie w Waszej organizacji.</span></p>
</td>
</tr>
<tr style="height: 17.25pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oto macierz RACI. Przeanalizujemy teraz zakresy odpowiedzialności poszczeg&oacute;lnych os&oacute;b w projekcie.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Za komunikację w projekcie odpowiadać będą: po stronie wykonawcy &ndash; Architekt modern workplace, po stronie klienta &ndash; Asystentka zarządu. Kanały komunikacji to e-mail oraz telefon. Raz w tygodniu odbywać się będą spotkania statusowe, celem zrealizowanych prac oraz zaplanowania działań na najbliższy tydzień. Om&oacute;wimy też przyczyny pojawiających się ewentualnych op&oacute;źnień i problem&oacute;w. Spotkania odbywać się będą w siedzibie klienta, a po wdrożeniu narzędzia do telekonferencji online.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Już dzisiaj proponuję, byśmy um&oacute;wili się na spotkanie analityczne, celem ustalenia szczeg&oacute;ł&oacute;w. Proszę też o przygotowanie listy pracownik&oacute;w, kt&oacute;rzy mają mieć dostęp do narzędzia, ustalimy na spotkaniu zakresy ich dostęp&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 15.75pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Pozostawiam dane do kontaktu. Po spotkaniu wyślę notatkę podsumowującą podjęte ustalenia.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
