import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GameType from "../../../../../interfaces/game/GameType";
import StyledTextButtonWithIcon from "../../../../components/StyledTextButtonWithIcon";
import CopyIcon from "../../../../../assets/images/icons/copy_icon.svg";
import DeleteIcon from "../../../../../assets/images/icons/delete_icon_red.svg";
import MainContext from "../../../../../context/MainContext";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CategoryMockType from "../../../../../interfaces/game/CategoryMockType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../../../store/store";
import GameMockType from "../../../../../interfaces/game/GameMockType";
import StyledButton from "../../../../components/StyledButton";
import { useNavigate } from "react-router-dom";
import { deleteGame } from "../../../../../services/game.service";
import { setInfoMessage } from "../../../../../reducers/message.reducer";
import { GameTypeType } from "../../../../../enums/GameTypeType";

interface Props {
  game: GameType;
  open: boolean;
  setOpen: (value: string) => void;
}

const GameRow = (props: Props) => {
  const { t } = useTranslation();
  const { game, open, setOpen } = props;
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { showDefaultModal, filtersGameType } = mainContext;
  const [gameData, setGameData] = useState<GameMockType | null>(null);
  const [categoryData, setCategoryData] = useState<CategoryMockType | null>(
    null
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  useEffect(() => {
    if (gamesData) {
      const categoryData = gamesData.find(
        (category) => game.categoryKey === category.key
      );
      if (categoryData) {
        setCategoryData(categoryData);
        let gameData = categoryData.games.find((item) => game.key === item.key);
        if (gameData) {
          gameData = {
            ...gameData,
            subtasks: gameData.subtasks.filter((item) =>
              game.gameSubtasks.some((subtask) => subtask === item.key)
            ),
          };
          setGameData(gameData);
        }
      }
    }
  }, [gamesData, game]);

  const handleGameDelete = () => {
    showDefaultModal({
      type: "warning",
      header: "Usuń grę",
      text: `Czy na pewno chcesz usunąć grę ${
        gameData!.subtasks.length === 1
          ? gameData!.subtasks[0].title
          : gameData!.gameTitle
      }?`,
      submitLabel: "Potwierdź",
      onSubmit: () =>
        dispatch(
          deleteGame({
            id: game.id,
            userId,
            userCreatorId: game.userCreatorId!,
          })
        ),
    });
  };

  const bottomInfo = (
    title: string,
    value: string,
    isButton: boolean,
    onClick?: () => void
  ) => {
    return (
      <div className="bottomInfoDiv">
        <span className="title">{title}</span>
        {isButton && onClick ? (
          <StyledButton type="text" label={value} onClick={onClick} />
        ) : (
          <span className="value">{value}</span>
        )}
      </div>
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/game/${game.id}`);
    dispatch(setInfoMessage(t("global.copyLinkSuccess")));
  };

  return (
    <React.Fragment>
      {gameData ? (
        <>
          <TableRow className="groupRow">
            <TableCell component="th" scope="row">
              <div className="groupHeader">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(open ? "" : game.id)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                {gameData.subtasks.length === 1
                  ? gameData.subtasks[0].title
                  : gameData.gameTitle}
              </div>
            </TableCell>
            {filtersGameType === GameTypeType.CONFIGURED && (
              <TableCell component="th" scope="row">
                <div className="">
                  {game.description ? game.description : "-"}
                </div>
              </TableCell>
            )}
            <TableCell component="th" scope="row">
              <div className="buttons">
                <StyledTextButtonWithIcon
                  label="Skopiuj link"
                  onClick={copyLink}
                  icon={CopyIcon}
                />
                {game.userCreatorId && (
                  <StyledTextButtonWithIcon
                    label="Usuń link"
                    onClick={handleGameDelete}
                    color="error"
                    icon={DeleteIcon}
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="gameData">
                  <span className="header">
                    {"Cele edukacyjne realizowane przez grę:"}
                  </span>
                  {gameData.subtasks.map((subtask) => (
                    <span className="subtask">{subtask.title}</span>
                  ))}
                  <div className="bottomInfoWrapper">
                    {bottomInfo(
                      "Branża dotycząca gry:",
                      t(`industries.${gameData.sectorKey}`),
                      false
                    )}
                    {bottomInfo(
                      "Technologia cyfrowa omawiana w grze:",
                      categoryData ? categoryData.categoryName : "-",
                      false
                    )}
                    {bottomInfo(
                      "Szczegóły gry:",
                      "Przejdź do opisu",
                      true,
                      () => navigate(`/game/${game.id}`)
                    )}
                  </div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : (
        <>{"Loading"}</>
      )}
    </React.Fragment>
  );
};

export default GameRow;
