import React from "react";
import { useTranslation } from "react-i18next";
import GroupIcon from "../../../../../assets/images/icons/group_icon.svg";
import StyledButton from "../../../../components/StyledButton";

interface Props {
  text: string;
  withButton?: boolean;
  onClick?: () => void;
}

const EmptyInfoComponent = (props: Props) => {
  const { t } = useTranslation();
  const { text, onClick, withButton } = props;

  return (
    <div className="emptyInfoComponent">
      <img src={GroupIcon} alt="group_icon" />
      <span className="text">{text}</span>
      {withButton && (
        <StyledButton
          label={t("organizationPanelScreen.createGroup")}
          onClick={onClick}
          type="contained"
          color="dark"
        />
      )}
    </div>
  );
};

export default EmptyInfoComponent;
