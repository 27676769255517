const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="5" style="border: 1px solid rgb(0, 0, 0);">
                    <p>Harmonogram realizacji projektu z obszaru marketingu cyfrowego</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Nazwa zadania projektowego (kamienie milowe projektu) &nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Nr tygodnia, w kt&oacute;rym następuje rozpoczęcie zadania&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Ilość tygodni trwania zadania&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Skład osobowy zespołu realizującego zadanie &nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Efekt realizacji zadania</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Audyt kompetencji zespołu klienta&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Senior HR Specialist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raport z audytu wraz z rekomendacjami</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Audyt zasob&oacute;w marketingowych klienta (www, branding)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Digital Strategist&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raport z audytu wraz z rekomendacjami&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie strategii marki i gł&oacute;wnych założeń komunikacji marki</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>2</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Digital Strategist&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Dokument z opisem strategii&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie ramowego planu działań na 6 m-cy</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>2</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>2</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Digital Strategist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Dokument zawierający ramowy plan działania&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zrekrutowanie wykonawc&oacute;w i wdrożenie narzędzi</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>4</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>4</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM, Senior Digital Marketing Specialist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Lista wymaganych wykonawc&oacute;w/kompetencji&nbsp;</p>
                    <p>Zapytania ofertowe na wykonawc&oacute;w&nbsp;</p>
                    <p>Lista rekomendowanych narzędzi&nbsp;</p>
                    <p>Podpisane umowy wykonawcze Zakupione i gotowe do działania narzędzia</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Optymalizacja proces&oacute;w sprzedażowych prowadzonych przez klienta w kanałach cyfrowych (www, marketplace &ndash; poprawa błęd&oacute;w ujawnionych w audycie)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>5</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>3</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Senior Digital Marketing Specialist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Wdrożone optymalizacje sugerowane we wcześniejszym audycie</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Produkcja treści (na potrzeby content marketingu)</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>8</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>24</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM,Wykonawca zewnętrzny</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Treści gotowe do publikacji</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Prowadzenie działań komunikacyjnych w social mediach (publikacje, moderacja)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>9</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>24</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM,Wykonawca zewnętrzny</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Działania przeprowadzone zgodnie z założeniami&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Przeprowadzenie kampanii płatnych w social mediach &nbsp;(socialads, zasięgowe, wizerunkowe/sprzedażowe, remarketingowe)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>9</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>24</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM, Wykonawca zewnętrzny</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Kampanie przeprowadzone zgodnie z założeniami&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raportowanie okresowe (milestones x3 w trakcie 6 miesięcy)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>14</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM, Wykonawca zewnętrzny</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raporty okresowe&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie raportu końcowego</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>30&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM, Senior Digital Marketing Specialist, Digital Strategist&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raport końcowy&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
