import { useTranslation } from "react-i18next";
import LinkTextButton from "../../components/LinkTextButton";
import LoginScreenForm from "./LoginScreenForm";

const LoginScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="loginScreen">
      <div className="logoWrapper">
        <img
          className="logo"
          alt="DigiLeader"
          src="https://digileader.cloud/wp-content/uploads/2021/05/digi-leader-logo.png"
        />
      </div>
      <div className="welcomeTextWrapper">
        <span>{t("loginScreen.welcome")}</span>
        <span className="small">{t("loginScreen.welcomeStar")}</span>
      </div>
      <LoginScreenForm />
      <div className="noAccountContainer">
        <div className="forgotYourPassword">
          <LinkTextButton
            label={t("loginScreen.forgotYourPassword")}
            href="reset-password"
          />
        </div>
        <div>
          <span>{t("loginScreen.noAccount")}</span>
          <LinkTextButton label={t("loginScreen.register")} href="register" />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
