const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Przebieg spotkania kończącego realizację projektu z obszaru marketingu cyfrowego</strong></p>
                    <p><strong>(wykaz poruszanych zagadnień)</strong></p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>NASZE OSIĄGNIĘCIA&nbsp;</p>
                    <p>1.CELE SPRZEDAŻOWE - Z NADWYŻKĄ</p>
                    <p>2.MEDIA i NARZĘDZIA GOTOWE DO PRACY</p>
                    <p>3.PROCEDURY i DOBRE PRAKTYKI</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>DOBRE PRAKTYKI DALSZEGO KORZYSTANIA Z WDROŻONYCH NARZĘDZI: GOOGLE ANALYTICS</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>DOBRE PRAKTYKI DALSZEGO KORZYSTANIA Z WDROŻONYCH NARZĘDZI: NARZĘDZIA DO MONITORINGU INTERNETU</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>DOBRE PRAKTYKI DALSZEGO KORZYSTANIA Z WDROŻONYCH NARZĘDZI: MENEDŻER FIRMY I USTAWIENIA FIRMOWE NA PLATFORMACH SPOŁECZNOŚCIOWYCH&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
