const html = `<h3 style="text-align: center;"><strong>CASE-STUDY 1</strong></h3>
<p><strong>Tytuł projektu: Połączenie IoT i AI w celu poprawy jakości życia senior&oacute;w</strong><span style="font-weight: 400;">&nbsp;</span></p>
<p><strong>Gł&oacute;wny problem klienta (instytucji rządowej): </strong><span style="font-weight: 400;">Samotność ludzi starszych i spowodowana nią bezradność w codziennym życiu&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na zainstalowaniu w mieszkaniach senior&oacute;w czujnik&oacute;w z obszaru home automation. Czujniki dostarczać miały informacji na temat bieżącego zachowania osoby obserwowanej, np. czujniki nacisku w materacu generują dane o czasie i jakości snu, czujniki dotykowe zbierają dane o tym, czy okna są otwarte, czujniki zbliżeniowe &ndash; określają czy osoba znajduje się w domu, czujniki nacisku na toalecie. Wszystkie zebrane dane były analizowane przez sztuczną inteligencję, dostarczaną przez firmę IBM &ndash; Watson. Watson analizując dane wypracowuje pewne wzorce, kt&oacute;re stanowią punkt odniesienia do &bdquo;normalnego&rdquo; zachowania seniora. Watson wykrywa odchylenia od wzorca. Odchylenia są kategoryzowane ze względu na ich wpływ na standard życia lub nawet zagrożenie życia podopiecznego. Dany rodzaj odchylenia generuje podjęcie określonego działania przez system &ndash; od pr&oacute;by kontaktu po poinformowanie bliskich o gorszym stanie seniora, aż po powiadomienie służb.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Poprawa jakości życia senior&oacute;w, spadek koszt&oacute;w opieki nad seniorami&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">IoT, chmura, Watson, sensory home automation&nbsp;</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">12 miesięcy&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">PM (1FTE), Machine Learning Engineer (3FTE), Data Engineer (1FTE), Developer (4FTE), Tester (2FTE) Architekt IoT (1FTE) Automation Engineer (2FTE)</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">10 mln zł</span></p>`;

export default html;
