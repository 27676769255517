const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada usługa marketingu cyfrowego</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <ul>
                        <li>
                            <p>audyt działań prowadzonych w SM (social media) oraz opracowanie strategii komunikacji w SM</p>
                        </li>
                        <li>
                            <p>kampania (działania marketingowe) nastawiona na osiągnięcie konkretnych cel&oacute;w</p>
                        </li>
                        <li>
                            <p>audyt strony internetowej z ofertą sprzedaży i możliwością zakupu aplikacji&nbsp;</p>
                        </li>
                        <li>
                            <p>wyb&oacute;r wykonawcy działań płatnych w mediach społecznościowych oraz zrekrutowanie osoby do koordynacji kampanii w social media (SM)</p>
                        </li>
                        <li>
                            <p>przeprowadzenie analizy konkurencji oraz wdrożenie narzędzi do monitorowania internetu pod kątem komunikacji konkurencji i jej klient&oacute;w</p>
                        </li>
                        <li>
                            <p>rozw&oacute;j wewnętrznych kompetencji zespołu w zakresie marketingu cyfrowego</p>
                        </li>
                        <li>
                            <p>identyfikacja os&oacute;b odpowiedzialnych za proces decyzyjny przy wyborze narzędzi onboardingowych oraz skierowanie komunikat&oacute;w marketingowych do zidentyfikowanych os&oacute;b decyzyjnych</p>
                        </li>
                        <li>
                            <p>zawężenie grupy docelowej kampanii do wskazanych przez klienta podmiot&oacute;w</p>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
