const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<h3><strong>Agenda/struktura spotkania rozpoczynającego realizację projektu z obszaru Big Data (kick-off meeting)&nbsp;</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Wykaz temat&oacute;w do om&oacute;wienia&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Cel projektu&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Poznajmy się &ndash; zesp&oacute;ł projektu&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Harmonogram i terminy w projekcie&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Podział pracy i odpowiedzialności w projekcie (RACI)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dostęp do system&oacute;w klienta&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zasady komunikacji zespołu projektowego</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Harmonogram spotkań&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dziękuję!</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
