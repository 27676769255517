const html = `<p><strong>CASE STUDY PROJEKTU&nbsp;</strong></p>
<h4><strong>Tytuł projektu: Aplikacja do prognozowania popytu na wyroby cukiernicze wiodącego polskiego producenta wykorzystującej model ML</strong></h4>
<p>&nbsp;</p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">duże wahania sezonowe w wolumenach wielkości sprzedaży powodowały znaczne problemy w zakresie planowania produkcji, zakup&oacute;w, logistyki i zatowarowania, zwiększając tym samym koszty operacyjne.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na zbudowaniu aplikacji umożliwiającej dostęp do danych w zakresie prognoz popytu na określone produkty w okresie na kolejne 12 miesięcy. Celem klienta było zoptymalizowanie koszt&oacute;w poprzez efektywniejsze zarządzanie zasobami oraz zwiększenie satysfakcji klient&oacute;w dzięki dopasowaniu oferty i dostępności produkt&oacute;w oczekiwanych przez klient&oacute;w. Klienta posiadał już hurtownię danych w kt&oacute;rej gromadził wiele danych sprzedażowych.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">niższe koszty prowadzenia działalności, zwiększenie zadowolenia klient&oacute;w i efektywności decyzji zarządczych&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Aplikacja webowa (SAP Fiori) oraz backend w AMDP wykorzystująca indywidualnie dopasowany do danych klienta model prognostyczny (proof of value) z wykorzystaniem bibliotek SAP PAL (predicitve analytics library).&nbsp; Aplikacja wykorzystuje hurtownię danych klienta (SAP HANA). PoV stworzone zostało w języku Python z wykorzystaniem bibliotek ML.&nbsp;</span></p>
<p><strong>Czas trwania projektu</strong><span style="font-weight: 400;">: 7 miesięcy&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">PM (0,25 FTE),</span> <span style="font-weight: 400;">Machine Learning Engineer (0,5FTE), SAP Data Engineer (1 FTE), SAP Frontend Developer (0,5FTE), Tester (0,25 FTE)</span></p>
<p><strong>Budżet projektu: 775 tys. zł</strong></p>`;

export default html;
