const html = `<h3 style="text-align: center;"><strong>Wykaz technologii mających zastosowanie w projektach blockchain wraz z opisem i przykładami</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Technologia&nbsp;</strong></p>
</td>
<td>
<p><strong>Opis technologii&nbsp;</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Protok&oacute;ł sieci blockchain typu permissioned, umożliwiający zbudowanie prywatnej sieci węzł&oacute;w</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia tworzenie zdecentralizowanych, niezawodnych i bezpiecznych system&oacute;w, dostępnych wybranej grupie użytkownik&oacute;w, do przeprowadzania i rejestrowania transakcji, bez konieczności polegania na zaufanej trzeciej stronie.&nbsp;</span></p>
<p><span style="font-weight: 400;">Protok&oacute;ł sieci blockchain to zestaw reguł rządzących interakcjami między węzłami (np. komputerami) w sieci blockchain. Określa on w jaki spos&oacute;b transakcje są walidowane, zapisywane i łączone w bloki.</span></p>
<p><span style="font-weight: 400;">Protok&oacute;ł typu permissioned pozwala na podłączenie się do istniejącej sieci lub zbudowanie własnej, prywatnej (konsorcjalnej) sieci. Po zbudowaniu infrastruktury ponoszone są wyłącznie koszty utrzymania infrastruktury (zależne od wolumenu zapisywanych danych).</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Hyperledger Fabric, R3 Corda, Quorum , Private Ethernum, Private Network, Multichain, Parity.</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Język programowania Golang (język rozwijany przez Google) lub JS/ Java (mniej wydajny)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">IDE (MS Visual Sudio)</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Infrastruktura CPU/RAM (pamięć operacyjna maszyn wirtualnych)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia wykonywanie pracy wielu system&oacute;w operacyjnych jednocześnie, izolację aplikacji, testowanie w bezpiecznym środowisku i łatwe tworzenie kopii zapasowych.</span></p>
<p><span style="font-weight: 400;">Maszyna wirtualna to oprogramowanie emulujące fizyczny komputer, kt&oacute;re może uruchamiać system operacyjny i aplikacje jak rzeczywisty komputer.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> VMware Workstation 6.5.2, Azure Virtual Machine, Amazon Web Services (AWS) Virtual Machine (EC2)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Obsługa konfigurator&oacute;w do zarządzania usługami poszczeg&oacute;lnych dostawc&oacute;w chmury</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Orkiestrator do zarządzania kontenerami</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia skalowanie i zarządzanie aplikacjami opartymi na kontenerach, automatyczne rozmieszczenie.</span></p>
<p><span style="font-weight: 400;">Orkiestrator kontener&oacute;w to zestaw narzędzi, kt&oacute;re zapewniają mechanizmy wdrażania, skalowania, uruchamiania aplikacji zgodnie z wytycznymi. To narzędzie służące do automatyzacji, koordynowania i zarządzania życiem kontener&oacute;w, kt&oacute;re są jednostkami oprogramowania zawierającymi kod i wszystkie jego zależności. Orkiestrator dokonuje replikacji kontener&oacute;w, a także daje możliwość zarządzania ich dostępnością i obciążeniem sieci.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span><strong>Przykłady:</strong><span style="font-weight: 400;"> Kubernetes, Rachner, Openshift</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Konfigurowanie narzędzi i mikroserwis&oacute;w w środowisku chmurowym</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Golang</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Zarządzanie materiałem kryptograficznym</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie do zarządzania materiałem kryptograficznym HSM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia bezpieczne generowanie, przechowywanie i zarządzanie kluczami kryptograficznymi.</span></p>
<p><span style="font-weight: 400;">Sprzętowe moduły zabezpieczające (HSM) to fizyczne urządzenia dedykowane do zarządzania kluczami kryptograficznymi i przyspieszania operacji kryptograficznych. Zabezpieczają materiał kryptograficzny przed nieautoryzowanym dostępem. Umożliwiają także przeprowadzanie operacji kryptograficznych w środowisku odizolowanym od reszty systemu.</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Thales nShield, Yubico YubiHSM, Utimaco SecurityServer</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Obsługa narzędzi typu HSM</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Aplikacja do uwierzytelniania użytkownik&oacute;w</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia centralne zarządzanie tożsamościami użytkownik&oacute;w, kontrolę dostępu do zasob&oacute;w, delegowanie uprawnień, a także integrację z innymi usługami i aplikacjami.</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">AD MS, OpenLDAP, Red Hat Directory Server, Novell eDirectory</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Obsługa konfiguratora danego narzędzie do uwierzytelniania&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Protok&oacute;ł sieci blockchain oparty o publiczną sieć (permission less), wymagający użycia kryptowalut</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia tworzenie zdecentralizowanych, i bezpiecznych system&oacute;w do przeprowadzania i rejestrowania transakcji, dostępnych otwartej grupie użytkownik&oacute;w.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Nie wymaga konieczności polegania na zaufanej trzeciej stronie. Protok&oacute;ł sieci blockchain to zestaw reguł rządzących interakcjami między węzłami w sieci blockchain, określający, jak transakcje są walidowane, zapisywane i połączone w bloki.</span></p>
<p><span style="font-weight: 400;">Protok&oacute;ł permissionless, czyli publiczny funkcjonuje w ten spos&oacute;b, że każdy obiekt wrzucony do bazy jest widoczny dla wszystkich użytkownik&oacute;w sieci. Oznacza to, że np. wchodząc do blockchain explorer mogę zobaczyć wszystkie transakcje, wszystkich uczestnik&oacute;w sieci. W przypadku protokołu publicznego, wrzucanie kolejnych danych zwiększa koszty utrzymania sieci. Trudno przewidzieć koszty transakcyjne ponoszone w kryptowalucie, kt&oacute;rej wahania są znaczne. Największe wsparcie ze strony community oferuje sieć Ethereum, jest to&nbsp; największa sieć tego typu. Daje możliwość uruchomienia smart kontrakt&oacute;w i integracji z bazą danych IPFS.&nbsp; W przypadku Bitcoin występuje problem skomplikowanej obsługi smart kontrakt&oacute;w.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Bitcoin, Ethereum, Solana , Riple (nie obsługuje smart kontrakt&oacute;w)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Język programowania Solidity</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">IDE (visual studio code &ndash;MS)</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Baza danych IPFS (interplanetary file system)&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia efektywne i niezawodne przechowywanie i dostęp do danych, zastępując tradycyjne adresy URL.</span></p>
<p><span style="font-weight: 400;">InterPlanetary File System (IPFS) to protok&oacute;ł i sieć peer-to-peer służące do przechowywania i udostępniania danych w rozproszonym systemie. W odr&oacute;żnieniu od bazy typu rozproszonego nie wymaga postawienia kilku węzł&oacute;w administrator&oacute;w. IPFS oparte jest na lokalizacji, zastępuje tradycyjne adresy URL przez adresy oparte na zawartości, co zapewnia odporność na awarie i cenzurę. IPFS zapewnia możliwość wrzucania danych do rozproszonej bazy danych (P2P). Po wrzuceniu danych przesyłane są one do wszystkich węzł&oacute;w (komputer&oacute;w użytkownik&oacute;w). Korzystanie z bazy wymaga wyłączenie instalacji nowego klienta.</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;"> Obsługa baz danych typu IPFS</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
