const html = `<h3 style="text-align: center;"><strong>Opis propozycji usług po wdrożeniu rozwiązania IoT wraz z uzasadnieniem</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja usługi/rozwiązania technologicznego&nbsp;</strong></p>
</td>
<td>
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie analityki danych opartej na sztucznej inteligencji</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Mając wszystkie dostępne dane, naukowcy od danych mogą zacząć szukać korelacji i zbudować modele usprawniające pracę farmy w jeszcze większym stopniu.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Optymalizacja procesu produkcji</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozpoznanie niekt&oacute;rych problem&oacute;w i osadzenie ich w kontekście np. warunk&oacute;w automatycznych może pom&oacute;c je wyeliminować poprzez np. zmianę procesu produkcji i zastosowanie innych podzespoł&oacute;w.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Automatyzacja procesu zamawiania części zamiennych</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Proces przewidywania konserwacji może być w pełni zautomatyzowany poprzez integrację z systemami dostawc&oacute;w części. W ten spos&oacute;b można skompletować wszystkie potrzebne elementy w najbardziej optymalnym momencie czasu, unikając przestoj&oacute;w.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
