import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { categoriesImages } from "../../../constants/games";
import GameType from "../../../interfaces/game/GameType";
import {
  add_editUserGame,
  getAverageSubtasksResults,
  getGames,
  getUserGames,
} from "../../../services/game.service";
import { AppDispatch, AppState } from "../../../store/store";
import ControllerImage from "../../../assets/images/controllerImage.svg";
import StyledButton from "../../components/StyledButton";
import StyledOptionsSwitch from "../../components/StyledOptionsSwitch";
import StyledIconButton from "../../components/StyledIconButton";
import { gamesModes } from "../../../constants/mocks";
import GameModeSpectificationRow from "./GameModeSpectificationRow";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import GameMockType from "../../../interfaces/game/GameMockType";
import ClockIcon from "../../../assets/images/icons/clock_icon.svg";
import ArrowDownIcon from "../../../assets/images/icons/arrow_down_icon.svg";
import EducationalGoalsAccordion from "./EducationalGoalsAccordion";
import ScoresAccordion from "./ScoresAccordion";
import KnowledgeBasesAccordion from "./KnowledgeBasesAccordion";
import CoursesAccordion from "./CoursesAccordion";
import UserGameScoreType from "../../../interfaces/game/UserGameScoreType";
import ReportsAccordion from "./ReportsAccordion";
import FavouriteFullIcon from "../../../assets/images/icons/favourite_full_icon.svg";
import FavouriteEmptyIcon from "../../../assets/images/icons/favourite_empty_icon.svg";
import PadlockIcon from "../../../assets/images/icons/padlock_icon.svg";
import UserGameType from "../../../interfaces/game/UserGameType";
import { RoleType } from "../../../enums/RoleType";

const GameScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const isFetchingGamesRef = useRef<boolean>(true);
  const isFetchingUserGamesRef = useRef<boolean>(true);
  const addIsGivenRef = useRef<boolean | null>(null);
  const gameRef = useRef<GameType | null>(null);
  const [gameData, setGameData] = useState<GameMockType | undefined>(undefined);
  const [gameType, setGameType] = useState<GameType | undefined>(undefined);
  const [selectedGameMode, setSelectedGameMode] = useState<string>(
    gamesModes[0].key
  );
  const [category, setCategory] = useState<CategoryMockType | undefined>(
    undefined
  );
  const [game, setGame] = useState<UserGameScoreType | null>(null);

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const isFetchingGames: boolean = useSelector(
    (state: AppState) => state.gameReducer.isFetchingGames
  );

  const isFetchingUserGames: boolean = useSelector(
    (state: AppState) => state.gameReducer.isFetchingUserGames
  );

  const error: any = useSelector((state: AppState) => state.gameReducer.error);

  const isFetching: boolean = useSelector(
    (state: AppState) => state.gameReducer.isFetchingGames
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const userGames: UserGameType[] = useSelector(
    (state: AppState) => state.gameReducer.userGames
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  useEffect(() => {
    if (!isFetchingGames && isFetchingGamesRef.current) {
      isFetchingGamesRef.current = isFetchingGames;
    }
    isFetchingGamesRef.current = isFetchingGames;
  }, [isFetchingGames]);

  useEffect(() => {
    isFetchingGamesRef.current = isFetchingGames;
  }, [isFetchingGames]);

  useEffect(() => {
    isFetchingUserGamesRef.current = isFetchingUserGames;
  }, [isFetchingUserGames]);

  useEffect(() => {
    dispatch(getGames(true, true));
  }, []);

  useEffect(() => {
    if (userId && dispatch) {
      dispatch(getUserGames(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (
      !isFetchingUserGamesRef.current &&
      !isFetchingGamesRef.current &&
      userId &&
      id &&
      games &&
      userGames &&
      !game
    ) {
      const gameType = games.find((item) => item.id === id)!;
      const userGame = userGames.find((userGame) => userGame.gameId === id);
      setGameType(gameType);
      if (
        gameType &&
        gameType.userCreatorId &&
        gameType.userCreatorId !== userId &&
        !userGame
      ) {
        isFetchingGamesRef.current = isFetchingGames;
        addIsGivenRef.current = true;
        setGameGiven();
      } else addIsGivenRef.current = false;
    }
  }, [
    userGames,
    game,
    games,
    id,
    userId,
    dispatch,
    isFetchingUserGamesRef,
    isFetchingGamesRef,
  ]);

  const setGameGiven = async () => {
    const gameType = games!.find((item) => item.id === id)!;
    await dispatch(
      add_editUserGame({
        userId,
        gameId: gameType.id,
        isGiven: true,
        isFavourite: false,
        isFinished: false,
        isRecommended: false,
        isStarted: false,
      })
    );
  };

  useEffect(() => {
    if (
      games &&
      !isFetching &&
      !isFetchingGamesRef.current &&
      !isFetchingUserGamesRef.current &&
      addIsGivenRef.current !== null
    ) {
      if (id && !isFetching) {
        const game = games.find((item) => item.id === id);
        if (game) {
          setGame(game.userGame);
          gameRef.current = game;
          const category = gamesData.find(
            (category) => category.key === game.categoryKey
          )!;
          let gameData = category.games.find((item) => item.key === game.key)!;
          setCategory(category);
          if (gameData) {
            gameData = {
              ...gameData,
              subtasks: gameData.subtasks.filter((item) =>
                game.gameSubtasks.some((subtask) => subtask === item.key)
              ),
            };
            setGameData(gameData);
            const subtasks = gameData.subtasks
              .map((subtask) => subtask.key)
              .flat();
            dispatch(getAverageSubtasksResults(subtasks));
          }
        } else navigate("/404");
      }
    } else if (error) navigate("/404");
  }, [
    id,
    games,
    navigate,
    isFetching,
    error,
    addIsGivenRef,
    isFetchingGamesRef,
    isFetchingUserGamesRef,
  ]);

  const renderModeSpecification = () => {
    const gameMode = gamesModes.find((mode) => mode.key === selectedGameMode)!;
    return gameMode.modeSpecification.map((modeSpecificationText, idx) => (
      <GameModeSpectificationRow
        key={`${gameMode.key}_${idx}`}
        text={modeSpecificationText}
      />
    ));
  };

  const renderTag = (tag: { type: string; text?: string; key?: string }) => {
    let icon: string | null = null;
    switch (tag.type) {
      case "time":
        icon = ClockIcon;
        break;
      default:
        break;
    }
    return (
      <div className="tag" key={tag.text ? tag.text : tag.key}>
        {icon && <img src={icon} alt="tagIcon" />}
        {tag.text ? tag.text : t(`industries.${tag.key}`)}
      </div>
    );
  };

  const addGameToFavourite = () => {
    if (id)
      dispatch(
        add_editUserGame({
          userId,
          gameId: id,
          isFavourite: game ? !game.isFavourite : true,
          isFinished: game ? game.isFinished : false,
          isRecommended: game ? game.isRecommended : false,
          isStarted: game ? game.isStarted : false,
          isGiven: game ? game.isGiven : false,
        })
      );
  };

  return (
    <div className="gameScreen">
      {category && gameData && (
        <>
          <div className="topSection">
            <div className="leftSide">
              <div className="categoryImage">
                {categoriesImages[category.key] && (
                  <>
                    <img
                      src={categoriesImages[category.key]}
                      alt={category.key}
                    />
                    <span>{category.categoryName}</span>
                  </>
                )}
              </div>
              <div className="gameName">
                {gameData.subtasks.length === 1
                  ? gameData.subtasks[0].title
                  : gameData.gameTitle}
              </div>
              <div className="gameDescriptionHeader">
                {t("gameScreen.gameDescriptionHeader")}
              </div>
              <div className="gameDescription">
                {gameData.subtasks.length === 1
                  ? gameData.subtasks[0].description
                  : gameData.description}
              </div>
              <div className="tags">
                {gameData.tags.map((tag) => renderTag(tag))}
              </div>
            </div>
            <div className="rightSide">
              <div className="image">
                <img src={ControllerImage} alt="controllerImage" />
              </div>
              <div className="infoSection">
                {role === RoleType.USER && (
                  <div className="buttons">
                    <StyledButton
                      label={t("gameScreen.play")}
                      onClick={() =>
                        window.open(
                          `https://digileader.cloud/game?gameId=${id}&userId=${userId}&gameMode=${selectedGameMode}`,
                          "_blank"
                        )
                      }
                      type="contained"
                    />
                    <StyledIconButton
                      icon={
                        game && game.isFavourite
                          ? FavouriteFullIcon
                          : FavouriteEmptyIcon
                      }
                      onClick={addGameToFavourite}
                    />
                  </div>
                )}
                <div className="switchHeader">{"Tryb gry:"}</div>
                <StyledOptionsSwitch
                  onChange={setSelectedGameMode}
                  disabled={
                    gameRef.current
                      ? !!gameRef.current.userCreatorId
                      : undefined
                  }
                  selected={
                    gameRef.current && gameRef.current.gameMode
                      ? gameRef.current.gameMode
                      : selectedGameMode
                  }
                  options={gamesModes}
                />
                <div className="modesSpecificationHeader">
                  {t("gameScreen.thisModeIncludes")}
                </div>
                {renderModeSpecification()}
              </div>
            </div>
          </div>
          <div className="bottomSection">
            <div className="bottomSectionComponentsWrapper">
              <EducationalGoalsAccordion
                gameData={gameData}
                isConfigured={!!(gameType && gameType.userCreatorId)}
              />
              {role === RoleType.USER && (
                <ScoresAccordion gameData={gameData} gameScore={game} />
              )}
              <ReportsAccordion gameData={gameData} gameScore={game} />
              <KnowledgeBasesAccordion
                gameData={gameData}
                gameScore={game}
                categoryData={category}
              />
              <CoursesAccordion
                gameData={gameData}
                gameScore={game}
                categoryData={category}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

//TODO wyrzucić do oddzielnego pliku
export const accordionHeader = (header: string, open?: boolean) => (
  <div className="accordionHeader">
    <img className="downIcon open" src={ArrowDownIcon} alt="downIcon" />
    <span>{header}</span>
  </div>
);

export const getNoUserGameLine = (text: string) => (
  <div className="noUserGame">
    <img className="padlockIcon" src={PadlockIcon} alt="padlockIcon" />
    <span>{text}</span>
  </div>
);

export default GameScreen;
