const html = `<div dir="ltr" style="margin-left:0pt;" align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr style="height:0pt;">
                <td colspan="3" style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Technologie i narzędzia wspierające marketing cyfrowy&nbsp;</span></p><br>
                </td>
            </tr>
            <tr style="height:0pt;">
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Technologia&nbsp;</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Funkcja&nbsp;</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Wymagane kompetencje</span></p>
                </td>
            </tr>
            <tr style="height:0pt;">
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Narzędzie do analityki ruchu na stronach internetowych&nbsp;</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Umożliwia analizę aktywności użytkownik&oacute;w na stronie www, popularność poszczeg&oacute;lnych treści, pomiar konwersji [poziom zrealizowanych cel&oacute;w, np. sprzedażowych] i skuteczność zewnętrznych kampanii promocyjnych kierujących do stronę www. Zewnętrzne kampanie promocyjne to np. kampanie płatne w social media, kampania display [kampania banerowa, polegająca na wykupieniu powierzchni reklamowych np. na portalach internetowych], działania PR [public relations] linkujące do naszej strony internetowej.&nbsp;</span></p><br>
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Przykłady:</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Google Analytics 4,&nbsp;</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Obsługa i konfiguracja narzędzia Google Analytics 4. Poznanie gł&oacute;wnych funkcjonalności narzędzia wymaga przejścia kilkugodzinnego szkolenia.</span></p>
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Właściwa (optymalna) konfiguracja narzędzia wymaga doświadczenia i dodatkowych umiejętności technicznych.&nbsp;</span></p>
                </td>
            </tr>
            <tr style="height:0pt;">
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;"><br>
                    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Narzędzie do obsługi kont biznesowych na portalach społecznościowych (Business Manager/Ads Manager)</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Umożliwia odpowiednią konfigurację kont biznesowych na platformach social media [aplikacje społecznościowe takie jak Facebook, Instagram, LinkedIn, TikTok, Twitter]. Pozwala na bezpieczne zarządzanie zasobami (treściami, kontami, dostępami pracownik&oacute;w), realizację działań płatnych, zaawansowaną analitykę i udostępnianie zarządzania kanałami społecznościowymi firmy partnerom takim jak agencje reklamowe (udostępnienie agencjom możliwości publikowania treści i tworzenia reklam np. na fanpage&rsquo;u firmy [Strona, czyli wizyt&oacute;wka firmy na platformie social media].</span></p><br>
                    <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Przykłady:</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Meta Business Manager/ Menedżer Firmy Meta, TikTok Business Center etc.</span></p><br>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Obsługa i konfiguracja narzędzi. Poznanie gł&oacute;wnych funkcjonalności narzędzia wymaga przejścia kilkunastogodzinnej szkolenia, kt&oacute;re umożliwi pracownikowi firmy zarządzanie narzędziami i analizę działań w SM. Na etapie konfiguracji rekomendowane jest wsparcie zewnętrznego konsultanta.&nbsp;</span></p>
                </td>
            </tr>
            <tr style="height:0pt;">
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Narzędzie do monitoringu treści umieszczanych przez użytkownik&oacute;w na forach, platformach internetowych, w social media</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Umożliwia monitoring wszystkich publikacji o danej marce i związanych z nią tematach w mediach społecznościowych, serwisach informacyjnych, blogach, forach, stronach z recenzjami i innych mediach. Aplikacja, ze wskazaną częstotliwością, &nbsp;tworzy raporty zawierające wszystkie wzmianki o firmie pojawiające się w internecie, co m.in. pozwala firmie szybko reagować na pojawiające się treści.&nbsp;</span></p><br>
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Przykłady:</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Brand24, Sentione&nbsp;</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Przejście procesu onboardingu w aplikacji oraz podstawowa wiedza w zakresie strategii marketingowej i biznesowej firmy.&nbsp;</span></p>
                </td>
            </tr>
            <tr style="height:0pt;">
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Narzędzie wspierające SEM [Search Engine Marketing,&nbsp;</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#4d5156;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">og&oacute;ł działań promocyjnych w internecie mających na celu uzyskanie wysokich pozycji w wynikach wyszukiwania, na wybrane słowa i frazy kluczowe wpisywane przez użytkownik&oacute;w w zapytaniu do wyszukiwarki]</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;w zakresie analizy i doboru sł&oacute;w kluczowych do pozycjonowania organicznego i kampanii płatnych</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Umożliwia zbadanie jakich fraz używają użytkownicy internetu wyszukując firmę lub produkt w wyszukiwarce. Pozwala też oszacować koszty kampanii płatnych mających na celu poprawę wynik&oacute;w wyszukiwania. Narzędzie daje też możliwość zidentyfikowania problem&oacute;w klient&oacute;w związanych z tematyką naszego biznesu poprzez poznanie najczęściej zadawanych przez nich pytań. Użytkownik w narzędziu:</span></p>
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a) wpisuje np. adres strony www a narzędzie analizuje treści na stronie www i wskazuje jakich sł&oacute;w kluczowych należy użyć w treściach marketingowych mających na celu łatwiejsze znalezienie strony www w wynikach wyszukiwania&nbsp;</span></p>
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b) wpisuje słowo kluczowe i obszar geograficzny, a narzędzie wskazuje ile razy dana fraz jest wpisywana miesięcznie oraz z jakimi innymi słowami dana fraz jest wpisywana przez użytkownik&oacute;w, pozwala to lepiej zrozumieć użytkownik&oacute;w i zoptymalizować koszty marketingu w wyszukiwarce, poprzez lepsze dopasowanie reklam do wyszukiwanych potrzeb/problem&oacute;w potencjalnych klient&oacute;w&nbsp;</span></p><br>
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Przykłady:&nbsp;</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">planer sł&oacute;w kluczowych Google (bezpłatny),</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">woorank.com, senuto.com, semstorm.com, semrush.com, MajesticSEO, answerthepublic.com, ahrefs.com, , &nbsp;kwfinder.com, NEILPATEL.COM/UBERSUGGEST</span></p>
                </td>
                <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0.75pt 0.75pt 0.75pt 0.75pt;overflow:hidden;overflow-wrap:break-word;">
                    <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Roboto,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Umiejętność obsługi narzędzia i podstawowa znajomość mechanizm&oacute;w SEM i SEO [Search Engine Optimization, optymalizacji organicznych, tj. bezpłatnych wynik&oacute;w wyszukiwania].&nbsp;</span></p><br>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p style="text-align: justify;">&nbsp;</p>`;

export default html;
