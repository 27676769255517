const html = `<h3 style="text-align: center;"><strong>Wykaz stanowisk w zespole wdrażającym blockchain wraz z zakresami obowiązk&oacute;w i CV</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Stanowisko</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Specjalista ds blockchain (łączy kompetencje Eksperta technologii, Analityka biznesowego i Architekta rozwiązania)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zdefiniowanie potrzeb i wymagań klienta w kontekście rozwiązania. Przełożenie wymagań na wybory technologiczne. Zaprojektowanie architektury rozwiązania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">PM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zarządzanie całością projektu (budżet, timeline, delivery, dobranie zespołu do wykonania zaplanowanych zadań, WBS).</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">DevOps</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Przygotowanie, skonfigurowanie i zabezpieczanie dostępu do infrastruktury i środowisk deweloperskich (chmura, baza danych, serwisy chmurowe, orkiestrator, środowiska dev, testowe, prod). Skonfigurowanie komunikacji sieciowej i zestawienie połączeń VPN. Uruchomienie i konfiguracja protokołu blockchain i zarządzanie nim. Uruchomienie całości aplikacji i jej integracja z systemami zewnętrznymi.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Programista blockchain (smart kontrakt)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wytworzenie/zaimplementowanie logiki biznesowej na warstwie smart kontraktu (stworzenie smart kontraktu).</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Programista full-stack (warstwa aplikacyjnej backend i frontend aplikacji, testy automatyczne w Selenium)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wytworzenie backendu i frontendu aplikacji webowej. Testy automatyczne rozwiązania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">UI/UX designer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dbanie o wysoką jakość UX experience rozwiązania (doświadczenia użytkownik&oacute;w w kontakcie z rozwiązaniem). Zaprojektowanie od strony wizualnej jak ma wyglądać aplikacja na r&oacute;żnych typach ekran&oacute;w (mobile, web). Przygotowanie mockup&oacute;w (makiet) rozwiązania.</span></p>
</td>
</tr>
</tbody>
</table>
<h3 style="text-align: center;">&nbsp;</h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko: Specjalista ds technologii blockchain&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2015 Deweloper</span></p>
<p><span style="font-weight: 400;">2015 - 2019 Architekt IT</span></p>
<p><span style="font-weight: 400;">2019 &ndash;&nbsp; Architekt blockchain</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">GitHub***</span></p>
<p><span style="font-weight: 400;">Jira****</span></p>
<p><span style="font-weight: 400;">Confluence****</span></p>
<p><span style="font-weight: 400;">IDE (visual studio code)****</span></p>
<p><span style="font-weight: 400;">Draw.io****</span></p>
<p><span style="font-weight: 400;">Python*****</span></p>
<p><span style="font-weight: 400;">Visual Paradigm***</span></p>
<p><span style="font-weight: 400;">JS***</span></p>
<p><span style="font-weight: 400;">Golang****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Certyfikat architekt&oacute;w TOGAF</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> Budowa i zarządzanie technologiami blockchain. Tworzenie środowisk w oparciu o mikroserwisy, integracja system&oacute;w (bazy danych, AD, ERP, TMS +). Konfigurowanie infrastruktury cloud (tworzenie, zarządzanie). Znajomość protokoł&oacute;w blockchain. Znajomość budowy architektury system&oacute;w IT oraz wiedza o najnowszych technologiach.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność kontaktu z klientami, zbieranie wymagań, umiejętność zarządzania&nbsp; zespołem deweloperskim.</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko: PM</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><strong>&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2015 Specjalista ds. Obsługi klienta</span></p>
<p><span style="font-weight: 400;">2015 - 2019 Kierownik produkcji</span></p>
<p><span style="font-weight: 400;">2019 &ndash;&nbsp; PM</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Git*</span></p>
<p><span style="font-weight: 400;">Jira****</span></p>
<p><span style="font-weight: 400;">IDE (visual studio code)****</span></p>
<p><span style="font-weight: 400;">Confluence***</span></p>
<p><span style="font-weight: 400;">MS Project**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">PMI , Scrum Master, Prince</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozumienie sposobu działania rozwiązań IT. Znajomość metodyk projektowych.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Studia podyplomowe: &ldquo;Zarządzanie projektami&rdquo;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, skrupulatność, wsp&oacute;łpraca z klientem i zespołem programist&oacute;w.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko: DevOps</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2015 Programista Python</span></p>
<p><span style="font-weight: 400;">2015 - 2019 Cloud developer</span></p>
<p><span style="font-weight: 400;">2020 &ndash;&nbsp; DevOps</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">CLI (command line interface &ndash; Shell, Bash)****</span></p>
<p><span style="font-weight: 400;">GitHub***</span></p>
<p><span style="font-weight: 400;">REST API***</span></p>
<p><span style="font-weight: 400;">Sopa**</span></p>
<p><span style="font-weight: 400;">Jira****</span></p>
<p><span style="font-weight: 400;">IDE (visual studio code)****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Certyfikat inżyniera cloud</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Obsługa serwis&oacute;w chmurowych (całość środowisk chmurowych). Integrowanie system&oacute;w (interface API &ndash; Soap, REST). Obsługa protokoł&oacute;w sieciowych (TCP/IP). Administrowanie siecią. Znajomość system&oacute;w operacyjnych i umiejętność administrowania nimi (Linux). Zarządzanie cyklem życia aplikacji (znajomość praktyk CI/CD). Znajomość usług oferowanych przez dostawc&oacute;w chmury.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr matematyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność , otwartość na dyskusję , wyrażenie swojego zdania.</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko: Programista warstwy blockchain</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2015 Ekspert ds. baz danych&nbsp;</span></p>
<p><span style="font-weight: 400;">2015 - 2019 Programista Python</span></p>
<p><span style="font-weight: 400;">2020 &ndash; Programista blockchain</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Golang****</span></p>
<p><span style="font-weight: 400;">Solidity**</span></p>
<p><span style="font-weight: 400;">JS***</span></p>
<p><span style="font-weight: 400;">Typescript**</span></p>
<p><span style="font-weight: 400;">SQL***</span></p>
<p><span style="font-weight: 400;">NoSQL****</span></p>
<p><span style="font-weight: 400;">TestNet sieci blockchain***</span></p>
<p><span style="font-weight: 400;">nodeJS (środowisko uruchomienia aplikacji napisanych w JS)***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Certyfikat budowy aplikacji opartych na blockchain</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> Wiedza o budowie smart kontrakt&oacute;w. Znajomość struktur/modeli rozproszonych baz danych. Znajomość r&oacute;żnic między SQL/NoSQL. Znajomość protokoł&oacute;w blockchain i mechanizm&oacute;w ich działania (Bitcoin, Ethereum , Hyperlegend Fabric, Corda ).</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inżynier informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikacja , asertywność</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko: Programista&nbsp; full-stack</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2015 Frontend developer</span></p>
<p><span style="font-weight: 400;">2015 - 2019 Backend developer</span></p>
<p><span style="font-weight: 400;">2021 &ndash; Full Stack Developer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Git***</span></p>
<p><span style="font-weight: 400;">Jira****</span></p>
<p><span style="font-weight: 400;">IDE (visual studio code)****</span></p>
<p><span style="font-weight: 400;">JS****</span></p>
<p><span style="font-weight: 400;">Typescript**</span></p>
<p><span style="font-weight: 400;">noteJS**</span></p>
<p><span style="font-weight: 400;">SQL**</span></p>
<p><span style="font-weight: 400;">NoSQL***</span></p>
<p><span style="font-weight: 400;">ESlint (narzędzie wskazujące co można poprawić w kodzie &ndash; JS)***</span></p>
<p><span style="font-weight: 400;">HTML***</span></p>
<p><span style="font-weight: 400;">CSS ***</span></p>
<p><span style="font-weight: 400;">Angular****</span></p>
<p><span style="font-weight: 400;">REACT**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Certyfikat ukończenia kursu programowania w JS</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość baz danych. Znajomość r&oacute;żnić SQL/ NoSQL. Tworzenie stron internetowych w HTML, JS. Umiejętność integracji system&oacute;w z narzędziami autentykacji i autoryzacji. Znajomość zasad RWD (responsive web design, tworzenie stron internetowych wyświetlają się poprawnie na r&oacute;żnej wielkości ekranach, w tym na urządzeniach mobilnych).&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inż. Informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Skrupulatność, analityczne myślenie, wyczucie estetyki.</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko:&nbsp; UX/UI Designer&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2014-2016 &ndash;Grafik</span></p>
<p><span style="font-weight: 400;">2016 &ndash;2021 &ndash; UX Designer</span></p>
<p><span style="font-weight: 400;">2021 &ndash; UX/UI Designer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Adobe XD****</span></p>
<p><span style="font-weight: 400;">Figma**</span></p>
<p><span style="font-weight: 400;">Photoshop****</span></p>
<p><span style="font-weight: 400;">Balsamique****</span></p>
<p><span style="font-weight: 400;">Jira***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Google UX Design Professional Certificate</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Projektowanie graficzne w systemach/programach do tworzenia mockup&oacute;w. Obsługa program&oacute;w graficznych. Znajomość najnowszych trend&oacute;w UX/UI. Znajomość zasad projektowania zgodnie z dobrymi praktykami UX. Znajomość heurystyk Nielsena.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">ASP, grafika komputerowa</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność prezentacji pomysł&oacute;w i ich wyjaśnienia. Poczucie estetyki.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
