const html = `<table style="border: none; border-collapse: collapse; table-layout: fixed; width: 453.55944881889764pt;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Matryca RACI projektu wdrożenia blockchain</span></strong></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadanie\\Osoba&nbsp;</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Specjalista ds blockchain&nbsp;</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">PM</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">DevOps</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Programista blockchain</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Programista full stack</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 10pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">UX/UI Designer</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza przedwdrożeniowa</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowanie makiet (mockup&oacute;w) aplikacji i infrastruktury IT</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Development smart contractu w Hyperledger Fabric</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Development backendu aplikacji&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Development frontendu aplikacji</span><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy systemu&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wdrożenie produkcyjne rozwiązania&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C</span></p>
</td>
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
