const html = `<h3 style="text-align: center;"><strong>Case study projektu wydruku implant&oacute;w w technologii 3D</strong></h3>
<p>&nbsp;</p>
<h4><strong>Tytuł projektu:</strong><em><span style="font-weight: 400;">&nbsp; </span></em><span style="font-weight: 400;">Wydruk 3D implantu medycznego&nbsp;</span></h4>
<p>&nbsp;</p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Stosowana obecnie technologia produkcji implant&oacute;w opiera się na pozyskaniu formy modelu poprzez odcisk, odlew lub metodę szacunkową, a następnie wykonanie odlewu i rzeźbienie, kt&oacute;re nadaje mu ostateczny kształt. Proces ten wymaga udziału co najmniej dw&oacute;ch wykwalifikowanych i uzdolnionych manualnie pracownik&oacute;w.&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na jest stworzenie modelu uzębienia i otaczających go element&oacute;w jamy ustnej pacjenta za pomocą skanowania 3D bezpośredniego (laserowe skanowanie wewnątrzustne). Następnie, model ten został przetworzony w specjalistycznym oprogramowaniu, w wyniku czego określono dokładny kształt oraz lokalizację przyszłego implantu. W końcowym etapie projektu wirtualny model 3D implantu zębowego został wydrukowany na drukarce 3D.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Zdecydowanie szybszy czas tworzenia implant&oacute;w, pełne spersonalizowanie implant&oacute;w, co oznacza, że ich kształt i wielkość w pełni odpowiadają realnym ubytkom uzębienia.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Drukarka przemysłowa 3D FDM, wsp&oacute;łpracująca z filamentami o wysokiej wydajności (PEEK, ULTEMTM i PPSU).</span></p>
<p><strong>Czas trwania projektu</strong><span style="font-weight: 400;">: 6 tygodni</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Grafik, Technologi, Technik dentystyczny&nbsp;</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">18 tys. zł</span></p>`;

export default html;
