const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać podczas spotkania rozpoczynającego realizację projektu z obszaru druku 3D</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jesteśmy tu po to, by stworzyć dla Was prototyp nowego opakowania środk&oacute;w higienicznych, kt&oacute;re chcecie wprowadzić na rynek.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Cały proces będzie się odbywał w duchu szybkiego prototypowania &ndash; na podstawie Waszych wymagań powstaną trzy sztuki opakowania, kt&oacute;re będą poddawane Waszej ocenie. Następnie na bazie wniosk&oacute;w z Waszych test&oacute;w, w kolejnej iteracji zostaną wprowadzone odpowiednie zmiany, aż do uzyskania satysfakcjonującego prototypu opakowania.</span></p>
</td>
</tr>
<tr style="height: 1.05pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oto nasz zesp&oacute;ł. Zesp&oacute;ł składa się z grafika, technologia i handlowca.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Cały proces musimy zakończyć w ciągu 2 miesięcy. Czas trwania jednej iteracji po naszej stronie, składającej się z przygotowania/poprawienia modelu 3D oraz wydruku każdorazowo ma zamknąć się w maksymalnie 7 dniach roboczych.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po zakończeniu każdego etapu projektu przekazywać będziemy Państwu konkretne efekty, tj. model i wydruki, czy też notatki służbowe, gdy zadanie nie wiązało się bezpośrednio z osiągnięciem fizycznych rezultat&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do bieżących kontakt&oacute;w korzystajmy z komunikacji mailowej lub telefonicznej. W przypadku większych kamieni milowych będziemy organizować spotkania na żywo. Osobą wyznaczoną do kontaktu ze strony naszej firmy jest Handlowiec.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uprzejmie proszę o zadawanie pytań lub zgłaszanie propozycji co do przebiegu projektu. W ten spos&oacute;b unikniemy nieporozumień na dalszych etapach.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dziękuję za spotkanie. Myślę, że po nim wszyscy wiemy co mamy robić i w jakim celu. Pozostaję do dyspozycji w razie pytań.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
