const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 32.9529%;"><strong>Technologia</strong></td>
<td style="width: 32.9529%;"><strong>Funkcja</strong></td>
<td style="width: 32.9565%;"><strong>Kompetencje</strong></td>
</tr>
<tr>
<td style="width: 32.9529%;">Narzędzie ETL</td>
<td style="width: 32.9529%;"><strong>Umożliwia</strong> realizację proces&oacute;w pobrania danych ze źr&oacute;deł, transformacji danych i załadowania do centralnej bazy danych</td>
<td style="width: 32.9565%;">
<p>&bull; wdrażanie proces&oacute;w ETL</p>
<p>&bull; znajomość narzędzi ETL</p>
</td>
</tr>
<tr>
<td style="width: 32.9529%;">Hurtowania danych</td>
<td style="width: 32.9529%;"><strong>Umożliwia</strong> agregowania i integrację danych z wielu źr&oacute;deł, a następnie łatwy i efektywny dostęp do danych m.in. przez systemy raportujące</td>
<td style="width: 32.9565%;">
<p>&bull; SQL Server Management Studio</p>
<p>&bull; T-SQL/ SQL</p>
</td>
</tr>
<tr>
<td style="width: 32.9529%;">Silnik analityczny (usługa chmurowa)</td>
<td style="width: 32.9529%;"><strong>Umożliwia</strong> budowanie modeli analitycznych (przeliczających dane w określony spos&oacute;b), zawierających obiekty biznesowe (miary i wymiary). Silnik może przetwarzać r&oacute;żne typy danych, w r&oacute;żnych wymiarach</td>
<td style="width: 32.9565%;">
<p>&bull; SQL Server Data Tools</p>
<p>&bull; DAX</p>
<p>&bull; ASSL</p>
<p>&bull; XMLA</p>
</td>
</tr>
<tr>
<td style="width: 32.9529%;">Aplikacja SaaS do tworzenia raport&oacute;w analitycznych</td>
<td style="width: 32.9529%;"><strong>Umożliwia</strong> stworzenie warstwy wizualnej raport&oacute;w, dashboard&oacute;w analitycznych</td>
<td style="width: 32.9565%;">
<p>&bull; obsługa narzędzia do wizualizacji danych</p>
<p>&bull; wiedza o rodzajach i zastosowaniu wykres&oacute;w</p>
</td>
</tr>
</tbody>
</table>`;

export default html;
