import { Checkbox } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onChange: () => void;
  label?: ReactElement;
  disabled?: boolean;
  value: boolean;
  error?: string;
}

const StyledCheckbox = (props: Props) => {
  const { t } = useTranslation();
  const { label, onChange, disabled, value, error } = props;

  return (
    <div className="styledCheckbox">
      <div className="main">
        <Checkbox disabled={disabled} value={value} onChange={onChange} />
        <div>{label}</div>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default StyledCheckbox;
