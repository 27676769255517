import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { history } from "../..";
import { useQuery } from "../../hooks/hooks";
import { activateAcount } from "../../services/user.service";
import { AppDispatch } from "../../store/store";

const ActivateAcountScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  let query = useQuery();

  useEffect(() => {
    if (query) {
      const id = query.get("id");
      if (id) {
        dispatch(activateAcount(id));
        history.push("/login");
      } else history.push("/login");
    }
  }, [query]);

  return <div />;
};

export default ActivateAcountScreen;
