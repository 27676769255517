const html = `<div align="left">
    <table>
        <tbody>
            <tr>
                <td colspan="2">
                    <p><strong>Podstawy content planu</strong></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>Definicja content planu</strong></p><br>
                </td>
                <td>
                    <p>Content plan to szczeg&oacute;łowy harmonogram publikacji r&oacute;żnorodnych treści. Dotyczyć może np.&nbsp;&nbsp;tekst&oacute;w, grafik, video, audio na stronę www czy &nbsp;do social medi&oacute;w.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>WSKAZ&Oacute;WKI PRZY PRZYGOTOWYWANIU CONTENT PLANU</strong></p><br>
                </td>
                <td>
                    <p>Pamiętaj, że publikowane treści powinny &nbsp;nawiązywać do unikalnych cech promowanego produktu!&nbsp;</p>
                    <p>W komunikatach nawiąż do przyjętych założeń w zakresie archetypu marki!</p>
                    <p>Przygotuj treści, kt&oacute;re są interesujące z punktu widzenia określonej grupy docelowej!</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>PRZYKŁADOWE TYTUŁY TREŚCI TEKSTOWYCH ZAPLANOWANYCH DO PUBLIKACJI </strong></p><br>
                </td>
                <td>
                    <p>Rozszerzona rzeczywistość w onboardingu - o aplikacji&nbsp;</p>
                    <p>Trendy w social media - zobacz raport</p>
                    <p>Czy Europę czeka kryzys?</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p><strong>PRZYKŁADOWE OPISY KAMPANII PŁATNYCH W MEDIACH SPOŁECZNOŚCIOWYCH</strong></p><br>
                </td>
                <td>
                    <p>Promocja posta z video wraz ze skonfigurowaniem grupy niestandardowych odbiorc&oacute;w, kt&oacute;rzy widzieli co najmniej 50% video. Grupa będzie w kolejnych tygodniach wykorzystywana do remarketingu sprzedażowego.</p>
                    <p>Promocja wtorkowego posta na LINKED IN. Kampania zasięgowa, optymalizowana na pozyskanie jak największej ilości przejść na stronę z case study.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
