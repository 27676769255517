const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="border-left: solid #000000 0.5pt; border-right: solid #000000 0.5pt; border-bottom: solid #000000 1pt; border-top: solid #000000 0.5pt; vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać i uzyskać podczas spotkania rozpoczynającego realizację projektu z obszaru IoT</span></strong></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Celem projektu jest wdrożenie platformy IoT, kt&oacute;ra optymalizuje procesy zarządzania i utrzymania farm wiatrowych, dzięki generowaniu wartości z danych i procesom inteligentnej automatyzacji.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nasz zesp&oacute;ł składa się z dw&oacute;ch architekt&oacute;w, inżyniera chmury, testera oraz DevOps. Prace związane z analizą danych i modelami wykonywać będą Data Scientisci z Państwa firmy, podobnie jak uzupełniające prace programistyczne, kt&oacute;re koordynować będą nasi architekci.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dokumentacja, kt&oacute;ra bedzie dostarczona Państwu w ramach realizacji projektu obejmuje:</span></p>
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Opis architektury rozwiązania (dokumentacja techniczna opisująca wszystkie użyte komponenty chmurowe, komponenty open source, np. biblioteka Newtonsoft.Json, opis sposob&oacute;w połączenia komponent&oacute;w, informacje o repozytoriach, informacje o pipelines DevOps podpiętych do repozytori&oacute;w, użyte języki programowania, użyte wersje komponent&oacute;w)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Opis strategii updatowania użytych komponent&oacute;w technologicznych i odpowiedzialności za updaty&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Instrukcja obsługi platformy, najczęściej występujące błędy i sposoby ich rozwiązywania&nbsp;</span></li>
</ol>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzebujemy od Państwa specyfikacji technicznej zdefiniowanych element&oacute;w infrastruktury farm, tj. wiatrak&oacute;w, czujnik&oacute;w i procesor&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przed rozpoczęciem realizacji danego kamienia milowego odbywać się będą spotkania na kt&oacute;rych określane będą obiektywne kryteria realizacji danego cel&oacute;w, tj. ustalenie wsp&oacute;lnego rozumienia tzw. definition of done, DoD.</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po realizacji każdego kamienia milowego nastąpi podpisanie dokument&oacute;w potwierdzających odbi&oacute;r prac.</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do rozpoczęcia pracy potrzebujemy dostęp&oacute;w do system&oacute;w. Tu mam listę os&oacute;b, kt&oacute;re potrzebują kont. Przekazuję ją Administratorowi IT. Proszę także o stworzenie kont do usług chmurowych na Waszym tenancie (grupa użytkownik&oacute;w dzielących określony dostęp do uprawnień i zasob&oacute;w w chmurze).&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zaprezentuję macierz RACI, tak by każdy wiedział czego się od niego oczekuje i za co jest odpowiedzialny.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Będziemy pracować w sprintach 2 tygodniowych, po każdym sprincie prezentować będziemy efekty naszych prac.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
