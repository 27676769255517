const html = `<h3 style="text-align: center;"><strong>Case study 1</strong></h3>
<p><strong>Tytuł projektu: Zagwarantowanie niezaprzeczalności dokument&oacute;w publikowanych drogą elektroniczną</strong></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Dokumenty publikowane dla klient&oacute;w przez organizacje muszą być przekazywane za pomocą trwałego nośnika informacji. Publikacja dokumentu w kanałach elektronicznych (np. w ramach aplikacji bankowej lub portalu sprzedażowego), nie spełnia tego wymogu.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt zakładał zbudowanie rozwiązania, kt&oacute;re wykorzystując technologię blockchain umożliwiłoby publikowanie dokumentu oraz zapisanie jego unikalnego identyfikatora (hashu) w sieci blockchain, dzięki czemu klient miałby możliwość zweryfikowania czy przeglądany przez niego dokument, nie został zmieniony lub zmodyfikowany. Dotychczas wykorzystywane repozytoria dokument&oacute;w nie musiałyby zostać zmieniane, a dokumenty nadal byłyby publikowane za pomocą tych samych kanał&oacute;w komunikacji, jednak umieszczenia dodatkowo hashu dokumentu oraz udostępnienie dla klient&oacute;w weryfikacji z wykorzystaniem blockchain, spełniły wymagania trwałego nośnika.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">możliwość publikowanie dokument&oacute;w drogą elektroniczną z jednoczesnym spełnieniem wymog&oacute;w trwałego nośnika, brak konieczność wysyłki dokument&oacute;w drogą pocztową przyniosło znaczące oszczędności</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">System bazuje na dedykowanej aplikacji webowej udostępnianej dla klient&oacute;w, w ramach kt&oacute;rej mogą oni zweryfikować oryginalność dokument&oacute;w, aplikacja wykorzystuje sieć blockchain opartą o protok&oacute;ł Hyper Ledger Fabric.</span></p>
<p><strong>Czas trwania projektu:</strong><span style="font-weight: 400;"> NDA</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Project Manager, Analityk biznesowy, Architekt IT, Developer front-end, Developer back-end, Developer warstwy smart-contract, Dev-Ops</span></p>
<h4 style="text-align: left;"><strong>Budżet projektu: </strong><span style="font-weight: 400;">NDA</span></h4>`;

export default html;
