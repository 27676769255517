const html = `<p><strong>Tytuł projektu: Zwiększenie sprzedaży produktu - akumulator&oacute;w samochodowych - za pośrednictwem komunikacji i kampanii płatnych w mediach społecznościowych.</strong></p>
<p><strong>Gł&oacute;wny problem klienta:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Klient, producent akumulator&oacute;w samochodowych, boryka się z agresywną konkurencją produkt&oacute;w sprowadzanych z Azji. Chce wykorzystać potencjał medi&oacute;w społecznościowych, by dotrzeć z kampanią sprzedażową do grup fan&oacute;w motoryzacji jako świadomych konsument&oacute;w potencjalnie zainteresowanych zakupem wysokiej jakości akumulatora.&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Klient zleca opracowanie założeń strategicznych kampanii płatnej z uwzględnieniem wieloetapowych lejk&oacute;w sprzedażowych oraz przygotowanie treści reklamowych i przeprowadzenie 2-miesięcznych kampanii płatnych kierujących do sklepu online Klienta.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania:</strong><strong><br /></strong><span style="font-weight: 400;">Zwiększenie zysk&oacute;w ze sprzedaży bezpośredniej w sklepie internetowym Klienta. Zwiększenie udziału w rynku, odzyskanie i stabilizacja zagrożonej pozycji biznesowej.&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><strong><br /></strong><span style="font-weight: 400;">Menedżer Reklam Meta, Menedżer Reklam LinkedIn, Google Analytics 4</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">1 tydzień na przygotowania + 2 miesiące kampanii</span></p>
<p><strong>Zesp&oacute;ł:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Wersja in-house: Digital Strategist, Copywriter, Senior Designer (koncepcja), Designer (egzekucja), Social Ads Manager.</span></p>
<p><span style="font-weight: 400;">Wersja out-source: Brand Manager/Marketing Manager wsp&oacute;łpracujący z zewnętrzną agencją</span></p>
<p><br /><strong>Budżet : </strong><span style="font-weight: 400;">125 000 zł netto (wliczony budżet mediowy)</span></p>`;

export default html;
