const html = `<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację usługi automatyzacji proces&oacute;w biznesowych z wykorzystaniem RPA</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki wykorzystaniu RPA:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">relatywnie wysokie, a jednocześnie możliwe do zoptymalizowania koszty osobowe realizacji procesu wprowadzania danych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">błędy podczas wprowadzania danych&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">czasochłonność procesu, wymuszająca zmniejszenie czasu pracownik&oacute;w przeznaczonego na prace tw&oacute;rcze</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza oraz automatyzacja proces&oacute;w wybranych do automatyzacji RPA wraz z wdrożeniem na środowisku produkcyjnym klienta. Realizacja usługi obejmie też przeprowadzenia walidacji jakości technicznej rozwiązania, przeprowadzenie test&oacute;w oraz naniesienie poprawek zgłoszonych przez klienta po etapie test&oacute;w.&nbsp;</span></p>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">proces podlegający automatyzacji będzie posiadał wyjątki (mogą się zdarzyć niepełne dane w formularzu kandydata). Wyjątki należy obsużyć poprzez wysyłanie maili do kandytat&oacute;w z prośbą o uzupełnienie danych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wymaganie techniczne: założenie konta robotycznego do systemu ERP spełniającego następujące wymagania: uprawnienia umożliwiające wykonanie procesu, odpowiedni username (</span><a style="text-decoration: none;" href="about:blank"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #0563c1; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">erp_proces.rpa.prod@</span></a><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">...).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">konieczność zakupu min. 1 licencji na robota (oprogramowanie RPA), kt&oacute;ry raz dziennie, w godzinach porannych będzie odpowiednio przetwarzał dane wprowadzone w dniu poprzednim</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające wdrażanie RPA.</span></p>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</span></strong></p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zmniejszenie koszt&oacute;w realizacji procesu</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zwiększenie satysfakcji i zadowolenia pracownik&oacute;w&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wyeliminowanie błęd&oacute;w podczas wprowadzania danych</span></li>
</ul>`;

export default html;
