import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { productsData } from "../../constants/mocks";
import UserType from "../../interfaces/user/UserType";
import { AppState } from "../../store/store";
import StyledButton from "../components/StyledButton";
import { DateTime } from "luxon";
import StatutePDf from "../../constants/pdfs/statute.pdf";

interface Props {
  onClose: () => void;
}

const BagComponent = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onClose } = props;

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const getProductName = () => {
    const subscriptionData = productsData.find(
      (item) => item.id === user!.subscriptionPlanId
    );
    if (subscriptionData) return subscriptionData.label;
    return "Brak informacji o subskrypcji";
  };

  const getDate = () => {
    if (user!.subscriptionEndDate)
      return DateTime.fromISO(user!.subscriptionEndDate, {
        zone: "UTC",
      }).toFormat("dd LLLL yyyy");
    return "Brak informacji o subskrypcji";
  };

  const getLeftTime = () => {
    if (user!.subscriptionEndDate) {
      const a = DateTime.fromISO(user!.subscriptionEndDate, {
        zone: "UTC",
      });
      const b = DateTime.now();
      const diff = a.diff(b, ["years", "months", "days"]).toObject();
      return `${
        diff.years ? ` ${diff.years} ${getYearString(diff.years)}` : ""
      }${diff.months ? ` ${diff.months} ${getMonthString(diff.months)}` : ""}${
        diff.days
          ? ` ${Math.floor(diff.days)} ${getDayString(Math.floor(diff.days))}`
          : ""
      }`;
    }
    return "Brak informacji o subskrypcji";
  };

  const getYearString = (year: number) => {
    if (year === 1) return "rok";
    if (year > 1 && year < 5) return "lata";
    if (year >= 5) return "lat";
  };

  const getMonthString = (month: number) => {
    if (month === 1) return "miesiąc";
    if (month > 1 && month < 5) return "miesiące";
    if (month >= 5) return "miesięcy";
  };

  const getDayString = (day: number) => {
    if (day === 1) return "dzień";
    if (day > 1) return "dni";
  };

  return (
    <div className="bagComponent">
      {user && user.hasSubscriptionActive ? (
        <>
          <div className="header">{"Subskrypcja"}</div>
          <div className="info">{getProductName()}</div>
          <div className="header">{"Data ważności subskypcji"}</div>
          <div className="info">{getDate()}</div>
          <div className="header">{"Pozostało"}</div>
          <div className="info">{getLeftTime()}</div>
          <StyledButton
            label={"Przedłuż subskrypcję"}
            onClick={() => {
              onClose();
              navigate("/subscription");
            }}
            type="contained"
          />
        </>
      ) : (
        <div className="noActiveSubscription">
          {"Brak aktywnej subskrypcji"}
        </div>
      )}
      <div className="header">{"Dodatkowe informacje"}</div>
      <a
        className="label clickable"
        href={StatutePDf}
        download={t("footer.statute")}
      >
        {"Regulamin"}
      </a>
    </div>
  );
};

export default BagComponent;
