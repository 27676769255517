const html = `<h3 style="text-align: center;"><strong>Analiza SWOT zastosowania RPA</strong></h3>
<p><strong>Mocne strony (Strengths)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">szybkość wdrożenia</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">uniwersalność (robotyzacja może dotyczyć praktycznie każdego procesu, ograniczeniem mogą być np. kwestie bezpieczeństwa)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">niezawodność (robot nie popełnia błęd&oacute;w popełnianych przez człowieka, powtarza zawsze tę samą sekwencję działań)</span></li>
</ul>
<p><strong>Słabe strony (Weaknesses)&nbsp;</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wysoki koszt inwestycji początkowej (koszt licencji rocznej na narzędzie do zarządzania robotami wynosi około 10 tys. EUR</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">ze względu na to, że gł&oacute;wna idea pracy robot&oacute;w to operowanie po GUI (graficzny interfejs użytkownika), pewne procesy mogą być realizowane wolniej niż w przypadku dedykowanej aplikacji, kt&oacute;ra miałaby bezpośredni dostęp do backendu źr&oacute;dłowej aplikacji, z kt&oacute;rej pobierane są dane (np. bazy danych SAP)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">ze względu na działanie po GUI realizacja proces&oacute;w może być mniej dokładna niż w przypadku zastosowania podejścia softwarowego (programowanie dedykowanych aplikacji), gdyż mogą wystąpić sytuacje, w kt&oacute;rych danych program się zawiesi, dany element w programie nie jest jeszcze aktywny, a to uniemożliwi realizację działania przez robota&nbsp;</span></li>
</ul>
<p><strong>Szanse (Opportunities)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">robot pracować może bez przerwy 24h na dobę, 365 dni w roku co prowadzi do znacznych oszczędności w kosztach pracy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zwiększenie zadowolenia pracownik&oacute;w ze względu na odciążenie ich w wykonywaniu powtarzalnych czynności</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zwiększenie wydajności i szybkości pracy (w tej samej jednostce czasu pracować może zar&oacute;wno człowiek jak i komputer)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">podniesienie jakości wykonywanej pracy w por&oacute;wnaniu do pracy człowieka (robot nie popełni liter&oacute;wki, nie zapomni o elemencie proces, nie męczy się)</span></li>
</ul>
<p><strong>Zagrożenia (Threats)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">negatywne nastawienie pracownik&oacute;w do robot&oacute;w (obawa przed utratą pracy)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">jeżeli w procesie programowania robota nie przewidzieliśmy wszystkich wyjątk&oacute;w biznesowych (business exceptions, tj. zdarzenia kt&oacute;re mogą wydarzyć się w trakcie trwania procesu, np. system wyświetli komunikat informujący o zdarzeniu związanym z działaniem robota), dalsze/standardowe działanie robota będzie niezgodne z wymaganiami procesu (procedurami firmowymi)&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">w przypadku zmian w przebiegu proces&oacute;w biznesowych, należy przewidzieć budżet na dostosowanie działania robota do zmian w procesach </span></li>
</ul>`;

export default html;
