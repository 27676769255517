const html = `<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację usługi polegającej na wytworzeniu oprogramowania, wykorzystującego technologię blockchain</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki zastosowaniu blockchain :</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">brak dostępu w czasie rzeczywistym do aktualnych informacji o produktach (np. nr partii, miejsce pochodzenia, data zebrania z pola, ścieżki transportu)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">brak możliwości dokonywania selektywnego wycofywania danych partii produkt&oacute;w ze sprzedaży (np. w sytuacji wykrycia salmonelli wszystkie produkty od danego producenta muszą zostać zutylizowane)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">trudności w zarządzaniu łańcuchem dostaw produkt&oacute;w spożywczych, ze względu na trudny dostęp do danych o dostawcach</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">czasochłonny proces raportowanie (proces wymaga dużego nakładu pracy analityk&oacute;w ze względu na rozmieszczenie danych w r&oacute;żnych systemach)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">brak integralności danych (tj. sp&oacute;jności, tzw. data integrity, stanowiącej własność&nbsp;danych&nbsp;wykluczającą możliwość wprowadzenie do nich zmian w nieautoryzowany spos&oacute;b, ochrona&nbsp;integralności&nbsp;zapobiega przypadkowemu zniekształceniu&nbsp;danych&nbsp;podczas odczytu, zapisu, transmisji lub magazynowania), gdyż r&oacute;żne podmioty skupione w łańcuch dostaw dostarczają dane w r&oacute;żnych formatach zapisu (xml, CSV) oraz np. nie zgłaszają zmian</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Usługa polegać będzie na wytworzeniu systemu informatycznego umożliwiającego zapis i odtworzenie cyklu życia produkt&oacute;w, wykorzystującego technologię blockchain, jako niezaprzeczalną bazę danych, przechowującą informacje o produktach w ujednoliconym formacie. Zapewni to standaryzację procesu i podniesie jakość raportowania, umożliwiając efektywną analizę danych. Blockchain umożliwi chronologiczne rejestrowanie wszystkich zdarzeń związanych z konkretnym produktem, następujących po sobie w ramach łańcucha dostaw. Rozwiązanie umożliwi dostęp do wszystkich danych z centralnego źr&oacute;dła, szybkie tworzenie harmonogram&oacute;w przetwarzania danych, możliwość automatycznego odszyfrowywania danych. System zapewni w pełni cyfrowy obieg dokument&oacute;w i da możliwość odtworzenia pełnego cyklu życia produktu na podstawie jego numeru seryjnego, przez każdego użytkownika w dowolnym miejscu, w czasie rzeczywistym, z wykorzystaniem aplikacji mobilnej, strony www lub kodu QR.&nbsp; Rozwiązanie umożliwi też dostęp do wyszukiwalnej listy, powiązanych z danym użytkownikiem lub produktem, informacji oraz da możliwość wizualnej prezentacji i&nbsp; raportowania wszystkich wymaganych danych.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">pełna kontrola nad tym kto będzie m&oacute;gł być uczestnikiem danej sieci. Gwarancja, że udostępniane dane nie trafią do sieci publicznej (wykorzystanie protokołu blockchain typu permissioned)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">możliwość programowania smart kontrakt&oacute;w w popularnych językach np. Golang, Java, Javascript (protok&oacute;ł Hyperledger Fabric)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zapewnienie integralności danych wykluczającej możliwość wprowadzenie do nich zmian w nieautoryzowany spos&oacute;b</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">możliwość dostępu do informacji o produktach przy użyciu aplikacji mobilnej i czytnika kod&oacute;w QR</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające wdrażanie blockchain.&nbsp;</span></p>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">szybki dostęp do pełnych danych o cyklu życia danego produktu (np. poprzez zeskanowanie w sklepie kodu QR na produkcie)&nbsp;&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">możliwość szybkiego przekazania informacji o produktach do odpowiednich podmiot&oacute;w</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">umożliwienie partnerom (np. dostawcy) sprawowania kontroli nad informacjami w bazie (bez ich wiedzy zmiany w bazie nie będą możliwe)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">gwarancja integralności i niezaprzeczalności danych, brak możliwości usunięcia raz wprowadzonej informacji</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zapewnienie natywnie automatycznej replikacji (kopiowania) danych do wszystkich uczestnik&oacute;w sieci, dzięki czemu w czasie rzeczywistym każdy uzyskuje dostęp do informacji w ramach własnej bazy danych </span></li>
</ul>`;

export default html;
