const html = `<div align="left">
    <table style="border-collapse: collapse; border: 2px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Propozycja usługi/rozwiązania technologicznego</strong></p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Uzasadnienie </strong></p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Dodanie danych z innych obszar&oacute;w biznesowych do hurtowni danych</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>W firmie funkcjonują systemy (obszary biznesowe), w kt&oacute;rych gromadzone są dane &nbsp;nie przesyłane do hurtowni danych. Warto je dodać. Opr&oacute;cz możliwości raportowania z wykorzystaniem nowych danych, możliwe będzie też wykonywanie analizy krzyżowych, na przecięciu tych obszar&oacute;w (cross domain).</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Dodanie nowych raport&oacute;w do dashboard&oacute;w analitycznych&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Po pewnym czasie użytkowania narzędzia z pewnością pojawiły się w firmie nowe pomysły na raporty i analizy. Proponujemy pomoc w ich przygotowaniu.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Utrzymanie platformy, rozwiązywanie problem&oacute;w (maintenance)</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Doskonała znajomość narzędzia pozwala na zaoferowanie najwyższej jakości prac w zakresie utrzymania narzędzia, w korzystnej cenie. Pozwoli to firmie na skoncentrowanie się na działalności biznesowej.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Zintegrowanie hurtowni danych z narzędziami wykorzystywanymi w innych sp&oacute;łkach zależnych.</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Hurtownia danych może być także wykorzystywana jako element konsolidacji raportowania z wielu sp&oacute;łek, np. z r&oacute;żnych kraj&oacute;w. Jeżeli Państwo chcieliby raportować na poziomie holdingu to możemy przygotować ofertę takiego projektu</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Zautomatyzowanie proces&oacute;w biznesowych (proces&oacute;w podejmowania decyzji i działań systemu)</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Warto się zastanowić, czy nie ma obszar&oacute;w w kt&oacute;rych mogłaby nastąpić automatyzacja podejmowania decyzji na podstawie jakiegoś wskaźnika. Możemy rozbudować platformę analityczną w taki spos&oacute;b by np. automatycznie wysłała maila z informacją o poziomie wskaźnika i krokami jakie należy podjąć &ndash; np. zam&oacute;wienie towaru w przypadku gdy spadnie on poniżej jakiegoś poziomu w magazynie.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
