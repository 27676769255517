import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { buyProductExtendAccessOptions } from "../../../constants/mocks";
import { PaymentStatusType } from "../../../enums/PaymentStatusType";
import { RoleType } from "../../../enums/RoleType";
import { SubscriptionType } from "../../../enums/SubscriptionType";
import PaymentType from "../../../interfaces/payment/PaymentType";
import ProductType from "../../../interfaces/payment/ProductType";
import { getLastPayment, getProducts } from "../../../services/payment.service";
import { AppDispatch, AppState } from "../../../store/store";
import BuyProductComponent from "./BuyProductComponent";
import OrderComponent from "./OrderComponent";

interface FormValues {
  email: string;
  country: string;
  nip?: string;
  companyName?: string;
  address: string;
  city: string;
  zipCode: string;
  phone?: string;
  agreement: boolean;
}

const BuyProductScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const methods = useForm<FormValues>();
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(
    null
  );
  const [extendAccessOption, setExtendAccessOption] = useState<string>(
    buyProductExtendAccessOptions[0].value
  );
  const lastPayment: PaymentType | null = useSelector(
    (state: AppState) => state.paymentReducer.lastPayment
  );

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  useEffect(() => {
    dispatch(
      getProducts(
        role === RoleType.EDUCATOR
          ? SubscriptionType.SUBSCRIPTION_EDUCATOR
          : SubscriptionType.SUBSCRIPTION_USER
      )
    );
  }, []);

  useEffect(() => {
    if (userId) dispatch(getLastPayment(userId));
  }, [userId]);

  if (lastPayment && lastPayment.status === PaymentStatusType.PENDING) {
    return (
      <div className="buyProductScreen">
        <div className="componentHeader paymentStatus">
          <span>
            {
              "System odnotował nieukończoną płatność. Spróbuj ponownie za kilka godzin lub skontaktuj się z administratorem."
            }
          </span>
        </div>
      </div>
    );
  }

  if (selectedProduct) {
    return (
      <div className="buyProductScreen">
        <FormProvider {...methods}>
          <OrderComponent
            product={selectedProduct}
            extendAccessOption={extendAccessOption}
          />
        </FormProvider>
      </div>
    );
  }

  return (
    <div className="buyProductScreen">
      <BuyProductComponent
        onProductSelect={setSelectedProduct}
        extendAccessOption={extendAccessOption}
        setExtendAccessOption={setExtendAccessOption}
      />
    </div>
  );
};

export default BuyProductScreen;
