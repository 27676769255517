const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<h3 style="text-align: center;"><strong>Informacje, kt&oacute;re należy przekazać pracownikom klienta po zrealizowaniu projektu z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Kiedy zaczynaliśmy, strona www nie była wystarczająco dobrze zoptymalizowanym narzędziem sprzedażowym. Opracowaliśmy wszystkie szczeg&oacute;ły, więc dziś jest elementem, kt&oacute;ry sprzedaje wymagając przy tym minimalnej obsługi. Profile w mediach społecznościowych mają już wypracowane dobre praktyki, kt&oacute;re wystarczy kontynuować, by dalej rozwijać społeczność fan&oacute;w, obserwujących i klient&oacute;w. Know-how opisane w raportach końcowych jest wiedzą, opartą na danych i licznych przeprowadzonych testach i eksperymentach. Mamy nadzieję, że będzie to fundamentem do dalszego, dynamicznego rozwoju Waszej marki.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zesp&oacute;ł marketingowy powinien świadomie korzystać z danych analitycznych dostarczanych przez statystyki odwiedzalności i sprzedaży na stronie www</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Po kilku miesiącach aktywnego tworzenia strategii, mamy już mn&oacute;stwo danych. Dalszy monitoring może być prowadzony w spos&oacute;b ciągły lub okresowy - raporty mogą być tworzone np. raz na kwartał, aby zaobserwować zmiany w tym jak w internecie postrzegany jest nasz produkt, produkty konkurencji oraz jakie są trendy w naszej branży.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wszystkie kanały społecznościowe są profesjonalnie skonfigurowane i mają pełne profile biznesowe. Klient powinien zadbać, by ten porządek utrzymać - nie tworzyć nowych, niepotrzebnych kont, kontrolować uprawnienia, publikować treści przez dedykowane do tego narzędzia.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
