const html = `<h3 style="text-align: center;"><strong>CASE STUDY PROJEKTU&nbsp;</strong></h3>
<p>&nbsp;</p>
<p><strong>Tytuł projektu:</strong><span style="font-weight: 400;"> Aplikacja szkoleniowa VR stanowiąca symulację działań i wydarzeń niepożądanych w fabryce&nbsp;</span></p>
<h3 style="text-align: center;">&nbsp;</h3>
<p><strong>Gł&oacute;wny problem klienta:</strong><span style="font-weight: 400;"> Brak możliwości przećwiczenia ekstremalnych zdarzeń na terenie fabryki i umiejętności prawidłowego zachowania w takich sytuacjach przez pracownik&oacute;w.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Odtworzenie części fabryki, stworzenie symulacji r&oacute;żnego rodzaju awarii , kt&oacute;re powodują zapr&oacute;szenie ognia (element praktyczny w zakresie szkolenia BHP), identyfikacja zagrożenia na fabryce/ rozpoznanie zagrożenia, umiejętność reagowania na zdarzenie nieporządane</span> <span style="font-weight: 400;">według opracowanej instrukcji.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Zwiększenie bezpieczeństwa ludzi, maszyn, urządzeń, poprzez możliwość przetestowania w warunkach zbliżonych do rzeczywistych zachowania w warunkach zagrożenia.&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Aplikacja VR instalowana na komputerze, opracowana na silniku Unity, o zwiększonej jakości grafiki i animacji, gogle VR, komputer spełniający odpowiednie parametry.&nbsp;</span></p>
<p><strong>Czas trwania projektu</strong><span style="font-weight: 400;">: 3 miesiące</span><strong>&nbsp;</strong></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Programista Unity (1 FTE), modelarz 3D (1 FTE), programista oprogramowania (0,5 FTE),&nbsp; tester oprogramowania (0,5 FTE), PM (0,5 FTE), Specjalista ds. szkolenia (0,5 FTE)</span></p>
<p><strong>Budżet: </strong><span style="font-weight: 400;">40tys. zł</span></p>`;

export default html;
