import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import GameMockType from "../interfaces/game/GameMockType";
import GroupUser from "../interfaces/group/GroupUser";
import { DateTime } from "luxon";
import colors from "../assets/styles/_colors.module.scss";
import DigiLeaderLogo from "../assets/images/digiLeaderLogo.png";
//@ts-ignore
import NunitoRegular from "../assets/fonts/Nunito/Nunito-Regular.ttf";
import GroupType from "../interfaces/group/GroupType";

interface Props {
  users: GroupUser[];
  gameData: GameMockType;
  selectedGroup: GroupType;
}

const GroupGameReport = (props: Props) => {
  const { users, gameData, selectedGroup } = props;

  Font.register({
    family: "Nunito",
    format: "truetype",
    src: NunitoRegular,
  });

  const getScoreColor = (score: number | null) => {
    let color = "#d32f2f";
    if (!score) color = "#fff";
    else if (score >= 51 && score <= 75) color = "orange";
    else if (score >= 76) color = "#a0d911";
    return color;
  };

  const renderRow = (user: GroupUser) => {
    const score =
      user.gameResults.length > 0 && user.gameResults[0]
        ? user.gameResults[0].score
        : null;

    const scoreDate =
      user.gameResults.length > 0 && user.gameResults[0]
        ? DateTime.fromISO(user.gameResults[0].userGameDate, {
            zone: "UTC",
          }).toFormat("dd.LL.yyyy")
        : "-";

    return (
      <View style={styles.tableRow} key={user.id}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{user.firstName}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{user.lastName}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{user.email}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text
            style={{
              ...styles.tableCell,
              color: getScoreColor(score),
            }}
          >
            {score ? `${score}${score && "%"}` : "-"}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{scoreDate}</Text>
        </View>
      </View>
    );
  };

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.logo} source={DigiLeaderLogo} />
        <View style={styles.buttonWrapper}>
          <Text style={styles.buttonText}>Raport z wynikami grupy</Text>
        </View>
        <Text
          style={styles.topInfo}
        >{`Nazwa grupy: ${selectedGroup.name}`}</Text>
        <Text
          style={styles.topInfo}
        >{`Nazwa rozegranej gry: ${gameData.gameTitle}`}</Text>
        <Text
          style={styles.topInfo}
        >{`Data wygenerowania raportu: ${DateTime.now().toFormat(
          "dd.LL.yyyy"
        )}`}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.header }}>
                Imię
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.header }}>
                Nazwisko
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.header }}>
                E-mail
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.header }}>
                Wynik
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.header }}>
                Data uzyskania wyniku
              </Text>
            </View>
          </View>
          {users.map((student) => renderRow(student))}
        </View>
      </Page>
    </Document>
  );
};

export default GroupGameReport;

const styles = StyleSheet.create({
  body: {
    fontFamily: "Nunito",
    padding: "20px 0",
  },
  logo: {
    width: 128,
    height: 56,
    margin: "0 auto 20 auto",
  },
  topInfo: {
    fontSize: 11,
    margin: "0px 20px 10px 20px",
    color: "#000",
  },
  buttonText: {
    color: "#363d52",
    textTransform: "uppercase",
    fontSize: 10,
  },
  buttonWrapper: {
    backgroundColor: "#00f8ff",
    padding: "8px 12px",
    margin: "0 auto",
    borderRadius: 8,
    marginBottom: 20,
  },
  table: {
    // @ts-ignore
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "20px 20px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    color: "#000",
  },
  header: {
    backgroundColor: "#00f8ff",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    backgroundColor: "#fff",
    padding: "4px 8px",
    fontSize: 8,
  },
});
