const html = `<h3 style="text-align: center;"><strong>Modelowy harmonogram tworzenia aplikacji szkoleniowej wykorzystującej technologię VR</strong></h3>
<h3 style="text-align: center;">&nbsp;</h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)&nbsp;</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Osoby odpowiedzialne/realizujące(zesp&oacute;ł)</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td><br />
<p><span style="font-weight: 400;">Analiza trend&oacute;w i możliwości wykorzystania VR w realizacji danego celu biznesowego&nbsp;</span></p>
<p><span style="font-weight: 400;">(cele biznesowe versus możliwości technologii)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1-2 (w zależności od wielkości organizacji, liczby os&oacute;b decyzyjnych, liczby os&oacute;b zaangażowanych w projekt)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konsultant ds. zastosowania technologii VR w biznesie&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Decyzja o realizacji projektu lub braku uzasadnienia biznesowego realizacji projektu.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Określenie grupy potencjalnych odbiorc&oacute;w rozwiązania&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konsultant ds. zastosowania technologii VR w biznesie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Podstawowe wytyczne w zakresie specyfiki rozwiązania VR skierowanego do danej grupy docelowej.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Stworzenie og&oacute;lnego scenariusza doświadczenia/interakcji z technologią VR&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konsultant ds. zastosowania technologii VR w biznesie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Opis działania rozwiązania (np. aplikacji) wykorzystującej VR.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przedstawienie założeń scenariusza odbiorcom finalnym rozwiązania&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konsultant ds. zastosowania technologii VR w biznesie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Decyzja o kontynuacji lub zakończeniu realizacji.&nbsp;</span></p>
</td>
</tr>
<tr>
<td><br />
<p><span style="font-weight: 400;">Określenie harmonogramu i zasob&oacute;w niezbędnych do realizacji projektu&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konsultant ds. zastosowania technologii VR w biznesie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Harmonogram projektu, zesp&oacute;ł projektowy.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie i zatwierdzenie scenariusza szkolenia/szczeg&oacute;łowe rozwiązania</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Specjalista tworzący dokument wykonawczy&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Finalny scenariusz szkolenia w formacie ms word + power point&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie wytycznych programistycznych (opis gł&oacute;wnych funkcjonalności)&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Specjalista tworzący dokument wykonawczy&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Opis wymagań programistycznych zawierający sposoby reakcji aplikacji na zachowania szkolonego/ użytkownika&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zebranie dokumentacji umożliwiającej stworzenie wizualizacji 3D&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">7</span></p>
</td>
<td>
<p><span style="font-weight: 400;">6</span></p>
</td>
<td>
<p><span style="font-weight: 400;">PM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zebranie kompletu dokumentacji np w prezentacji Power Point + folder plik&oacute;w&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Realizacja procesu modelowania 3D (wraz z akceptacją)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">13</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Modelarz</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Modelarz 3D</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przeniesienie sceny 3D do kodu. Tworzenie animacji 3D na postawie modeli 3D&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">17</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Animacje modeli 3D&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Programowanie wymaganych funkcjonalności&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">17</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programista&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pierwsza wersja rozwiązania&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Testy rozwiązania&nbsp;</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span style="font-weight: 400;">21</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Tester VR (osoba spoza projektu) lub osoby od klienta&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie dokumentacji oraz szkolenie klienta</span></p>
</td>
<td>&nbsp;</td>
<td>
<p><span style="font-weight: 400;">23</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Specjalista tworzący dokument wykonawczy, PM&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
