const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<h3 style="text-align: center;"><strong>Podstawy content planu</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Definicja content planu</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Content plan to szczeg&oacute;łowy harmonogram publikacji r&oacute;żnorodnych treści. Dotyczyć może np.</span> <span style="font-weight: 400;">&nbsp;tekst&oacute;w, grafik, video, audio na stronę www czy&nbsp; do social medi&oacute;w.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>WSKAZ&Oacute;WKI PRZY PRZYGOTOWYWANIU CONTENT PLANU</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Pamiętaj, że publikowane treści powinny&nbsp; nawiązywać do unikalnych cech promowanego produktu!&nbsp;</span></p>
<p><span style="font-weight: 400;">W komunikatach nawiąż do przyjętych założeń w zakresie archetypu marki!</span></p>
<p><span style="font-weight: 400;">Przygotuj treści, kt&oacute;re są interesujące z punktu widzenia określonej grupy docelowej!</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>PRZYKŁADOWE TYTUŁY TREŚCI TEKSTOWYCH ZAPLANOWANYCH DO PUBLIKACJI&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozszerzona rzeczywistość w onboardingu - o aplikacji&nbsp;</span></p>
<p><span style="font-weight: 400;">Trendy w social media - zobacz raport</span></p>
<p><span style="font-weight: 400;">Czy Europę czeka kryzys?</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>PRZYKŁADOWE OPISY KAMPANII PŁATNYCH W MEDIACH SPOŁECZNOŚCIOWYCH</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Promocja posta z video wraz ze skonfigurowaniem grupy niestandardowych odbiorc&oacute;w, kt&oacute;rzy widzieli co najmniej 50% video. Grupa będzie w kolejnych tygodniach wykorzystywana do remarketingu sprzedażowego.</span></p>
<p><span style="font-weight: 400;">Promocja wtorkowego posta na LINKEDIN. Kampania zasięgowa, optymalizowana na pozyskanie jak największej ilości przejść na stronę z case study.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
