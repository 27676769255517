import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RoleType } from "../../../enums/RoleType";
import { AppState } from "../../../store/store";
import CheckIcon from "../../../assets/images/icons/check_primary_icon.svg";
import StyledOptionsSwitch from "../../components/StyledOptionsSwitch";
import {
  buyProductExtendAccessOptions,
  subscriptionTime,
} from "../../../constants/mocks";
import ProductType from "../../../interfaces/payment/ProductType";
import ProductComponent from "./ProductComponent";
import SelectBubbleComponent from "../../components/SelectBubbleComponent";
import UserType from "../../../interfaces/user/UserType";
import { useNavigate } from "react-router-dom";

interface Props {
  onProductSelect: (product: ProductType) => void;
  extendAccessOption: string;
  setExtendAccessOption: (value: string) => void;
}

const whatYouGet = [
  "dostęp do wszystkich gier i konfiguratora",
  "dostęp do raportów, baz wiedzy, kursów (powiązanych z rozgranymi grami)",
  "dostęp do wyników i historii gier",
  "możliwość wygenrowania profilu cyfrowego",
];

const BuyProductComponent = (props: Props) => {
  const { t } = useTranslation();
  const { onProductSelect, extendAccessOption, setExtendAccessOption } = props;
  const navigate = useNavigate();

  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedProducts, setSelectedProducts] = useState<ProductType[]>([]);

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const isFetchingProducts: boolean = useSelector(
    (state: AppState) => state.paymentReducer.isFetchingProducts
  );

  const products: ProductType[] = useSelector(
    (state: AppState) => state.paymentReducer.products
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  useEffect(() => {
    if (role && role === RoleType.EDUCATOR) {
      setSelectedTime(subscriptionTime[0].key);
    }
  }, [role]);

  useEffect(() => {
    if (user && user.groupId) navigate("/dashboard");
  }, [user]);

  useEffect(() => {
    if (products) {
      console.log(products);
      let sortedProducts: ProductType[] = products
        .slice()
        .sort((a, b) => a.price.value - b.price.value);
      if (selectedTime)
        setSelectedProducts(
          sortedProducts.filter(
            (item) => item.duration === Number(selectedTime)
          )
        );
      else setSelectedProducts(sortedProducts);
    }
  }, [selectedTime, products]);

  const renderTopSection = () => {
    const isSubscriptionActive = user && user.hasSubscriptionActive;
    if (isSubscriptionActive && role === RoleType.EDUCATOR) {
      return (
        <div className="topSection">
          <SelectBubbleComponent
            selected={extendAccessOption}
            onChange={setExtendAccessOption}
            bubbles={buyProductExtendAccessOptions}
          />
        </div>
      );
    } else if (!isSubscriptionActive) {
      return (
        <div className="topSection">
          <div className="sectionHeader">
            {t("buyProductScreen.withAccessToThePlatformYouGet")}
          </div>
          {whatYouGet.map((item, idx) => (
            <div key={`${item}_${idx}`} className="whatYouGetRow">
              <img src={CheckIcon} alt="checkIcon" />
              <span>{item}</span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderHeader = () => {
    const isSubscriptionActive = user && user.hasSubscriptionActive;
    if (isSubscriptionActive) return "Przedłuż dostęp do platformy Digileader";
    else return t("buyProductScreen.buyAccess");
  };

  const getIsProductDisabled = (product: ProductType) => {
    const active = user!.hasSubscriptionActive;
    if (
      active &&
      (user!.subscriptionPlanId !== product.id ||
        extendAccessOption !== buyProductExtendAccessOptions[0].value)
    )
      return true;
    else return false;
  };

  return (
    <>
      <div className="componentHeader">{renderHeader()}</div>
      {renderTopSection()}
      <div className="sectionHeader">
        {t("buyProductScreen.selectThePeriod")}
      </div>
      {role === RoleType.EDUCATOR && (
        <StyledOptionsSwitch
          onChange={setSelectedTime}
          selected={selectedTime}
          options={subscriptionTime}
          color={"gray"}
        />
      )}
      {isFetchingProducts ? (
        <div>{"Loading"}</div>
      ) : (
        <div className="productsWrapper">
          {selectedProducts.map((product) => (
            <ProductComponent
              key={product.id}
              product={product}
              disabled={getIsProductDisabled(product)}
              onClick={() => onProductSelect(product)}
            />
          ))}
        </div>
      )}
      <div className="contactContainer">
        <div className="capslockText">
          {t("buyProductScreen.doYouHaveAnIdea")}
          <ul>
            <li>
              DODAć GRĘ REALIZUJĄCĄ INDYWIDUALNIE OKREŚLONE CELE EDUKACYJNE
            </li>
          </ul>
          <ul>
            <li>STWORZYć GRĘ, NA PODSTAWIE WŁASNEGO SCENARIUSZA</li>
          </ul>
        </div>
        <div className="sectionHeader">{t("buyProductScreen.contactUs")}</div>
        <span className="email">{process.env.REACT_APP_CONTACT_EMAIL}</span>
      </div>
    </>
  );
};

export default BuyProductComponent;
