import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainContext from "../../../context/MainContext";
import UserType from "../../../interfaces/user/UserType";
import { getGames } from "../../../services/game.service";
import { addGroup, getGroups } from "../../../services/group.service";
import { getNumberOfActiveLicences } from "../../../services/user.service";
import { AppDispatch, AppState } from "../../../store/store";
import CustomModal from "../../components/CustomModal";
import SlimTabsComponent from "../../components/SlimTabsComponent";
import StyledTextInput from "../../components/StyledTextInput";
import Games from "./tabs/games/Games";
import Groups from "./tabs/groups/Groups";
import Scores from "./tabs/scores/Scores";
import { DateTime } from "luxon";

interface FormValues {
  name: string;
}

const OrganizationPanelScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const methods = useForm<FormValues>({ defaultValues: { name: "" } });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;
  const mainContext = useContext(MainContext);
  const { organizationPanelTab, showDefaultModal } = mainContext;
  const [addGroupModalOpen, setIsGroupModalOpen] = useState<boolean>(false);

  const activeLicences: number = useSelector(
    (state: AppState) => state.userReducer.activeLicences
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  // useEffect(() => {
  //   if (showDefaultModal)
  //     showDefaultModal({
  //       type: "success",
  //       header: "Usuń grupę",
  //       text: `Czy na pewno chcesz usunąć grupę ${"GRUPA"}?`,
  //       submitLabel: "Potwierdź",
  //       onSubmit: () => {},
  //     });
  // }, [showDefaultModal]);

  useEffect(() => {
    if (dispatch) {
      dispatch(getGroups());
      dispatch(getGames(true, true));
      dispatch(getNumberOfActiveLicences());
    }
  }, [dispatch]);

  const tabs = useMemo(
    () => [
      {
        title: "Grupy użytkowników",
        component: (
          <Groups setIsGroupModalOpen={() => setIsGroupModalOpen(true)} />
        ),
      },
      {
        title: "Wyniki graczy",
        component: <Scores />,
      },
      {
        title: "Lista gier",
        component: <Games />,
      },
    ],
    []
  );

  const topNumberDiv = (label: string, value: string | number) => {
    return (
      <div className="topPartNumberContainer">
        <span className="label">{label}</span>
        <span className="value">{value}</span>
      </div>
    );
  };

  const renderTab = useCallback(() => {
    switch (organizationPanelTab) {
      case tabs[0].title:
        return tabs[0].component;
      case tabs[1].title:
        return tabs[1].component;
      case tabs[2].title:
        return tabs[2].component;
      default:
        return tabs[0].component;
    }
  }, [tabs, organizationPanelTab]);

  const handleAddGroup = (data: any) => {
    dispatch(addGroup({ userId, name: data.name }));
    setIsGroupModalOpen(false);
  };

  return (
    <>
      <div className="organizationPanelScreen">
        <div className="top">
          <span>{"Panel organizacji"}</span>
          <div className="top_leftPart">
            {user!.hasSubscriptionActive && user!.subscriptionEndDate ? (
              <>
                {topNumberDiv("Licencje", user!.numberOfLicences)}
                {topNumberDiv("Aktywne", activeLicences)}
                {topNumberDiv(
                  "Dostęp aktywny do",
                  DateTime.fromISO(user!.subscriptionEndDate!, {
                    zone: "UTC",
                  }).toFormat("dd LLLL yyyy")
                )}
              </>
            ) : (
              "Subskrypcja nieaktywna"
            )}
          </div>
        </div>
        <div className="bottom">
          <SlimTabsComponent
            tabs={useMemo(() => tabs.map((tab) => tab.title), [tabs])}
          />
          <div className="tabContent">{renderTab()}</div>
        </div>
      </div>
      {addGroupModalOpen && (
        <CustomModal
          onCancel={() => setIsGroupModalOpen(false)}
          onSubmit={handleSubmit(handleAddGroup)}
          submitLabel="Zapisz"
          header="Dodaj grupę"
        >
          <FormProvider {...methods}>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <StyledTextInput
                  value={value}
                  onChange={onChange}
                  error={errors.name && errors.name.message}
                  label={"Nazwa grupy"}
                  color="neutral"
                  placeholder={"Wpisz nazwę grupy..."}
                />
              )}
              rules={{ required: "Pole wymagane" }}
            />
          </FormProvider>
        </CustomModal>
      )}
    </>
  );
};

export default OrganizationPanelScreen;
