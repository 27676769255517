const html = `<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację usługi z obszaru wdrożenia rozwiązania e-commerce</span></strong></p>
<p><br /><br /></p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki wdrożeniu platformy e-commerce:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">spadająca sprzedaż&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zagrożenie problemami finansowymi w sytuacji małej dywersyfikacji kanał&oacute;w sprzedaży</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ryzyko pozostawania w tyle za konkurencją&nbsp;</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Proponowane rozwiązanie to platforma sprzedażowa zbudowana w oparciu o silnik wiodącej rynkowej platformy e-commerce, zintegrowana z systemami klienta, klasy ERP i WMS. Platforma służyć będzie do prowadzenia sprzedaży detalicznej w branży fashion. Rozwiązanie posiadać będzie moduły umożliwiające konkurowanie ze sklepami internetowymi wiodących producent&oacute;w odzieży. Platforma posiadać będzie panel administracyjny, za pomocą kt&oacute;rego możliwe będzie konfigurowanie ustawień sklepu i prowadzenie efektywnej sprzedaży.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">dostęp do informacji o aktualnym stanie magazynowym w czasie rzeczywistym w panelu obsługi sklepu internetowego&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">nowoczesny wygląd sklepu zgodny z trendami w branży&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">możliwość zarządzania całą sprzedażą internetową (z wykorzystaniem r&oacute;żnych kanał&oacute;w sprzedaży) z jednego panelu</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające działania z obszaru e-commerce.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zwiększenie sprzedaży</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zbudowanie rozpoznawalności w segmencie B2C [segment business to customers, segment firm kierujących produkty do odbiorc&oacute;w indywidualnych]</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">w ciągu 2 lat zbudowanie łącznego przychodu z nowego kanału sprzedaży na poziomie 30% przychod&oacute;w całkowitych </span></li>
</ul>`;

export default html;
