import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onChange: (value: string) => void;
  options: { name: string; key: string }[];
  selected: string;
  color?: "gray";
  disabled?: boolean;
}

const StyledOptionsSwitch = (props: Props) => {
  const { t } = useTranslation();
  const { options, onChange, selected, color, disabled } = props;

  const handleOnChange = (key: string) => {
    if (onChange && !disabled) {
      onChange(key);
    }
  };

  return (
    <div className={`styledOptionsSwitch`}>
      {options.map((option) => (
        <div
          className={`styledOption ${
            selected === option.key ? "selected" : ""
          } ${color}  ${disabled ? "disabled" : ""}`}
          key={option.key}
          onClick={() => handleOnChange(option.key)}
        >
          {option.name}
        </div>
      ))}
    </div>
  );
};

export default StyledOptionsSwitch;
