const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Zadanie/Etap prac w odpowiedniej kolejności)</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Osoby odpowiedzialne/realizujące (zesp&oacute;ł)</strong></p>
</td>
<td>
<p><strong>Efekt realizacji działania</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Analiza projektowa:</strong></p>
<p><span style="font-weight: 400;">analiza otrzymanych wytycznych, opracowanie struktury podziału prac, wyznaczenie zasob&oacute;w, stworzenie harmonogramu i kosztorysu szczeg&oacute;łowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">project manager lub marketing manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Struktura podziału prac;</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">harmonogram z alokacją zasob&oacute;w;</span></p>
<p><span style="font-weight: 400;">kosztorys szczeg&oacute;łowy.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Prace przygotowawcze:</strong></p>
<p><span style="font-weight: 400;">opracowanie strategii (komunikacji/marki/kampanii), koncepcji kreatywnej i taktycznego planu działań.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1-2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">project manager,</span></p>
<p><span style="font-weight: 400;">digital strategist, copywriter, senior designer </span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"><br /><br /></span></p>
</td>
<td>
<p><span style="font-weight: 400;">Strategia w formie dokumentu;</span></p>
<p><span style="font-weight: 400;">propozycje kreacji;</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">plan kampanii z wyznaczonymi KPI, logiką lejk&oacute;w sprzedażowych, planem egzekucyjnym.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Preprodukcja treści:</strong><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">tworzenie treści audio-wizualnych i tekstowych. Dostosowanie do wymog&oacute;w niezbędnych format&oacute;w reklamowych. Budowa content-plan&oacute;w.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">copywriter,</span></p>
<p><span style="font-weight: 400;">designer, social media manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Nośniki i kreacje gotowe do publikacji.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Realizacja działań marketingowych:</strong><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">prowadzenie komunikacji (publikacja post&oacute;w, relacji, innych treści); moderacja (reagowanie na komentarze, aktywność animująca grupy zainteresowań; usuwanie treści niestosownych); obsługa kampanii płatnych; social media listening;&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3-4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">czas określony w zleceniu</span></p>
</td>
<td>
<p><span style="font-weight: 400;">social media manager, moderator, młodszy analityk, social ads manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Działania przeprowadzone zgodnie z oczekiwaniami Klienta i opracowaną strategią; zrealizowane cele projektu.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Raportowanie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">zależny od czasu trwania zlecenia</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">starszy analityk / marketing manager / social ads manager / digital strategist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport(y) podsumowujący projekt i jego poszczeg&oacute;lne fazy, zawierający poziom zrealizowania osiągniętych cel&oacute;w, ocenę skuteczności założeń początkowych i rekomendacje na przyszłość.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
