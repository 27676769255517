const html = `<p><strong>CASE STUDY PROJEKTU</strong></p>
<p><strong>Tytuł projektu: PoV systemu do wyszukiwania ryzykownych wpis&oacute;w księgowych na cele przeciwdziałania &bdquo;praniu brudnych pieniędzy&rdquo; międzynarodowej korporacji finansowej</strong><span style="font-weight: 400;">&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Czasochłonne działania analityczne wymagane przez instytucje publiczne. Niedopełnienie wymagań to potencjalne straty wizerunkowe i kary pieniężne. Obecnie wykorzystywany system generuje zbyt dużo alert&oacute;w dla analityk&oacute;w (system oparty o zestaw reguł).</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">W ramach projektu wytworzono Proof of Value zaawansowanego systemu do wyszukiwania i trafnego identyfikowania podejrzanych transakcji, kt&oacute;re podlegać muszą dalszej analizie eksperckiej. Działanie systemu oparte zostało na indywidualnie opracowanym modelu ML/DL [deep learning].</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Stworzenie pierwszej wersji systemu, kt&oacute;ry przy wdrożeniu produkcyjnym potencjalnie zwiększyłby trafności wyszukiwania dzięki czemu zmniejszy się obciążenie zespołu analitycznego (AML). </span><strong>UWAGA!</strong><span style="font-weight: 400;"> Klient nie zdecydował się na wdrożenie produkcyjne rozwiązania ze względu na cięcia budżetowe. Klient może wykorzystywać stworzony model do analiz oraz rozwijać go we własnym zakresie. Bez wdrożenia produkcyjnego wymaga to dodatkowych krok&oacute;w manualnych i jest czasochłonne.&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Hurtownia danych (Snowflake), data lake (Azure Data Lake), proof of value z wykorzystaniem bibliotek ML (m.in. PyTorch)</span></p>
<p><strong>Czas trwania projektu</strong><span style="font-weight: 400;">: 2 msc</span></p>
<p><strong>Zesp&oacute;ł: PM (0,25 FTE), Data Scientist (0,5 FTE), ML Engineer (0,5 FTE), Snowflake Data Engineer (0,5 FTE)</strong></p>
<p><strong>Budżet projektu:&nbsp; 175 tys. zł&nbsp;</strong></p>`;

export default html;
