const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Narzędzie ETL (Extract, Transform, Load) to oprogramowanie, kt&oacute;re ułatwia proces pobrania, przetwarzania i ładowania danych z r&oacute;żnych źr&oacute;deł do tzw. centralnej bazy danych (np. hurtowni danych, jeziora danych).&nbsp;Pozwala ono zbudować proces przepływu danych zgodnie z oczekiwanymi regułami biznesowymi oraz nadać typy poszczeg&oacute;lnym rodzajom danych (np. numeryczny, co umożliwia dodawanie arytmetyczne).</p>
<p style="text-align: justify;">Narzędzie ETL oferuje szereg funkcjonalności, kt&oacute;re umożliwiają automatyzację proces&oacute;w ETL i znacznie przyspieszają ich realizację. Narzędzia ETL umożliwia pobranie danych z r&oacute;żnych źr&oacute;deł, takich jak bazy danych, pliki tekstowe, strumienie danych, a także zewnętrzne źr&oacute;dła, takie jak API czy chmury obliczeniowe. Dzięki technologii ETL możliwe jest takie przetworzenie danych jak: filtrowanie, sortowanie, kategoryzacja, agregacja i normalizacja danych.&nbsp;</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">ETL umożliwia też mapowanie danych, łączenie r&oacute;żnych źr&oacute;deł danych i dopasowanie ich do docelowej struktury danych, automatyzację proces&oacute;w harmonogramowania zadań, automatyczne wykrywanie zmian w źr&oacute;dłach danych i aktualizowanie centralnej bazy danych. ETL umożłiwia integracja z r&oacute;żnymi systemami, takimi jak bazy danych, narzędzia BI (Business Intelligence), narzędzia analityczne, a także np.&nbsp;czyszczenia danych&nbsp;z białych znak&oacute;w np. spacji.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Znajomość procesu ETL, obsługa konfiguratora, mapowanie danych.&nbsp;Narzędzie obsługiwane być może (w zależności od rodzaju) z użyciem interfejsu graficznego, nie wymagane programowania.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Przykłady:</strong>&nbsp;Apache Nifi, Talend, Informatica PowerCenter, Microsoft SQL Server Integration Services (SSIS), Oracle Data Integrator (ODI), Pentaho Data Integration</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Przy wyborze narzędzia należy wziąć pod uwagę m.in. wykorzystywane już w organizacji technologie, tak by narzędzie ETL zapewniało sp&oacute;jną komunikację z innymi systemami. </p>`;

export default html;
