import ai_ml_1_report_01 from "./ai_ml_1/ai_ml_1_report_01";
import ai_ml_1_report_02 from "./ai_ml_1/ai_ml_1_report_02";
import ai_ml_1_report_03 from "./ai_ml_1/ai_ml_1_report_03";
import ai_ml_1_report_04 from "./ai_ml_1/ai_ml_1_report_04";
import ai_ml_1_report_05 from "./ai_ml_1/ai_ml_1_report_05";
import ai_ml_1_report_06 from "./ai_ml_1/ai_ml_1_report_06";
import ai_ml_1_report_07 from "./ai_ml_1/ai_ml_1_report_07";
import ai_ml_1_report_08 from "./ai_ml_1/ai_ml_1_report_08";
import ai_ml_1_report_09 from "./ai_ml_1/ai_ml_1_report_09";
import ai_ml_1_report_10 from "./ai_ml_1/ai_ml_1_report_10";
import ai_ml_1_report_11 from "./ai_ml_1/ai_ml_1_report_11";
import ai_ml_1_report_12 from "./ai_ml_1/ai_ml_1_report_12";
import ai_ml_1_report_13 from "./ai_ml_1/ai_ml_1_report_13";
import ai_ml_1_report_14 from "./ai_ml_1/ai_ml_1_report_14";
import ai_ml_1_report_15 from "./ai_ml_1/ai_ml_1_report_15";
import ai_ml_1_report_16 from "./ai_ml_1/ai_ml_1_report_16";
import ai_ml_1_report_17 from "./ai_ml_1/ai_ml_1_report_17";
import ai_ml_1_report_18 from "./ai_ml_1/ai_ml_1_report_18";
import ai_ml_1_report_19 from "./ai_ml_1/ai_ml_1_report_19";
import ai_ml_1_report_20 from "./ai_ml_1/ai_ml_1_report_20";
import ai_ml_2_report_01 from "./ai_ml_2/ai_ml_2_report_01";
import ai_ml_2_report_02 from "./ai_ml_2/ai_ml_2_report_02";
import ai_ml_2_report_03 from "./ai_ml_2/ai_ml_2_report_03";
import ai_ml_2_report_04 from "./ai_ml_2/ai_ml_2_report_04";
import ai_ml_2_report_05 from "./ai_ml_2/ai_ml_2_report_05";
import ai_ml_2_report_06 from "./ai_ml_2/ai_ml_2_report_06";
import ai_ml_2_report_07 from "./ai_ml_2/ai_ml_2_report_07";
import ai_ml_2_report_08 from "./ai_ml_2/ai_ml_2_report_08";
import ai_ml_2_report_09 from "./ai_ml_2/ai_ml_2_report_09";
import ai_ml_2_report_10 from "./ai_ml_2/ai_ml_2_report_10";
import ai_ml_2_report_11 from "./ai_ml_2/ai_ml_2_report_11";
import ai_ml_2_report_12 from "./ai_ml_2/ai_ml_2_report_12";
import ai_ml_2_report_13 from "./ai_ml_2/ai_ml_2_report_13";
import ai_ml_2_report_14 from "./ai_ml_2/ai_ml_2_report_14";
import ai_ml_2_report_15 from "./ai_ml_2/ai_ml_2_report_15";
import ai_ml_2_report_16 from "./ai_ml_2/ai_ml_2_report_16";
import ai_ml_2_report_17 from "./ai_ml_2/ai_ml_2_report_17";
import ai_ml_2_report_18 from "./ai_ml_2/ai_ml_2_report_18";
import ai_ml_2_report_19 from "./ai_ml_2/ai_ml_2_report_19";
import ai_ml_2_report_20 from "./ai_ml_2/ai_ml_2_report_20";
import bi_1_report_01 from "./bi_1/bi_1_report_01";
import bi_1_report_02 from "./bi_1/bi_1_report_02";
import bi_1_report_03 from "./bi_1/bi_1_report_03";
import bi_1_report_04 from "./bi_1/bi_1_report_04";
import bi_1_report_05 from "./bi_1/bi_1_report_05";
import bi_1_report_06 from "./bi_1/bi_1_report_06";
import bi_1_report_07 from "./bi_1/bi_1_report_07";
import bi_1_report_08 from "./bi_1/bi_1_report_08";
import bi_1_report_09 from "./bi_1/bi_1_report_09";
import bi_1_report_10 from "./bi_1/bi_1_report_10";
import bi_1_report_11 from "./bi_1/bi_1_report_11";
import bi_1_report_12 from "./bi_1/bi_1_report_12";
import bi_1_report_13 from "./bi_1/bi_1_report_13";
import bi_1_report_14 from "./bi_1/bi_1_report_14";
import bi_1_report_15 from "./bi_1/bi_1_report_15";
import bi_1_report_16 from "./bi_1/bi_1_report_16";
import bi_1_report_17 from "./bi_1/bi_1_report_17";
import bi_1_report_18 from "./bi_1/bi_1_report_18";
import bi_1_report_19 from "./bi_1/bi_1_report_19";
import bi_1_report_20 from "./bi_1/bi_1_report_20";
import bi_2_report_01 from "./bi_2/bi_2_report_01";
import bi_2_report_02 from "./bi_2/bi_2_report_02";
import bi_2_report_03 from "./bi_2/bi_2_report_03";
import bi_2_report_04 from "./bi_2/bi_2_report_04";
import bi_2_report_05 from "./bi_2/bi_2_report_05";
import bi_2_report_06 from "./bi_2/bi_2_report_06";
import bi_2_report_07 from "./bi_2/bi_2_report_07";
import bi_2_report_08 from "./bi_2/bi_2_report_08";
import bi_2_report_09 from "./bi_2/bi_2_report_09";
import bi_2_report_10 from "./bi_2/bi_2_report_10";
import bi_2_report_11 from "./bi_2/bi_2_report_11";
import bi_2_report_12 from "./bi_2/bi_2_report_12";
import bi_2_report_13 from "./bi_2/bi_2_report_13";
import bi_2_report_14 from "./bi_2/bi_2_report_14";
import bi_2_report_15 from "./bi_2/bi_2_report_15";
import bi_2_report_16 from "./bi_2/bi_2_report_16";
import bi_2_report_17 from "./bi_2/bi_2_report_17";
import bi_2_report_18 from "./bi_2/bi_2_report_18";
import bi_2_report_19 from "./bi_2/bi_2_report_19";
import bi_2_report_20 from "./bi_2/bi_2_report_20";
import blockchain_1_report_01 from "./blockchain_1/blockchain_1_report_01";
import blockchain_1_report_02 from "./blockchain_1/blockchain_1_report_02";
import blockchain_1_report_03 from "./blockchain_1/blockchain_1_report_03";
import blockchain_1_report_04 from "./blockchain_1/blockchain_1_report_04";
import blockchain_1_report_05 from "./blockchain_1/blockchain_1_report_05";
import blockchain_1_report_06 from "./blockchain_1/blockchain_1_report_06";
import blockchain_1_report_07 from "./blockchain_1/blockchain_1_report_07";
import blockchain_1_report_08 from "./blockchain_1/blockchain_1_report_08";
import blockchain_1_report_09 from "./blockchain_1/blockchain_1_report_09";
import blockchain_1_report_10 from "./blockchain_1/blockchain_1_report_10";
import blockchain_1_report_11 from "./blockchain_1/blockchain_1_report_11";
import blockchain_1_report_12 from "./blockchain_1/blockchain_1_report_12";
import blockchain_1_report_13 from "./blockchain_1/blockchain_1_report_13";
import blockchain_1_report_14 from "./blockchain_1/blockchain_1_report_14";
import blockchain_1_report_15 from "./blockchain_1/blockchain_1_report_15";
import blockchain_1_report_16 from "./blockchain_1/blockchain_1_report_16";
import blockchain_1_report_17 from "./blockchain_1/blockchain_1_report_17";
import blockchain_1_report_18 from "./blockchain_1/blockchain_1_report_18";
import blockchain_1_report_19 from "./blockchain_1/blockchain_1_report_19";
import blockchain_1_report_20 from "./blockchain_1/blockchain_1_report_20";
import blockchain_2_report_01 from "./blockchain_2/blockchain_2_report_01";
import blockchain_2_report_02 from "./blockchain_2/blockchain_2_report_02";
import blockchain_2_report_03 from "./blockchain_2/blockchain_2_report_03";
import blockchain_2_report_04 from "./blockchain_2/blockchain_2_report_04";
import blockchain_2_report_05 from "./blockchain_2/blockchain_2_report_05";
import blockchain_2_report_06 from "./blockchain_2/blockchain_2_report_06";
import blockchain_2_report_07 from "./blockchain_2/blockchain_2_report_07";
import blockchain_2_report_08 from "./blockchain_2/blockchain_2_report_08";
import blockchain_2_report_09 from "./blockchain_2/blockchain_2_report_09";
import blockchain_2_report_10 from "./blockchain_2/blockchain_2_report_10";
import blockchain_2_report_11 from "./blockchain_2/blockchain_2_report_11";
import blockchain_2_report_12 from "./blockchain_2/blockchain_2_report_12";
import blockchain_2_report_13 from "./blockchain_2/blockchain_2_report_13";
import blockchain_2_report_14 from "./blockchain_2/blockchain_2_report_14";
import blockchain_2_report_15 from "./blockchain_2/blockchain_2_report_15";
import blockchain_2_report_16 from "./blockchain_2/blockchain_2_report_16";
import blockchain_2_report_17 from "./blockchain_2/blockchain_2_report_17";
import blockchain_2_report_18 from "./blockchain_2/blockchain_2_report_18";
import blockchain_2_report_19 from "./blockchain_2/blockchain_2_report_19";
import blockchain_2_report_20 from "./blockchain_2/blockchain_2_report_20";
import cloud_1_report_01 from "./cloud_1/cloud_1_report_01";
import cloud_1_report_02 from "./cloud_1/cloud_1_report_02";
import cloud_1_report_03 from "./cloud_1/cloud_1_report_03";
import cloud_1_report_04 from "./cloud_1/cloud_1_report_04";
import cloud_1_report_05 from "./cloud_1/cloud_1_report_05";
import cloud_1_report_06 from "./cloud_1/cloud_1_report_06";
import cloud_1_report_07 from "./cloud_1/cloud_1_report_07";
import cloud_1_report_08 from "./cloud_1/cloud_1_report_08";
import cloud_1_report_09 from "./cloud_1/cloud_1_report_09";
import cloud_1_report_10 from "./cloud_1/cloud_1_report_10";
import cloud_1_report_11 from "./cloud_1/cloud_1_report_11";
import cloud_1_report_12 from "./cloud_1/cloud_1_report_12";
import cloud_1_report_13 from "./cloud_1/cloud_1_report_13";
import cloud_1_report_14 from "./cloud_1/cloud_1_report_14";
import cloud_1_report_15 from "./cloud_1/cloud_1_report_15";
import cloud_1_report_16 from "./cloud_1/cloud_1_report_16";
import cloud_1_report_17 from "./cloud_1/cloud_1_report_17";
import cloud_1_report_18 from "./cloud_1/cloud_1_report_18";
import cloud_1_report_19 from "./cloud_1/cloud_1_report_19";
import cloud_1_report_20 from "./cloud_1/cloud_1_report_20";
import cloud_2_report_01 from "./cloud_2/cloud_2_report_01";
import cloud_2_report_02 from "./cloud_2/cloud_2_report_02";
import cloud_2_report_03 from "./cloud_2/cloud_2_report_03";
import cloud_2_report_04 from "./cloud_2/cloud_2_report_04";
import cloud_2_report_05 from "./cloud_2/cloud_2_report_05";
import cloud_2_report_06 from "./cloud_2/cloud_2_report_06";
import cloud_2_report_07 from "./cloud_2/cloud_2_report_07";
import cloud_2_report_08 from "./cloud_2/cloud_2_report_08";
import cloud_2_report_09 from "./cloud_2/cloud_2_report_09";
import cloud_2_report_10 from "./cloud_2/cloud_2_report_10";
import cloud_2_report_11 from "./cloud_2/cloud_2_report_11";
import cloud_2_report_12 from "./cloud_2/cloud_2_report_12";
import cloud_2_report_13 from "./cloud_2/cloud_2_report_13";
import cloud_2_report_14 from "./cloud_2/cloud_2_report_14";
import cloud_2_report_15 from "./cloud_2/cloud_2_report_15";
import cloud_2_report_16 from "./cloud_2/cloud_2_report_16";
import cloud_2_report_17 from "./cloud_2/cloud_2_report_17";
import cloud_2_report_18 from "./cloud_2/cloud_2_report_18";
import cloud_2_report_19 from "./cloud_2/cloud_2_report_19";
import cloud_2_report_20 from "./cloud_2/cloud_2_report_20";
import vr_ar_1_report_01 from "./vr_ar_1/vr_ar_1_report_01";
import vr_ar_1_report_02 from "./vr_ar_1/vr_ar_1_report_02";
import vr_ar_1_report_03 from "./vr_ar_1/vr_ar_1_report_03";
import vr_ar_1_report_04 from "./vr_ar_1/vr_ar_1_report_04";
import vr_ar_1_report_05 from "./vr_ar_1/vr_ar_1_report_05";
import vr_ar_1_report_06 from "./vr_ar_1/vr_ar_1_report_06";
import vr_ar_1_report_07 from "./vr_ar_1/vr_ar_1_report_07";
import vr_ar_1_report_08 from "./vr_ar_1/vr_ar_1_report_08";
import vr_ar_1_report_09 from "./vr_ar_1/vr_ar_1_report_09";
import vr_ar_1_report_10 from "./vr_ar_1/vr_ar_1_report_10";
import vr_ar_1_report_11 from "./vr_ar_1/vr_ar_1_report_11";
import vr_ar_1_report_12 from "./vr_ar_1/vr_ar_1_report_12";
import vr_ar_1_report_13 from "./vr_ar_1/vr_ar_1_report_13";
import vr_ar_1_report_14 from "./vr_ar_1/vr_ar_1_report_14";
import vr_ar_1_report_15 from "./vr_ar_1/vr_ar_1_report_15";
import vr_ar_1_report_16 from "./vr_ar_1/vr_ar_1_report_16";
import vr_ar_1_report_17 from "./vr_ar_1/vr_ar_1_report_17";
import vr_ar_1_report_18 from "./vr_ar_1/vr_ar_1_report_18";
import vr_ar_1_report_19 from "./vr_ar_1/vr_ar_1_report_19";
import vr_ar_1_report_20 from "./vr_ar_1/vr_ar_1_report_20";
import vr_ar_2_report_01 from "./vr_ar_2/vr_ar_2_report_01";
import vr_ar_2_report_02 from "./vr_ar_2/vr_ar_2_report_02";
import vr_ar_2_report_03 from "./vr_ar_2/vr_ar_2_report_03";
import vr_ar_2_report_04 from "./vr_ar_2/vr_ar_2_report_04";
import vr_ar_2_report_05 from "./vr_ar_2/vr_ar_2_report_05";
import vr_ar_2_report_06 from "./vr_ar_2/vr_ar_2_report_06";
import vr_ar_2_report_07 from "./vr_ar_2/vr_ar_2_report_07";
import vr_ar_2_report_08 from "./vr_ar_2/vr_ar_2_report_08";
import vr_ar_2_report_09 from "./vr_ar_2/vr_ar_2_report_09";
import vr_ar_2_report_10 from "./vr_ar_2/vr_ar_2_report_10";
import vr_ar_2_report_11 from "./vr_ar_2/vr_ar_2_report_11";
import vr_ar_2_report_12 from "./vr_ar_2/vr_ar_2_report_12";
import vr_ar_2_report_13 from "./vr_ar_2/vr_ar_2_report_13";
import vr_ar_2_report_14 from "./vr_ar_2/vr_ar_2_report_14";
import vr_ar_2_report_15 from "./vr_ar_2/vr_ar_2_report_15";
import vr_ar_2_report_16 from "./vr_ar_2/vr_ar_2_report_16";
import vr_ar_2_report_17 from "./vr_ar_2/vr_ar_2_report_17";
import vr_ar_2_report_18 from "./vr_ar_2/vr_ar_2_report_18";
import vr_ar_2_report_19 from "./vr_ar_2/vr_ar_2_report_19";
import vr_ar_2_report_20 from "./vr_ar_2/vr_ar_2_report_20";
import iot_1_report_01 from "./iot_1/iot_1_report_01";
import iot_1_report_02 from "./iot_1/iot_1_report_02";
import iot_1_report_03 from "./iot_1/iot_1_report_03";
import iot_1_report_04 from "./iot_1/iot_1_report_04";
import iot_1_report_05 from "./iot_1/iot_1_report_05";
import iot_1_report_06 from "./iot_1/iot_1_report_06";
import iot_1_report_07 from "./iot_1/iot_1_report_07";
import iot_1_report_08 from "./iot_1/iot_1_report_08";
import iot_1_report_09 from "./iot_1/iot_1_report_09";
import iot_1_report_10 from "./iot_1/iot_1_report_10";
import iot_1_report_11 from "./iot_1/iot_1_report_11";
import iot_1_report_12 from "./iot_1/iot_1_report_12";
import iot_1_report_13 from "./iot_1/iot_1_report_13";
import iot_1_report_14 from "./iot_1/iot_1_report_14";
import iot_1_report_15 from "./iot_1/iot_1_report_15";
import iot_1_report_16 from "./iot_1/iot_1_report_16";
import iot_1_report_17 from "./iot_1/iot_1_report_17";
import iot_1_report_18 from "./iot_1/iot_1_report_18";
import iot_1_report_19 from "./iot_1/iot_1_report_19";
import iot_1_report_20 from "./iot_1/iot_1_report_20";
import iot_2_report_01 from "./iot_2/iot_2_report_01";
import iot_2_report_02 from "./iot_2/iot_2_report_02";
import iot_2_report_03 from "./iot_2/iot_2_report_03";
import iot_2_report_04 from "./iot_2/iot_2_report_04";
import iot_2_report_05 from "./iot_2/iot_2_report_05";
import iot_2_report_06 from "./iot_2/iot_2_report_06";
import iot_2_report_07 from "./iot_2/iot_2_report_07";
import iot_2_report_08 from "./iot_2/iot_2_report_08";
import iot_2_report_09 from "./iot_2/iot_2_report_09";
import iot_2_report_10 from "./iot_2/iot_2_report_10";
import iot_2_report_11 from "./iot_2/iot_2_report_11";
import iot_2_report_12 from "./iot_2/iot_2_report_12";
import iot_2_report_13 from "./iot_2/iot_2_report_13";
import iot_2_report_14 from "./iot_2/iot_2_report_14";
import iot_2_report_15 from "./iot_2/iot_2_report_15";
import iot_2_report_16 from "./iot_2/iot_2_report_16";
import iot_2_report_17 from "./iot_2/iot_2_report_17";
import iot_2_report_18 from "./iot_2/iot_2_report_18";
import iot_2_report_19 from "./iot_2/iot_2_report_19";
import iot_2_report_20 from "./iot_2/iot_2_report_20";
import sm_1_report_01 from "./sm_1/sm_1_report_01";
import sm_1_report_02 from "./sm_1/sm_1_report_02";
import sm_1_report_03 from "./sm_1/sm_1_report_03";
import sm_1_report_04 from "./sm_1/sm_1_report_04";
import sm_1_report_05 from "./sm_1/sm_1_report_05";
import sm_1_report_06 from "./sm_1/sm_1_report_06";
import sm_1_report_07 from "./sm_1/sm_1_report_07";
import sm_1_report_08 from "./sm_1/sm_1_report_08";
import sm_1_report_09 from "./sm_1/sm_1_report_09";
import sm_1_report_10 from "./sm_1/sm_1_report_10";
import sm_1_report_11 from "./sm_1/sm_1_report_11";
import sm_1_report_12 from "./sm_1/sm_1_report_12";
import sm_1_report_13 from "./sm_1/sm_1_report_13";
import sm_1_report_14 from "./sm_1/sm_1_report_14";
import sm_1_report_15 from "./sm_1/sm_1_report_15";
import sm_1_report_16 from "./sm_1/sm_1_report_16";
import sm_1_report_17 from "./sm_1/sm_1_report_17";
import sm_1_report_18 from "./sm_1/sm_1_report_18";
import sm_1_report_19 from "./sm_1/sm_1_report_19";
import sm_1_report_20 from "./sm_1/sm_1_report_20";
import sm_2_report_01 from "./sm_2/sm_2_report_01";
import sm_2_report_02 from "./sm_2/sm_2_report_02";
import sm_2_report_03 from "./sm_2/sm_2_report_03";
import sm_2_report_04 from "./sm_2/sm_2_report_04";
import sm_2_report_05 from "./sm_2/sm_2_report_05";
import sm_2_report_06 from "./sm_2/sm_2_report_06";
import sm_2_report_07 from "./sm_2/sm_2_report_07";
import sm_2_report_08 from "./sm_2/sm_2_report_08";
import sm_2_report_09 from "./sm_2/sm_2_report_09";
import sm_2_report_10 from "./sm_2/sm_2_report_10";
import sm_2_report_11 from "./sm_2/sm_2_report_11";
import sm_2_report_12 from "./sm_2/sm_2_report_12";
import sm_2_report_13 from "./sm_2/sm_2_report_13";
import sm_2_report_14 from "./sm_2/sm_2_report_14";
import sm_2_report_15 from "./sm_2/sm_2_report_15";
import sm_2_report_16 from "./sm_2/sm_2_report_16";
import sm_2_report_17 from "./sm_2/sm_2_report_17";
import sm_2_report_18 from "./sm_2/sm_2_report_18";
import sm_2_report_19 from "./sm_2/sm_2_report_19";
import sm_2_report_20 from "./sm_2/sm_2_report_20";
import e_commerce_1_report_01 from "./e_commerce_1/e_commerce_1_report_01";
import e_commerce_1_report_02 from "./e_commerce_1/e_commerce_1_report_02";
import e_commerce_1_report_03 from "./e_commerce_1/e_commerce_1_report_03";
import e_commerce_1_report_04 from "./e_commerce_1/e_commerce_1_report_04";
import e_commerce_1_report_05 from "./e_commerce_1/e_commerce_1_report_05";
import e_commerce_1_report_06 from "./e_commerce_1/e_commerce_1_report_06";
import e_commerce_1_report_07 from "./e_commerce_1/e_commerce_1_report_07";
import e_commerce_1_report_08 from "./e_commerce_1/e_commerce_1_report_08";
import e_commerce_1_report_09 from "./e_commerce_1/e_commerce_1_report_09";
import e_commerce_1_report_10 from "./e_commerce_1/e_commerce_1_report_10";
import e_commerce_1_report_11 from "./e_commerce_1/e_commerce_1_report_11";
import e_commerce_1_report_12 from "./e_commerce_1/e_commerce_1_report_12";
import e_commerce_1_report_13 from "./e_commerce_1/e_commerce_1_report_13";
import e_commerce_1_report_14 from "./e_commerce_1/e_commerce_1_report_14";
import e_commerce_1_report_15 from "./e_commerce_1/e_commerce_1_report_15";
import e_commerce_1_report_16 from "./e_commerce_1/e_commerce_1_report_16";
import e_commerce_1_report_17 from "./e_commerce_1/e_commerce_1_report_17";
import e_commerce_1_report_18 from "./e_commerce_1/e_commerce_1_report_18";
import e_commerce_1_report_19 from "./e_commerce_1/e_commerce_1_report_19";
import e_commerce_1_report_20 from "./e_commerce_1/e_commerce_1_report_20";
import e_commerce_2_report_01 from "./e_commerce_2/e_commerce_2_report_01";
import e_commerce_2_report_02 from "./e_commerce_2/e_commerce_2_report_02";
import e_commerce_2_report_03 from "./e_commerce_2/e_commerce_2_report_03";
import e_commerce_2_report_04 from "./e_commerce_2/e_commerce_2_report_04";
import e_commerce_2_report_05 from "./e_commerce_2/e_commerce_2_report_05";
import e_commerce_2_report_06 from "./e_commerce_2/e_commerce_2_report_06";
import e_commerce_2_report_07 from "./e_commerce_2/e_commerce_2_report_07";
import e_commerce_2_report_08 from "./e_commerce_2/e_commerce_2_report_08";
import e_commerce_2_report_09 from "./e_commerce_2/e_commerce_2_report_09";
import e_commerce_2_report_10 from "./e_commerce_2/e_commerce_2_report_10";
import e_commerce_2_report_11 from "./e_commerce_2/e_commerce_2_report_11";
import e_commerce_2_report_12 from "./e_commerce_2/e_commerce_2_report_12";
import e_commerce_2_report_13 from "./e_commerce_2/e_commerce_2_report_13";
import e_commerce_2_report_14 from "./e_commerce_2/e_commerce_2_report_14";
import e_commerce_2_report_15 from "./e_commerce_2/e_commerce_2_report_15";
import e_commerce_2_report_16 from "./e_commerce_2/e_commerce_2_report_16";
import e_commerce_2_report_17 from "./e_commerce_2/e_commerce_2_report_17";
import e_commerce_2_report_18 from "./e_commerce_2/e_commerce_2_report_18";
import e_commerce_2_report_19 from "./e_commerce_2/e_commerce_2_report_19";
import e_commerce_2_report_20 from "./e_commerce_2/e_commerce_2_report_20";
import rpa_1_report_01 from "./rpa_1/rpa_1_report_01";
import rpa_1_report_02 from "./rpa_1/rpa_1_report_02";
import rpa_1_report_03 from "./rpa_1/rpa_1_report_03";
import rpa_1_report_04 from "./rpa_1/rpa_1_report_04";
import rpa_1_report_05 from "./rpa_1/rpa_1_report_05";
import rpa_1_report_06 from "./rpa_1/rpa_1_report_06";
import rpa_1_report_07 from "./rpa_1/rpa_1_report_07";
import rpa_1_report_08 from "./rpa_1/rpa_1_report_08";
import rpa_1_report_09 from "./rpa_1/rpa_1_report_09";
import rpa_1_report_10 from "./rpa_1/rpa_1_report_10";
import rpa_1_report_11 from "./rpa_1/rpa_1_report_11";
import rpa_1_report_12 from "./rpa_1/rpa_1_report_12";
import rpa_1_report_13 from "./rpa_1/rpa_1_report_13";
import rpa_1_report_14 from "./rpa_1/rpa_1_report_14";
import rpa_1_report_15 from "./rpa_1/rpa_1_report_15";
import rpa_1_report_16 from "./rpa_1/rpa_1_report_16";
import rpa_1_report_17 from "./rpa_1/rpa_1_report_17";
import rpa_1_report_18 from "./rpa_1/rpa_1_report_18";
import rpa_1_report_19 from "./rpa_1/rpa_1_report_19";
import rpa_1_report_20 from "./rpa_1/rpa_1_report_20";
import rpa_2_report_01 from "./rpa_2/rpa_2_report_01";
import rpa_2_report_02 from "./rpa_2/rpa_2_report_02";
import rpa_2_report_03 from "./rpa_2/rpa_2_report_03";
import rpa_2_report_04 from "./rpa_2/rpa_2_report_04";
import rpa_2_report_05 from "./rpa_2/rpa_2_report_05";
import rpa_2_report_06 from "./rpa_2/rpa_2_report_06";
import rpa_2_report_07 from "./rpa_2/rpa_2_report_07";
import rpa_2_report_08 from "./rpa_2/rpa_2_report_08";
import rpa_2_report_09 from "./rpa_2/rpa_2_report_09";
import rpa_2_report_10 from "./rpa_2/rpa_2_report_10";
import rpa_2_report_11 from "./rpa_2/rpa_2_report_11";
import rpa_2_report_12 from "./rpa_2/rpa_2_report_12";
import rpa_2_report_13 from "./rpa_2/rpa_2_report_13";
import rpa_2_report_14 from "./rpa_2/rpa_2_report_14";
import rpa_2_report_15 from "./rpa_2/rpa_2_report_15";
import rpa_2_report_16 from "./rpa_2/rpa_2_report_16";
import rpa_2_report_17 from "./rpa_2/rpa_2_report_17";
import rpa_2_report_18 from "./rpa_2/rpa_2_report_18";
import rpa_2_report_19 from "./rpa_2/rpa_2_report_19";
import rpa_2_report_20 from "./rpa_2/rpa_2_report_20";
import _3d_1_report_01 from "./3d_1/3d_1_report_01";
import _3d_1_report_02 from "./3d_1/3d_1_report_02";
import _3d_1_report_03 from "./3d_1/3d_1_report_03";
import _3d_1_report_04 from "./3d_1/3d_1_report_04";
import _3d_1_report_05 from "./3d_1/3d_1_report_05";
import _3d_1_report_06 from "./3d_1/3d_1_report_06";
import _3d_1_report_07 from "./3d_1/3d_1_report_07";
import _3d_1_report_08 from "./3d_1/3d_1_report_08";
import _3d_1_report_09 from "./3d_1/3d_1_report_09";
import _3d_1_report_10 from "./3d_1/3d_1_report_10";
import _3d_1_report_11 from "./3d_1/3d_1_report_11";
import _3d_1_report_12 from "./3d_1/3d_1_report_12";
import _3d_1_report_13 from "./3d_1/3d_1_report_13";
import _3d_1_report_14 from "./3d_1/3d_1_report_14";
import _3d_1_report_15 from "./3d_1/3d_1_report_15";
import _3d_1_report_16 from "./3d_1/3d_1_report_16";
import _3d_1_report_17 from "./3d_1/3d_1_report_17";
import _3d_1_report_18 from "./3d_1/3d_1_report_18";
import _3d_1_report_19 from "./3d_1/3d_1_report_19";
import _3d_1_report_20 from "./3d_1/3d_1_report_20";
import _3d_2_report_01 from "./3d_2/3d_2_report_01";
import _3d_2_report_02 from "./3d_2/3d_2_report_02";
import _3d_2_report_03 from "./3d_2/3d_2_report_03";
import _3d_2_report_04 from "./3d_2/3d_2_report_04";
import _3d_2_report_05 from "./3d_2/3d_2_report_05";
import _3d_2_report_06 from "./3d_2/3d_2_report_06";
import _3d_2_report_07 from "./3d_2/3d_2_report_07";
import _3d_2_report_08 from "./3d_2/3d_2_report_08";
import _3d_2_report_09 from "./3d_2/3d_2_report_09";
import _3d_2_report_10 from "./3d_2/3d_2_report_10";
import _3d_2_report_11 from "./3d_2/3d_2_report_11";
import _3d_2_report_12 from "./3d_2/3d_2_report_12";
import _3d_2_report_13 from "./3d_2/3d_2_report_13";
import _3d_2_report_14 from "./3d_2/3d_2_report_14";
import _3d_2_report_15 from "./3d_2/3d_2_report_15";
import _3d_2_report_16 from "./3d_2/3d_2_report_16";
import _3d_2_report_17 from "./3d_2/3d_2_report_17";
import _3d_2_report_18 from "./3d_2/3d_2_report_18";
import _3d_2_report_19 from "./3d_2/3d_2_report_19";
import _3d_2_report_20 from "./3d_2/3d_2_report_20";

const reports = {
  ai_ml_1_report_01,
  ai_ml_1_report_02,
  ai_ml_1_report_03,
  ai_ml_1_report_04,
  ai_ml_1_report_05,
  ai_ml_1_report_06,
  ai_ml_1_report_07,
  ai_ml_1_report_08,
  ai_ml_1_report_09,
  ai_ml_1_report_10,
  ai_ml_1_report_11,
  ai_ml_1_report_12,
  ai_ml_1_report_13,
  ai_ml_1_report_14,
  ai_ml_1_report_15,
  ai_ml_1_report_16,
  ai_ml_1_report_17,
  ai_ml_1_report_18,
  ai_ml_1_report_19,
  ai_ml_1_report_20,
  ai_ml_2_report_01,
  ai_ml_2_report_02,
  ai_ml_2_report_03,
  ai_ml_2_report_04,
  ai_ml_2_report_05,
  ai_ml_2_report_06,
  ai_ml_2_report_07,
  ai_ml_2_report_08,
  ai_ml_2_report_09,
  ai_ml_2_report_10,
  ai_ml_2_report_11,
  ai_ml_2_report_12,
  ai_ml_2_report_13,
  ai_ml_2_report_14,
  ai_ml_2_report_15,
  ai_ml_2_report_16,
  ai_ml_2_report_17,
  ai_ml_2_report_18,
  ai_ml_2_report_19,
  ai_ml_2_report_20,
  bi_1_report_01,
  bi_1_report_02,
  bi_1_report_03,
  bi_1_report_04,
  bi_1_report_05,
  bi_1_report_06,
  bi_1_report_07,
  bi_1_report_08,
  bi_1_report_09,
  bi_1_report_10,
  bi_1_report_11,
  bi_1_report_12,
  bi_1_report_13,
  bi_1_report_14,
  bi_1_report_15,
  bi_1_report_16,
  bi_1_report_17,
  bi_1_report_18,
  bi_1_report_19,
  bi_1_report_20,
  bi_2_report_01,
  bi_2_report_02,
  bi_2_report_03,
  bi_2_report_04,
  bi_2_report_05,
  bi_2_report_06,
  bi_2_report_07,
  bi_2_report_08,
  bi_2_report_09,
  bi_2_report_10,
  bi_2_report_11,
  bi_2_report_12,
  bi_2_report_13,
  bi_2_report_14,
  bi_2_report_15,
  bi_2_report_16,
  bi_2_report_17,
  bi_2_report_18,
  bi_2_report_19,
  bi_2_report_20,
  blockchain_1_report_01,
  blockchain_1_report_02,
  blockchain_1_report_03,
  blockchain_1_report_04,
  blockchain_1_report_05,
  blockchain_1_report_06,
  blockchain_1_report_07,
  blockchain_1_report_08,
  blockchain_1_report_09,
  blockchain_1_report_10,
  blockchain_1_report_11,
  blockchain_1_report_12,
  blockchain_1_report_13,
  blockchain_1_report_14,
  blockchain_1_report_15,
  blockchain_1_report_16,
  blockchain_1_report_17,
  blockchain_1_report_18,
  blockchain_1_report_19,
  blockchain_1_report_20,
  blockchain_2_report_01,
  blockchain_2_report_02,
  blockchain_2_report_03,
  blockchain_2_report_04,
  blockchain_2_report_05,
  blockchain_2_report_06,
  blockchain_2_report_07,
  blockchain_2_report_08,
  blockchain_2_report_09,
  blockchain_2_report_10,
  blockchain_2_report_11,
  blockchain_2_report_12,
  blockchain_2_report_13,
  blockchain_2_report_14,
  blockchain_2_report_15,
  blockchain_2_report_16,
  blockchain_2_report_17,
  blockchain_2_report_18,
  blockchain_2_report_19,
  blockchain_2_report_20,
  cloud_1_report_01,
  cloud_1_report_02,
  cloud_1_report_03,
  cloud_1_report_04,
  cloud_1_report_05,
  cloud_1_report_06,
  cloud_1_report_07,
  cloud_1_report_08,
  cloud_1_report_09,
  cloud_1_report_10,
  cloud_1_report_11,
  cloud_1_report_12,
  cloud_1_report_13,
  cloud_1_report_14,
  cloud_1_report_15,
  cloud_1_report_16,
  cloud_1_report_17,
  cloud_1_report_18,
  cloud_1_report_19,
  cloud_1_report_20,
  cloud_2_report_01,
  cloud_2_report_02,
  cloud_2_report_03,
  cloud_2_report_04,
  cloud_2_report_05,
  cloud_2_report_06,
  cloud_2_report_07,
  cloud_2_report_08,
  cloud_2_report_09,
  cloud_2_report_10,
  cloud_2_report_11,
  cloud_2_report_12,
  cloud_2_report_13,
  cloud_2_report_14,
  cloud_2_report_15,
  cloud_2_report_16,
  cloud_2_report_17,
  cloud_2_report_18,
  cloud_2_report_19,
  cloud_2_report_20,
  vr_ar_1_report_01,
  vr_ar_1_report_02,
  vr_ar_1_report_03,
  vr_ar_1_report_04,
  vr_ar_1_report_05,
  vr_ar_1_report_06,
  vr_ar_1_report_07,
  vr_ar_1_report_08,
  vr_ar_1_report_09,
  vr_ar_1_report_10,
  vr_ar_1_report_11,
  vr_ar_1_report_12,
  vr_ar_1_report_13,
  vr_ar_1_report_14,
  vr_ar_1_report_15,
  vr_ar_1_report_16,
  vr_ar_1_report_17,
  vr_ar_1_report_18,
  vr_ar_1_report_19,
  vr_ar_1_report_20,
  vr_ar_2_report_01,
  vr_ar_2_report_02,
  vr_ar_2_report_03,
  vr_ar_2_report_04,
  vr_ar_2_report_05,
  vr_ar_2_report_06,
  vr_ar_2_report_07,
  vr_ar_2_report_08,
  vr_ar_2_report_09,
  vr_ar_2_report_10,
  vr_ar_2_report_11,
  vr_ar_2_report_12,
  vr_ar_2_report_13,
  vr_ar_2_report_14,
  vr_ar_2_report_15,
  vr_ar_2_report_16,
  vr_ar_2_report_17,
  vr_ar_2_report_18,
  vr_ar_2_report_19,
  vr_ar_2_report_20,
  iot_1_report_01,
  iot_1_report_02,
  iot_1_report_03,
  iot_1_report_04,
  iot_1_report_05,
  iot_1_report_06,
  iot_1_report_07,
  iot_1_report_08,
  iot_1_report_09,
  iot_1_report_10,
  iot_1_report_11,
  iot_1_report_12,
  iot_1_report_13,
  iot_1_report_14,
  iot_1_report_15,
  iot_1_report_16,
  iot_1_report_17,
  iot_1_report_18,
  iot_1_report_19,
  iot_1_report_20,
  iot_2_report_01,
  iot_2_report_02,
  iot_2_report_03,
  iot_2_report_04,
  iot_2_report_05,
  iot_2_report_06,
  iot_2_report_07,
  iot_2_report_08,
  iot_2_report_09,
  iot_2_report_10,
  iot_2_report_11,
  iot_2_report_12,
  iot_2_report_13,
  iot_2_report_14,
  iot_2_report_15,
  iot_2_report_16,
  iot_2_report_17,
  iot_2_report_18,
  iot_2_report_19,
  iot_2_report_20,
  sm_1_report_01,
  sm_1_report_02,
  sm_1_report_03,
  sm_1_report_04,
  sm_1_report_05,
  sm_1_report_06,
  sm_1_report_07,
  sm_1_report_08,
  sm_1_report_09,
  sm_1_report_10,
  sm_1_report_11,
  sm_1_report_12,
  sm_1_report_13,
  sm_1_report_14,
  sm_1_report_15,
  sm_1_report_16,
  sm_1_report_17,
  sm_1_report_18,
  sm_1_report_19,
  sm_1_report_20,
  sm_2_report_01,
  sm_2_report_02,
  sm_2_report_03,
  sm_2_report_04,
  sm_2_report_05,
  sm_2_report_06,
  sm_2_report_07,
  sm_2_report_08,
  sm_2_report_09,
  sm_2_report_10,
  sm_2_report_11,
  sm_2_report_12,
  sm_2_report_13,
  sm_2_report_14,
  sm_2_report_15,
  sm_2_report_16,
  sm_2_report_17,
  sm_2_report_18,
  sm_2_report_19,
  sm_2_report_20,
  e_commerce_1_report_01,
  e_commerce_1_report_02,
  e_commerce_1_report_03,
  e_commerce_1_report_04,
  e_commerce_1_report_05,
  e_commerce_1_report_06,
  e_commerce_1_report_07,
  e_commerce_1_report_08,
  e_commerce_1_report_09,
  e_commerce_1_report_10,
  e_commerce_1_report_11,
  e_commerce_1_report_12,
  e_commerce_1_report_13,
  e_commerce_1_report_14,
  e_commerce_1_report_15,
  e_commerce_1_report_16,
  e_commerce_1_report_17,
  e_commerce_1_report_18,
  e_commerce_1_report_19,
  e_commerce_1_report_20,
  e_commerce_2_report_01,
  e_commerce_2_report_02,
  e_commerce_2_report_03,
  e_commerce_2_report_04,
  e_commerce_2_report_05,
  e_commerce_2_report_06,
  e_commerce_2_report_07,
  e_commerce_2_report_08,
  e_commerce_2_report_09,
  e_commerce_2_report_10,
  e_commerce_2_report_11,
  e_commerce_2_report_12,
  e_commerce_2_report_13,
  e_commerce_2_report_14,
  e_commerce_2_report_15,
  e_commerce_2_report_16,
  e_commerce_2_report_17,
  e_commerce_2_report_18,
  e_commerce_2_report_19,
  e_commerce_2_report_20,
  rpa_1_report_01,
  rpa_1_report_02,
  rpa_1_report_03,
  rpa_1_report_04,
  rpa_1_report_05,
  rpa_1_report_06,
  rpa_1_report_07,
  rpa_1_report_08,
  rpa_1_report_09,
  rpa_1_report_10,
  rpa_1_report_11,
  rpa_1_report_12,
  rpa_1_report_13,
  rpa_1_report_14,
  rpa_1_report_15,
  rpa_1_report_16,
  rpa_1_report_17,
  rpa_1_report_18,
  rpa_1_report_19,
  rpa_1_report_20,
  rpa_2_report_01,
  rpa_2_report_02,
  rpa_2_report_03,
  rpa_2_report_04,
  rpa_2_report_05,
  rpa_2_report_06,
  rpa_2_report_07,
  rpa_2_report_08,
  rpa_2_report_09,
  rpa_2_report_10,
  rpa_2_report_11,
  rpa_2_report_12,
  rpa_2_report_13,
  rpa_2_report_14,
  rpa_2_report_15,
  rpa_2_report_16,
  rpa_2_report_17,
  rpa_2_report_18,
  rpa_2_report_19,
  rpa_2_report_20,
  _3d_1_report_01,
  _3d_1_report_02,
  _3d_1_report_03,
  _3d_1_report_04,
  _3d_1_report_05,
  _3d_1_report_06,
  _3d_1_report_07,
  _3d_1_report_08,
  _3d_1_report_09,
  _3d_1_report_10,
  _3d_1_report_11,
  _3d_1_report_12,
  _3d_1_report_13,
  _3d_1_report_14,
  _3d_1_report_15,
  _3d_1_report_16,
  _3d_1_report_17,
  _3d_1_report_18,
  _3d_1_report_19,
  _3d_1_report_20,
  _3d_2_report_01,
  _3d_2_report_02,
  _3d_2_report_03,
  _3d_2_report_04,
  _3d_2_report_05,
  _3d_2_report_06,
  _3d_2_report_07,
  _3d_2_report_08,
  _3d_2_report_09,
  _3d_2_report_10,
  _3d_2_report_11,
  _3d_2_report_12,
  _3d_2_report_13,
  _3d_2_report_14,
  _3d_2_report_15,
  _3d_2_report_16,
  _3d_2_report_17,
  _3d_2_report_18,
  _3d_2_report_19,
  _3d_2_report_20
};

export default reports