const html = `<p style="text-align: justify;">Podejście agile (tj. zwinne, elastyczne) polega na dzieleniu złożonego projektu na małe zadania programistyczne i kr&oacute;tkie cykle (sprinty, daily z klientem), po kt&oacute;rych zesp&oacute;ł ponownie ocenia wykonaną pracę i w razie potrzeby ustala nowe priorytety rozwoju w oparciu o wyniki.&nbsp;</p>
<p style="text-align: justify;">Produt owner po stronie klienta lub sam klient dostarcza mock-up (prototyp aplikacji lub layoutu (układu) i na bieżąco definiuje potrzeby, analizuje rozwiązanie. Prace w tym stylu zarządzania przebiegają następująco:&nbsp;</p>
<ul>
    <li>
        <p style="text-align: justify;"><strong>Planowanie:</strong>&nbsp;Ustalenie potrzeb i szczeg&oacute;ł&oacute;w z klientem, czego efektem jest koncepcja i założenia produktu (określające tzw. PoC (Proof of concept) , priorytety - KPI, zasoby i ew. budżet projektu.</p>
    </li>
    <li>
        <p style="text-align: justify;"><strong>Prace projektowe:</strong> Określenie kluczowych komponent&oacute;w produktu. Specjaliści od user experience (doświadczeń użytkownik&oacute;w), pod kierownictwem Project Menager (PM) wsp&oacute;łpracują z klientem, zespołem produktowym i innymi interesariuszami.</p>
    </li>
    <li>
        <p style="text-align: justify;"><strong>Prace rozwojowe:</strong> Etap prototypowania/MVP (minimum viable product, minimalna wersja produktu), etap tworzenia kolejnych iteracji produktu w ramach kr&oacute;tkich etap&oacute;w, zwanych sprintami.&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;"><strong>Faza testowa:</strong> Sprawdzenie zgodności produktu z wymaganiami. W razie wykrycia błęd&oacute;w, produkt wraca do fazy rozwojowej, a po usunięciu problem&oacute;w jest ponownie testowany. Cykl ten powtarzany jest do skutku, tj. do momentu aż produkt osiągnie pełną zgodność ze specyfikacją zamawiającego.</p>
    </li>
    <li style="font-weight: bold;">
        <p style="text-align: justify;"><strong>Faza akceptacji finalnej wersji produktu</strong></p>
    </li>
    <li>
        <p style="text-align: justify;"><strong>Faza wdrożeniowa:</strong>&nbsp;Przekazanie klientowi zaakceptowanego produktu finalnego do wdrożenia w organizacji&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Mocne strony podejścia agile: &nbsp; &nbsp;</strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;aktywne uczestnictwo klienta w rozwoju produktu (otrzymywanie informacji zwrotnych i kontrola z powodu częstych spotkań zespołu i klienta)</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;możliwość zmiany wymagań na dowolnym etapie procesu rozwoju produktu</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;specjalne narzędzia PM (Jira, Trello itp.), kt&oacute;re zapewniają przejrzystość każdego procesu roboczego</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;wyższa jakość i wydajne zarządzanie ryzykiem (dzięki ciągłemu planowaniu, częstemu raportowaniu wynik&oacute;w i lepszemu dostosowaniu do potrzeb klient&oacute;w)</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;minimalizacja dokumentacji &ndash; wydajny spos&oacute;b rozwiązania kwestii, minimalizując liczbę i objętość dokumentacji&nbsp;</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;kr&oacute;tkie cykle dostaw oraz pełne zrozumienie biznesu i priorytet&oacute;w klient&oacute;w</p>
<p style="text-align: justify;">szybszy zwrot z inwestycji ze względu na wczesne rozpoczęcie procesu programowania</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Słabe strony podejścia agile: </strong></p>
<p style="text-align: justify;">&nbsp; &nbsp;&nbsp;●&nbsp; &nbsp;&nbsp;uboga dokumentacja &ndash;zaleca się, by dokumentacja nie przekraczała kilku stron A4. Według krytyk&oacute;w utrudnia to komunikację w zespole i organizację pracy</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;ryzyko braku koncentracji &ndash; ryzyko, że samozarządzające się zespoły nie będą właściwie skoncentrowane na pracy</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;brak planowania lub nadmierne planowanie&ndash;nieodpowiednio wyszkolone zespoły, mogą nie wiedzieć, jak zaplanować pracę lub planują ją zbyt dokładnie</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;nieefektywność w dużych organizacjach &ndash; wielu krytyk&oacute;w preferuje podejście hybrydowe, w kt&oacute;rym łączy się planowanie z elastycznością Agile</p>
<p style="text-align: justify;">● &nbsp; &nbsp;obawa klient&oacute;w o niedokładność &ndash; metodyka Agile może powodować obawę u klienta, kt&oacute;ry nie zrozumie filozofii Agail, kt&oacute;rą jest brak sztywnych ram, a w konsekwencji uzna, że koszty projektu są trudne do przewidzenia i kontroli.</p>`;

export default html;
