const html = `<table border="1">
<tbody>
<tr style="height: 35px;">
<td style="height: 35px;">
<h3 style="text-align: center;"><strong>Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada usługa marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr style="height: 152px;">
<td style="height: 152px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">audyt działań prowadzonych w SM oraz opracowanie strategii komunikacji w SM&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">kampania (działania marketingowe) ma być nastawiona na osiągnięcie cel&oacute;w, kt&oacute;rymi są zakupy w sklepie internetowym</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">utworzenie kompletnego procesu sprzedaży online dedykowanego rodzicom</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">kompleksowa obsługa komunikacji w mediach społecznościowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">przeprowadzenie analizy konkurencji. Wdrożenie narzędzi do monitorowania internetu pod kątem komunikacji konkurencji i jej klient&oacute;w</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">działania marketingowe podkreślające przewagę produkt&oacute;w analogowych nad cyfrowymi</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">wdrożenie narzędzi wspierających działania marketingowe</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">utworzenie kompletnego procesu sprzedaży on-line dedykowanego rodzicom dzieci w wieku przedszkolnym w przeciągu 6 miesięcy i opracowanie raportu dobrych praktyk do kampanii płatnych (w celu ich p&oacute;źniejszej kontynuacji)</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
