const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<h3><strong>Informacje, kt&oacute;re należy przekazać i uzyskać podczas spotkania rozpoczynającego realizację projektu z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Witamy! Oto nasz zesp&oacute;ł. Wszyscy będziemy się z Państwem spotkać, ale na r&oacute;żnych etapach projektu. Chcemy też poznać osoby z Państwa firmy, z kt&oacute;rymi będziemy pracować. Opowiedzcie nam proszę o Waszym produkcie i potrzebach i wyzwaniach marketingowych.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zgodnie z przyjętymi założeniami, produkcja materiał&oacute;w video jest po Waszej stronie. Doprecyzujmy jakich dokładnie informacji potrzebujecie od nas, by wyprodukować materiały wideo niezbędne do przeprowadzenia kampanii?</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Podsumujmy cele projektu, kt&oacute;re na etapie analizy przedofertowej wsp&oacute;lnie ustaliliśmy. Sprawdźmy, czy wszyscy rozumiemy je tak samo i czy nic się w między czasie nie zmieniło.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Nasz projekt będzie się składać z kilku etap&oacute;w, kt&oacute;re planujemy zrealizować według wysłanego w ofercie harmonogramu. Jeśli chodzi o podwykonawc&oacute;w, zostali wstępnie wybrani.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Uzupełnijmy macierz RACI, tak by każdy wiedział czego się od niego oczekuje i za co jest odpowiedzialny.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Potrzebujemy następujących rzeczy:</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;"><em>Brandbook</em> [dokument opisujący identyfikację wizualną marki, tj. logo i sposoby jego zastosowania, graficzne elementy charakterystyczne pojawiające się w komunikacji &ndash; np. czcionki, kolorystyka, elementy graficzne]&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dostępy do medi&oacute;w społecznościowych i strony www i ewentualnie do serwera&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dane kontaktowe do wszystkich członk&oacute;w zespołu</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Podsumowanie dotychczasowych działań marketingowych&nbsp; firmy klienta&nbsp;</span></li>
</ol>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Planujemy w projekcie korzystać z następujących narzędzi: Menedżer Firmy i Menedżera Reklam Meta, Business Center, konto reklamowe TikTok oraz Google Analytics 4. Czy korzystaliście do tej pory z tych platform? Jakie macie doświadczenia w tym obszarze?</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Musimy zdawać sobie sprawę z tego, że projekt będzie dzielił się na kilka dłuższych etap&oacute;w. Każdy z nich zakończy się konkretnymi produktami. Od sprawności akceptacji przez Państwa produkt&oacute;w projektu, zależy utrzymanie zakładanego harmonogramu etap&oacute;w projektu.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
