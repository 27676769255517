const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada technologia ML/AI</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">automatyzacja czasochłonnych proces&oacute;w manualnego kategoryzowania maili, w tym proces&oacute;w problematycznych, kt&oacute;re np. wymagają zmiany kategorii</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">automatyczne segregowanie e-maili wg. kategorii, takich jak: ofertowanie, zwrot towaru, reklamacja w skutek błędu dostawcy, reklamacja w skutek niezgodności towaru z zam&oacute;wieniem</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">automatyczne kolejkowanie e-maili (przypisanie mailom odpowiedniego poziom&oacute;w priorytetu)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">przesyłanie nowych, skategoryzowanych danych ze skrzynki e-mailowej, co godzinę</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">model ma być uruchamiany w środowisku chmurowym</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">należy dokonać analizy mającej na celu wyb&oacute;r najlepszych środowisk do uruchamiania modeli w chmurze, biorąc pod uwagę stack technologiczny klienta</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">rozwiązanie powinno wsp&oacute;łpracować z Best DW</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zaprojektowanie architektury rozwiązania uwzględniającej stack technologiczny klienta</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
