const html = `<p style="text-align: justify;"><strong>Gł&oacute;wne obowiązki:</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">pisanie kodu Spark w Python (np. na potrzeby przetwarzania danych między warstwami platformy Big Data)</p>
    </li>
    <li>
        <p style="text-align: justify;">pisanie skrytp&oacute;w w Python do AirFlow</p>
    </li>
    <li>
        <p style="text-align: justify;">obługa narzędzi ETL</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Wymagane technologie:</strong>&nbsp;AWS Glue, AirFlow, QklikReplicate, Git, Jira, IDE (visual studio code), Python Spark</p>
<p style="text-align: justify;"><strong>Pozostałe wymagania: </strong></p>
<ul>
    <li>
        <p style="text-align: justify;">rozumienie zasad ETL i przetwarzania danych big data</p>
    </li>
    <li>
        <p style="text-align: justify;">podstawy cloud computing</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość zasad budowy data lake</p>
    </li>
    <li>
        <p style="text-align: justify;">programowanie obiektowe w Spark</p>
    </li>
</ul>`;

export default html;
