import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import EditUserRequest from "../interfaces/user/EditUserRequest";
import TokenResponse from "../interfaces/user/TokenResponse";
import UserReducer from "../interfaces/user/UserReducer";
import UserType from "../interfaces/user/UserType";
import { decodeToken } from "../utils/token.utils";

const initialState: UserReducer = {
  isFetching: false,
  isFetchingActiveLicenses: false,
  isFetchingEducator: false,
  error: "",
  token: "", // TODO nie wiem czy to dobre
  refreshToken: "",
  user: null,
  userId: "",
  role: null, // może niepotrzebna
  isSendingMail: false,
  activeLicences: 0,
  hasEducatorSubscriptionActive: null,
  digitalProfile: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearAuth(state) {
      state.user = null;
      state.token = "";
      state.userId = "";
      state.role = null;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      state.role = action.payload.role;
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    loginUserPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    loginUserFulfilled(state, action: PayloadAction<TokenResponse>) {
      state.isFetching = false;
      const decodedToken = decodeToken(action.payload.access_token);
      state.userId = decodedToken.user_id;
      state.token = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      console.log(decodedToken);
      state.role = decodedToken.authorities[0];
    },
    loginUserRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    registerUserPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    registerUserFulfilled(state) {
      state.isFetching = false;
    },
    registerUserRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    forgotPasswordPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    forgotPasswordFulfilled(state) {
      state.isFetching = false;
    },
    forgotPasswordRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    resetPasswordPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    resetPasswordFulfilled(state) {
      state.isFetching = false;
    },
    resetPasswordRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    activateAcountPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    activateAcountFulfilled(state) {
      state.isFetching = false;
    },
    activateAcountRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    getUserPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    getUserFulfilled(state, action: PayloadAction<UserType>) {
      state.isFetching = false;
      state.user = action.payload;
      // state.user = { ...action.payload, hasSubscriptionActive: false };
      state.role = action.payload.role;
    },
    getUserRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    sendFAQMailPending(state) {
      state.isSendingMail = true;
      state.error = "";
    },
    sendFAQMailFulfilled(state) {
      state.isSendingMail = false;
    },
    sendFAQMailRejected(state, action: PayloadAction<string>) {
      state.isSendingMail = false;
      state.error = action.payload;
    },
    updateUserPending(state, action: PayloadAction<EditUserRequest>) {
      state.isFetching = true;
      state.error = "";
      //@ts-ignore
      state.user = { ...state.user, ...action.payload };
    },
    updateUserFulfilled(state) {
      state.isFetching = false;
    },
    updateUserRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    getNumberOfActiveLicencesPending(state) {
      state.isFetchingActiveLicenses = true;
      state.error = "";
    },
    getNumberOfActiveLicencesFulfilled(state, action: PayloadAction<number>) {
      state.isFetchingActiveLicenses = false;
      state.activeLicences = action.payload;
    },
    getNumberOfActiveLicencesRejected(state, action: PayloadAction<string>) {
      state.isFetchingActiveLicenses = false;
      state.error = action.payload;
    },
    getEducatorPending(state) {
      state.isFetchingEducator = true;
      state.error = "";
    },
    getEducatorFulfilled(state, action: PayloadAction<boolean>) {
      state.isFetchingEducator = false;
      state.hasEducatorSubscriptionActive = action.payload;
    },
    getEducatorRejected(state, action: PayloadAction<string>) {
      state.isFetchingEducator = false;
      state.error = action.payload;
    },
    sendDigitalProfileMailPending(state) {
      state.isFetchingEducator = true;
      state.error = "";
    },
    sendDigitalProfileMailFulfilled(state) {
      state.isFetchingEducator = false;
    },
    sendDigitalProfileMailRejected(state, action: PayloadAction<string>) {
      state.isFetchingEducator = false;
      state.error = action.payload;
    },
    addOrEditDigitalProfilePending(state) {
      state.isFetchingEducator = true;
      state.error = "";
    },
    addOrEditDigitalProfileFulfilled(state) {
      state.isFetchingEducator = false;
    },
    addOrEditDigitalProfileRejected(state, action: PayloadAction<string>) {
      state.isFetchingEducator = false;
      state.error = action.payload;
    },
    getDigitalProfilePending(state) {
      state.isFetchingEducator = true;
      state.error = "";
    },
    getDigitalProfileFulfilled(state, action: PayloadAction<any>) {
      state.isFetchingEducator = false;
      state.digitalProfile = action.payload;
    },
    getDigitalProfileRejected(state, action: PayloadAction<string>) {
      state.isFetchingEducator = false;
      state.error = action.payload;
    },
    setDigitalProfile(
      state,
      action: PayloadAction<{ content: string; userId: string } | null>
    ) {
      state.digitalProfile = action.payload;
    },
  },
});

export const {
  clearAuth,
  setToken,
  setUser,
  setRefreshToken,
  loginUserFulfilled,
  loginUserPending,
  loginUserRejected,
  registerUserFulfilled,
  registerUserPending,
  registerUserRejected,
  forgotPasswordFulfilled,
  forgotPasswordPending,
  forgotPasswordRejected,
  resetPasswordFulfilled,
  resetPasswordPending,
  resetPasswordRejected,
  activateAcountFulfilled,
  activateAcountPending,
  activateAcountRejected,
  getUserFulfilled,
  getUserPending,
  getUserRejected,
  sendFAQMailFulfilled,
  sendFAQMailPending,
  sendFAQMailRejected,
  updateUserFulfilled,
  updateUserPending,
  updateUserRejected,
  getNumberOfActiveLicencesFulfilled,
  getNumberOfActiveLicencesPending,
  getNumberOfActiveLicencesRejected,
  getEducatorFulfilled,
  getEducatorPending,
  getEducatorRejected,
  sendDigitalProfileMailFulfilled,
  sendDigitalProfileMailPending,
  sendDigitalProfileMailRejected,
  addOrEditDigitalProfileFulfilled,
  addOrEditDigitalProfilePending,
  addOrEditDigitalProfileRejected,
  getDigitalProfileFulfilled,
  getDigitalProfilePending,
  getDigitalProfileRejected,
  setDigitalProfile,
} = userSlice.actions;

export default userSlice.reducer;
