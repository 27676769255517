const html = `<h3 style="text-align: center;"><strong>CASE STUDY 3</strong></h3>
<p><strong>Tytuł projektu: Stworzenie platformy IoT do zarządzania hodowlą dżdżownic</strong><span style="font-weight: 400;">&nbsp;</span></p>
<p><strong>Gł&oacute;wny problem klienta:&nbsp; </strong><span style="font-weight: 400;">Dżdżownice, kt&oacute;re naturalnie występują na polach uprawnych poza spulchnianiem ziemi jednocześnie niszczą uprawy poprzez zniszczenie korzeni. Istnieje zatem potrzeba wpuszczenia na teren upraw gatunku dżdżownic, kt&oacute;ry jednocześnie wyprze gatunki szkodnicze i sam będzie neutralny dla upraw.&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Zbudowano platformę IoT, kt&oacute;ra</span> <span style="font-weight: 400;">miała na celu zbierania i analizowanie danych w zakresie warunk&oacute;w optymalnego wzrostu dżdżownic, a następnie automatyzację sterowaniem określonymi parametrami w hali produkcyjnej. Gł&oacute;wne parametry: temperatura gleby, temperatura powietrza, wilgotność gleby, wilgotność powietrza, zawartość pierwiastk&oacute;w typu potas i s&oacute;d w glebie. Dodatkowym modułem wdrożonym na platformie był moduł dostępu dostaw bezpośrednio do hali produkcyjnej, polegający na wykryciu tablic rejestracyjnych pojazdu chcącego wjechać lub wyjechać z monitorowanego obszaru oraz por&oacute;wnanie ich z rejestrem zaufanych dostawc&oacute;w, kt&oacute;ry był aktualizowany na bieżąco.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Optymalizacja procesu hodowli dżdżownic, zautomatyzowanie procesu dostaw dżdżownic na docelowe pola, wzmocnienie bezpieczeństwa obszaru objętego kontrolą&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Azure cloud, IoT, edge, sterowanie automatyką przemysłową, przetwarzanie strumieni video&nbsp;</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">12 miesięcy</span><strong>&nbsp;</strong></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">PM (0,25FTE), Machine Learning Engineer (0,5FTE), Cloud Architekt (0,5FTE), Frontend Developer (1FTE), Tester (0,25FTE), DevOps (1FTE), Backend developer (2FTE)&nbsp;</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">1,6 mln zł</span></p>`;

export default html;
