const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zadanie&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>PM</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Digital Strategist&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Senior Digital Marketing Specialist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Senior HR Specialist&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Klient- Product Owner/PM&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Klient -Marketing Manager/Ekspert</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Klient -Junior Marketing Manager</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Audyt kompetencji zespołu klienta&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>A</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>R</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Audyt zasob&oacute;w marketingowych klienta (www, branding)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>A</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>R</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie strategii marki i gł&oacute;wnych założeń komunikacji marki</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>A</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>R</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie ramowego planu działań na 6 m-cy</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>A</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>R</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zrekrutowanie wykonawc&oacute;w i wdrożenie narzędzi</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RA</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>CI</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RC</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Optymalizacja proces&oacute;w sprzedażowych prowadzonych przez klienta w kanałach cyfrowych (www, marketplace &ndash; poprawa błęd&oacute;w ujawnionych w audycie)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>A</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>R</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Produkcja treści (na potrzeby content marketingu)</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RA</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Prowadzenie działań komunikacyjnych w social mediach (publikacje, moderacja)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RA</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Przeprowadzenie kampanii płatnych w social mediach &nbsp;(social ads, zasięgowe, wizerunkowe/sprzedażowe, remarketingowe)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RA</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raportowanie okresowe (milestones x3 w trakcie 6 miesięcy)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RA</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie raportu końcowego</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>A</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>R</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>C</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>-</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>I</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
