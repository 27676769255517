const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="border-left: solid #000000 0.5pt; border-right: solid #000000 0.5pt; border-bottom: solid #000000 1pt; border-top: solid #000000 0.5pt; vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadania szczeg&oacute;łowe wymagane do realizacji w ramach projektu z obszaru e-commerce</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza wymagań&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warsztat z klientem&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza wymagań&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dokumentacja wymagań&nbsp;</span></li>
</ul>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Instalacja i konfiguracja gotowego do wdrożenia modułu B2B zgodnie z wymaganiami klienta&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowanie środowiska - przed rozpoczęciem instalacji i konfiguracji modułu B2B, należy przygotować środowisko, w kt&oacute;rym będzie działał sklep internetowy. Zadaniem jest wstępna konfiguracja platformy e-commerce, kt&oacute;ra będzie wymagała ustawienia odpowiednich parametr&oacute;w, takich jak konfiguracja bazy danych, ustawienia serwera WWW, konfiguracja SSL, itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Instalacja modułu - po przygotowaniu środowiska, należy przystąpić do instalacji modułu B2B. Zadaniem jest pobranie i zainstalowanie plik&oacute;w modułu zgodnie z instrukcjami producenta.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Konfiguracja modułu - po zainstalowaniu modułu, należy przystąpić do jego konfiguracji. Zadaniem jest ustawienie opcji modułu, takich jak konfiguracja panelu administracyjnego, ustawienia płatności, ustawienia wysyłki, konfiguracja podatk&oacute;w, itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testowanie modułu - po zakończeniu instalacji, konfiguracji i dostosowaniu szablon&oacute;w, należy przetestować moduł B2B, aby upewnić się, że działa zgodnie z wymaganiami klienta. Zadaniem jest wykonanie test&oacute;w funkcjonalnych, test&oacute;w integracyjnych, test&oacute;w wydajnościowych i test&oacute;w bezpieczeństwa.</span></li>
</ul>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy funkcjonalne i wydajnościowe&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy funkcjonalności Rejestracja użytkownik&oacute;w - sprawdzenie, czy moduł B2B umożliwia rejestrację użytkownik&oacute;w zgodnie z wymaganiami klienta, w tym: wymagane dane, poziomy dostępu, weryfikacja adresu e-mail itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy funkcjonalności Logowanie użytkownik&oacute;w - sprawdzenie, czy użytkownicy mogą się zalogować do panelu B2B, a także czy panel jest dostępny tylko dla zarejestrowanych użytkownik&oacute;w z odpowiednimi poziomami dostępu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy funkcjonalności Zarządzanie zam&oacute;wieniami - sprawdzenie, czy moduł B2B umożliwia składanie zam&oacute;wień zgodnie z wymaganiami klienta, w tym: wyb&oacute;r produkt&oacute;w, dodawanie ich do koszyka, wyb&oacute;r metody płatności i wysyłki, potwierdzenie zam&oacute;wienia itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy funkcjonalności Zarządzanie produktami - sprawdzenie, czy moduł B2B umożliwia dodawanie, usuwanie i modyfikowanie produkt&oacute;w zgodnie z wymaganiami klienta, a także czy poziomy dostępu są ustawione prawidłowo, tak aby nieautoryzowani użytkownicy nie mieli dostępu do produkt&oacute;w.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy funkcjonalności Zarządzanie cenami - sprawdzenie, czy moduł B2B umożliwia zarządzanie cenami produkt&oacute;w zgodnie z wymaganiami klienta, a także czy ceny wyświetlane dla użytkownik&oacute;w są zgodne z ustalonymi poziomami dostępu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy wydajnościowe Obciążeniowe - sprawdzenie wydajności modułu B2B podczas dużego obciążenia, w tym: ile użytkownik&oacute;w może korzystać z modułu jednocześnie, jak szybko strony ładują się dla r&oacute;żnych poziom&oacute;w obciążenia, jak szybko moduł obsługuje zapytania itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy wydajnościowe czasu odpowiedzi - sprawdzenie czasu odpowiedzi modułu B2B podczas r&oacute;żnych czynności, w tym: czasu ładowania stron, czasu potrzebnego na przetworzenie zam&oacute;wień, czasu potrzebnego na zarejestrowanie użytkownika itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy wydajnościowe stabilności - sprawdzenie, czy moduł B2B działa stabilnie przez długi czas bez awarii, utraty danych itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy wydajnościowe bezpieczeństwa - sprawdzenie, czy moduł B2B jest bezpieczny, tj. czy nie istnieją luki w zabezpieczeniach, kt&oacute;re mogą prowadzić do atak&oacute;w hakerskich, kradzieży danych itp.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy wydajnościowe integracyjne - sprawdzenie, czy moduł B2B działa poprawnie z innymi moduł</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przeprowadzenie końcowej weryfikacji i akceptacji przez klienta podczas warsztat&oacute;w.</span></li>
</ul>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie modułu B2B na środowisku produkcyjnym klienta</span></p>
</td>
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Instalacja oraz konfiguracja odpowiednich wersji oprogramowania (php oraz jego rozszerzenia).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Instalacja i konfiguracja serwis&oacute;w third-party tj. Elasticsearch (wyszukiwarka), Redis, Varnish (full-page cache)</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br /></span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Konfiguracja SSL</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br /></span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Instalacja cronjob</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie produkcyjne&nbsp;</span></li>
</ul>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szkolenie pracownik&oacute;w i partner&oacute;w z obsługi modułu B2B&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 5pt 6pt 5pt 6pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wprowadzenie do modułu B2B - przedstawienie celu i korzyści związanych z wdrożeniem modułu B2B, objaśnienie, jak moduł działa, oraz przedstawienie funkcjonalności, kt&oacute;re pomogą pracownikom i partnerom zwiększyć efektywność biznesową.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Obsługa panelu administracyjnego - instruktaż dotyczący obsługi panelu administracyjnego modułu B2B, czyli wskazanie, jak dodawać i usuwać produkty, zmieniać ceny, zarządzać zam&oacute;wieniami, dostępami użytkownik&oacute;w, a także jak korzystać z narzędzi analitycznych i raport&oacute;w.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Obsługa panelu użytkownika - instruktaż dotyczący obsługi panelu użytkownika modułu B2B, czyli wskazanie, jak złożyć zam&oacute;wienie, jakie są dostępne metody płatności i dostawy, jak korzystać z koszyka zakup&oacute;w, a także jak zarządzać swoim kontem użytkownika.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Procesy biznesowe - instruktaż dotyczący proces&oacute;w biznesowych, kt&oacute;re są obsługiwane przez moduł B2B, a kt&oacute;re dotyczą m.in. zam&oacute;wień, faktur, płatności, dostaw itp. Należy om&oacute;wić, jakie są etapy tych proces&oacute;w, jakie dokumenty są potrzebne, jakie są terminy i warunki płatności itp. Integracja z innymi systemami - instruktaż dotyczący integracji modułu B2B z innymi systemami, takimi jak systemy ERP, CRM, księgowość itp. Należy om&oacute;wić, jakie informacje są wymieniane między systemami, jakie są wymagane parametry integracji, jakie są korzyści z integracji itp.</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
