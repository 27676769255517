import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import "./assets/styles/main.scss";
import { createBrowserHistory } from "history";
import MainProvider from "./context/MainProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const history = createBrowserHistory({ window });

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MainProvider>
      {/* @ts-ignore */}
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </MainProvider>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
