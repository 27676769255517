const html = `<h3 style="text-align: center;"><strong>Analiza SWOT technologii </strong><strong>cloud computingu</strong></h3>
<p><strong>Mocne strony:</strong></p>
<p><span style="font-weight: 400;">- elastyczność</span></p>
<p><span style="font-weight: 400;">- praktycznie nieograniczone zasoby</span></p>
<p><span style="font-weight: 400;">- efektywny model kosztowy</span></p>
<p><strong>Słabe strony</strong></p>
<p><span style="font-weight: 400;">- integracja ze starymi aplikacjami</span></p>
<p><span style="font-weight: 400;">- przepustowość łącza (większe zapotrzebowanie na pasmo łącza internetowego)</span></p>
<p><span style="font-weight: 400;">- ograniczony wpływ na rozw&oacute;j usług</span></p>
<p><strong>Szanse&nbsp;</strong></p>
<p><span style="font-weight: 400;">- łatwe zarządzanie usługami</span></p>
<p><span style="font-weight: 400;">- dostęp do usług niedostępnych we własnej infrastrukturze</span></p>
<p><span style="font-weight: 400;">- dostęp do usług z każdego miejsca</span></p>
<p><strong>Zagrożenia&nbsp;</strong></p>
<p><span style="font-weight: 400;">- brak kompetencji w organizacji</span></p>
<p><span style="font-weight: 400;">- możliwość utraty danych</span></p>
<p><span style="font-weight: 400;">- odpowiedzialność dostawcy usług SLA</span></p>`;

export default html;
