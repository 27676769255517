import ai_ml_1_article_01 from "./ai_ml/1/ai_ml_1_article_01";
import ai_ml_1_article_02 from "./ai_ml/1/ai_ml_1_article_02";
import ai_ml_1_article_03 from "./ai_ml/1/ai_ml_1_article_03";
import ai_ml_2_article_01 from "./ai_ml/2/ai_ml_2_article_01";
import ai_ml_3_article_01 from "./ai_ml/3/ai_ml_3_article_01";
import ai_ml_4_article_01 from "./ai_ml/4/ai_ml_4_article_01";
import ai_ml_4_article_02 from "./ai_ml/4/ai_ml_4_article_02";
import ai_ml_4_article_03 from "./ai_ml/4/ai_ml_4_article_03";
import ai_ml_5_article_01 from "./ai_ml/5/ai_ml_5_article_01";
import ai_ml_5_article_02 from "./ai_ml/5/ai_ml_5_article_02";
import ai_ml_5_article_03 from "./ai_ml/5/ai_ml_5_article_03";
import ai_ml_5_article_04 from "./ai_ml/5/ai_ml_5_article_04";
import ai_ml_5_article_05 from "./ai_ml/5/ai_ml_5_article_05";
import ai_ml_5_article_06 from "./ai_ml/5/ai_ml_5_article_06";
import ai_ml_5_article_07 from "./ai_ml/5/ai_ml_5_article_07";
import ai_ml_5_article_08 from "./ai_ml/5/ai_ml_5_article_08";
import ai_ml_5_article_09 from "./ai_ml/5/ai_ml_5_article_09";
import ai_ml_5_article_10 from "./ai_ml/5/ai_ml_5_article_10";
import ai_ml_5_article_11 from "./ai_ml/5/ai_ml_5_article_11";
import ai_ml_5_article_12 from "./ai_ml/5/ai_ml_5_article_12";
import ai_ml_6_article_01 from "./ai_ml/6/ai_ml_6_article_01";
import bi_1_article_01 from "./bi/1/bi_1_article_01";
import bi_1_article_02 from "./bi/1/bi_1_article_02";
import bi_1_article_03 from "./bi/1/bi_1_article_03";
import bi_2_article_01 from "./bi/2/bi_2_article_01";
import bi_2_article_02 from "./bi/2/bi_2_article_02";
import bi_2_article_03 from "./bi/2/bi_2_article_03";
import bi_2_article_04 from "./bi/2/bi_2_article_04";
import bi_3_article_01 from "./bi/3/bi_3_article_01";
import bi_3_article_02 from "./bi/3/bi_3_article_02";
import bi_3_article_03 from "./bi/3/bi_3_article_03";
import bi_3_article_04 from "./bi/3/bi_3_article_04";
import bi_3_article_05 from "./bi/3/bi_3_article_05";
import bi_3_article_06 from "./bi/3/bi_3_article_06";
import bi_3_article_07 from "./bi/3/bi_3_article_07";
import bi_3_article_08 from "./bi/3/bi_3_article_08";
import bi_3_article_09 from "./bi/3/bi_3_article_09";
import bi_3_article_10 from "./bi/3/bi_3_article_10";
import bi_4_article_01 from "./bi/4/bi_4_article_01";
import bi_4_article_02 from "./bi/4/bi_4_article_02";
import bi_4_article_03 from "./bi/4/bi_4_article_03";
import bi_5_article_01 from "./bi/5/bi_5_article_01";
import bi_5_article_02 from "./bi/5/bi_5_article_02";
import bi_5_article_03 from "./bi/5/bi_5_article_03";
import bi_5_article_04 from "./bi/5/bi_5_article_04";
import bi_5_article_05 from "./bi/5/bi_5_article_05";
import bi_5_article_06 from "./bi/5/bi_5_article_06";
import bi_5_article_07 from "./bi/5/bi_5_article_07";
import bi_5_article_08 from "./bi/5/bi_5_article_08";
import bi_5_article_09 from "./bi/5/bi_5_article_09";
import bi_5_article_10 from "./bi/5/bi_5_article_10";
import bi_5_article_11 from "./bi/5/bi_5_article_11";
import bi_5_article_12 from "./bi/5/bi_5_article_12";
import bi_6_article_01 from "./bi/6/bi_6_article_01";
import bi_6_article_02 from "./bi/6/bi_6_article_02";
import blockchain_1_article_01 from "./blockchain/1/blockchain_1_article_01";
import blockchain_1_article_02 from "./blockchain/1/blockchain_1_article_02";
import blockchain_2_article_01 from "./blockchain/2/blockchain_2_article_01";
import blockchain_3_article_01 from "./blockchain/3/blockchain_3_article_01";
import blockchain_4_article_01 from "./blockchain/4/blockchain_4_article_01";
import blockchain_4_article_02 from "./blockchain/4/blockchain_4_article_02";
import blockchain_5_article_01 from "./blockchain/5/blockchain_5_article_01";
import blockchain_5_article_02 from "./blockchain/5/blockchain_5_article_02";
import blockchain_5_article_03 from "./blockchain/5/blockchain_5_article_03";
import blockchain_5_article_04 from "./blockchain/5/blockchain_5_article_04";
import blockchain_5_article_05 from "./blockchain/5/blockchain_5_article_05";
import blockchain_5_article_06 from "./blockchain/5/blockchain_5_article_06";
import blockchain_5_article_07 from "./blockchain/5/blockchain_5_article_07";
import blockchain_5_article_08 from "./blockchain/5/blockchain_5_article_08";
import blockchain_5_article_09 from "./blockchain/5/blockchain_5_article_09";
import blockchain_5_article_10 from "./blockchain/5/blockchain_5_article_10";
import blockchain_5_article_11 from "./blockchain/5/blockchain_5_article_11";
import blockchain_5_article_12 from "./blockchain/5/blockchain_5_article_12";
import blockchain_6_article_01 from "./blockchain/6/blockchain_6_article_01";
import cloud_1_article_01 from "./cloud/1/cloud_1_article_01";
import cloud_1_article_02 from "./cloud/1/cloud_1_article_02";
import cloud_2_article_01 from "./cloud/2/cloud_2_article_01";
import cloud_3_article_01 from "./cloud/3/cloud_3_article_01";
import cloud_4_article_01 from "./cloud/4/cloud_4_article_01";
import cloud_4_article_02 from "./cloud/4/cloud_4_article_02";
import cloud_4_article_03 from "./cloud/4/cloud_4_article_03";
import cloud_5_article_01 from "./cloud/5/cloud_5_article_01";
import cloud_5_article_02 from "./cloud/5/cloud_5_article_02";
import cloud_5_article_03 from "./cloud/5/cloud_5_article_03";
import cloud_5_article_04 from "./cloud/5/cloud_5_article_04";
import cloud_5_article_05 from "./cloud/5/cloud_5_article_05";
import cloud_5_article_06 from "./cloud/5/cloud_5_article_06";
import cloud_5_article_07 from "./cloud/5/cloud_5_article_07";
import cloud_5_article_08 from "./cloud/5/cloud_5_article_08";
import cloud_5_article_09 from "./cloud/5/cloud_5_article_09";
import cloud_5_article_10 from "./cloud/5/cloud_5_article_10";
import cloud_5_article_11 from "./cloud/5/cloud_5_article_11";
import cloud_5_article_12 from "./cloud/5/cloud_5_article_12";
import cloud_6_article_01 from "./cloud/6/cloud_6_article_01";
import vr_ar_1_article_01 from "./vr_ar/1/vr_ar_1_article_01";
import vr_ar_1_article_02 from "./vr_ar/1/vr_ar_1_article_02";
import vr_ar_2_article_01 from "./vr_ar/2/vr_ar_2_article_01";
import vr_ar_3_article_01 from "./vr_ar/3/vr_ar_3_article_01";
import vr_ar_4_article_01 from "./vr_ar/4/vr_ar_4_article_01";
import vr_ar_4_article_02 from "./vr_ar/4/vr_ar_4_article_02";
import vr_ar_4_article_03 from "./vr_ar/4/vr_ar_4_article_03";
import vr_ar_5_article_01 from "./vr_ar/5/vr_ar_5_article_01";
import vr_ar_5_article_02 from "./vr_ar/5/vr_ar_5_article_02";
import vr_ar_5_article_03 from "./vr_ar/5/vr_ar_5_article_03";
import vr_ar_5_article_04 from "./vr_ar/5/vr_ar_5_article_04";
import vr_ar_5_article_05 from "./vr_ar/5/vr_ar_5_article_05";
import vr_ar_5_article_06 from "./vr_ar/5/vr_ar_5_article_06";
import vr_ar_5_article_07 from "./vr_ar/5/vr_ar_5_article_07";
import vr_ar_5_article_08 from "./vr_ar/5/vr_ar_5_article_08";
import vr_ar_5_article_09 from "./vr_ar/5/vr_ar_5_article_09";
import vr_ar_5_article_10 from "./vr_ar/5/vr_ar_5_article_10";
import vr_ar_5_article_11 from "./vr_ar/5/vr_ar_5_article_11";
import vr_ar_5_article_12 from "./vr_ar/5/vr_ar_5_article_12";
import vr_ar_6_article_01 from "./vr_ar/6/vr_ar_6_article_01";
import iot_1_article_01 from "./iot/1/iot_1_article_01";
import iot_1_article_02 from "./iot/1/iot_1_article_02";
import iot_2_article_01 from "./iot/2/iot_2_article_01";
import iot_3_article_01 from "./iot/3/iot_3_article_01";
import iot_4_article_01 from "./iot/4/iot_4_article_01";
import iot_4_article_02 from "./iot/4/iot_4_article_02";
import iot_4_article_03 from "./iot/4/iot_4_article_03";
import iot_5_article_01 from "./iot/5/iot_5_article_01";
import iot_5_article_02 from "./iot/5/iot_5_article_02";
import iot_5_article_03 from "./iot/5/iot_5_article_03";
import iot_5_article_04 from "./iot/5/iot_5_article_04";
import iot_5_article_05 from "./iot/5/iot_5_article_05";
import iot_5_article_06 from "./iot/5/iot_5_article_06";
import iot_5_article_07 from "./iot/5/iot_5_article_07";
import iot_5_article_08 from "./iot/5/iot_5_article_08";
import iot_5_article_09 from "./iot/5/iot_5_article_09";
import iot_5_article_10 from "./iot/5/iot_5_article_10";
import iot_5_article_11 from "./iot/5/iot_5_article_11";
import iot_5_article_12 from "./iot/5/iot_5_article_12";
import iot_6_article_01 from "./iot/6/iot_6_article_01";
import sm_1_article_01 from "./sm/1/sm_1_article_01";
import sm_1_article_02 from "./sm/1/sm_1_article_02";
import sm_1_article_03 from "./sm/1/sm_1_article_03";
import sm_2_article_01 from "./sm/2/sm_2_article_01";
import sm_3_article_01 from "./sm/3/sm_3_article_01";
import sm_4_article_01 from "./sm/4/sm_4_article_01";
import sm_4_article_02 from "./sm/4/sm_4_article_02";
import sm_4_article_03 from "./sm/4/sm_4_article_03";
import sm_5_article_01 from "./sm/5/sm_5_article_01";
import sm_5_article_02 from "./sm/5/sm_5_article_02";
import sm_5_article_03 from "./sm/5/sm_5_article_03";
import sm_5_article_04 from "./sm/5/sm_5_article_04";
import sm_5_article_05 from "./sm/5/sm_5_article_05";
import sm_5_article_06 from "./sm/5/sm_5_article_06";
import sm_5_article_07 from "./sm/5/sm_5_article_07";
import sm_5_article_08 from "./sm/5/sm_5_article_08";
import sm_5_article_09 from "./sm/5/sm_5_article_09";
import sm_5_article_10 from "./sm/5/sm_5_article_10";
import sm_5_article_11 from "./sm/5/sm_5_article_11";
import sm_5_article_12 from "./sm/5/sm_5_article_12";
import sm_6_article_01 from "./sm/6/sm_6_article_01";
import e_commerce_1_article_01 from "./e_commerce/1/e_commerce_1_article_01";
import e_commerce_1_article_02 from "./e_commerce/1/e_commerce_1_article_02";
import e_commerce_2_article_01 from "./e_commerce/2/e_commerce_2_article_01";
import e_commerce_3_article_01 from "./e_commerce/3/e_commerce_3_article_01";
import e_commerce_4_article_01 from "./e_commerce/4/e_commerce_4_article_01";
import e_commerce_4_article_02 from "./e_commerce/4/e_commerce_4_article_02";
import e_commerce_4_article_03 from "./e_commerce/4/e_commerce_4_article_03";
import e_commerce_5_article_01 from "./e_commerce/5/e_commerce_5_article_01";
import e_commerce_5_article_02 from "./e_commerce/5/e_commerce_5_article_02";
import e_commerce_5_article_03 from "./e_commerce/5/e_commerce_5_article_03";
import e_commerce_5_article_04 from "./e_commerce/5/e_commerce_5_article_04";
import e_commerce_5_article_05 from "./e_commerce/5/e_commerce_5_article_05";
import e_commerce_5_article_06 from "./e_commerce/5/e_commerce_5_article_06";
import e_commerce_5_article_07 from "./e_commerce/5/e_commerce_5_article_07";
import e_commerce_5_article_08 from "./e_commerce/5/e_commerce_5_article_08";
import e_commerce_5_article_09 from "./e_commerce/5/e_commerce_5_article_09";
import e_commerce_5_article_10 from "./e_commerce/5/e_commerce_5_article_10";
import e_commerce_5_article_11 from "./e_commerce/5/e_commerce_5_article_11";
import e_commerce_5_article_12 from "./e_commerce/5/e_commerce_5_article_12";
import e_commerce_6_article_01 from "./e_commerce/6/e_commerce_6_article_01";
import rpa_1_article_01 from "./rpa/1/rpa_1_article_01";
import rpa_1_article_02 from "./rpa/1/rpa_1_article_02";
import rpa_2_article_01 from "./rpa/2/rpa_2_article_01";
import rpa_3_article_01 from "./rpa/3/rpa_3_article_01";
import rpa_4_article_01 from "./rpa/4/rpa_4_article_01";
import rpa_4_article_02 from "./rpa/4/rpa_4_article_02";
import rpa_4_article_03 from "./rpa/4/rpa_4_article_03";
import rpa_5_article_01 from "./rpa/5/rpa_5_article_01";
import rpa_5_article_02 from "./rpa/5/rpa_5_article_02";
import rpa_5_article_03 from "./rpa/5/rpa_5_article_03";
import rpa_5_article_04 from "./rpa/5/rpa_5_article_04";
import rpa_5_article_05 from "./rpa/5/rpa_5_article_05";
import rpa_5_article_06 from "./rpa/5/rpa_5_article_06";
import rpa_5_article_07 from "./rpa/5/rpa_5_article_07";
import rpa_5_article_08 from "./rpa/5/rpa_5_article_08";
import rpa_5_article_09 from "./rpa/5/rpa_5_article_09";
import rpa_5_article_10 from "./rpa/5/rpa_5_article_10";
import rpa_5_article_11 from "./rpa/5/rpa_5_article_11";
import rpa_5_article_12 from "./rpa/5/rpa_5_article_12";
import rpa_6_article_01 from "./rpa/6/rpa_6_article_01";
import _3d_1_article_01 from "./3d/1/3d_1_article_01";
import _3d_1_article_02 from "./3d/1/3d_1_article_02";
import _3d_2_article_01 from "./3d/2/3d_2_article_01";
import _3d_3_article_01 from "./3d/3/3d_3_article_01";
import _3d_4_article_01 from "./3d/4/3d_4_article_01";
import _3d_5_article_01 from "./3d/5/3d_5_article_01";
import _3d_5_article_02 from "./3d/5/3d_5_article_02";
import _3d_5_article_03 from "./3d/5/3d_5_article_03";
import _3d_5_article_04 from "./3d/5/3d_5_article_04";
import _3d_5_article_05 from "./3d/5/3d_5_article_05";
import _3d_5_article_06 from "./3d/5/3d_5_article_06";
import _3d_5_article_07 from "./3d/5/3d_5_article_07";
import _3d_5_article_08 from "./3d/5/3d_5_article_08";
import _3d_5_article_09 from "./3d/5/3d_5_article_09";
import _3d_5_article_10 from "./3d/5/3d_5_article_10";
import _3d_5_article_11 from "./3d/5/3d_5_article_11";
import _3d_5_article_12 from "./3d/5/3d_5_article_12";
import _3d_6_article_01 from "./3d/6/3d_6_article_01";

const articles = {
    ai_ml_1_article_01,
    ai_ml_1_article_02,
    ai_ml_1_article_03,
    ai_ml_2_article_01,
    ai_ml_3_article_01,
    ai_ml_4_article_01,
    ai_ml_4_article_02,
    ai_ml_4_article_03,
    ai_ml_5_article_01,
    ai_ml_5_article_02,
    ai_ml_5_article_03,
    ai_ml_5_article_04,
    ai_ml_5_article_05,
    ai_ml_5_article_06,
    ai_ml_5_article_07,
    ai_ml_5_article_08,
    ai_ml_5_article_09,
    ai_ml_5_article_10,
    ai_ml_5_article_11,
    ai_ml_5_article_12,
    ai_ml_6_article_01,
    bi_1_article_01,
    bi_1_article_02,
    bi_1_article_03,
    bi_2_article_01,
    bi_2_article_02,
    bi_2_article_03,
    bi_2_article_04,
    bi_3_article_01,
    bi_3_article_02,
    bi_3_article_03,
    bi_3_article_04,
    bi_3_article_05,
    bi_3_article_06,
    bi_3_article_07,
    bi_3_article_08,
    bi_3_article_09,
    bi_3_article_10,
    bi_4_article_01,
    bi_4_article_02,
    bi_4_article_03,
    bi_5_article_01,
    bi_5_article_02,
    bi_5_article_03,
    bi_5_article_04,
    bi_5_article_05,
    bi_5_article_06,
    bi_5_article_07,
    bi_5_article_08,
    bi_5_article_09,
    bi_5_article_10,
    bi_5_article_11,
    bi_5_article_12,
    bi_6_article_01,
    bi_6_article_02,
    blockchain_1_article_01,
    blockchain_1_article_02,
    blockchain_2_article_01,
    blockchain_3_article_01,
    blockchain_4_article_01,
    blockchain_4_article_02,
    blockchain_5_article_01,
    blockchain_5_article_02,
    blockchain_5_article_03,
    blockchain_5_article_04,
    blockchain_5_article_05,
    blockchain_5_article_06,
    blockchain_5_article_07,
    blockchain_5_article_08,
    blockchain_5_article_09,
    blockchain_5_article_10,
    blockchain_5_article_11,
    blockchain_5_article_12,
    blockchain_6_article_01,
    cloud_1_article_01,
    cloud_1_article_02,
    cloud_2_article_01,
    cloud_3_article_01,
    cloud_4_article_01,
    cloud_4_article_02,
    cloud_4_article_03,
    cloud_5_article_01,
    cloud_5_article_02,
    cloud_5_article_03,
    cloud_5_article_04,
    cloud_5_article_05,
    cloud_5_article_06,
    cloud_5_article_07,
    cloud_5_article_08,
    cloud_5_article_09,
    cloud_5_article_10,
    cloud_5_article_11,
    cloud_5_article_12,
    cloud_6_article_01,
    vr_ar_1_article_01,
    vr_ar_1_article_02,
    vr_ar_2_article_01,
    vr_ar_3_article_01,
    vr_ar_4_article_01,
    vr_ar_4_article_02,
    vr_ar_4_article_03,
    vr_ar_5_article_01,
    vr_ar_5_article_02,
    vr_ar_5_article_03,
    vr_ar_5_article_04,
    vr_ar_5_article_05,
    vr_ar_5_article_06,
    vr_ar_5_article_07,
    vr_ar_5_article_08,
    vr_ar_5_article_09,
    vr_ar_5_article_10,
    vr_ar_5_article_11,
    vr_ar_5_article_12,
    vr_ar_6_article_01,
    iot_1_article_01,
    iot_1_article_02,
    iot_2_article_01,
    iot_3_article_01,
    iot_4_article_01,
    iot_4_article_02,
    iot_4_article_03,
    iot_5_article_01,
    iot_5_article_02,
    iot_5_article_03,
    iot_5_article_04,
    iot_5_article_05,
    iot_5_article_06,
    iot_5_article_07,
    iot_5_article_08,
    iot_5_article_09,
    iot_5_article_10,
    iot_5_article_11,
    iot_5_article_12,
    iot_6_article_01,
    sm_1_article_01,
    sm_1_article_02,
    sm_1_article_03,
    sm_2_article_01,
    sm_3_article_01,
    sm_4_article_01,
    sm_4_article_02,
    sm_4_article_03,
    sm_5_article_01,
    sm_5_article_02,
    sm_5_article_03,
    sm_5_article_04,
    sm_5_article_05,
    sm_5_article_06,
    sm_5_article_07,
    sm_5_article_08,
    sm_5_article_09,
    sm_5_article_10,
    sm_5_article_11,
    sm_5_article_12,
    sm_6_article_01,
    e_commerce_1_article_01,
    e_commerce_1_article_02,
    e_commerce_2_article_01,
    e_commerce_3_article_01,
    e_commerce_4_article_01,
    e_commerce_4_article_02,
    e_commerce_4_article_03,
    e_commerce_5_article_01,
    e_commerce_5_article_02,
    e_commerce_5_article_03,
    e_commerce_5_article_04,
    e_commerce_5_article_05,
    e_commerce_5_article_06,
    e_commerce_5_article_07,
    e_commerce_5_article_08,
    e_commerce_5_article_09,
    e_commerce_5_article_10,
    e_commerce_5_article_11,
    e_commerce_5_article_12,
    e_commerce_6_article_01,
    rpa_1_article_01,
    rpa_1_article_02,
    rpa_2_article_01,
    rpa_3_article_01,
    rpa_4_article_01,
    rpa_4_article_02,
    rpa_4_article_03,
    rpa_5_article_01,
    rpa_5_article_02,
    rpa_5_article_03,
    rpa_5_article_04,
    rpa_5_article_05,
    rpa_5_article_06,
    rpa_5_article_07,
    rpa_5_article_08,
    rpa_5_article_09,
    rpa_5_article_10,
    rpa_5_article_11,
    rpa_5_article_12,
    rpa_6_article_01,
    _3d_1_article_01,
    _3d_1_article_02,
    _3d_2_article_01,
    _3d_3_article_01,
    _3d_4_article_01,
    _3d_5_article_01,
    _3d_5_article_02,
    _3d_5_article_03,
    _3d_5_article_04,
    _3d_5_article_05,
    _3d_5_article_06,
    _3d_5_article_07,
    _3d_5_article_08,
    _3d_5_article_09,
    _3d_5_article_10,
    _3d_5_article_11,
    _3d_5_article_12,
    _3d_6_article_01
};

export default articles