const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<h3><strong>Skład zespołu realizującego projekt z obszaru Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Stanowisko&nbsp;</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Architekt BI/BD</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">przygotowanie projektu całości rozwiązania, struktury, model hurtowni, nazwy tabel</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">określenie sposobu pobierania i przepływu danych, struktury kodu</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dbałość o wydajność system</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">pomoc w problemach technicznych zespołowi</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">opracowanie High Level Design/Solution Concept oraz Low Level Design/szczeg&oacute;łowy projekt rozwiązania</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">mentoring deweloper&oacute;w</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">code review [analiza zgodności kodu ze standardami i dobrymi praktykami]</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">wycena koszt&oacute;w prac, w tym wynikających ze zmiany wymagań&nbsp;w trakcie trwania projektu</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Programista Python</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">programowanie skrypt&oacute;w realizujących założone scenariusza</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;testowanie rozwiązania &nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Programista ETL</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">przygotowanie założeń struktury procesu ETL</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">pobranie danych ze źr&oacute;dła danych&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">przygotowanie procesu załadowanie danych do odpowiednich warstw&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">budowa modelu analitycznego</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zadania w obszarze DevOps</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
