import ai_ml_course_1 from "./ai_ml/ai_ml_course_1.pdf";
import ai_ml_course_2 from "./ai_ml/ai_ml_course_2.pdf";
import ai_ml_course_3 from "./ai_ml/ai_ml_course_3.pdf";
import ai_ml_course_4 from "./ai_ml/ai_ml_course_4.pdf";
import bi_course_1 from "./bi/bi_course_1.pdf";
import bi_course_2 from "./bi/bi_course_2.pdf";
import bi_course_3 from "./bi/bi_course_3.pdf";
import bi_course_4 from "./bi/bi_course_4.pdf";
import blockchain_course_1 from "./blockchain/blockchain_course_1.pdf";
import blockchain_course_2 from "./blockchain/blockchain_course_2.pdf";
import blockchain_course_3 from "./blockchain/blockchain_course_3.pdf";
import blockchain_course_4 from "./blockchain/blockchain_course_4.pdf";
import cloud_course_1 from "./cloud/cloud_course_1.pdf";
import cloud_course_2 from "./cloud/cloud_course_2.pdf";
import cloud_course_3 from "./cloud/cloud_course_3.pdf";
import cloud_course_4 from "./cloud/cloud_course_4.pdf";
import e_commerce_course_1 from "./e_commerce/e_commerce_course_1.pdf";
import e_commerce_course_2 from "./e_commerce/e_commerce_course_2.pdf";
import e_commerce_course_3 from "./e_commerce/e_commerce_course_3.pdf";
import e_commerce_course_4 from "./e_commerce/e_commerce_course_4.pdf";
import iot_course_1 from "./iot/iot_course_1.pdf";
import iot_course_2 from "./iot/iot_course_2.pdf";
import iot_course_3 from "./iot/iot_course_3.pdf";
import iot_course_4 from "./iot/iot_course_4.pdf";
import rpa_course_1 from "./rpa/rpa_course_1.pdf";
import rpa_course_2 from "./rpa/rpa_course_2.pdf";
import rpa_course_3 from "./rpa/rpa_course_3.pdf";
import rpa_course_4 from "./rpa/rpa_course_4.pdf";
import sm_course_1 from "./sm/sm_course_1.pdf";
import sm_course_2 from "./sm/sm_course_2.pdf";
import sm_course_3 from "./sm/sm_course_3.pdf";
import sm_course_4 from "./sm/sm_course_4.pdf";
import vr_ar_course_1 from "./vr_ar/vr_ar_course_1.pdf";
import vr_ar_course_2 from "./vr_ar/vr_ar_course_2.pdf";
import vr_ar_course_3 from "./vr_ar/vr_ar_course_3.pdf";
import vr_ar_course_4 from "./vr_ar/vr_ar_course_4.pdf";
import _3d_course_1 from "./3d/3d_course_1.pdf";
import _3d_course_2 from "./3d/3d_course_2.pdf";
import _3d_course_3 from "./3d/3d_course_3.pdf";
import _3d_course_4 from "./3d/3d_course_4.pdf";

const courses = {
  ai_ml_course_1,
  ai_ml_course_2,
  ai_ml_course_3,
  ai_ml_course_4,
  bi_course_1,
  bi_course_2,
  bi_course_3,
  bi_course_4,
  blockchain_course_1,
  blockchain_course_2,
  blockchain_course_3,
  blockchain_course_4,
  cloud_course_1,
  cloud_course_2,
  cloud_course_3,
  cloud_course_4,
  e_commerce_course_1,
  e_commerce_course_2,
  e_commerce_course_3,
  e_commerce_course_4,
  iot_course_1,
  iot_course_2,
  iot_course_3,
  iot_course_4,
  rpa_course_1,
  rpa_course_2,
  rpa_course_3,
  rpa_course_4,
  sm_course_1,
  sm_course_2,
  sm_course_3,
  sm_course_4,
  vr_ar_course_1,
  vr_ar_course_2,
  vr_ar_course_3,
  vr_ar_course_4,
  _3d_course_1,
  _3d_course_2,
  _3d_course_3,
  _3d_course_4,
};

export default courses;
