const html = `<p><strong>SILNE STRONY</strong></p>
<ol>
<li><span style="font-weight: 400;"> Niska bariera wejścia. Marketing w social media jest dostępny na r&oacute;żnych poziomach zaangażowania i budżetu.</span></li>
<li><span style="font-weight: 400;"> R&oacute;żnorodność zastosowań - media społecznościowe mogą być zastosowane na każdym etapie ścieżki sprzedażowej (od budowy świadomości, po wspieranie lojalności już pozyskanych klient&oacute;w)</span></li>
<li><span style="font-weight: 400;"> Dostęp do szczeg&oacute;łowych danych o użytkownikach - możliwość monitorowania aktywności, opinii, sentymentu, możliwość bezpośredniego kontaktu.</span></li>
<li><span style="font-weight: 400;"> Skalowalność geograficzna - te same narzędzia mogą być wykorzystywane na wielu rynkach.</span></li>
<li><span style="font-weight: 400;"> Rozbudowane ekosystemy reklamowe pozwalające na integrację r&oacute;żnych kampanii, platform i danych.</span></li>
<li><span style="font-weight: 400;"> Mocne wsparcie budowy marki, szczeg&oacute;lnie w obszarach wizerunkowych, brand equity i budowy świadomości/rozpoznawalności.</span></li>
</ol>
<p><strong>SŁABE STRONY</strong></p>
<ol>
<li><span style="font-weight: 400;"> Czasochłonność obsługi i wysokie koszty produkcji wysokiej jakości treści.</span></li>
<li><span style="font-weight: 400;"> Konieczność elastycznego, szybkiego reagowania na trendy i aktywność społeczności.</span></li>
<li><span style="font-weight: 400;"> Wysoka konkurencja innych podmiot&oacute;w walczących o uwagę konsument&oacute;w.</span></li>
<li><span style="font-weight: 400;"> Trudności w zidentyfikowaniu klient&oacute;w biznesowych i w pozyskaniu ich zaangażowania (istotne szczeg&oacute;lnie w przypadku produkt&oacute;w B2B)</span></li>
<li><span style="font-weight: 400;"> Konieczność wspierania budowy zasięgu kampaniami płatnymi (zazwyczaj).</span></li>
<li><span style="font-weight: 400;"> Działania skuteczne tylko w przypadku grup docelowych aktywnych w mediach społecznościowych.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>SZANSE</strong></p>
<ol>
<li><span style="font-weight: 400;"> Zwiększająca się popularność zdalnych form przyswajania wiedzy (webinary, transmisje live, tutoriale zamiast szkoleń i konferencji).</span></li>
<li><span style="font-weight: 400;"> Rosnąca liczba nowych platform i obecność na nich niemal wszystkich grup demograficznych.</span></li>
<li><span style="font-weight: 400;"> Rozszerzanie się sieci reklamowych na kolejne serwisy i aplikacje - zwiększanie zasięgu i punkt&oacute;w styku.</span></li>
<li><span style="font-weight: 400;"> Dostępność nowych rozwiązań opartych na AI usprawniających obsługę medi&oacute;w społecznościowych.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>ZAGROŻENIA</strong></p>
<ol>
<li><span style="font-weight: 400;"> Zmiany prawne i systemowe - wprowadzane odg&oacute;rnie ograniczenia prywatności mogą utrudniać remarketing i śledzenie aktywności klient&oacute;w.</span></li>
<li><span style="font-weight: 400;"> Dynamicznie zmieniające się funkcjonalności platform oraz trendy utrudniają utrzymanie długoterminowej strategii komunikacji.</span></li>
<li><span style="font-weight: 400;"> Zależność technologiczna od platform społecznościowych, a co za tym idzie, od ich błęd&oacute;w i nieprawidłowości.</span></li>
<li><span style="font-weight: 400;"> Rosnące koszty kampanii płatnych w związku z podnoszącą się konkurencyjnością (coraz więcej biznes&oacute;w przenosi się do internetu).</span></li>
</ol>`;

export default html;
