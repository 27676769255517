const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="border-left: solid #000000 0.5pt; border-right: solid #000000 0.5pt; border-bottom: solid #000000 1pt; border-top: solid #000000 0.5pt; vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word;">
<p style="line-height: 1.2; text-align: center; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać pracownikom klienta po zakończeniu wdrożenia rozwiązania wykorzystującego cloud computing</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po przeniesieniu do chmury państwa aplikacji do sprzedaży (sklepu internetowego) wszystkie funkcjonalności i spos&oacute;b prezentacji danych pozostaje bez zmian. Dzięki temu nie będą musieli Państwo od nowa uczyć się obsługi rozwiązania</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Teraz zaprezentowana i om&oacute;wiona zostanie wysokopoziomowa architektura rozwiązania. Z racji specyfiki Państwa sklepu internetowego oraz wskazanych potrzeb biznesowych zdecydowaliśmy się na architekturę mikroserwis&oacute;w. Jest to nowoczesna architektura pozwalająca w łatwy spos&oacute;b uruchomić sklep w chmurze obliczeniowej oraz bezprzerwowe wprowadzanie nowych funkcjonalności oczekiwanych w niedalekiej przyszłości przez biznes.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dokumentację techniczną i funkcjonalną rozwiązania zamieściliśmy w katalogu projektowym w Państwa narzędziu do kolaboracji.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jeśli mają Państwo jakieś pytania, proszę o ich zadanie. Pozostawiam także dane kontaktowe, na wypadek gdyby pytania pojawiły się po zakończeniu spotkania. W przypadku pojawienia się zapotrzebowania na nowe usługi chmurowe, zapraszam do kontaktu.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
