const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<h3><strong>Agenda/struktura spotkania rozpoczynającego realizację projektu z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Wykaz temat&oacute;w do om&oacute;wienia</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Poznajmy się. Zesp&oacute;ł projektu&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zakres wsp&oacute;łpracy&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Cele projektu</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Harmonogram realizacji projektu&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Podział pracy i odpowiedzialności w projekcie (RACI)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Lista rzeczy wykonawca usługi potrzebuje</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzia planowane do wykorzystywania w projekcie&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Kamienie milowe projektu</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
