const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Propozycja&nbsp;</strong></p>
</td>
<td>
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Budowa nowej strony internetowej&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">W trakcie działań marketingowych działania analityczne wykazały, że strona www nie wykorzystuje pełnego potencjału sprzedażowego kampanii marketingowej. Zbudowanie strony od nowa pozwoli na odświeżenie wizerunku i poprawę mechanizm&oacute;w sprzedażowych.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie systemu CRM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znaczące zwiększenie ilości obsługiwanych klient&oacute;w i mamy sygnały, że dział sprzedaży nie jest w stanie utrzymać płynności obsługi zapytań, wymaga wdrożenie efektywniejszego i bardziej rozbudowanego CRM, kt&oacute;ry wpłynie pozytywnie na zadowolenie pracownik&oacute;w oraz działania marketingowe.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie materiał&oacute;w wielojęzycznych w celu przygotowania do wejścia na nowe rynki.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Aplikacja jest już pozytywnie zweryfikowana na rynku krajowym i gotowa do wdrożenia nowych wersjach językowych.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
