const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 49.6018%;"><strong>Wizualizacja danych</strong></td>
<td style="width: 49.6018%;">Og&oacute;ł działań prowadzonych w obszarze graficznej prezentacji danych, umożliwiający obserwowanie trend&oacute;w i wzorc&oacute;w w danych, kt&oacute;re nie są od razu widoczne podczas analizowania surowych wartości (cyfry, tekst)</td>
</tr>
<tr>
<td style="width: 49.6018%;"><strong>Warstwa pośrednia (stage, STG)</strong></td>
<td style="width: 49.6018%;">Warstwa/wydzielona część w bazie danych (hurtowni danych) tworzona jest w celu uniknięcia częstego odpytywania źr&oacute;deł danych oraz zapewnienia wysokiej jakości danych w systemach klienckich</td>
</tr>
<tr>
<td style="width: 49.6018%;"><strong>Model hurtowni (bazy) danych</strong></td>
<td style="width: 49.6018%;">Spos&oacute;b/logika organizacji/zapisania danych w tabelach i relacjach, pozwalający/a na szybkie odczytywanie danych z bazy przez systemy powiązane</td>
</tr>
<tr>
<td style="width: 49.6018%;"><strong>Baza danych</strong></td>
<td style="width: 49.6018%;">Zorganizowany zbi&oacute;r usystematyzowanych informacji, czyli danych, służący do zbierania i organizowania informacji, zwykle przechowywany w systemie komputerowym, w formie elektronicznej</td>
</tr>
</tbody>
</table>`;

export default html;
