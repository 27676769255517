const html = `<p style="text-align: justify;"><strong>Macierz RACI</strong>&nbsp;to tabela przedstawiająca zestaw zadań i os&oacute;b w projekcie, wraz ze wskazaniem zakres&oacute;w odpowiedzialności (r&oacute;l) poszczeg&oacute;lnych osob&oacute;w w zakresie określonych zadań. Macierz RACI jest stosunkowo łatwa do zbudowania. Jest to dwuwymiarowa macierz z uczestnikami, ich rolami (Responsible, Accountable, Consult, Inform) oraz czynnościami lub zadaniami wyszczeg&oacute;lnionymi w poziomej części macierzy. W samej tabeli znajdują się oznaczenia RACI.</p>
<p style="text-align: justify;"><strong>Przykład RACI: &nbsp; &nbsp;Role A &nbsp; &nbsp;Role B &nbsp; &nbsp;Role C &nbsp; &nbsp;Role D</strong></p>
<p style="text-align: justify;">Activity/Task 1&nbsp; &nbsp;&nbsp;R&nbsp; &nbsp;&nbsp;A&nbsp; &nbsp;&nbsp;C&nbsp; &nbsp;&nbsp;I</p>
<p style="text-align: justify;">Activity/Task 2&nbsp; &nbsp;&nbsp;A&nbsp; &nbsp;&nbsp;R&nbsp; &nbsp;&nbsp;I&nbsp; &nbsp;&nbsp;-</p>
<p style="text-align: justify;">Activity/Task 3&nbsp; &nbsp;&nbsp;C&nbsp; &nbsp;&nbsp;C&nbsp; &nbsp;&nbsp;-&nbsp; &nbsp;&nbsp;A</p>
<p style="text-align: justify;">Skr&oacute;t RACI jest akronimem od angielskich sł&oacute;w: Responsible, Accountable, Consult, Inform. Każdej osobie w zespole możemy przyporządkować jedną lub więcej r&oacute;l, tj. R, A, C lub I. Poniżej opisane zostaną wskazania do przypisania danej osobie określonej roli w projekcie.&nbsp;</p>
<p style="text-align: justify;"><strong>Responsible (R)</strong></p>
<p style="text-align: justify;">Przypisać należy osobie odpowiedzialnej za wykonie określonego zadania (odpowiedzialność dotyczy nie tylko samej realizacji ale także jakości i terminowości realizacji zadania). Stopień i poziom odpowiedzialności są określone przez osobę odpowiedzialną lub kierownika procesu. Osoba odpowiedzialna nie jest konsultantem ani nadzorcą. Osoba odpowiedzialna zawsze ma bezpośredni udział w realizacji prac.</p>
<p style="text-align: justify;"><strong>Accountable (A)</strong></p>
<p style="text-align: justify;">Przypisać należy osobie odpowiedzialnej za dostarczenie produktu, programu, zadania lub usługi w określonym terminie i na uzgodnionym poziomie jakości. Do obowiązk&oacute;w osoby należy także zatwierdzenie kompletności realizacji zadania. Pełni ona zatem funkcję ostatecznej instatni decydującej o akceptacji realizacji zadania.&nbsp;</p>
<p style="text-align: justify;"><strong>Consulted (C)</strong></p>
<p style="text-align: justify;">Przypisać należy osobie konsultującej (np. eksperci, kadra zarządzająca)spos&oacute;b i/lub zakres realizacji zadania. Osoba ta ma bezpośredni wpływ na to, czy zadanie zostanie uznane za zrealizowane poprawnie czy nie.</p>
<p style="text-align: justify;"><strong>Informed (I)</strong></p>
<p style="text-align: justify;">Przypisać należy osobie, kt&oacute;ra powinna zostać poinformowana o decyzjach lub postępach w realizacji zadania. W przeciwieństwie do os&oacute;b konsultowanych (C), komunikacja z osobami poinformowanymi (I), ma charakter jednokierunkowy. Poinformowane strony nie wnoszą bezpośredniego wkładu w podejmowanie decyzji).</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Korzyści z używania RACI w projektach: </strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;pomaga w analizie obciążenia pracą</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;daje możliwość szybkie reorganizacji i rotacji pracownik&oacute;w, poprzez umożliwienie nowym osobom szybkiego rozpoznanie ich r&oacute;l i obowiązk&oacute;w</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;poprawia pracę zespołową dzięki zapewnieniu dostępu do informacji o zakresach prac całego zespołu</p>
<p style="text-align: justify;">● &nbsp; &nbsp;stanowi prostą i wydajną metoda dokumentowania r&oacute;l i obowiązk&oacute;w w projekcie</p>`;

export default html;
