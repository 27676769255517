const html = `<p><strong>CASE STUDY PROJEKTU</strong></p>
<p><strong>Tytuł projektu: Przygotowanie i wdrożenie modelu NLP klasyfikowanie skarg/problem&oacute;w klient&oacute;w dużej platformy e-commerce (przypisywanie rekordu/wyniku do danej grupy/kategorii, e-mail triage)</strong></p>
<p>&nbsp;</p>
<p><strong>Gł&oacute;wny problem klienta:</strong><span style="font-weight: 400;"> Czytanie skarg i uwag zgłaszanych przez klient&oacute;w firmy jest czasochłonne i wpływa na czas reakcji , co obniża satysfakcję kupujących oraz wymaga wielu godzin pracy pracownik&oacute;w.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">W ramach projektu wdrożono model NLP. Model na podstawie analizy treści maila przypisuje skargę/uwagę do kategorii oraz nadaje jej poziom priorytetu. Rozwiązanie przetwarza i zapisuje wygenerowane dane w hurtowni danych. Wyniki wykorzystywane są przez obecne systemy klienta. Zależnie od kategorii priorytetu niekt&oacute;re sprawy rozwiązywane są automatycznie przez RPA a pozostałe skierowywane są do specjalist&oacute;w.</span> <span style="font-weight: 400;">Zależnie od rodzaju priorytetu sprawy są kolejkowane do realizacji.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">redukcja czasu pracy specjalist&oacute;w ds obsługi klienta, zwiększenie satysfakcji klietn&oacute;w poprzez zwiększenie szybkości reakcji</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">system RPA wykorzystywany przez klienta,</span> <span style="font-weight: 400;">hurtownia danych AWS Redshift , data lake (AWS S3), data lakehouse [platforma technologiczna pozwalająca na wykorzystanie hurtowni danych oraz data lake na potrzeby analityki] (Databricks), biblioteki NLP (spaCy, Huggingface), narzędzie do harmonogramowania zadań realizowanych przez poszczeg&oacute;lne komponenty rozwiązania.</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">5 msc</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">PM (0,25FTE), Data Scientist (1FTE) , ML Engineer NLP Specialization (1FTE), AWS Data Engineer (0,75 FTE)</span></p>
<p><strong>Budżet: </strong><span style="font-weight: 400;">750tys. zł</span></p>`;

export default html;
