const html = `<h3 style="text-align: center;"><strong>Wykaz technologii wykorzystywanych w projektach produkcyjnych i 3D wraz z opisem i przykładami</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Technologia/narzędzie&nbsp;</strong></p>
</td>
<td>
<p><strong>Opis&nbsp;</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii&nbsp;</strong></p>
</td>
<td>
<p><strong>Czynniki wyboru/odrzucenia technologii&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Drukarka 3D w technologii FDM</strong></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Urządzenie umożliwiające wytwarzanie obiekt&oacute;w materialnych odwzorowujących modele cyfrowe warstwa po warstwie z rozgrzanego materiału w postaci żyłki (filamentu). Przykładowe parametry drukarki:</span></p>
<p><span style="font-weight: 400;">- zamknięta komora</span></p>
<p><span style="font-weight: 400;">-praca na materiałach wymagających wyżej temperatury</span></p>
<p><span style="font-weight: 400;">- możliwość zdalnej kontroli</span></p>
<p><span style="font-weight: 400;">- jedna dysza</span></p>
<p><span style="font-weight: 400;">- pole robocze minimum 250x250x250 mm</span></p>
<p><span style="font-weight: 400;">- czujnik filamentu</span></p>
<p><span style="font-weight: 400;">- filtr powietrza</span></p>
<p><span style="font-weight: 400;">- wbudowana kamera</span></p>
<p><span style="font-weight: 400;">- wysokotemperaturowy ekstruder</span></p>
<p><span style="font-weight: 400;">- głośność&nbsp; &lt; 60 dB</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Apium P220, FlashForge Creator 4, ZMORPH i500 itp.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość technologii druku 3D i obsługi danej drukarki.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrana technologia druku 3D powinna spełniać wymogi stawiane przez klienta odnośnie cech finalnego wydruku np. wytrzymałość, wykończenie powierzchni, rodzaj użytego materiału. Standardowo do wydruk&oacute;w małych, plastikowych element&oacute;w&nbsp; używa się właśnie technologii FDM.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Oprogramowanie do modelowania 3D</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Oprogramowanie do projektowania wspomaganego komputerowo umożliwiające modelowanie obiekt&oacute;w i definiowanie ich konstrukcji np. cechami geometrycznymi, dynamicznymi lub materiałowymi. Przy jego użyciu tworzy się model cyfrowy (, model 3D, najpopularniejszy format to .stl), kt&oacute;ry definiuje zbi&oacute;r punkt&oacute;w XYZ opisujących geometrię obiektu.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> SOLIDWORKS, Autodesk Fusion 360, Blender, Netfabb</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność modelowania 3D</span></p>
<p><span style="font-weight: 400;">Znajomość oprogramowania typu CAD, CAD/CAM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrane narzędzie powinno być sp&oacute;jne z pozostałymi technologiami używanymi w przedsiębiorstwie. Wielu grafik&oacute;w ma swoje ulubione programy, z kt&oacute;rymi pracuje.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Slicer</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Oprogramowanie służące do &bdquo;przetłumaczenia&rdquo; modelu 3D na komendy zrozumiałe przez drukarkę 3D. Slicer dzieli model 3D na warstwy i dla każdej z nich definiuje ścieżki obrysu i wypełnienia. Użytkownik może sam ustalać parametry druku, np. grubość warstwy, szybkość druku, zdefiniować jak obiekt ma być zorientowany w komorze drukarki, zlecić druk podp&oacute;r&nbsp; w sytuacji gdy jest to wymagane, by druk się nie zapadł, wybrać temperaturę druku.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;"><strong>Przykłady:</strong> Simplify3D, CURA, OctoPrint</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi slicera oraz doboru parametr&oacute;w druku 3D</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrane narzędzie powinno być sp&oacute;jne z pozostałymi technologiami używanymi w przedsiębiorstwie. Wielu technolog&oacute;w ma swoje ulubione programy, z kt&oacute;rymi pracuje.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Pakiet aplikacji biurowych</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia przygotowanie dokument&oacute;w tekstowych i prezentacji dla klienta, a także korespondencję mailową.</span></p>
<p><span style="font-weight: 400;">Przykłady: Office 365, Open Office</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość obsługi pakietu Word, Excel, Power Point.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Oprogramowanie do tworzenia dwuwymiarowej grafiki wektorowej</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Oprogramowanie do tworzenia grafiki, w kt&oacute;rej obraz opisany jest za pomocą figur geometrycznych.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><strong>Przykłady</strong><span style="font-weight: 400;">: Adobe Illustrator, CorelDRAW</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność tworzenia grafiki wektorowej</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrane narzędzie powinno być sp&oacute;jne z pozostałymi technologiami używanymi w przedsiębiorstwie. Wielu grafik&oacute;w ma swoje ulubione programy, z kt&oacute;rymi pracuje.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Ploter</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Sterowane komputerowo urządzenie tnące, służące do nacinania lub wycinania kształt&oacute;w w arkuszach miękkich materiał&oacute;w. Działa na zasadzie głowicy wyposażonej w ostrze, kt&oacute;ra przesuwa się nad powierzchnią wycinanego materiału.</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Roland, Mutoh, GCC, Summa</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi plotera</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrane narzędzie powinno być sp&oacute;jne z pozostałymi urządzeniami w dziale produkcyjnym.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Tokarka</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Obrabiarka przeznaczona do obr&oacute;bki skrawaniem przedmiot&oacute;w najczęściej o powierzchni brył obrotowych (wałki, stożki, kule, gwinty wewnętrzne i zewnętrzne). Narzędziem obr&oacute;bczym są najczęściej n&oacute;ż tokarski, wiertło lub narzędzia do gwintowania.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Holzmann, Hecht, BERNARDO</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi tokarki</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrane narzędzie powinno być sp&oacute;jne z pozostałymi urządzeniami w dziale produkcyjnym.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wtryskarka</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Obrabiarka do formowania wtryskowego tworzyw sztucznych. Składa się z zasobnika na surowiec w postaci granulatu oraz ogrzewanego cylindra, w kt&oacute;rym tworzywo ulega uplastycznieniu. Tłok lub ślimak przetłacza stopione tworzywo do formy, w kt&oacute;rej zastyga ono (zestala się) w kształtkę zwaną r&oacute;wnież wypraską.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi wtryskarki</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wybrane narzędzie powinno być sp&oacute;jne z pozostałymi urządzeniami w dziale produkcyjnym.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
