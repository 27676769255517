import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GameMockType from "../../../interfaces/game/GameMockType";
import Accordion from "./Accordion";
import { accordionHeader, getNoUserGameLine } from "./GameScreen";
import StyledLinearProgress from "../../components/StyledLinearProgress";
import StyledCircularProgress from "../../components/StyledCircularProgress";
import UserGameScoreType from "../../../interfaces/game/UserGameScoreType";
import SubtaskType from "../../../interfaces/game/SubtaskType";
import UserGameScoreSubtaskType from "../../../interfaces/game/UserGameScoreSubtaskType";
import { useSelector } from "react-redux";
import AverageScore from "../../../interfaces/game/AverageScore";
import { AppState } from "../../../store/store";
import { secondsToHms } from "../../../utils/utils";

interface Props {
  gameData: GameMockType | null;
  gameScore: UserGameScoreType | null;
}

const ScoresAccordion = (props: Props) => {
  const { t } = useTranslation();
  const { gameData, gameScore } = props;

  const [mainGameScore, setMainGameScore] = useState<number | null>(null);
  const [mainSubtasksScore, setMainSubtasksScore] = useState<
    | {
        key: string;
        value: number;
      }[]
    | null
  >(null);

  const averageScores: AverageScore[] = useSelector(
    (state: AppState) => state.gameReducer.averageScores
  );

  useEffect(() => {
    if (gameScore) {
      let subtasksScores: {
        key: string;
        value: number;
      }[] = [];
      let mainScore = 0;
      gameScore.subtasks.forEach((subtask) => {
        let subtaskSum = subtask.score;
        subtasksScores.push({
          key: subtask.subtaskKey,
          value: subtaskSum,
        });
        mainScore += subtaskSum;
      });
      mainScore = Math.floor(
        gameScore.subtasks.length !== 0
          ? mainScore / gameScore.subtasks.length
          : 0
      );
      setMainGameScore(mainScore);
      setMainSubtasksScore(subtasksScores.length > 0 ? subtasksScores : null);
    }
  }, [gameScore]);

  const subtaskScoreRow = (
    subtask: SubtaskType,
    reportObject: { key: string; label: string }
  ) => {
    const subtaskScore = gameScore!.subtasks.find(
      (item) => item.subtaskKey === subtask.key
    );
    if (subtaskScore) {
      const reportScore = subtaskScore.reports.find(
        (item) => item.reportKey === reportObject.key
      );
      if (reportScore) {
        return (
          <li>
            <div className="subtask_li_div">
              <div>{reportObject.label}</div>
              <StyledLinearProgress score={reportScore.reportScore} />
            </div>
          </li>
        );
      }
    }
  };

  const renderScoresSection = (
    label: string,
    currentSubtaskScores: UserGameScoreSubtaskType,
    subtask: SubtaskType,
    rows: { key: string; label: string }[]
  ) => {
    const rowsWithScore = rows.filter((row) =>
      currentSubtaskScores.reports.some((item) => item.reportKey === row.key)
    );
    if (rowsWithScore.length === 0) return null;
    return (
      <div className="subtask_section">
        <span className="subtask_section_title">{label}</span>
        <ul>{rowsWithScore.map((item) => subtaskScoreRow(subtask, item))}</ul>
      </div>
    );
  };

  const smallScore = (
    title: string,
    score: number | string,
    type: "number" | "percentage" | "string",
    icon?: any
  ) => {
    let scoreDisplay = score;
    if (type === "percentage") scoreDisplay = `${score}%`;
    return (
      <div className="smallScore">
        <span className="smallScore_title">{title}</span>
        <div className="smallScore_score">
          {icon && <img src={icon} alt="icon" />}
          {scoreDisplay}
        </div>
      </div>
    );
  };

  const renderSubtasks = () => {
    return gameData!.subtasks
      .filter((item) =>
        gameScore!.subtasks.some((score) => score.subtaskKey === item.key)
      )
      .map((subtask: SubtaskType) => {
        const currentSubtaskScores = gameScore!.subtasks.find(
          (item) => item.subtaskKey === subtask.key
        );
        const mainSubtaskScore = mainSubtasksScore
          ? mainSubtasksScore.find((item) => item.key === subtask.key)
          : null;
        if (currentSubtaskScores) {
          const correctQuestionsPercentage =
            currentSubtaskScores.doneTime >= 0
              ? Math.floor(
                  (currentSubtaskScores.correctQuestions /
                    currentSubtaskScores.overallQuestions) *
                    100
                )
              : 0;
          const correctAnswersPercentage =
            currentSubtaskScores.doneTime >= 0
              ? Math.floor(
                  (currentSubtaskScores.correctAnswers /
                    currentSubtaskScores.overallAnswers) *
                    100
                )
              : 0;
          let averageTime = null;
          const averageSubtaskScore = averageScores.find(
            (item) => item.subtaskKey === currentSubtaskScores.subtaskKey
          );
          if (averageSubtaskScore)
            averageTime = averageSubtaskScore.averageTime;

          return (
            <div className={`subtask`} key={subtask.key}>
              <div className="subtask_title">
                <span className="goal">{"Cel:"}</span>
                {subtask.title}
              </div>
              <div className="mainScore">
                <span>{"Zrealizowany"}</span>
                {mainSubtaskScore ? (
                  <StyledCircularProgress score={mainSubtaskScore.value} />
                ) : (
                  "Wynik"
                )}
                <div />
              </div>
              {renderScoresSection(
                "Znasz:",
                currentSubtaskScores,
                subtask,
                subtask.knowledge
              )}
              {renderScoresSection(
                "Umiesz:",
                currentSubtaskScores,
                subtask,
                subtask.skills
              )}
              <div className="extraScores">
                <span className="title">{"Dodatkowe statystyki"}</span>
                <div className="scoresWrapper">
                  {smallScore(
                    "Testy wiedzy",
                    currentSubtaskScores.knowledgeTest,
                    "percentage"
                  )}
                  {smallScore(
                    "Właściwie zadane pytania",
                    correctQuestionsPercentage,
                    "percentage"
                  )}
                  {smallScore(
                    "Właściwe udzielone odpowiedzi",
                    correctAnswersPercentage,
                    "percentage"
                  )}
                  {smallScore(
                    "Czas wykonania zadania",
                    `${
                      currentSubtaskScores.doneTime >= 0
                        ? secondsToHms(currentSubtaskScores.doneTime)
                        : "0 s"
                    }${
                      averageTime !== null
                        ? ` (średni czas innych użytkowników: ${secondsToHms(
                            averageTime
                          )})`
                        : ""
                    }`,
                    "string"
                  )}
                </div>
              </div>
            </div>
          );
        }
      });
  };

  const renderMainGameScore = () => {
    return (
      <div className="mainGameScoreWrapper">
        <div>{"Twój całkowity wynik:"}</div>
        <StyledCircularProgress
          raiting={mainScoreColor(mainGameScore!)}
          score={mainGameScore!}
        />
        <div className="uppercase">{mainScoreLabel(mainGameScore!)}</div>
      </div>
    );
  };

  const mainScoreColor = (score: number) => {
    if (score <= 50) return "red";
    if (score > 50 && score <= 75) return "orange";
    if (score > 76) return "green";
  };

  const mainScoreLabel = (score: number) => {
    if (score <= 50) return "Niezadowalający";
    if (score > 50 && score <= 75) return "Zadowalający";
    if (score > 76) return "Bardzo dobry";
  };

  return (
    <div className="scoresAccordion">
      <Accordion
        HeaderComponent={accordionHeader("Twoje wyniki")}
        BodyComponent={
          <div className="bodyWrapper">
            {gameScore && gameData && gameScore.subtasks.length > 0 ? (
              <>
                {mainGameScore && renderMainGameScore()}
                <div className="styledSelectHeader">
                  {"Poziom realizacji poszczególnych celów edukacyjnych:"}
                </div>
                {renderSubtasks()}
              </>
            ) : (
              getNoUserGameLine("Zagraj w grę, aby zobaczyć wyniki.")
            )}
          </div>
        }
      />
    </div>
  );
};

export default ScoresAccordion;
