import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UserType from "../../interfaces/user/UserType";
import { updateUser } from "../../services/user.service";
import { AppDispatch, AppState } from "../../store/store";
import { getInputRules } from "../../utils/utils";
import StyledButton from "../components/StyledButton";
import StyledTextInput from "../components/StyledTextInput";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface Props {
  onClose: () => void;
}

const SettingsForm = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { onClose } = props;
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<FormValues>();

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
      setValue("firstName", user.firstName ? user.firstName : "");
      setValue("lastName", user.lastName ? user.lastName : "");
      setValue("phoneNumber", user.phoneNumber ? user.phoneNumber : "");
    }
  }, [user]);

  const onSubmit = (data: FormValues) => {
    dispatch(
      updateUser({
        ...user,
        phoneNumber: data.phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        id: user!.id,
      })
    );
  };

  return (
    <form className="settingsForm">
      <Controller
        name="email"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            disabled={true}
            onChange={onChange}
            error={errors.email && (errors.email.message as string)}
            label={t("buyProductScreen.email")}
          />
        )}
        rules={getInputRules(false, 60)}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.firstName && (errors.firstName.message as string)}
            label={"Imię"}
          />
        )}
        rules={getInputRules(true, 32)}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.lastName && (errors.lastName.message as string)}
            label={"Nazwisko"}
          />
        )}
        rules={getInputRules(true, 32)}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.phoneNumber && (errors.phoneNumber.message as string)}
            label={"Telefon"}
          />
        )}
        rules={getInputRules(true, 13)}
      />
      <StyledButton
        label={"Zapisz"}
        onClick={handleSubmit(onSubmit)}
        type="contained"
        buttonType="submit"
      />
    </form>
  );
};

export default SettingsForm;
