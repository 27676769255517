const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="3">
<h3><strong>Przykładowe spotkania projektowe przeprowadzane w czasie realizacji projektu z obszaru Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Rodzaj spotkania</strong></p>
</td>
<td>
<p><strong>Osoby uczestniczące</strong></p>
</td>
<td>
<p><strong>Cykliczność</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Statusowe (sprint review)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Cały zesp&oacute;ł</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Co 2 tyg.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Spotkania dotyczące dostępności danych&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt + CMO, DLA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Ad hoc&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Spotkania analityczne</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Cały zesp&oacute;ł</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Jednorazowo&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Daily&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zesp&oacute;ł programistyczny&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Codziennie&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Demo</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Cały zesp&oacute;ł</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Co miesiąc</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
