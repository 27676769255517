const html = `<h3 style="text-align: center;"><strong>Opis propozycji usług po wdrożeniu blockchain&nbsp; wraz z uzasadnieniem</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja</strong></p>
</td>
<td>
<p><strong>Uzasadnienie</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dodanie nowych funkcjonalności do aplikacji</span></p>
</td>
<td>
<p><span style="font-weight: 400;">W razie zidentyfikowania w organizacji nowych scenariuszy użycia aplikacji, może zrealizować prace polegające na dodaniu nowych funkcjonalności, rozszerzających możliwości stosowanego rozwiązania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zbudowanie nowej aplikacji wykorzystującej stworzoną bazę danych blockchain.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Stworzona baza danych blockchain może stanowić źr&oacute;dło danych dla kolejnych aplikacji. Możliwe jest wykorzystanie backendu stworzonej aplikacji zapewniającej komunikację z blockchain. Aplikacja posiada API, kt&oacute;re zintegrowane być może z frontendem kolejnej aplikacji, umożliwiającej realizację nowych funkcjonalności.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Utrzymanie i support</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Szczeg&oacute;łowa znajomość systemu i organizacji umożliwia nam zaoferowanie najwyższej jakości usługi utrzymaniowej oraz szybkie rozwiązywanie pojawiających się problem&oacute;w.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
