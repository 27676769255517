const html = `<p style="text-align: justify;">Kanban jest metodą zarządzania przepływem pracy, opartą na wizualizacji pracy (za pomocą tablic), maksymalizacji wydajności i ciągłym doskonaleniu. Metoda Kanban (z j. japońskiego oznacza &bdquo;billboard&rdquo;, &bdquo;szyld&rdquo;) używa tablic, na kt&oacute;rych poszczeg&oacute;lne zadania są podzielone na kolumny, co pomaga skoncentrować się na pracy będącej w toku, nie tracąc jednak z oczu perspektywy większych projekt&oacute;w.</p>
<p style="text-align: justify;">Tablica Kanban składa się z dw&oacute;ch gł&oacute;wnych element&oacute;w: kart, kt&oacute;re reprezentują zadania, i kolumn ilustrujących zadania na r&oacute;żnych etapach projektu. Kolumny zazwyczaj dzielą się na trzy sekcje &mdash; &bdquo;Do wykonania&rdquo;, &bdquo;W toku&rdquo; i &bdquo;Gotowe&rdquo;. Karty zadań, kt&oacute;re zwykle zawierają listy kontrolne, przydziały i etykiety, rozkłada się między te trzy kolumny. (W miarę postępu w realizacji zadania karty przesuwa się z lewej na prawą stronę tablicy, co ułatwia wizualizację ich stanu w przypływie pracy &mdash; od &bdquo;Do wykonania&rdquo; do &bdquo;Gotowe&rdquo;).</p>
<p style="text-align: justify;"><strong>Charakterystyczne role w zespole wykorzystującym kanban:</strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;<strong>Klient (Product Owner)</strong>&nbsp;-odpowiada na pytania, ustala priorytety, wsp&oacute;łpracuje przy testach akceptacyjnych.</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;<strong>Developerzy </strong>&ndash;&nbsp;programiści, kt&oacute;rzy wykonują poza programowaniem także estymaty dostarczenia zadań (tasks) i historyjek (User Story), piszą testy i zajmują się implementacją.</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;<strong>Trener (Coach) </strong>- nie jest wymagane. Taką rolę w zespole może pełnić osoba, kt&oacute;ra ma już doświadczenie w programowaniu ekstremalnym. Zadaniem trenera jest upewnienie się, że zesp&oacute;ł stosuje dobre praktyki, przemieniając je potem w nawyki i nie wracając do starych sposob&oacute;w tworzenia oprogramowania.</p>
<p style="text-align: justify;">● &nbsp; &nbsp;<strong>Tracker </strong>- osoba, kt&oacute;ra zajmuje się śledzeniem postępu zespołu oraz rozmawia z każdym developerem. Konsultacje z każdym członkiem zespołu pozwalają na identyfikację blocker&oacute;w, wąskich gardeł oraz znalezienie sposob&oacute;w na ich rozwiązanie. Do obowiązk&oacute;w Trackera należy r&oacute;wnież prezentowanie metryk, pokazujących w jaki spos&oacute;b radzi sobie zesp&oacute;ł, jaka jest jego prędkość (velocity), i wykres&oacute;w spalania (burndown charts). Często w celu prowadzenia takich metryk korzysta się z już gotowych rozwiązań, typu tablica scrumowa czy kanbanowa, kt&oacute;re automatycznie obliczają statystyki.</p>
<p style="text-align: justify;">&nbsp;</p>`;

export default html;
