import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainContext from "../../../../../context/MainContext";
import { LanguageType } from "../../../../../enums/LanguageType";
import CategoryMockType from "../../../../../interfaces/game/CategoryMockType";
import GameMockType from "../../../../../interfaces/game/GameMockType";
import GameType from "../../../../../interfaces/game/GameType";
import GroupType from "../../../../../interfaces/group/GroupType";
import GroupUser from "../../../../../interfaces/group/GroupUser";
import UserType from "../../../../../interfaces/user/UserType";
import GroupGameReport from "../../../../../pdfs/GroupGameReport";
import { getAverageSubtasksResults } from "../../../../../services/game.service";
import {
  getGroupUsers,
  sendGroupReportMail,
} from "../../../../../services/group.service";
import { AppDispatch, AppState } from "../../../../../store/store";
import StyledButton from "../../../../components/StyledButton";
import StyledFiltersComponent from "../../../../components/StyledFiltersComponent";
import StyledSelect from "../../../../components/StyledSelect";
import { UserRow } from "./UserRow";

interface Props {}

interface GroupGameReportPDFButtonProps {
  afterDownload?: () => void;
  afterSend?: () => void;
  gameData: GameMockType;
  selectedGroup: GroupType;
}

const GroupGameReportPDFButton = (props: GroupGameReportPDFButtonProps) => {
  const { afterDownload, afterSend, gameData, selectedGroup } = props;
  const dispatch: AppDispatch = useDispatch();

  const users: GroupUser[] = useSelector(
    (state: AppState) => state.groupReducer.users
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const [instance, updateInstance] = usePDF({
    document: (
      <GroupGameReport
        users={users}
        gameData={gameData}
        selectedGroup={selectedGroup}
      />
    ),
  });

  const blobToBase64 = function (
    blob: any,
    callback: (base64: string) => void
  ) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      callback(dataUrl as string);
    };
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    if (instance.url && !instance.loading && afterDownload) {
      downloadPDF();
    }
  }, [instance]);

  const sendPdf = (base64: string) => {
    const pureBase64 = base64.split(",")[1];
    dispatch(
      sendGroupReportMail({
        attachment: pureBase64,
        language: LanguageType.PL,
        to: user!.email,
      })
    );
    if (afterSend) afterSend();
  };

  useEffect(() => {
    if (instance.blob && !instance.loading && afterSend && user)
      blobToBase64(instance.blob, (base64: string) => sendPdf(base64));
  }, [instance, user]);

  const downloadPDF = async () => {
    var a = document.createElement("a");
    a.href = instance.url!;
    a.download = "Raport z wynikami grupy.pdf";
    a.click();
    afterDownload!();
  };

  return (
    <StyledButton type="contained" onClick={() => {}} label={"Ładowanie"} />
  );
};

const Scores = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState<GroupType | null>(null);
  const mainContext = useContext(MainContext);
  const { filtersSelectedGame } = mainContext;

  const groups: GroupType[] = useSelector(
    (state: AppState) => state.groupReducer.groups
  );

  const users: GroupUser[] = useSelector(
    (state: AppState) => state.groupReducer.users
  );

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const isFetchingUsers: boolean = useSelector(
    (state: AppState) => state.groupReducer.isFetchingUsers
  );

  const [gameData, setGameData] = useState<GameMockType | undefined>(undefined);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
  const [isSendingPDF, setIsSendingPDF] = useState<boolean>(false);

  useEffect(() => {
    if (groups.length > 0) {
      setSelectedGroup(groups[0]);
    }
  }, [groups]);

  useEffect(() => {
    if (selectedGroup && filtersSelectedGame) {
      dispatch(getGroupUsers(selectedGroup.id, filtersSelectedGame));
    }
  }, [selectedGroup, filtersSelectedGame, dispatch]);

  useEffect(() => {
    if (filtersSelectedGame && games) {
      const selectedGame = games.find(
        (game) => game.id === filtersSelectedGame
      );
      if (selectedGame) {
        const category = gamesData.find(
          (category) => category.key === selectedGame.categoryKey
        )!;
        const gameData = category.games.find(
          (item) => item.key === selectedGame.key
        )!;
        setGameData(gameData);
        if (gameData) {
          const subtasks = gameData.subtasks
            .map((subtask) => subtask.key)
            .flat();
          dispatch(getAverageSubtasksResults(subtasks));
        }
      }
    }
  }, [filtersSelectedGame]);

  const findSelectedGroup = (groupId: string) => {
    setSelectedGroup(groups.find((group) => group.id === groupId)!);
  };

  return (
    <div className="scoresTab" style={{ position: "relative" }}>
      <div className="scoresTab_filter">
        <div className="subtask_title">
          {"Wybierz grupę, której wyniki chcesz sprawdzić:"}
        </div>
        <StyledSelect
          onChange={findSelectedGroup}
          options={useMemo(
            () =>
              groups.map((group) => ({ text: group.name, value: group.id })),
            [groups]
          )}
          value={selectedGroup ? selectedGroup.id : ""}
        />
        <div className="subtask_title">
          {
            "Wybierz grę, do której chcesz wygenerować raporty z wynikami grupy:"
          }
        </div>
        {games && <StyledFiltersComponent withGames={true} games={games} />}
        {selectedGroup && gameData && (
          <div className="scoresTab_filter_buttons">
            {!isDownloadingPDF ? (
              <StyledButton
                type="contained"
                disabled={isFetchingUsers}
                onClick={() => setIsDownloadingPDF(true)}
                label={"Pobierz raport z wynikami grupy"}
              />
            ) : (
              <GroupGameReportPDFButton
                gameData={gameData}
                afterDownload={() => setIsDownloadingPDF(false)}
                selectedGroup={selectedGroup}
              />
            )}
            {!isSendingPDF ? (
              <StyledButton
                type="outlined"
                color="dark"
                onClick={() => setIsSendingPDF(true)}
                label={"Wyślij e-mail z raportem"}
              />
            ) : (
              <GroupGameReportPDFButton
                gameData={gameData}
                // afterDownload={() => setIsSendingPDF(false)}
                afterSend={() => setIsSendingPDF(false)}
                selectedGroup={selectedGroup}
              />
            )}
            {/* <StyledButton
              type="outlined"
              color="dark"
              onClick={() => {}}
              label={"Wyślij e-mail z raportem"}
            /> */}
          </div>
        )}
      </div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="headerTc main">
              <span>{"Imię"}</span>
            </TableCell>
            <TableCell scope="row" className="headerTc small">
              <span>{"Nazwisko"}</span>
            </TableCell>
            <TableCell scope="row" className="headerTc small">
              <span>{"E-mail"}</span>
            </TableCell>
            <TableCell scope="row" className="headerTc small">
              <span>{"Wynik"}</span>
            </TableCell>
            <TableCell scope="row" className="headerTc small">
              <span>{"Data uzyskania wyniku"}</span>
            </TableCell>
            <TableCell scope="row" className="headerTc small">
              <span>{"Akcje"}</span>
            </TableCell>
          </TableRow>
          {users.length > 0 &&
            users.map((user) => (
              <UserRow key={user.id} row={user} gameData={gameData} />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Scores;
