const html = `<h3 style="text-align: center;"><strong>Wykaz technologii stosowanych w projektach RPA wraz z opisem i przykładami</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Technologia&nbsp;</strong></p>
</td>
<td>
<p><strong>Opis technologii&nbsp;</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Narzędzie RPA</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia pisanie robot&oacute;w automatyzujących procesy biznesowe. Pisanie robot&oacute;w to programowanie tzw. wizualne, tj. tzw. low-code/no-code. Polega ono na budowaniu rozwiązania poprzez przeciąganie odpowiednich bloczk&oacute;w, kt&oacute;re reprezentują aktywności podejmowane przez ludzi w czasie realizacji procesu. Gł&oacute;wne aktywności (bloczki do wyboru) to: klikanie, otwieranie aplikacji, wpisywanie tekstu, czytanie tekstu, operacje na bazach danych, odczytywanie plik&oacute;w excel. Bloczki należy następnie ułożyć w odpowiedniej kolejności.&nbsp;</span></p>
<p><span style="font-weight: 400;">Dla każdego użytego bloczku należy wskazać: </span></p>
<p><span style="font-weight: 400;">1) miejsce w kt&oacute;rym ma być wykonana dana aktywność (np. dane miejsce w systemie, plik, dokumencie tekstowym, miejsce na ekranie komputera)</span></p>
<p><span style="font-weight: 400;">2) zmienną do kt&oacute;rej ma być przypisana wartość &bdquo;zdobyta&rdquo; w ramach podjętej przez robota aktywności&nbsp;</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">UIPath Studio, Blue Prism, Microsoft Power Automate, Automation Anywhere</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość na poziomie podstawowym: C#, VB.Net, Power Shell, JavaScript</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">umiejętność integracji z API&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość obsługi narzędzi RPA&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Maszyny wirtualne</strong><span style="font-weight: 400;"> (8 GB RAM, 4 rdzeniowy procesor)/ usługa chmurowa&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia dostęp do mocy obliczeniowej komputer&oacute;w, kt&oacute;re nie są fizycznie dostępne w siedzibie firmy. Maszyny wirtualne, tj. komputery na kt&oacute;rych będą uruchamiane roboty automatyzujące procesy, muszą być włączone w czasie realizacji procesu przez robota.</span></p>
<p><span style="font-weight: 400;">Korzystanie z maszyn wirtualnych jako usługi chmurowej jest bardzo wygodne, gł&oacute;wnie ze względu na przejęcie przez dostawcę usługi całości proces&oacute;w utrzymania i wsparcia. Dostawca gwarantuje dostęp do usługi 24h/7.&nbsp;</span></p>
<p><span style="font-weight: 400;">Usługa oferowana być może w pakiecie z narzędziem RPA, co umożliwi dostęp do całości narzędzi umożliwiających automatyzację w pakiecie.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> MS Azure, AWS, Google Cloud</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">podstawy zarządzania usługami chmurowymi</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Framework .NET</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia uruchomienie narzędzi RPA (np. UIPath) w środowisku system&oacute;w operacyjnych Microsoft.</span></p>
<p><span style="font-weight: 400;">Framework.NET to platforma programistyczna, kt&oacute;ra została opracowana przez Microsoft. Obejmuje ona środowisko uruchomieniowe (Common Language Runtime, CLR), a także biblioteki klas, kt&oacute;re dostarczają standardowej funkcjonalności dla aplikacji. Technologia ta nie jest związana z żadnym konkretnym językiem programowania, a programy mogą być pisane w jednym z wielu język&oacute;w, na przykład </span><a href="https://pl.wikipedia.org/wiki/C%2B%2B/CLI"><span style="font-weight: 400;">C++/CLI</span></a><span style="font-weight: 400;">, </span><a href="https://pl.wikipedia.org/wiki/C_Sharp"><span style="font-weight: 400;">C#</span></a><span style="font-weight: 400;">, </span><a href="https://pl.wikipedia.org/wiki/F_Sharp"><span style="font-weight: 400;">F#</span></a><span style="font-weight: 400;">, </span><a href="https://pl.wikipedia.org/wiki/J_Sharp"><span style="font-weight: 400;">J#</span></a><span style="font-weight: 400;">, </span><a href="https://pl.wikipedia.org/wiki/Embarcadero_Delphi"><span style="font-weight: 400;">Delphi</span></a><span style="font-weight: 400;"> 8 dla .NET, </span><a href="https://pl.wikipedia.org/wiki/Visual_Basic_.NET"><span style="font-weight: 400;">Visual Basic .NET</span></a><span style="font-weight: 400;">. Zadaniem platformy .NET Framework jest zarządzanie r&oacute;żnymi elementami systemu: kodem aplikacji, pamięcią i zabezpieczeniami.&nbsp;</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> nie dotyczy</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość obsługi systemu operacyjnego Microsoft</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">podstawowa znajomość język&oacute;w programowania dostarczanych przez Microsoft, np. </span><a href="https://pl.wikipedia.org/wiki/C_Sharp">C#</a>, <a href="https://pl.wikipedia.org/wiki/Visual_Basic_.NET">Visual Basic .NET</a>, <a href="https://pl.wikipedia.org/wiki/F_Sharp">F#</a></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Aplikacja do integracji maszyny wirtualnej z robotem wraz z orkiestratorem </strong><span style="font-weight: 400;">(integralny element narzędzia RPA)&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia nawiązanie komunikacji między narzędziem do automatyzacji a maszyną wirtualną. Pozwala to na uruchomienie robota na wskazanej maszynie. Orkiestrator pozwala na zarządzanie działaniem robot&oacute;w, tj. wskazanie o kt&oacute;rej dokładnie godzinie i jakiego dnia dane roboty mają być uruchamiane. Aplikacja jest elementem ekosystemu konkretnego dostawcy narzędzia do automatyzacji.&nbsp;</span></p>
<p><strong>Przykład:</strong><span style="font-weight: 400;"> UI Robot, Blueprism Client</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość obsługi danego narzędzia RPA</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
