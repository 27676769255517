import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  onClick?: () => void;
  label: string;
  buttonType?: "button" | "submit" | "reset" | undefined;
  type: "contained" | "outlined" | "text";
  color?: "dark" | "light" | "white";
  disabled?: boolean;
  icon?: string;
  tooltipText?: string;
  className?: string;
}

const StyledButton = (props: Props) => {
  const { t } = useTranslation();
  const {
    label,
    onClick,
    type,
    color = "light",
    disabled,
    icon,
    tooltipText,
    buttonType,
    className,
  } = props;

  const button = () => (
    <Button
      className={`styledButton ${color} ${className}`}
      variant={type}
      disabled={disabled}
      onClick={onClick}
      type={buttonType}
    >
      {icon && <img className="buttonIcon" src={icon} alt="button_icon" />}
      {label}
    </Button>
  );

  return (
    <>
      {tooltipText ? (
        <Tooltip title={tooltipText} arrow>
          {button()}
        </Tooltip>
      ) : (
        button()
      )}
    </>
  );
};

export default StyledButton;
