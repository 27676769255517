const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="3">
<h3 style="text-align: center;"><strong>Przykładowe spotkania projektowe przeprowadzane w czasie realizacji projektu z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Rodzaj spotkania</strong></p>
</td>
<td>
<p><strong>Osoby uczestniczące</strong></p>
</td>
<td>
<p><strong>Częstotliwość</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Spotkanie dotyczące technicznych aspekt&oacute;w landing page&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist +Lead designer +frontend developer + Brand manager klienta +Creative director klienta&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1x w 3 tyg&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Szkolenie zespołu klienta z nowych narzędzie&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist +zesp&oacute;ł klienta&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1x w 4 tyg.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Spotkanie celem prezentacji raportu 1 (17)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Digital Strategist + Social Media Manager + Brand manager klienta&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1x w 17tydz</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Spotkania statusowe&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Cały zesp&oacute;ł</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1x tydz.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
