import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: () => void;
  icon?: string;
  disabled?: boolean;
  color?: "primary" | "error";
  label: string;
}

const StyledTextButtonWithIcon = (props: Props) => {
  const { t } = useTranslation();
  const { onClick, icon, color, disabled, label } = props;

  return (
    <Button
      className={`styledTextButtonWithIcon ${color}`}
      variant={"text"}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <img className="buttonIcon" src={icon} alt="button_icon" />}
      {label}
    </Button>
  );
};

export default StyledTextButtonWithIcon;
