const html = `<h3 style="text-align: center;"><strong>CASE STUDY PROJEKTU NR 3&nbsp;</strong></h3>
<p><strong>Tytuł projektu: Automatyzacja procesu naliczanie składek na ubezpieczenie publiczne pracownik&oacute;w firmy z branży lotniczej</strong></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Czasochłonny proces sprawdzania i wprowadzania danych w zakresie wielkości należnych składek.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na zautomatyzowaniu procesu sprawdzania danych wrażliwych (dane osobowe) na stronie internetowej instytucji publicznej a następnie wprowadzania tych danych w odpowiednie miejsca w systemach wewnętrznych firmy. Robot wyszukiwał w firmowej bazie danych pracownik&oacute;w powyżej 50 roku życia, następnie dla każdego z pracownik&oacute;w pobierał numer ubezpieczenia, wpisywał ten numer na stronie internetowej instytucji publicznej by uruchomić wyszukiwarkę na stronie internetowej, co umożliwiało dostęp do danych w zakresie wielkości składek należnych za danego pracownika. W dalszej kolejności robot szedł do systemu firmowego naliczającego płace pracownikom, wklejał w odpowiednie miejsce wielkość należnych składek, dzięki czemu system nalicza odpowiednią wysokość wynagrodzenia.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Odciążenie pracownik&oacute;w w wykonywaniu powtarzalnych czynności, wyeliminowanie błęd&oacute;w podczas przepisywania danych,i przyspieszenie czasu realizacji procesu.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Baza danych pracownik&oacute;w, system naliczający wynagrodzenia, narzędzie RPA z wbudowaną technologią OCR (optical character recognition &ndash; technologia pozwalająca na odczyt danych ze skan&oacute;w i zdjęć).</span></p>
<p><strong>Czas realizacji projektu: </strong><span style="font-weight: 400;">7 tygodni</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">30 tys. zł (development) + 8 tys EUR rocznie (licencje tzw. unattended do automatycznego uruchamiania proces&oacute;w do maszynach wirtualnych, bez nadzoru człowieka, wedle wcześniej wskazanych informacji) + utrzymanie serwera (maszyny wirtualne) 300USD/msc + 3,3 tys. EUR rocznie (licencja deweloperska, umożliwiająca programowanie robot&oacute;w)&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">1 Developer RPA, 1 Architekt - przeprowadza analizę procesu z klientem i przygotowuje dokumentację biznesową.</span></p>`;

export default html;
