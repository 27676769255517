const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać podczas spotkania rozpoczynającego realizację projektu wdrożenia RPA</span></strong></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Celem projektu jest automatyzacja procesu wprowadzania danych z formularzy ankietowych do systemu ERP.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nasz zesp&oacute;ł nie jest duży. Składa się z trzech os&oacute;b. Architekta, kt&oacute;ry zajmie się analizą biznesową i przygotowaniem dokumentacji oraz dw&oacute;ch programist&oacute;w RPA.</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Widzicie tu poszczeg&oacute;lne kroki w projekcie wraz z zakładanymi przez nas terminami. Za chwilę om&oacute;wimy kto jest odpowiedzialny za poszczeg&oacute;lne zadania.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Macierz RACI, kt&oacute;rą za chwilę wsp&oacute;lnie przeanalizujemy, zawiera informacje w zakresie zakres&oacute;w odpowiedzialności poszczeg&oacute;lnych członk&oacute;w zespołu projektowego.</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do rozpoczęcia pracy potrzebujemy dostęp&oacute;w do system&oacute;w, nadanych kotom robotycznym. Tu mam listę system&oacute;w do kt&oacute;rych potrzebujemy kont robotycznych. Obejmują one systemy używane przy realizacji zautomatyzowanych proces&oacute;w biznesowych. Przekazuję ją Administratorowi IT.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zakładam grupę na komunikatorze firmowym - to narzędzie do bieżącej komunikacji. W narzędziu do zarządzania projektami dodałem nasz projekt, gdzie będą dodawane taski do wykonania. Zapewni to możliwość śledzenia postęp&oacute;w prac.</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Proponuję organizować spotkania codziennie, tj. daily scrum. Dynamika projektu jest duża. W związku z tym codzienne spotkania pozwolą nam realizować projekt zgodnie z założeniami. W razie potrzeby odbywać się będą spotkania inicjowane przez Architekta z ekspertami z Państwa firmy.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Projekt zaczynamy od mapowania procesu biznesowego, kt&oacute;ry ma zostać poddany automatyzacji. Architekt skontaktuje się z osobami, kt&oacute;re zostały wskazane jako eksperci ds. przebiegu zautomatyzowanego procesu biznesowego.&nbsp;&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
