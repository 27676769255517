const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 100%;" colspan="2">Stanowisko: Architekt BI/BD</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Imię i nazwisko</strong></td>
<td style="width: 50%;">Piotr Kamiński</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Doświadczenie</strong></td>
<td style="width: 50%;">
<p>2013-2015</p>
<p>Python Deweloper 2015 - 2020</p>
<p>Data Engineer 2020 &ndash; obecnie Architekt</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Technologie</strong><br /><strong>(*****)</strong></td>
<td style="width: 50%;">
<p>SQL****</p>
<p>Power BI****</p>
<p>Azure Data Factory****</p>
<p>Azure Analysis Services****</p>
<p>DAX***</p>
<p>Azure DevOps****</p>
<p>Python***</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Certyfikaty/kursy</strong></td>
<td style="width: 50%;">Azure Foundation, Azure Data Engineer</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Wiedza</strong></td>
<td style="width: 50%;">
<p>Teoria struktur danych</p>
<p>Modela danych i sposoby ich przetwarzania</p>
<p>Hurtownie danych</p>
<p>Procesy ETL</p>
<p>Procesy CI/CD</p>
<p>Znajomość reguł programowania KIS (keep it simple) i DRY (don&rsquo;t repeat yourself)</p>
<p>Znajomość zasad optymalizacji kosztowej i wydajnościowej usług chmurowych</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Wykształcenie</strong></td>
<td style="width: 50%;">Mgr informatyki</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Kompetencje miękkie</strong></td>
<td style="width: 50%;">Komunikatywność, umiejętność prezentacji pomysł&oacute;w/wyjaśnienia, prowadzenie dyskusji</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 100%;" colspan="2">Stanowisko: Programista BI</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Imię i nazwisko</strong></td>
<td style="width: 50%;">Olga Kamińska</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Doświadczenie</strong></td>
<td style="width: 50%;">
<p>2014-2021 &ndash; starsza księgowa</p>
<p>2021 &ndash; stażysta w firmie IT, działa BI</p>
<p>2022 &ndash; obecnie - BI developer</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Technologie</strong><br /><strong>(*****)</strong></td>
<td style="width: 50%;">
<p>SQL*</p>
<p>Power BI***</p>
<p>Azure Analysis Services**</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Certyfikaty/kursy</strong></td>
<td style="width: 50%;">Szkolenie Power BI</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Wiedza</strong></td>
<td style="width: 50%;">
<p>Zasady prezentacji danych</p>
<p>Dopasowanie grafu/ wykresu do rodzaju danych</p>
<p>Modele hurtowni danych (fakt, miara, wymiar)</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Wykształcenie</strong></td>
<td style="width: 50%;">Mgr ekonomii</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Kompetencje miękkie</strong></td>
<td style="width: 50%;">Poczucie estetyki</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 100%;" colspan="2">Stanowisko: Programista ETL</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Imię i nazwisko</strong></td>
<td style="width: 50%;">Eugeniusz Męczybuła</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Doświadczenie</strong></td>
<td style="width: 50%;">
<p>2021-2022 &ndash; stażysta w firmie IT</p>
<p>2022- obecnie - ETL developer</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Technologie</strong><br /><strong>(*****)</strong></td>
<td style="width: 50%;">
<p>T-SQL****</p>
<p>PL/SQL****</p>
<p>Azure Data Factory***</p>
<p>Git***</p>
<p>Azure SQL Sever****</p>
<p>SQL management studio***</p>
<p>Talend***</p>
<p>Informatica***</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Certyfikaty/kursy</strong></td>
<td style="width: 50%;">-</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Wiedza</strong></td>
<td style="width: 50%;">
<p>Modele hurtowni danych (fakt/miara, wymiar)</p>
<p>Mapowanie proces&oacute;w ETL</p>
</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Wykształcenie</strong></td>
<td style="width: 50%;">Lic. informatyki</td>
</tr>
<tr>
<td style="width: 50%;"><strong>Kompetencje miękkie</strong></td>
<td style="width: 50%;">Analityczne myślenie, inżynierski umysł</td>
</tr>
</tbody>
</table>`;

export default html;
