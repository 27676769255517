const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać klientowi po wdrożeniu technologii VR</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do działu IT wysłaliśmy pliku do zainstalowania oprogramowania. Wysłaliśmy także instrukcję instalacji, omawiającą po kolei wszystkie kroki, kt&oacute;re należy podjąć by skutecznie zainstalować oprogramowania na dedykowanym komputerze. Przy pobieraniu plik&oacute;w instalacyjnych proszę o zachowanie zasad bezpieczeństwa cyfrowego.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Bardzo ważne jest dbanie o higienę sprzętu VR (gogli i kontroler&oacute;w) w celu zapewnienia zdrowia i bezpieczeństwa użytkownik&oacute;w. Warto zapoznać się ze szczeg&oacute;łami instrukcji danego sprzętu, gdzie wskazane będą bezpieczne sposoby czyszczenia urządzeń.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Om&oacute;wię teraz w jaki spos&oacute;b poprawnie uruchomić i wyłączyć aplikację, jak wybierać maszynę do oglądania.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przed użyciem aplikacji VR należy zaznajomić użytkownik&oacute;w z zasadami działania VR, w tym kontroler&oacute;w. Warto om&oacute;wić spos&oacute;b poruszanie się w VR. Osoby, kt&oacute;re nigdy wcześniej nie miały kontaktu z VR potrzebują tego typu wprowadzenia i bezpiecznej przestrzeni na testowanie.&nbsp;</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Należy poinformować też o potencjalnych problemach, kt&oacute;re mogą się pojawić w efekcie korzystania z VR. Są to m.in. zawroty głowy, gorsze samopoczucie po pierwszym użytkowaniu, poczucie dyskomfortu).&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
