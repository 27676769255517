import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../services/user.service";
import { AppDispatch } from "../../../store/store";
import { getInputRules } from "../../../utils/utils";
import StyledButton from "../../components/StyledButton";
import StyledTextInput from "../../components/StyledTextInput";

interface FormValues {
  email: string;
  password: string;
}

const LoginScreenForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    dispatch(
      loginUser({
        grant_type: "password",
        username: data.email,
        password: data.password,
      })
    );
  };

  return (
    <form className="preAuthForm">
      <Controller
        name="email"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            onChange={onChange}
            error={errors.email && errors.email.message}
            label={"Email"}
          />
        )}
        rules={getInputRules(true, 60)}
      />
      <Controller
        name="password"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            type="password"
            onChange={onChange}
            error={errors.password && errors.password.message}
            label={"Hasło"}
          />
        )}
        rules={getInputRules(true, 20, 8)}
      />
      <StyledButton
        label={t("loginScreen.login")}
        onClick={handleSubmit(onSubmit)}
        type="contained"
        buttonType="submit"
      />
    </form>
  );
};

export default LoginScreenForm;
