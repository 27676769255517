const html = `<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.1pt; border: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="text-align: center; line-height: normal; border: none;"><strong><span style="font-size: 12.0pt; font-family: Roboto; color: black;">Zadania szczeg&oacute;łowe członk&oacute;w zespołu realizującego projekt z obszaru e-commerce</span></strong></p>
</td>
</tr>
<tr>
<td style="width: 233.65pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="312">
<p style="margin-bottom: 0cm; margin-top: 0cm; line-height: normal;"><strong><span style="font-size: 12.0pt; font-family: Roboto;">Stanowisko</span></strong></p>
</td>
<td style="width: 219.45pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="293">
<p style="margin-bottom: 0cm; margin-top: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Zadania</span></strong></p>
</td>
</tr>
<tr>
<td style="width: 233.65pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="312">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Analityk biznesowy</span></p>
</td>
<td style="width: 219.45pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="293">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Analiza wymagań </span></p>
</td>
</tr>
<tr>
<td style="width: 233.65pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="312">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Senior backend developer</span></p>
</td>
<td style="width: 219.45pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="293">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Instalacja i konfiguracja gotowego do wdrożenia modułu B2B zgodnie z wymaganiami klienta</span></p>
</td>
</tr>
<tr>
<td style="width: 233.65pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="312">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">QA Engineer</span></p>
</td>
<td style="width: 219.45pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="293">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Testy funkcjonalne i wydajnościowe </span></p>
</td>
</tr>
<tr>
<td style="width: 233.65pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="312">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Senior backend developer </span></p>
</td>
<td style="width: 219.45pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="293">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Uruchomienie modułu B2B na środowisku produkcyjnym klienta</span></p>
</td>
</tr>
<tr>
<td style="width: 233.65pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="312">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">PM</span></p>
</td>
<td style="width: 219.45pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="293">
<p style="margin-bottom: 0cm; margin-top: 0cm; text-align: justify; line-height: normal; border: none;"><span style="font-family: Roboto;">Szkolenie pracownik&oacute;w i partner&oacute;w z obsługi modułu B2B </span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
