const html = `<p style="text-align: justify;"><strong>Proces wyceny koszt&oacute;w realizacji prac IT z reguły obejmuje następujące etapy:</strong></p>
<p style="text-align: justify;">1.&nbsp; &nbsp;&nbsp;Rozpisanie specyfikacji, tj. tzw. user stores oraz zgrupowanie ich w epiki (zbiory user Stories, kt&oacute;re agregują je do jednej funkcjonalności). Po wykonanym tych prac powstać powinien product backlog zgodny z frameworkiem Scrum.</p>
<p style="text-align: justify;">2.&nbsp; &nbsp;&nbsp;Ustalenie priorytet&oacute;w, w tym harmonogramu prac.</p>
<p style="text-align: justify;">3.&nbsp; &nbsp;&nbsp;Analiza historyczna, tj. przegląd pod względem rezultatu, podobnych projekt&oacute;w, zrealizowanych iteracji, velocity (prędkość prac zespołu, tj. określenie ile zadań są w stanie zrealizować w określonym czasie) i wykres&oacute;w spalania (burn down charts, graficzne przedstawienie pozostałych do wykonania w projekcie prac względem czasu).</p>
<p style="text-align: justify;">4.&nbsp; &nbsp;&nbsp;Ustalenie wielkości velocity (potrzebnej do obliczenia czasu realizacji projektu) &ndash; określana jest na podstawie danych historycznych, dla szacowanej iteracji, kt&oacute;ra uwzględnia podstawowy zesp&oacute;ł wymagany do realizacji projektu.&nbsp;</p>
<p style="text-align: justify;">5.&nbsp; &nbsp;&nbsp;Estymowanie (szacowanie czasu realizacji) zadań (np. w arkuszu Google gdzie każdy estymujący ma swoją kolumnę) lub też za pomocą Scrum pokera. Zgodnie z szacowaniem Scrum wszyscy członkowie zespołu powinni się zgodzić co do estymacji danego user stories.&nbsp;</p>
<p style="text-align: justify;">6.&nbsp; &nbsp;&nbsp;Doliczenie bufor&oacute;w, premii. Bufory czasowe doliczane do określonych etap&oacute;w w projekcie. Zazwyczaj dolicza się do szacowanego czasu dodatkowo od 10% do 30%. Premie motywacyjne dla pracownik&oacute;w wypłacane będą jeśli dotrzymają oni termin&oacute;w realizacji prac, jeśli nie środki finansowe są przekazane na pokrycie kar umownych</p>
<p style="text-align: justify;">7.&nbsp; &nbsp;&nbsp;Wyliczenie czasu całkowitego projektu (podstawie szacowanego velocity iteracji oraz estymacji zadań).</p>
<p style="text-align: justify;">8.&nbsp;&nbsp; &nbsp;&nbsp;Wyliczenie &nbsp;koszt&oacute;w realizacji prac, poprzez pomnożenie czasu realizacji prac przez koszty pracy zespołu (liczba pracownik&oacute;w potrzebna do ralizacji danych prac, określona stawka pracownika za godzinę/dzień pracy).&nbsp;</p>
<p style="text-align: justify;">9.&nbsp;&nbsp; &nbsp;&nbsp;Doliczenie marży i ew. innych koszt&oacute;w realizacji prac, np. wynikających z oceny ryzyka (np. projekt posiada wysoki poziom kar i kr&oacute;tkie terminy realizacji), koszty pośrednie (np. wynajem, dojazdy, księgowości, koszty zarządu i obsługi prawnej, etc.). Marża może być jednoznacznie ustalona przez firmę (np. 20-30% wartości szacowanego projektu) lub może być wyliczana indywidualnie dla projektu. W przypadku indywidualnego wyliczenia marży pomocne jest podejście top-down.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Przykład:</strong></p>
<p style="text-align: justify;">Zał&oacute;żmy, że planowana iteracja trwa tydzień czasu, w ciągu iteracji trzyosobowy zesp&oacute;ł jest w stanie zrealizować zadania o łącznej wartości 46 punkt&oacute;w (story points). Łączna liczba zadań oszacowanych w projekcie to 318 punkt&oacute;w.</p>
<p style="text-align: justify;">&nbsp;.&nbsp; &nbsp;&nbsp;Dzieląc 318 przez 46 otrzymujemy liczbę tygodni jaka jest potrzeba do przeprowadzenia projektu. W tym przykładzie wyniesie 6,91.</p>
<p style="text-align: justify;">&nbsp;.&nbsp; &nbsp;&nbsp;Do wyliczonej liczby tygodni dodajemy &lsquo;bufor czasowy&rsquo; w wysokości 20% i otrzymujemy w zaokrągleniu wartość 8,3 tygodnia.</p>
<p style="text-align: justify;">&nbsp;.&nbsp; &nbsp;&nbsp;Uzyskany wynik mnożymy przez liczbę członk&oacute;w zespołu i ich wynagrodzenia w skali np. tygodnia.</p>
<p style="text-align: justify;">Dodajemy marże i ew. inne koszty</p>
<p style="text-align: justify;">&nbsp;. &nbsp; &nbsp;Końcowy wynik to całkowita wycena koszt&oacute;w realizacji projektu wraz z buforem czasowym</p>`;

export default html;
