const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada technologia IoT</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">platforma IoT umożliwiać ma dostęp do danych, nie wymagający umiejętności technicznych w zakresie skonfigurowania środowiska pracy na potrzeby analiz (sugerowane rozwiązanie typu no-code)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">stworzenie firmowego centrum doskonałości w obszarze chmury, IoT oraz Data Science (wymaga to&nbsp; przeprowadzenia serii szkoleń technicznych)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">obniżenie koszt&oacute;w realizacji proces&oacute;w produkcyjnych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">udostępnienie danych w formie umożliwiającej opracowanie algorytm&oacute;w automatyzujących procesy produkcyjne</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">przesyłanie danych generowanych przez czujniki w wykorzystaniem łącza internetowego</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">opracowanie disaster recovery plan (dokument zawierający opis proces&oacute;w, polityki i procedur związanych z wznowieniem lub utrzymywaniem infrastruktury&nbsp;IT krytycznej dla organizacji, po wystąpieniu&nbsp;awarii/katastrofy), zapewniającego szybkie odzyskanie danych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">dostęp do danych z sensor&oacute;w i czujnik&oacute;w w czasie rzeczywistym</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zapewnienie zgodności działania platformy IoT z prawem (compliance)</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
