import jwtDecode from "jwt-decode";
import { RoleType } from "../enums/RoleType";
import { DateTime } from "luxon";

interface TokenType {
  authorities: RoleType[];
  client_id: string;
  exp: number;
  jti: string;
  scope: string[];
  user_id: string;
  user_name: string;
}

const decodeToken = (token: string) => {
  let decoded: TokenType = jwtDecode(token);
  return decoded;
};

const isTokenExpired = (token: string) => {
  const expiresMillis = decodeToken(token).exp * 1000;
  const nowMillis = DateTime.now().toMillis();
  return expiresMillis < nowMillis;
};

function doesTokenExists(token: string) {
  if (token) return Promise.resolve(token);
  return Promise.reject(new Error("Brak tokenu"));
}

export { decodeToken, isTokenExpired, doesTokenExists };
