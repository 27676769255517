const html = `<h3 style="text-align: center;"><strong>SWOT&nbsp; zastosowania technologii IoT</strong></h3>
<p><strong>STRENGTHS (mocne strony)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">IoT jest podejściem umożliwiającym budowanie na jej bazie szeregu rozwiązań szczeg&oacute;łowych (enabler)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">wprowadzenie IoT w firmie umożliwia zunifikowanie podejścia do urządzeń, system&oacute;w i innych rozwiązań, kt&oacute;re do tej pory działały niezależnie od siebie&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">możliwość zarządzania systemami rozproszonymi (np. kilkoma fabrykami na kilku kontynentach)</span></li>
</ul>
<p><strong>WEAKNESSES (słabe strony)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wysoki pr&oacute;g wejścia (w obszarze stworzenia dedykowanej platformy IoT), gdyż do wdrożenia rozwiązania wymagana jest wiedza ekspercka zar&oacute;wno z obszaru IT i OT (operational technology, systemy automatyczne)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">konieczność tworzenia interdyscyplinarnych zespoł&oacute;w do stworzenia rozwiązania spełniającego wymagania branżowe klienta&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">brak generycznych rozwiązań, tj. takich kt&oacute;re można zastosować w każdym środowisku, co wymaga indywidualnego podejścia do każdego rozwiązania, w związku z czym jest czasochłonne&nbsp;</span></li>
</ul>
<p><strong>OPPORTUNITIES (szanse)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">platforma IoT umożliwia znalezienie zależności między danymi pozyskiwanymi ze dowolnych źr&oacute;deł danych&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">optymalizacja proces&oacute;w w firmie, w tym znacznie obniżenie koszt&oacute;w działalności</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">automatyzacja proces&oacute;w produkcyjnych i biznesowych&nbsp;</span></li>
</ul>
<p><strong>THREATS (zagrożenia)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wdrożenie IoT bez szczeg&oacute;łowej analizy opłacalności</span> <span style="font-weight: 400;">może okazać się droższe niż oczekiwane zyski&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zastosowanie nie przetestowanych szczeg&oacute;łowo algorytm&oacute;w sterowania urządzeniem powodować może, że urządzenia ulegną zniszczeniu</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">jeżeli por&oacute;wnujemy dane z wielu system&oacute;w, muszą zostać one znormalizowane, jeśli nie zostaną odpowiednio przetworzone to spowoduje to błędy obliczeniowe</span></li>
</ul>`;

export default html;
