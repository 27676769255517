const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Technologia</strong></p>
</td>
<td>
<p><strong>Opis technologii</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii&nbsp;</strong></p>
</td>
<td>
<p><strong>Czynniki wyboru/odrzucenia technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie do analityki ruchu na stronach internetowych&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia analizę aktywności użytkownik&oacute;w na stronie www, popularność poszczeg&oacute;lnych treści, pomiar konwersji [poziom zrealizowanych cel&oacute;w, np. sprzedażowych] i skuteczność zewnętrznych kampanii promocyjnych kierujących do stronę www. Zewnętrzne kampanie promocyjne to np. kampanie płatne w social media, kampania display [kampania banerowa, polegająca na wykupieniu powierzchni reklamowych np. na portalach internetowych], działania PR [public relations] linkujące do naszej strony internetowej.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Google Analytics 4,&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Obsługa i konfiguracja narzędzia Google Analytics 4. Poznanie gł&oacute;wnych funkcjonalności narzędzia wymaga przejścia kilkugodzinnego szkolenia.</span></p>
<p><span style="font-weight: 400;">Właściwa (optymalna) konfiguracja narzędzia wymaga doświadczenia i dodatkowych umiejętności technicznych.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Narzędzie posiada dominującą pozycję na rynku, jest bezpłatne i integruje się ze wszystkimi platformami internetowymi.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr>
<td><br />
<p><span style="font-weight: 400;">Narzędzie do obsługi kont biznesowych na portalach społecznościowych (Business Manager/Ads Manager)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia odpowiednią konfigurację kont biznesowych na platformach social media [aplikacje społecznościowe takie jak Facebook, Instagram, LinkedIn, TikTok, Twitter]. Pozwala na bezpieczne zarządzanie zasobami (treściami, kontami, dostępami pracownik&oacute;w), realizację działań płatnych, zaawansowaną analitykę i udostępnianie zarządzania kanałami społecznościowymi firmy partnerom takim jak agencje reklamowe (udostępnienie agencjom możliwości publikowania treści i tworzenia reklam np. na fanpage&rsquo;u firmy [strona, czyli wizyt&oacute;wka firmy na platformie social media].</span></p>
<br /><br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Meta Business Manager/ Menedżer Firmy Meta, TikTok Business Center etc.</span></p>
<br /><br /></td>
<td>
<p><span style="font-weight: 400;">Obsługa i konfiguracja narzędzi. Poznanie gł&oacute;wnych funkcjonalności narzędzia wymaga przejścia kilkunastogodzinnego szkolenia, kt&oacute;re umożliwi pracownikowi firmy zarządzanie narzędziami i analizę działań w SM. Na etapie konfiguracji rekomendowane jest wsparcie zewnętrznego konsultanta.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Każda platforma SM oferuje bezpłatne, dedykowane narzędzie do obsługi kont biznesowych. Dob&oacute;r platform zależny jest od przyjętej strategii marketingowej.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie do monitoringu treści umieszczanych przez użytkownik&oacute;w na forach, platformach internetowych, w social media</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia monitoring wszystkich publikacji o danej marce i związanych z nią tematach w mediach społecznościowych, serwisach informacyjnych, blogach, forach, stronach z recenzjami i innych mediach. Aplikacja, ze wskazaną częstotliwością,&nbsp; tworzy raporty zawierające wszystkie wzmianki o firmie pojawiające się w internecie, co m.in. pozwala firmie szybko reagować na pojawiające się treści.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Brand24, Sentione&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Przejście procesu onboardingu w aplikacji oraz podstawowa wiedza w zakresie strategii marketingowej i biznesowej firmy.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;"> Miesięczna opłata za korzystanie z narzędzia wynosi od kilkuset do kilku tysięcy złotych. Rekomendowane jest wykorzystanie narzędzia w okresie przygotowywania strategii działań. W dalszych etapach realizacji projektu jest opcjonalne, może być zlecone podmiotom zewnętrznym.&nbsp;</span></p>
</td>
</tr>
<tr>
<td><br />
<p><span style="font-weight: 400;">Narzędzie wspierające SEM [Search Engine Marketing, </span><span style="font-weight: 400;">og&oacute;ł działań promocyjnych w internecie mających na celu uzyskanie wysokich pozycji w wynikach wyszukiwania, na wybrane słowa i frazy kluczowe wpisywane przez użytkownik&oacute;w w zapytaniu do wyszukiwarki]</span><span style="font-weight: 400;"> w zakresie analizy i doboru sł&oacute;w kluczowych do pozycjonowania organicznego i kampanii płatnych</span></p>
</td>
<td><br />
<p><span style="font-weight: 400;">Umożliwia zbadanie jakich fraz używają użytkownicy internetu wyszukując firmę lub produkt w wyszukiwarce. Pozwala też oszacować koszty kampanii płatnych mających na celu poprawę wynik&oacute;w wyszukiwania. Narzędzie daje też możliwość zidentyfikowania problem&oacute;w klient&oacute;w związanych z tematyką naszego biznesu poprzez poznanie najczęściej zadawanych przez nich pytań. Użytkownik w narzędziu:</span></p>
<p><span style="font-weight: 400;">a) wpisuje np. adres strony www a narzędzie analizuje treści na stronie www i wskazuje jakich sł&oacute;w kluczowych należy użyć w treściach marketingowych mających na celu łatwiejsze znalezienie strony www w wynikach wyszukiwania&nbsp;</span></p>
<p><span style="font-weight: 400;">b) wpisuje słowo kluczowe i obszar geograficzny, a narzędzie wskazuje ile razy dana fraz jest wpisywana miesięcznie oraz z jakimi innymi słowami dana fraz jest wpisywana przez użytkownik&oacute;w, pozwala to lepiej zrozumieć użytkownik&oacute;w i zoptymalizować koszty marketingu w wyszukiwarce, poprzez lepsze dopasowanie reklam do wyszukanych potrzeb/problem&oacute;w potencjalnych klient&oacute;w&nbsp;</span></p>
<br />
<p><strong>Przykłady: </strong><span style="font-weight: 400;">planer sł&oacute;w kluczowych Google (bezpłatny),</span> <span style="font-weight: 400;">woorank.com, senuto.com, semstorm.com, semrush.com, MajesticSEO, answerthepublic.com, ahrefs.com, ,&nbsp; kwfinder.com, NEILPATEL.COM/UBERSUGGEST</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi narzędzia i podstawowa znajomość mechanizm&oacute;w SEM i SEO [Search Engine Optimization, optymalizacji organicznych, tj. bezpłatnych wynik&oacute;w wyszukiwania].&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Narzędzie od Google jest bezpłatne i pozwala realizować podstawowe cele w zakresie wyszukiwania sł&oacute;w kluczowych. Wyb&oacute;r dodatkowych narzędzi zależy od konkretnych preferencji i dostępnego budżetu projektu.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzia do zarządzania umieszczaniem i moderowaniem treści na wykorzystywanych platformach social mediach (publikacje i moderacja)&nbsp;</span></p>
<br /><br /></td>
<td>
<p><span style="font-weight: 400;">Umożliwia automatyczną publikację treści na wielu platformach social media oraz moderowanie (monitorowanie, odpowiadanie automatyczne, odpowiadanie z poziomu jednej aplikacji w wielu miejscach) komentarzy i wzmianek o naszej marce. Możliwe jest np. konfigurowanie narzędzia w ten spos&oacute;b by automatycznie publikować odpowiedzi na określone komentarze wpisywane pod postami umieszczonymi na SM. Ponadto dzięki narzędziu możliwe jest śledzenie wszystkich komentarzy, reakcji użytkownik&oacute;w na wielu platformach w jednym miejscu.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> napoleoncat.com , hootsuite.com</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi narzędzia i podstawowa znajomość mechanizm&oacute;w funkcjonowania medi&oacute;w społecznościowych.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Miesięczna opłata za korzystanie z narzędzia wynosi od kilkudziesięciu złotych.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie do przygotowania makiet rozwiązania&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia opracowanie makiety, czyli projektu funkcjonalnego nowo powstającej strony www lub aplikacji.</span></p>
<br />
<p><span style="font-weight: 400;"><strong>Przykłady:</strong> Adobe XD, Figma&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność obsługi programu. Znajomość zasad projektowania UX [user experience, czyli planowania doświadczenia użytkownika i łatwości korzystania z oprogramowania]</span></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Platforma e-commerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia stworzenie sklepu internetowego i kompleksowe zarządzanie sprzedażą internetową</span></p>
<br />
<p><span style="font-weight: 400;"><strong>Przykłady:</strong> Shoplo, Shopify, WooCommerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność instalacji, projektowania, konfiguracji i obsługi sklepu na wybranej platformie.</span></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">System zarządzania treścią (CMS) stron internetowych</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia szybkie budowanie stron internetowych&nbsp; i ich p&oacute;źniejszą edycję także przez osoby, kt&oacute;re nie posiadają kompetencji programistycznych.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> WordPress, Joomla</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pierwsza instalacji i konfiguracja systemu CMS powinna być wykonana przez programistę, kt&oacute;ry zadba o bezpieczeństwo wdrażanej strony, wykazuje się znajomością JS, HTML, CSS, PHP, zna zasady cyberbezpieczeństwa. Dalsza rozbudowa i edycja strony z wykorzystaniem CMS wymaga kilkugodzinnego szkolenia i opiera się na wykorzystaniu wizualnego konfiguratora.</span></p>
</td>
<td>
<p><span style="font-weight: 400;"> Najczęściej wybierana platforma CMS na świecie.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
