const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<h3 style="text-align: center;"><strong>Podstawy kampanii płatnych w mediach cyfrowych</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>CELE KAMPANII PŁATNYCH W MEDIACH SPOŁECZNOŚCIOWYCH</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">RUCH - celem jest sprawienie, że użytkownik kliknie w reklamę i przejdzie np. na stronę www</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">AKTYWNOŚĆ - celem jest zmotywowanie użytkownika do działania, np. do zostawienia reakcji pod postem z reklamą</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> ŚWIADOMOŚĆ - celem jest pokazanie reklamy jak największej ilości os&oacute;b, czyli zbudowanie jak największego zasięgu i rozpoznawalności</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>GŁ&Oacute;WNE WSKAŹNIKI W RAPORTACH SKUTECZNOŚCI KAMPANII PŁATNYCH</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">KOSZT ZA WYNIK - wydany budżet podzielony na liczbę zrealizowanych WYNIK&Oacute;W, czyli średni koszt zrealizowanego celu</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">CPC - cost per click, średni koszt jednego kliknięcia w reklamę</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">CTR - click through rate, jaki procent WYŚWIETLEŃ zakończył się kliknięciem w reklamę</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">CPM - cost per mile, koszt 1000 wyświetleń reklamy</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>EKOSYSTEMY REKLAMOWE</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Meta</span></p>
<p><span style="font-weight: 400;">Google&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Co warto sprawdzić oceniając skuteczność kampanii?</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">CPC, czyli koszt za kliknięcie</span></p>
<p><span style="font-weight: 400;">CPM, czyli koszt 1000 wyświetleń reklamy&nbsp;</span></p>
<p><span style="font-weight: 400;">Jakość wynik&oacute;w</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
