const html = `<p><span style="font-weight: 400;">S - Strengths (Mocne strony)</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Automatyzacja proces&oacute;w, co pozwala na szybszą obsługę klient&oacute;w i sprzedaż</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Możliwość personalizacji ofert i reklam, co zwiększa skuteczność marketingu</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Dostępność 24/7, co pozwala na sprzedaż w dowolnym momencie dnia i nocy</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Optymalizacja koszt&oacute;w i czasu związanych z obsługą zam&oacute;wień i dostawami</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Integracja z r&oacute;żnymi kanałami sprzedaży, np. social media, co zwiększa zasięg i efektywność sprzedaży</span></li>
</ul>
<p><span style="font-weight: 400;">W - Weaknesses (Słabe strony)</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Wymagają ciągłej aktualizacji i dostosowania do zmieniających się trend&oacute;w i wymagań klient&oacute;w</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Istnieje ryzyko bezpieczeństwa i oszustw związanych z danymi klient&oacute;w i transakcjami</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Brak bezpośredniego kontaktu z klientem, co może powodować trudności w rozwiązywaniu problem&oacute;w i budowaniu relacji z klientami</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Koszty związane z utrzymaniem i rozwijaniem platformy e-commerce</span></li>
</ul>
<p><span style="font-weight: 400;">O - Opportunities (Szanse)</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Rosnąca liczba konsument&oacute;w korzystających z zakup&oacute;w online, co zwiększa rynek e-commerce</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Możliwość sprzedaży w globalnym zasięgu, co zwiększa potencjał klient&oacute;w i sprzedaży</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Możliwość wykorzystania danych klient&oacute;w do tworzenia lepiej dopasowanych ofert i usług</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Nowe technologie, takie jak sztuczna inteligencja czy rozszerzona rzeczywistość, pozwalają na innowacyjne i efektywne sposoby sprzedaży i marketingu</span></li>
</ul>
<p><span style="font-weight: 400;">T - Threats (Zagrożenia)</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Konkurencja z innych platform e-commerce i sklep&oacute;w stacjonarnych</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Ryzyko naruszenia danych klient&oacute;w i transakcji przez cyberprzestępc&oacute;w</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Problemy związane z dostawami i logistyką, takie jak op&oacute;źnienia i błędy w dostawie</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zmieniające się regulacje i przepisy związane z e-commerce, kt&oacute;re mogą wpłynąć na koszty i spos&oacute;b prowadzenia biznesu.</span></li>
</ul>`;

export default html;
