const html = `<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.1pt; border: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="604">
<p style="text-align: center; border: none;"><strong><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto; color: black;">CV członk&oacute;w zespołu realizującego projekt z obszaru wdrożenia platformy e-commerce</span></strong></p>
</td>
</tr>
</tbody>
</table>
<p style="margin-bottom: 0cm; line-height: 115%; border: none;"><span style="font-size: 12.0pt; line-height: 115%; font-family: Roboto;">&nbsp;</span></p>
<p style="margin-bottom: 0cm; line-height: 115%; border: none;"><span style="font-size: 12.0pt; line-height: 115%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="591">
<tbody>
<tr style="height: 1.0pt;">
<td style="width: 443.25pt; border: solid black 1.0pt; background: #E6E6E6; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.0pt;" colspan="2" width="591">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko</span></strong><span style="font-family: Roboto; color: black;">: Business Analyst&nbsp; </span></p>
</td>
</tr>
<tr style="height: 1.1pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="437">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr style="height: 1.1pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="437">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2013 &ndash; 2015 &ndash; Project Manager</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2015 &ndash; 2020 &ndash; Junior Business Analyst</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2021 &ndash; obecnie &ndash; Business Analyst w branży e-commerce</span></p>
</td>
</tr>
<tr style="height: 4.1pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 4.1pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 4.1pt;" width="437">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Business Analyse***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Diagraming***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magento***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ERP**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">E-commerce**</span></p>
</td>
</tr>
<tr style="height: 24.25pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 24.25pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 24.25pt;" width="437">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ECBA (Entry Certificate in Business Analysis)</span></p>
</td>
</tr>
<tr style="height: 1.1pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="437">
<p style="margin-bottom: 0cm; text-indent: -18.0pt; line-height: normal;"><span style="font-family: Symbol;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">6 lat doświadczenia komercyjnego jako analityk biznesowy</span></p>
<p style="margin-bottom: 0cm; text-indent: -18.0pt; line-height: normal;"><span style="font-family: Symbol;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Znajomość branży IT oraz zasad działania ekosystem&oacute;w e-commerce </span></p>
<p style="margin-bottom: 0cm; text-indent: -18.0pt; line-height: normal;"><span style="font-family: Symbol;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Modelowanie proces&oacute;w biznesowych </span></p>
<p style="margin-bottom: 0cm; text-indent: -18.0pt; line-height: normal;"><span style="font-family: Symbol;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Tworzenie dokumentacji </span></p>
<p style="margin-bottom: 0cm; text-indent: -18.0pt; line-height: normal;"><span style="font-family: Symbol;">&middot;<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Znajomość metod i technik integracji informatycznych</span></p>
</td>
</tr>
<tr style="height: 1.1pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 1.1pt;" width="437">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Technikum ekonomiczne</span></p>
</td>
</tr>
<tr style="height: 4.85pt;">
<td style="width: 115.5pt; border: solid black 1.0pt; border-top: none; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 4.85pt;" width="154">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 327.75pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 5.0pt 5.0pt 5.0pt 5.0pt; height: 4.85pt;" width="437">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p style="margin-bottom: 0cm; line-height: 115%; border: none;"><span style="font-size: 12.0pt; line-height: 115%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> Senior backend deweloper</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2015 - 2017 &ndash; Junior PHP Developer</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2017 &ndash; 2019 &ndash; PHP Developer</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2019 &ndash; obecnie - Magento Developer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magento***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">PHP***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">MySQL***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Docker***<br />RabbitMQ***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Elasticsearch***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Kibana**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Xdebug***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">GraphQL**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Rest API***<br />E-commerce*</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Adobe Certified Expert - Magento Commerce Developer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Rozw&oacute;j i utrzymanie funkcjonalności dla sklep&oacute;w internetowych Magento 2/Adobe Commerce</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Tworzenie nowych rozwiązań eCommerce, od startu projektu do uruchomienia MVP i pomocy w utrzymaniu działającego sklepu</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Trzymanie jakości kodu</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Tworzenie dokumentacji</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Wsp&oacute;łpraca z Tech Leadem i klientami biznesowymi</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Inżynier Informatyki</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> UI Designer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2021 - 2021 &ndash; stażysta w firmie IT </span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2021 &ndash; obecnie UI Designer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Figma***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Adobe XD**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Miro*</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">E-commerce*</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Kurs UI Design 2022</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">doświadczenie w projektowaniu aplikacji internetowych i mobilnych</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">doświadczenie w projektowaniu produkt&oacute;w internetowych (aplikacji, narzędzi, e-commerce)</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">wysokie rozumienie procesu projektowania doświadczeń użytkownik&oacute;w</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">wysoka dbałość o szczeg&oacute;ły oraz wyważone proporcje między oryginalnością a efektywnością</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Inżynier Architekt Krajobrazu </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> UX designer </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2020 - 2021 &ndash; Junior UX Designer</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2021 &ndash; obecnie UX Designer w branży e-commerce</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Figma***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Adobe XD**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Miro***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">E-commerce**</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Google UX Design Professional Certificate</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">doświadczenie w pracy jako projektant UX </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">doświadczenie w projektowaniu UX sklep&oacute;w internetowych</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">projektowanie UX pod kątem użyteczności, metryk ilościowych i jakościowych</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Lic. informatyki </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> QA Engineer </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2019 - 2020 &ndash; stażysta w firmie IT </span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2020 &ndash; obecnie Junior QA Engineer </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Selenium**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">CI/CD*</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Jenkins*</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">AWS*</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ISTQB&reg; Certified Tester Foundation Level</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Dobra znajomość zagadnień związanych z testowaniem UI</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Znajomość narzędzi Selenium, Appium</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie z narzędziami CI/CD </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie w testach manualnych </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Umiejętności w pisaniu dokumentacji przypadk&oacute;w użytkownika</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Licencjat informatyki </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, prezentacja pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> Mid frontend developer&nbsp; </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2018 - 2020 &ndash; Junior Frontend Developer </span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2020 &ndash; obecnie Frontend Developer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">HTML***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">CSS***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">GIT**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">JS**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">VUE.JS**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">REACT*</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">NODE.JS*</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magento Front End Developer Certification</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie zawodowe jako Frontend Developer </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Dobra znajomość języka Javascript (ES6+)</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Dobra znajomość HTML, CSS (SASS)</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie z Vue.js</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie z web bundlerami (esbuild, webpack, rollup, itp.)</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie z systemami kontroli wersji (GIT)</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Lic. informatyki </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, prezentacja pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
