const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać klientowi po przekazaniu druku 3D</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Celem dzisiejszego spotkania jest przekazanie Państwu zaawansowanych informacji o druku 3D i możliwościach jego wykorzystania w procesach biznesowych.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szybkie prototypowanie odnosi się do&nbsp;procesu tworzenia lub składania fizycznych części modelu w optymalny spos&oacute;b. Przy projektowaniu modelu 3D zakłada się szybką procedurę produkcyjną &ndash; najczęściej druk 3D, ale też np. odlewanie pr&oacute;żniowe albo CNC. Idea szybkiego prototypowania leży w powtarzaniu procesu:</span></p>
<p style="line-height: 1.2; text-align: justify; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1) tworzenie modelu</span></p>
<p style="line-height: 1.2; text-align: justify; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2) testowanie modelu</span></p>
<p style="line-height: 1.2; text-align: justify; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3) poprawianie modelu</span></p>
<p style="line-height: 1.2; text-align: justify; background-color: #ffffff; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">iteracyjnie aż do osiągnięcia zadowalających rezultat&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 4.75pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oprogramowanie do projektowania wspomaganego komputerowo (CAD, CAD/CAM) umożliwia modelowanie obiekt&oacute;w i definiowanie ich konstrukcji np. cechami geometrycznymi, dynamicznymi lub materiałowymi. Przy jego użyciu tworzy się model cyfrowy - model 3D (najpopularniejszy format to .stl), kt&oacute;ry definiuje zbi&oacute;r punkt&oacute;w XYZ opisujących geometrię obiektu w spos&oacute;b jednoznaczny.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do druku 3D używa się najr&oacute;żniejszych materiał&oacute;w: tworzyw sztucznych (np. PLA, ABS, PET-G) i ich kompozyt&oacute;w (np. plastiku i drewna, plastiku i gipsu), żywic, gumy, metalu, past (np. ceramicznych), silikon&oacute;w, wosku itd. Ponadto można drukować z mniej typowych materiał&oacute;w: czekolady, piasku, betonu, papieru, cukru, szkła itd. Katalog materiał&oacute;w cały czas się rozszerza dzięki prowadzonym badaniom.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
