const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="border-left: solid #000000 0.5pt; border-right: solid #000000 0.5pt; border-bottom: solid #000000 1pt; border-top: solid #000000 0.5pt; vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać i uzyskać podczas spotkania rozpoczynającego realizację projektu z obszaru e-commerce</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Naszym celem jest wdrożenie platformy sprzedażowej, kt&oacute;ra umożliwi Waszej firmie rozpoczęcie sprzedaży detalicznej.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nasz zesp&oacute;ł składa się obecnie z Analityka biznesowego,&nbsp; Senior Developera, Mid developera, UX i UI designer&oacute;w oraz testera.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Widzicie tu orientacyjne kamienie milowe projektu wraz z szacowanymi okresami realizacji. Po etapie analizy będziemy w stanie potwierdzić realność zakładanych termin&oacute;w realizacji prac.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Macierz RACI, pomoże nam to w płynnej realizacji projektu.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do rozpoczęcia pracy potrzebujemy dostępu do systemu ERP, WMS oraz serwera. Proszę także o dostęp do API&nbsp; system&oacute;w ERP i WMS. Pozwoli nam to sprawdzić jakość API i ich możliwości zintegrowania ze sklepem internetowym. Na tym etapie nie wiemy jaka będzie najlepsza opcja integracji (API ERP, API silnika platformy e-commerce, gotowy moduł integratora). Po zapoznaniu się z API podejmiemy optymalną decyzję.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W systemie do zarządzania zadaniami stworzymy nowy projekt, dodamy wszystkich interesariuszy i przypiszemy im odpowiednie role. Prosimy o wskazanie osoby, kt&oacute;ra podejmować będzie wiążące decyzje w projekcie. Proszę o dane mailowe os&oacute;b, kt&oacute;re mają mieć dostęp do systemu do zarządzania zadaniami projektowymi.&nbsp;</span></p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wszystkie niezbędne i wiążące informacje będą na bieżąco umieszczane w systemie.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zaczynamy nasze prace od przeprowadzenia analizy biznesowej. Analityk w razie potrzeby będzie odzywał się do Was z prośbą o spotkanie. Po wypracowaniu szczeg&oacute;ł&oacute;w produktu programiści będą mogli zacząć pracę.&nbsp;</span></p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Pracować będziemy w 2 tygodniowych interwałach czasowych. Na początku każdego okresu spotykamy się na spotkaniu planującym. Po tygodniu na spotkaniu korygującym, celem uzupełnienia lub poprawy harmonogramu prac. Na koniec każdego okresu spotkajmy się 2 razy. Na pierwszym spotkaniu zaprezentujemy obecny statusu prac, a na drugim przeanalizujemy możliwości poprawy jakości procesu naszej wsp&oacute;łpracy. Dodatkowo codziennie mamy kr&oacute;tkie spotkanie synchronizujące, na kt&oacute;rym Wasza obecność nie jest wymagana.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dziękuję za spotkanie. Myślę, że po nim wszyscy wiemy co mamy robić. W najbliższym czasie zostanie wykonana analiza biznesowa, a w razie pytań, proszę o kontakt bezpośrednio ze mną.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
