const html = `<p><strong>Tytuł projektu: Diagnozowanie problem&oacute;w i prognozowanie potrzeb użytkownik&oacute;w biurowych drukarek wielkoformatowych za pośrednictwem medi&oacute;w społecznościowych.&nbsp;&nbsp;</strong></p>
<p><strong>Gł&oacute;wny problem klienta:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Klient, importer ploter&oacute;w i drukarek wielkoformatowych, musi podjąć decyzję o strategii importu produkt&oacute;w i akcesori&oacute;w na kolejne lata. Poza danymi sprzedażowymi i trendami chce wykorzystać pogłębione obserwacje zebrane dzięki social listening&rsquo;owi (monitorowaniu rozm&oacute;w w mediach społecznościowych).&nbsp;&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Klient zleca przeprowadzenie trwającej 3 miesiące aktywnej obserwacji polegającej na zbadaniu gł&oacute;wnych problem&oacute;w i potrzeb związanych z jego produktami, komunikowanych przez użytkownik&oacute;w medi&oacute;w społecznościowych. Obserwacja ma być połączona z pracą moderator&oacute;w włączających się w dyskusje online i odpowiednio animujących te rozmowy.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania:</strong></p>
<p><span style="font-weight: 400;">Dane z analiza jakościowej medi&oacute;w społecznościwych są elementem analizy rynku i pomagają zoptymalizować efekty decyzji biznesowych.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Platformy do social listening&rsquo;u, np. Brand24.&nbsp;</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">3 miesiące</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Project manager,</span> <span style="font-weight: 400;">Social Media Manager/Moderator, Starszy Analityk, Młodszy Analityk</span></p>
<p><strong>Budżet: </strong><span style="font-weight: 400;">26 000 zł netto</span></p>`;

export default html;
