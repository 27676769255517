import React from "react";
import { useTranslation } from "react-i18next";
import { gameSkills, gamesModes } from "../../../../constants/mocks";
import GameModeSpectificationRow from "../../gameScreen/GameModeSpectificationRow";
import CheckIcon from "../../../../assets/images/icons/check_primary_icon.svg";
import CheckWhiteIcon from "../../../../assets/images/icons/check_white_icon.svg";
import StyledTextInput from "../../../components/StyledTextInput";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import { RoleType } from "../../../../enums/RoleType";

interface Props {
  gameMode: string;
  setGameMode: (gameMode: string) => void;
  description: string;
  setDescription: (description: string) => void;
}

const GameModeSection = (props: Props) => {
  const { t } = useTranslation();
  const { gameMode, setGameMode, description, setDescription } = props;

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const renderModeSpecification = () => {
    const gameModeData = gamesModes.find((mode) => mode.key === gameMode)!;
    return gameModeData.modeSpecification.map((modeSpecificationText, idx) => (
      <GameModeSpectificationRow
        key={`${gameMode}_${idx}`}
        text={modeSpecificationText}
        icon={CheckWhiteIcon}
      />
    ));
  };

  return (
    <div className="gameModeSection">
      <div className="sectionsTitle">
        {
          "Wybierz tryb, z użyciem którego rozegrana ma zostać konfigurowana gra:"
        }
      </div>
      <div className="bubbles">
        {gamesModes.map((item) => (
          <div
            className={`bubble ${item.key === gameMode ? "selected" : ""}`}
            key={item.key}
            onClick={() => setGameMode(item.key)}
          >
            {gameMode === item.key && (
              <img
                className="selectedIcon"
                src={CheckIcon}
                alt="selectedIcon"
              />
            )}
            <div className="bubble_text">{item.name}</div>
          </div>
        ))}
      </div>
      <div className="modeSpecification">
        <div className="modeSpecification_title">{"Ten tryb obejmuje:"}</div>
        <div className="specificationRow">{renderModeSpecification()}</div>
      </div>
      <div className="skills">
        <span className="skills_title">
          {"Umiejętności miękkie rozwijane w wybranym trybie:"}
        </span>
        {gameSkills
          .find((item) => item.gameModeKey === gameMode)!
          .skills.map((skill, idx, array) => (
            <>
              <span className="primary">{skill}</span>
              {idx < array.length - 1 && (
                <span className="primary">{", "}</span>
              )}
            </>
          ))}
      </div>
      {role === RoleType.EDUCATOR && (
        <>
          <div className="sectionsTitle">{"Notatka:"}</div>
          <StyledTextInput
            value={description}
            onChange={setDescription}
            error={
              description.length > 256 ? "Maksymalna ilość znaków to 256" : ""
            }
            placeholder="Tutaj wpisz opis dodatkowy..."
          />
          <span className="info">
            {
              "Dzięki notatce opiszesz grę w taki sposób, by wiedzieć komu i kiedy chcesz wysłać link do skonfigurowanej gry."
            }
          </span>
        </>
      )}
    </div>
  );
};

export default GameModeSection;
