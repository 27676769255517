const html = `<h3 style="text-align: center;"><strong>Analiza SWOT technologii 3D&nbsp;</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Mocne strony</strong></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Niski koszt</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Wysoka wydajność procesu</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Wysoka jakość produktu&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Kastomizacja - model dostosowany do potrzeb klienta</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Zapotrzebowanie na modele 3D - pozytywny trend rynkowy</span></li>
</ol>
</td>
<td>
<p><strong>Słabe strony</strong></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Czas produkcji</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Problem z wielkością produktu&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">R&oacute;żnice w jakości druku - r&oacute;żna jakość przy zastosowaniu r&oacute;żnych drukarek</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Ograniczony wyb&oacute;r materiał&oacute;w</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Proces uczenia się programowania</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Możliwość zastosowania post-processingu&nbsp;</span></li>
</ol>
</td>
</tr>
<tr>
<td>
<p><strong>Szanse&nbsp;&nbsp;</strong></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Materiał z recyklingu</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Rozw&oacute;j potencjału materiału</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Inteligentne materiały</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Wynalazek sprzętowy</span></li>
</ol>
</td>
<td>
<p><strong> Zagrożenia&nbsp;</strong></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Kompatybilność maszyn</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Wpływ na środowisko</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Prawa własności intelektualnej</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Konkurencja rynkowa</span></li>
</ol>
</td>
</tr>
</tbody>
</table>`;

export default html;
