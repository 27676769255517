import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  href: string;
  label: string;
  className?: string;
}

const LinkTextButton = (props: Props) => {
  const { t } = useTranslation();
  const { label, href, className } = props;

  return (
    <a className={`linkTextButton ${className}`} href={href}>
      {label}
    </a>
  );
};

export default LinkTextButton;
