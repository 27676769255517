const html = `<h3 style="text-align: center;"><strong>Oferta na usługę z obszaru marketingu cyfrowego&nbsp;</strong></h3>
<h3 style="text-align: center;">&nbsp;</h3>
<p><strong>Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki realizacji usługi marketingu cyfrowego</strong> <strong>z wykorzystaniem medi&oacute;w społecznościowych:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">brak świadomości istnienia produktu wśr&oacute;d rodzic&oacute;w dzieci w wieku przedszkolnym</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">brak stworzonej komunikacji i strategii marki celowanej do nowej grupy docelowej</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">sklep internetowy, kt&oacute;ry nie posiada stron lądowania dostosowanych do nowej grupy docelowej</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">brak doświadczenia i wiedzy o dobrych praktykach w prowadzeniu kampanii w mediach społecznościowych</span></li>
</ul>
<p>&nbsp;</p>
<p><strong>Koncepcja realizacji usługi:</strong></p>
<p><span style="font-weight: 400;">Zakres oferowanych działań zakłada analizę zastanej sytuacji, opracowanie strategii marki, koncepcji kreatywnej i ramowego planu działań. Prace przygotowawczo-koncepcyjne potrwają około 3 miesięcy, następnie przez 6 miesięcy prowadzona będzie komunikacja promowana kampaniami płatnymi w mediach społecznościowych. Wykonawca będzie skoncentrowany na opracowaniu zestawu dobrych praktyk dotyczących procesu sprzedażowego i konfiguracji kampanii płatnych,&nbsp; kt&oacute;re wraz z rekomendacjami narzędzi - pozwolą Klientowi na dalsze kontynuowanie działań marketingowych po zakończeniu projektu.</span></p>
<p>&nbsp;</p>
<p><strong>Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">utworzenie kompletnego procesu sprzedaży on-line dedykowanego rodzicom dzieci w wieku przedszkolnym w przeciągu 6 miesięcy&nbsp;</span></li>
</ul>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">opracowanie raportu dobrych praktyk do kampanii płatnych (w celu ich p&oacute;źniejszej kontynuacji)</span></li>
</ul>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">przeprowadzenie analizy konkurencji</span></li>
</ul>
<p>&nbsp;</p>
<p><strong>Technologie proponowane do wykorzystania w projekcie:</strong></p>
<p><span style="font-weight: 400;">Wiodące rynkowe technologie, narzędzia i platformy wspierające działania z obszaru marketingu cyfrowego.&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">utworzenie kompletnego procesu sprzedaży on-line dedykowanego rodzicom dzieci w wieku przedszkolnym w przeciągu 6 miesięcy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">opracowanie raportu dobrych praktyk do kampanii płatnych (w celu ich p&oacute;źniejszej kontynuacji)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">osiągnięcie sprzedaży w kanale e-commerce na poziomie 4 tysięcy sztuk w ciągu 9 miesięcy od rozpoczęcia wsp&oacute;łpracy</span></li>
</ul>`;

export default html;
