import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import EmptyInfoComponent from "./EmptyInfoComponent";
import GroupType from "../../../../../interfaces/group/GroupType";
import { AppDispatch, AppState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import GroupUser from "../../../../../interfaces/group/GroupUser";
import StyledTextButtonWithIcon from "../../../../components/StyledTextButtonWithIcon";
import CopyIcon from "../../../../../assets/images/icons/copy_icon.svg";
import DeleteIcon from "../../../../../assets/images/icons/delete_icon_red.svg";
import MainContext from "../../../../../context/MainContext";
import { deleteGroup, deleteUser } from "../../../../../services/group.service";
import { setInfoMessage } from "../../../../../reducers/message.reducer";

interface RowType {
  row: GroupType;
  open: boolean;
  onRowOpen: (value: string) => void;
}

export const GroupRow = (props: RowType) => {
  const { t } = useTranslation();
  const { row, open, onRowOpen } = props;
  const dispatch: AppDispatch = useDispatch();
  const mainContext = useContext(MainContext);
  const { showDefaultModal } = mainContext;

  const users: GroupUser[] = useSelector(
    (state: AppState) => state.groupReducer.users
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  const isFetchingUsers: boolean = useSelector(
    (state: AppState) => state.groupReducer.isFetchingUsers
  );

  const handleGroupDelete = () => {
    showDefaultModal({
      type: "warning",
      header: "Usuń grupę",
      text: `Czy na pewno chcesz usunąć grupę ${row.name}?`,
      submitLabel: "Potwierdź",
      onSubmit: () =>
        dispatch(deleteGroup({ id: row.id, name: row.name, userId })),
    });
  };

  const handleUserDelete = (user: GroupUser) => {
    showDefaultModal({
      type: "warning",
      header: "Usuń użytkownika",
      text: `Czy na pewno chcesz usunąć użytkownika ${user.firstName} ${user.lastName}?`,
      submitLabel: "Potwierdź",
      onSubmit: () => dispatch(deleteUser(user.id)),
    });
  };

  const renderUsersRows = () => {
    return users.map((user: GroupUser) => (
      <TableRow className="userRow">
        <TableCell>{user.firstName}</TableCell>
        <TableCell>{user.lastName}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell align="right">
          <StyledTextButtonWithIcon
            label="Usuń użytkownika"
            onClick={() => handleUserDelete(user)}
            color="error"
            icon={DeleteIcon}
          />
        </TableCell>
      </TableRow>
    ));
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/register/${row.id}`
    );
    dispatch(setInfoMessage(t("global.copyGroupLinkSuccess")));
  };

  return (
    <React.Fragment>
      <TableRow className="groupRow">
        <TableCell component="th" scope="row">
          <div className="groupHeader">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onRowOpen(open ? "" : row.id)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row.name}
          </div>
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="buttons">
            <StyledTextButtonWithIcon
              label="Skopiuj link"
              onClick={copyLink}
              icon={CopyIcon}
            />
            <StyledTextButtonWithIcon
              label="Usuń grupę"
              onClick={handleGroupDelete}
              color="error"
              icon={DeleteIcon}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isFetchingUsers ? (
              "Loading" //TODO
            ) : users.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>{renderUsersRows()}</TableBody>
              </Table>
            ) : (
              <EmptyInfoComponent text={t("organizationPanelScreen.noUsers")} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
