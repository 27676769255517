const html = `<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację usługi wdrożenia aplikacji chmurowych w organizacji</span></strong></p>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki wdrożeniu aplikacji chmurowych wspierających prace biurowe i księgowe:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">utrudniona komunikacja między wsp&oacute;łpracownikami oraz pracownikami i klientami ze względu na wiele wykorzystywanych narzędzi komunikacji, w tym często z użyciem prywatnych kont</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">brak możliwości wsp&oacute;łdzielenia dokument&oacute;w</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">utrata dokument&oacute;w firmowych (np. projekt&oacute;w) w efekcie awarii sprzętu&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">aktualizacja wykorzystywanego obecnie systemu księgowego (rozwiązanie zainstalowane na lokalnym komputerze) powodują, że raz na kilka tygodni pracę działu księgowego muszą dopasowywać się do harmonogramu serwisu&nbsp;&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">praca zdalna doprowadziła do powiększania repozytori&oacute;w dokument&oacute;w i chaosu w dostępie do danych</span></li>
</ul>
<p><br /><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Efektem projektu będzie wdrożone narzędzie cyfrowe tzw. modern workplace, uruchomione w chmurze (infrastruktura dostawcy), dzięki czemu firma nie musi posiadać infrastruktury serwerowej w biurze, a dostęp do dokument&oacute;w i system&oacute;w możliwy jest z każdego miejsca w każdym czasie (z wykorzystaniem łącza internetowego). We wdrożeniu wykorzystany zostanie gotowy pakiet aplikacji biurowych wiodącego dostawcy chmury, z kt&oacute;rym wsp&oacute;łpracujemy w ramach partnerstwa, jako integrator. Płatność za usługi, tzw. aplikacje SaaS, odbywać się będzie w modelu subskrypcyjnym.</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Usługa obejmie też wdrożenie programu księgowo-finansowego w formule aplikacji SaaS, dostępnego poprzez przeglądarkę internetową oraz migrację danych do aplikacji SaaS (poczta internetowa i system księgowy). Zapewniamy skonfigurowanie architektury platformy zgodnie z wymaganiami firmy, w tym utworzenie kont, nadawanie dostęp&oacute;w, utworzenie katalog&oacute;w plik&oacute;w.</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły pakietu aplikacji chmurowych typu modern workplace :&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">prowadzenie telekonferencji (dźwięk, obraz, prezentowanie treści) oraz obsługę firmowej poczty internetowej, zapewniając dużą przestrzeń w skrzynce oraz zwiększony poziom bezpieczeństwa (antyspam z usługą migracji danych z obecnej poczty)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">dostęp do kalendarza firmowego umożliwiającego śledzenie dostępności pracownik&oacute;w, umawianie/organizowanie spotkań</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">gromadzenie i wsp&oacute;łdzielenie plik&oacute;w, wysłanie linku do dokumentu, automatyczne tworzenie kopii zapasowych</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające wdrażanie rozwiązań bazujących na chmurze obliczeniowej.&nbsp;</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zwiększenie efektywności pracy, poprzez możliwość wsp&oacute;lnej pracy na plikach, pracy online na dokumentach kilku os&oacute;b jednocześnie</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">łatwy i efektywny kontakt online z klientami</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">obniżenie pracochłonności i zwiększenie dostępności wewnętrznych system&oacute;w (narzędzia finansowo-księgowe) &ndash; wyeliminowanie konieczności czasochłonnego aktualizowania system&oacute;w przez serwisanta&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1 miesiąc od podpisania umowy pracownicy mają mieć możliwość korzystania z podstawowej wersji rozwiązania&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">elastyczny model płatność za korzystanie z rozwiązania w przyszłości, tj. tylko za wykorzystanie zasob&oacute;w chmurowych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">w przypadku wzrostu liczby pracownik&oacute;w, zapotrzebowania na zasoby IT, rozwiązanie może być w prosty spos&oacute;b skalowane&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">całość rozwiązania utrzymywana (serwis, aktualizacje) przez dostawcę aplikacji chmurowych </span></li>
</ul>`;

export default html;
