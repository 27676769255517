import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { productsData } from "../../../constants/mocks";
import { RoleType } from "../../../enums/RoleType";
import ProductDataType from "../../../interfaces/payment/ProductDataType";
import ProductType from "../../../interfaces/payment/ProductType";
import { AppState } from "../../../store/store";
import {
  getCurrencyString,
  getPeriodLabel,
} from "../../../utils/payment.utils";
import StyledButton from "../../components/StyledButton";

interface Props {
  product: ProductType;
  onClick: () => void;
  disabled?: boolean;
}

const ProductComponent = (props: Props) => {
  const { t } = useTranslation();
  const { product, onClick, disabled } = props;

  const [productData, setProductData] = useState<ProductDataType | null>(null);

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  useEffect(() => {
    if (product) {
      const productData = productsData.find(
        (item) => item.code === product.code
      );
      if (productData) setProductData(productData);
    }
  }, [product]);

  return (
    <>
      {productData && product && (
        <Tooltip
          title={
            disabled
              ? "Przedłużenie pakietu na wybrany okres będzie możliwe po wygaśnięciu aktywnego dostępu."
              : "Niezależnie od momentu opłacenia pakietu, zostanie on przedłużony na wybrany okres, licząc od pierwszego dnia następującego po upływie aktywnego pakietu."
          }
        >
          <div className={`productComponent ${disabled ? "disabled" : ""}`}>
            <span className="label">
              {role === RoleType.EDUCATOR
                ? productData!.label
                : getPeriodLabel(product)}
            </span>

            <div className="priceWrapper">
              <span>{product.price.value}</span>
              <span>{getCurrencyString(product.price.currency)}</span>
            </div>

            {role === RoleType.EDUCATOR &&
              productData &&
              productData.benefits && (
                <>
                  <span>{t("buyProductScreen.packageIncludes")}</span>
                  <div className="benefitsWrapper">
                    {productData.benefits.map((benefit: any) => (
                      <span className="benefit" key={`product_${benefit}`}>
                        {benefit}
                      </span>
                    ))}
                  </div>
                </>
              )}
            <StyledButton
              label={t("buyProductScreen.select")}
              onClick={onClick}
              disabled={disabled}
              type="contained"
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default ProductComponent;
