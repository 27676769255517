const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada rozwiązanie wykorzystujące blockchain (prywatny)</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zapewnienie integralności danych wykluczającej możliwość wprowadzenie do nich zmian w nieautoryzowany spos&oacute;b.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Możliwość dostępu do informacji (danych z bazy blockchain) przy użyciu aplikacji mobilnej i czytnika kod&oacute;w QR.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Możliwość dodawania informacji do bazy danych przez wszystkich partner&oacute;w w sieci w zunifikowany (jednakowy) spos&oacute;b, poprzez wykorzystanie dostępnych opcji wyboru.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zapewnienie dostępu do danych w czasie rzeczywistym (wyeliminowanie op&oacute;źnienia w dostępie do informacji wszystkich uczestnik&oacute;w sieci).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Pełna kontrola nad strukturą sieci (liczbą, rodzajem uczestnik&oacute;w danej sieci). Gwarancja, że udostępniane dane nie trafią do sieci publicznej (zapewnia to wykorzystanie protokołu blockchain typu permissioned/prywatny).&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Możliwość programowania smart kontrakt&oacute;w w popularnych językach, np. Golang, Java, Javascript (zapewnia to wykorzystanie protokołu blockchain - Hyperledger Fabric).&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Automatyczna replikacja danych, tj. powielania danych pomiędzy r&oacute;żnymi serwerami baz danych (cecha naturalna/natywna technologii blockchian).&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zapewnienie transparentności&nbsp; wprowadzonych danych (dostęp do jednego źr&oacute;dła prawdy i danych historycznych wszystkim podmiot&oacute;w w sieci)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">HA (High Availability), SLA&nbsp; na poziomie 99,999%, kontrola przepływu danych wrażliwych, w tym zapewnienie by żadna z organizacji w sieci nie miała dostępu do danych wrażliwych innej organizacji (przechowywanie danych wrażliwych w spos&oacute;b zaszyfrowany).&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dodawanie w bazie blockchain informacji o modyfikacji dokument&oacute;w źr&oacute;dłowych, do kt&oacute;rych prowadzi HASH (skr&oacute;t), umieszczony w bazie blockchain. Oznacza to brak możliwości wykasowania śladu o tym, że dokument źr&oacute;dłowy, zamieszczony pierwotnie w bazie, został zmodyfikowany.&nbsp;</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
