import React from "react";
import { useTranslation } from "react-i18next";
import GameMockType from "../../../interfaces/game/GameMockType";
import Accordion from "./Accordion";
import { accordionHeader, getNoUserGameLine } from "./GameScreen";
import StyledButton from "../../components/StyledButton";
import UserGameScoreType from "../../../interfaces/game/UserGameScoreType";
import coursesMap from "../../../mocks/courses/courses_mapping";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import { saveAs } from "file-saver";
import { RoleType } from "../../../enums/RoleType";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/store";

interface Props {
  gameData: GameMockType | null;
  gameScore: UserGameScoreType | null;
  categoryData: CategoryMockType | undefined;
}

const CoursesAccordion = (props: Props) => {
  const { t } = useTranslation();
  const { categoryData, gameScore } = props;

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const renderCourses = () => {
    const categoryCourses = categoryData
      ? coursesMap[categoryData!.key]
      : undefined;
    if ((!categoryCourses || !gameScore) && role === RoleType.USER)
      return getNoUserGameLine(
        "Zagraj w grę, aby odblokować mini kursy wiedzy o nowych technologiach."
      );
    return categoryCourses ? (
      categoryCourses.map((course) => {
        const courseData = categoryData!.courses.find(
          (item) => item.key === course.key
        );

        const saveFile = () => {
          saveAs(course.article, `${course.key}.pdf`);
        };

        return (
          <div className="courseRow" key={courseData?.key}>
            <span className="title">
              {courseData ? courseData.label : "Brak nazwy kursu"}
            </span>
            <StyledButton type="text" label="Pobierz kurs" onClick={saveFile} />
          </div>
        );
      })
    ) : (
      <div className="noDataToshow">{"Brak kursów do wyświetlenia"}</div>
    );
  };

  return (
    <div className="coursesAccordion">
      <Accordion
        HeaderComponent={accordionHeader("Kursy")}
        BodyComponent={<div className="bodyWrapper">{renderCourses()}</div>}
      />
    </div>
  );
};

export default CoursesAccordion;
