import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import StyledButton from "../../components/StyledButton";
import SelectBubbleComponent from "../../components/SelectBubbleComponent";
import ProductType from "../../../interfaces/payment/ProductType";
import { buyProductFormType, productsData } from "../../../constants/mocks";
import ProductDataType from "../../../interfaces/payment/ProductDataType";
import {
  getCurrencyString,
  getPeriodLabel,
} from "../../../utils/payment.utils";
import OrderForm from "./OrderForm";
import { RoleType } from "../../../enums/RoleType";
import UserType from "../../../interfaces/user/UserType";
import { updateUser } from "../../../services/user.service";
import { createSubscription } from "../../../services/payment.service";
import { createSubscriptionFulfilled } from "../../../reducers/payment.reducer";

interface Props {
  product: ProductType;
  extendAccessOption: string;
}

const OrderComponent = (props: Props) => {
  const { t } = useTranslation();
  const { product, extendAccessOption } = props;
  const dispatch: AppDispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useFormContext();

  const [productData, setProductData] = useState<ProductDataType | null>(null);

  const [selectedFormType, setSelectedFormType] = useState<string>(
    buyProductFormType[0].value
  );

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const redirectUrl: string = useSelector(
    (state: AppState) => state.paymentReducer.redirectUrl
  );

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
      user.phoneNumber && setValue("phone", user.phoneNumber);
      user.street && setValue("address", user.street);
      user.nip && setValue("nip", user.nip);
      user.postalCode && setValue("zipCode", user.postalCode);
      user.country && setValue("country", user.country);
      user.city && setValue("city", user.city);
      user.organizationName && setValue("companyName", user.organizationName);
      user.firstName && setValue("firstName", user.firstName);
      user.lastName && setValue("lastName", user.lastName);
    }
  }, [user]);

  useEffect(() => {
    if (product) {
      const productData = productsData.find(
        (item) => item.code === product.code
      );
      if (productData) setProductData(productData);
    }
  }, [product]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
      dispatch(createSubscriptionFulfilled(""));
    }
  }, [redirectUrl]);

  const onFormTypeChange = (newValue: string) => {
    setSelectedFormType(newValue);
    setError("nip", {});
  };

  const formComponent = () => (
    <>
      <div className="componentHeader">{t("buyProductScreen.yourOrder")}</div>
      <SelectBubbleComponent
        selected={selectedFormType}
        onChange={onFormTypeChange}
        bubbles={buyProductFormType}
      />
      <div className="sectionHeader">{"Dane zamawiającego:"}</div>
      <OrderForm formType={selectedFormType} />
    </>
  );

  const onSubmit = (data: any) => {
    dispatch(
      updateUser({
        ...user,
        phoneNumber: data.phone,
        firstName: data.firstName,
        lastName: data.lastName,
        city: data.city,
        postalCode: data.zipCode,
        organizationName: data.companyName,
        street: data.address,
        nip: data.nip,
        country: data.country,
        id: user!.id,
      })
    );
    dispatch(
      createSubscription(
        user!.id,
        product.id,
        user!.role === RoleType.EDUCATOR
          ? extendAccessOption === buyProductFormType[1].value
          : false
      )
    );
  };

  const summaryComponent = () => (
    <>
      <span className="label">
        {role === RoleType.EDUCATOR
          ? `${productData!.label} - ${getPeriodLabel(product)}`
          : getPeriodLabel(product)}
      </span>
      {role === RoleType.EDUCATOR && productData && productData.benefits && (
        <div className="benefitsWrapper">
          <span>{t("buyProductScreen.packageIncludes")}</span>
          {productData.benefits.map((benefit: any) => (
            <span className="benefit" key={`sumary_${benefit}`}>
              {benefit}
            </span>
          ))}
        </div>
      )}
      <div className="priceWrapper">
        <span className="price_small">{t("buyProductScreen.toPay")}</span>
        <span className="price_big">{product.price.value}</span>
        <span className="price_big">
          {getCurrencyString(product.price.currency)}
        </span>
      </div>
      <StyledButton
        label={t("buyProductScreen.goToPayment")}
        onClick={handleSubmit((data) => onSubmit(data))}
        type="contained"
        buttonType="submit"
      />
      <span className="smallSpan">
        {t("buyProductScreen.przelewy24HandlesTransactions")}
      </span>
    </>
  );

  return (
    <div className="orderComponent">
      <div className="leftSide">{formComponent()}</div>
      <div className="rightSide">{productData && summaryComponent()}</div>
    </div>
  );
};

export default OrderComponent;
