import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StyledFiltersComponent from "../../../../components/StyledFiltersComponent";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { AppDispatch, AppState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import GameType from "../../../../../interfaces/game/GameType";
import GameRow from "./GameRow";
import StyledButton from "../../../../components/StyledButton";
import MainContext from "../../../../../context/MainContext";
import { GameTypeType } from "../../../../../enums/GameTypeType";
import { useNavigate } from "react-router-dom";

interface Props {}

const Games = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const { filtersGameMode, filtersGameType } = mainContext;
  const [openGame, setOpenGame] = useState<string>("");
  const [availableGames, setAvailableGames] = useState<GameType[]>([]);

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.gameReducer.isFetching
  );

  useEffect(() => {
    if (games) {
      const availableGames = games.filter((game) =>
        filtersGameType === GameTypeType.CONFIGURED
          ? !!game.userCreatorId && game.gameMode === filtersGameMode
          : !game.userCreatorId
      );
      setAvailableGames(availableGames);
    }
  }, [filtersGameMode, filtersGameType, games]);

  return (
    <div className="gamesTab">
      <div className="redirectToConfigurator">
        <span>
          {
            "Jeśli chcesz skonfigurować grę  spełniające Twoje cele edukacyjne, kliknij"
          }
        </span>
        <StyledButton
          type="text"
          label="KONFIGURATOR GRY"
          onClick={() => navigate("/configurator")}
        />
      </div>
      <div className="subtask_title">
        {
          "Wybierz rodzaj i grę do której linki chcesz skopiować i przesłać wybranej grupie użytkowników:"
        }
      </div>
      <StyledFiltersComponent />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="headerTc main">
              <span>{"Nazwa grupy"}</span>
            </TableCell>
            {filtersGameType === GameTypeType.CONFIGURED && (
              <TableCell className="headerTc small">
                <span>{"Notatka"}</span>
              </TableCell>
            )}
            <TableCell scope="row" className="headerTc small">
              <span>{"Akcje"}</span>
            </TableCell>
          </TableRow>
          {availableGames.length > 0 &&
            availableGames.map((game: GameType) => (
              <GameRow
                open={openGame === game.id}
                setOpen={setOpenGame}
                game={game}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Games;
