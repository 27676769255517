const html = `<h3 style="text-align: center;"><strong>Modelowy harmonogram projektu w zakresie przygotowanie wydruk&oacute;w 3D</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)&nbsp;</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Osoby odpowiedzialne/realizujące(zesp&oacute;ł)</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Ustalenie szczeg&oacute;łowych wymagań klienta</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Grafik, Technolog, Handlowiec</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Notatka służbowa.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie/weryfikacja wizualizacji przedmiotu do wydruku&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Grafik, Technolog, Handlowiec</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wizualizacja umożliwiająca stworzenie modelu 3D.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie wizualizacji modelu 3D w formie zapisu cyfrowego, wydruk i przekazanie pierwszego wydruku 3D do oceny.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Grafik, Technolog, Handlowiec&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Plik cyfrowy z modelem, format .stl .Wydruk 3D . Notatka służbowa</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Naniesienie zmian w modelu 3D, wydruk kolejnej wersji.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Grafik, Technolog, Handlowiec</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Plik cyfrowy z modelem, format .stl .Wydruk 3D . Notatka służbowa</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Akceptacja wydruku i zakończenie projektu.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">6</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Grafik, Technolog, Handlowiec</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Plik cyfrowy z modelem, format .stl .Wydruk 3D . Protok&oacute;ł odbioru prac.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
