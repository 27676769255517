const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<h3><strong>Przebieg szkolenia po wdrożeniu w organizacji rozwiązania z obszaru Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Wykaz temat&oacute;w do om&oacute;wienia</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Budowa platformy Big Data.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zasady bezpieczeństwa przy korzystaniu z platformy Big Data.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przykładowe user story realizowane z wykorzystaniem platformy Big Data.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Etapy działań mających na celu rozwiązanie problemu biznesowego przy wykorzystaniu platformy Big Data.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dziękuję i zapraszam do dalszej wsp&oacute;łpracy.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
