const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="2" style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Przykłady opis&oacute;w wybranych element&oacute;w strategii marketingowej </strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Grupa docelowa</strong></p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <ul>
                        <li>
                            <p>Pracownicy HR i marketingu sklep&oacute;w wielkopowierzchniowych zatrudniających powyżej 100 pracownik&oacute;w. Miasta powyżej 50k mieszkańc&oacute;w.</p>
                        </li>
                        <li>
                            <p>Pracownicy HR i marketingu magazyn&oacute;w/firm oferujących powierzchnie magazynowe, zatrudniających powyżej 100 pracownik&oacute;w. Firmy zlokalizowane na obrzeżach miast i poza ich granicami.</p>
                        </li>
                    </ul><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Cel i wskaźnik</strong></p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <ul>
                        <li>
                            <p>Zwiększenie bazy zarejestrowanych klient&oacute;w z potencjałem do upsellingu. Wskaźnik: kompletne instalacje aplikacji. Cel: 7000 instalacji w ciągu 6 miesięcy</p>
                        </li>
                        <li>
                            <p>Pozyskanie stałych źr&oacute;deł przychod&oacute;w projektu. Wskaźnik: płatne, roczne subskrypcje. Cel: 1000 płatnych kont w trakcie 6 miesięcy</p>
                        </li>
                    </ul><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);"><br>
                    <p><strong>Unique Selling Proposition</strong></p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <ul>
                        <li>
                            <p>Aplikacja do onboardingu zaprojektowana przez najlepszych projektant&oacute;w. Design nagrodzony na Festiwalu Artystycznym w Genui.</p>
                        </li>
                        <li>
                            <p>Aplikacja mobilna do wdrożenia, szkolenia i monitoringu pracownik&oacute;w na stanowisku sprzedawca-kasjer. Wsp&oacute;łpracujemy z 40 producentami stanowisk kasowych.</p>
                        </li>
                    </ul><br>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
