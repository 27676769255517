const html = `<p><span style="font-weight: 400;">Kr&oacute;tki opis projektu: Celem projektu jest stworzenie sklepu internetowego dla producenta biżuterii, kt&oacute;ry pozwoli na sprzedaż produkt&oacute;w online oraz umożliwi klientom personalizację wybranych produkt&oacute;w. W sklepie będzie można znaleźć zar&oacute;wno gotowe produkty, jak i zam&oacute;wić indywidualne projekty.</span></p>
<p><span style="font-weight: 400;">Zakres projektu:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Analiza wymagań biznesowych i technicznych.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Projektowanie interfejsu użytkownika i architektury systemu.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Implementacja i testowanie sklepu internetowego.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Integracja z płatnościami online i systemami logistycznymi.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Wdrożenie i szkolenie użytkownik&oacute;w.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Dalsze utrzymanie i rozw&oacute;j sklepu.</span></li>
</ol>
<p><span style="font-weight: 400;">Korzyści osiągnięte z wdrożenia:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zwiększenie sprzedaży poprzez dostępność produkt&oacute;w w internecie.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zwiększenie zasięgu klient&oacute;w i dotarcie do nowych grup odbiorc&oacute;w.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Personalizacja produkt&oacute;w pozwoli na oferowanie bardziej atrakcyjnych i unikalnych produkt&oacute;w dla klient&oacute;w.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Automatyzacja procesu sprzedaży i obsługi klienta.</span></li>
</ol>
<p><span style="font-weight: 400;">Narzędzia, systemy, technologie wykorzystane w projekcie:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">CMS Wordpress z wtyczką WooCommerce do stworzenia sklepu internetowego.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Payment Gateway do integracji płatności online.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">System logistyczny do obsługi proces&oacute;w magazynowych i dostaw.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Narzędzia do projektowania graficznego i interfejsu użytkownika.</span></li>
</ol>
<p><span style="font-weight: 400;">Czas trwania projektu: 6 miesięcy</span></p>
<p><span style="font-weight: 400;">Zesp&oacute;ł wraz z ilością etatu każdego z członk&oacute;w:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Projektant UX/UI (0.5 FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Programista Wordpress/WooCommerce (1 FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tester (0.5 FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Project Manager (0.25 FTE)</span></li>
</ol>
<p><span style="font-weight: 400;">Budżet projektu: 100 000 zł.</span></p>`;

export default html;
