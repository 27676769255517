const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 49.6018%;"><strong>Stanowisko</strong></td>
<td style="width: 49.6018%;"><strong>Zakres obowiązk&oacute;w</strong></td>
</tr>
<tr>
<td style="width: 49.6018%;">Architekt BI/BD</td>
<td style="width: 49.6018%;">
<p>&bull; przygotowanie projektu całości rozwiązania, struktury, model hurtowni, nazwy tabel</p>
<p>&bull; określenie sposobu pobierania i przepływu danych, struktury kodu</p>
<p>&bull; dbałość o wydajność system</p>
<p>&bull; pomoc w problemach technicznych zespołowi</p>
<p>&bull; opracowanie High Level Design/Solution Concept oraz Low Level Design/szczeg&oacute;łowy projekt rozwiązania</p>
<p>&bull; mentoring developer&oacute;w</p>
<p>&bull; code review</p>
<p>&bull; wycena koszt&oacute;w prac, w tym wynikających ze zmiany wymagań w trakcie trwania projektu</p>
</td>
</tr>
<tr>
<td style="width: 49.6018%;">BI frontend developer</td>
<td style="width: 49.6018%;">
<p>&bull; przygotowanie końcowych raport&oacute;w aplikacji, zgodnie z wymaganiami</p>
<p>&bull; nadanie dostęp&oacute;w do raport&oacute;w</p>
<p>&bull; skonfigurowanie kont w aplikacji BI</p>
<p>&bull; pomoc w budowie modelu analitycznego</p>
</td>
</tr>
<tr>
<td style="width: 49.6018%;">ETL developer/backend BI</td>
<td style="width: 49.6018%;">
<p>&bull; przygotowanie założeń struktury procesu ETL</p>
<p>&bull; pobranie danych ze źr&oacute;dła danych przygotowanie struktur hurtowni danych zgodnie z wytycznymi</p>
<p>&bull; przygotowanie procesu załadowanie danych do hurtowni</p>
<p>&bull; budowa modelu analitycznego</p>
<p>&bull; zadania w obszarze DevOps</p>
</td>
</tr>
</tbody>
</table>`;

export default html;
