const html = `<p style="text-align: justify;"><strong>SWOT</strong>&nbsp;to&nbsp;technika służąca do porządkowania i analizy informacji o danym zagadnieniu czy rozwiązaniu.&nbsp;Cztery elementy analizy obejmują:&nbsp;<em>Strengths </em>&ndash; silne strony,&nbsp;<em>Weaknesses </em>&ndash; słabe strony,&nbsp;<em>Opportunities </em>&ndash; szanse, okazje i&nbsp;<em>Threats </em>&ndash; zagrożenia&nbsp;</p>
<p style="text-align: justify;"><strong>Silne strony technologii BD/BI</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">zapewnia szybki dostęp do nowej wiedzy opartej na danych (dostępnej np. w postaci raport&oacute;w analitycznych)</p>
    </li>
    <li>
        <p style="text-align: justify;">pozwala na osiągnięcie oszczędność czasu pracy analityk&oacute;w oraz innych os&oacute;b odpowiedzialnych za tworzenie raport&oacute;w analitycznych &nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">zapewnia przejrzysty dostęp do danych z jednego, pewnego, zawsze aktualnego źr&oacute;dła &nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Słabe strony technologii BD/BI</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">niska jakość danych w systemach źr&oacute;dłowych i nieefektywne modele przetwarzania danych wpływają na niską jakość wniosk&oacute;w z przeprowadzonych analiz&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">relatywnie wysokie koszty, kt&oacute;re należy ponosić w celu utrzymania i rozwoju systemu do gromadzenia i przetwarzania danych&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">czachochłonne prace programistyczne związane z dostosowaniem system&oacute;w analitycznych do zmian wymagań biznesowych, proces&oacute;w produkcyjnych, regulacji, wymog&oacute;w analitycznych&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Szanse związane z zastosowaniem technologii BD/BI</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">systematyczny przyrost wolumenu danych gromadzonych w firmie pozwalić może na podnoszenie jakości wniosk&oacute;w z analiz i automatyzowanie czasochłonnych proces&oacute;w&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">tendencje rynkowe w zakresie spadku koszt&oacute;w infrastruktury chmurowej wpływają na wzrost efektywność technologii</p>
    </li>
    <li>
        <p style="text-align: justify;">gromadzone dane i opracowane techniki ich przetwarzania stanowić mogą nowe źr&oacute;dło przychodu firmy, co pozwali na dywersyfikację działalności i wzrost stabilności firmy&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">uzyskanie dodatkowych korzyści biznesowych z wykorzystania gromadzonych danych&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Zagrożenia związane z zastosowaniem technologii BD/BI</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">cyberataki, naruszenia RODO &nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">osiągnięcie niewielkich korzyści z gromadzenia i analizy danych wynikające z błęd&oacute;w przy wdrażaniu rozwiązania, w tym w implementacji modeli analitycznych, modeli ML/AI</p>
    </li>
    <li>
        <p style="text-align: justify;">brak efektywnego wykorzystania wniosk&oacute;w z danych w działaniach biznesowych i produkcyjnych ze względu na niską otwartość pracownik&oacute;w firmy na nowe technologie (problemy kultury organizacyjnej)</p>
    </li>
</ul>`;

export default html;
