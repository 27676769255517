import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MessageReducer from "../interfaces/message/MessageReducer";

const initialState: MessageReducer = {
  errorMessage: "",
  infoMessage: "",
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setInfoMessage: (state, action: PayloadAction<string>) => {
      state.infoMessage = action.payload;
    },
    clearTnfoMessage: (state) => {
      state.infoMessage = "";
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = "";
    },
  },
});

export const {
  setErrorMessage,
  setInfoMessage,
  clearErrorMessage,
  clearTnfoMessage,
} = messageSlice.actions;

export default messageSlice.reducer;
