import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GameMockType from "../../../interfaces/game/GameMockType";
import Accordion from "./Accordion";
import { accordionHeader, getNoUserGameLine } from "./GameScreen";
import StyledSelect from "../../components/StyledSelect";
import ArrowDownIcon from "../../../assets/images/icons/arrow_down_icon.svg";
import reports from "../../../mocks/reports/report_mapping";
import UserGameScoreType from "../../../interfaces/game/UserGameScoreType";
import { RoleType } from "../../../enums/RoleType";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/store";

interface Props {
  gameData: GameMockType | null;
  gameScore: UserGameScoreType | null;
}

const ReportsAccordion = (props: Props) => {
  const { t } = useTranslation();
  const { gameData, gameScore } = props;

  const [selectedSubtask, setSelectedSubtask] = useState<string>("");
  const [reportHeaders, setReportHeaders] = useState<
    { key: string; label: string }[]
  >([]);

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const subtasksOptions = useMemo(() => {
    if (gameData) {
      if (role === RoleType.EDUCATOR)
        return gameData.subtasks.map((item) => ({
          value: item.key,
          text: item.title,
        }));
      else if (gameScore)
        return gameData.subtasks
          .filter((subtask) =>
            gameScore.subtasks.some(
              (item) => item.subtaskKey === subtask.key && item.doneTime >= 0
            )
          )
          .map((item) => ({
            value: item.key,
            text: item.title,
          }));
    }
    return [];
  }, [gameData]);

  const reportsToShow: { report: string; key: string }[] | null =
    useMemo(() => {
      if (
        gameData &&
        selectedSubtask &&
        reports[gameData.key] &&
        reports[gameData.key][selectedSubtask]
      ) {
        return reports[gameData.key][selectedSubtask];
      } else return null;
    }, [selectedSubtask, gameData]);

  useEffect(() => {
    setSelectedSubtask(
      subtasksOptions.length > 0 ? subtasksOptions[0].value : ""
    );
  }, [subtasksOptions]);

  useEffect(() => {
    if (selectedSubtask) {
      const subtaskData = gameData!.subtasks.find(
        (item) => item.key === selectedSubtask
      );
      let headers: { key: string; label: string }[] = [];
      if (subtaskData) {
        headers = headers.concat(subtaskData.skills, subtaskData.knowledge);
      }
      setReportHeaders(headers);
    }
  }, [selectedSubtask, gameData]);

  const accordionSmallHeader = (header: string) => (
    <div className="accordionSmallHeader">
      <img className="downIcon" src={ArrowDownIcon} alt="downIcon" />
      <span>{header}</span>
    </div>
  );

  const renderReports = () => {
    return (
      <div className="nestedAccordions">
        {(gameScore && gameScore.subtasks.length > 0) ||
        role === RoleType.EDUCATOR ? (
          <>
            <span className="styledSelectHeader">
              {
                "Wybierz tematykę raportów, a następnie kliknij w strzałkę przy wybranym raporcie, by wyświetlić jego zawartość:"
              }
            </span>
            <StyledSelect
              onChange={setSelectedSubtask}
              options={subtasksOptions}
              value={selectedSubtask}
            />
            {reportsToShow
              ? reportsToShow.map((report) => {
                  const template = { __html: report.report };
                  const header = reportHeaders.find(
                    (item) => item.key === report.key
                  );

                  return (
                    <Accordion
                      key={report.key}
                      HeaderComponent={accordionSmallHeader(
                        header ? header.label : "Brak nagłówka"
                      )}
                      BodyComponent={
                        <div className="baseBodyWrapper" id={report.key}>
                          {/* @ts-ignore */}
                          <div dangerouslySetInnerHTML={template} />
                        </div>
                      }
                    />
                  );
                })
              : selectedSubtask && (
                  <div className="noDataToshow">
                    {"Brak raportów do wyświetlenia"}
                  </div>
                )}
          </>
        ) : (
          getNoUserGameLine("Zagraj w grę, aby odblokować raporty wiedzy.")
        )}
      </div>
    );
  };

  return (
    <div className="reportsAccordion">
      <Accordion
        HeaderComponent={accordionHeader(
          "Raporty wiedzy uzupełniane podczas gry"
        )}
        BodyComponent={<div className="bodyWrapper">{renderReports()}</div>}
      />
    </div>
  );
};

export default ReportsAccordion;
