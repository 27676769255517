const html = `<div align="left">
    <table style="border-collapse: collapse; border: 2px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Propozycja usługi/rozwiązania technologicznego</strong></p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Uzasadnienie </strong></p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Optymalizacja koszt&oacute;w usług chmurowych&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Szczeg&oacute;łowa analiza sposobu przetwarzania, czasu przetwarzania, sposobu wykorzystywania i rodzaj&oacute;w danych gromadzonych na platformie, może skutkować wnioskami, kt&oacute;re pozwolą na zmianę lub optymalizację modelu korzystania z usług chmurowych, kt&oacute;re stanowią gł&oacute;wny koszt utrzymania platformy. &nbsp;&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Wdrożenie modeli ML/AI celem automatyzacji i optymalizacji proces&oacute;w biznesowych&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Analiza proces&oacute;w biznesowych i wiedza o rodzajach gromadzonych danych )zdobyta podczas wdrażania platformy BD) pozwalają na zaproponowanie wdrożenia automatyzacji proces&oacute;w, a tym samym osiągnięcie znacznych korzyści finansowych, jakościowych i &nbsp;wizerunkowach.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Utrzymanie platformy Big Data, rozwiązywanie bieżących problem&oacute;w (maintenance)&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Rozw&oacute;j i utrzymania platformy stanowią klucz do czerpania wartości biznesowyj z danych. Dzięki dużemu doświadczeniu i znajomości potrzeb firmy jesteśmy w stanie być skutecznym partnerem w tym procesie.&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
