const html = `<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.1pt; border: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="604">
<p style="text-align: center; border: none;"><strong><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto; color: black;">CV członk&oacute;w zespołu realizującego projekt z obszaru wdrożenia platformy e-commerce</span></strong></p>
</td>
</tr>
</tbody>
</table>
<p><span style="font-size: 12.0pt; line-height: 107%; font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> Business Analyst&nbsp; </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2013 &ndash; 2015 - Project Manager</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2015 &ndash; 2020 - Junior Business Analyst</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2021 &ndash; obecnie - Business Analyst w branży e-commerce</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Business Analyse***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Diagraming***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magento***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ERP**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">E-commerce**</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ECBA (Entry Certificate in Business Analysis)</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">6 lat doświadczenia komercyjnego jako analityk biznesowy</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Znajomość branży IT oraz zasad działania ekosystem&oacute;w e-commerce </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Modelowanie proces&oacute;w biznesowych </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Tworzenie dokumentacji </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Znajomość metod i technik integracji informatycznych</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Technikum ekonomiczne</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> Project Manager </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2013 &ndash; 2017 &ndash; Account Manager</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2017 &ndash; obecnie &ndash; Project Manager w branży e-commerce</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">E-commerce***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Project Management***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magento***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Prestashop**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ERP**</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">AGILE PM Foundation</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">5 lat doświadczenia komercyjnego jako kierownik projektu</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Znajomość zwinnych metodyk zarządzania projektami</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Znajomość branży IT oraz zasad działania ekosystem&oacute;w e-commerce </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-size: 10.0pt; font-family: 'Noto Sans Symbols';">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto;">Tworzenie dokumentacji </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magister Stosunk&oacute;w Międzynarodowych</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> Senior backend deweloper</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2015 &ndash; 2017 &ndash; Junior PHP Developer</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2017 &ndash; 2019 &ndash; PHP Developer</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2019 &ndash; obecnie &ndash; Magento Developer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Magento***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">PHP***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">MySQL***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Docker***<br />RabbitMQ***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Elasticsearch***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Kibana**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Xdebug***</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">GraphQL**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Rest API***<br />E-commerce*</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Adobe Certified Expert - Magento Commerce Developer</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Rozw&oacute;j i utrzymanie funkcjonalności dla sklep&oacute;w internetowych Magento 2/Adobe Commerce</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Tworzenie nowych rozwiązań eCommerce, od startu projektu do uruchomienia MVP i pomocy w utrzymaniu działającego sklepu</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Trzymanie jakości kodu</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Tworzenie dokumentacji</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: 'Noto Sans Symbols'; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Wsp&oacute;łpraca z Tech Leadem i klientami biznesowymi</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Inżynier Informatyki</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
<table style="border-collapse: collapse; border: none;" width="604">
<tbody>
<tr>
<td style="width: 453.0pt; border: solid black 1.0pt; background: #E6E6E6; padding: 0cm 5.4pt 0cm 5.4pt;" colspan="2" width="604">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto; color: black;">Stanowisko:</span></strong><span style="font-family: Roboto; color: black;"> QA Engineer </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Imię i nazwisko</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Doświadczenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2019 &ndash; 2020 &ndash; stażysta w firmie IT </span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">2020 &ndash; obecnie Junior QA Engineer </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Technologie (*****)</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Selenium**</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">CI/CD*</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Jenkins*</span></p>
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">AWS*</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Certyfikaty/kursy </span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">ISTQB&reg; Certified Tester Foundation Level</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wiedza</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: Roboto; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Dobra znajomość zagadnień związanych z testowaniem UI</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: Roboto; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Znajomość narzędzi Selenium, Appium</span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: Roboto; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie z narzędziami CI/CD </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: Roboto; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Doświadczenie w testach manualnych </span></p>
<p style="text-indent: -18.0pt; line-height: normal; margin: 0cm 0cm 0cm 36.0pt;"><span style="font-family: Roboto; color: black;">●<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-family: Roboto; color: black;">Umiejętności w pisaniu dokumentacji przypadk&oacute;w użytkownika</span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Wykształcenie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Licencjat informatyki </span></p>
</td>
</tr>
<tr>
<td style="width: 176.9pt; border: solid black 1.0pt; border-top: none; padding: 0cm 5.4pt 0cm 5.4pt;" width="236">
<p style="margin-bottom: 0cm; line-height: normal;"><strong><span style="font-family: Roboto;">Kompetencje miękkie</span></strong></p>
</td>
<td style="width: 276.1pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" width="368">
<p style="margin-bottom: 0cm; line-height: normal;"><span style="font-family: Roboto;">Komunikatywność, prezentacja pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
