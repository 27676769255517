import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  value: string | undefined;
  onChange: (newValue: string) => void;
  label?: string;
  error?: string;
  type?: "password" | "number";
  placeholder?: string;
  color?: "neutral" | "primary";
  multiline?: boolean;
  disabled?: boolean;
}

const StyledTextInput = (props: Props) => {
  const { t } = useTranslation();
  const {
    value,
    label,
    onChange,
    error,
    type,
    placeholder,
    color = "primary",
    multiline,
    disabled,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getInputProps = () => {
    let inputProps = {};
    if (type === "password") {
      inputProps = {
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      };
    }
    return inputProps;
  };

  return (
    <div className="styledTextInputWrapper">
      {label && <div className={`styledTextInput_label ${color}`}>{label}</div>}
      <TextField
        id="outlined-basic"
        className={`styledTextInput ${!!error && "error"} ${
          !!label && "withLabel"
        } ${color}`}
        value={value}
        error={!!error}
        disabled={disabled}
        multiline={multiline}
        rows={multiline ? 4 : undefined}
        type={type === "password" ? (showPassword ? "text" : type) : type}
        InputProps={getInputProps()}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        helperText={error}
        placeholder={label ? (placeholder ? placeholder : label) : placeholder}
      />
    </div>
  );
};

export default StyledTextInput;
