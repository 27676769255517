const html = `<h3 style="text-align: center;"><strong>Wykaz stanowisk w zespole wdrażającym RPA wraz z zakresami obowiązk&oacute;w i CV</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Stanowisko&nbsp;</strong></p>
</td>
<td style="height: 35px;">
<p><strong>Opis zadań</strong></p>
</td>
</tr>
<tr style="height: 74px;">
<td style="height: 74px;">
<p><span style="font-weight: 400;">Programista RPA</span></p>
</td>
<td style="height: 74px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">zaprogramowanie robota zgodnie z wytycznymi</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">przygotowanie dokumentacji technicznej</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">przeprowadzenie test&oacute;w z użytkownikami</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">naprawa błęd&oacute;w, serwis</span></li>
</ul>
</td>
</tr>
<tr style="height: 48.7188px;">
<td style="height: 48.7188px;">
<p><span style="font-weight: 400;">Starszy programista RPA</span></p>
</td>
<td style="height: 48.7188px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">przeprowadzenie code review/Quality Assurance&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">sprawdzenie technicznej poprawności zbudowania robota (sprawdzenie czy robot jest napisany zgodnie z dobrymi praktykami programowania)</span></li>
</ul>
</td>
</tr>
<tr style="height: 74px;">
<td style="height: 74px;">
<p><span style="font-weight: 400;">Architekt</span></p>
</td>
<td style="height: 74px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">analiza procesu biznesowego</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">optymalizacja procesu biznesowego, określenie wyjątk&oacute;w biznesowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">napisanie instrukcji opisującej krok po kroku&nbsp; działanie robota (PDD)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">przygotowanie dokumentacji opisującej rozwiązanie (SDD)</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table style="width: 647.328px;" border="1" cellpadding="5">
<tbody>
<tr>
<td style="width: 630.328px;" colspan="2">
<p><span style="font-weight: 400;">Stanowisko: </span><strong>Starszy programista RPA</strong></p>
</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Imię i nazwisko</strong></p>
</td>
<td style="width: 497.328px;">&nbsp;</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="width: 497.328px;">
<p><span style="font-weight: 400;">2013-2015 &ndash; Specjalista w zespole wsparcia RPA w firmie konsultingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2016 - 2020 &ndash; Programista RPA w firmie konsultingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 &ndash; Starszy programista RPA w banku&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="width: 497.328px;">
<p><span style="font-weight: 400;">Google Cloud Platform***</span></p>
<p><span style="font-weight: 400;">UI Path *****</span></p>
<p><span style="font-weight: 400;">Blue Prisme**</span></p>
<p><span style="font-weight: 400;">MS Power Automate*</span></p>
<p><span style="font-weight: 400;">VBA**</span></p>
<p><span style="font-weight: 400;">VB.Net**&nbsp;</span></p>
<p><span style="font-weight: 400;">C#**</span></p>
</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="width: 497.328px;">
<p><span style="font-weight: 400;">Advanced Developer UI Path&nbsp;</span></p>
<p><span style="font-weight: 400;">Foundation Blue Prism</span></p>
<p><span style="font-weight: 400;">Advanced Abbyy&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Wiedza</strong></p>
</td>
<td style="width: 497.328px;">
<p><span style="font-weight: 400;">Znajomość etap&oacute;w proces&oacute;w biznesowych&nbsp;</span></p>
<p><span style="font-weight: 400;">Umiejętność modelowania proces&oacute;w biznesowych&nbsp;</span></p>
<p><span style="font-weight: 400;">Znajomość środowisk MS Windows Server</span></p>
<p><span style="font-weight: 400;">Znajomość proces&oacute;w w SAP i Oracle&nbsp;</span></p>
<p><span style="font-weight: 400;">Znajomość najpopularniejszych, powtarzalnych proces&oacute;w w dużych firmach&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="width: 497.328px;">
<p><span style="font-weight: 400;">Średnie&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 133px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="width: 497.328px;">
<p><span style="font-weight: 400;">Komunikatywność, umiejętność prezentacji pomysł&oacute;w/wyjaśnienia , prowadzenie dyskusji</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table style="width: 647.328px;" border="1" cellpadding="5">
<tbody>
<tr style="height: 35px;">
<td style="height: 35px; width: 631.328px;" colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; </span><strong>Architekt RPA</strong></p>
</td>
</tr>
<tr style="height: 107px;">
<td style="height: 107px; width: 133px;">
<p><strong>Doświadczenie</strong></p>
</td>
<td style="height: 107px; width: 498.328px;">
<p><span style="font-weight: 400;">2010-2013 &ndash;Specjalista ds. order to cash (OTC)</span></p>
<p><span style="font-weight: 400;">2014-2021 &ndash; Lead zespołu ds. proces&oacute;w&nbsp;</span></p>
<p><span style="font-weight: 400;">2021 &ndash;Improvement Manager&nbsp;</span></p>
<p><span style="font-weight: 400;">2022 &ndash;Architekt RPA&nbsp;</span></p>
</td>
</tr>
<tr style="height: 179px;">
<td style="height: 179px; width: 133px;">
<p><strong>Technologie (*****)</strong></p>
</td>
<td style="height: 179px; width: 498.328px;">
<p><span style="font-weight: 400;">Excel****</span></p>
<p><span style="font-weight: 400;">Word**</span></p>
<p><span style="font-weight: 400;">Paint***</span></p>
<p><span style="font-weight: 400;">Miro**</span></p>
<p><span style="font-weight: 400;">Drw.io***</span></p>
<p><span style="font-weight: 400;">Jira****</span></p>
<p><span style="font-weight: 400;">Grow.io***</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px; width: 133px;">
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td style="height: 35px; width: 498.328px;">
<p><span style="font-weight: 400;">UIPath Automation Business Analyst&nbsp;</span></p>
</td>
</tr>
<tr style="height: 59px;">
<td style="height: 59px; width: 133px;">
<p><strong>Wiedza</strong></p>
</td>
<td style="height: 59px; width: 498.328px;">
<p><span style="font-weight: 400;">BPMN&nbsp;</span></p>
<p><span style="font-weight: 400;">Zarządzanie strategiczne</span></p>
</td>
</tr>
<tr style="height: 35.4375px;">
<td style="height: 35.4375px; width: 133px;">
<p><strong>Wykształcenie</strong></p>
</td>
<td style="height: 35.4375px; width: 498.328px;">
<p><span style="font-weight: 400;">Mgr Ekonomii&nbsp;</span></p>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px; width: 133px;">
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td style="height: 35px; width: 498.328px;">
<p><span style="font-weight: 400;">Komunikatywność, umiejętność prezentacji pomysł&oacute;w/wyjaśnienia , analityczne myślenie&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: </span><strong>Programista RPA&nbsp;</strong></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2016-2017 &ndash;Specjalista w zespole wsparcia RPA w firmie konsultingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2018 - 2020 &ndash; Programista RPA w firmie konsultingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2021 &ndash;&nbsp; Programista RPA w firmie ubezpieczeniowej&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Google Cloud Platform***</span></p>
<p><span style="font-weight: 400;">UI Path ***</span></p>
<p><span style="font-weight: 400;">VBA**</span></p>
<p><span style="font-weight: 400;">VB.Net**&nbsp;</span></p>
<p><span style="font-weight: 400;">C#**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Advanced Developer UI Path&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość etap&oacute;w proces&oacute;w biznesowych&nbsp;</span></p>
<p><span style="font-weight: 400;">Umiejętność modelowania proces&oacute;w biznesowych&nbsp;</span></p>
<p><span style="font-weight: 400;">Znajomość środowisk MS Windows Server</span></p>
<p><span style="font-weight: 400;">Znajomość proces&oacute;w w SAP i Oracle&nbsp;</span></p>
<p><span style="font-weight: 400;">Znajomość najpopularniejszych, powtarzalnych proces&oacute;w w dużych firmach&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Lic. mechatroniki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność prezentacji pomysł&oacute;w/wyjaśnienia , prowadzenie dyskusji</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>`;

export default html;
