import { CurrencyType } from "../enums/CurrencyType";
import i18n from "../i18n/i18n";
import ProductType from "../interfaces/payment/ProductType";

export const getCurrencyString = (currencyCode: CurrencyType) => {
  switch (currencyCode) {
    case CurrencyType.PLN:
      return i18n.t(`currency.${CurrencyType.PLN}`);
    default:
      return i18n.t(`currency.${CurrencyType.PLN}`);
  }
};

export const getPeriodLabel = (product: ProductType) => {
  let periodLabel = i18n.t(`global.${product.period}_1`);
  if (product.duration === 1)
    periodLabel = i18n.t(`global.${product.period}_1`);
  else if (product.duration > 1 && product.duration < 5)
    periodLabel = i18n.t(`global.${product.period}_2`);
  else periodLabel = i18n.t(`global.${product.period}_3`);

  return `${product.duration} ${periodLabel}`;
};
