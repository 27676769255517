const html = `<table style="width: 100%; border-collapse: collapse; border: 1px solid #000000; font-family: Roboto;">
    <tbody>
        <tr>
            <td style="width: 99.8807%; border: 1px solid rgb(0, 0, 0);">
                <div style="text-align: center;"><strong><span style="font-size: 18px;">Potrzeby i wymagania</span></strong></div>
            </td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Wdrożenie hurtowni danych</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Integracja danych z kilku r&oacute;żnych źr&oacute;deł</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Dostęp do aktualizowanych raz dziennie raport&oacute;w</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Użycie w raportach zar&oacute;wno wskaźnik&oacute;w prostych jak i złożonych (wyliczanych na podstawie określonych algorytm&oacute;w)</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Możliwość udzielenia dostępu do wybranych raport&oacute;w wybranym grupom użytkownik&oacute;w (zarządzanie dostępami)</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Automatyczne generowanie przejrzystych raport&oacute;w analitycznych, zawierających dane i wskaźniki zgodne z wymaganiami biznesowymi</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Filtrowanie i dezagregacja wskaźnik&oacute;w i danych uwzględnionych w &nbsp;raportach</td>
        </tr>
        <tr>
            <td style="width: 88.9125%; border: 1px solid rgb(0, 0, 0);">&bull; Eksportowanie danych w formacie excel i PDF<br></td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>`;

export default html;
