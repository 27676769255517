const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 24.900183717558892pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać i uzyskać podczas spotkania rozpoczynającego realizację projektu z obszaru VR</span></strong></p>
</td>
</tr>
<tr style="height: 43.71145308337714pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nasz zesp&oacute;ł składa się z 5 os&oacute;b, tj. architekta, programisty Unity, modelarza 3D, menadżera projektu oraz specjalisty ds. dokumentacji wymagań. Przypominam, że ustaliliśmy, iż szkolenie w aplikacji dostępne ma być w 3 r&oacute;żnych trybach, tj. w trybie symulacji, szkolenia i testu.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 43.71145308337714pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Chciałbym zaprezentować teraz harmonogram projektu. Ustalamy także tryb spotkań projektowych. Proponuję spotkania statusowe raz w tygodniu. Podczas nich będziemy mogli zaprezentować kolejne efekty prac.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 32.29886887949598pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zaprezentuję teraz Państwu macierz RACI, kt&oacute;ra w jasny spos&oacute;b pokazuje zakresy odpowiedzialności w projekcie.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 43.71145308337714pt;">
<td style="vertical-align: top; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W trakcie realizacji projektu ważne jest formalne akceptowanie kolejnych dokument&oacute;w, stanowiących kamienie milowe projektu. Są to w kolejności chronologicznej: scenariusz i dialogi, cyfrowa wizualizacja maszyny i fabryki, aplikacja szkoleniowa, testy.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 47.00372259720088pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Materiał szkoleniowy do akceptacji będzie przedstawiany Państwu w formie opisu, dokumentacji zdjęciowej/wideo oraz storyboard&oacute;w (jeśli brak będzie możliwości wykorzystania zdjęć, np. sytuacja wybuchu). Storyboard to narysowana klatka po klatce sekwencja następujących po sobie zdarzeń.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 27.74440852436286pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy UAT (user acceptance tests) przeprowadzane są przez przyszłych użytkownik&oacute;w rozwiązania. Mają na celu wykrycie błęd&oacute;w, ale także zebranie informacji zwrotnej, tj.&nbsp; feedbacku</span></p>
</td>
</tr>
<tr style="height: 35.59113839331972pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po zakończeniu projektu, przekażemy Państwu scenariusz szkolenia, user guide w zakresie obsługi aplikacji oraz instrukcje obsługi sprzętu a także dobre praktyki w zakresie realizowania szkoleń wykorzystujących VR w organizacji.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 27.74440852436286pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zachęcam Państwa do zadawania pytań. Można się kontaktować ze mną także telefonicznie i mailowo. Dziękuję za uwagę.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
