const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Propozycja usługi/rozwiązania technologicznego</strong></p>
</td>
<td>
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Product Information Management (PIM, system do zarządzania informacją o produktach i ofercie)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">System ten pozwala na zarządzenie informacjami o swoich produktach i ofercie w jednym miejscu, następnie na transferowanie (przesyłanie) zmian do zintegrowanych system&oacute;w, np. powiązanych sklep&oacute;w internetowych, system&oacute;w wykorzystywanych w sklepach stacjonarnych i sklepach zlokalizowanych w r&oacute;żnych miastach czy państwach.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Aplikacja BI&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Aplikacja umożliwi automatyczne generowanie raport&oacute;w sprzedażowych, szybki dostęp do raport&oacute;w i danych, a tym samym efektywniejsze zarządzanie sprzdażą.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">System do automatyzacji działań marketingowych (marketing automation system)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">System ten pozwala na zautomatyzowanie takich proces&oacute;w jak np.: wyświetlanie personalizowanej strony gł&oacute;wnej w zależności od historii przeglądania użytkownika, wysyłanie personalizowanych newsletter&oacute;w w zależności od historii zakupowej użytkownika.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Usługa utrzymania (maintenance) platformy e-commerce&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Działania takie jest rozwiązywanie bieżących problem&oacute;w, naprawa błęd&oacute;w zapewnią nieprzerwane działanie sklepu i zadowolenie klient&oacute;w.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Automatyzacja procesu wystawiania i wysyłania klientom faktur i innych dokument&oacute;w (np. aktualnych ofera i katalog produkt&oacute;w skierowane do konkrentego klienta)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dzięki automatyzacji i integracji modułu B2B z systemem księgowym możliwe będzie wyeliminowanie wykonwanych ręcznie, czasochłonnych proces&oacute;w generowania i wysyłania dokument&oacute;w klientom. Pozwoli to na znaczne oszczędności czasu pracy księgowego i pracownik&oacute;w obsługujących klienta.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dodanie do modułu B2B opcji pozwlającej na zalogowanie się na jednym koncie firmy kilku użytkownik&oacute;w posiadających r&oacute;żne uprawnienia&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Obsługa dużych klient&oacute;w wymaga posiadania tej funkcjonalności. Proces zakupowy w dużych firmach przebiega bowiem wieloetapowo, kilka os&oacute;b zatwierdza poszczeg&oacute;lne etapy procesu sprzedażowego i musi mieć dostęp do aktulanych informacji.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
