import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LineImage from "../../../assets/images/line.svg";
import { useDispatch, useSelector } from "react-redux";
import { getGames } from "../../../services/game.service";
import { AppDispatch, AppState } from "../../../store/store";
import GamesCategoriesComponent from "./GamesCategoriesComponent";
import StyledButton from "../../components/StyledButton";
import { useNavigate } from "react-router-dom";

const MainScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  useEffect(() => {
    dispatch(getGames(false, false));
  }, []);

  return (
    <div className="mainScreen">
      <div className="centerSection">
        <div className="digiLeader">
          <span>{t("mainScreen.digiLeader")}</span>
          <img src={LineImage} alt="line" />
        </div>
        <span className="header">{t("mainScreen.centerSectionHeader")}</span>
        <ul>
          <li className="info">{t("mainScreen.centerSectioninfo_1")}</li>
          <li className="info">{t("mainScreen.centerSectioninfo_2")}</li>
        </ul>
        <StyledButton
          label={t("pageTemplate.try")}
          onClick={() => navigate(`/game/6e5b23b0-483e-4f82-bdc5-0b3f90f86112`)}
          type="contained"
        />
      </div>
      <div className="gamesSection">
        <GamesCategoriesComponent />
      </div>
    </div>
  );
};

export default MainScreen;
