import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoriesImages } from "../../../constants/games";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import GameType from "../../../interfaces/game/GameType";
import { AppState } from "../../../store/store";
import GamesShow from "../../components/GamesShow";
import StyledButton from "../../components/StyledButton";
import CircularProgress from "@mui/material/CircularProgress";
import CategoryType from "../../../interfaces/game/CategoryType";
import UserType from "../../../interfaces/user/UserType";

const GamesCategoriesComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const categories: CategoryType[] = useSelector(
    (state: AppState) => state.gameReducer.categories
  );

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.gameReducer.isFetchingGames
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [selectedGames, setSelectedGames] = useState<GameType[]>([]);

  useEffect(() => {
    if (categories && games) {
      let filteredGames: GameType[] = [];
      if (selectedCategory === "all") {
        filteredGames = games;
      } else {
        filteredGames = categories.find(
          (category) => category.key === selectedCategory
        )!.games;
        if (!filteredGames) filteredGames = [];
      }
      setSelectedGames([...filteredGames]);
    }
  }, [selectedCategory, categories, games]);

  const renderCategory = (key: string, categoryName?: string) => {
    if (!categoryName) {
      const category = gamesData.find((category) => category.key === key)!;
      categoryName = category.categoryName;
    }
    return (
      <div
        className={`category ${selectedCategory === key ? "selected" : ""}`}
        key={key}
        onClick={() => setSelectedCategory(key)}
      >
        {categoriesImages[key] && <img src={categoriesImages[key]} alt={key} />}
        {categoryName}
      </div>
    );
  };

  const renderGameCard = (game: any, idx: number) => {
    if (game === null)
      return <div className="gameCard empty" key={`emptyCard_${idx}`} />;
    const key = game.categoryKey!;
    const category: CategoryMockType = gamesData.find(
      (category) => category.key === key
    )!;
    game = {
      ...game,
      ...category.games.find((item) => item.key === game.key)!,
    };
    return (
      <div className="gameCard" key={game.id}>
        <div className="category">
          {categoriesImages[key] && (
            <img src={categoriesImages[key]} alt={key} />
          )}
          {category.categoryName}
        </div>
        <div className="title">{game.gameTitle}</div>
        <div className="description">{game.description}</div>
        <StyledButton
          label="Więcej"
          onClick={() => navigate(`/game/${game.id}`)}
          type="contained"
          disabled={
            !(user && !!user.hasSubscriptionActive) &&
            game.id !== "6e5b23b0-483e-4f82-bdc5-0b3f90f86112"
          }
          color="dark"
        />
      </div>
    );
  };

  const renderCategoryInfo = () => {
    const category = gamesData.find((item) => item.key === selectedCategory)!;
    if (category.categoryInfo) {
      return (
        <div className="categoryInfoWrapper">
          <div className="categoryLongName">
            <span>{category.categoryInfo.categoryLongName}</span>
          </div>
          <div className="categoryDescription">
            <span>{category.categoryInfo.categoryDescription}</span>
          </div>
          <div className="categoryTags">
            <span className="technologies">{"Technologie:"}</span>
            {category.categoryInfo.tags.map((tag) => (
              <div className="tag">{tag}</div>
            ))}
          </div>
        </div>
      );
    } else return null;
  };

  return (
    <div className="gamesCategoriesComponent">
      <div className="header">{t("mainScreen.selectCategory")}</div>
      <div className="categories">
        {renderCategory("all", "Wszystkie")}
        {categories.map((category) => renderCategory(category.key))}
      </div>
      {selectedCategory !== "all" && renderCategoryInfo()}
      {isFetching ? (
        <div className="progressWrapper">
          <CircularProgress />
        </div>
      ) : (
        selectedGames && (
          <GamesShow
            games={selectedGames}
            renderGameCard={(game, idx) =>
              renderGameCard(game as GameType, idx)
            }
          />
        )
      )}
    </div>
  );
};

export default GamesCategoriesComponent;
