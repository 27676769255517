const html = `<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na realizację aplikacji wykorzystującej VR</span></strong></p>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki wdrożeniu technologii VR:</span></strong></p>
<p><br /><br /></p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">niska efektywność szkoleń pracownik&oacute;w linii produkcyjnej&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">niechęć młodych os&oacute;b do uczestnictwa w szkoleniach stacjonarnych (pracownicy oczekują wykorzystania cyfrowych narzędzi szkoleniowych)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">blokowanie linii produkcyjnej w czasie szkoleń</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ryzyko uszkodzenia maszyn podczas szkoleń</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ryzyko uszkodzenia ciała pracownik&oacute;w podczas szkoleń</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wąska przepustowość liczby jednocześnie szkolonych os&oacute;b (mała liczba szkoleniowc&oacute;w, jedna linia produkcyjna)&nbsp;</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p style="line-height: 1.2; margin-left: -0.749999999999981pt; text-indent: -0.749999999999981pt; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 0.749999999999981pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Realizowana usługa obejmie przygotowanie aplikacji szkoleniowej z użyciem technologii VR, przygotowanej w oparciu o opracowany w konsultacji z klientem, szczeg&oacute;łowy scenariusz szkolenia. Aplikacja przygotowana z użyciem silnika Unity, umożliwi przeprowadzenie interaktywnego szkolenia wykorzystującego technologię wizualizacji 3D, realizowanego z wykorzystaniem gogli VR. Szkolenie będzie mogło być realizowane w dw&oacute;ch trybach (2 ścieżki edukacyjne). Pierwszy, to tryb symulatora maszyny produkcyjnej. W tym trybie pracownik szkoli się samodzielnie, powtarzając kolejne czynności, aż do czasu ich bezbłędnego wykonania. Drugi tryb, tzw. szkolenie z instrukcją, polega na otrzymywaniu przez osobę szkolną wskaz&oacute;wek, tj. informacji o krokach, kt&oacute;re powinien podjąć. Efektem szkolenia będzie raport, zawierający dane o liczbie błęd&oacute;w popełnionych przez gracza, przesyłany w formie dokumentu PDF do działu szkoleń firmy. Elementem realizacji usługi będzie także dokumentacja szkolenia (scenariusz) oraz instrukcja korzystania z aplikacji VR (włączenie/wyłączenie gogli oraz aplikacji, kwestie techniczne i sieciowe), zalecenia w zakresie prowadzenia szkoleń z użyciem VR w organizacji. Klient uzyska licencję na aplikację możliwą do użycia na 1 komputerze i 1 okularach, w 1 fabryce, bez limitu os&oacute;b szkolonych. Oferta nie obejmuje koszt&oacute;w zakupu sprzętu.</span></p>
<p><br /><br /></p>
<p style="line-height: 1.2; margin-left: -0.749999999999981pt; text-indent: -0.749999999999981pt; text-align: justify; margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 0.749999999999981pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">stworzenie w pełni wirtualnego środowiska pracy, odwzorowanego&nbsp; 1:1&nbsp;&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">przygotowanie warsztatu szkoleniowego&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">przygotowanie ścieżki dialogowej (lektor)&nbsp;</span></li>
</ul>
<p><br /><br /></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające wykorzystanie technologii VR.</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">redukcja koszt&oacute;w szkolenia dzięki wykorzystaniu nowoczesnych metod szkolenia</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zwiększenie bezpieczeństwa pracownik&oacute;w podczas szkoleń</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zwiększenie liczby szkolonych jednocześnie pracownik&oacute;w</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">zmniejszenie liczby godzin zaangażowania szkoleniowc&oacute;w</span></li>
</ul>`;

export default html;
