import React, { useContext } from "react";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import GameMockType from "../interfaces/game/GameMockType";
import { DateTime } from "luxon";
import DigiLeaderLogo from "../assets/images/digiLeaderLogo.png";
//@ts-ignore
import NunitoRegular from "../assets/fonts/Nunito/Nunito-Regular.ttf";
import UserGameScoreType from "../interfaces/game/UserGameScoreType";
import AverageScore from "../interfaces/game/AverageScore";
import { secondsToHms } from "../utils/utils";

interface Props {
  userGameScore: UserGameScoreType | null;
  gameData: GameMockType | undefined;
  userName: string;
  averageScores: AverageScore[];
}

const UserGameReport = (props: Props) => {
  Font.register({
    family: "Nunito",
    format: "truetype",
    src: NunitoRegular,
  });

  const { userGameScore, gameData, userName, averageScores } = props;

  const getScoreColor = (score: number) => {
    let color = "#d32f2f";
    if (score >= 51 && score <= 75) color = "orange";
    else if (score >= 76) color = "#a0d911";
    return color;
  };

  const renderSection = (
    sectionName: string,
    section: { key: string; value: number }[]
  ) => {
    return (
      <>
        <View style={styles.tableRow} key={sectionName}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableCell, ...styles.header }}>
              {sectionName}
            </Text>
          </View>
        </View>
        {section.map((row, idx) => (
          <View style={styles.tableRow} key={`${sectionName}_${idx}`}>
            <View style={{ ...styles.tableCol, ...styles.textCol }}>
              <Text style={{ ...styles.tableCell }}>{row.key}</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                ...styles.scoreCol,
                color: getScoreColor(row.value),
              }}
            >
              <Text style={{ ...styles.tableCell }}>{row.value}</Text>
            </View>
          </View>
        ))}
      </>
    );
  };

  const renderSubtask = (idx: number) => {
    const subtaskData =
      userGameScore!.subtasks.length > 1
        ? gameData!.subtasks[idx]
        : gameData!.subtasks.find(
            (item) => item.key === userGameScore!.subtasks[0].subtaskKey
          );

    if (subtaskData) {
      const subtask =
        userGameScore!.subtasks.length === 1
          ? userGameScore!.subtasks[0]
          : userGameScore!.subtasks.find(
              (item) => item.subtaskKey === subtaskData.key
            );

      if (subtask) {
        const subtaskScores = userGameScore!.subtasks.find(
          (item) => item.subtaskKey === subtask.subtaskKey
        );
        if (subtaskScores) {
          const knowledge = subtaskData.knowledge
            .filter((item) =>
              subtaskScores.reports.find(
                (report) => report.reportKey === item.key
              )
            )
            .map((item) => {
              const currentSubtaskScore = subtaskScores.reports.find(
                (report) => report.reportKey === item.key
              );
              return {
                key: item.label,
                value: currentSubtaskScore!.reportScore,
              };
            });
          const skills = subtaskData.skills
            .filter((item) =>
              subtaskScores.reports.find(
                (report) => report.reportKey === item.key
              )
            )
            .map((item) => {
              const currentSubtaskScore = subtaskScores.reports.find(
                (report) => report.reportKey === item.key
              );
              return {
                key: item.label,
                value: currentSubtaskScore!.reportScore,
              };
            });
          const correctQuestionsPercentage = Math.floor(
            (subtaskScores.correctQuestions / subtaskScores.overallQuestions) *
              100
          );
          const correctAnswersPercentage = Math.floor(
            (subtaskScores.correctAnswers / subtaskScores.overallAnswers) * 100
          );
          let averageTime = null;
          const averageSubtaskScore = averageScores.find(
            (item) => item.subtaskKey === subtaskScores.subtaskKey
          );
          if (averageSubtaskScore)
            averageTime = averageSubtaskScore.averageTime;

          const additionalStats = [
            { key: "Testy wiedzy", value: subtaskScores.knowledgeTest },
            {
              key: "Właściwie zadane pytania",
              value: correctQuestionsPercentage,
            },
            {
              key: "Właściwie udzielone odpowiedzi",
              value: correctAnswersPercentage,
            },
          ];

          return (
            <>
              <Text
                style={styles.subtaskTitle}
              >{`CEL: ${subtaskData.title}`}</Text>
              <View style={styles.subtaskScore}>
                <Text style={styles.subtaskScore_text}>
                  Poziom realizacji celu:
                </Text>
                <Text
                  style={{
                    ...styles.subtaskScore_score,
                    color: getScoreColor(subtask.score),
                  }}
                >
                  {subtask.score}%
                </Text>
              </View>
              {subtask.doneTime >= 0 && (
                <>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={{ ...styles.tableCol, ...styles.textCol }}>
                        <Text
                          style={{
                            ...styles.tableCell,
                            ...styles.uppercase,
                          }}
                        >
                          Składowa kompetencji cyfrowej rozwijana w przebiegu
                          gry
                        </Text>
                      </View>
                      <View style={{ ...styles.tableCol, ...styles.scoreCol }}>
                        <Text
                          style={{
                            ...styles.tableCell,
                            ...styles.uppercase,
                          }}
                        >
                          Wynik w %*
                        </Text>
                      </View>
                    </View>
                    {knowledge.length > 0 &&
                      renderSection("Wiedza [Zna...]", knowledge)}
                    {skills.length > 0 &&
                      renderSection(
                        "Umiejętności [Potrafi opracować/wskazać...]",
                        skills
                      )}
                    {renderSection("Dodatkowe statystyki", additionalStats)}
                  </View>
                  <Text style={styles.gameTimeLine}>
                    {`Czas wykonania zadania: ${`${secondsToHms(
                      subtaskScores.doneTime
                    )}${
                      averageTime !== null
                        ? ` (średni czas innych użytkowników: ${secondsToHms(
                            averageTime
                          )})`
                        : ""
                    }`}`}
                  </Text>
                </>
              )}
            </>
          );
        }
      }
    }

    return null;
  };

  if (!userGameScore) return null;

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.logo} source={DigiLeaderLogo} />
        <View style={styles.buttonWrapper}>
          <Text style={styles.buttonText}>Raport z wynikami gry</Text>
        </View>
        <Text
          style={styles.topInfo}
        >{`Imię i nazwisko gracza: ${userName}`}</Text>
        <Text style={styles.topInfo}>{`Nazwa rozegranej gry: ${
          gameData!.gameTitle
        }`}</Text>
        <Text
          style={styles.topInfo}
        >{`Data wygenerowania raportu: ${DateTime.now().toFormat(
          "dd.LL.yyyy"
        )}`}</Text>
        <View style={styles.gameScore}>
          <Text
            style={{ ...styles.subtaskScore_text, textTransform: undefined }}
          >
            Twój całkowity wynik:
          </Text>
          <Text
            style={{
              ...styles.subtaskScore_score,
              color: getScoreColor(userGameScore.score),
            }}
          >
            {userGameScore.score}%
          </Text>
          <Text style={{ ...styles.subtaskScore_text, margin: "0 0 0 12" }}>
            Zadowalający
          </Text>
        </View>
        <Text style={styles.topInfoCapslock}>
          {"Poziom realizacji poszczególnych celów edukacyjnych:"}
        </Text>
        {userGameScore.subtasks.length >= 1 && renderSubtask(0)}
      </Page>
      {userGameScore.subtasks.length > 1 && (
        <>
          <Page wrap={false} style={styles.body}>
            {renderSubtask(1)}
            {renderSubtask(2)}
          </Page>
          <Page wrap={false} style={styles.body}>
            {renderSubtask(3)}
            {renderSubtask(4)}
            <Text style={styles.annotation}>
              {
                "*Wynik, oznaczający poziom kompetencji, wyliczany jest na podstawie poprawności raportów uzupełnianych w grze.\nDodatkowe statystyki odnoszą się do pozostałych aktywności gracza."
              }
            </Text>
          </Page>
        </>
      )}
    </Document>
  );
};

export default UserGameReport;

const styles = StyleSheet.create({
  body: {
    fontFamily: "Nunito",
    padding: "20px 0",
  },
  annotation: {
    fontSize: 10,
    margin: "10px 20px 0px 20px",
    color: "#000",
  },
  logo: {
    width: 128,
    height: 56,
    margin: "0 auto 20 auto",
  },
  topInfo: {
    fontSize: 11,
    margin: "0px 20px 10px 20px",
    color: "#000",
  },
  topInfoCapslock: {
    fontSize: 11,
    margin: "20px 20px 0px 20px",
    color: "#000",
    textTransform: "uppercase",
  },
  buttonText: {
    color: "#363d52",
    textTransform: "uppercase",
    fontSize: 10,
  },
  gameTimeLine: {
    fontSize: 11,
    margin: "10px 20px 0px 20px",
    color: "#000",
  },
  buttonWrapper: {
    backgroundColor: "#00f8ff",
    padding: "8px 12px",
    margin: "0 auto",
    borderRadius: 8,
    marginBottom: 20,
  },
  table: {
    // @ts-ignore
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: "20px 20px 0 20px",
  },
  gameScore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "12 20 0 20",
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 16,
    padding: 16,
    borderColor: "#00f8ff",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  subtaskTitle: {
    fontSize: 14,
    margin: "20px 20px",
  },
  subtaskScore: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
  },
  subtaskScore_text: {
    fontSize: 14,
    marginRight: 12,
    textTransform: "uppercase",
  },
  subtaskScore_score: {
    fontSize: 14,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    color: "#000",
  },
  header: {
    backgroundColor: "#00f8ff",
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  textCol: {
    width: "75%",
  },
  scoreCol: {
    width: "25%",
  },
  tableCell: {
    backgroundColor: "#fff",
    padding: "4px 8px",
    fontSize: 8,
  },
});
