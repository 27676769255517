const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="2" style="border: 1px solid rgb(0, 0, 0);"><br><br>
                    <p><strong>Podstawy kampanii płatnych w mediach cyfrowych</strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);"><br>
                    <p><strong>CELE KAMPANII PŁATNYCH W MEDIACH SPOŁECZNOŚCIOWYCH</strong></p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>RUCH - celem jest sprawienie, że użytkownik kliknie w reklamę i przejdzie np. na stronę www<br>&nbsp;AKTYWNOŚĆ - celem jest zmotywowanie użytkownika do działania, np. do zostawienia reakcji pod postem z reklamą<br>&nbsp;ŚWIADOMOŚĆ - celem jest pokazanie reklamy jak największej ilości os&oacute;b, czyli zbudowanie jak największego zasięgu i rozpoznawalności</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>GŁ&Oacute;WNE WSKAŹNIKI W RAPORTACH SKUTECZNOŚCI KAMPANII PŁATNYCH</strong></p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>KOSZT ZA WYNIK - wydany budżet podzielony na liczbę zrealizowanych WYNIK&Oacute;W, czyli średni koszt zrealizowanego celu<br>CPC - cost per click, średni koszt jednego kliknięcia w reklamę<br>CTR - click through rate, jaki procent WYŚWIETLEŃ zakończył się kliknięciem w reklamę<br>CPM - cost per mile, koszt 1000 wyświetleń reklamy</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>EKOSYSTEMY REKLAMOWE</strong></p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Meta</p>
                    <p>Google&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Co warto sprawdzić oceniając skuteczność kampanii?</strong></p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>CPC, czyli koszt za kliknięcie</p>
                    <p>CPM, czyli koszt 1000 wyświetleń reklamy&nbsp;</p>
                    <p>Jakość wynik&oacute;w</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
