import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick: () => void;
  icon: string;
}

const StyledIconButton = (props: Props) => {
  const { t } = useTranslation();
  const { onClick, icon } = props;

  return (
    <Button
      className="styledIconButton"
      variant={"contained"}
      onClick={onClick}
    >
      <img src={icon} alt="icon" />
    </Button>
  );
};

export default StyledIconButton;
