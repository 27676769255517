const html = `<table style="width: 1042px;" border="1" cellpadding="10">
<tbody>
<tr>
<td style="width: 1025px;" colspan="2">
<h3><strong>Usługi cyfrowe umożliwiające pogłębianie transformacji cyfrowej organizacji, po wdrożeniu narzędzia opartego o rozwiązanie typu Big Data</strong></h3>
</td>
</tr>
<tr>
<td style="width: 262.641px;">
<p><strong>Propozycja usługi/rozwiązania technologicznego</strong></p>
</td>
<td style="width: 762.359px;">
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td style="width: 262.641px;">
<p><span style="font-weight: 400;">Optymalizacja koszt&oacute;w usług chmurowych</span></p>
</td>
<td style="width: 762.359px;">
<p><span style="font-weight: 400;">Szczeg&oacute;łowa analiza sposobu przetwarzania, czasu przetwarzania, sposobu wykorzystywania i rodzaj&oacute;w danych gromadzonych na platformie, może skutkować wnioskami, kt&oacute;re pozwolą na zmianę lub optymalizację modelu korzystania z usług chmurowych, kt&oacute;re stanowią gł&oacute;wny koszt utrzymania platformy.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr>
<td style="width: 262.641px;">
<p><span style="font-weight: 400;">Wdrożenie modeli ML/AI celem automatyzacji i optymalizacji proces&oacute;w biznesowych</span></p>
</td>
<td style="width: 762.359px;">
<p><span style="font-weight: 400;">Analiza proces&oacute;w biznesowych i wiedza o rodzajach gromadzonych danych )zdobyta podczas wdrażania platformy BD) pozwalają na zaproponowanie wdrożenia automatyzacji proces&oacute;w, a tym samym osiągnięcie znacznych korzyści finansowych, jakościowych i&nbsp; wizerunkowych.</span></p>
</td>
</tr>
<tr>
<td style="width: 262.641px;">
<p><span style="font-weight: 400;">Utrzymanie platformy Big Data, rozwiązywanie bieżących problem&oacute;w (maintenance)</span></p>
</td>
<td style="width: 762.359px;">
<p><span style="font-weight: 400;">Rozw&oacute;j i utrzymania platformy stanowią klucz do czerpania wartości biznesowej z danych. Dzięki dużemu doświadczeniu i znajomości potrzeb firmy jesteśmy w stanie być skutecznym partnerem w tym procesie.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>`;

export default html;
