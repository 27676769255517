const html = `<p><span style="font-weight: 400;">Kr&oacute;tki opis projektu: Projekt polega na stworzeniu sklepu internetowego, kt&oacute;ry będzie oferował szeroki wyb&oacute;r artykuł&oacute;w spożywczych, w tym świeżych produkt&oacute;w spożywczych, przetworzonych produkt&oacute;w spożywczych i produkt&oacute;w specjalnych dla os&oacute;b z alergiami i nietolerancjami pokarmowymi. Klienci będą mieli możliwość przeglądania oferty, składania zam&oacute;wień oraz dokonywania płatności online.</span></p>
<p><span style="font-weight: 400;">Zakres projektu:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Projektowanie i implementacja interfejsu użytkownika</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Implementacja systemu koszyka i płatności online</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Integracja z systemami logistycznymi i firmami kurierskimi</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Konfiguracja systemu zarządzania treścią i produktami</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Integracja z systemami marketingowymi i narzędziami do analityki</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Stworzenie dedykowanej funkcjonalności dla os&oacute;b z alergiami i nietolerancjami pokarmowymi</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Testowanie i wdrożenie sklepu</span></li>
</ul>
<p><span style="font-weight: 400;">Korzyści osiągnięte z wdrożenia:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zwiększenie zasięgu i efektywności sprzedaży poprzez dostępność 24/7</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Automatyzacja proces&oacute;w związanych z obsługą klient&oacute;w i zam&oacute;wień</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Poprawa wizerunku marki poprzez obecność w internecie</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Możliwość dostarczenia produkt&oacute;w spożywczych do domu klienta, co jest szczeg&oacute;lnie ważne w czasie pandemii</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Stworzenie funkcjonalności dedykowanej dla os&oacute;b z alergiami i nietolerancjami pokarmowymi, co zwiększa ich zadowolenie i lojalność wobec marki</span></li>
</ul>
<p><span style="font-weight: 400;">Narzędzia, systemy, technologie wykorzystane w projekcie:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Platforma e-commerce: Shopify</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Systemy płatności online: PayPal, Stripe</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Systemy logistyczne i kurierskie: FedEx, DHL</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Systemy zarządzania treścią: WordPress</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Narzędzia do analityki: Google Analytics</span></li>
</ul>
<p><span style="font-weight: 400;">Czas trwania projektu: 8 miesięcy</span></p>
<p><span style="font-weight: 400;">Zesp&oacute;ł:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Programista Shopify (1FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Grafik/UI designer (1FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tester oprogramowania (0,5FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Project Manager (0,25FTE)</span></li>
</ul>
<p><span style="font-weight: 400;">Budżet projektu: 150 000 zł.</span></p>`;

export default html;
