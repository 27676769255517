import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StyledButton from "../components/StyledButton";
import UserIcon from "../../assets/images/icons/user.svg";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "../../reducers/user.reducer";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../store/store";
import { RoleType } from "../../enums/RoleType";
import MainContext from "../../context/MainContext";
import SuccessIcon from "../../assets/images/icons/success_icon.svg";
import WarningIcon from "../../assets/images/icons/warning_icon.svg";
import CloseIcon from "../../assets/images/icons/close_icon.svg";
import SettingsIcon from "../../assets/images/icons/settings_icon.svg";
import BagIcon from "../../assets/images/icons/bag_icon.svg";
import LogoutIcon from "../../assets/images/icons/logout_icon.svg";
import UserType from "../../interfaces/user/UserType";
import ArrowDownIcon from "../../assets/images/icons/arrow_down_icon.svg";
import SettingsForm from "./SettingsForm";
import BagComponent from "./BagComponent";
import { clearGameReducer } from "../../reducers/game.reducer";
import { CircularProgress } from "@mui/material";
import CustomModal from "../components/CustomModal";
import AccessibleIcon from "@mui/icons-material/Accessible";
import ContrastIcon from "@mui/icons-material/Contrast";
import FormatSizeIcon from "@mui/icons-material/FormatSize";

interface Props {
  children: ReactElement;
}

const PageTemplate = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const {
    defaultModalParams,
    showDefaultModal,
    setAccessibilityContrast,
    accessibilityContrast,
    accessibilityFontSize,
    setAccessibilityFontSize,
  } = mainContext;
  const [hasAccess, setHasAccess] = useState(true);
  const userRole = useSelector((state: AppState) => state.userReducer.role);
  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );
  const hasEducatorSubscriptionActive = useSelector(
    (state: AppState) => state.userReducer.hasEducatorSubscriptionActive
  );
  const [isUserSidebarOpen, setIsUserSidebarOpen] = useState<boolean>(false);
  const [accessibilityPopupShown, setAccessibilityPopupShown] =
    useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>("");
  const gamesData = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );
  const isFetchingEducator = useSelector(
    (state: AppState) => state.userReducer.isFetchingEducator
  );

  useEffect(() => {
    document.body.classList.toggle("contrast", accessibilityContrast);
  }, [accessibilityContrast]);

  useEffect(() => {
    document.body.classList.toggle("fontSizeLarge", accessibilityFontSize);
  }, [accessibilityFontSize]);

  useEffect(() => {
    if (user && !isFetchingEducator) {
      if (!user.groupId) setHasAccess(user.hasSubscriptionActive);
      else if (hasEducatorSubscriptionActive !== null)
        setHasAccess(hasEducatorSubscriptionActive);
      else setHasAccess(false);
    }
  }, [user, hasEducatorSubscriptionActive, isFetchingEducator]);

  const tabs = () => {
    switch (userRole) {
      case RoleType.USER:
        return (
          <>
            <div className="tab" onClick={() => navigate("/dashboard")}>
              <span>{"Katalog gier"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/games")}>
              <span>{"Moje gry"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/configurator")}>
              <span>{"Konfigurator gry"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/digital_profile")}>
              <span>{"Profil cyfrowy"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/faq")}>
              <span>{"Pomoc"}</span>
            </div>
          </>
        );
      case RoleType.EDUCATOR:
        return (
          <>
            <div className="tab" onClick={() => navigate("/")}>
              <span>{"Katalog gier"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/panel")}>
              <span>{"Panel organizacji"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/configurator")}>
              <span>{"Konfigurator gry"}</span>
            </div>
            <div className="tab" onClick={() => navigate("/faq")}>
              <span>{"Pomoc"}</span>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const defaultModalOnCancel = () => {
    if (defaultModalParams!.onCancel) {
      defaultModalParams!.onCancel();
    }
    showDefaultModal(null);
  };

  const defaultModalOnSubmit = () => {
    if (defaultModalParams!.onSubmit) {
      defaultModalParams!.onSubmit();
    }
    showDefaultModal(null);
  };

  const renderUserPanelSectionHeader = (
    label: string,
    withArrow: boolean,
    icon: any,
    onClick: () => void
  ) => (
    <div className="section" onClick={onClick}>
      <img className="section_icon" src={icon} alt="section_icon" />
      <span>{label}</span>
      {withArrow && (
        <img className="arrow_icon" src={ArrowDownIcon} alt="arrow_icon" />
      )}
    </div>
  );

  const renderSection = () => {
    switch (selectedSection) {
      case "":
        return (
          <>
            {renderUserPanelSectionHeader(
              "Ustawienia konta",
              true,
              SettingsIcon,
              () => setSelectedSection("settings")
            )}
            {user &&
              !user.groupId &&
              renderUserPanelSectionHeader("Moje produkty", true, BagIcon, () =>
                setSelectedSection("products")
              )}
            {renderUserPanelSectionHeader(
              "Wyloguj",
              false,
              LogoutIcon,
              async () => {
                await dispatch(clearAuth());
                await dispatch(clearGameReducer());
              }
            )}
          </>
        );
      case "settings": {
        return <SettingsForm onClose={onPanelClose} />;
      }
      case "products": {
        return <BagComponent onClose={onPanelClose} />;
      }
      default:
        return null;
    }
  };

  const onPanelClose = () => {
    setIsUserSidebarOpen(false);
    setSelectedSection("");
  };

  const getPanelHeader = () => {
    let headerText = user!.email;
    if (selectedSection === "settings") headerText = "Ustawienia konta";
    if (selectedSection === "products") headerText = "Moje produkty";
    return (
      <>
        {selectedSection && (
          <img
            className="back_arrow_icon"
            src={ArrowDownIcon}
            alt="back_arrow_icon"
            onClick={() => setSelectedSection("")}
          />
        )}
        <span>{headerText}</span>
        <img
          className="closeIcon"
          src={CloseIcon}
          alt="closeIcon"
          onClick={onPanelClose}
        />
      </>
    );
  };

  const isFreeContent = () => {
    const pathname = window.location.pathname;
    switch (pathname) {
      case "/dashboard":
        return true;
      case "/game/6e5b23b0-483e-4f82-bdc5-0b3f90f86112":
        return true;
      case "/subscription":
        return true;
      case "/payment-status":
        return true;
      case "/faq":
        return true;
      default:
        return false;
    }
  };

  const accessibilityPopup = () => {
    return (
      <div className="accessibilityPopup">
        <ContrastIcon
          onClick={() => setAccessibilityContrast(!accessibilityContrast)}
        />
        <FormatSizeIcon
          onClick={() => setAccessibilityFontSize(!accessibilityFontSize)}
        />
      </div>
    );
  };

  return (
    <div className="pageTemplate">
      <div
        className="menu"
        onClick={isUserSidebarOpen ? onPanelClose : undefined}
      >
        <div className="left">
          <img
            className="logo"
            alt="DigiLeader"
            src="https://digileader.cloud/wp-content/uploads/2021/05/digi-leader-logo.png"
            onClick={() => navigate("/dashboard")}
          />
          {tabs()}
        </div>
        <div className="right">
          {user && !user.groupId && (
            <StyledButton
              type="contained"
              onClick={() => navigate("/subscription")}
              label={
                user.hasSubscriptionActive ? "Przedłuż dostęp" : "Kup dostęp"
              }
            />
          )}
          <div
            className="accessibleAccount"
            onMouseOver={() => setAccessibilityPopupShown(true)}
            onMouseOut={() => setAccessibilityPopupShown(false)}
          >
            <div className="accessibleIconBackground">
              <AccessibleIcon />
            </div>
            {accessibilityPopupShown && accessibilityPopup()}
          </div>
          <div
            className="userAccount"
            onClick={() => setIsUserSidebarOpen(true)}
          >
            <img className="userIcon" src={UserIcon} alt="user" />
          </div>
        </div>
      </div>
      {props.children}
      {defaultModalParams && (
        <>
          <div className="modalBackground" />
          <div className="pageTemplateDefaultModal">
            <div className="topSection">
              <img
                className="icon"
                src={
                  defaultModalParams.type === "warning"
                    ? WarningIcon
                    : SuccessIcon
                }
                alt="icon"
              />
              <div className="textWrapper">
                <span className="header">{defaultModalParams.header}</span>
                <span className="text">{defaultModalParams.text}</span>
              </div>
            </div>
            <div className="buttons">
              <StyledButton
                type="contained"
                color="white"
                onClick={defaultModalOnCancel}
                label={"Anuluj"}
              />
              {defaultModalParams.onSubmit &&
                defaultModalParams.submitLabel && (
                  <StyledButton
                    type="contained"
                    color="dark"
                    onClick={defaultModalOnSubmit}
                    label={defaultModalParams.submitLabel}
                  />
                )}
            </div>
          </div>
        </>
      )}
      {isUserSidebarOpen && (
        <>
          <div className="backgroudWithOpacity" onClick={onPanelClose} />
          <div className="userPanel">
            <div className="headerWrapper">{getPanelHeader()}</div>
            {renderSection()}
          </div>
        </>
      )}
      {!hasAccess && !isFreeContent() && (
        <CustomModal
          onCancel={() => navigate("/dashboard")}
          onSubmit={() => navigate("/subscription")}
          submitLabel="Kup teraz"
          header="Brak aktywnej subskrypcji"
        >
          <div>
            Wykup subskrypcję, by cieszyć się pełnym dostępem do systemu.
          </div>
        </CustomModal>
      )}
      {(gamesData.length === 0 || isFetchingEducator) && (
        <>
          <div className="modalBackground" />
          <CircularProgress />
        </>
      )}
    </div>
  );
};

export default PageTemplate;
