const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Technologia</strong></p>
</td>
<td>
<p><strong>Opis technologii</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii&nbsp;</strong></p>
</td>
<td>
<p><strong>Czynniki wyboru/odrzucenia technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Silnik platformy e-commerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia zarządzanie sklepem internetowym przez administrację oraz przejście ścieżki zakupowej dla klienta końcowego.Silnik platformy e-commerce to zbi&oacute;r niezbędnych moduł&oacute;w umożliwiający sprzedaż w Internecie.</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Magento</span></p>
<p><span style="font-weight: 400;">Prestashop</span></p>
<p><span style="font-weight: 400;">Woocommerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">GIT</span></p>
<p><span style="font-weight: 400;">PHP</span></p>
<p><span style="font-weight: 400;">MySQL</span></p>
<p><span style="font-weight: 400;">Docker</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">RabbitMQ</span></p>
<p><span style="font-weight: 400;">Elasticsearch</span></p>
<p><span style="font-weight: 400;">Xdebug</span></p>
<p><span style="font-weight: 400;">GraphQL</span></p>
<p><span style="font-weight: 400;">Rest API</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">E-commerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Skala i potrzeby biznesu to gł&oacute;wne czynniki determinujące wyb&oacute;r platformy. Im większe potrzeby platforma powinna oferować więcej możliwości.&nbsp;</span></p>
<br /><br /></td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Serwer dostosowany do platformy e-commerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia przetrzymywanie plik&oacute;w źr&oacute;dłowych sklepu internetowego, danych o produktach oraz wszystkich medi&oacute;w.&nbsp;</span></p>
<p><span style="font-weight: 400;">Serwer posiadający zgodność z technologiami wymaganymi przez wybrany silnik e-commerce.</span></p>
<br />
<p><strong>Przykłady:</strong> <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">AWS</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Serwer wirtualny</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Serwer dedykowany</span><span style="font-weight: 400;"><br /><br /></span></p>
</td>
<td>
<p><span style="font-weight: 400;">Nginx</span></p>
<p><span style="font-weight: 400;">Varnish</span></p>
<p><span style="font-weight: 400;">PHP</span></p>
<p><span style="font-weight: 400;">MySQL</span></p>
<p><span style="font-weight: 400;">Sphinx</span></p>
<p><span style="font-weight: 400;">Solr&nbsp;</span></p>
<p><span style="font-weight: 400;">Elasticsearch</span></p>
<p><span style="font-weight: 400;">RabbitMQ</span></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dodatkowe, płatne moduły do integracji z platformą&nbsp;&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwiają dostosowanie platformy do potrzeb klienta. Moduły przyśpieszają i ułatwiają pracę. Wyr&oacute;żniają sklep na tle konkurencji.</span></p>
<br />
<p><strong>Przykłady&nbsp;</strong></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Szybsze i wydajniejsze filtrowanie produkt&oacute;w&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zaawansowana wyszukiwarka produkt&oacute;w&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zaawansowany upselling i crossseling produkt&oacute;w</span></li>
</ul>
</td>
<td>
<p><span style="font-weight: 400;">GIT</span></p>
<p><span style="font-weight: 400;">PHP</span></p>
<p><span style="font-weight: 400;">MySQL</span></p>
<p><span style="font-weight: 400;">Docker</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">RabbitMQ</span></p>
<p><span style="font-weight: 400;">Elasticsearch</span></p>
<p><span style="font-weight: 400;">Xdebug</span></p>
<p><span style="font-weight: 400;">GraphQL</span></p>
<p><span style="font-weight: 400;">Rest API</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">E-commerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozwiązania muszą spełniać potrzebę klienta i być efektywne kosztowo.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie do integracji</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia połączenie dw&oacute;ch system&oacute;w w celu wymiany danych. Integratory przygotowane przez firmy trzecie mają swoje ograniczenia, ale w wielu przypadkach spełnią potrzebę klienta i nie wymagane będzie tworzenie customowego [dedykowanego] narzędzia do wymiany danych.</span></p>
<br />
<p><strong>Przykłady</strong></p>
<p><span style="font-weight: 400;">Wapro Mag</span></p>
<p><span style="font-weight: 400;">SubSync2</span></p>
<p><span style="font-weight: 400;">Elte-s Optima</span></p>
<br /><br /></td>
<td>
<p><span style="font-weight: 400;">Rest API</span></p>
<p><span style="font-weight: 400;">MySQL</span></p>
<p><span style="font-weight: 400;">E-commerce</span></p>
<p><span style="font-weight: 400;">Wiedza informatyczna</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozwiązania muszą spełniać potrzebę klienta i być efektywne kosztowo.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Gotowy do wdrożenia moduł B2B&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Umożliwia dodanie do platformy e-commerce funkcjonalności umożliwiających obsługę procesow zakupowych klient&oacute;w handlowych.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Opis&nbsp;</span></p>
<p><span style="font-weight: 400;">Gotowe do wdrożenia moduły przyśpieszają prace deweloperskie związanie z tworzeniem lub dodawaniem nowych funkcjonlaności do platform e-commerce. Moduły po zainstalowaniu umożliwiają zrealizowanie większości scenariuszy użycia oczekiwanych przez klient&oacute;w. W sytuacji, gdy moduł nie spełnia wszystkich oczekiwań klienta, możliwe jest jego indywidualne dostosowanie, poprzez modyfikację lub dodanie kodu. Wymaga to kosztownych prac programistycznych.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Moduły są konfigurowalne, przy użyciu CMS (system do zarządzania treścią, wizualny interface użytkownika), dzięki czemu pracownik sklepu może w prosty spos&oacute;b zedytować np. rabat dla wybranej grupy klient&oacute;w B2B bez ingerencji programisty w kodzie.&nbsp;</span></p>
<br /><br />
<p><span style="font-weight: 400;">Przykłady funkcjonalności dostępnych w module B2B:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Kredyt kupiecki</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Rabaty dla wybranego klienta lub dla grupy klient&oacute;w</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Automatyczne przyznawanie rabat&oacute;w po przekroczeniu danego progu zam&oacute;wień</span></li>
</ul>
<br />
<p><strong>Przykłady: </strong><span style="font-weight: 400;">B2B E-commerce Premium Suite for Magento 2, B2B Ecommerce Platform Shopify Plus, B2B for Shoper</span></p>
</td>
<td>
<p><span style="font-weight: 400;">GIT</span></p>
<p><span style="font-weight: 400;">PHP</span></p>
<p><span style="font-weight: 400;">MySQL</span></p>
<p><span style="font-weight: 400;">Docker</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">RabbitMQ</span></p>
<p><span style="font-weight: 400;">Elasticsearch</span></p>
<p><span style="font-weight: 400;">Xdebug</span></p>
<p><span style="font-weight: 400;">GraphQL</span></p>
<p><span style="font-weight: 400;">Rest API</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">E-commerce</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozwiązanie musi odpowiadać specyfice przedsiębiorstwa i realnie wspierać realizowane procesy zakupowe.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
