import React from "react";
import { useTranslation } from "react-i18next";
import { industries } from "../../../../constants/games";
import CheckIcon from "../../../../assets/images/icons/check_primary_icon.svg";

interface Props {
  industry: string;
  onChange: (value: string) => void;
}

const IndustrySection = (props: Props) => {
  const { t } = useTranslation();
  const { onChange, industry } = props;

  return (
    <div className="industrySection">
      <div className="sectionsTitle">
        {"Wybierz branżę, której dotyczyć ma konfigurowana gra:"}
      </div>
      <div className="bubbles">
        {industries.map((item) => (
          <div
            key={item}
            className={`bubble ${industry === item ? "selected" : ""}`}
            onClick={() => onChange(item)}
          >
            {industry === item && (
              <img
                className="selectedIcon"
                src={CheckIcon}
                alt="selectedIcon"
              />
            )}
            {t(`industries.${item}`)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndustrySection;
