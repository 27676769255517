const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="2" style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zadania szczeg&oacute;łowe wymagane do realizacji w ramach projektu z obszaru marketingu cyfrowego</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Audyt kompetencji zespołu klienta</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkanie/spotkania - zebranie danych o kompetencjach członk&oacute;w zespołu klienta.</p>
                    <p>Opracowanie raportu z audytu kompetencji klienta.</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Audyt zasob&oacute;w marketingowych klienta (www, branding)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Analiza wykorzystywanych założeń strategicznych: grupy docelowej, komunikat&oacute;w marki, identyfikacji marki.</p>
                    <p>Analiza jakości działań marketingowych: strony www, PR, medi&oacute;w społecznościowych, kampanii płatnych.</p>
                    <p>Analiza rezultat&oacute;w wcześniejszych działań marketingowych.</p>
                    <p>Opracowanie raportu z audytu działań i zasob&oacute;w marketingowych.</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie strategii marki i gł&oacute;wnych założeń komunikacji marki</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zdefiniowanie i uszczeg&oacute;łowienie grupy docelowej, jej segmentacja i ustalenie priorytet&oacute;w dla poszczeg&oacute;lnych segment&oacute;w</p>
                    <p>Wyznaczenie cel&oacute;w działań dla poszczeg&oacute;lnych etap&oacute;w egzekucji oraz zdefiniowanie KPI [Key Performance Indicators - Kluczowych Wskaźnik&oacute;w Efektywności, czyli element&oacute;w mierzonych w celu p&oacute;źniejszej oceny postęp&oacute;w pracy oraz realizacji cel&oacute;w].</p>
                    <p>Sformułowanie gł&oacute;wnego przekazu marki - specjalizacji produktu i gł&oacute;wnych cech wyr&oacute;żniających [inaczej USP czyli Unique Selling Proposition].</p>
                    <p>Sformułowanie gł&oacute;wnych wartości marki [inaczej brand equity], języka marki i jej archetypu.</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie ramowego planu działań na 6 m-cy</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Dob&oacute;r kanał&oacute;w komunikacji</p>
                    <p>Zdefiniowanie gł&oacute;wnych lejk&oacute;w sprzedażowych</p>
                    <p>Budżetowanie wydatk&oacute;w</p>
                    <p>Opracowanie ramowego kalendarza działań i realizowanych cel&oacute;w</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zrekrutowanie wykonawc&oacute;w i wdrożenie narzędzi</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie zapotrzebowania na wykonawc&oacute;w i kompetencje zewnętrzne</p>
                    <p>Opracowanie rekomendacji narzędzi</p>
                    <p>Rekrutacja wykonawc&oacute;w usług zewnętrznych</p>
                    <p>Zakup i konfiguracja narzędzi wspierających działania marketingu cyfrowego</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Optymalizacja proces&oacute;w sprzedażowych prowadzonych przez klienta w kanałach cyfrowych (www, marketplace &ndash; poprawa błęd&oacute;w ujawnionych w audycie)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Modyfikacje na stronie www (system stron lądowania, poprawa procesu zakupowego)</p>
                    <p>Uzupełnienie i konfiguracja profili społecznościowych</p>
                    <p>Konfiguracja paneli biznesowych i reklamowych (Social Ads)</p>
                    <p>Podpięcie kod&oacute;w śledzących i konfiguracja Google Analytics</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Produkcja treści (na potrzeby content marketingu)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zlecenie stworzenia content plan&oacute;w [szczeg&oacute;łowy kalendarz publikacji treści we wszystkich kanałach komunikacji marki, np. na wszystkich profilach społecznościowych] (cyklicznie, raz w miesiącu). Uwagi. Akceptacja</p>
                    <p>Koordynowanie produkcji treści tekstowych - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem</p>
                    <p>Koordynowanie produkcji treści graficznych/zdjęciowych - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem</p>
                    <p>Koordynowanie produkcji treści audio/video - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Prowadzenie działań komunikacyjnych w social mediach (publikacje, moderacja)</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Przekazanie niezbędnych uprawnień i kontrola polityki bezpieczeństwa danych (jednorazowo).</p>
                    <p>Kontakt z wykonawcami - odpowiedzi na pytania, rozwiązywanie konflikt&oacute;w, dostarczanie niezbędnych materiał&oacute;w (w miarę potrzeb).</p>
                    <p>Rozliczenie faktur, dopilnowanie płatności (raz w miesiącu).</p>
                    <p>Kontrola zgodności publikacji z założonym planem - raz/dwa razy w tygodniu. Zgłoszenie uwag.</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Przeprowadzenie kampanii płatnych w social mediach (social ads, zasięgowe, wizerunkowe/sprzedażowe, remarketingowe)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Przekazanie niezbędnych uprawnień i kontrola polityki bezpieczeństwa danych (jednorazowo).</p>
                    <p>Kontakt z wykonawcami - odpowiedzi na pytania, rozwiązywanie konflikt&oacute;w, dostarczanie niezbędnych materiał&oacute;w (w miarę potrzeb).</p>
                    <p>Rozliczenie faktur za kampanie płatne, dopilnowanie płatności (raz w miesiącu).&nbsp;</p>
                    <p>Kontrola zgodności ustawień kampanii płatnych z założonym planem - raz/dwa razy w tygodniu. Zgłoszenie uwag.</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Raportowanie okresowe (milestones x3 w trakcie 6 miesięcy)</p><br>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>3 x Wyegzekwowanie raportu okresowego od Social Media Managera (odpowiedzialnego za zebranie informacji cząstkowych)</p>
                    <p>3 x Skonsultowanie raportu z zespołem i klientem - zgromadzenie danych o niezbędnych zmianach w działaniu</p>
                    <p>3 x Debriefing wykonawc&oacute;w</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie raportu końcowego</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Wyegzekwowanie raportu końcowego od Social Media Managera (odpowiedzialnego za zebranie informacji cząstkowych)&nbsp;</p>
                    <p>Uzupełnienie otrzymanych danych o własną interpretację analityczną, zebranie wniosk&oacute;w i rekomendacji</p>
                    <p>Skonsultowanie raportu z zespołem i klientem - zgromadzenie i prezentacja danych o niezbędnych zmianach w działaniu</p>
                    <p>Zamknięcie i rozliczenie wykonawc&oacute;w</p><br>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
