const html = `<h3 style="text-align: center;"><strong>MODELOWY HARMONOGRAM PROJEKTU WDROŻENIA aplikacji chmurowej (SaaS)&nbsp;</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Czas trwania zadania (tyg.)</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Osoby odpowiedzialne/realizujące</strong></p>
</td>
<td style="height: 48px;">
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><strong>I</strong><span style="font-weight: 400;">dentyfikacja potrzeb organizacji w zakresie aplikacji chmurowych, wymaganych funkcjonalności i zakres&oacute;w dostępu&nbsp;</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">2 tyg.</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Analityk biznesowy</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Dokument opisujący założenia.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 61.7188px;">
<td style="height: 61.7188px;">
<p><span style="font-weight: 400;">Założenie konta administratora u dostawcy usług chmurowych (wejście na stronę dostawcy, założenie konta (trial 30 dni lub regularne) w tym podpięcie karty kredytowej</span></p>
</td>
<td style="height: 61.7188px;">
<p><span style="font-weight: 400;">30 min</span></p>
</td>
<td style="height: 61.7188px;">
<p><span style="font-weight: 400;"> Pracownik działu IT/ Administrator system&oacute;w</span></p>
</td>
<td style="height: 61.7188px;">
<p><span style="font-weight: 400;"> Aktywne konto administratora usługi&nbsp;</span></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Założenie kont dla użytkownik&oacute;w organizacji (tj. os&oacute;b kt&oacute;re będą miały dostęp do usług)</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;"> 60 min (w zależności od liczby potrzebnych kont)</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Pracownik działu IT/ Administrator system&oacute;w</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;"> Aktywne konta użytkownik&oacute;w&nbsp;</span></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Konfigurowanie zasad funkcjonowania środowiska chmurowego w organizacji</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;"> 60 min</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Pracownik działu IT/ Administrator system&oacute;w</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;"> Odpowiednio skonfigurowane konta i polityka bezpieczeństwa&nbsp;</span></p>
</td>
</tr>
<tr style="height: 48px;">
<td style="height: 48px;">
<p><span style="font-weight: 400;">Przekazanie pracownikom link&oacute;w do aplikacji oraz szkolenie z obsługi narzędzi&nbsp;&nbsp;</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">60 min</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Analityk biznesowy&nbsp;</span></p>
</td>
<td style="height: 48px;">
<p><span style="font-weight: 400;">Aktywne wykorzystywanie aplikacji przez pracownik&oacute;w.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
