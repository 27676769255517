import { Dispatch } from "@reduxjs/toolkit";
import i18n from "../i18n/i18n";
import {
  createSubscriptionFulfilled,
  createSubscriptionPending,
  createSubscriptionRejected,
  getLastPaymentFulfilled,
  getLastPaymentPending,
  getLastPaymentRejected,
  getProductsFulfilled,
  getProductsPending,
  getProductsRejected,
} from "../reducers/payment.reducer";
import { setErrorMessage } from "../reducers/message.reducer";
import { errorHandler } from "../utils/error.utils";
import { axiosService } from "./axios.service";
import { SubscriptionType } from "../enums/SubscriptionType";

const paymentService = "/payment";

export const getProducts =
  (type?: SubscriptionType) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getProductsPending());
      const response = await axiosService.get(
        `${paymentService}/subscriptions/subscriptionPlans${
          type ? `?type=${type}` : ""
        }`
      );
      dispatch(getProductsFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.getProductsException")))
      );
      //@ts-ignore
      dispatch(getProductsRejected(e.message));
    }
  };

export const createSubscription =
  (userId: string, subscriptionPlanId: string, deleteUsers?: boolean) =>
  async (dispatch: Dispatch | any) => {
    try {
      dispatch(createSubscriptionPending());
      const response = await axiosService.post(
        `${paymentService}/subscriptions`,
        { userId, subscriptionPlanId, deleteUsers }
      );
      dispatch(createSubscriptionFulfilled(response.data.redirectUrl));
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.createSubscriptionException"))
        )
      );
      //@ts-ignore
      dispatch(createSubscriptionRejected(e.message));
    }
  };

export const getLastPayment =
  (userId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getLastPaymentPending());
      const response = await axiosService.get(
        `${paymentService}/subscriptions/${userId}/lastPayment`
      );
      dispatch(getLastPaymentFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.getLastPaymentException"))
        )
      );
      //@ts-ignore
      dispatch(getLastPaymentRejected(e.message));
    }
  };
