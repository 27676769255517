import React from "react";
import { GameModeType } from "../enums/GameModeType";
import { GameTypeType } from "../enums/GameTypeType";
import GameType from "../interfaces/game/GameType";

export interface DefaultModalParams {
  type: "warning" | "success";
  header: string;
  text: string;
  submitLabel?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export interface MainContextType {
  organizationPanelTab: string | null;
  setOrganizationPanelTab: (value: string | null) => void;
  defaultModalParams: DefaultModalParams | null;
  showDefaultModal: (value: DefaultModalParams | null) => void;
  filtersGameMode: GameModeType;
  setFiltersGameMode: (value: GameModeType) => void;
  filtersGameType: GameTypeType;
  setFiltersGameType: (value: GameTypeType) => void;
  filtersSelectedGame: string;
  setFiltersSelectedGame: (value: string) => void;
  accessibilityContrast: boolean;
  setAccessibilityContrast: (value: boolean) => void;
  accessibilityFontSize: boolean;
  setAccessibilityFontSize: (value: boolean) => void;
}

export const MainContext = React.createContext<MainContextType>({
  organizationPanelTab: null,
  setOrganizationPanelTab: () => {},
  defaultModalParams: null,
  showDefaultModal: () => {},
  filtersGameMode: GameModeType.LEARN,
  setFiltersGameMode: () => {},
  filtersGameType: GameTypeType.PRIMARY,
  setFiltersGameType: () => {},
  filtersSelectedGame: "",
  setFiltersSelectedGame: () => {},
  accessibilityContrast: false,
  setAccessibilityContrast: () => {},
  accessibilityFontSize: false,
  setAccessibilityFontSize: () => {},
});

export default MainContext;
