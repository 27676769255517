const html = `<h3 style="text-align: center;"><strong>CASE STUDY PROJEKTU NR 2&nbsp;</strong></h3>
<p><strong>Tytuł projektu: Automatyzacja procesu księgowania faktur</strong></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Pracochłonny i jednocześnie powtarzalny proces przepisywania danych z faktur do odpowiednich system&oacute;w.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegała na zautomatyzowaniu procesu przepisywania danych &ndash; tj. numer faktury, dane kontrahent&oacute;w, pozycje na fakturze, kwoty, termin płatności, numer konta, do systemu CRM lub ERP. Wdrożono dwa roboty. Pierwszy robot raz na godzinę sprawdza skrzynkę odbiorczą (skrzynka na kt&oacute;ra przesyłane są faktury), jeśli przyszły nowe faktury, robot uruchamia drugiego robota. Drugi robot przepisuje odpowiednie dane do odpowiednich system&oacute;w, zgodnie ze wskazaniami procesu biznesowego.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Odciążenie pracownik&oacute;w w wykonywaniu powtarzalnych czynności, usprawnienie procesu dzięki regularnemu sprawdzaniu skrzynki mailowej, wyeliminowanie błęd&oacute;w w procesie przepisywania danych z faktur.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">System mailingowy, systemy CRM i ERP, narzędzie RPA z wbudowaną technologią OCR (optical character recognition &ndash; technologia pozwalająca na odczyt danych ze skan&oacute;w i zdjęć).</span></p>
<p><strong>Czas realizacji projektu: </strong><span style="font-weight: 400;">5 tygodni</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">20 tys. zł (development) + koszt zakupu laptopa (laptop wykorzystywany jest wyłącznie przez robota, robot jest zalogowany na koncie użytkownika licencji) + 1,3 tys EUR rocznie (licencje na oprogramowanie do zarządzania robotami/uruchamiania proces&oacute;w) + 3,3 tys. EUR rocznie (licencja deweloperska, umożliwiająca programowanie robot&oacute;w)&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">2 Developer&oacute;w RPA (jeden pełni funkcję dewelopera, drugi architekta &ndash; tj. przeprowadza analizę procesu z klientem, przygotowuje dokumentację).</span></p>`;

export default html;
