const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td style="text-align: center;">
<h3><strong>Ustalenia będące efektem audytu zasob&oacute;w marketingowych</strong></h3>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Materiały video klienta budziły pozytywne reakcje ze względu na dow&oacute;d skuteczności (dzieci czytają samodzielnie) oraz nagraną pozytywną reakcję dzieci na humor. Materiały były publikowane wyłącznie na www i FB.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Profile w mediach społecznościowych są kompletne i prawidłowo skonfigurowane.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Moderacja treści jest na niskim poziomie.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
