import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import GroupReducer from "../interfaces/group/GroupReducer";
import GroupType from "../interfaces/group/GroupType";
import GroupUser from "../interfaces/group/GroupUser";

const initialState: GroupReducer = {
  isFetching: false,
  isFetchingGroups: false,
  isFetchingUsers: false,
  error: null,
  groups: [],
  users: [],
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    getGroupsPending(state) {
      state.isFetchingGroups = true;
      state.error = "";
    },
    getGroupsFulfilled(state, action: PayloadAction<GroupType[]>) {
      state.isFetchingGroups = false;
      state.groups = action.payload;
    },
    getGroupsRejected(state, action: PayloadAction<string>) {
      state.isFetchingGroups = false;
      state.error = action.payload;
    },
    getGroupUsersPending(state) {
      state.isFetchingUsers = true;
      state.error = "";
    },
    getGroupUsersFulfilled(state, action: PayloadAction<GroupUser[]>) {
      state.isFetchingUsers = false;
      state.users = action.payload;
    },
    getGroupUsersRejected(state, action: PayloadAction<string>) {
      state.isFetchingUsers = false;
      state.error = action.payload;
    },
    deleteGroupPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    deleteGroupFulfilled(state) {
      state.isFetching = false;
    },
    deleteGroupRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    addGroupPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    addGroupFulfilled(state) {
      state.isFetching = false;
    },
    addGroupRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    deleteUserPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    deleteUserFulfilled(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    deleteUserRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    sendGroupReportMailPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    sendGroupReportMailFulfilled(state) {
      state.isFetching = false;
    },
    sendGroupReportMailRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
  },
});

export const {
  getGroupUsersFulfilled,
  getGroupUsersPending,
  getGroupUsersRejected,
  getGroupsFulfilled,
  getGroupsPending,
  getGroupsRejected,
  deleteGroupFulfilled,
  deleteGroupPending,
  deleteGroupRejected,
  addGroupFulfilled,
  addGroupPending,
  addGroupRejected,
  deleteUserFulfilled,
  deleteUserPending,
  deleteUserRejected,
  sendGroupReportMailFulfilled,
  sendGroupReportMailPending,
  sendGroupReportMailRejected,
} = groupSlice.actions;

export default groupSlice.reducer;
