import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../context/MainContext";

interface Props {
  tabs: string[];
}

const SlimTabsComponent = (props: Props) => {
  const { t } = useTranslation();
  const { tabs } = props;
  const mainContext = useContext(MainContext);
  const { organizationPanelTab, setOrganizationPanelTab } = mainContext;

  useEffect(() => {
    if (tabs && tabs.length > 0 && !organizationPanelTab)
      setOrganizationPanelTab(tabs[0]);
  }, [tabs, setOrganizationPanelTab, organizationPanelTab]);

  return (
    <div className="slimTabsComponent">
      {tabs.map((tab) => (
        <div
          key={tab}
          onClick={() => setOrganizationPanelTab(tab)}
          className={`tab ${organizationPanelTab === tab ? "selected" : ""}`}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default SlimTabsComponent;
