import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  score: number;
}

const StyledLinearProgress = (props: Props) => {
  const { t } = useTranslation();
  const { score } = props;

  return (
    <div className="styledLinearProgress">
      <LinearProgress
        className="progressBar"
        variant="determinate"
        value={score}
      />
      <span>{`${score}%`}</span>
    </div>
  );
};

export default StyledLinearProgress;
