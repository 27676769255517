const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<h3><strong>Przebieg spotkania kończącego realizację projektu z obszaru marketingu cyfrowego&nbsp;</strong><strong>(wykaz poruszanych zagadnień)</strong></h3>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Nasze osiągnięcia&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">1.CELE SPRZEDAŻOWE - Z NADWYŻKĄ</span></p>
<p><span style="font-weight: 400;">2.MEDIA i NARZĘDZIA GOTOWE DO PRACY</span></p>
<p><span style="font-weight: 400;">3.PROCEDURY i DOBRE PRAKTYKI</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">DOBRE PRAKTYKI DALSZEGO KORZYSTANIA Z WDROŻONYCH NARZĘDZI: GOOGLE ANALYTICS</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">DOBRE PRAKTYKI DALSZEGO KORZYSTANIA Z WDROŻONYCH NARZĘDZI: NARZĘDZIA DO MONITORINGU INTERNETU</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">DOBRE PRAKTYKI DALSZEGO KORZYSTANIA Z WDROŻONYCH NARZĘDZI: MENEDŻER FIRMY I USTAWIENIA FIRMOWE NA PLATFORMACH SPOŁECZNOŚCIOWYCH&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
