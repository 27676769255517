const html = `<h2><strong>Modelowy harmonogram projektu w zakresie tworzenia modelu ML/AI</strong></h2>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<p><strong>Zadanie/etap prac (w odpowiedniej kolejności)&nbsp;</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia realizacji zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zadania (tyg)</strong></p>
</td>
<td>
<p><strong>Osoby odpowiedzialne/realizujące(zesp&oacute;ł)</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Warsztaty z klientem mające na celu identyfikację szczeg&oacute;łowych potrzeb&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1-2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">PM, Data Scientist, Data Engineer, Tech Lead</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokument z opisem szczeg&oacute;łowych potrzeb klienta, wysokopoziomowy backlog rozwiązania.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Proof of Value&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Data Scientist, ML Engineer PM, , Data Engineer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pierwsza wersja modelu ML/AI, analiza wykonalności (ocena modelu zestawiona z celami biznesowymi).</span></p>
<br />
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Na tym etapie klient może wycofać się z dalszej realizacji projektu</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Aplikacja :Uszczeg&oacute;łowienie wymagań w zakresie funkcjonalności i interface&rsquo;u użytkownika aplikacji&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1-3</span></p>
<p><span style="font-weight: 400;">W zależności od skomplikowania aplikacji i ilości person&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">PM, Data Scientist, Frontend dev , Tech Lead&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokument z opisem szczeg&oacute;łowych potrzeb klienta, wireframe (mockup), backlog prac.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zbieranie danych na potrzeby trenowania modeli ML/AI i planowanie architektury&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2-4&nbsp;</span></p>
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Czas trwania etapu ten może się rozciągnąć, gdy okaże się, że dane w systemach klienta są trudno dostępne, niskiej jakości, wymagają skomplikowanych przekształceń.&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Data Engineer, Data Scientist, PM, ML Engineer,&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Widoki bazodanowe [kod SQL definiujący widoki kt&oacute;re będą wykorzystywane przez model ML/AI], przekształcenia danych [m.in. kod SQL lub Python opisujący spos&oacute;b przekształcenia danych na potrzeby modelu], dokument definiujący architekturę (diagramy modlu danych oraz architektury modelu ML/AI, propozycja architektury produkcyjnej opisująca spos&oacute;b uruchomienia modelu na infrastrukturze klienta).</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Budowa modelu ML/AI i trenowanie modelu&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2-4&nbsp;</span></p>
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Długość etapu zależy od stopnia skomplikowania modelu i wymagań na moc obliczeniową samego treningu (rodzaj modelu ML/DL)&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">ML Engineer, Data Scientist , Data Engineer, PM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pierwsza wersja wytrenowanego modelu wdrożona na infrastrukturze klienta. Możliwość uruchomienia pierwszych predykcji.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Aplikacja&nbsp;</strong></p>
<p><span style="font-weight: 400;">Budowa backendu na potrzeby aplikacji</span></p>
</td>
<td>
<p><span style="font-weight: 400;">7</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4-10</span></p>
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Długość etapu zależy od stopnia skomplikowania rozwiązania&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Backend Developer, Data Engineer, ML Engineer, PM, Tester</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Komponenty backendu aplikacji.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Aplikacja&nbsp;</strong></p>
<p><span style="font-weight: 400;">Budowa frontendu na potrzeby aplikacji</span></p>
</td>
<td>
<p><span style="font-weight: 400;">9</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4-10</span></p>
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Długość etapu zależy od stopnia skomplikowania rozwiązania</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Frontend Developer, PM, Tester</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pierwsza działająca wersja aplikacji. Narzędzie wspierające zbieranie feedbacku od użytkownik&oacute;w biznesowych na potrzeby cykli optymalizacji</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Cykle optymalizacji modelu ML/AI (DL)</span></p>
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Ilość cykli może zależeć od budżetu klienta. Z reguły rekomenduje się 3 cykle optymalizacji.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">12</span></p>
</td>
<td>
<p><span style="font-weight: 400;">3-9</span></p>
<br />
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Długość jednego cyklu trwa ok 1-3 tyg., z zależności od dostępności użytkownik&oacute;w biznesowych z kt&oacute;rymi chcemy przedyskutować wyniki uzyskiwane przez model&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Data Scientist, ML Engineer, Data Engineer, PM</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dob&oacute;r zoptymalizowanych parametr&oacute;w modelu. Zoptymalizowana metoda selekcji danych traningowych. Propozycje zmiany architektury modelu (opcjonalnie).&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Testy rozwiązania z użytkownikami końcowymi (testy akceptacyjne)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">21</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2-3</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Tester, PM, Data Scientist, ML Engineer, Data Engineer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raporty z test&oacute;w&nbsp;</span></p>
<p><span style="font-weight: 400;">Wsparcie użytkownik&oacute;w.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie produkcyjne&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">23</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2-4</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Data Engineer, ML Engineer, PM Data Scientist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Poprawa błęd&oacute;w wynikających z test&oacute;w akceptacyjnych. Zoptymalizowana wersja modelu i/lub aplikacji wdrożona na infrastrukturze klienta&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Faza stabilizacji&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">25</span></p>
</td>
<td>
<p><span style="font-weight: 400;">4-12&nbsp;</span></p>
<p><strong>Uwaga!</strong><span style="font-weight: 400;"> Długość etapu zależy od charakteru danych i długości procesu biznesowego&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">PM, Data Engineer, ML Engineer, Data Scientist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Identyfikacja anomalii lub problem&oacute;w systemowych, często wynikających ze sposobu wprowadzania danych przez pracownik&oacute;w.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Utrzymanie i rozw&oacute;j&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">32</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Z zależności od indywidualnych ustaleń z klientem&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">PM/Success Manager, Data Engineer, ML Engineer, Data Scientist</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zapewnienie stabilności działania systemu. Eliminowanie błęd&oacute;w. Utrzymanie cyklu życia modelu. Dostosowanie modelu do zmian z procesach biznesowych. Aktualizacja oprogramowania i bibliotek.&nbsp;&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
