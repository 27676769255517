const html = `<h3 style="text-align: center;"><strong>Wykaz stanowisk w zespole IoT wraz z zakresami obowiązk&oacute;w i CV</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Stanowisko</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Architekt sieciowy</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Projekt sieci wewnętrznej (internetowej)wraz z końc&oacute;wkami (ze sprzętem końcowym &ndash; rutery, switche, repeater &ndash;powtarzacz sygnału). Zaprojektowanie reguł bezpieczeństwa w zakresie działania sieci</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Architekt chmurowy (cloud)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wykonanie projektu architektury rozwiązania</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Cloud Engineer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programowanie rozwiązania</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Tester automatyczny</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Testowanie rozwiązania</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">DevOps</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Konfiguracja i optymalizacja zasob&oacute;w chmurowych</span></p>
</td>
</tr>
</tbody>
</table>
<h3 style="text-align: center;"><br /><br /></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Stanowisko: Architekt sieciowy&nbsp;&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2021 &ndash; obecnie &ndash; Architekt sieciowy</span></p>
<p><span style="font-weight: 400;">2016-2021 &ndash; Administrator sieci komputerowych w instytucji publicznej</span></p>
<p><span style="font-weight: 400;">2013-2016 &ndash; Administrator sieci komputerowej w małej firmie produkcyjnej</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">ANSI C &ndash; 3 (programowanie niskopoziomowe)****</span></p>
<p><span style="font-weight: 400;">Python**</span></p>
<p><span style="font-weight: 400;">Linux, Ubuntu, Debian (znajomość gł&oacute;wnych system&oacute;w operacyjnych open source)****</span></p>
<p><span style="font-weight: 400;">Creately (narzędzie do projektowania architektury)***</span></p>
<p><span style="font-weight: 400;">Visual Studio Code (IDE)***</span></p>
<p><span style="font-weight: 400;">Wireshark (monitorowanie ruchu sieciowego)****</span></p>
<p><span style="font-weight: 400;">Network notepad (narzędzie do projektowania infrastruktury sieciowej)***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Certyfikaty wystawiane przez gł&oacute;wnych dostawc&oacute;w sprzętu sieciowego</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość modelu ISO-OSI</span></p>
<p><span style="font-weight: 400;">Wiedza o protokołach sieciowych (http, websocket, arp)</span></p>
<p><span style="font-weight: 400;">Narzędzia do monitorowania ruchu sieciowego (Wireshark)</span></p>
<p><span style="font-weight: 400;">Znajomość zasad (pryncypi&oacute;w) dobrego projektowania sieci (tz. well architecture framework w obszarze sieci)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span></p>
<p><span style="font-weight: 400;">Znajomość gł&oacute;wnych symulator&oacute;w urządzeń ,</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inż. mechatroniki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Logiczne myślenie, komunikatywność</span></p>
</td>
</tr>
</tbody>
</table>
<h3 style="text-align: center;">&nbsp;</h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Architekt chmurowy&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2018- obecnia &ndash; Architekt chmurowy</span></p>
<p><span style="font-weight: 400;">2018-2016 &ndash; St. Inż chmury</span></p>
<p><span style="font-weight: 400;">2016-2014 &ndash; Inż chmury</span></p>
<p><span style="font-weight: 400;">2010-2014 &ndash;Dev chmury</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">MS Azure *****</span></p>
<p><span style="font-weight: 400;">Security ***</span></p>
<p><span style="font-weight: 400;">Sieci komputerowe/Networking**</span></p>
<p><span style="font-weight: 400;">Bazy danych***</span></p>
<p><span style="font-weight: 400;">Swagger**</span></p>
<p><span style="font-weight: 400;">MS Architect/Draw.io****</span></p>
<p><span style="font-weight: 400;">Git*****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">●&nbsp; &nbsp; &nbsp; AZ 303</span></p>
<p><span style="font-weight: 400;">●&nbsp; &nbsp; &nbsp; AZ 304</span></p>
<p><span style="font-weight: 400;">●&nbsp; &nbsp; &nbsp; Scrum Master?</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">IoT ****</span></p>
<p><span style="font-weight: 400;">Hardware/edge***</span></p>
<p><span style="font-weight: 400;">Wzorce projektowe**</span></p>
<p><span style="font-weight: 400;">API***</span></p>
<p><span style="font-weight: 400;">Git***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr inż. informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie/</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">komunikatywność, umiejętność przekazywania wiedzy technicznej osobom nietechnicznym , negocjowanie, praca w zespole</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<h3 style="text-align: center;">&nbsp;</h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Cloud Engineer</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2018-2020 &ndash;Junior programista&nbsp; JS/Python</span></p>
<p><span style="font-weight: 400;">2020- Programista JS/Node.JS</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">C#**</span></p>
<p><span style="font-weight: 400;">JS***</span></p>
<p><span style="font-weight: 400;">Node.JS**** / 2 mniej</span></p>
<p><span style="font-weight: 400;">MS Azure *</span></p>
<p><span style="font-weight: 400;">Docker***</span></p>
<p><span style="font-weight: 400;">Kubernetes/Container Instances /Containerized Apps**</span></p>
<p><span style="font-weight: 400;">Git****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">●&nbsp; &nbsp; &nbsp; AZ 900</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Wzorce projektowe, architektura mikroserwis&oacute;w, messaging , konteneryzacja/wirtualizacja ,debugowanie , logowanie, Git flow</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inż. mechatroniki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność pracy w zespole, umiejętność przyjmowania krytyki, szybkie uczenie&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>Tester automatyczny</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2020-obecnia &ndash; Tester automatyczny</span></p>
<p><span style="font-weight: 400;">2018-2020 &ndash; Tester manualny</span></p>
<p><span style="font-weight: 400;">2016-2018 &ndash; Junior tester&nbsp;</span></p>
<p><span style="font-weight: 400;">2010-2016 &ndash;Manager produkcji</span></p>
<p><span style="font-weight: 400;">2008-2019 &ndash; młodszy kierownik produkcji</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Selenium ***</span></p>
<p><span style="font-weight: 400;">Node.JS**</span></p>
<p><span style="font-weight: 400;">JS**</span></p>
<p><span style="font-weight: 400;">Azure Function*</span></p>
<p><span style="font-weight: 400;">Docker*</span></p>
<p><span style="font-weight: 400;">Kubernetes/Container Instances /Containerized Apps*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Debugowanie</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Lic. Zarządzanie</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, jasność wyrażania opinii, skrupulatność, dociekliwość, kreatywność</span></p>
</td>
</tr>
</tbody>
</table>
<h3 style="text-align: center;"><br /><br /></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><strong>DevOps Senior</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2021-obecnia &ndash; Senior DevOps</span></p>
<p><span style="font-weight: 400;">2018-2021 - DevOps</span></p>
<p><span style="font-weight: 400;">2013-2018 &ndash; St. Administrator system&oacute;w</span></p>
<p><span style="font-weight: 400;">2010-2013 &ndash;Administrator IT</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Azure DevOps****</span></p>
<p><span style="font-weight: 400;">Jenkins ****</span></p>
<p><span style="font-weight: 400;">Docker***</span></p>
<p><span style="font-weight: 400;">MS Azure ***</span></p>
<p><span style="font-weight: 400;">Batch**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">●&nbsp; &nbsp; &nbsp; AZ 400</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Systemy operacyjne , rozwiązania chmurowe (redundancja, disaster recovery, compliance), sieci komputerowe, metodologia devops, CI/CD, tworzenie dokumentacji technicznej, monitoring i bezpieczeństwo, IAAC (infrastructure as a code)</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inż. informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Umiejętność jasnego wyrażania myśli, organizacja czasu</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
