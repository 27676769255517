const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="3">
<h3><strong>Technologie i narzędzia wykorzystane do wytworzenia rozwiązania </strong><span style="font-weight: 400;">&nbsp;</span><strong>biznesowego z obszaru</strong> <strong>Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Technologia&nbsp;</strong></p>
</td>
<td>
<p><strong>Funkcja&nbsp;</strong></p>
</td>
<td>
<p><strong>Kompetencje</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;"> Narzędzie ETL</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> realizację proces&oacute;w pobrania danych ze źr&oacute;deł, transformacji danych i załadowania do centralnej bazy danych.&nbsp;</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wdrażanie proces&oacute;w ETL</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość narzędzi ETL&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Cloud storage na potrzeby data lake</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> składowanie danych nieustrukturyzowanych, w formie obiekt&oacute;w (object storage).</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">obsługa SQL Server Management Studio</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość język&oacute;w&nbsp; SQL, DAX, MDX, ASSL, XMLA.</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p>USŁUGA CHMUROWA WSPIERAJĄCA ANALIZY DANYCH ZGROMADZONYCH NA PLATFORMIE BIG DATA</p>
</td>
<td>
<p><strong>Umożliwia</strong> <span style="font-weight: 400;">prowadzenie analiz dużych ilości danych i </span><span style="font-weight: 400;">efektywne przetwarzanie ogromnych ilości danych przy użyciu najpopularniejszych platform open source</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Python, R, Sacla, SQL</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Scheduler (job scheduler)</span></p>
</td>
<td>
<p><strong>Umożliwia </strong><span style="font-weight: 400;">kontrolowanie nienadzorowanego wykonywania zadań przez programy działające w tle</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">obsługa konfiguratora danej aplikacji&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">SQL, Python</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
