const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<h3><strong>Korzyści biznesowe związane z zastosowaniem w organizacji rozwiązania opartego na technologii Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Obszar działalności klienta</strong></p>
</td>
<td>
<p><strong>Wpływ wdrożenia rozwiązania na dany obszar</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Zatrudnienie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozwiązanie pozwoliło na zatrudnienie nowych Data Scientis&oacute;w i zoptymalizowanie ich pracy. Obecnie nie muszą oni zajmować się kopiowaniem, pozyskiwaniem danych a mogą skupić się na pracach analitycznych.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Przychody/zyski</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Wnioski z analiz danych pozwoliły na podejmowanie efektywniejszych decyzji biznesowych. Stworzono też nowy kanał przychod&oacute;w, polegający na sprzedaży danych i raport&oacute;w zewnętrznym podmiotom.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Marketing /pozyskanie nowych klient&oacute;w</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Zoptymalizowano procesy sprzedażowe w oparciu o dostępne dane, umożliwiające wskazanie do kt&oacute;rych klient&oacute;w warto skierować komunikaty marketingowe.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Koszty /struktura koszt&oacute;w</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Roczny koszt utrzymania platformy jest wysoki i wynosi nawet kilkaset tysięcy złotych rocznie. Są to wydatki mające charakter operacyjny (OPEX), nie inwestycyjny, co umożliwia kontrolę wydatk&oacute;w. Jesteśmy zadowoleni z tego modelu. Staramy się też optymalizować na bieżąco koszty chmury obliczeniowej, co możliwe jest dzięki analizie sposobu wykorzystywania danych.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Strategia rozwoju</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Platforma Big Data umożliwiała wprowadzenie automatyzacji proces&oacute;w za pomocą rozwiązań opartych na ML.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Og&oacute;lna ocena wsp&oacute;łpracy z wykonawcą /obszary do poprawy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Osiągnęliśmy razem oczekiwane efekty biznesowe, więc wsp&oacute;łpracę oceniam pozytywnie.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
