const html = `<table border="1" cellpadding="5">
<tbody>
<tr style="height: 41px;">
<td style="height: 41px;" colspan="2">
<h3 style="text-align: center;"><strong>Przykłady opis&oacute;w wybranych element&oacute;w strategii marketingowej&nbsp;</strong></h3>
</td>
</tr>
<tr style="height: 61.7188px;">
<td style="height: 61.7188px;">
<p><strong>Grupa docelowa</strong></p>
</td>
<td style="height: 61.7188px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Pracownicy HR i marketingu sklep&oacute;w wielkopowierzchniowych zatrudniających powyżej 100 pracownik&oacute;w. Miasta powyżej 50k mieszkańc&oacute;w.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Pracownicy HR i marketingu magazyn&oacute;w/firm oferujących powierzchnie magazynowe, zatrudniających powyżej 100 pracownik&oacute;w. Firmy zlokalizowane na obrzeżach miast i poza ich granicami.</span></li>
</ul>
</td>
</tr>
<tr style="height: 61px;">
<td style="height: 61px;">
<p><strong>Cel i wskaźnik</strong></p>
</td>
<td style="height: 61px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Zwiększenie bazy zarejestrowanych klient&oacute;w z potencjałem do upsellingu. Wskaźnik: kompletne instalacje aplikacji. Cel: 7000 instalacji w ciągu 6 miesięcy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Pozyskanie stałych źr&oacute;deł przychod&oacute;w projektu. Wskaźnik: płatne, roczne subskrypcje. Cel: 1000 płatnych kont w trakcie 6 miesięcy</span></li>
</ul>
</td>
</tr>
<tr style="height: 61px;">
<td style="height: 61px;"><br />
<p><strong>Unique Selling Proposition</strong></p>
</td>
<td style="height: 61px;">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Aplikacja do onboardingu zaprojektowana przez najlepszych projektant&oacute;w. Design nagrodzony na Festiwalu Artystycznym w Genui.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Aplikacja mobilna do wdrożenia, szkolenia i monitoringu pracownik&oacute;w na stanowisku sprzedawca-kasjer. Wsp&oacute;łpracujemy z 40 producentami stanowisk kasowych.</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
