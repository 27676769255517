const html = `<h3 style="text-align: center;"><strong>Analiza SWOT zastosowania blockchain</strong></h3>
<p><strong>Mocne strony:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Rozproszenie danych pomiędzy wieloma węzłami oraz struktura ich zapisu gwarantuje niezaprzeczalność oraz brak możliwości modyfikacji, dzięki czemu zaufanie może zostać przeniesione na warstwę technologii, bez potrzeby korzystania z usług zaufanej strony trzeciej.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Spos&oacute;b działania protokoł&oacute;w blockchain zapewnia automatyczną synchronizację danych pomiędzy węzłami na zasadzie peer-to-peer, dzięki czemu każdy nowy uczestnik dołączający do sieci nie musi integrować się z każdym uczestnikiem osobno.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dane zapisane w sieci blockchain przechowywane są w strukturze blok&oacute;w i wszystkie transakcje od początku istnienia sieci są widoczne dla każdego z uczestnik&oacute;w. Zapewnia to transparentność, kt&oacute;rej nie gwarantuje żadna inna technologia.</span></li>
</ul>
<p><strong>Słabe strony:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Rozwiązania bazujące na technologii blockchain często mogą działać wolniej niż w przypadku wykorzystania centralnej bazy danych, ze względu na wydłużony czas zapisu spowodowany rozproszeniem sieci i koniecznością osiągnięcia konsensusu przed utworzeniem nowego bloku.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Do optymalnego działania sieć blockchain wymaga istnienia wielu węzł&oacute;w i ciągłej replikacji danych, co w przypadku nieefektywnie zaprojektowanego modelu oraz błędnego zarządzania będzie powodować znaczne wykorzystanie zasob&oacute;w dyskowych bez możliwości usunięcia danych, co wynika ze sposobu funkcjonowania tej technologii.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Rozwiązania oparte o blockchain mają inną architekturę oraz dodatkowe elementy nie wykorzystywane w innych technologiach, wymaga to dodatkowej wiedzy oraz ekspertyzy, a dostęp do specjalist&oacute;w zajmujących się tą dziedziną jest mocno ograniczony, co może powodować dodatkowe koszty związane z wdrożeniem rozwiązań.</span></li>
</ul>
<p><strong>Szanse:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dzięki wykorzystaniu technologii blockchain możliwa jest budowa nowych rozwiązań, kt&oacute;re mogą zapewnić transparentność oraz niepodważalność danych, nieosiągalną przez inne technologie.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Technologia blockchain zapewnia możliwość integracji z wieloma partnerami w ramach sieci bez potrzeby nawiązywania komunikacji i integracji point-to-point, dzięki temu możliwe jest zastąpienie wymiany danych realizowanych obecnie np. za pomocą protokołu EDI.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Technologia blockchain charakteryzuje się możliwością tworzenia i zarządzania natywnymi walutami, kt&oacute;re mogą zostać wykorzystane do budowy systemu finansowego bazującego na CBDC (Central Bank Digital Currency).</span></li>
</ul>
<p><strong>Zagrożenia:</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Błędnie napisany smart contract, może doprowadzić do ataku hakerskiego lub wykorzystania dostępnych funkcji w spos&oacute;b niezgodny z ich przeznaczeniem. Jest to szczeg&oacute;lnie niebezpieczne w przypadku publicznych sieci blockchain, gdzie budowane rozwiązania szczeg&oacute;lnie w obszarze zdecentralizowanych finans&oacute;w, odpowiadają za obr&oacute;t aktywami o wysokiej wartości i mogą doprowadzić do ich całkowitej utraty.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Technologia blockchain wymaga wsp&oacute;łpracy wielu podmiot&oacute;w w ramach procesu biznesowego. Oznacza to, że przypadek użycia i budowane rozwiązanie musi uwzględniać benefity dla każdej ze stron.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dokonanie modyfikacji lub usunięcia raz wprowadzonych danych jest niemożliwe, należy wziąć to pod uwagę w momencie projektowania modelu danych, ponieważ w przypadku wprowadzenia do blockchain danych osobowych lub wrażliwych może skutkować ich udostępnieniem niepowołanym podmiotom, oraz brakiem możliwości usunięcia co jest szczeg&oacute;lnie istotne z punktu widzenia np. regulacji RODO.</span></li>
</ul>`;

export default html;
