const html = `<p style="text-align: justify;">Waterfall to tradycyjne podejście do zarządzania projektami. Jak sama nazwa wskazuje, proces rozwoju musi przebiegać jak wodospad, a wszystkie etapy należy wykonać w ściśle określonej kolejności. &nbsp;Og&oacute;lna lista proces&oacute;w Waterfall obejmuje analizę wymagań, projektowanie, wdrażanie, sprawdzanie poprawności i konserwację oprogramowania. W tej metodzie przeprowadzimy analizę, a po jej zatwierdzeniu pracujemy zgodnie z wytycznymi i kontaktujemy się raz na tydzień z klientem, działamy na zasadzie iteracji.</p>
<p style="text-align: justify;"><strong>Kolejność prac w projektach realizowanych zgodnie z metodą waterfall:</strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Zbieranie i ocena wymagań: (od klient&oacute;w, interesariuszy) o specyfikacjach funkcjonalnych, systemowych lub technicznych.</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Projektowanie: ustala się najważniejsze funkcjonalności produktu lub usługi, r&oacute;wnież w kontekście doświadczeń użytkownika.</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Testowanie: etap test&oacute;w jakościowo-wydajnościowych. Jeżeli produkt spełnia wymagania, uzyskuje akceptację zamawiającego. Jeżeli są błędy zesp&oacute;ł projektowy usuwa je przed dostarczeniem produktu.</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Przekazanie produktu: następuje jeżeli produkt spełnia wszystkie wymagania określone na początku prac projektowych.</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;Utrzymanie / konserwacja: po otrzymaniu finalnego produktu klient może zażądać wprowadzenia w nim dodatkowych zmian</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Czynniki wpływające na wyb&oacute;r metody waterfall w zarządzaniu projektem IT:</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">ustalony i niezmienny budżet projektu&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">mniej doświadczony zesp&oacute;ł projektowy</p>
    </li>
    <li>
        <p style="text-align: justify;">jasno i precyzyjnie określone wymagania klienta w zakresie produktu końcowego</p>
    </li>
    <li>
        <p style="text-align: justify;">ograniczona dostępność klienta (klient nie chce przeznaczyć dużo czasu na konsultacje/dyskusje /warsztaty)&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">mała tolerancja ryzyka przez klienta&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">proste projekty o niewielkich wymaganiach&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Mocne strony metody waterfall: &nbsp; &nbsp;</strong></p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;uporządkowane i długie procesy są łatwe w prowadzeniu (niezależnie od wielkości zespołu)</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;większa stabilność zespołu o niewielkim doświadczeniu, dzięki ustalonym cyklom rozwojowym</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;mniej skomplikowany proces wykonawczy i o ograniczonej liczbie zmiennych, ze względu na jasno określoną specyfikację produktu na początkowym etapie planowania</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;łatwiejsze zarządzanie oczekiwaniami i ryzykiem, ponieważ projekt jest od początku budżetowany i finansowany, co.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Słabe strony metody waterfall:</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">duża formalizacja i sekwencyjność &nbsp;cyklu rozwojowego</p>
    </li>
</ul>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;sekwencyjny charakter powoduje wolniejsze tempo prac i zmniejsza elastyczność</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;określenie i zatwierdzenie wymagań na początku projektu może utrudnić wprowadzenie zmian na p&oacute;źniejszych etapach</p>
<p style="text-align: justify;">●&nbsp; &nbsp;&nbsp;czas trwania projektu jest uzależniony od zakończenia poszczeg&oacute;lnych etap&oacute;w</p>
<p style="text-align: justify;">● &nbsp; &nbsp;usterki wykrywane są zazwyczaj w p&oacute;źniejszych fazach procesu</p>`;

export default html;
