const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 32.9529%;"><strong>Rodzaj spotkania</strong></td>
<td style="width: 32.9529%;"><strong>Osoby uczestniczące</strong></td>
<td style="width: 32.9565%;"><strong>Cykliczność</strong></td>
</tr>
<tr>
<td style="width: 32.9529%;">Statusowe (sprint review)</td>
<td style="width: 32.9529%;">Cały zesp&oacute;ł</td>
<td style="width: 32.9565%;">Co 2 tyg.</td>
</tr>
<tr>
<td style="width: 32.9529%;">Spotkania dotyczące dostępności danych</td>
<td style="width: 32.9529%;">Architekt + Administrator</td>
<td style="width: 32.9565%;">Ad hoc</td>
</tr>
<tr>
<td style="width: 32.9529%;">Spotkania analityczne</td>
<td style="width: 32.9529%;">Cały zesp&oacute;ł</td>
<td style="width: 32.9565%;">Jednorazowa</td>
</tr>
<tr>
<td style="width: 32.9529%;">Daily</td>
<td style="width: 32.9529%;">Zesp&oacute;ł programistyczny</td>
<td style="width: 32.9565%;">Codziennie</td>
</tr>
<tr>
<td style="width: 32.9529%;">Demo</td>
<td style="width: 32.9529%;">Cały zesp&oacute;ł</td>
<td style="width: 32.9565%;">Co miesiąc</td>
</tr>
</tbody>
</table>`;

export default html;
