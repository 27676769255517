const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada wdrożenie aplikacji chmurowych typu SaaS</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;"><br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">pakiet aplikacji chmurowych typu modern workplace umożliwiać powinien edycję tekstu, dostęp do arkuszy kalkulacyjnych, aplikacji umożliwiającej tworzenie prezentacji sprzedażowych</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">pakiet aplikacji chmurowych typu modern workplace umożliwiać powinien prowadzenie telekonferencji (dźwięk, obraz, prezentowanie treści) oraz obsługę firmowej poczty internetowej, zapewniając dużą przestrzeń w skrzynce oraz zwiększony poziom bezpieczeństwa (antyspam z usługą migracji danych z obecnej poczty)</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">pakiet aplikacji chmurowych typu modern workplace umożliwiać powinien dostęp do kalendarza firmowego umożliwiającego śledzenie dostępności pracownik&oacute;w, umawianie/organizowanie spotkań</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">pakiet aplikacji chmurowych typu modern workplace umożliwiać powinien gromadzenie i wsp&oacute;łdzielenie plik&oacute;w, wysłanie linku do dokumentu, automatyczne tworzenie kopii zapasowych</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">dostęp do komunikatora internetowego (chat firmowy)</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wdrożenie aplikacji finansowo-księgowej wraz z migracją danych finansowych do nowego system</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wdrożenie aplikacji finansowo-księgowej i migracja danych&nbsp; ze starych system&oacute;w w okresie do 6 miesięcy</span></li>
</ul>
<br />
<ul style="margin-top: 0; margin-bottom: 10; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">roczna subskrypcja licencji na pakiet aplikacji chmurowych (modern workplace) z kluczami dostępu wybranej grupie&nbsp; użytkownik&oacute;w</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
