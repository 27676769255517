import { Dispatch } from "@reduxjs/toolkit";
import { history } from "..";
import i18n from "../i18n/i18n";
import ForgotPasswordRequest from "../interfaces/user/ForgotPasswordRequest";
import RegisterUserRequest from "../interfaces/user/RegisterUserRequest";
import ResetPasswordRequest from "../interfaces/user/ResetPasswordRequest";
import TokenRequest from "../interfaces/user/TokenRequest";
import TokenResponse from "../interfaces/user/TokenResponse";
import { setErrorMessage, setInfoMessage } from "../reducers/message.reducer";
import {
  addOrEditDigitalProfileFulfilled,
  addOrEditDigitalProfilePending,
  addOrEditDigitalProfileRejected,
  forgotPasswordFulfilled,
  forgotPasswordPending,
  forgotPasswordRejected,
  getDigitalProfileFulfilled,
  getDigitalProfilePending,
  getDigitalProfileRejected,
  getEducatorFulfilled,
  getEducatorPending,
  getEducatorRejected,
  getNumberOfActiveLicencesFulfilled,
  getNumberOfActiveLicencesPending,
  getNumberOfActiveLicencesRejected,
  getUserFulfilled,
  getUserPending,
  getUserRejected,
  loginUserFulfilled,
  loginUserPending,
  loginUserRejected,
  registerUserFulfilled,
  registerUserPending,
  registerUserRejected,
  resetPasswordFulfilled,
  resetPasswordPending,
  resetPasswordRejected,
  sendDigitalProfileMailFulfilled,
  sendDigitalProfileMailPending,
  sendDigitalProfileMailRejected,
  sendFAQMailFulfilled,
  sendFAQMailPending,
  setRefreshToken,
  setToken,
  updateUserFulfilled,
  updateUserPending,
  updateUserRejected,
} from "../reducers/user.reducer";
import { store } from "../store/store";
import { errorHandler } from "../utils/error.utils";
import {
  axiosService,
  axiosServiceWithoutAuth,
  axiosServiceWithoutAuthFormData,
} from "./axios.service";
import { getGames } from "./game.service";
import { getGroups } from "./group.service";

const oauthService = "/user/oauth";
const usersService = "/user/users";
const mailService = "/mail/mails";

export const loginUser =
  (data: TokenRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(loginUserPending());
      const response = await axiosServiceWithoutAuthFormData.post(
        `${oauthService}/token`,
        data
      );
      dispatch(loginUserFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.loginUserException")))
      );
      //@ts-ignore
      dispatch(loginUserRejected(e.message));
    }
  };

export const refreshTokenRequest = (data: TokenRequest) => {
  return axiosServiceWithoutAuthFormData
    .post(`${oauthService}/token`, data)
    .then((response) => {
      const data: TokenResponse = response.data;
      store.dispatch(setToken(data.access_token));
      store.dispatch(setRefreshToken(data.refresh_token));
      return data.access_token;
    });
};

export const registerUser =
  (data: RegisterUserRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(registerUserPending());
      await axiosServiceWithoutAuth.post(`${usersService}/create`, data);
      dispatch(registerUserFulfilled());
      dispatch(
        setInfoMessage(i18n.t("resetPasswordScreen.registerUserSuccess"))
      );
      history.push("/login");
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.registerUserException")))
      );
      //@ts-ignore
      dispatch(registerUserRejected(e.message));
    }
  };

export const forgotPassword =
  (data: ForgotPasswordRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(forgotPasswordPending());
      await axiosServiceWithoutAuth.post(
        `${usersService}/forgotPassword`,
        data
      );
      dispatch(
        setInfoMessage(i18n.t("resetPasswordScreen.forgotPasswordSuccess"))
      );
      dispatch(forgotPasswordFulfilled());
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.forgotPasswordException"))
        )
      );
      //@ts-ignore
      dispatch(forgotPasswordRejected(e.message));
    }
  };

export const resetPassword =
  (data: ResetPasswordRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(resetPasswordPending());
      await axiosServiceWithoutAuth.post(`${usersService}/resetPassword`, data);
      dispatch(
        setInfoMessage(i18n.t("resetPasswordScreen.resetPasswordSuccess"))
      );
      dispatch(resetPasswordFulfilled());
      history.push("/login");
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.forgotPasswordException"))
        )
      );
      //@ts-ignore
      dispatch(resetPasswordRejected(e.message));
    }
  };

export const activateAcount =
  (id: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(resetPasswordPending());
      await axiosServiceWithoutAuth.post(`${usersService}/activate`, id);
      dispatch(
        setInfoMessage(i18n.t("resetPasswordScreen.activateAcountSuccess"))
      );
      dispatch(resetPasswordFulfilled());
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.activateAcountException"))
        )
      );
      //@ts-ignore
      dispatch(resetPasswordRejected(e.message));
    }
  };

export const getUser = (userId: string) => async (dispatch: Dispatch | any) => {
  try {
    dispatch(getUserPending());
    const response = await axiosService.get(`${usersService}/${userId}`);
    dispatch(getUserFulfilled(response.data));
  } catch (e) {
    dispatch(
      setErrorMessage(errorHandler(e, i18n.t("error.getUserException")))
    );
    //@ts-ignore
    dispatch(getUserRejected(e.message));
  }
};

export const getNumberOfActiveLicences =
  () => async (dispatch: Dispatch | any) => {
    try {
      const { userId } = store.getState().userReducer;
      dispatch(getNumberOfActiveLicencesPending());
      const response = await axiosService.get(
        `${usersService}/getNumberOfActiveLicences/${userId}`
      );
      dispatch(getNumberOfActiveLicencesFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.getNumberOfActiveLicencesException"))
        )
      );
      //@ts-ignore
      dispatch(getNumberOfActiveLicencesRejected(e.message));
    }
  };

export const sendFAQMail =
  (mail: string) => async (dispatch: Dispatch | any) => {
    try {
      const { user } = store.getState().userReducer;
      dispatch(sendFAQMailPending());
      await axiosService.post(`${mailService}/sendFAQMail`, {
        id: user!.id,
        language: user!.language,
        from: user!.email,
        content: mail,
      });
      dispatch(sendFAQMailFulfilled());
      dispatch(setInfoMessage(i18n.t("global.sendFAQMailSuccess")));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.sendFAQMailException")))
      );
      //@ts-ignore
      dispatch(sendFAQMailFulfilled(e.message));
    }
  };

export const updateUser = (user: any) => async (dispatch: Dispatch | any) => {
  try {
    dispatch(updateUserPending(user));
    const response = await axiosService.put(`${usersService}/editUser`, user);
    dispatch(updateUserFulfilled(response.data));
    dispatch(setInfoMessage(i18n.t("global.updateUserSuccess")));
  } catch (e) {
    dispatch(
      setErrorMessage(errorHandler(e, i18n.t("error.updateUserException")))
    );
    //@ts-ignore
    dispatch(updateUserRejected(e.message));
  }
};

export const getUserEducator =
  (userId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getEducatorPending());
      const response = await axiosService.get(
        `${usersService}/educatorByUserId/${userId}`
      );
      dispatch(getEducatorFulfilled(response.data.hasSubscriptionActive));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.getEducatorException")))
      );
      //@ts-ignore
      dispatch(getEducatorRejected(e.message));
    }
  };

export const sendDigitalProfileMail =
  (userId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(sendDigitalProfileMailPending());
      await axiosService.post(
        `${mailService}/sendDigitalProfileMail/${userId}`
      );
      dispatch(sendDigitalProfileMailFulfilled());
      dispatch(setInfoMessage(i18n.t("global.sendDigitalProfileMailSuccess")));
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.sendDigitalProfileMailException"))
        )
      );
      //@ts-ignore
      dispatch(sendDigitalProfileMailRejected(e.message));
    }
  };

export const addOrEditDigitalProfile =
  (data: any) => async (dispatch: Dispatch | any) => {
    try {
      const userId = store.getState().userReducer.userId;
      dispatch(addOrEditDigitalProfilePending());
      await axiosService.put(`${usersService}/addOrEditDigitalProfile`, data);
      dispatch(getGames(false, true));
      dispatch(getUser(userId));
      dispatch(addOrEditDigitalProfileFulfilled());
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.addOrEditDigitalProfileException"))
        )
      );
      //@ts-ignore
      dispatch(addOrEditDigitalProfileRejected(e.message));
    }
  };

export const getDigitalProfile =
  (userId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getDigitalProfilePending());
      const response = await axiosService.get(
        `${usersService}/${userId}/digitalProfile`
      );
      dispatch(getDigitalProfileFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(
          errorHandler(e, i18n.t("error.addOrEditDigitalProfileException"))
        )
      );
      //@ts-ignore
      dispatch(getDigitalProfileRejected(e.message));
    }
  };
