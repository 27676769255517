import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  HeaderComponent: any;
  BodyComponent: any;
}

const Accordion = (props: Props) => {
  const { t } = useTranslation();
  const { HeaderComponent, BodyComponent } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      className={`accordion ${open ? "open" : ""}`}
      style={{ transition: "2s ease-in-out" }}
    >
      <div onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
        {HeaderComponent}
      </div>
      <div className="accordionBody" aria-expanded={!open}>
        {BodyComponent}
      </div>
    </div>
  );
};

export default Accordion;
