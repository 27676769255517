import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getGames } from "../../../services/game.service";
import { AppDispatch, AppState } from "../../../store/store";
import StyledButton from "../../components/StyledButton";
import { accordionHeader } from "../gameScreen/GameScreen";
import Accordion from "../gameScreen/Accordion";
import StyledTextInput from "../../components/StyledTextInput";
import { sendFAQMail } from "../../../services/user.service";
import faqPDF from "../../../constants/pdfs/faq.pdf";

const faq = [
  {
    id: "question_1",
    question:
      "Czy muszę ukończyć wybraną grę aby mój wynik został zapisany w aplikacji?",
    answer:
      "W przypadku, gdy grasz w jedną z 20 gier dostępnych w katalogu, warunkiem zapisania Twojego wyniku jest ukończenie minimum 1 z 5 zadań w ramach danej gry. W czasie gry, w MONITORINGU będziesz miał możliwość sprawdzenia na którym etapie zadania jesteś. Jeśli ukończysz 1 zadanie i zamkniesz grę, w zakładce MOJE GRY w aplikacji zobaczysz tę grę w kategorii ROZPOCZĘTE GRY. Klikając na tę grę, będziesz mógł zobaczyć swoje wyniki z 1 zadania. Możesz w każdej chwili wrócić do kontynuacji gry. W przypadku, gdy grasz w grę stworzoną z wykorzystaniem KONFIGURATORA GRY, warunkiem zapisania Twojego wyniku jest ukończenie całej gry.",
  },
  {
    id: "question_2",
    question: "W jaki sposób mogę sprawdzić moje wyniki po zakończeniu gry?",
    answer:
      "Po zakończeniu gry, zostaniesz przekierowany na ekran główny Aplikacji. Kliknij zakładkę MOJE GRY, a następnie kafelek z nazwą gry, w którą grałeś. Pod opisem gry, wybierz MOJE WYNIKI.",
  },
  {
    id: "question_3",
    question:
      "Do jakich materiałów edukacyjnych uzyskam dostęp po wykupieniu dostępu do platformy Digi Leader?",
    answer:
      "Jeśli korzystasz z konta ORGANIZACJA, po wykupieniu dostępu uzyskasz dostęp do raportów wiedzy, bazy wiedzy i kursów związanych ze wszystkimi 20 grami dostępnymi na platformie. Jeśli korzystasz z konta GRACZ, po wykupiniu dostępu i zagraniu w daną grę (ukończeniu minimum 1 zadania), uzyskujesz dostęp do raportów wiedzy, bazy wiedzy i kursów związanych z wybraną grą. Rozgrywając kolejne gry, odblokowujesz dostęp do materiałów edukacyjnych związanych z daną grą. Po rozegraniu wszystkich gier, uzyskasz dostęp do wszystkich materiałów.",
  },
  {
    id: "question_4",
    question: "Czym różni się wersja płatna i bezpłatna platformy Digi Leader?",
    answer:
      "Wersja bezpłatna (testowa) pozwala (konto GRACZ), na rozegranie jednego zadania w grze testowej oraz ograniczony dostęp do funkcjonalności aplikacji (część funkcji nie jest dostępna na koncie testowym i w aplikacji zobaczysz jej jako zablokowane). Wersja bezpłatna (testowa) pozwala (konto ORGANIZACJA), na poznanie głównych funkcjonalności aplikacji (bez możliwości ich aktywnego użycia). Wersja płatna konta ORGANIZACJA daje dostęp do wszystkich materiałów edukacyjnych. Wersje płatne pozwalają na dostęp do wszystkich funkcjonalności aplikacji, gier dostępnych na platformie oraz materiałów edukacyjnych.",
  },
  {
    id: "question_5",
    question:
      "Ile czasu mogę korzystać z bezpłatnego dostępu do platformy Digi Leader?",
    answer:
      "Bezpłatny dostęp do aplikacji jest bezterminowy. Digi Leader zastrzega jednak sobie prawo do usunięcia konta, zgodnie z zapisami Regulaminu, który należy zaakceptować zakładając konto.",
  },
  {
    id: "question_6",
    question:
      "Czy po zakończeniu okresu na który został wykupiony dostęp do aplikacji, zapisy dotyczące moich wyników i historii rozgrywek zostaną usunięte?",
    answer:
      "W przypadku konta GRACZ, zapisy te nie będą automatycznie usuwane bo wygaśnięciu dostępu do konta (szczegóły zawiera Regulamin). W przypadku kont GRACZ, powiązanego z kontem ORGANIZACJA (gracz otrzymał link aktywacyjny do założenia konta), ORGANIZACJA decyduje o usunięciu lub nie zgromadzonych danych, po wygaśnięciu okresu dostępu. W przypadku konta ORGANIZACJA, zapisy na koncie, również nie są automatycznie usuwane i bo ponownym wykupieniu konta, ORGANIZACJA ma do nich dostęp.",
  },
  {
    id: "question_7",
    question:
      "Czy przed zagraniem w grę wideo dostępną na platformie Digi Leder powinienem przejść jakieś szkolenie, które pomoże mi zrozumieć zasady gry?",
    answer:
      "Przed rozpoczęciem gry zostaniesz zapytany, czy chcesz zapoznać się z samouczkiem. Samouczek zawiera informacje, które ułatwią Ci realizację zadań w grze oraz pokażą dostępne sposoby podejmowania działań. Ponadto warto zapoznać się z Przewodnikiem użytkownika Aplikacji Digi Leader, dostępnym w aplikacji w zakładce POMOC.",
  },
];

const FAQScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [question, setQuestion] = useState<string>("");
  const isSendingMailRef = useRef<boolean>(false);

  // useEffect(() => {
  //   dispatch(getGames(false, false));
  // }, []);

  const isSendingMail: boolean = useSelector(
    (state: AppState) => state.userReducer.isSendingMail
  );

  useEffect(() => {
    if (!isSendingMail && isSendingMailRef.current) {
      setQuestion("");
      isSendingMailRef.current = isSendingMail;
    }
    isSendingMailRef.current = isSendingMail;
  }, [isSendingMail]);

  const renderFAQ = () => {
    return faq.map((question) => (
      <Accordion
        key={question.id}
        HeaderComponent={accordionHeader(question.question)}
        BodyComponent={<div className="bodyWrapper">{question.answer}</div>}
      />
    ));
  };

  return (
    <div className="FAQScreen">
      <div className="componentHeader">{t("FAQScreen.help")}</div>
      <div className="smallInfo">{t("FAQScreen.doYouNeedHelp?")}</div>
      <div className="sectionHeader">{t("FAQScreen.faq")}</div>
      <div className="faqWrapper">{renderFAQ()}</div>
      <div className="sectionHeader capslock">{t("FAQScreen.contactForm")}</div>
      <div className="contactFormWrapper">
        <div className="textLabel">{t("FAQScreen.messageContent")}</div>
        <StyledTextInput
          value={question}
          onChange={setQuestion}
          multiline={true}
          placeholder={t("FAQScreen.typeYourQestion")}
        />
        <StyledButton
          type="contained"
          color="white"
          disabled={!question}
          label={t("FAQScreen.send")}
          onClick={() => dispatch(sendFAQMail(question))}
        />
      </div>
      <div className="sectionHeader capslock">{t("FAQScreen.userGuide")}</div>
      {/* <div className="smallInfo">{t("FAQScreen.userGuideInfo")}</div> */}
      <a
        className="section clickable"
        href={faqPDF}
        download={t("FAQScreen.userGuide")}
      >
        <StyledButton type="contained" label={t("FAQScreen.downloadGuide")} />
      </a>
    </div>
  );
};

export default FAQScreen;
