const html = `<h3 style="text-align: center;"><strong>Matryca RACI projektu z </strong><strong>z </strong><strong>obszaru marketingu cyfrowego</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Zadanie projektowe/Osoba</strong></p>
</td>
<td>
<p><strong>Digital Strategist</strong></p>
</td>
<td>
<p><strong>Lead Designer</strong></p>
</td>
<td>
<p><strong>Social Media Manager</strong></p>
</td>
<td>
<p><strong>Frontend Developer</strong></p>
</td>
<td>
<p><strong>Klient- Brand manager</strong></p>
</td>
<td>
<p><strong>Klient - Creative director</strong></p>
</td>
<td>
<p><strong>Klient - Multimedia Team Leader</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analiza zastanej sytuacji i dostarczonych danych; profilowanie grupy docelowej</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie strategii marki i gł&oacute;wnych założeń komunikacji marki; dob&oacute;r kanał&oacute;w komunikacji w social media</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie koncepcji kreatywnej</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie ramowego planu działań na 6 m-cy</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie rekomendacji narzędzi</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">i</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Konfiguracja wybranych narzędzi</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie content plan&oacute;w</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Projektowanie UI/UX landing page sprzedażowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Projektowanie graficzne landing page sprzedażowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przekazanie zapotrzebowania na treści wideo</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie, testowanie i instalacja na serwerze produkcyjnym landing page sprzedażowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Nadz&oacute;r nad zgodnością realizacji treści wideo z content planem</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Prowadzenie działań komunikacyjnych w social mediach (publikacje, moderacja)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przeprowadzenie kampanii płatnych w social mediach (social ads, zasięgowe, wizerunkowe/sprzedażowe, remarketingowe)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Raportowanie okresowe (milestones x3 w trakcie 6 miesięcy)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Opracowanie raportu końcowego</span></p>
</td>
<td>
<p><span style="font-weight: 400;">RA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">C</span></p>
</td>
<td>
<p><span style="font-weight: 400;">-</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
<td>
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
