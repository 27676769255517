const html = `<p style="text-align: justify;"><strong>Cel projektu:</strong>&nbsp;Zaprojektowanie i wdrożenie narzędzia, kt&oacute;re umożliwi dostęp do danych i generowanie raport&oacute;w&nbsp;</p>
<p style="text-align: justify;"><strong>Czas trwania projektu:</strong>&nbsp;6 miesięcy&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<strong>Budżet projektu:</strong>&nbsp;360 tys. zł</p>
<p style="text-align: justify;"><strong>Zesp&oacute;ł projektowy:</strong>&nbsp;Architekt BI/BD (0,25FTE), Programista ETL (1FTE), Programista BI (1FT)</p>
<p style="text-align: justify;"><strong>Gł&oacute;wny problem klienta</strong></p>
<p style="text-align: justify;">Dotychczasowy system raportowania klienta w całości oparty było o transakcyjną bazę danych systemu ERP. Pobierane dane przetwarzane były ręcznie, często przy użyciu programu MS Excel i Power Point. W związku z rozwojem firmy dotychczasowa metoda tworzenia raport&oacute;w stała się nieefektywna.</p>
<p style="text-align: justify;"><strong>Kr&oacute;tki opis projektu</strong></p>
<p style="text-align: justify;">Projekt polegał na zbudowaniu narzędzi kt&oacute;re umożliwią efektywne raportowanie danych biznesowych. Stworzono hurtownię danych w oparciu o bazę danych Azure SQL. Do pobrania i przetworzenia danych z system&oacute;w źr&oacute;dłowych użyto Azure Data Factory. W aplikacji BI (SaaS) wdrożono modelu analityczny oraz przygotowano warstwy wizualne raport&oacute;w dla użytkownik&oacute;w końcowych.&nbsp;</p>
<p style="text-align: justify;"><strong>Korzyści klienta, osiągnięte w efekcie wdrożenia</strong></p>
<p style="text-align: justify;">Ułatwienie pracy działu kontrolingu i sprzedaży. Przyspieszenie tworzenia raport&oacute;w dzięki ograniczeniu ręcznego przetwarzania danych. Uporządkowanie i zwiększenie jakości danych firmowych dzięki wdrożeniu hurtownia danych, zogranizowanej zgodnie z wymaganiami biznesowymi.&nbsp;</p>
<p style="text-align: justify;"><strong>Narzędzia/systemy/technologie wykorzystane w projekcie</strong></p>
<p style="text-align: justify;">Azure Data Factory, Azure SQL, Power BI</p>`;

export default html;
