import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PaymentReducer from "../interfaces/payment/PaymentReducer";
import PaymentType from "../interfaces/payment/PaymentType";
import ProductType from "../interfaces/payment/ProductType";

const initialState: PaymentReducer = {
  isFetching: false,
  isFetchingProducts: false,
  error: "",
  products: [],
  redirectUrl: "",
  lastPayment: null,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    getProductsPending(state) {
      state.isFetchingProducts = true;
      state.error = "";
    },
    getProductsFulfilled(state, action: PayloadAction<ProductType[]>) {
      state.isFetchingProducts = false;
      state.products = action.payload;
    },
    getProductsRejected(state, action: PayloadAction<string>) {
      state.isFetchingProducts = false;
      state.error = action.payload;
    },
    createSubscriptionPending(state) {
      state.isFetchingProducts = true;
      state.error = "";
    },
    createSubscriptionFulfilled(state, action: PayloadAction<string>) {
      state.isFetchingProducts = false;
      state.redirectUrl = action.payload;
    },
    createSubscriptionRejected(state, action: PayloadAction<string>) {
      state.isFetchingProducts = false;
      state.error = action.payload;
    },
    getLastPaymentPending(state) {
      state.isFetchingProducts = true;
      state.error = "";
    },
    getLastPaymentFulfilled(state, action: PayloadAction<PaymentType>) {
      state.isFetchingProducts = false;
      state.lastPayment = action.payload;
    },
    getLastPaymentRejected(state, action: PayloadAction<string>) {
      state.isFetchingProducts = false;
      state.error = action.payload;
    },
  },
});

export const {
  getProductsFulfilled,
  getProductsPending,
  getProductsRejected,
  createSubscriptionFulfilled,
  createSubscriptionPending,
  createSubscriptionRejected,
  getLastPaymentFulfilled,
  getLastPaymentPending,
  getLastPaymentRejected,
} = paymentSlice.actions;

export default paymentSlice.reducer;
