const html = `<p style="text-align: justify;"><strong>Opis technologii&nbsp;</strong></p>
<p style="text-align: justify;">Aplikacja SaaS do analizy i raportowania danych to usługa w chmurze/oprogramowanie jako usługa (dostępna w tzw. modelu subskrypcyjnym, tj. opłacana co miesiąc/rok), kt&oacute;ra umożliwia zbieranie, przetwarzanie i prezentowanie danych w celu ułatwienia podejmowania decyzji biznesowych. Aplikacja ta umożliwia użytkownikom generowanie r&oacute;żnego rodzaju raport&oacute;w, takich jak raporty finansowe, raporty sprzedażowe, raporty dotyczące wydajności lub raporty dotyczące działań marketingowych.</p>
<p style="text-align: justify;">W aplikacji do raportowania danych można zdefiniować r&oacute;żne źr&oacute;dła danych, takie jak bazy danych, pliki CSV lub API. Po podłączeniu źr&oacute;dła danych do aplikacji, użytkownicy mogą tworzyć r&oacute;żnego rodzaju raporty, definiując filtry i sortowanie danych, a także tworząc wykresy i grafiki, kt&oacute;re ułatwiają analizę danych.</p>
<p style="text-align: justify;">Aplikacja do raportowania danych to narzędzie, kt&oacute;re umożliwia użytkownikom gromadzenie, przetwarzanie i prezentowanie informacji w celu uzyskania jasnego i przejrzystego obrazu danych biznesowych, operacyjnych i innych informacji związanych z działalnością firmy lub organizacji.</p>
<p style="text-align: justify;">Aplikacja do raportowania danych może r&oacute;wnież oferować funkcje takie jak automatyczne planowanie i wysyłanie raport&oacute;w.Tworzenie raport&oacute;w w narzędziu polega na wyklikiwaniu/konfigurowaniu rozwiązania z dostępnych do wyboru opcji.&nbsp;</p>
<p style="text-align: justify;">Aplikacje działające w ramach SaaS, są utrzymywane przez dostawcę, kt&oacute;ry dba o spełnienie wymog&oacute;w dotyczących bezpieczeństwa i zapewnia ciągłość działania usługi.</p>
<p style="text-align: justify;"><strong>Przykłady narzędzi/rozwiązań do zastosowania:</strong> Power BI, Tableu, Qlicksens, Qlikview</p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Obsługa konfiguratora danej aplikacji BI narzędzi do wizualizacji danych, wiedza o rodzajach wykres&oacute;w&nbsp;</p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Gotowe do użycia aplikacje typu SaaS, tzw. pudełkowe, są szybkie we wdrożeniu i spełniają większość wymagań klienta. Z reguły, nie ma potrzeby tworzenia własnego rozwiązania (aplikacji) do tworzenia wykres&oacute;w.</p>`;

export default html;
