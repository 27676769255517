import ai_ml_icon from "../assets/images/icons/ai_ml_icon.svg";
import three_d_icon from "../assets/images/icons/3d_icon.svg";
import bi_icon from "../assets/images/icons/bi_icon.svg";
import blockchain_icon from "../assets/images/icons/blockchain_icon.svg";
import cloud_icon from "../assets/images/icons/cloud_icon.svg";
import e_commerce_icon from "../assets/images/icons/e_commerce_icon.svg";
import iot_icon from "../assets/images/icons/iot_icon.svg";
import rpa_icon from "../assets/images/icons/rpa_icon.svg";
import sm_icon from "../assets/images/icons/sm_icon.svg";
import vr_ar_icon from "../assets/images/icons/vr_ar_icon.svg";

export const defaultCategories = [
  {
    id: "0",
    key: "all",
    categoryName: "All",
    games: [],
  },
];

export const categoriesImages: { [key: string]: string } = {
  ai_ml: ai_ml_icon,
  "3d": three_d_icon,
  bi: bi_icon,
  blockchain: blockchain_icon,
  cloud: cloud_icon,
  e_commerce: e_commerce_icon,
  iot: iot_icon,
  rpa: rpa_icon,
  sm: sm_icon,
  vr_ar: vr_ar_icon,
};

export const industries = [
  "logistics",
  "cosmetics_industry",
  "trade",
  "production_of_packaging",
  "wholesale_trade",
  "electronics",
  "construction",
  "services",
  "automotive",
  "machine_industry",
  "power_engineering",
  "fish_breeding",
  "it_services",
  "publishing",
  "clothing_industry",
  "wood_industry",
  "finance",
  "production",
];

export const reportsShortKeys = [
  {
    key: "1_knowledge_1",
    label:
      "Potrzeby i wymagania biznesowe na które odpowiada technologia AI ML",
  },
  {
    key: "1_skill_1",
    label:
      "Harmonogram realizacji projektu z obszaru wdrożenia technologii AI ML",
  },
  {
    key: "1_skill_2",
    label: "Oferta na realizację usługi z obszaru wdrożenia technologii AI ML",
  },
  {
    key: "2_knowledge_1",
    label:
      "Technologie i narzędzia wykorzystane przy tworzeniu rozwiązań wykorzystujących technologię AI ML",
  },
  {
    key: "2_knowledge_2",
    label:
      "Skład zespołu realizującego projekt z obszaru wdrożenia technologii AI ML",
  },
  {
    key: "2_knowledge_3",
    label:
      "Struktura przebiegu spotkania rozpoczynającego realizację projektu z obszaru AI ML",
  },
  {
    key: "2_skill_1",
    label:
      "Kompetencje wymagane od członków zespołu realizującego projekt z obszaru wdrożenia technologii AI ML",
  },
  {
    key: "2_skill_2",
    label:
      "Informacje, które należy przekazać podczas spotkania rozpoczynającego realizację projektu z obszaru wdrożenia technologii AI ML",
  },
  {
    key: "2_skill_3",
    label: "Matryca RACI projektu z  obszaru AI ML",
  },
  {
    key: "2_skill_4",
    label: "Prace zespołu projektowego realizującego projekt z obszaru AI ML",
  },
  {
    key: "3_knowledge_1",
    label: "Przykłady wymagań biznesowych z obszaru wdrożenia AI ML",
  },
  {
    key: "3_skill_1",
    label:
      "Zadania poszczególnych członków zespołu realizującego projekt z obszaru AI ML",
  },
  {
    key: "3_skill_2",
    label:
      "Zadania szczegółowe zadań ramowych z harmonogramu prac projektu wdrożenia technologii AI ML",
  },
  {
    key: "4_knowledge_1",
    label: "Podstawy prognozowania z wykorzystaniem modeli AI ML",
  },
  {
    key: "4_skill_1",
    label: "Główne miary ewaluacji (oceny jakości) modeli AI ML",
  },
  {
    key: "4_skill_2",
    label: "Główne podejścia do analizy tekstu z użyciem NLP",
  },
  {
    key: "5_knowledge_1",
    label:
      "Tematy które należy poruszyć na spotkaniu kończącym projekt z obszaru AI ML",
  },
  {
    key: "5_knowledge_2",
    label:
      "Usługi cyfrowe umożliwiające pogłębianie transformacji cyfrowej organizacji po  wdrożeniu technologii AI ML",
  },
  {
    key: "5_skill_1",
    label:
      "Niezbędne informacje, które należy przekazać klientowi po wdrożenia rozwiązania wykorzystującego technologię AI ML",
  },
  {
    key: "5_skill_2",
    label:
      "Korzyści biznesowe osiągnięte w efekcie zastosowania technologii AI ML",
  },
];
