import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getInputRules } from "../../../utils/utils";
import StyledCheckbox from "../../components/StyledCheckbox";
import StyledTextInput from "../../components/StyledTextInput";
import StatutePDf from "../../../constants/pdfs/statute.pdf";
import PrivacyPolicyPDf from "../../../constants/pdfs/privacyPolicy.pdf";

interface Props {
  formType: string;
}

const OrderForm = (props: Props) => {
  const { t } = useTranslation();
  const { formType } = props;
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useFormContext();

  const renderSatuteAgreementLabel = () => (
    <div>
      <span className="label">{"Akceptuję "}</span>
      <a
        className="label clickable"
        href={StatutePDf}
        download={t("footer.statute")}
      >
        {"regulamin"}
      </a>
      <span className="label">{" i "}</span>
      <a
        className="label clickable"
        href={PrivacyPolicyPDf}
        download={t("footer.privacyPolicy")}
      >
        {"politykę prywatnóści"}
      </a>
    </div>
  );

  return (
    <form className="orderForm">
      <Controller
        name="email"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.email && (errors.email.message as string)}
            label={t("buyProductScreen.email")}
          />
        )}
        rules={getInputRules(true, 60)}
      />
      <Controller
        name="country"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.country && (errors.country.message as string)}
            label={"Kraj"}
          />
        )}
        rules={getInputRules(true, 32)}
      />
      <Controller
        name="nip"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.nip && (errors.nip.message as string)}
            label={formType === "company" ? "NIP" : "NIP (opcjonalnie)"}
          />
        )}
        rules={getInputRules(formType === "company", 10)}
      />
      <div style={{ display: formType === "company" ? undefined : "none" }}>
        <Controller
          name="companyName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledTextInput
              value={value}
              color={"neutral"}
              onChange={onChange}
              error={
                errors.companyName && (errors.companyName.message as string)
              }
              label={"Nazwa firmy"}
            />
          )}
          rules={getInputRules(formType === "company", 256)}
        />
      </div>
      <Controller
        name="address"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.address && (errors.address.message as string)}
            label={"Ulica i numer"}
          />
        )}
        rules={getInputRules(true, 60)}
      />
      <Controller
        name="city"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.city && (errors.city.message as string)}
            label={"Miasto"}
          />
        )}
        rules={getInputRules(true, 60)}
      />
      <Controller
        name="zipCode"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.zipCode && (errors.zipCode.message as string)}
            label={"Kod pocztowy"}
          />
        )}
        rules={getInputRules(true, 6, 6)}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledTextInput
            value={value}
            color={"neutral"}
            onChange={onChange}
            error={errors.phone && (errors.phone.message as string)}
            label={"Telefon (opcjonalnie)"}
          />
        )}
        rules={getInputRules(false, 13)}
      />
      <div className="sectionHeader">{"Zgody formalne:"}</div>
      <Controller
        name="agreement"
        control={control}
        render={({ field: { value, onChange } }) => (
          <StyledCheckbox
            onChange={onChange}
            value={value}
            error={errors.agreement && (errors.agreement.message as string)}
            label={renderSatuteAgreementLabel()}
          />
        )}
        rules={getInputRules(true)}
      />
    </form>
  );
};

export default OrderForm;
