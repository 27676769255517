const html = `<table style="width: 1027px;" border="1" cellpadding="10">
<tbody>
<tr>
<td style="width: 851px;" colspan="7">
<h3><strong>Matryca RACI projektu z z obszaru Big Data (kick-off meeting)&nbsp;</strong></h3>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><strong>Zadanie&nbsp;</strong></p>
</td>
<td style="width: 56px;">
<p><strong>Architekt </strong><strong><br /><br /></strong></p>
</td>
<td style="width: 25px;">
<p><strong>ETL&nbsp;</strong></p>
</td>
<td style="width: 79px;">
<p><strong>Programista&nbsp;</strong></p>
</td>
<td style="width: 73px;">
<p><strong>Product Owner</strong></p>
</td>
<td style="width: 80.6406px;">
<p><strong>Business Owner</strong></p>
</td>
<td style="width: 71.3594px;">
<p><strong>Data Scientist</strong></p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">Analiza biznesowo-systemowa</span></p>
</td>
<td style="width: 56px;">
<p>R</p>
</td>
<td style="width: 25px;">
<p>I</p>
</td>
<td style="width: 79px;">
<p>I</p>
</td>
<td style="width: 73px;">
<p>C</p>
</td>
<td style="width: 80.6406px;">
<p>A</p>
</td>
<td style="width: 71.3594px;">
<p>C</p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">Utworzenie warstwy ROW (warstwa danych w data lake) i pobranie do niej danych ze źr&oacute;deł&nbsp;</span></p>
</td>
<td style="width: 56px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 25px;">
<p><span style="font-weight: 400;">R</span></p>
</td>
<td style="width: 79px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 73px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 80.6406px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 71.3594px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">Utworzenie warstwy CLEANSED (warstwa analityczna/przejściowa) i pobranie do niej danych w warstwy ROW&nbsp;</span></p>
</td>
<td style="width: 56px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 25px;">
<p><span style="font-weight: 400;">R</span></p>
</td>
<td style="width: 79px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 73px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 80.6406px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 71.3594px;">
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Utworzenie warstwy CURATED (warstwa hurtowni danych/DWH) i pobranie do niej danych z warstwy CLEANSED</span></p>
</td>
<td style="width: 56px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 25px;">
<p><span style="font-weight: 400;">R&nbsp;</span></p>
</td>
<td style="width: 79px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 73px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 80.6406px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 71.3594px;">
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">Budowa narzędzia do automatycznego przetwarzania danych zgodnie z potrzebami i wymaganiami analityk&oacute;w/Data Scientist&oacute;w</span></p>
</td>
<td style="width: 56px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 25px;">
<p><span style="font-weight: 400;">C</span></p>
</td>
<td style="width: 79px;">
<p><span style="font-weight: 400;">R</span></p>
</td>
<td style="width: 73px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 80.6406px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 71.3594px;">
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">Budowa narzędzia do automatycznego odszyfrowywania danych wrażliwych</span></p>
</td>
<td style="width: 56px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 25px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 79px;">
<p><span style="font-weight: 400;">R</span></p>
</td>
<td style="width: 73px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 80.6406px;">
<p><span style="font-weight: 400;">C</span></p>
</td>
<td style="width: 71.3594px;">
<p><span style="font-weight: 400;">C</span></p>
</td>
</tr>
<tr>
<td style="width: 466px;">
<p><span style="font-weight: 400;">Przekazanie produktu klientowi do testowania, naprawa błęd&oacute;w, wdrożenie produkcyjne. Szkolenie</span></p>
</td>
<td style="width: 56px;">
<p><span style="font-weight: 400;">A</span></p>
</td>
<td style="width: 25px;">
<p><span style="font-weight: 400;">R</span></p>
</td>
<td style="width: 79px;">
<p><span style="font-weight: 400;">R</span></p>
</td>
<td style="width: 73px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 80.6406px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
<td style="width: 71.3594px;">
<p><span style="font-weight: 400;">I</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
