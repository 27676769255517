import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../../services/user.service";
import { AppDispatch } from "../../../store/store";
import { getInputRules } from "../../../utils/utils";
import LinkTextButton from "../../components/LinkTextButton";
import StyledButton from "../../components/StyledButton";
import StyledTextInput from "../../components/StyledTextInput";

interface FormValues {
  email: string;
  password: string;
  repeatPassword: string;
}

interface Props {
  resetToken: string;
}

const ResetPasswordForm = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { resetToken } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    if (!resetToken) {
      dispatch(
        forgotPassword({
          email: data.email,
          //@ts-ignore
          language: "en",
          resetLink: "http://localhost:3000/reset-password",
        })
      );
    } else {
      dispatch(
        resetPassword({
          password: data.password,
          resetToken,
        })
      );
    }
  };

  return (
    <form className="preAuthForm">
      {!resetToken ? (
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledTextInput
              value={value}
              onChange={onChange}
              error={errors.email && errors.email.message}
              label={"Email"}
            />
          )}
          rules={getInputRules(true, 60)}
        />
      ) : (
        <>
          <Controller
            name="password"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledTextInput
                value={value}
                type="password"
                onChange={onChange}
                error={errors.password && errors.password.message}
                label={"Hasło"}
              />
            )}
            rules={getInputRules(true, 20, 8)}
          />
          <Controller
            name="repeatPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledTextInput
                value={value}
                type="password"
                onChange={onChange}
                error={errors.repeatPassword && errors.repeatPassword.message}
                label={"Powtórz hasło"}
              />
            )}
            rules={getInputRules(true, 20, 8)}
          />
        </>
      )}
      <StyledButton
        label={t("resetPasswordScreen.send")}
        onClick={handleSubmit(onSubmit)}
        type="contained"
      />
    </form>
  );
};

export default ResetPasswordForm;
