const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Korzyści i możliwości związane z zastosowaniem RPA w praktyce biznesowej&nbsp;</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Korzyści wynikające z zastosowania&nbsp; RPA</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Mniej kodowania:</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Do wdrażania robota wykorzystywane są tzw. konfiguratory wizualne,&nbsp; wykorzystujące funkcję "przeciągnij i upuść". Pozwala to na wdrożenie do proces&oacute;w RPA także personelu nietechnicznego.&nbsp;&nbsp;</span></p>
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Szybkie oszczędności koszt&oacute;w:</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Ponieważ RPA zmniejsza obciążenie zespoł&oacute;w, personel może zostać przesunięty w kierunku innych priorytetowych prac, kt&oacute;re nie wymagają wkładu człowieka. Prowadzi to do wzrostu wydajności i ROI.&nbsp;</span></p>
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Wyższa satysfakcja klienta</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">: Ponieważ roboty, boty i chatboty mogą pracować przez całą dobę, zmniejsza się np. czas oczekiwania klient&oacute;w na obsługę ich zapytań, co prowadzi do wyższych wskaźnik&oacute;w zadowolenia klient&oacute;w.</span></p>
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Zwiększenie zadowolenia pracownik&oacute;w</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">:&nbsp; Odciążenie zespołu, w postaci usunięcia z zakresu ich obowiązk&oacute;w powtarzalnych, czasochłonnych czynności, sprawia, że dzięki RPA pracownicy mogą skupić się na strategicznych działaniach, przynoszących satysfakcję.&nbsp;</span></p>
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Większa dokładność i mniej błęd&oacute;w:</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Ponieważ można zaprogramować roboty RPA w określony spos&oacute;b, zgodnie z regułami, prowadzi to do zmniejszenia liczby błęd&oacute;w ludzkich, szczeg&oacute;lnie w ramach prac, kt&oacute;re wymagają dokładności i zgodności, jak np. standardy regulacyjne. RPA może r&oacute;wnież zapewnić ścieżkę audytu, co ułatwia monitorowanie postęp&oacute;w i szybsze rozwiązywanie problem&oacute;w.&nbsp;&nbsp;</span></p>
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">Istniejące systemy pozostają na miejscu:</span><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"> Oprogramowanie do automatyzacji proces&oacute;w robotycznych nie powoduje zakł&oacute;ceń w systemach bazowych, ponieważ boty działają na warstwie prezentacji istniejących aplikacji. Można zatem wdrożyć boty w sytuacjach, w kt&oacute;rych aplikacja źr&oacute;dłowa nie posiada interfejsu programowania aplikacji (API) lub zasob&oacute;w do opracowania głębokich integracji.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przykładowe pytania zadawane na etapie analizy przed wdrożeniem RPA</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaki jest protok&oacute;ł mailowy (SMTP/Exchange/Gsuite) oraz czy faktury przychodzą na jedną skrzynkę generyczną lub/czy z jednego adresu mailowego?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W jakim formacie przychodzą faktury?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy faktury zawsze wyglądają tak samo (jeśli nie ile jest templat&oacute;w/formatek faktur)?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy faktury mogą mieć więcej niż jedną stronę?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy przeprocesowane faktury mają być przenoszone do innego folderu?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaki system ERP jest używany?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy logowanie do systemu jest przez SSO (Single Sign On) czy manualne?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jakie mogą być wyjątki w procesie, np. jak należy procesować w przypadku błędnej daty faktury, daty wystawienia FV wcześniejsza niż realizacji usługi etc?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy robot powinien sprawdzać jakieś dodatkowe parametry przed uzupełnieniem?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy każda faktura ma być wpisywana w ten sam spos&oacute;b?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy robot ma raportować zakończone z sukcesem procesy?</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: #ffffff; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czy w przypadku wykrycia błędu robot ma wysyłać maila?</span></li>
</ol>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Podstawowe możliwości narzędzi RPA</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Możliwości low-code do budowania skrypt&oacute;w automatyzacji</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Integracja z aplikacjami przedsiębiorstwa</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zarządzanie i administracja skonfigurowanymi robotami (w tym konfiguracja, monitorowanie i bezpieczeństwo)</span></li>
</ol>
</td>
</tr>
</tbody>
</table>`;

export default html;
