const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 12.604492187499996pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwy platformy IoT</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwa pre-ingestion + ingestion (warstwa urządzeń)</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jest to najniższa warstwa platformy IoT i obejmuje wszystkie urządzenia, kt&oacute;re są podłączone do sieci IoT, takie jak sensory, czujniki, urządzenia pomiarowe i inne urządzenia końcowe, a także infrastrukturę sieciową, karty SIM, urządzenia sieciowe do zainstalowania na fizycznych urządzeniach (routery, bramy sieciowe, VPN). W warstwie tej funkcjonują też tzw. edge (tzw. urządzenia brzegowe), umożliwiające podłączenie się pod czujniki/sensory.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwa łączności</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwa odpowiedzialna za przesyłanie danych między urządzeniami IoT a siecią internetową. Obejmuje protokoły komunikacyjne takie jak Bluetooth, Wi-Fi, Zigbee, LoRaWAN i inne. Obejmuje protok&oacute;ł komunikacji urządzeń infrastruktury fizycznej z edgem, określający spos&oacute;b organizacji danych w streamie, tj. wiązce danych spływających do urządzenia).</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwa chmury</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwa zajmująca się przetwarzaniem i przechowywaniem danych, kt&oacute;re są przesyłane z urządzeń IoT. To tutaj odbywa się analiza danych, przetwarzanie danych. W warstwie tej wykorzystywany jest chipset TPM, służący do przechowywania certyfikat&oacute;w bezpieczeństwa, zapewniających bezpieczeństwo przepływu danych z urządzeń edge do chmury i odwrotnie.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwa aplikacji i analityczna</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Warstwy te umożliwiają przetwarzanie danych i uzyskiwanie z nich informacji i wiedzy, kt&oacute;ra może być wykorzystana do podejmowania decyzji biznesowych lub optymalizacji proces&oacute;w. Warstwy te umożliwiają też użytkownikom końcowym interakcję z systemem IoT poprzez r&oacute;żnego rodzaju aplikacje. Mogą to być aplikacje mobilne, strony internetowe, panele kontrolne itp.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
