const html = `<h3 style="text-align: center;"><strong>CV członk&oacute;w zespołu realizującego projekt z obszaru marketingu cyfrowego</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Social Media Manager</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013 -2016&nbsp; Marketing specialist w agencji marketingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2016-2020 Digital specialist w agencji marketingowej</span></p>
<p><span style="font-weight: 400;">2020 &ndash; Social Media Manager</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Menedżer Firmy i Reklam Meta ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Meta Business Suite ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">TikTok Business Center / Ads Manager ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">LinkedIn Ads Manager ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Hootsuite ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Brand24 ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Google Analytics ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Trello ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">MS Excel ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Adobe Photoshop **</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Canva****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">400-101: Meta Certified Media Planning Professional</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">- Obsługa platform społecznościowych (publikacja, moderacja) </span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">- Planowanie i konfiguracja kampanii płatnych w ekosystemie Meta, TikTok, LinkedIn</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">- Opracowywanie raport&oacute;w kampanii</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr zarządzania i marketingu</span></p>
<p><span style="font-weight: 400;">Studia podyplomowe: Marketing Internetowy</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Kreatywność&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Digital strategist /Strateg medi&oacute;w cyfrowych&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2015 Senior marketing specialist w agencji marketingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2015-2020 Head of B2B digital strategy w agencji marketingowej&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 &ndash; obecnie Head of marketing w startupie technologicznym</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Narzędzia analityczne Google, Meta *****</span></p>
<p><span style="font-weight: 400;">Platformy biznesowe medi&oacute;w społecznościowych *****&nbsp;</span></p>
<p><span style="font-weight: 400;">Adobe XD ***&nbsp;</span></p>
<p><span style="font-weight: 400;">Meta Ads ****&nbsp;</span></p>
<p><span style="font-weight: 400;">Google Ads ***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Dimaq Professional</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza/umiejętności&nbsp;</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie strategii marki i opracowywanie taktyki działania w mediach cyfrowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tworzenie założeń komunikacji marketingowej.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dob&oacute;r kanał&oacute;w komunikacji.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Planowanie harmonogram&oacute;w i budżetu kampanii reklamowych.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Audytowanie działań i kompetencji marketingowych</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr inż. ochrony środowiska&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Strategiczne myślenie</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Organizacja pracy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Kreatywność</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Lead Designer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2013 - UX specialist i WebDesigner w software housie</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">2013-2015 - Senior Web Designer w agencji interaktywnej</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">2015-2021 Art Director w agencji interaktywnej</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">2021-obecnie Lead Designer w 2-osobowym teamie kreatywnym (z copywriterką tworzącą warstwę tekstową koncepcji)</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Figma ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Adobe XD ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Adobe Photoshop ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Adobe Illustrator ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Asana **</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Adobe Photoshop Proficiency </span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Prototypowanie produkt&oacute;w cyfrowych i architektura informacji</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Storyboardy w video marketingu</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">- Projektowanie graficzne na potrzeby projekt&oacute;w cyfrowych i kampanii reklamowych w internecie</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">- Projektowanie UX, prototypowanie stron www</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">- Kompleksowe tworzenie koncepcji kreatywnych (we wsp&oacute;łpracy z copywriterem)</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">- Koncepcyjne wsparcie tworzenia projekt&oacute;w video o animacji (storyboardy, scenariusze)</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Akademia Sztuk Pięknych w Łodzi; mgr projektowania graficznego</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">zarządzanie zespołem projektowym</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Frontend Developer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2012-2013 Frontend developer w software housie</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">2013-2015 Full-Stack Developer w software housie</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">2015-2020 Senior Full-Stack developer w agencji interaktywnej</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">2020-obecnie Frontend developer w naszej firmie</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">JavaScript ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">HTML ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">CSS ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">.NET ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">React.js ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Angular.js **</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Spring ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">SOAP ***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Scrum/Agile ****</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">WordPress ****</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>-</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">umiejętność kompleksowego wdrożenia stron www i podstawowych aplikacji webowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">doświadczenie w projektach IT wspierających strategię marketingową</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">mgr inż. informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">dobra organizacja czasu</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">umiejętność pracy pod presją</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">ciągły rozw&oacute;j i aktualizowanie wiedzy</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
