const html = `<h3 style="text-align: center;"><strong>CASE STUDY PROJEKTU&nbsp;</strong></h3>
<p>&nbsp;</p>
<p><strong>Tytuł projektu:</strong><span style="font-weight: 400;"> Zbudowanie wirtualnych odpowiednik&oacute;w maszyn i urządzeń, celem ich pokazania na małym stoisku targowym&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Klienta produkuje wysokogabarytowe maszyny, a posiada niewielkie stoisko targowe, umożliwiające wystawienie produkowanego sprzętu.&nbsp;&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na odtworzeniu maszyn w formie cyfrowej na potrzeby&nbsp; pokazania w VR z użyciem mobilnych gogli VR, odtwarzających aplikację bez potrzeby korzystania z komputera&nbsp;&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Niższe koszty ekspozycji, zwiększenie zadowolenia klient&oacute;w dzięki dostęp do pełnej oferty produkt&oacute;w w skali 1:1 na stoisku&nbsp;&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Aplikacja VR oparta na silniku Unity, mobilne gogle VR&nbsp;&nbsp;</span></p>
<p><strong>Czas trwania projektu</strong><span style="font-weight: 400;">: 2 miesiące&nbsp;&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Programista Unity (0,5 FTE), modelarz 3D (1 FTE), programista oprogramowania (1 FTE),&nbsp; tester oprogramowania (0,5 FTE), PM (0,25 FTE)</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">50 tys. zł</span></p>`;

export default html;
