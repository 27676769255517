const html = `<h2><strong>Wykaz technologii wykorzystanych w projekcie ML/AI wraz z opisem i przykładami</strong></h2>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<p><strong>Element/moduł rozwiązania/ technologia&nbsp;</strong></p>
</td>
<td colspan="2">
<p><strong>Opis elementu/technologii&nbsp;</strong></p>
</td>
<td colspan="2">
<p><strong>Umiejętności wspierające obsługę/tworzenie technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Narzędzie ETL</span></p>
</td>
<td colspan="2">
<p><span style="font-weight: 400;">Umożliwia realizację proces&oacute;w pobrania danych ze źr&oacute;deł, transformacji danych i załadowania do centralnej bazy danych. Realizuje elementy związane z przetwarzaniem danych (pobieranie, transformację i ładowanie danych).</span></p>
<br />
<p><span style="font-weight: 400;">Narzędzie obsługiwane być może ( w zależności od rodzaju) z użyciem interfejsu graficznego, nie wymagane programowania.</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> AirFlow Apache, Informatica Power Center, Spark, Talend Data Integration, Oracle Data Integration, AWS Glue</span></p>
</td>
<td colspan="2">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wdrażanie proces&oacute;w ETL</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość narzędzi ETL&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Platforma/aplikacja BI (wykorzystywana przez klienta)</span></p>
</td>
<td colspan="2">
<p><span style="font-weight: 400;">Umożliwia zbudowanie modelu danych, wizualizację danych, analizę danych.</span></p>
<br />
<p><span style="font-weight: 400;">Zwykle budowa dashboard&oacute;w realizowana jest przez interfejs drag&amp;drop. Przekształcenia danych oraz miary analityczne programowane są za pomocą wyrażeń.</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Power BI, Tableau, Qlik</span></p>
</td>
<td colspan="2">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">język wyrażeń używany w danym narzędziu BI,</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">SQL</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">podstawy modelowania danych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">umiejętności związane z wizualizacją danych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość danego narzędzia BI pod kątem ustawień, tworzenia filtr&oacute;w i interakcji użytkownika</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">IDE (Integrated Development Environment) do python</span></p>
</td>
<td colspan="2">
<p><span style="font-weight: 400;">Umożliwia programowanie w python, kolorowania składni, podpowiedzi, debugowanie, wizualizacje.</span></p>
<p><span style="font-weight: 400;">Środowisko IDE służy do maksymalizacji produktywności programowania.</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Jupyter Lab, Spyder, PyCharm, Rodeo, DataSpell</span></p>
</td>
<td colspan="2">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość IDE do python oraz programowania w python w stopniu wystarczającym dla Data Science i ML</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">umiejętności eksploracji i wizualizacji danych</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Biblioteki Data Science i ML do python</span></p>
</td>
<td colspan="2">
<p><span style="font-weight: 400;">Umożliwia przekształcenia danych, eksplorację danych, wizualizację i budowę modeli ML z wykorzystaniem gotowych komponent&oacute;w</span></p>
<br /><br />
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Pandas, numpy, scikit-learn, pytorch, tensorflow, scipy itd.</span></p>
</td>
<td colspan="2">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">miejętność korzystania z bibliotek Data Science i ML w celu przekształcenia danych, ich wizualizacji oraz budowy modeli ML</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Hurtownia danych&nbsp;</span></p>
</td>
<td colspan="2">
<p><span style="font-weight: 400;">Umożliwia przechowywanie danych w postaci danych ustrukturyzowanych i semi-strukturyzowanych, uruchamianie zapytań analitycznych na danych.&nbsp;</span></p>
<p><span style="font-weight: 400;">Często dane przechowywane są w postaci kolumnowej w celu optymalizacji zapytań analitycznych.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Snowflake, Azure Synapse, AWS Redshift, Google BigQuery.&nbsp;</span></p>
</td>
<td colspan="2">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość architektury baz danych i hurtowni danych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość zagadnień SQL lub dialekty specyficzne dla danej hurtowni</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Data Lake&nbsp;&nbsp;</span></p>
</td>
<td colspan="2">
<p><span style="font-weight: 400;">Umożliwia przechowywanie danych w postaci danych ustrukturyzowanych i semi-strukturyzowanych, uruchamianie zapytań analitycznych na danych.</span></p>
<p><span style="font-weight: 400;">Często dane przechowywane są w postaci kolumnowej w celu optymalizacji zapytań analitycznych. W architekturze Data Lake House dane przechowywane są na Data Lake, a pośrednia warstwa odpowiada za uwsp&oacute;lniony interfejs do odpytywania danych.</span></p>
<br />
<p><strong>Przykłady:</strong></p>
<p><span style="font-weight: 400;">Snowflake, Azure Synapse, AWS Redshift, Google BigQuery, Delta Lake / Databricks</span></p>
</td>
<td colspan="2">
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość architektury baz danych i hurtowni danych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość zagadnień Data lake i Data lake house</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">SQL lub dialekty specyficzne dla danej hurtowni lub Data Lake House&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">znajomość NLP</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<h3><br /><br /></h3>`;

export default html;
