const html = `<table border="1">
<tbody>
<tr>
<td>
<p><strong>Stanowisko</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Senior backend deweloper</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programowanie rozwiązania zgodnie z wymaganiami klienta.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analityk biznesowy</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Analiza potrzeb klienta.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">UX designer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Projektowanie makiet rozwiązania i proces&oacute;w, tak by zapewniały jak najwyższy poziom zadowolenia użytkownik&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Mid backend deweloper&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programowanie rozwiązania.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Junior backend deweloper</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Programowanie rozwiązania.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">UI designer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Projektowanie element&oacute;w graficznych.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Mid frontend deweloper</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Prgoramowanie rozwiązania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">QA Engineer&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Testowanie rozwiązania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Project Manager</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zarządzanie projektem.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Business Analyst&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Zwr&oacute;ć uwagę czy Analityk skupia się tylko na technologii, czy też na potrzebach klienta. Ważne by Analityk nie skupiał się wyłącznie na aspektach technologicznych</span><span style="font-weight: 400;">.</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 15&nbsp;000 złotych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Ela Małachowska</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013 &ndash; 2015 Project Manager</span></p>
<p><span style="font-weight: 400;">2015 - 2020 Junior Business Analyst</span></p>
<p><span style="font-weight: 400;">2021 &ndash; obecnie Business Analyst w branży e-commerce</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">Business Analyse***</span></p>
<p><span style="font-weight: 400;">Diagraming***</span></p>
<p><span style="font-weight: 400;">Magento***</span></p>
<p><span style="font-weight: 400;">ERP**</span></p>
<p><span style="font-weight: 400;">E-commerce**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><strong>ECBA (Entry Certificate in Business Analysis)</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">6 lat doświadczenia komercyjnego jako analityk biznesowy</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Znajomość branży IT oraz zasad działania ekosystem&oacute;w e-commerce&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Modelowanie proces&oacute;w biznesowych&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tworzenie dokumentacji&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Znajomość metod i technik integracji informatycznych</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Technikum ekonomiczne</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Project Manager&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Zwr&oacute;ć uwagę czy Project Manager jest asertywny, potrafi szybko podejmować decyzje i lubi porządek.</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 17&nbsp;000 złotych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Paweł Dusigrosz</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013 - 2017 Account Manager</span></p>
<p><span style="font-weight: 400;">2017 - obecnie&nbsp; Project Manager w branży e-commerce</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">E-commerce***</span></p>
<p><span style="font-weight: 400;">Project Management***</span></p>
<p><span style="font-weight: 400;">Magento***</span></p>
<p><span style="font-weight: 400;">Prestashop**</span></p>
<p><span style="font-weight: 400;">ERP**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">AGILE PM Foundation</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">5 lat doświadczenia komercyjnego jako kierownik projektu</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Znajomość zwinnych metodyk zarządzania projektami</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Znajomość branży IT oraz zasad działania ekosystem&oacute;w e-commerce&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tworzenie dokumentacji&nbsp;</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Magister Stosunk&oacute;w Międzynarodowych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Senior backend deweloper</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Ważne, żeby Deweloper miał doświadczenie z dużymi systemami ecommerce, ERP i WMS.</span><span style="font-weight: 400;">&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 22&nbsp;000 złotych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Adrian Walczak</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">2015 - 2017 &ndash; Junior PHP Developer</span></p>
<p><span style="font-weight: 400;">2017 &ndash; 2019 &ndash; PHP Developer</span></p>
<p><span style="font-weight: 400;">2019 &ndash; obecnie - Magento Developer</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">Magento***</span></p>
<p><span style="font-weight: 400;">PHP***</span></p>
<p><span style="font-weight: 400;">MySQL***</span></p>
<p><span style="font-weight: 400;">Docker***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">RabbitMQ***</span></p>
<p><span style="font-weight: 400;">Elasticsearch***</span></p>
<p><span style="font-weight: 400;">Kibana**</span></p>
<p><span style="font-weight: 400;">Xdebug***</span></p>
<p><span style="font-weight: 400;">GraphQL**</span></p>
<p><span style="font-weight: 400;">Rest API***</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">E-commerce*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Adobe Certified Expert - Magento Commerce Developer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Rozw&oacute;j i utrzymanie funkcjonalności dla sklep&oacute;w internetowych Magento 2/Adobe Commerce</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tworzenie nowych rozwiązań eCommerce, od startu projektu do uruchomienia MVP i pomocy w utrzymaniu działającego sklepu</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Trzymanie jakości kodu</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tworzenie dokumentacji</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Wsp&oacute;łpraca z Tech Leadem i klientami biznesowymi</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inżynier Informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; UI Designer</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Dokładność i perfekcyjne zarządzanie plikami oraz elementami graficznymi to oznaka dojrzałości projektanta graficznego.</span><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 7000 złotych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Marcelina Bogdańska</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2021 - 2021 &ndash; stażysta w firmie IT&nbsp;</span></p>
<p><span style="font-weight: 400;">2021 &ndash; obecnie UI Designer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">Figma***</span></p>
<p><span style="font-weight: 400;">Adobe XD**</span></p>
<p><span style="font-weight: 400;">Miro*</span></p>
<p><span style="font-weight: 400;">E-commerce*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Kurs UI Design 2022</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">doświadczenie w projektowaniu aplikacji internetowych i mobilnych</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">doświadczenie w projektowaniu produkt&oacute;w internetowych (aplikacji, narzędzi, e-commerce)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">wysokie rozumienie procesu projektowania doświadczeń użytkownik&oacute;w</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">wysoka dbałość o szczeg&oacute;ły oraz wyważone proporcje między oryginalnością a efektywnością</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inżynier Architekt Krajobrazu&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; UX designer&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Dobry projektant doświadczeń użytkownika doskonale rozumie potrzeby biznesu i użytkownika końcowego.</span><span style="font-weight: 400;">&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 11000 złotych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Edyta Balonik</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">2020 - 2021 &ndash; Junior UX Designer</span></p>
<p><span style="font-weight: 400;">2021 &ndash; obecnie UX Designer w branży e-commerce</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">Figma***</span></p>
<p><span style="font-weight: 400;">Adobe XD**</span></p>
<p><span style="font-weight: 400;">Miro***</span></p>
<p><span style="font-weight: 400;">E-commerce**</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Google UX Design Professional Certificate</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">doświadczenie w pracy jako projektant UX&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">doświadczenie w projektowaniu UX sklep&oacute;w internetowych</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">projektowanie UX pod kątem użyteczności, metryk ilościowych i jakościowych</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Lic. informatyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: &nbsp; QA Engineer&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Wysoka dbałość o szczeg&oacute;ły i umiejętność definiowania problem&oacute;w do rozwiązania charakteryzuje dobrego kandydata na to stanowisko.</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 8000 złotych</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Waldemar Czułko</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">2019 - 2020 &ndash; stażysta w firmie IT&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 &ndash; obecnie Junior QA Engineer&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">Selenium**</span></p>
<p><span style="font-weight: 400;">CI/CD*</span></p>
<p><span style="font-weight: 400;">Jenkins*</span></p>
<p><span style="font-weight: 400;">AWS*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">ISTQB&reg; Certified Tester Foundation Level</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Dobra znajomość zagadnień związanych z testowaniem UI</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Znajomość narzędzi Selenium, Appium</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Doświadczenie z narzędziami CI/CD&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Doświadczenie w testach manualnych&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Umiejętności w pisaniu dokumentacji przypadk&oacute;w użytkownika</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Licencjat informatyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, prezentacja pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Mid frontend developer&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: </span><span style="font-weight: 400;">Wysoka dbałość o szczeg&oacute;ły i umiejętne wykorzystanie technologii charakteryzuje osoby z potencjałem na te stanowisko.</span><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 12000</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Aneta Skwarko</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">2018 - 2020 &ndash; Junior Frontend Developer&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 &ndash; obecnie Frontend Developer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">HTML***</span></p>
<p><span style="font-weight: 400;">CSS***</span></p>
<p><span style="font-weight: 400;">GIT**</span></p>
<p><span style="font-weight: 400;">JS**</span></p>
<p><span style="font-weight: 400;">VUE.JS**</span></p>
<p><span style="font-weight: 400;">REACT*</span></p>
<p><span style="font-weight: 400;">NODE.JS*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Magento Front End Developer Certification</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Doświadczenie zawodowe jako Frontend Developer&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Dobra znajomość języka Javascript (ES6+)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Dobra znajomość HTML, CSS (SASS)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Doświadczenie z Vue.js</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Doświadczenie z web bundlerami (esbuild, webpack, rollup, itp.)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Doświadczenie z systemami kontroli wersji (GIT)</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Lic. informatyki&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, prezentacja pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Junior backend deweloper</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: U mniej doświadczonego członka zespołu najważniejsza będzie otwartość na rady bardziej doświadczonych pracownik&oacute;w, proaktywne działanie oraz chłonność wiedzy.</span><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 6000</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Tomasz Łodyga</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">2022 &ndash; obecnie Stażysta PHP</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">PHP*</span></p>
<p><span style="font-weight: 400;">MySQL*</span></p>
<p><span style="font-weight: 400;">E-commerce*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Brak</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Podstawowa znajomość języka PHP</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Podstawowa znajomość baz danych</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Podstawowa znajomość GIT</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Student Informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; Mid backend deweloper</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Developer z doświadczeniem potrzebuje ukierunkowania i mentoringu. Jeśli w projekcie będzie z jeszcze bardziej doświadczonym developerm szybko może mu dor&oacute;wnać.</span><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Wynagrodzenie msc: 12000</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Antoni Prochibek</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">2018 - 2020 &ndash; Junior PHP Developer</span></p>
<p><span style="font-weight: 400;">2020 &ndash; 2022 &ndash; PHP Developer</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td><br />
<p><span style="font-weight: 400;">PHP**</span></p>
<p><span style="font-weight: 400;">MySQL**</span></p>
<p><span style="font-weight: 400;">Docker*</span></p>
<p><span style="font-weight: 400;">Elasticsearch*</span></p>
<p><span style="font-weight: 400;">Rest API**</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">E-commerce*</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Brak</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td><br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zaawansowana znajomość języka PHP</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zaawansowana znajomość MySQL</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zaawansowana znajomość GIT</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Trzymanie jakości kodu</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tworzenie dokumentacji</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Wsp&oacute;łpraca z Tech Leadem i klientami biznesowymi</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Inżynier Informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, umiejętność negocjacji i prezentacji pomysł&oacute;w, prowadzenie merytorycznej dyskusji.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
