export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const getInputRules = (
  required: boolean,
  maxLength?: number,
  minLength?: number
) => {
  return {
    maxLength: maxLength
      ? {
          value: maxLength,
          message: `Maksymalna ilość znaków wynosi ${maxLength}`,
        }
      : undefined,
    minLength: minLength
      ? {
          value: minLength,
          message: `Minimalna ilość znaków wynosi ${minLength}`,
        }
      : undefined,
    required: required ? "Pole wymagane" : "",
  };
};

export const secondsToHms = (s: number) => {
  const hours = (s - (s % 3600)) / 3600;
  const minutes = ((s - (s % 60)) / 60) % 60;
  const seconds = s % 60;
  return `${hours ? `${hours} godz` : ""} ${
    minutes || hours ? `${minutes} min` : ""
  } ${seconds} s`;
};

export const emailPattern = new RegExp(
  "^[a-z0-9._%+-]+@[a-z0-9-]+([.][a-z]+)+$"
);
export const passwordPattern = new RegExp(
  "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[!@#$%^&*()_\\-+=])[A-Za-z\\d!@#$%^&*()_\\-+=]{8,}$"
);
