const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<h3 style="text-align: center;"><strong>Zadania szczeg&oacute;łowe członk&oacute;w zespołu realizującego projekt z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Stanowisko</strong></p>
</td>
<td><strong>Zadania</strong></td>
</tr>
<tr>
<td>
<p><strong>Digital strategist</strong></p>
</td>
<td>
<ul>
<li>Weryfikacja kompletności dostarczonych danych</li>
<li><span style="font-weight: 400;">Analiza dotychczasowych działań marketingowych w zakresie komunikacji w mediach społecznościowych i spisanie podsumowania</span></li>
<li><span style="font-weight: 400;">Analiza dotychczasowych działań marketingowych w zakresie stron www i spisanie podsumowania</span></li>
<li><span style="font-weight: 400;">Analiza posiadanych przez markę wytycznych dotyczących identyfikacji marki i strategii komunikacji; wybranie element&oacute;w sp&oacute;jnych z nową grupą docelową</span></li>
<li><span style="font-weight: 400;">Zdefiniowanie i uszczeg&oacute;łowienie grupy docelowej, jej segmentacja i ustalenie priorytet&oacute;w dla poszczeg&oacute;lnych segment&oacute;w; spisanie wniosk&oacute;w</span></li>
<li><span style="font-weight: 400;">Wyznaczenie cel&oacute;w działań dla poszczeg&oacute;lnych etap&oacute;w egzekucji oraz definiowanie <em>KPI</em> [<em>Key Performance Indicators</em> - kluczowych wskaźnik&oacute;w efektywności, czyli element&oacute;w mierzonych w celu p&oacute;źniejszej oceny postęp&oacute;w pracy oraz realizacji cel&oacute;w]</span></li>
<li><span style="font-weight: 400;">Sformułowanie gł&oacute;wnego przekazu marki - specjalizacji produktu i <em>gł&oacute;wnych cech wyr&oacute;żniających</em> [inaczej <em>USP</em> czyli <em>Unique Selling Proposition</em>]</span></li>
<li><span style="font-weight: 400;">Sformułowanie gł&oacute;wnych <em>wartości marki</em> [inaczej <em>brand equity</em>], języka marki i jej archetypu</span></li>
<li><span style="font-weight: 400;">Dob&oacute;r platform społecznościowych do segment&oacute;w grupy docelowej&nbsp;</span></li>
<li><span style="font-weight: 400;">Zdefiniowanie gł&oacute;wnych lejk&oacute;w sprzedażowych</span></li>
<li><span style="font-weight: 400;">Budżetowanie wydatk&oacute;w</span></li>
<li><span style="font-weight: 400;">Opracowanie ramowego kalendarza działań i realizowanych cel&oacute;w</span></li>
<li><span style="font-weight: 400;">Opracowanie rekomendacji narzędzi</span></li>
<li><span style="font-weight: 400;">Warsztaty z Klientem w celu wsp&oacute;lnego wyboru narzędzi z listy rekomendowanych</span></li>
<li><span style="font-weight: 400;">Zakup (jeśli to konieczne), podłączenie i konfiguracja startowa wybranych narzędzi</span></li>
<li><span style="font-weight: 400;">Warsztaty z Klientem - szkolenie z podstawowej obsługi narzędzi</span></li>
<li><span style="font-weight: 400;">Stworzenie listy niezbędnych treści wideo na podstawie content planu</span></li>
<li><span style="font-weight: 400;">Skompletowanie specyfikacji dla format&oacute;w wideo, w kt&oacute;rych będą wyświetlane filmy (rozdzielczość, długość, kadr, ograniczenia techniczne)</span></li>
<li><span style="font-weight: 400;">Przekazanie listy treści, specyfikacji oraz wzorcowych scenariuszy i storyboard&oacute;w Klientowi</span></li>
<li><span style="font-weight: 400;">Kontakt z klientem koordynującym produkcję video - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem</span></li>
<li><span style="font-weight: 400;">Przekazanie video osobom odpowiedzialnym za komunikację i kampanie w mediach społecznościowych</span></li>
<li><span style="font-weight: 400;">Przekazanie niezbędnych uprawnień i kontrola polityki bezpieczeństwa danych (jednorazowo).</span></li>
<li><span style="font-weight: 400;">Publikowanie treści zgodnie z harmonogramem/content planem; moderacja komentarzy, odpowiadanie na wiadomości</span></li>
<li><span style="font-weight: 400;">Rozliczenie faktur, dopilnowanie płatności (raz w miesiącu)</span></li>
<li><span style="font-weight: 400;">Przekazanie niezbędnych uprawnień i kontrola polityki bezpieczeństwa danych (jednorazowo)</span></li>
<li><span style="font-weight: 400;">Konfiguracja kampanii płatnych zgodnie z założonym harmonogramem/content planem; kontrola działania trwających kampanii i ich optymalizacja</span></li>
<li><span style="font-weight: 400;">3 x Opracowanie raportu zawierającego dane o aktywności na landing page, aktywności w poszczeg&oacute;lnych kanałach social media, efektach kampanii płatnych oraz poziomie sprzedaży w raportowanym okresie</span></li>
<li><span style="font-weight: 400;">3 x Skonsultowanie raportu z zespołem i klientem - zgromadzenie danych o niezbędnych zmianach w działaniu</span></li>
<li><span style="font-weight: 400;">Opracowanie raportu końcowego zawierającego dane o aktywności na landing page, aktywności w poszczeg&oacute;lnych kanałach social media, efektach kampanii płatnych oraz poziomie sprzedaży w trakcie całej kampanii</span></li>
<li><span style="font-weight: 400;">Skonsultowanie raportu z zespołem i klientem - zgromadzenie i prezentacja danych o niezbędnych zmianach w działaniu</span></li>
<li><span style="font-weight: 400;">Opracowanie raportu dobrych praktyk do kampanii płatnych (w celu ich p&oacute;źniejszej kontynuacji)</span></li>
<li><span style="font-weight: 400;">Ewaluacja projektu</span></li>
<li><span style="font-weight: 400;">Zamknięcie i rozliczenie budżetu projektu</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Social media manager</strong></p>
</td>
<td>
<ul>
<li><span style="font-weight: 400;">Zlecenie stworzenia content planu na cały czas trwania <em>kampanii</em> [szczeg&oacute;łowy kalendarz publikacji treści we wszystkich kanałach komunikacji marki, np. na wszystkich profilach społecznościowych]&nbsp;</span></li>
<li><span style="font-weight: 400;">Uwagi. Akceptacja</span></li>
<li><span style="font-weight: 400;">Koordynowanie produkcji treści tekstowych - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem</span></li>
<li><span style="font-weight: 400;">Koordynowanie produkcji treści graficznych/zdjęciowych - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Lead Designer</strong></p>
</td>
<td>
<ul>
<li><span style="font-weight: 400;">Komunikacja wizualna - opracowanie <em>key visuali</em>&nbsp; [gł&oacute;wny motyw graficzny, kt&oacute;ry pojawia się na materiałach reklamowych marki w danej kampanii]</span></li>
<li><span style="font-weight: 400;">Copywriting - opracowanie haseł i wzorcowych komunikat&oacute;w tekstowych</span></li>
<li><span style="font-weight: 400;">Wzorcowe scenariusze i <em>storyboardy</em> [scenorys/scenopis w formie szkicowanych obrazk&oacute;w] na potrzeby produkcji video</span></li>
<li><span style="font-weight: 400;">Zdefiniowanie gł&oacute;wnych lejk&oacute;w sprzedażowych</span></li>
<li><span style="font-weight: 400;">Budżetowanie wydatk&oacute;w</span></li>
<li><span style="font-weight: 400;">Opracowanie ramowego kalendarza działań i realizowanych cel&oacute;w</span></li>
<li><span style="font-weight: 400;">Projekt prototypu landing page</span></li>
<li><span style="font-weight: 400;">Testy użyteczności projektowanego prototypu</span></li>
<li><span style="font-weight: 400;">Akceptacja i przekazanie prototypu do projektowania graficznego</span></li>
<li><span style="font-weight: 400;">Projektowanie graficzne landing page sprzedażowego</span></li>
<li><span style="font-weight: 400;">Akceptacja i przekazanie projektu graficznego do wdrożenia</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Frontend Developer</strong></p>
</td>
<td>
<ul>
<li><span style="font-weight: 400;">Wdrożenie landing page na serwerze testowym</span></li>
<li><span style="font-weight: 400;">Testowanie landing page</span></li>
<li><span style="font-weight: 400;">Instalacja na serwerze produkcyjnym</span></li>
<li><span style="font-weight: 400;">Finalne testy i publikacja</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
