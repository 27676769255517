const html = `<table style="border-collapse: collapse; width: 100%; height: 198px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Komunikaty pojawiające się podczas spotkania kick-off</span></strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jesteśmy tu po to by stworzyć narzędzie do tworzenia raport&oacute;w analitycznych, kt&oacute;re poprawi dostęp do informacji zarządczej.</span></td>
</tr>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nasz zesp&oacute;ł składa się z 3 os&oacute;b. Architekta, ETL developera i BI&nbsp; developera. Poznajmy się, niech każdy powie coś o sobie.</span></td>
</tr>
<tr style="height: 36px;">
<td style="width: 99.5449%; height: 36px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Om&oacute;wię teraz harmonogram projektu wraz z zakładanymi przez nas terminami. Za chwilę om&oacute;wimy kto jest odpowiedzialny za poszczeg&oacute;lne zadania.</span></td>
</tr>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Om&oacute;wię teraz macierz RACI, tak by każdy wiedział czego się od niego oczekuje i za co jest odpowiedzialny.</span></td>
</tr>
<tr style="height: 36px;">
<td style="width: 99.5449%; height: 36px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do rozpoczęcia pracy potrzebujemy dostęp&oacute;w do system&oacute;w. Tu mam listę os&oacute;b, kt&oacute;re potrzebują kont. Przekazuję ją Administratorowi IT. Proszę także o stworzenie kont do usług chmurowych na Waszym temacie.</span></td>
</tr>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zakładam grupę na komunikatorze firmowym, to narzędzie do bieżącej komunikacji. W narzędziu do zarządzania projektami dodałem nasz projekt, gdzie będą dodawane taski do wykonania. Zapewni to możliwość śledzenia postęp&oacute;w prac.</span></td>
</tr>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Proponuję zorganizować spotkania statusowe w naszym gronie co 2 tygodnie, na końcu każdego sprintu. Ponadto, w razie potrzeby odbywać się będą spotkania inicjowane przez Architekta lub programist&oacute;w.</span></td>
</tr>
<tr style="height: 18px;">
<td style="width: 99.5449%; height: 18px;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dziękuję za spotkanie. Myślę, że po nim wszyscy wiemy co mamy robić i w jakim celu. Jestem do dyspozycji w razie pytań. Do zobaczenia za 2 tygodnie.</span></td>
</tr>
</tbody>
</table>`;

export default html;
