const html = `<p><strong>Tytuł projektu: Opracowanie strategii komunikacji oraz jej egzekucja w social media dla nowej marki kosmetycznej skierowanej do młodych matek w celu zwiększenia świadomości marki w grupie docelowej.</strong></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">wprowadzana na rynek marka produktowa jest nieznana. Przed rozpoczęciem sprzedaży klient musi zbudować świadomość i rozpoznawalność produkt&oacute;w w grupie docelowej, kt&oacute;rą oznaczono jako kobiety w wieku 24-32 lata, mieszkające w miastach 100 tys. mieszkańc&oacute;w, kt&oacute;re w ciągu ostatnich 12 miesięcy urodziły dziecko.&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Klient zleca opracowanie strategii komunikacji, koncepcji kreatywnej i content planu na 4 miesiące działań. Do zadań wykonawcy będzie należała także egzekucja zaplanowanych działań i raportowanie.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">podniesienie skuteczności działań sprzedażowych, obniżenie koszt&oacute;w kampanii sprzedażowych, długofalowo umożliwienie podniesienia marżowości sprzedaży dzięki dodatkowej wartości jaką niesie rozpoznawalność i zaufanie do marki.&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie:</strong><strong><br /></strong><span style="font-weight: 400;">Meta Business Suite, Menedżer Firmy i Reklam Meta, TikTok Business Center</span></p>
<p><strong>Czas trwania projektu:</strong><span style="font-weight: 400;"> 1 miesiąc prac przygotowawczych + 4 miesiące egzekucji</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"><br /></span><strong>Zesp&oacute;ł:&nbsp;</strong></p>
<p><span style="font-weight: 400;">Wersja in-house: Digital Strategist, Project Manager, Copywriter, Senior Designer (koncepcja), Designer (egzekucja), Social Media Manager, Social Ads Manager.</span></p>
<p><span style="font-weight: 400;">Wersja out-source: Brand Manager/Marketing Manager wsp&oacute;łpracujący z zewnętrzną agencją</span></p>
<p><strong>Budżet :</strong><span style="font-weight: 400;"> 250 000 zł netto</span></p>`;

export default html;
