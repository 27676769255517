const html = `<h3 style="text-align: center;"><strong>SWOT&nbsp; zastosowania technologii VR w biznesie&nbsp;</strong></h3>
<p>&nbsp;</p>
<p><strong>Strengths (mocne strony)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">możliwość obecności w cyfrowo odwzorowanej przestrzeni świata rzeczywistego lub doświadczenie warunk&oacute;w, kt&oacute;re byłyby niemożliwe do osiągnięcia w prawdziwym świecie</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">możliwość przebywania wielu os&oacute;b jednocześnie, w tej samej przestrzeni cyfrowej, bez względu na ich fizyczną lokalizację&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">automatyzacja proces&oacute;w szkoleniowych&nbsp;</span></li>
</ul>
<p><strong>Weaknesses (słabe strony)&nbsp;</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">źle przygotowane szkolenie VR, może zrazić użytkownik&oacute;w do wykorzystywania technologii&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">niedostosowanie cel&oacute;w biznesowych do możliwości technologicznych, może spowodować brak realizacji założonych wynik&oacute;w (KPI)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">koszty stworzenia skomplikowanego środowiska cyfrowego (odwzorowanie rzeczywistości fizycznej), w sytuacji braku jakościowych materiał&oacute;w dokumentacyjnych (plany, pliki techniczne, zdjęcia), są relatywnie wysokie&nbsp;</span></li>
</ul>
<h4>Opportunities (szanse)</h4>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">redukcja koszt&oacute;w szkolenia dzięki automatyzacji procesu&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zwiększenie bezpieczeństwa pracownik&oacute;w podczas szkoleń</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zwiększenie efektywności szkoleń, dzięki standaryzacji oraz stworzeniu warunk&oacute;w treningowych&nbsp; zbliżonych do rzeczywistych</span></li>
</ul>
<p><strong>Threats (zagrożenia)</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wyzwania związane z adaptacją nowej technologii przez grupę docelową (ze względu na to, że aktywne rozpoczęcie korzystania z nowej technologii wymaga nauki obsługi sprzętu oraz pokonania barier psychologicznych związanych z użytkowaniem nowego sprzętu)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">niskie nasycenie rynku sprzętem VR (relatywnie niewiele os&oacute;b i firm posiada gogle VR, w związku z tym istnieje bariera rozpoczęcia korzystania z oprogramowania wykorzystującego VR)&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">koszty wprowadzanie aktualizacji oprogramowania są relatywnie wysokie, w sytuacji, gdy np. treści szkoleń szybko się dezaktualizują należałoby rozważyć, kt&oacute;re elementy szkoleń powinny wykorzystywać VR</span></li>`;

export default html;
