const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="2" style="border: 1px solid rgb(0, 0, 0);">
                    <p>Usługi umożliwiające pogłębianie transformacji cyfrowej organizacji, po realizacji usługi z obszaru marketingu cyfrowego</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Propozycja usługi&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Uzasadnienie&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Budowa nowej strony internetowej&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>W trakcie działań marketingowych działania analityczne wykazały, że strona www nie wykorzystuje pełnego potencjału sprzedażowego kampanii marketingowej. Zbudowanie strony od nowa pozwoli na odświeżenie wizerunku i poprawę mechanizm&oacute;w sprzedażowych.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Wdrożenie lepszego systemu CRM</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Znaczące zwiększenie ilości obsługiwanych klient&oacute;w i mamy sygnały, że dział sprzedaży nie jest w stanie utrzymać płynności obsługi zapytań, wymaga wdrożenie efektywniejszego i bardziej rozbudowanego CRM, kt&oacute;ry wpłynie pozytywnie na zadowolenie pracownik&oacute;w oraz działania marketingowe.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Opracowanie materiał&oacute;w wielojęzycznych w celu przygotowania do wejścia na nowe rynki.</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Aplikacja jest już pozytywnie zweryfikowana na rynku krajowym i gotowa do wdrożenia nowych wersji językowych.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
