const html = `<h3 style="text-align: center;"><strong>Wykaz stanowisk w zespole 3D wraz z zakresami obowiązk&oacute;w i CV</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Stanowisko</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Handlowiec</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Project Manager projektu z druku 3D</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">kontakt z klientem&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">odpowiedzi na zapytania do klienta oraz przeprowadzenie szczeg&oacute;łowej analizy potrzeb klienta tak by m&oacute;c przygotować wycenę we wsp&oacute;łpracy z technologiem i wzornikiem</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">bieżąca komunikacja z zespołem i klientem</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Grafik</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">projektowanie i przygotowanie modelu 3D</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Technolog</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">konsultacje z handlowcem&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">przeprowadzenie procesu druku i post procesu (wygładzenie, malowanie, chromowanie, klejenie)</span></li>
</ul>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: </span><strong>Handlowiec</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2020-2022 Przedstawiciel handlowy - druk 3D</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Systemy typu CRM***</span></p>
<p><span style="font-weight: 400;">Pakiet Office 365 ***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Kurs druku 3D</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Certyfikat językowy&nbsp;TELC&nbsp;(poziom B2:&nbsp;angielski, niemiecki)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Certyfikat Akademii EITCA/BI Informatyki Biznesowej</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> Znajomość podstaw druku 3D</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr Zarządzania</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, logiczne myślenie, praca pod presją czasu</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko:&nbsp; <strong>Grafik</strong></span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2015-2016 &ndash; Stażysta w firmie druku 3D</span></p>
<p><span style="font-weight: 400;">2016-2018 &ndash; </span><span style="font-weight: 400;">Młodszy technolog</span></p>
<p><span style="font-weight: 400;">2018-2022 &ndash; Grafik 3D</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">CAD/CAM****</span></p>
<p><span style="font-weight: 400;">Blender***</span></p>
<p><span style="font-weight: 400;">Asana***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Kurs &ldquo;Grafik 3D &ndash; Blender&rdquo;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Certyfikat Akademii EITCA/CG Grafiki Komputerowej</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Technologia druku 3D &ndash; zasady modelowania i projektowania 3D</span></p>
<p><span style="font-weight: 400;">Obsługa oprogramowania CAD/CAM</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr ASP,&nbsp; w specjalności wzornictwo przemysłowe</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Poczucie estetyki, samodzielność w działaniu, kreatywność</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: <strong>Technolog</strong></span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Robert Biela</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2021 - Mechanik Maszyn i Urządzeń Produkcyjnych</span></p>
<p><span style="font-weight: 400;">2021-2022 - Technolog</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">CAD/CAM**</span></p>
<p><span style="font-weight: 400;">Asana ***</span></p>
<p><span style="font-weight: 400;">FDM ****</span></p>
<p><span style="font-weight: 400;">SLS ***</span></p>
<p><span style="font-weight: 400;">DLP ***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Certyfikat kursu CAD/CAM</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wiedza</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Znajomość technologii druku 3D</span></li>
</ul>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Obsługa slicera,</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Obsługa drukarek 3D</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wykształcenie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Inżynier mechatronik</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Kompetencje miękkie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Kreatywność; inicjatywa oraz dynamika w działaniu; Umiejętność pracy samodzielnej jak i zespołowej; dokładność i odpowiedzialność w działaniu</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>`;

export default html;
