import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pl from "./locales/pl.json";

const resources = {
  pl: {
    translation: pl,
  },
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pl",
  debug: true,
  fallbackingLng: "pl",
  compatibilityJSON: "v3",
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
