const html = `<p style="text-align: justify;"><strong>Gł&oacute;wne obowiązki:</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">koordynacja prac zespołu developerskiego (w tym przygotowywanie zadań dla zespołu deweloperskiego oraz ich weryfikacja i koordynacja)</p>
    </li>
    <li>
        <p style="text-align: justify;">zbieranie wymagań biznesowych, tworzenie technicznej dokumentacji projektowej</p>
    </li>
    <li>
        <p style="text-align: justify;">zapewnienie jakości danych oraz definiowanie i utrzymanie słownik&oacute;w biznesowych</p>
    </li>
    <li>
        <p style="text-align: justify;">szkolenie i wsparcie innych członk&oacute;w zespołu technicznego</p>
    </li>
    <li>
        <p style="text-align: justify;">przygotowywanie architktury/struktury całości rozwiązania</p>
    </li>
    <li>
        <p style="text-align: justify;">zaprojektowanie modelu hurtowni danych, określenie nazw tabel</p>
    </li>
    <li>
        <p style="text-align: justify;">zdefiniowanie spos&oacute;b&oacute;w pobierania i przepływu danych</p>
    </li>
    <li>
        <p style="text-align: justify;">zapewnienie wysokiej jakości kodu i wydajności systemu</p>
    </li>
    <li>
        <p style="text-align: justify;">wycena koszt&oacute;w realizacji prac, w tym dodatkowych koszt&oacute;w wynikających ze zmiany wymagań w trakcie projektu&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Wymagane technologie: </strong></p>
<p style="text-align: justify;">SQL, MS SQL, Power BI, Azure Data Factory, Azure Analysis Services, Azure DevOps, DAX, Oracle DB&nbsp;</p>
<p style="text-align: justify;"><strong>Pozostałe wymagania: </strong></p>
<ul>
    <li>
        <p style="text-align: justify;">wykształcenie wyższe informatyczne/techniczne</p>
    </li>
    <li>
        <p style="text-align: justify;">doświadczenie na podobnym stanowisku min. 1 rok i na stanowisku pokrewnym, np. Data Engeneer przez min. 5 lat</p>
    </li>
    <li>
        <p style="text-align: justify;">certyfikaty: Azure Foundation , Azure Data Engineer</p>
    </li>
    <li>
        <p style="text-align: justify;">umiejętność posługiwania się ramami architektonicznymi, znajomość szablon&oacute;w i sposob&oacute;w opisu architektury system&oacute;w informatycznych</p>
    </li>
    <li>
        <p style="text-align: justify;">doświadczenie w projektowaniu architektury złożonych, skalowalnych system&oacute;w IT</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość hurtowni danych oraz wiedza nt. struktur danych</p>
    </li>
    <li>
        <p style="text-align: justify;">doświadczenie w tworzeniu architektury rozwiązań ETL</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomości rozwiązań analitycznych klasy BI oraz narzędzi integracji danych (ETL)</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość proces&oacute;w CI/CD</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość reguł programowania KISS (keep it simple) i DRY (don&rsquo;t repeat yourself)</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość zasad optymalizacji kosztowej i wydajnościowej usług chmurowych</p>
    </li>
</ul>`;

export default html;
