const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-left: -6pt; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Informacje, kt&oacute;re należy przekazać podczas spotkania rozpoczynającego realizację projektu z obszaru wdrożenia technologii ML/AI</span></strong></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Celem projektu jest zbudowanie narzędzia prognozującego popyt. To narzędzie jest ważne dla firmy, aby zoptymalizować koszty i zarządzanie oraz poprawić satysfakcję klient&oacute;w.&nbsp; Jest ważne abyście mieli świadomość, że jest to jeden z priorytetowych projekt&oacute;w firmy na ten rok.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 13.85pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Widzicie tu poszczeg&oacute;lne kroki w projekcie wraz z zakładanymi przez nas terminami. Za chwilę om&oacute;wimy kto jest odpowiedzialny za poszczeg&oacute;lne zadania.</span></p>
</td>
</tr>
<tr style="height: 30.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">My, jako wykonawca, zbudujemy narzędzie i poprowadzimy projekt. Będziemy od Was potrzebować wsparcia w obszarze dostępu do system&oacute;w oraz danych oraz wsparcia merytorycznego w obszarze Waszych potrzeb biznesowych. Pomocny byłby harmonogram dostępności członk&oacute;w Waszego zespołu. Pomoże nam to zaplanować działania.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 10.5pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowałem macierz RACI. Chciałbym ją teraz zaprezentować.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 37.8pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do rozpoczęcia pracy potrzebujemy dostęp&oacute;w do Państwa system&oacute;w. Tu mam listę os&oacute;b, kt&oacute;re potrzebują kont. Przekazuję ją Administratorowi IT. Proszę także o stworzenie kont do usług chmurowych na Waszym tenancie [grupa użytkownik&oacute;w dzielących określony dostęp do uprawnień i zasob&oacute;w w chmurze].&nbsp;</span></p>
</td>
</tr>
<tr style="height: 14.65pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Proponuję zorganizować spotkania statusowe w naszym gronie co 2 tygodnie, na początku każdego sprintu.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 3.1pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Spotkajmy się wyjątkowo już za tydzień, aby potwierdzić, że udało nam się skutecznie zrealizować wszystkie zadania związane z otwarciem projektu.</span></p>
</td>
</tr>
<tr style="height: 1.05pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dziękuję za spotkanie. Zostawiam moje dane do kontaktu.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
