const html = `<h3 style="text-align: center;"><strong>CASE STUDY PROJEKTU NR 1&nbsp;</strong></h3>
<p><strong>Tytuł projektu: Automatyzacja procesu&nbsp; dodawania rekrutowanych pracownik&oacute;w do systemu ERP na podstawie input&oacute;w (danych wejściowe) w formie google sheets (arkusze kalkulacyjne)</strong></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Czasochłonne przepisywanie danych rekrutowanych pracownik&oacute;w z google sheets (generowanych na podstawie wypełnionych ankiet) do systemu ERP przez rekrutera</span></p>
<p><strong>Kr&oacute;tki opis projektu:</strong><span style="font-weight: 400;"> Projekt polegał na zautomatyzowaniu procesu wprowadzania danych kandydat&oacute;w do systemu ERP. Robot codziennie o godz. 24 kopiuje informacja z google sheets, otwiera system ERP, wkleja dane i w oparciu o informacje z google sheets wybiera odpowiednie działy do kt&oacute;rych przesyła informacje o kandydacie.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Znaczne odciążenie rekrutera w zakresie działań czysto administracyjnych, przez co może skupić się na lepszym poznaniu kandydat&oacute;w (więcej czasu na prace merytoryczne).</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie:</strong><span style="font-weight: 400;"> GSuit, narzędzie RPA &ndash; UIPath , system ERP.</span></p>
<p><strong>Czas realizacji projektu: </strong><span style="font-weight: 400;">4 tygodnie</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">10 tys. zł (development) + koszt zakupu laptopa + 1,3 tys EUR rocznie (licencja na oprogramowanie do zarządzania robotami/uruchamiania proces&oacute;w) + 3,3 tys. EUR rocznie (licencja deweloperska, umożliwiająca programowanie robot&oacute;w)&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">1 Developer RPA (pełni funkcję developera i architekta &ndash; tj. przeprowadza analizę procesu z klientem, programuje, tworzy dokumentację)</span></p>`;

export default html;
