import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GameType from "../../../interfaces/game/GameType";
import { AppDispatch, AppState } from "../../../store/store";
import { categoriesImages } from "../../../constants/games";
import StyledButton from "../../components/StyledButton";
import { useNavigate } from "react-router-dom";
import GamesShow from "../../components/GamesShow";
import {
  add_editUserGame,
  getGames,
  getUserGames,
} from "../../../services/game.service";
import UserGameType from "../../../interfaces/game/UserGameType";
import FullGameType from "../../../interfaces/game/FullGameType";
import LinearProgress from "@mui/material/LinearProgress";
import favouriteFullIcon from "../../../assets/images/icons/favourite_full_icon.svg";
import favouriteEmptyIcon from "../../../assets/images/icons/favourite_empty_icon.svg";
import configuredGameIcon from "../../../assets/images/icons/configured_game_icon.svg";
import testIcon from "../../../assets/images/icons/test_icon.svg";
import learnIcon from "../../../assets/images/icons/learn_icon.svg";
import alertIcon from "../../../assets/images/icons/alert_icon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import { GameModeType } from "../../../enums/GameModeType";
import { Tooltip } from "@mui/material";

const MyGamesScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const userGames: UserGameType[] = useSelector(
    (state: AppState) => state.gameReducer.userGames
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.gameReducer.isFetching
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  useEffect(() => {
    if (userId && dispatch) {
      dispatch(getUserGames(userId));
      dispatch(getGames(true, true));
    }
  }, [dispatch, userId]);

  const addGameToFavourite = (gameId: string, game: UserGameType | null) => {
    dispatch(
      add_editUserGame({
        userId,
        gameId,
        isFavourite: game ? !game.isFavourite : true,
        isFinished: game ? !!game.isFinished : false,
        isRecommended: game ? !!game.isRecommended : false,
        isStarted: game ? !!game.isStarted : false,
        isGiven: game ? !!game.isGiven : false,
      })
    );
  };

  const renderGameCardFromGame = (game: GameType) => {
    const category = gamesData.find(
      (category) => category.key === game.categoryKey
    )!;

    let gameData = category.games.find((item) => game.key === item.key);
    if (gameData) {
      gameData = {
        ...gameData,
        subtasks: gameData.subtasks.filter((item) =>
          game.gameSubtasks.some((subtask) => subtask === item.key)
        ),
      };
    }
    const userGame = userGames.find((item) => item.gameId === game.id);
    const fullGame: FullGameType | null = userGame
      ? {
          ...userGame,
          ...gameData!,
        }
      : null;

    const getFinishedText = () =>
      fullGame!.isFinished
        ? t("myGamesScreen.finished")
        : fullGame!.isStarted
        ? t("myGamesScreen.gameIsFinished")
        : t("myGamesScreen.gameIsNotStarted");

    if (!gameData) return null;

    return (
      <div className="gameCard" key={game.id}>
        <div className="category">
          {category && categoriesImages[category.key] && (
            <img src={categoriesImages[category.key]} alt={category.key} />
          )}
          {category.categoryName}
          <div className="icons">
            {fullGame && fullGame.isGiven && (
              <img src={alertIcon} alt="given" />
            )}
            {game.gameMode && (
              <img
                src={
                  game.gameMode === GameModeType.LEARN ? learnIcon : testIcon
                }
                alt="gameMode"
              />
            )}
            {game.userCreatorId && (
              <img src={configuredGameIcon} alt="configured" />
            )}
            <img
              src={
                fullGame && fullGame.isFavourite
                  ? favouriteFullIcon
                  : favouriteEmptyIcon
              }
              alt="favourite"
              className="clickableIcon"
              onClick={() =>
                addGameToFavourite(game.id, fullGame ? fullGame : null)
              }
            />
          </div>
        </div>
        <div className="title">
          {gameData.subtasks.length === 1
            ? gameData.subtasks[0].title
            : gameData.gameTitle}
        </div>
        <div className="progress">
          {fullGame ? (
            <>
              <span>{getFinishedText()}</span>
              {!fullGame.isFinished && fullGame.isStarted && (
                <span className="subtasksFinished">{`${fullGame.subtasksFinished} / 5 Gry`}</span>
              )}
            </>
          ) : (
            "Gra nierozpoczęta"
          )}
        </div>

        <div className="score">
          {!fullGame || !fullGame.isFinished ? (
            <span className="noScore">{t("myGamesScreen.noScore")}</span>
          ) : (
            <>
              <span>{t("myGamesScreen.yourScore")}</span>
              <LinearProgress
                className="progressBar"
                variant="determinate"
                value={fullGame.score}
              />
              <span>{`${fullGame.score}%`}</span>
            </>
          )}
        </div>
        <StyledButton
          label={t("myGamesScreen.goToGame")}
          onClick={() => navigate(`/game/${game.id}`)}
          type="contained"
          color="dark"
        />
      </div>
    );
  };

  const renderGameCard = (userGame: UserGameType) => {
    let category = gamesData.find(
      (category) => category.key === userGame.categoryKey
    )!;
    let gameData = category.games.find((item) => userGame.key === item.key);
    if (gameData) {
      gameData = {
        ...gameData,
        subtasks: gameData.subtasks.filter((item) =>
          userGame.gameSubtasks.some((subtask) => subtask === item.key)
        ),
      };
    }
    const game = games && games.find((item) => item.id === userGame.gameId);
    const fullGame: FullGameType = {
      ...userGame,
      ...gameData!,
    };

    const finishedText = userGame.isFinished
      ? t("myGamesScreen.finished")
      : userGame.isStarted
      ? t("myGamesScreen.gameIsFinished")
      : t("myGamesScreen.gameIsNotStarted");

    return (
      <div className="gameCard" key={userGame.gameId}>
        <div className="category">
          {category && categoriesImages[category.key] && (
            <img src={categoriesImages[category.key]} alt={category.key} />
          )}
          {category.categoryName}
          <div className="icons">
            {userGame.isGiven && (
              <>
                <img src={alertIcon} alt="given" />
              </>
            )}
            {game && game.gameMode && (
              <img
                src={
                  game.gameMode === GameModeType.LEARN ? learnIcon : testIcon
                }
                alt="gameMode"
              />
            )}
            {userGame.userCreatorId && (
              <img src={configuredGameIcon} alt="configured" />
            )}
            <img
              src={
                userGame.isFavourite ? favouriteFullIcon : favouriteEmptyIcon
              }
              alt="favourite"
              className="clickableIcon"
              onClick={() => addGameToFavourite(userGame.gameId, userGame)}
            />
          </div>
        </div>
        <div className="title">
          {fullGame.subtasks.length === 1
            ? fullGame.subtasks[0].title
            : fullGame.gameTitle}
        </div>
        <div className="progress">
          <span>{finishedText}</span>
          {!userGame.isFinished && userGame.isStarted && (
            <span className="subtasksFinished">{`${userGame.subtasksFinished} / 5 Gry`}</span>
          )}
        </div>
        <div className="score">
          {!userGame.isFinished ? (
            <span className="noScore">{t("myGamesScreen.noScore")}</span>
          ) : (
            <>
              <span>{t("myGamesScreen.yourScore")}</span>
              <LinearProgress
                className="progressBar"
                variant="determinate"
                value={userGame.score}
              />
              <span>{`${userGame.score}%`}</span>
            </>
          )}
        </div>
        <StyledButton
          label={t("myGamesScreen.goToGame")}
          onClick={() => navigate(`/game/${userGame.gameId}`)}
          type="contained"
          color="dark"
        />
      </div>
    );
  };

  const renderSection = (
    games: (UserGameType | GameType)[],
    sectionHeader: string,
    fromGameType?: boolean,
    tooltip?: string
  ) => {
    return (
      <div className="section">
        {tooltip && (
          <Tooltip title={tooltip} arrow>
            <div className="sectionHeader">{sectionHeader}</div>
          </Tooltip>
        )}
        {!tooltip && <div className="sectionHeader">{sectionHeader}</div>}
        {isFetching ? (
          <div className="progressWrapper">
            <CircularProgress />
          </div>
        ) : games.length > 0 ? (
          <GamesShow
            games={games}
            renderGameCard={(game) =>
              fromGameType
                ? renderGameCardFromGame(game as GameType)
                : renderGameCard(game as UserGameType)
            }
          />
        ) : (
          <div className="noGames">{"Brak gier danej kategorii"}</div>
        )}
      </div>
    );
  };

  return (
    <div className="myGameScreen">
      <div className="topSection">
        <div className="header">{"Moje gry"}</div>
        {renderSection(
          userGames.filter((game) => game.isStarted && !game.isFinished),
          "Rozpoczęte gry"
        )}
      </div>
      <div className="bottomSection">
        {games &&
          renderSection(
            games.filter((game) => game.userGame && game.userGame.isGiven),
            "Zadane do wykonania",
            true
          )}
        {renderSection(
          userGames.filter((game) => game.isFavourite),
          "Dodane do ulubionych"
        )}
        {games &&
          renderSection(
            games.filter((game) => game.userCreatorId === userId),
            "Skonfigurowane  przeze mnie",
            true
          )}
        {renderSection(
          userGames.filter((game) => game.isFinished),
          "Ukończone gry"
        )}
        {renderSection(
          userGames.filter((game) => game.isRecommended),
          "Rekomendowane *",
          undefined,
          "Gry w tej kategorii pojawią się po rozegraniu przez Ciebie pierwszej gry. Wybór rekomendowanych gier dokonywany jest przy użyciu algorytmu uwzględniającego Twoją dotychczasową aktywność w aplikacji Digi Leader."
        )}
      </div>
    </div>
  );
};

export default MyGamesScreen;
