const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadania szczeg&oacute;łowe w ramach prac ramowych harmonogramu projektu automatyzacji proces&oacute;w z wykorzystaniem RPA</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza procesu wprowadzania danych z ankiet do systemu ERP&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Poznanie procesu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Opisanie procesu w obecnej formie (as is).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadawanie pytań ekspertom o błędy, formę plik&oacute;w i inne aspekty.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadawanie pytań w zakresie optymalizacji procesu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowanie dokumentu z opisem krok&oacute;w do wykonania przez robota.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nanoszenie zmian w dokumentach w efekcie pojawienia się propozycji usprawnień.&nbsp;</span></li>
</ol>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowanie środowisk programistycznych (dev, test, prod) wraz ze stworzeniem dostęp&oacute;w do system&oacute;w, do kt&oacute;rych dostęp jest elementem zautomatyzowanego procesu&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po stronie klienta (zadania szczeg&oacute;łowe zależą od system&oacute;w wewnętrznych i proces&oacute;w IT w firmie klienta).&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Design to develop (stworzenie szczeg&oacute;łowej makiety procesu i oszacowanie czasu developmentu poszczeg&oacute;lnych moduł&oacute;w/element&oacute;w procesu, dodanie task&oacute;w do backlogu)</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Poznanie procesu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Opisanie procesu w obecnej formie (as is).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadawanie pytań ekspertom o błędy, formę plik&oacute;w i inne aspekty.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadawanie pytań w zakresie optymalizacji procesu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowanie dokumentu (SDD) tj. mapy procesu, pokazującej kroki do wykonania przez robota w formie graficznej i tekstowej.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nanoszenie zmian w dokumentach w efekcie pojawienia się propozycji usprawnień.</span></li>
</ol>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Programowanie i cykliczne testowanie kolejnych moduł&oacute;w (budowanie robota)</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przygotowanie środowiska &ndash; tj. wyłączenie wszystkich aplikacji na serwerze, kt&oacute;re mogłoby obciążać działanie narzędzia RPA.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza dokumentu, tj mapy procesu.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie narzędzi RPA.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Sprawdzenie dostęp&oacute;w do system&oacute;w i plik&oacute;w.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Utworzenie pliku raportowego (raport z działania robota).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Programowanie kolejne kroki działania robota zgodnie z tzw. happy path z mapy proces&oacute;w.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Programowanie alternatywne ścieżki działania robota (wyjątki).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Sprawdzenie poprawność efekt&oacute;w działania robota.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Programowanie moduł&oacute;w do wyłączania system&oacute;w, oczyszczania środowiska etc.&nbsp;</span></li>
</ol>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Quality Assurance (sprawdzenie techniczne)</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie robota.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Obserwacja pracy działania robota.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza jakości kodu działania robota.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przekazanie informacji zwrotnej deweloperowi.&nbsp;</span></li>
</ol>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Testy z klientem i poprawa zidentyfikowanych błęd&oacute;w&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dostarczenie scenariuszy testowych przez klienta&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie robota na środowisku testowym&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Sprawdzenie przez klienta efekt&oacute;w pracy robota&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Otrzymanie informacji zwrotnej od klienta&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Poprawa procesu działania robota zgodnie z uwagami</span></li>
</ol>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 10pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie robota na środowisku produkcyjnym (deployment) oraz przygotowanie dokumentacji technicznej (Solution Design Document)</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ol style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Sprawdzenie dostępności kont produkcyjnych&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przeniesienie kodu na środowisko produkcyjne</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uruchomienie robota</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przekazanie dokumentacji klientowi&nbsp;</span></li>
</ol>
</td>
</tr>
</tbody>
</table>`;

export default html;
