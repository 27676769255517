const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="border-left: solid #000000 0.5pt; border-right: solid #000000 0.5pt; border-bottom: solid #000000 1pt; border-top: solid #000000 0.5pt; vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przykłady wymagań szczeg&oacute;łowych w projekcie wdrożenia VR</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: middle; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dane wykorzystane jako treści szkolenia oraz dane o uczestnikach szkoleń i wynikach mają być przechowywane w sieci wewnętrznej (VPN) i nie mogę przechodzić przez sieć zewnętrzną.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: middle; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po ukończeniu szkolenia aplikacja wygenerować ma raport z wynikiem uczestnika, zawierający: informacja o wyniku szkolenia, wykaz popełnionych błęd&oacute;w, czas ukończenia szkolenia.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: middle; padding: 5pt 5pt 5pt 5pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Elementy interaktywne szkolenia to naciskanie i regulowanie przycisk&oacute;w maszyn produkcyjnych, co wywołać ma odpowiednią reakcję maszyny w VR</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
