const html = `<h3 style="text-align: center;"><strong>Wykaz technologii mających zastosowanie w projektach IoT wraz z opisem i przykładami</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Technologia/</strong><strong>narzędzie&nbsp;</strong></p>
</td>
<td>
<p><strong>Opis&nbsp;</strong></p>
</td>
<td>
<p><strong>Umiejętności wspierające obsługę technologii</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Infrastruktura sieciowa (routery, bramy sieciowe, VPN, karty SIM)</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> podłączenie Internetu do urządzeń,rozdzielenie sygnału internetowego między r&oacute;żne urządzenia.</span></p>
<p><span style="font-weight: 400;">Dzięki infrastrukturze sieciowej możliwe jest stworzenie rozwiązania działającego w oparciu o chmurę obliczeniową i zwiększenie jego niezawodności.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> przewody internetowe, światłow&oacute;d, switch, router, wi-fi.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Doświadczenie w administracji systemami operacyjnymi, studia o specjalizacji sieci komputerowe, doświadczenie w projektach z domeny telekomunikacji, praca przy infrastrukturze sieciowej jako na przykład serwisant. PROGRAMOWANIE w C/C++.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Urządzenia brzegowe, tzw. edge</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><strong>Umożliwiają</strong><span style="font-weight: 400;"> pobieranie danych z czujnik&oacute;w/sensor&oacute;w (zbierających dane telemetryczne) i przetwarzanie ich zgodnie z modelem obliczeniowym.</span></p>
<p><span style="font-weight: 400;">Edge odpowiedzialne są za wysyłanie informacji sterującej do urządzenia oraz wysłanie danych do chmury. Edge to komputer klasy industry, zapewniający reagowanie przez urządzenie na sygnały (zmiany parametr&oacute;w, danych) w czasie rzeczywistym, w tym w warunkach offline. W przemyśle, gdzie połączenia online nie są łatwo dostępne, daje to możliwość stałej, dwukierunkowej komunikacji, między urządzeniem a systemem sterującym.</span></p>
<p><span style="font-weight: 400;">Model obliczeniowy, kt&oacute;ry na bieżąco przelicza dane i &nbsp; wysyła na ich podstawie do urządzenia&nbsp; odpowiednią informację sterującą (zgodnie z wdrożonym algorytmem), &nbsp; działa na egdu cały czas (nieprzerwanie). Po przywr&oacute;ceniu Internetu edge wysyła wyniki obliczeń do chmury, w tym informacje o zdarzeniach kt&oacute;re miały miejsce. Pozwala to na udoskonalenie modelu uczenia maszynowego, wykorzystywanego do sterowania danym elementem zarządzanego automatycznie procesu (może być to model obliczeniowy działający na edgu lub logika zaimplementowana bezpośrednio w części chmurowej rozwiązania jako program sterujący urządzeniami).</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">hpe edgeline, b&amp;r edgeline</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość sprzętowych zagadnień bezpieczeństwa (HSM, TPM), znajomość protokoł&oacute;w sieciowych takich jak mqtt, amqp, opc ua, modbus, znajomość zagadnień konteneryzacji oraz technologii docker, programowanie w dowolnym języku wspieranym przez orkiestrator docker, znajomość zasad użycia certyfikat&oacute;w w zabezpieczaniu komunikacji.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Protok&oacute;ł komunikacji urządzeń infrastruktury fizycznej z edgem</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> dwukierunkową komunikację pomiędzy edgem a urządzeniem będącym źr&oacute;dłem danych.</span></p>
<p><span style="font-weight: 400;">Producenci urządzeń definiuje spos&oacute;b lub sposoby w jakie można wymieniać dane z urządzeniem.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Modbus, wi-fi, OPC, legacy (specyficzne dla danej klasy urządzeń), np. X10, Insteon</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość mqtt, amqp, mosquito, service bus, sds, modbus, opc da/had/ae/ua.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Chipset TPM</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> zabezpieczenie sprzętu edge (Hardware security module) poprzez wykorzystanie zapisanego stanu domyślnego tego urządzenia. Przy logowaniu sprawdzany jest zapisany stan rejestru.</span></p>
<p><span style="font-weight: 400;">Chipset TPM musi być zgodny z następującym standardem: ISO/IEC 11889</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">TPM 2.0</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość zagadnień związanych z HSM oraz doświadczenie z TPM 1.4/2.0.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Lokalna baza danych telemetrycznych</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> przechowywanie na urządzeniu typu edge,&nbsp; mierzonych wartości nawet w przypadku braku dostępu do internetu.</span></p>
<p><span style="font-weight: 400;">Po uzyskaniu dostępu dane z bazy lokalnej&nbsp; mogą być synchronizowane z chmurą.</span></p>
<p><strong>Przykłady</strong><span style="font-weight: 400;">: Influxdb, Mongodb, Nagios telemetry</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość telemetrii, baz typu influxdb i mongo.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Silnik przetwarzania danych</span></p>
</td>
<td>
<p><span style="font-weight: 400;"><strong>Umożliwia</strong>&nbsp; przetwarzanie&nbsp; zebranych danych w spos&oacute;b zgodny z określoną domeną (danymi wymaganiami biznesowymi) oraz daje możliwość wdrożenia algorytm&oacute;w opartych na sztucznej inteligencji.</span></p>
<p><span style="font-weight: 400;">Silnik stanowi warstwę implementacyjną logiki biznesowej, umożliwia przechowywanie danych&nbsp; zintegrowanych z wielu źr&oacute;deł&nbsp; w oczekiwanej formie (np. anonimizacja danych, agregacja w ten spos&oacute;b by zmniejszyć wolumen przechowywanych danych)&nbsp;</span></p>
<p><span style="font-weight: 400;">Wyb&oacute;r tzw. stacku technologicznego (technologii wybranych do realizacji projektu) wpływa na wyb&oacute;r silnika przetwarzania danych.&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">Rodzaje silnik&oacute;w przetwarzania danych</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">-cloud native (kontenery)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">serverless (deweloper dostaje środowisko tworzenia kodu/konfigurator w przeglądarce, w efekcie powstaje skonfigurowana aplikacja, połączona z bazami danych i źr&oacute;dłami&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">serwery/maszyny wirtualne</span></li>
</ul>
<p><span style="font-weight: 400;">&nbsp;</span><strong>Przykłady</strong><span style="font-weight: 400;">: DataBricks, Azure Synapse, Serverless, Kubernetes</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość k8s, funkcji Azure, lambda, konteneryzacji, programowanie w językach wspieranych przez demona konteneryzacji, doświadczenie projektowe w domenie danego rozwiązania (specyficzne algorytmy), znajomość AI/ML, jupiter notes (python framework for ML), znajomość wzorcy projektowych takich jak microservice mesh, microservice ekosystem, api Gateway, fasada.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Kontrakt danych (format danych jaki ma trafić do chmury)</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> porozumiewanie się ze sobą wielu niezależnych system&oacute;w. Platforma IoT przetwarzać może r&oacute;żne formaty danych, wyb&oacute;r kontraktu jest niezbędny, by opracować strategię ujednolicania danych do jednego formatu, np. przy wykorzystaniu connector&oacute;w lub gotowych serwis&oacute;w.&nbsp;</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">OPC, CSV, Apache Parquet and Apache Avro</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Doświadczenie z big data oraz znajomość format&oacute;w Parquet i Avro.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Chmura publiczna</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> synchronizację wielu środowisk (np. kilku wiatrak&oacute;w zlokalizowanych w r&oacute;żnych krajach), co umożliwia por&oacute;wnywanie r&oacute;żnych zestaw&oacute;w danych. Chmura zapewnia prawie nieograniczone możliwości w zakresie przetwarzania, przechowywania, disaster recovery, governance i wysokiej dostępności danych i rozwiązań, w tym w warunkach rozbudowy rozwiązania, dużego przyrostu danych etc.</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Azure , Google Cloud Platform, AWS</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Doświadczenie projektowe w domenie chmur publicznych i hybrydowych. Zagadnienia związane z monitoringiem, wysoką dostępnością itp. </span><span style="font-weight: 400;">Azure IoT portfolio (iot hub, event hub, data explorer, time series insights, api management service)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Środowisko Data Science do analizy zebranych danych wyposażone w silnik analityczny</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
</td>
<td>
<p><strong>Umożliwia </strong><span style="font-weight: 400;">tworzenie algorytm&oacute;w opartych na sztucznej inteligencji. Dodatkowo umożliwia kolaborację wielu członk&oacute;w jednego zespołu, tworzenie, trenowanie, testowanie, produkcyjne wdrożenie modeli ML. Jest to narzędzie dedykowane Data Scientistom, służące m.in. do tworzenia rozwiązań AI, nie wymagające szczeg&oacute;łowej znajomości infrastruktury chmurowej, dzięki czemu mogą skupić się na tworzeniu wartości intelektualnej, nie martwiąc się o infrastrukturę.</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Data Brics, Synaps, Hadoop&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość budowania platform typu PaaS i tworzenia środowisk uruchomieniowych.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Jezioro danych</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> przechowywanie dużej ilości i dużego rozmiaru plik&oacute;w z danymi, danych nieustrukturyzowanych (w hurtowni danych przechowywane są dane ustrukturyzowane), danych wykorzystywanych do trenowania modeli ML/AI. R&oacute;żne projekty/działy w organizacji mogę mieć wydzielone osobne przestrzenie w jeziorze danych (tzw. directory, określające obszar dostępu do danych danej grupy użytkownik&oacute;w). Jezioro danych jest podstawowym źr&oacute;deł danych wykorzystywanych w pracach prowadzonych przez Data Scientist&oacute;w</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Blob Storage Azure, AWS S3, Snowflake</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Doświadczenie projektowe z data lake od Azure/AWS s3 lub Snowflake.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Oparty na rolach&nbsp; mechanizm kontroli dostępu w systemach komputerowych (</span><span style="font-weight: 400;">Role Based Access Control, RBAC)</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> nadanie danej roli w systemie (użytkownikom z daną rolą) dostępu do danego zestawu danych.</span></p>
<p><span style="font-weight: 400;">RBAC polega na zdefiniowaniu tzw. r&oacute;l z kt&oacute;rymi wiąże się określony zakres obowiązk&oacute;w i uprawnień, r&oacute;żnym użytkownikom systemu. Użytkownik może posiadać wiele przypisanych r&oacute;l. Rola może być przypisana wielu użytkownikom.</span></p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Active Directory (MS), AWS Cognito wraz z API Management (wzorzec projektowy)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość narzędzi dostarczania tożsamości, autentykacji i autoryzacji. Znajomość federacji dostawc&oacute;w tożsamości wspierających znane standardy jak np.&nbsp; Oauth 2.0. </span><span style="font-weight: 400;">Azure Active Directory, Api management service.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Aplikacja do wizualizacji danych/raportowania typu SaaS</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> automatyczne generowanie dashboard&oacute;w z raportami zawierającymi określone dane lub wskaźniki, wyliczone zgodnie z regułami biznesowymi. Raporty generowane są zgodnie z wymaganą częstotliwością i udostępniane określonym grupom użytkownik&oacute;w. Utrzymanie i aktualizowanie aplikacji leży po stronie tzw. vendora, tj. producenta aplikacji. Za korzystanie z aplikacji pobierana jest opłata subskrypcyjna (opłacana miesięcznie lub rocznie).</span></p>
<p><strong>Przykłady: </strong><span style="font-weight: 400;">Power BI, Tableau</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość power BI, Tableau, programowanie w Javascript (Angular/react) znajomość wzorca micro frontends, znajomość HTML 5.0 i CSS3, znajomość konteneryzacji.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Kubernetes, K8s (otwartoźr&oacute;dłowa platforma do aplikacji kontenerowych</span><span style="font-weight: 400;">)</span></p>
</td>
<td>
<p><strong>Umożliwia </strong><span style="font-weight: 400;">orkiestrację ekosystemem mikrokontroler&oacute;w stanowiącym logikę biznesową całego rozwiązania. Stanowi silnik kalkulacji i pozwala na osadzenie modeli ML, udostępnienie ruchu od użytkownik&oacute;w do kontener&oacute;w. Automatycznie zarządza cyklem życia kontener&oacute;w, rozprowadzając je po infrastrukturze hostingowej</span><span style="font-weight: 400;">. Skaluje r&oacute;wnież zasoby w g&oacute;rę lub w d&oacute;ł, w zależności od zapotrzebowania. Udostępnia&nbsp; kontenery, tworzy ich harmonogramy, usuwa je oraz monitoruje ich stan. Kontener to </span><span style="font-weight: 400;">struktura danych, kt&oacute;rej zadaniem jest przechowywanie w zorganizowany spos&oacute;b zbioru danych (obiekt&oacute;w)</span><span style="font-weight: 400;">. Kontener zapewnia narzędzia dostępu, w tym dodawanie, usuwanie i wyszukiwanie danej (obiektu) w kontenerze.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">NodeJS, java, .net, docker</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Docker (otwarte oprogramowanie służące do wirtualizacji)</span></p>
</td>
<td>
<p><strong>Umożliwia</strong><span style="font-weight: 400;"> enkapsulacje logiki biznesowej w formie obraz&oacute;w kt&oacute;re są uruchamiane z właściwymi parametrami jako kontenery. Ułatwia aktualizowanie systemu operacyjnego i innych element&oacute;w, kt&oacute;re zmieniają się w zależności od środowiska. Dz</span><span style="font-weight: 400;">iała jako platforma dla programist&oacute;w i administrator&oacute;w do tworzenia, wdrażania i uruchamiania aplikacji rozproszonych. Upraszcza tworzenie łatwego do dystrybucji artefaktu dowolnej aplikacji, wdrażanie go na dużą skalę w dowolnym środowisku.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">NodeJS, java, docker, C</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
