const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Ustalenia będące efektem audytu kompetencji marketingowych zespołu </strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Firma posiada&nbsp;<strong>wysokie </strong>kompetencje w zakresie&nbsp;<strong>copywritingu</strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Firma posiada&nbsp;<strong>niskie </strong>kompetencje w zakresie&nbsp;<strong>obsługi medi&oacute;w społecznościowych</strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Firma ma <strong>podstawową </strong>wiedzę dotyczącą analityki internetowej, ale jest ona <strong>niewystarczająca</strong></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
