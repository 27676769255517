const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Usługi cyfrowe umożliwiające pogłębianie transformacji cyfrowej po wdrożeniu RPA w organizacji</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.3800000000000001; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Propozycja usługi&nbsp;</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.3800000000000001; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Uzasadnienie&nbsp;</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Automatyzacja kolejnych proces&oacute;w z użyciem RPA&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Posiadane już know how w zakresie wsp&oacute;łpracy z Państwa firmą, pozwala na zwiększenie szybkości i efektywności&nbsp; wdrażania kolejnych proces&oacute;w automatyzacji z użyciem RPA.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Analiza potencjału automatyzacji i optymalizacji proces&oacute;w biznesowych&nbsp;</span></p>
<br /><br /></td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Architekt RPA podjąć się może analizy proces&oacute;w biznesowych i zaproponować optymalizacje. W razie potrzeby może przygotować dokumentację oraz wycenę wykonania prac.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Usługa wsparcia technicznego i utrzymania&nbsp;</span></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W razie pojawienia się błęd&oacute;w lub zmian w procesie podjęte zostaną działania w zakresie naprawy i dostosowania pracy działania robota.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
