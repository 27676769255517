const html = `<h3 style="text-align: center;"><strong>Opisy propozycji usług IT wraz z uzasadnieniem</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja usługi/rozwiązania technologicznego</strong></p>
</td>
<td>
<p><strong>Uzasadnienie&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie środowiska do projektowania w Autocad opartego o maszyny wirtualne uruchomione w chmurze obliczeniowej&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Skorzystanie z usługi wyeliminuje konieczność zakupu nowych komputer&oacute;w, daje bowiem możliwość wykorzystania starych urządzeń (komputer&oacute;w) i korzystania mocy obliczeniowej z chmury. Zapewnia to płynność kalkulacji wykorzystywanych przez program. Ponadto zapewnia możliwość płatności tylko za wykorzystane zasoby.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie aplikacji SaaS do rezerwacji parking&oacute;w i przestrzeni biurowej (hotdesk&oacute;w i salek konferencyjnych)&nbsp;&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wdrożenie aplikacji zwiększy zadowolenie pracownik&oacute;w i tym samym zmniejszy rotację pracownik&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie narzędzia BI do raportowania zarządczego&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wdrożenia aplikacji SaaS do raportowania zwiększy efektywność zarządzania dzięki dostępowi do raport&oacute;w analitycznych w zakresie danych biznesowych.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie środowiska Data Science</span></p>
</td>
<td>
<p><span style="font-weight: 400;">W związku z faktem iż firma gromadzi duże ilości danych, zapewnienie odpowiedniego środowiska analityczne ułatwi prowadzenie analiz danych, a w ich efekcie generowanie dodatkowej wartości biznesowej.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
