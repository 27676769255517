import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../hooks/hooks";
import { AppDispatch } from "../../../store/store";
import LinkTextButton from "../../components/LinkTextButton";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [resetToken, setResetToken] = useState<string>("");
  let query = useQuery();

  useEffect(() => {
    if (query) {
      const id = query.get("id");
      if (id) setResetToken(id);
    }
  }, [query]);

  return (
    <div className="resetPasswordScreen">
      <div className="logoWrapper">
        <img
          className="logo"
          alt="DigiLeader"
          src="https://digileader.cloud/wp-content/uploads/2021/05/digi-leader-logo.png"
        />
      </div>
      <div className="resetPasswordInfo">
        {t(`resetPasswordScreen.${resetToken ? "enterNewPassword" : "info"}`)}
      </div>
      <ResetPasswordForm resetToken={resetToken} />
      <div className="backTologin">
        <LinkTextButton label={t("loginScreen.backToLoginPage")} href="login" />
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
