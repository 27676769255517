const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 49.594%;"><strong>Elementy struktury modelu analitycznego (tabeli wielowymiarowej, kostka OLAP)</strong></td>
<td style="width: 49.594%;"><strong>Definicja elementu</strong></td>
</tr>
<tr>
<td style="width: 49.594%;"><strong>Miara</strong></td>
<td style="width: 49.594%;">Wartość liczbowa przyporządkowana do danego faktu</td>
</tr>
<tr>
<td style="width: 49.594%;"><strong>Wymiar</strong></td>
<td style="width: 49.594%;">Cecha opisująca dany fakt (analizowane zdarzenie), pozwalająca powiązać go z innymi pojęciami modelu przedsiębiorstwa</td>
</tr>
<tr>
<td style="width: 49.594%;"><strong>Atrybut</strong></td>
<td style="width: 49.594%;">Cecha wymiaru, przechowująca dodatkowe informacje na temat faktu</td>
</tr>
</tbody>
</table>`;

export default html;
