const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada RPA</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">w ramach procesu robot powinien pobrać CV i zapisać w odpowiednim miejscu w systemie ERP. W przypadku braku CV należy wysłać maila do kandydata</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">automatyzacja procesu polegającego na przeklejaniu fragment&oacute;w tekstu z formularza ankietowego do odpowiednich moduł&oacute;w systemu ERP</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">proces podlegający automatyzacji będzie posiadał wyjątki (mogą się zdarzyć niepełne dane w formularzu kandydata). Wyjątki należy obsłużyć poprzez wysyłanie maili do kandytat&oacute;w z prośbą o uzupełnienie danych</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">do obsługi procesu należy zaimplementować tyle moduł&oacute;w robota, ile jest rodzaj&oacute;w moduł&oacute;w w systemie ERP do kt&oacute;rych robot będzie musiał mieć dostęp</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">należy obsłużyć wyjątki i zdarzenia niestandardowe, kt&oacute;re mogą wystąpić w przebiegu zautomatyzowanego procesu</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wymaganie techniczne: założenie konta robotycznego do systemu ERP spełniającego następujące wymagania: uprawnienia umożliwiające wykonanie procesu, użycie odpowiedniego username (</span><a style="text-decoration: none;" href="about:blank"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #0563c1; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">erp_proces.rpa.prod@</span></a><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">...)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wymaganie techniczne: założenie robotycznego konta mailowego (mail</span><a style="text-decoration: none;" href="about:blank"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #0563c1; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: underline; -webkit-text-decoration-skip: none; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;">_proces.rpa.prod@</span></a><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">...)</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">konieczność zakupu min. 1 licencji na robota (oprogramowanie RPA), kt&oacute;ry raz dziennie, w godzinach porannych będzie odpowiednio przetwarzał dane wprowadzone w dniu poprzednim</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
