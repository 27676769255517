import { combineReducers, configureStore } from "@reduxjs/toolkit";
import messageReducer from "../reducers/message.reducer";
import userReducer from "../reducers/user.reducer";
import gameReducer from "../reducers/game.reducer";
import groupReducer from "../reducers/group.reducer";
import paymentReducer from "../reducers/payment.reducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "../middleware/logger";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userReducer", "gameReducer"],
};

export const combinedReducers = combineReducers({
  userReducer,
  messageReducer,
  gameReducer,
  groupReducer,
  paymentReducer,
});

const reducer = persistReducer(persistConfig, combinedReducers);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
const persistor = persistStore(store);

export { store, persistor };

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
