import React, { ReactNode, useState } from "react";
import { GameModeType } from "../enums/GameModeType";
import { GameTypeType } from "../enums/GameTypeType";
import "../i18n/i18n";
import GameType from "../interfaces/game/GameType";
import MainContext, { DefaultModalParams } from "./MainContext";

interface MainProviderType {
  children: ReactNode;
}

const MainProvider = (props: MainProviderType) => {
  const { children } = props;

  const [organizationPanelTab, setOrganizationPanelTab] = useState<
    string | null
  >(null);
  const [defaultModalParams, showDefaultModal] =
    useState<DefaultModalParams | null>(null);
  const [filtersGameMode, setFiltersGameMode] = useState<GameModeType>(
    GameModeType.LEARN
  );
  const [filtersGameType, setFiltersGameType] = useState<GameTypeType>(
    GameTypeType.PRIMARY
  );
  const [filtersSelectedGame, setFiltersSelectedGame] = useState<string>("");
  const [accessibilityContrast, setAccessibilityContrast] =
    useState<boolean>(false);
  const [accessibilityFontSize, setAccessibilityFontSize] =
    useState<boolean>(false);

  return (
    <MainContext.Provider
      value={{
        organizationPanelTab,
        setOrganizationPanelTab,
        defaultModalParams,
        showDefaultModal,
        filtersGameMode,
        setFiltersGameMode,
        filtersGameType,
        setFiltersGameType,
        filtersSelectedGame,
        setFiltersSelectedGame,
        accessibilityContrast,
        setAccessibilityContrast,
        accessibilityFontSize,
        setAccessibilityFontSize,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;
