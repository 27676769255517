const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 49.594%;"><strong>Propozycja usługi/rozwiązania technologicznego</strong></td>
<td style="width: 49.594%;"><strong>Uzasadnienie</strong></td>
</tr>
<tr>
<td style="width: 49.594%;">Dodanie danych z innych obszar&oacute;w biznesowych do hurtowni danych</td>
<td style="width: 49.594%;">W firmie funkcjonują systemy (obszary biznesowe), w kt&oacute;rych gromadzone są dane nie przesyłane do hurtowni danych. Warto je dodać. Opr&oacute;cz możliwości raportowania z wykorzystaniem nowych danych, możliwe będzie też wykonywanie analizy krzyżowych, na przecięciu tych obszar&oacute;w (cross domain)</td>
</tr>
<tr>
<td style="width: 49.594%;">Dodanie nowych raport&oacute;w do dashboard&oacute;w analitycznych</td>
<td style="width: 49.594%;">Po pewnym czasie użytkowania narzędzia z pewnością pojawiły się w firmie nowe pomysły na raporty i analizy. Proponujemy pomoc w ich przygotowaniu</td>
</tr>
<tr>
<td style="width: 49.594%;">Utrzymanie platformy, rozwiązywanie problem&oacute;w (maintenance)</td>
<td style="width: 49.594%;">Doskonała znajomość narzędzia pozwala na zaoferowanie najwyższej jakości prac w zakresie utrzymania narzędzia, w korzystnej cenie. Pozwoli to firmie na skoncentrowanie się na działalności biznesowej</td>
</tr>
<tr>
<td style="width: 49.594%;">Zintegrowanie hurtowni danych z narzędziami wykorzystywanymi w innych sp&oacute;łkach zależnych</td>
<td style="width: 49.594%;">Hurtownia danych może być także wykorzystywana jako element konsolidacji raportowania z wielu sp&oacute;łek, np. z r&oacute;żnych kraj&oacute;w. Jeżeli Państwo chcieliby raportować na poziomie holdingu to możemy przygotować ofertę takiego projektu</td>
</tr>
<tr>
<td style="width: 49.594%;">Zautomatyzowanie proces&oacute;w biznesowych (proces&oacute;w podejmowania decyzji i działań systemu)</td>
<td style="width: 49.594%;">Warto się zastanowić, czy nie ma obszar&oacute;w w kt&oacute;rych mogłaby nastąpić automatyzacja podejmowania decyzji na podstawie jakiegoś wskaźnika. Możemy rozbudować platformę analityczną w taki spos&oacute;b by np. automatycznie wysłała maila z informacją o poziomie wskaźnika i krokami jakie należy podjąć &ndash; np. zam&oacute;wienie towaru w przypadku gdy spadnie on poniżej jakiegoś poziomu w magazynie</td>
</tr>
</tbody>
</table>`;

export default html;
