const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Korzyści biznesowe osiągnięte w efekcie zastosowania technologii druku 3D</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.3800000000000001; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Obszar działalności klienta</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.3800000000000001; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wpływ realizacji usługi na dany obszar</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zatrudnienie</span></p>
</td>
<td style="vertical-align: bottom; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nie ma bezpośredniego przełożenia na zatrudnienie.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przychody/zyski</span></p>
</td>
<td style="vertical-align: bottom; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zyski z nowego produktu, kt&oacute;rego koszt wytworzenia jest niższy niż wcześniej i przechodzi testy konsumencie już w trakcie prototypowania. Dodatkowy przych&oacute;d w wysokości ok. 2-2,5 mln zł</span><span style="font-size: 8pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zarządzanie</span></p>
</td>
<td style="vertical-align: bottom; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Firma zmieniła model wprowadzenia nowych opakowań na rynek, co wpłynęło na rozwinięcie modelu zarządzania procesem prototypowania i testowania w firmie</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Pozyskanie nowych klient&oacute;w /CSR [Corporate social responsibility, społeczna odpowiedzialność biznesu]</span></p>
</td>
<td style="vertical-align: bottom; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.295; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Użycie druku 3D w procesie powstawania nowego produktu zmniejsza w nim ilość odpad&oacute;w, jednocześnie umożliwiając wyprodukowanie prototyp&oacute;w opakowania w kilku wersjach. Fakt ten przyciąga kolejnych klient&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koszty /struktura koszt&oacute;w</span></p>
</td>
<td style="vertical-align: bottom; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zmniejszenie kosztu wytworzenia nowego produktu przy zmniejszonym ryzyku niepowodzenia. Koszty poniesione w projekcie są rzędu kilku tysięcy złotych, natomiast w analogicznym procesie przy użyciu w każdej iteracji produkcji nowego opakowania formy wtryskowej koszt ten wyni&oacute;słby ok. kilkadziesiąt do 100 tys. zł</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zadowolenie pracownik&oacute;w</span></p>
</td>
<td style="vertical-align: bottom; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Pracownicy klienta mają większy wpływ na wprowadzenie zmian do powstających nowych opakowań &ndash; zwiększyła się ich sprawczość, a przez to r&oacute;wnież zadowolenie.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
