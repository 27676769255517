const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 99.536%;"><strong>Wykaz temat&oacute;w do om&oacute;wienia</strong></td>
</tr>
<tr>
<td style="width: 99.536%;">Cel projektu</td>
</tr>
<tr>
<td style="width: 99.536%;">Poznajmy się - zesp&oacute;ł projektu</td>
</tr>
<tr>
<td style="width: 99.536%;">Harmonogram i terminy w projekcie</td>
</tr>
<tr>
<td style="width: 99.536%;">Podział pracy i odpowiedzialności w projekcie (RACI)</td>
</tr>
<tr>
<td style="width: 99.536%;">Dostęp do system&oacute;w klienta</td>
</tr>
<tr>
<td style="width: 99.536%;">Zasady komunikacji zespołu projektowego</td>
</tr>
<tr>
<td style="width: 99.536%;">Harmonogram spotkań</td>
</tr>
<tr>
<td style="width: 99.536%;">Dziękuję!</td>
</tr>
</tbody>
</table>`;

export default html;
