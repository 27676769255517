const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<h3 style="text-align: center;"><strong>Korzyści biznesowe związane z realizację usługi z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Obszar działalności klienta</strong></p>
</td>
<td>
<p><strong>Wpływ realizacji usługi na dany obszar</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Przychody/zyski</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Zarejestrowano 4260 transakcji, kt&oacute;re złożyły się na przych&oacute;d na poziomie 140 000 zł, w tym (po odjęciu koszt&oacute;w produkcyjnych i operacyjnych oraz podatku VAT) ok 91000 zł netto zysku.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Koszty</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">99 500 zł netto wydane na działania marketingowe, co oznacza, że wskaźnik <em>ROAS</em> [<em>Return On Advertising Spend</em> czyli wskaźnik zwrotu z inwestycji marketingowej] wyni&oacute;sł 91% (budżet reklamowy nie zwr&oacute;cił się w trakcie trwania kampanii).</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Pozyskanie nowych klient&oacute;w</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Pozyskano 3900 nowych klient&oacute;w i zaobserwowano, że część z nich powr&oacute;ciła i dokonała kolejnych transakcji jeszcze w trakcie trwania kampanii.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Strategia rozwoju</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Projekt pozwolił na budowę świadomości marki oraz pozytywnej relacji z produktami w nowej grupie docelowej. Zesp&oacute;ł obserwuje, że towe transakcje zakupowe z tego segmentu rynku pojawiają się stale, nawet po zakończeniu działań płatnych, co wskazuje na całkowity zwrot inwestycji na przestrzeni najbliższych tygodni. Zesp&oacute;ł ma zamiar kontynuować działania bazując na wypracowanych procesach, strategii i reporcie podsumowującym sprawdzone dobre praktyki wykorzystane w trakcie kampanii.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Poziom zatrudnienia&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Rozbudowano dział marketingu o Social Media Managera&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Og&oacute;lna ocena wsp&oacute;łpracy z wykonawcą</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Klient jest bardzo zadowolony ze wsp&oacute;łpracy z wykonawcą. Chce zatrudnić firmę do budowy strategii innych produkt&oacute;w wydawnictwa.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
