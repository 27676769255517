import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  score: number;
  raiting?: "orange" | "green" | "red";
}

const StyledCircularProgress = (props: Props) => {
  const { t } = useTranslation();
  const { score, raiting } = props;

  return (
    <div className="styledCircularProgress">
      <CircularProgress
        className="progressBar_gray"
        variant="determinate"
        value={100}
        size={80}
      />
      <CircularProgress
        className={`progressBar ${raiting}`}
        variant="determinate"
        value={score}
        size={80}
      />
      <div className="score">
        <span>{`${score}%`}</span>
      </div>
    </div>
  );
};

export default StyledCircularProgress;
