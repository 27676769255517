const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<h3><strong>Informacje, kt&oacute;re należy przekazać podczas spotkania rozpoczynającego realizację projektu z obszaru Big Data (kick-off meeting)</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Komunikaty pojawiające się podczas spotkania kick-off</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Jesteśmy tu po to by stworzyć narzędzie, kt&oacute;re demokratyzuje dostęp do danych w organizacji. To ważny element strategii cyfryzacji firmy. Choć z narzędzia gł&oacute;wnie będą korzystać Analitycy danych, to wszyscy musimy się zaangażować, by projekt spełnił oczekiwania wszystkich stakeholder&oacute;w projektu.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Poznajmy się, niech każdy powie czym się zajmuje i jakie ma dotychczasowe doświadczenia zawodowe.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Widzicie tu poszczeg&oacute;lne kroki w projekcie wraz z zakładanymi przez nas terminami. Za chwilę om&oacute;wimy kto jest odpowiedzialny za poszczeg&oacute;lne zadania.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Om&oacute;wmy teraz macierz RACI, tak by każdy wiedział czego się od niego oczekuje i za co jest odpowiedzialny. Mam też kilka pytań do wybranych os&oacute;b.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Do rozpoczęcia pracy potrzebujemy dostęp&oacute;w do system&oacute;w. Tu mam listę os&oacute;b, kt&oacute;re potrzebują kont. Przekażę ją Administratorowi IT. Proszę także o stworzenie kont do usług chmurowych na Waszym tenancie (grupa użytkownik&oacute;w dzielących określony dostęp do uprawnień i zasob&oacute;w w chmurze).</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Zakładam grupę na komunikatorze firmowym, to narzędzie do bieżącej komunikacji. W narzędziu do zarządzania projektami dodałem nasz projekt, gdzie będą dodawane taski do wykonania. Zapewni to możliwość śledzenia postęp&oacute;w prac.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Spotkania będą realizowane zgodnie z wytycznymi metodologii agile. Zgodnie z nimi organizowane będą następujące rodzaje spotkań: planning, daily, sprint review, retro. Proponuję zorganizować spotkania statusowe w naszym gronie co 2 tygodnie, na końcu każdego sprintu. Ponadto, w razie potrzeby odbywać się będą spotkania inicjowane przez Architekta lub Developer&oacute;w.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dziękuję za spotkanie. Myślę, że po nim wszyscy wiemy co mamy robić i w jakim celu. Jestem do dyspozycji w razie pytań. Do zobaczenia za 2 tygodnie.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
