const html = `<p style="text-align: justify;">WBS (ang. work break down structure) to dokument sporządzany gł&oacute;wnie w celu zaplanowania i oszacowania koszt&oacute;w realizacji projektu IT. Polega na rozbiciu całości prac w projekcie na konkretne zadania, kt&oacute;re muszą zostać zrealizowane, przez konkretne osoby w zespole projektowym, w danym czasie. &nbsp;Innymi słowy to hierarchia zadań, kt&oacute;ra reprezentuje rozumienie przez zesp&oacute;ł projektowy całości prac, kt&oacute;re muszą zostać zrealizowane, tak by doprowadzić do powstania produktu finalnego. Tworzenie WBS obejmuje trzy czynności:&nbsp;</p>
<ul>
    <li>
        <p style="text-align: justify;">Rozbicie całości pracy na mniejsze elementy, tj. łatwo zarządzalne i możliwe do oszacowania podzadania (zadania szczeg&oacute;łowego, tasku).</p>
    </li>
    <li>
        <p style="text-align: justify;">Określenie harmonogramu prac, w tym oszacowanie czasu potrzebnego do wykonania task&oacute;w, ustalenie wsp&oacute;łzależności między zadaniami. Wybranie dat rozpoczęcia i zakończenia zadań (celem określenia liczby dni potrzebnych na ich realizację)</p>
    </li>
    <li>
        <p style="text-align: justify;">Oszacowanie koszt&oacute;w realizacji zadań, poprzez pomnożenie ceny jednostki pracy członk&oacute;w zespołu przez czasochłonność zadań. Nakład pracy na zadanie jest obliczany w następujący spos&oacute;b: Liczba os&oacute;b &times; Czas trwania (dni)&times; Liczba godzin w standardowym dniu roboczym w kalendarzu projektu. Alternatywą jest ocena liczby dni wymaganych do realizacji prac, tzw. man-day (MD, osobo-dzień)</p>
    </li>
</ul>`;

export default html;
