const html = `<p><span style="font-weight: 400;">Kr&oacute;tki opis projektu: Projekt polega na stworzeniu sklepu internetowego, kt&oacute;ry będzie oferował szeroki wyb&oacute;r mebli do domu i biura. Klienci będą mieli możliwość przeglądania oferty, składania zam&oacute;wień oraz dokonywania płatności online.</span></p>
<p><span style="font-weight: 400;">Zakres projektu:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Projektowanie i implementacja interfejsu użytkownika</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Implementacja systemu koszyka i płatności online</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Integracja z systemami logistycznymi i firmami kurierskimi</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Konfiguracja systemu zarządzania treścią i produktami</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Integracja z systemami marketingowymi i narzędziami do analityki</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Testowanie i wdrożenie sklepu</span></li>
</ul>
<p><span style="font-weight: 400;">Korzyści osiągnięte z wdrożenia:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Zwiększenie zasięgu i efektywności sprzedaży poprzez dostępność 24/7</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Automatyzacja proces&oacute;w związanych z obsługą klient&oacute;w i zam&oacute;wień</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Poprawa wizerunku marki poprzez obecność w internecie</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Możliwość lepszego targetowania klient&oacute;w i personalizacji ofert</span></li>
</ul>
<p><span style="font-weight: 400;">Narzędzia, systemy, technologie wykorzystane w projekcie:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Platforma e-commerce: Magento 2</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Systemy płatności online: PayPal, PayU</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Systemy logistyczne i kurierskie: DHL, UPS</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Narzędzia do analityki: Google Analytics</span></li>
</ul>
<p><span style="font-weight: 400;">Czas trwania projektu: 6 miesięcy</span></p>
<p><span style="font-weight: 400;">Zesp&oacute;ł:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Programista Magento 2 (1FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Grafik/UI designer (1FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Tester oprogramowania (0,5FTE)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Project Manager (0,25FTE)</span></li>
</ul>
<p><span style="font-weight: 400;">Budżet projektu: 250 000 zł.</span></p>`;

export default html;
