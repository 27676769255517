const html = `<h3 style="text-align: center;"><strong>Wykaz stanowisk członk&oacute;w zespołu tworzącego rozwiązanie wykorzystujące VR wraz z zakresem obowiązk&oacute;w i CV</strong></h3>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Programista Unity&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka rekrutacyjna: Zwr&oacute;ć uwagę czy programista ma wymagane doświadczenie.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Piotr Kamiński</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2015 Tester automatyczny</span></p>
<p><span style="font-weight: 400;">2015 - 2020&nbsp; Python deweloper&nbsp;</span></p>
<p><span style="font-weight: 400;">2020 &ndash; Unity developer</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Unity****</span></p>
<p><span style="font-weight: 400;">Construct**</span></p>
<p><span style="font-weight: 400;">Python**</span></p>
<p><span style="font-weight: 400;">Git**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Podstawowe zasady projektowania przestrzeni</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Animacje komputerowe</span></p>
<p><span style="font-weight: 400;">Projektowanie gier</span></p>
<p><span style="font-weight: 400;">Tworzenie i optymalizacja scen w Unity&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr matematyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, wysoka odporność na stres, sumienność</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Modelarz 3D&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Zwr&oacute;ć uwagę na portfolio modelarza.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Anna Kot</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2013-2014 Ilustrator</span></p>
<p><span style="font-weight: 400;">2015 - 2019&nbsp; Grafik komputerowy</span></p>
<p><span style="font-weight: 400;">2019 &ndash; Modelarz 3D</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Blender ****</span></p>
<p><span style="font-weight: 400;">Substance **</span></p>
<p><span style="font-weight: 400;">3D Painter***&nbsp;</span></p>
<p><span style="font-weight: 400;">AdobeXD**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> -</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Modelowanie na potrzeby silnik&oacute;w czasu rzeczywistego.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr sztuki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Poczucie estetyki, kreatywność, sumienność</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: Architekt oprogramowania</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Zwr&oacute;ć uwagę na poziom znajomości technologii kandydata.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Gurt Haks</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2011-2014 Tester oprogramowania&nbsp;</span></p>
<p><span style="font-weight: 400;">2015 - 2018&nbsp;Developer Unity</span></p>
<p><span style="font-weight: 400;">2019 &ndash; Architekt oprogramowania VR</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Unity****</span></p>
<p><span style="font-weight: 400;">JS**</span></p>
<p><span style="font-weight: 400;">Angular**</span></p>
<p><span style="font-weight: 400;">Selenium***</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> Wzorce architektoniczne i zasady pisania czystego kodu.&nbsp;&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Znajomość&nbsp;projektowania system&oacute;w i aplikacji</span></p>
<p><span style="font-weight: 400;">Projektowanie gier wideo i system&oacute;w interaktywnych&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Mgr informatyki</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Komunikatywność, uważność, odporność na stres, dobra organizacja pracy</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<p><span style="font-weight: 400;">Stanowisko: PM&nbsp;</span></p>
<p><span style="font-weight: 400;">Wskaz&oacute;wka: Zwr&oacute;ć uwagę na doświadczenie projektowe kandydata.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Imię i nazwisko</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Rafał Wok</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Doświadczenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">2010-2014 Kierownik sklepu</span></p>
<p><span style="font-weight: 400;">2015 - 2017&nbsp; Tester oprogramowania&nbsp;</span></p>
<p><span style="font-weight: 400;">2017 &ndash; PM w branży IT</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Technologie (*****)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Office365***</span></p>
<p><span style="font-weight: 400;">Excel***</span></p>
<p><span style="font-weight: 400;">MS Project***</span></p>
<p><span style="font-weight: 400;">Power Point****</span></p>
<p><span style="font-weight: 400;">AdobeXD**</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Certyfikaty/kursy&nbsp;</strong></p>
</td>
<td>
<p><span style="font-weight: 400;"> -</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wiedza</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Projektowanie interfejs&oacute;w aplikacji VR.</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Wykształcenie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Lic. zarządzania&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Kompetencje miękkie</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Bardzo dobra komunikatywność, sumienność, świetna organizacja pracy, znajomość proces&oacute;w wynikających z typu projektu.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
