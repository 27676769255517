import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GameMockType from "../../../interfaces/game/GameMockType";
import Accordion from "./Accordion";
import { accordionHeader, getNoUserGameLine } from "./GameScreen";
import StyledSelect from "../../components/StyledSelect";
import ArrowDownIcon from "../../../assets/images/icons/arrow_down_icon.svg";
import UserGameScoreType from "../../../interfaces/game/UserGameScoreType";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/store";
import UserGameType from "../../../interfaces/game/UserGameType";
import articlesMap from "../../../mocks/knowledgeBases/knowledge_base_mapping";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import { RoleType } from "../../../enums/RoleType";

interface Props {
  gameData: GameMockType | null;
  gameScore: UserGameScoreType | null;
  categoryData: CategoryMockType | undefined;
}

const KnowledgeBasesAccordion = (props: Props) => {
  const { t } = useTranslation();
  const { gameScore, categoryData } = props;
  const [selectedBase, setSelectedBase] = useState<string>("");
  const [articlesHeaders, setArticlesHeaders] = useState<
    { key: string; label: string }[]
  >([]);

  const userGames: UserGameType[] = useSelector(
    (state: AppState) => state.gameReducer.userGames
  );

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const knowledgeBases = useMemo(() => {
    if (categoryData && articlesMap[categoryData.key])
      return Object.keys(articlesMap[categoryData.key]).map((key) => {
        const knowledgeBase = categoryData.knowledgeBases.find(
          (item) => item.key === key
        );
        return {
          value: key,
          text:
            knowledgeBase && knowledgeBase.label
              ? knowledgeBase.label
              : "Brak nagłówka",
        };
      });
    return [];
  }, [categoryData]);

  useEffect(() => {
    if (knowledgeBases)
      setSelectedBase(knowledgeBases.length > 0 ? knowledgeBases[0].value : "");
  }, [knowledgeBases]);

  const accordionSmallHeader = (header: string) => (
    <div className="accordionSmallHeader">
      <img className="downIcon" src={ArrowDownIcon} alt="downIcon" />
      <span>{header}</span>
    </div>
  );

  const articlesToShow = useMemo(() => {
    if (
      categoryData &&
      selectedBase &&
      articlesMap[categoryData.key] &&
      articlesMap[categoryData.key][selectedBase]
    ) {
      return articlesMap[categoryData.key][selectedBase];
    } else return null;
  }, [selectedBase, categoryData]);

  useEffect(() => {
    if (categoryData && selectedBase && articlesToShow) {
      const knowledgeBase = categoryData.knowledgeBases.find(
        (item) => item.key === selectedBase
      );
      if (knowledgeBase) setArticlesHeaders(knowledgeBase.articles);
    }
  }, [articlesToShow, selectedBase, categoryData]);

  const renderKnowledgeBase = () => {
    const currentUserGame = userGames.find(
      (item) => item.gameId === gameScore?.gameId
    );

    return (
      <div className="nestedAccordions">
        {(currentUserGame && currentUserGame.subtasksFinished > 0) ||
        role === RoleType.EDUCATOR ? (
          <>
            <span className="styledSelectHeader">{"Wybierz bazę:"}</span>
            <StyledSelect
              onChange={setSelectedBase}
              options={knowledgeBases}
              value={selectedBase}
            />
            {articlesToShow
              ? articlesToShow.map((article) => {
                  const template = { __html: article.article };
                  const header = articlesHeaders.find(
                    (item) => item.key === article.key
                  );

                  return (
                    <Accordion
                      key={article.key}
                      HeaderComponent={accordionSmallHeader(
                        header ? header.label : "Brak nagłówka"
                      )}
                      BodyComponent={
                        <div className="baseBodyWrapper" id={article.key}>
                          <div dangerouslySetInnerHTML={template} />
                        </div>
                      }
                    />
                  );
                })
              : selectedBase && (
                  <div className="noDataToshow">
                    {"Brak raportów do wyświetlenia"}
                  </div>
                )}
          </>
        ) : (
          getNoUserGameLine(
            "Zagraj w grę, aby odblokować artykuły w bazie wiedzy."
          )
        )}
      </div>
    );
  };

  return (
    <div className="knowledgeBasesAccordion">
      <Accordion
        HeaderComponent={accordionHeader("Bazy wiedzy")}
        BodyComponent={
          <div className="bodyWrapper">{renderKnowledgeBase()}</div>
        }
      />
    </div>
  );
};

export default KnowledgeBasesAccordion;
