const html = `<h3 style="text-align: center;"><strong>CASE-STUDY 2</strong></h3>
<p><strong>Tytuł projektu: Platforma IoT do zarządzania automatyką domową&nbsp;</strong></p>
<p><strong>Gł&oacute;wny problem klienta (firma instalująca automatyzacje domowe, typu Smart Home): </strong><span style="font-weight: 400;">W automatyce domowej stosowane są czujniki i rozwiązania do automatyzacji wykorzystujące r&oacute;żne protokoły komunikacji, sprawia to, że bardzo trudne i czasochłonne jest analizowanie wszystkich danych z urządzeń oraz skoordynowanie ich działania, przez co brak jest synchronizacji niezbędnej do osiągnięcia pełni korzyści z automatyzacji.&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na zbudowaniu platformy IoT, możliwej do połączenia&nbsp; z konkretnym planem domu i wykorzystywanymi urządzeniami typu smart i elementami automatyki. W efekcie umożliwione mogło być zdalne i automatyczne zarządzanie systemami automatyki domowej najpopularniejszych producent&oacute;w, takimi jak: centralne ogrzewanie, rolety antywłamaniowe, automatyczne włączniki świateł, system monitoringu domu.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Zdalne i automatyczne sterowanie zachowaniem system&oacute;w w domu, co wpłynęło na ograniczenie koszt&oacute;w i zwiększenie bezpieczeństwa mieszkańc&oacute;w dom&oacute;w&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">IoT, home automation (sensory), chmura</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">3 msc&nbsp;</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">PM (0,25FTE), Machine Learning Engineer (0,5FTE), Frontend Developer (1FTE), Tester (0,25FTE), Cloud Architect (1FTE), Cloud dev (2FTE), DevOps (0,25 FTE)</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">250tys. zł</span></p>`;

export default html;
