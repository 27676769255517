const html = `<h3 style="text-align: center;"><strong>CASE STUDY PROJEKTU&nbsp;</strong></h3>
<p>&nbsp;</p>
<h4><strong>Tytuł projektu:</strong><span style="font-weight: 400;"> Szkolenie VR z obsługi maszyny produkcyjnej</span></h4>
<p>&nbsp;</p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Niewystarczająca liczba szkolonych pracownik&oacute;w w miesiącu,</span> <span style="font-weight: 400;">brak możliwości wykorzystania maszyn produkcyjnych podczas szkolenia.</span><strong>&nbsp;</strong></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polegał na przygotowaniu aplikacji szkoleniowej, umożliwiającej przeprowadzenie pełnego szkolenia z obsługi maszyny produkcyjnej w VR.&nbsp;</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Zwiększenie liczby szkolonych os&oacute;b, zwiększenie czasu wykorzystania maszyn produkcyjnych.&nbsp;</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">gogle VR, komputer spełniający wymagania obliczeniowe, silnik Unity, Angular.</span></p>
<p><strong>Czas trwania projektu</strong><span style="font-weight: 400;">: 4 msc</span></p>
<p><strong>Zesp&oacute;ł:</strong><span style="font-weight: 400;"> Programista Unity (1 FTE), modelarz 3D (1 FTE), programista oprogramowania (1 FTE),&nbsp; tester oprogramowania (0,5 FTE), PM (0,5 FTE), Specjalista ds. szkolenia (1 FTE)</span></p>
<p><strong>Budżet projektu:</strong><span style="font-weight: 400;">&nbsp; 65 tys. zł </span></p>`;

export default html;
