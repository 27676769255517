const html = `<p style="text-align: justify;">Gł&oacute;wne obowiązki:</p>
<ul>
    <li>
        <p style="text-align: justify;">analiza wymagań biznesowych i pomoc architektowi rozwiązania w projektowaniu proces&oacute;w ETL, kt&oacute;re spełniają te wymagania</p>
    </li>
    <li>
        <p style="text-align: justify;">dbałość o to, aby procesy ETL były zgodne z wymaganiami dotyczącymi bezpieczeństwa i prywatności danych</p>
    </li>
    <li>
        <p style="text-align: justify;">programowanie skrypt&oacute;w ETL przetwarzających dane, wykonujących operacje transformacji i ładowania, zapewniających kontrolę jakości danych</p>
    </li>
    <li>
        <p style="text-align: justify;">testowanie proces&oacute;w ETL, aby upewnić się, że dane są przetwarzane zgodnie z oczekiwaniami</p>
    </li>
    <li>
        <p style="text-align: justify;">rozwiązywanie problem&oacute;w, jeśli wystąpią jakiekolwiek błędy w procesie ETL.</p>
    </li>
    <li>
        <p style="text-align: justify;">aktualizacja skrypt&oacute;w ETL, w przypadku zmian w źr&oacute;dłach danych lub systemach docelowych</p>
    </li>
    <li>
        <p style="text-align: justify;">monitorowanie procesy ETL i ich optymalizacja, tak aby zapewnić szybkie i niezawodne przetwarzanie danych</p>
    </li>
    <li>
        <p style="text-align: justify;">tworzenie dokumentacji proces&oacute;w ETL, w tym specyfikacji wymagań, schemat&oacute;w bazy danych, opis&oacute;w proces&oacute;w</p>
    </li>
    <li>
        <p style="text-align: justify;">przygotowanie struktur hurtowni danych zgodnie z wytycznymi</p>
    </li>
    <li>
        <p style="text-align: justify;">przygotowanie procesu załadowanie danych do hurtowni</p>
    </li>
    <li>
        <p style="text-align: justify;">zadania w obszarze DevOps</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Wymagane technologie:</strong></p>
<ul>
    <li>
        <p style="text-align: justify;">języki programowania: SQL, Python, Java, Scala</p>
    </li>
    <li>
        <p style="text-align: justify;">bazy danych: Oracle, Microsoft SQL Server, PostgreSQL, MySQL, MongoDB</p>
    </li>
    <li>
        <p style="text-align: justify;">narzędza ETL: Apache NiFi, Talend, Informatica</p>
    </li>
    <li>
        <p style="text-align: justify;">narzędzia do integracji danych: Apache Kafka, Apache Camel, RabbitMQ</p>
    </li>
    <li>
        <p style="text-align: justify;">narzędzia do wizualizacji danych:Tableau, QlikView, Power BI</p>
    </li>
    <li>
        <p style="text-align: justify;">technologie chmurowe: Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform</p>
    </li>
    <li>
        <p style="text-align: justify;">technologie Big Data: Apache Hadoop, Spark, Hive, HBase</p>
    </li>
    <li>
        <p style="text-align: justify;">narzędzia do kontroli wersji: Git, SVN, CVS.</p>
    </li>
</ul>
<p style="text-align: justify;"><strong>Pozostałe wymagania: </strong></p>
<ul>
    <li>
        <p style="text-align: justify;">co najmniej 1 rok doświadczenia na podobnym stanowisku</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość modeli hurtowni danych</p>
    </li>
    <li>
        <p style="text-align: justify;">wiedza w zakresie proces&oacute;w ETL</p>
    </li>
    <li>
        <p style="text-align: justify;">znajomość r&oacute;żnych rodzaj&oacute;w źr&oacute;deł danych&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;">&nbsp;</p>`;

export default html;
