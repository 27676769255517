import { Dispatch } from "@reduxjs/toolkit";
import i18n from "../i18n/i18n";
import TokenRequest from "../interfaces/user/TokenRequest";
import {
  addGameFulfilled,
  addGamePending,
  addGameRejected,
  add_editUserGameFulfilled,
  add_editUserGamePending,
  add_editUserGameRejected,
  deleteGameFulfilled,
  deleteGamePending,
  deleteGameRejected,
  getAverageSubtaskResultsFulfilled,
  getAverageSubtaskResultsPending,
  getAverageSubtaskResultsRejected,
  getGameFulfilled,
  getGamePending,
  getGameRejected,
  getGamesFulfilled,
  getGamesPending,
  getGamesRejected,
  getUserGameFulfilled,
  getUserGamePending,
  getUserGameRejected,
  getUserGamesFulfilled,
  getUserGamesPending,
  getUserGamesRejected,
} from "../reducers/game.reducer";
import { setErrorMessage, setInfoMessage } from "../reducers/message.reducer";
import { errorHandler } from "../utils/error.utils";
import { axiosService } from "./axios.service";
import { store } from "../store/store";
import Add_EditUserGameType from "../interfaces/game/Add_EditUserGameType";
import AddGame from "../interfaces/game/AddGame";
import DeleteGameRequest from "../interfaces/game/DeleteGameRequest";

const gameService = "/game";

export const getGames =
  (includeConfigured: boolean, includeScores: boolean) =>
  async (dispatch: Dispatch | any) => {
    try {
      const { userId } = store.getState().userReducer;
      dispatch(getGamesPending());
      const response = await axiosService.get(
        `${gameService}/games/all?userId=${userId}&includeConfigured=${includeConfigured}&includeScores=${includeScores}`
      );
      dispatch(getGamesFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.getGamesException")))
      );
      //@ts-ignore
      dispatch(getGamesRejected(e.message));
    }
  };

export const getGame = (id: string) => async (dispatch: Dispatch | any) => {
  try {
    dispatch(getGamePending());
    const response = await axiosService.get(`${gameService}/games/${id}`);
    dispatch(getGameFulfilled(response.data));
  } catch (e) {
    dispatch(
      setErrorMessage(errorHandler(e, i18n.t("error.getGameException")))
    );
    //@ts-ignore
    dispatch(getGameRejected(e.message));
  }
};

export const getAverageSubtasksResults =
  (subtasks: string[]) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getAverageSubtaskResultsPending());
      const response = await axiosService.get(
        `${gameService}/user-games/averageSubtaskResults?subtaskKey=${subtasks}`
      );
      dispatch(getAverageSubtaskResultsFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.somethinWentWrong")))
      );
      //@ts-ignore
      dispatch(getAverageSubtaskResultsRejected(e.message));
    }
  };

export const getUserGames =
  (userId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getUserGamesPending());
      const response = await axiosService.get(
        `${gameService}/user-games/${userId}/full`
      );
      dispatch(getUserGamesFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.getUserGamesException")))
      );
      //@ts-ignore
      dispatch(getUserGamesRejected(e.message));
    }
  };

export const getUserGameScore =
  (userId: string, gameId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getUserGamePending());
      const response = await axiosService.get(
        `${gameService}/user-games/${userId}/details?gameId=${gameId}`
      );
      dispatch(getUserGameFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.getGameException")))
      );
      //@ts-ignore
      dispatch(getUserGameRejected(e.message));
    }
  };

export const add_editUserGame =
  (game: Add_EditUserGameType) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(add_editUserGamePending());
      const response = await axiosService.put(
        `${gameService}/user-games/addEditUserGame`,
        game
      );
      dispatch(add_editUserGameFulfilled(response.data));
      dispatch(getGames(true, true));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.somethinWentWrong")))
      );
      //@ts-ignore
      dispatch(add_editUserGameRejected(e.message));
    }
  };

export const addGame = (game: AddGame) => async (dispatch: Dispatch | any) => {
  try {
    dispatch(addGamePending());
    const response = await axiosService.post(`${gameService}/games/add`, game);
    dispatch(addGameFulfilled(response.data));
    dispatch(setInfoMessage(i18n.t("global.addGameSuccess")));
  } catch (e) {
    dispatch(
      setErrorMessage(errorHandler(e, i18n.t("error.addGameException")))
    );
    //@ts-ignore
    dispatch(addGameRejected(e.message));
  }
};

export const deleteGame =
  (data: DeleteGameRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(deleteGamePending());
      const response = await axiosService.delete(
        `${gameService}/games/delete`,
        { data }
      );
      dispatch(deleteGameFulfilled(response.data));
      dispatch(getGames(true, true));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.deleteGameException")))
      );
      //@ts-ignore
      dispatch(deleteGameRejected(e.message));
    }
  };
