import React from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../assets/images/icons/check_icon.svg";

interface Props {
  text: string;
  key: string;
  icon?: string;
}

const GameModeSpectificationRow = (props: Props) => {
  const { t } = useTranslation();
  const { text, key, icon = CheckIcon } = props;

  return (
    <div className="gameModeSpectificationRow" key={key}>
      {icon !== CheckIcon ? (
        <img src={icon} alt="check" />
      ) : (
        <div className="iconBorder">
          <img src={CheckIcon} alt="check" />
        </div>
      )}
      <span className="text">{text}</span>
    </div>
  );
};

export default GameModeSpectificationRow;
