const html = `<table style="width: 100%; border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
    <tbody>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);"><strong>Nazwa zadania projektowego (kamienie milowe projektu)</strong></td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);"><strong>Zadania szczeg&oacute;łowe w ramach zadania projektowego</strong></td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);"><strong>Okres realizacji zadania (1-początkowy, 2-środkowy, 3-końcowy)</strong></td>
        </tr>
        <tr>
            <td rowspan="6" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Pobranie danych ze źr&oacute;deł do warstwy STG</td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie i wdrożenie mechanizmu pobierania danych ze źr&oacute;deł</td>
            <td rowspan="6" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">1</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie struktury tabel docelowych w warstwie STG</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Napisanie skrypt&oacute;w pobierających dane ze źr&oacute;deł</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie harmonogramu pobierania danych ze źr&oacute;deł</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie test&oacute;w warstwy STG</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Uruchomienie i przetestowanie warstwy STG</td>
        </tr>
        <tr>
            <td rowspan="6" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Budowa modelu hurtowni danych (tzw. warstwy data warehouse, DWH)</td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie struktury modelu DWH [data warehouse, hurtowania danych]</td>
            <td rowspan="6" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">2</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie skrypt&oacute;w ładujących model</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Określenie kolejności wykonywania skrypt&oacute;w</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie harmonogramu i połączenie go z harmonogramem w STG</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie i przeprowadzenie test&oacute;w hurtowni</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Uruchomienie warstwy hurtowni</td>
        </tr>
        <tr>
            <td rowspan="5" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Budowa modelu analitycznego (Online Analytical Processing, OLAP</td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przygotowanie modelu analitycznego zgodnie z wytycznymi (zaprogramowanie)</td>
            <td rowspan="5" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">2</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Budowa modelu security &ndash; zarządzania dostępami do danych</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Połączenie z harmonogramami STG, DWH (przepływy danych &ndash; do kolejnej struktury, odświeżenie &ndash; po kolei)</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Testy modelu</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Uruchomienie modelu</td>
        </tr>
        <tr>
            <td rowspan="3" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Budowa warstwy wizualnej raport&oacute;w w aplikacji BI oferowanej w modelu SaaS</td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Dob&oacute;r rodzaj&oacute;w wykres&oacute;w do danych Wygenerowanie (z użyciem konfiguratora w aplikacji BI), wykres&oacute;w zawierających odpowiednie dane do wszystkich wymaganych raport&oacute;w</td>
            <td rowspan="3" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">2</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Utworzenie grup dostęp&oacute;w do raport&oacute;w</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Dob&oacute;r kolor&oacute;w i pozostałych element&oacute;w wizualnych raport&oacute;w i dashboard&oacute;w</td>
        </tr>
        <tr>
            <td rowspan="4" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Przekazanie produktu klientowi do testowania, naprawa błęd&oacute;w, wdrożenie produkcyjne</td>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Testy UAT [user acceptance test, testy akceptacyjne z użytkownikami]</td>
            <td rowspan="4" style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">3</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Naprawa błęd&oacute;w</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Wdrożenie produkcyjne</td>
        </tr>
        <tr>
            <td style="width: 33.3333%; border: 1px solid rgb(0, 0, 0);">Podpisanie protokołu odbioru prac</td>
        </tr>
    </tbody>
</table>
<p><br></p>`;

export default html;
