const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 99.5449%;"><strong>Komunikaty pojawiające się podczas spotkania szkoleniowego</strong></td>
</tr>
<tr>
<td style="width: 99.5449%;">Ważne jest byście poinformowali pracownik&oacute;w o nowym narzędziu i zachęcili ich do korzystania. Proces adopcji rozwiązania przez pracownik&oacute;w może być trudny jeśli kultura organizacji nie promuje wdrażania innowacji. Jesteście Power Userami i to od Was zależy sukces wdrożenia</td>
</tr>
<tr>
<td style="width: 99.5449%;">
<p>Tak wygląda ekran gł&oacute;wny aplikacji BI, widoczny po zalogowaniu. Om&oacute;wię teraz następujące funkcjonalności aplikacji:</p>
<p>- logowanie <br />- dashboard gł&oacute;wny <br />- sposoby wyświetlania raport&oacute;w <br />- filtry <br />- udostępnianie raportu <br />- tworzenie nowego raportu</p>
</td>
</tr>
<tr>
<td style="width: 99.5449%;">Ustawiając zakres dostępu poszczeg&oacute;lnych os&oacute;b do poszczeg&oacute;lnych raport&oacute;w należy zachować szczeg&oacute;lną staranność. Chcąc wykorzystać w raportach dane osobowe należy wcześniej zapoznać się z polityką ochrony danych osobowych/RODO. Należy przechowywać dane o sposobach dostępu do system&oacute;w źr&oacute;dłowych w specjalnych narzędziach do przechowywania i zabezpieczania dostępu, oferowanych często przez dostawc&oacute;w chmury</td>
</tr>
<tr>
<td style="width: 99.5449%;">Dokumentacja produktu dostępna jest w Państwa firmowej bazie wiedzy. W razie problem&oacute;w z korzystaniem z narzędzia proszę o kontakt z firmowym działem IT</td>
</tr>
</tbody>
</table>`;

export default html;
