import { Button } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MainContext from "../../context/MainContext";
import { GameModeType } from "../../enums/GameModeType";
import { GameTypeType } from "../../enums/GameTypeType";
import CategoryMockType from "../../interfaces/game/CategoryMockType";
import GameType from "../../interfaces/game/GameType";
import { AppState } from "../../store/store";
import SelectBubbleComponent from "./SelectBubbleComponent";
import StyledSelect from "./StyledSelect";

interface Props {
  withGames?: boolean;
  games?: GameType[];
}

const gameMode = [
  { text: "Nauka", value: GameModeType.LEARN },
  { text: "Test", value: GameModeType.TEST },
];

const gameType = [
  { text: "Gry podstawowe", value: GameTypeType.PRIMARY },
  { text: "Gry skonfigurowane", value: GameTypeType.CONFIGURED },
];

const StyledFiltersComponent = (props: Props) => {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const {
    filtersGameMode,
    filtersGameType,
    setFiltersGameMode,
    setFiltersGameType,
    setFiltersSelectedGame,
    filtersSelectedGame,
  } = mainContext;
  const { withGames, games } = props;

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  //@ts-ignore
  const mappedGames: { text: string; value: string }[] = useMemo(() => {
    const gamesForSelection = games
      ? games
          .filter((game) =>
            filtersGameType === GameTypeType.CONFIGURED
              ? !!game.userCreatorId && game.gameMode === filtersGameMode
              : true
          )
          .map((game) => {
            const categoryData = gamesData.find(
              (category) => category.key === game.categoryKey
            );
            if (categoryData) {
              const gameData = categoryData.games.find(
                (item) => item.key === game.key
              );
              if (gameData) {
                return {
                  text: gameData.gameTitle,
                  value: game.id,
                };
              }
            }
            return null;
          })
          .filter(
            (game: { text: string; value: string } | null) => game !== null
          )
      : [];
    if (
      gamesForSelection &&
      gamesForSelection.length > 0 &&
      gamesForSelection[0]
    )
      setFiltersSelectedGame(gamesForSelection[0].value);
    return gamesForSelection;
  }, [games, filtersGameType, gamesData, filtersGameMode]);

  return (
    <div
      className={`styledFiltersComponent ${withGames ? "" : "withoutGames"}`}
    >
      <div className="section">
        <span>{"Rodzaj gry:"}</span>
        <SelectBubbleComponent
          selected={filtersGameType}
          onChange={setFiltersGameType}
          bubbles={gameType}
        />
      </div>
      <div
        className={`section ${
          filtersGameType === GameTypeType.PRIMARY ? "disabled" : ""
        }`}
      >
        <span>{"Tryb gry:"}</span>
        <SelectBubbleComponent
          selected={filtersGameMode}
          onChange={setFiltersGameMode}
          bubbles={gameMode}
          disabled={filtersGameType === GameTypeType.PRIMARY}
        />
      </div>
      {withGames && games && (
        <div className="section big">
          <span>{"Wybierz  grę:"}</span>
          <StyledSelect
            onChange={setFiltersSelectedGame}
            options={mappedGames}
            value={filtersSelectedGame}
          />
        </div>
      )}
    </div>
  );
};

export default StyledFiltersComponent;
