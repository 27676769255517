import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../assets/images/icons/check_primary_icon.svg";

interface Props {
  bubbles: { text: string; icon?: any; value: string }[];
  selected: string;
  onChange: (value: any) => void;
  disabled?: boolean;
}

const SelectBubbleComponent = (props: Props) => {
  const { t } = useTranslation();
  const { bubbles, selected, onChange, disabled } = props;

  return (
    <div className="selectBubbleComponent">
      {bubbles.map((bubble) => {
        const isSelected = bubble.value === selected;
        return (
          <div
            className={`bubble ${isSelected ? "selected" : ""} ${
              disabled ? "disabled" : ""
            }`}
            key={bubble.value}
            onClick={() => onChange(bubble.value)}
          >
            {isSelected && (
              <img
                className="selectedIcon"
                src={CheckIcon}
                alt="selectedIcon"
              />
            )}
            {bubble.icon && (
              <img className="categoryIcon" src={bubble.icon} alt={"icon"} />
            )}
            {bubble.text}
          </div>
        );
      })}
    </div>
  );
};

export default SelectBubbleComponent;
