const html = `<h3 style="text-align: center;"><strong>Opis propozycji usług po zrealizowaniu projektu druku 3D wraz z uzasadnieniem</strong></h3>
<h3 style="text-align: center;">&nbsp;</h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja</strong></p>
</td>
<td>
<p><strong>Uzasadnienie</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie drukarki 3D w firmie&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pomoc w wyborze najlepszej drukarki oraz jej instalacji i wdrożeniu przełożą się na fakt, iż klient będzie m&oacute;gł sam prowadzić procesy druku 3D w części lub w całości u siebie.</span></p>
<p><span style="font-weight: 400;">Klient będzie m&oacute;gł przekazać to na efekty marketingowe i oszczędności.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wsp&oacute;łpraca przy kolejnych projektach tego samego typu</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dzięki posiadanej już wiedza o procesie klienta, oferujemy atrakcyjne warunki dalszej wsp&oacute;łpracy.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przeprowadzenie szkolenia z druku i modelowania 3D</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Klient zdobędzie wiedzę umożliwiającą rozpoczęcie pracy z drukiem 3D i wykorzystanie go do rozwoju firmy.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Wdrożenie nowych drukarek 3D w firmie</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Pomoc w wyborze kolejnych drukarek przełożą się na fakt, iż klient będzie m&oacute;gł rozszerzyć zastosowanie druku 3D w firmie.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przeprowadzenie kolejnych szkoleń z druku i modelowania 3D</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Klient zdobędzie wiedzę umożliwiającą optymalne wykorzystywanie druku 3D do rozwoju firmy.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
