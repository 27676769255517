const html = `<p style="text-align: justify;"><strong>Analiza biznesowa</strong></p>
<p style="text-align: justify;">Działania mające na celu określenie szczeg&oacute;łowych wymagań biznesowych, w przypadku rozwiązań BI wymagania dotyczą gł&oacute;wnie: identyfikacji danych źr&oacute;dłowych, określenia struktury raport&oacute;w analitycznych, określenia miar i wymiar&oacute;w modelu analitycznego. Analiza biznesowa stanowi pierwszy etap realizacji projektu z obszaru IT.</p>
<p style="text-align: justify;"><strong><span style="color: rgb(97, 189, 109);">API</span></strong></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">&nbsp;API (ang. Application Programming Interface) to interfejs programistyczny, kt&oacute;ry umożliwia komunikację między r&oacute;żnymi aplikacjami lub systemami informatycznymi. API definiuje zestaw reguł i protokoł&oacute;w, kt&oacute;re pozwalają programistom na korzystanie z usług lub funkcjonalności danej aplikacji lub systemu. API może być używane w celu integracji r&oacute;żnych system&oacute;w lub aplikacji, dzięki czemu można uzyskać dostęp do danych lub funkcjonalności, kt&oacute;re nie są dostępne lub trudne do osiągnięcia w inny spos&oacute;b.</span></p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Dashboard analityczny/pulpit nawigacyjny</strong></p>
<p style="text-align: justify;">Rodzajem graficznego interfejsu użytkownika, zapewniający szybki podgląd raport&oacute;w analitycznych, zawierających kluczowe wskaźniki związane z określonym celem lub procesem biznesowym.</p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Data mart (magazyn danych)</span></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Magazyn danych określić mozna jako składową hurtowni danych skupiającą się na gromadzeniu danych z jednego obszaru biznesowego, np. finans&oacute;w lub marketingu. Ze względu na swoje przeznaczenie dane zawarte w obszarze data mart pochodzą z mniejszej liczby źr&oacute;deł niż w przypadku hurtowni danych.</span></p>
<p style="text-align: justify;"><strong><span style="color: rgb(226, 80, 65);">GB</span></strong></p>
<p style="text-align: justify;"><span style="color: rgb(226, 80, 65);">Gigabajt, GB, jednostka pojemności pamięci, 1mld bajt&oacute;w</span></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Warstwa currated platformy Big Data</span></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Warstwa danych w ramach struktury data lake, w kt&oacute;rej gromadzone są oczyszczone i uporządkowane dane źr&oacute;dłowe. Jest to warstwa tzw. przejściowa. Wykorzystywana jest przez analityk&oacute;w/Data Scientist&oacute;w, kt&oacute;rzy mogą wykonywać dowolne operacje na zgromadzonych danych, np. w wykorzystaniem narzędzi open source, wspierających analizy Big Data. Dane w warstwie currated, nie mają struktury odpowiadającej wymaganiom czy regułom biznesowym., przez co mogą być dowolnie wykorzystywane. </span></p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Warstwa hurtowni danych (<span style="color: rgb(97, 189, 109);">warstwa currated</span>, data warehouse, DWH)</strong></p>
<p style="text-align: justify;">Warstwa danych w ramach tzw. platformy danych, gdzie w jednym &bdquo;miejscu&rdquo; przechowywane są aktualne i historyczne dane, stanowiące tzw. jedno źr&oacute;dło prawdy (wiarygodne dane). Hurtownia danych to baza danych posiadająca inną strukturę (model danych) niż baza transakcyjna, np. system ERP. Umożliwia szybszy dostęp do danych przez aplikacje analityczne i raportujące.</p>
<p style="text-align: justify;"><strong><span style="color: rgb(97, 189, 109);">Warstwa row platformy Big Data</span></strong></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Warstwa danych w ramach struktury data lake, w kt&oacute;rej gromadzone są dane wyeksportowane z systemow źr&oacute;dłowych, w niezmienionej, tj. surowej formie. Jest to warstwa tzw. techniczna, niedostępna dla użytkownik&oacute;w końcowych rozwiązania.</span></p>
<p style="text-align: justify;"><strong>Model semantyczny<span style="color: rgb(226, 80, 65);"> (analityczny)</span></strong></p>
<p style="text-align: justify;">Semantyczny model danych to model opisujący znaczenie zawartych w nim element&oacute;w danych.&nbsp;Model ten zawiera nazwy biznesowe i algorytmy przeliczania danych zgodne z wymaganiami biznesowymi i stanowi źr&oacute;dło danych dla aplikacji raportujących. Zawiera właściwe i łatwo interpretowalne dane gotowe do wykorzystania przez aplikacje do wizualizacji danych. &nbsp;Modele tworzone są ze względu na fakt, iż struktury w bazie danych nie zawierają wszystkich konfiguracji przeliczeń danych wymaganych przez klienta. Umożliwia to natomiast implementacja (zastosowanie) modelu semantycznego, ułatwiającego pracę klientowi biznesowemu. Ponadto, dane dostarczane przez model analityczny (semantyczny) są pobierane z magazynu danych, zapewniając, że wszyscy użytkownicy widzą te same dane. W przypadku bezpośredniego pobierania danych z bazy danych, dostęp do nich byłby utrudniony (wymagana znajomość SQL, trudności we wskazaniu właściwych danych źr&oacute;dłowych w przypadku podobnych kategorii danych etc.). Istnieją dwa podstawowe typy modeli semantycznych, tj. tabelaryczny (używający konstrukcji modelowania relacyjnego, w kt&oacute;rym wewnętrznie metadane są dziedziczone z konstrukcji modelowania OLAP oraz wielowymiarowy (używający tradycyjnych konstrukcji modelowania OLAP, tj. moduł&oacute;w, wymiar&oacute;w, miar).</p>
<p style="text-align: justify;"><strong>Proces CI/CD (continuous integration,continuous delivery)</strong></p>
<p style="text-align: justify;">Proces określany przez zbi&oacute;r zasad, wytycznych i kolekcję dobrych praktyk dotyczących pracy nad projektami informatycznymi. Dzięki nim zesp&oacute;ł developer&oacute;w ma możliwość częstszego dostarczania pewnych, przetestowanych i sprawdzonych zmian w kodzie. Obejmuje m.in. zasady mearge/pull request, struktura pipeline. Ciągła integracja (CI) to praktyka używana przez zespoły deweloper&oacute;w automatyzujące scalanie i testowanie kodu. Implementacja ciągłej integracji pomaga złapać usterki na wczesnym etapie cyklu programowania, co sprawia, że są one tańsze do naprawienia. Automatyczne testy wykorzystuje się w ramach procesu ciągłej integracji w celu zapewnienia jakości. Artefakty tworzone są z system&oacute;w ciągłej integracji i przekazywane do proces&oacute;w wydawania w celu częstego wdrażania. Ciągłe dostarczanie (CD) oznacza proces, za pomocą kt&oacute;rego kod jest kompilowany, testowany i wdrażany w co najmniej jednym środowisku testowym i produkcyjnym. Wdrażanie i testowanie w wielu środowiskach zwiększa jakość. Systemy ciągłej integracji generują artefakty, w tym infrastrukturę i aplikacje. Zautomatyzowane procesy wydawania wykorzystują te artefakty do wydawania nowych wersji i poprawek istniejących system&oacute;w. Systemy monitorowania i alert&oacute;w są stale uruchamiane, aby zapewnić wgląd w cały proces ciągłego wdrażania.</p>
<p style="text-align: justify;"><strong><span style="color: rgb(226, 80, 65);">Prototyp</span></strong></p>
<p style="text-align: justify;"><span style="color: rgb(226, 80, 65);">Pierwsza wersja rozwiązania, zbudowana w celu pokazania jego możliwości i weryfikacji wstępnych założeń.</span></p>
<p style="text-align: justify;"><strong>Przetwarzania transakcji online (OLTP, online transaction processing)</strong></p>
<p style="text-align: justify;">System przetwarzania danych polegający na wykonywaniu wielu transakcji odbywających się jednocześnie. Kategoria aplikacji klient-serwer dotyczących baz danych w ramach bieżącego przetwarzania transakcji. Obejmuje bazy danych używane przez firmę do przechowywania wszystkich transakcji i rekord&oacute;w. Bazy te zwykle zawierają rekordy, kt&oacute;re są wprowadzane pojedynczo.&nbsp;</p>
<p style="text-align: justify;"><strong>Przetwarzanie analityczne online (OLAP, online analytical processing)</strong></p>
<p style="text-align: justify;">Przetwarzanie informacji z wykorzystaniem wielowymiarowej bazy danych, opartej na semantycznym modelu danych.&nbsp;Przetwarzanie analityczne online (OLAP) to technologia, kt&oacute;ra pozwala na zorganizowanie dużych bazy danych biznesowych i obsługiwanie złożonych analiz danych. Ułatwia wyodrębnienie informacji potrzebnych do analizy biznesowej danych w wydajny spos&oacute;b, dzięki zoptymalizowaniu pod kątem dużych obciążeń odczytu i niskiego zapisu.&nbsp;</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Spark</span></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Apache Spark to otwarte (open source) oprogramowanie do przetwarzania danych, kt&oacute;re umożliwia przetwarzanie dużej ilości danych w spos&oacute;b r&oacute;wnoległy i rozproszony na klastrze komputer&oacute;w&nbsp;</span></p>
<p style="text-align: justify;"><span style="color: rgb(97, 189, 109);">Biblioteka/framework Spark jest zbudowana w języku Scala i udostępnia API dla wielu innych język&oacute;w programowania, takich jak Python, Java, R i SQL.Biblioteka Spark oferuje wiele moduł&oacute;w, w tym Spark Core, Spark SQL, Spark Streaming, MLlib (biblioteka uczenia maszynowego) i GraphX (biblioteka przetwarzania graf&oacute;w). Spark Core stanowi podstawę dla innych moduł&oacute;w i udostępnia API do przetwarzania danych r&oacute;wnolegle w pamięci. Spark SQL umożliwia przetwarzanie danych w formacie SQL, co ułatwia integrację z istniejącymi narzędziami i aplikacjami. Spark Streaming umożliwia przetwarzanie strumieniowe danych w czasie rzeczywistym. MLlib oferuje wiele algorytm&oacute;w. </span></p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong><span style="color: rgb(226, 80, 65);">Testy A/B&nbsp;</span></strong></p>
<p style="text-align: justify;"><span style="color: rgb(226, 80, 65);">Testy A/B to metoda badawcza, w obszarze badań UX (user experience, doświadczenia użytkownik&oacute;w w kontakcie z produktami, usługami), polegająca na por&oacute;wnaniu dw&oacute;ch wersji makiety/aplikacji/strony internetowej celem wybrania tej wersji rozwiązania, kt&oacute;ra lepiej spełnia stawiane jej wymagania i cele. </span></p>
<p style="text-align: justify;"><strong>Warstwa pośrednia (stage, STG)</strong></p>
<p style="text-align: justify;">Wartwa danych w bazie danych/hurtowni danych, gdzie przechowywane są dane pobrane z system&oacute;w źr&oacute;dłowych, tzw. surowe, identyczne jak w systemach źr&oacute;dłowych, nie przetworzone na potrzeby konkretnego użycia</p>`;

export default html;
