const html = `<h3 style="text-align: center;"><strong>Opis propozycji usług po wdrożeniu RPA&nbsp; wraz z uzasadnieniem</strong></h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Propozycja usługi/rozwiązania technologicznego</strong></p>
</td>
<td>
<p><strong>Uzasadnienie</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Automatyzacja kolejnych proces&oacute;w z użyciem RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Posiadane już know how w zakresie wsp&oacute;łpracy z Państwa firmą, pozwoli na zwiększenie efektywności pracy przy realizacji kolejnych projekt&oacute;w automatyzacji proces&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analiza potencjału automatyzacji i optymalizacji proces&oacute;w biznesowych&nbsp;</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Nasz Architekt RPA podjąć się może przeglądu i analizy Państwa proces&oacute;w biznesowych oraz zaproponować optymalizacje proces&oacute;w. W razie potrzeby może przygotować odpowiednią dokumentację, tj. mapy proces&oacute;w oraz przygotować wycenę wykonania prac.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Usługa wsparcia technicznego i utrzymania</span></p>
</td>
<td>
<p><span style="font-weight: 400;">W ramach wykupienia usługi wsparcia, w razie pojawienia się błęd&oacute;w lub zmian w zautomatyzowanych procesach biznesowych zaoferować możemy realizację prac w zakresie dostosowania pracy działania robota do nowych regulacji, wymagań, system&oacute;w powiązanych i/lub naprawy działania robota.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
