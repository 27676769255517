const html = `<p style="text-align: justify;"><strong>Cel projektu:</strong>&nbsp;Zastąpienie starego narzędzia do raportowania nowym, bez przestoj&oacute;w w dostępnie do aktualnych raport&oacute;w&nbsp;</p>
<p style="text-align: justify;"><strong>Czas trwania projektu:</strong>&nbsp;3 miesięcy&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<strong>Budżet projektu:</strong>&nbsp;150 tys. zł</p>
<p style="text-align: justify;"><strong>Zesp&oacute;ł projektowy:</strong>&nbsp;Architekt BI/BD (0,25FTE), Programista ETL (0,25FTE), Programista BI (1FT)</p>
<p style="text-align: justify;"><strong>Gł&oacute;wny problem klienta</strong></p>
<p style="text-align: justify;">Czasochłonne działania analityczne wymagane przez instytucje publiczne. Dotychczasowa technologia (aplikacja typu BI,do raportowania) stała się przestarzała. Klient potrzebował pomocy przy przejściu na nowoczesną platformę, oferującą więcej funkcji i możliwości, a także oferującej bardziej intuicyjny interface do obsługi narzędzia. &nbsp;&nbsp;</p>
<p style="text-align: justify;"><strong>Kr&oacute;tki opis projektu</strong></p>
<p style="text-align: justify;">Projekt obejmował przeniesienie proces&oacute;w automatycznego generowania raport&oacute;w na potrzeby dział&oacute;w kontrolingu, finans&oacute;w, produckcji do nowego narzędzia typu BI. &nbsp;Przeniesiono funkcje i model analityczny z poprzedniej wersji aplikacji do nowego, ulepszonego narzędzia. &nbsp;Połączono nowe narzędzie z hurtownią danych klienta. Zoptymalizowano struktury raport&oacute;w, aby uzyskać lepszą wydajność.</p>
<p style="text-align: justify;"><strong>Korzyści klienta, osiągnięte w efekcie wdrożenia</strong></p>
<p style="text-align: justify;">Efektywniejsze zarządzanie dzięki dostępowi do aktualnych raport&oacute;w analitycznych wysokiej jakości. Zwiększenie szybkości procesu opracowywania raport&oacute;w oraz jakości i atrakcyjności raport&oacute;w. Włączenie os&oacute;b nietechnicznych (użytkownik&oacute;w biznesowych bez doświadczenia technicznego) w procesy generowania raport&oacute;w analitycznych w oparciu o duże zbiory danych.&nbsp;</p>
<p style="text-align: justify;"><strong>Narzędzia/systemy/technologie wykorzystane w projekcie</strong></p>
<p style="text-align: justify;">Aplikacja BI w modelu SaaS</p>`;

export default html;
