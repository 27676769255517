const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<h3><strong>Informacja, kt&oacute;re należy przekazać pracownikom klienta po wdrożeniu w organizacji rozwiązania z obszaru Big Data</strong></h3>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Rozwiązanie kt&oacute;re wdrożyliśmy jako platformę danych składa się z kilku warstw. Są to warstwa row, przechowująca dane surowe, warstwa cleansed przeznaczona gł&oacute;wnie do użytku Data Scientist&oacute;w oraz warstwa curated, będąca hurtownią danych zasilającą systemy raportujące.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Ustawiając zakres dostępu poszczeg&oacute;lnych os&oacute;b do poszczeg&oacute;lnych raport&oacute;w należy zachować szczeg&oacute;lną staranność. Chcąc wykorzystać w raportach dane osobowe należy wcześniej zapoznać się z polityką ochrony danych osobowych/RODO.</span></p>
<p><span style="font-weight: 400;">Należy przechowywać dane o sposobach dostępu do system&oacute;w źr&oacute;dłowych w specjalnych narzędziach do przechowywania i zabezpieczania dostępu, oferowanych często przez dostawc&oacute;w chmury.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Om&oacute;wię teraz przykładowy problem jaki rozwiązuje dostęp do platformy Big Data.</span></p>
<p><span style="font-weight: 400;">Wyobraźmy sobie, że firma ma trudności z utrzymaniem wysokiej jakością obsługi klient&oacute;w. Dyrektor ds sprzedaży przedstawił sw&oacute;j problem analitykom danych. Zidentyfikowano następujące user story: Jako dyrektor działu sprzedaży potrzebuję informacji o klientach perspektywicznych (strategicznych), o kt&oacute;rych należy szczeg&oacute;lnie zadbać.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Etapy działań mających na celu rozwiązanie problemu biznesowego przy wykorzystaniu platformy Big Data, to:&nbsp;</span></p>
<p><span style="font-weight: 400;">1. Identyfikacja, jakie dane potrzebne są do analizy, kt&oacute;ra ma na celu rozwiązanie problemu biznesowego&nbsp; (np. dane z ERP (sprzedaż/dostawa/klient, marża/klient), z CRM (opiekun klienta, wielkość klienta, liczba kontakt&oacute;w/klient)</span></p>
<p><span style="font-weight: 400;">2. Sprawdzenie dostępności danych z wykorzystaniem narzędzia np. AWS Sage Maker&nbsp;</span></p>
<p><span style="font-weight: 400;">3. Napisanie w narzędziu/notatniku (np. Sage Maker) kodu w Python, kt&oacute;ry połączy dane w pożądany spos&oacute;b (np. weź dane z tabeli Klient &ndash;połącz z danymi z tabeli Sprzedaż po kluczu ID klienta, oblicz ilość kontakt&oacute;w w miesiącu z klientem).</span></p>
<p><span style="font-weight: 400;">4. Wyeksportowanie wyniku w formacie CSV</span></p>
<p><span style="font-weight: 400;">5. Zaimportowanie wynik&oacute;w do aplikacji BI i przygotowanie wizualizacji i wykres&oacute;w&nbsp;</span></p>
<p><span style="font-weight: 400;">6. Udostępnienie linku do raportu odpowiednim osobom&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
