const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 99.5449%;"><strong>Wykaz temat&oacute;w do om&oacute;wienia</strong></td>
</tr>
<tr>
<td style="width: 99.5449%;">Jak skutecznie wdrożyć narzędzie BI wśr&oacute;d pracownik&oacute;w?</td>
</tr>
<tr>
<td style="width: 99.5449%;">Podstawy obsługi rozwiązania</td>
</tr>
<tr>
<td style="width: 99.5449%;">Jak bezpiecznie korzystać z narzędzia &ndash;zasady cyberbezpieczeństwa</td>
</tr>
<tr>
<td style="width: 99.5449%;">Dziękuję i zapraszam do dalszej wsp&oacute;łpracy</td>
</tr>
</tbody>
</table>`;

export default html;
