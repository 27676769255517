import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import DigiLeaderLogo from "../assets/images/digiLeaderLogo.png";
//@ts-ignore
import NunitoRegular from "../assets/fonts/Nunito/Nunito-Regular.ttf";
//@ts-ignore
import NunitoBold from "../assets/fonts/Nunito/Nunito-Bold.ttf";
//@ts-ignore
import NunitoItalic from "../assets/fonts/Nunito/Nunito-Italic.ttf";
import GameType from "../interfaces/game/GameType";

interface Props {
  userName: string;
  games: GameType[];
  chart1?: string;
  chart2?: string;
  int1: string;
  int2: string;
  int3: string;
  int4: string;
  int5: string;
  reports: { key: string; sum: number }[];
}

const DigitalProfileReport = (props: Props) => {
  const { userName, chart1, chart2, int1, int2, int3, int4, int5, reports } =
    props;

  Font.register({
    family: "Nunito",
    fonts: [
      {
        src: NunitoRegular,
      },
      {
        src: NunitoBold,
        fontWeight: "bold",
      },
      {
        src: NunitoItalic,
        fontWeight: "normal",
        fontStyle: "italic",
      },
    ],
  });

  const mainCompetence =
    "Rozwiązywanie problemów i usprawnianie procesów z wykorzystaniem technologii BI/ Big Data, social media (analityka internetowa), e-commerce (digital platforms), AI/ML, cloud computing, Internet of Things, VR, druk 3D, RPA (automatyzacja i robotyzacja), blockchain.";

  const knowledge = [
    "potrzeby i wymagania biznesowe na które odpowiadają technologie cyfrowe; technologie i narzędzia wykorzystywane w procesie wdrażania technologii, składy zespołów realizujących projekty technologiczne, przebieg spotkania rozpoczynającego realizację projektu IT, przykłady wymagań biznesowych w zakresie wdrożeń IT,  przykładowe zagadnienia poruszane na szkoleniu z narzędzi cyfrowych, usługi cyfrowe umożliwiające pogłębianie transformacji cyfrowej; istotę, charakterystykę, główne pojęcia związane z kluczowymi technologiami cyfrowymi",
  ];
  const skills = [
    "harmonogram realizacji projektu IT; ofertę na realizację usługi IT; CV członków zespołu realizującego projekt technologiczny; informacje, które należy przekazać podczas spotkania rozpoczynającego realizację projektu wdrożenia technologii cyfrowych, matrycę RACI projektu; harmonogram spotkań zespołu technologicznego; zadania poszczególnych członków zespołu technologicznego; zadania szczegółowe w ramach prac ramowych harmonogramu projektu technologicznego;  informacje, które należy przekazać klientowi po wdrożeniu technologii cyfrowej, korzyści biznesowe osiągnięte w efekcie zastosowania technologii cyfrowych",
  ];
  const bestCategoryDesc =
    "to technologia cyfrowa, którą uznać należy za wiodącą z punktu widzenia wykazanych przez gracza kompetencji i zdolności. Technologia ta stanowi obszar tematyczny, w którym gracz wykazał najwyższy, łączny poziom kompetencji, zainteresowania i zaangażowania. Poziom zróżnicowania kompetencji i zdolności gracza, w zależności od  obszaru technologicznego, przedstawia poniższy wykres.";

  return (
    <Document>
      <Page style={styles.body}>
        <Image style={styles.logo} source={DigiLeaderLogo} />
        <View style={styles.buttonWrapper}>
          <Text style={styles.buttonText}>Profil cyfrowy</Text>
        </View>
        <Text style={styles.topInfo}>
          <Text style={styles.bold}>Imię i nazwisko gracza: </Text>
          {`${userName}`}
        </Text>
        <Text style={{ ...styles.topInfo, marginBottom: 24 }}>
          <Text style={styles.bold}>Data wygenerowania raportu: </Text>
          {`${DateTime.now().toFormat("dd.LL.yyyy")}`}
        </Text>
        <View style={styles.section}>
          <Text>
            {`Profil cyfrowy stanowi potwierdzenie, iż `}
            <Text style={styles.bold}>{`${userName}`}</Text>
            {` ukończył 20  godzinny, program edukacyjny w zakresie rozwoju kompetencji cyfrowych, opracowany przez Digi Leader sp. z o. o., we współpracy z ekspertami ds. technologii cyfrowych. Szkolenie polegało na rozegraniu 20 gier edukacyjnych, o charakterze symulacji biznesowych.`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            Główna kompetencja cyfrowa rozwinięta w ramach zrealizowanego
            programu edukacyjnego:{" "}
            <Text style={styles.bold}>{mainCompetence}</Text>
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            <Text style={styles.bold}>
              Rozwinięte kompetencje szczegółowe:{" "}
            </Text>
            {`tworzenie koncepcji  rozwiązań opartych na technologiach cyfroych; identyfikowanie zasobów niezbędnych do realizacji projektów z obszaru technologii cyfrowych; określenie zakresu prac  w ramach realizacji projektu z obszaru technologii cyfrowych; przeglądanie, szukanie i filtrowanie danych, informacji, i treści cyfrowych związanych z zastosowaniem technologii cyfrowych; ocena danych, informacji, i treści cyfrowych związanych z zastosowaniem technologii cyfrowych`}
          </Text>
        </View>
        <View style={{ ...styles.section, ...styles.withWrap }}>
          <Text>{`W trakcie gier symulacyjnych gracz rozwinął następujące obszary wiedzy i umiejętności, składające się na wskazane powyższej kompetencje cyfrowe:`}</Text>
          <Text>
            <Text style={styles.bold}>{"Wiedza [Zna...]: "}</Text>
            {`${knowledge}.`}
          </Text>
          <Text>
            <Text style={styles.bold}>
              {"Umiejętności [Potrafi opracować/wskazać...]:"}{" "}
            </Text>
            {`${skills}.`}
          </Text>
        </View>
      </Page>
      <Page style={styles.body}>
        <View style={styles.buttonWrapper}>
          <Text style={styles.buttonText}>Szczegóły profilu cyfrowego</Text>
        </View>
        <View style={styles.buttonWrapper}>
          <Text style={styles.buttonText}>
            Analiza wyników uzyskanych przez gracza
          </Text>
        </View>
        <Text style={{ ...styles.sectionHeader, marginTop: 0 }}>
          {`Wiodąca technologia cyfrowa: ${int1}`}
        </Text>
        <View style={styles.section}>
          <Text>
            <Text style={styles.bold}>{int1}</Text>
            {` ${bestCategoryDesc}`}
          </Text>
        </View>
        {chart1 && <Image style={styles.chart1} src={chart1} />}
        <Text style={styles.italic}>
          {
            "*Kompetencje (oceniane  na podstawie przewag w zakresie poprawności uzupełnianych przez gracza raportów wiedzy; 100 oznacza, że w danym obszarze osiągnięto maksymalny wynik, możliwy do osiągnięcia)\n*Zdolności (oceniane na podstawie przewag w zakresie statystyk dodatkowych, mierzących zachowania gracza podczas niezwiązanych z uzupełnianiem raportów wiedzy, aktywności gracza w grze, w tym m.in. relacji poprawności do czasu)"
          }
        </Text>
        <Text style={styles.sectionHeader}>
          {`Poziom kompetencji cyfrowych`}
        </Text>
        <View style={styles.section}>
          <Text>
            {`Najwyższy poziom wiedzy i umiejętności, spośród 10 kluczowych technologii cyfrowych, wykazano w obszarze technologii `}
            <Text style={styles.bold}>{int2 ? int2 : "brak"}</Text>.
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            {`Najniższy poziom wiedzy i umiejętności, spośród 10 kluczowych technologii cyfrowych, wykazano w obszarze technologii `}
            <Text style={styles.bold}>{int3}</Text>.
          </Text>
        </View>
        <Text style={styles.sectionHeader}>
          {`Zainteresowania i sprawność wykonywania zadań`}
        </Text>
        <View style={styles.section}>
          <Text>
            {"Zadania związane z tematyką technologii "}
            <Text style={styles.bold}>{int4}</Text>
            {
              " wykonywane były najefektywniej, uwzględniając relację poprawności i czasu wykonywania zadań i aktywności dodatkowych."
            }
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            {"Zadania związane z tematyką technologii "}
            <Text style={styles.bold}>{int5}</Text>
            {
              " wykonywane były najmniej efektywnie, uwzględniając relację poprawności i czasu wykonywania zadań i aktywności dodatkowych."
            }
          </Text>
        </View>
      </Page>
      <Page style={styles.body}>
        <Text style={styles.sectionHeader}>{`Wiedza i umiejętności`}</Text>
        <View style={styles.section}>
          <Text>{`Najwyższe wyniki osiągnięto w zakresie realizacji następujących zadań:`}</Text>
          <Text style={{ paddingLeft: 20 }}>
            <Text>• </Text>
            {reports[0].key}
          </Text>
          <Text style={{ paddingLeft: 20 }}>
            <Text>• </Text>
            {reports[1].key}
          </Text>
          <Text style={{ paddingLeft: 20 }}>
            <Text>• </Text>
            {reports[2].key}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>{`Najniższe wyniki osiągnięto w zakresie realizacji następujących zadań:`}</Text>
          <Text style={{ paddingLeft: 20 }}>
            <Text>• </Text>
            {reports[19].key}
          </Text>
          <Text style={{ paddingLeft: 20 }}>
            <Text>• </Text>
            {reports[18].key}
          </Text>
          <Text style={{ paddingLeft: 20 }}>
            <Text>• </Text>
            {reports[17].key}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>{`Szczegółowe wyniki osiągnięte w zakresie realizacji każdego z 20 rodzajów zadań przedstawia poniższy wykres*.`}</Text>
        </View>
        {chart2 && <Image style={styles.chart2} src={chart2} />}
        <Text style={styles.italic}>
          {
            "*Wyniki stanowią sumę łączną wyników uzyskanych w 20 grach. 100% oznacza, iż dane zadanie, w każdej z 20 gier wykonano na 100%."
          }
        </Text>
      </Page>
    </Document>
  );
};

export default DigitalProfileReport;

const styles = StyleSheet.create({
  body: {
    fontFamily: "Nunito",
    padding: "20px 0",
  },
  logo: {
    width: 128,
    height: 56,
    margin: "0 auto 20 auto",
  },
  topInfo: {
    fontSize: 11,
    margin: "0px 20px 10px 20px",
    color: "#000",
  },
  buttonText: {
    color: "#363d52",
    textTransform: "uppercase",
    fontSize: 10,
  },
  buttonWrapper: {
    backgroundColor: "#00f8ff",
    padding: "8px 12px",
    margin: "0 auto",
    borderRadius: 8,
    marginBottom: 20,
  },
  header: {
    backgroundColor: "#00f8ff",
  },
  section: {
    // marginBottom: 20,
    fontSize: 11,
    margin: "0px 20px 8px 20px",
  },
  bold: {
    fontWeight: "bold",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  withWrap: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  sectionHeader: {
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 11,
    marginBottom: 10,
    marginTop: 24,
  },
  chart1: {
    margin: "0px auto 8px auto",
    width: 430,
    height: 220,
    // marginHorizontal: "auto",
  },
  chart2: {
    margin: "0px auto 8px auto",
    width: 430,
    height: 225,
    // marginHorizontal: "auto",
  },
  italic: {
    fontSize: 8,
    fontStyle: "italic",
    margin: "0px 20px 0px 20px",
  },
});
