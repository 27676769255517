const html = `<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oferta na wytworzenie rozwiązania z obszaru IoT</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki wdrożeniu platformy IoT:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wysokie koszty hodowli&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">brak doświadczonych pracownik&oacute;w, kt&oacute;rzy we właściwy spos&oacute;b mogliby zarządzać hodowlą&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">czasochłonny proces ręcznego wprowadzania danych w zakresie hodowli do oprogramowania wspierającego&nbsp;</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Koncepcja realizacji usługi:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Efektem projektu będzie oparta na chmurze obliczeniowej platforma IoT. Rozwiązanie stanowić będzie całościową realizację projektu cyfryzacji procesu hodowli. Jako cyfryzację należy rozumieć następujące etapy:</span></p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">umożliwienie komunikacji system&oacute;w wewnętrznych hali i akwari&oacute;w z systemami zewnętrznymi poprzez łącze internetowe&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">agregację oraz persystentne przechowywanie danych (zapisywanie danych na stałe gdzieś na zewnątrz naszego programu, np. w plikach) pozyskanych z wielu akwari&oacute;w w sp&oacute;jny spos&oacute;b, lecz w wielu modelach zależnie od postawionych wymagań&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">umożliwienie przetwarzania oraz analizowania zebranych danych pod kątem uzyskania informacji mających na celu optymalizację procesu hodowli&nbsp;&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wystawienie poprzez dedykowany interfejs wynik&oacute;w analizy zebranych danych oraz wystawienie surowych bądź wstępnie przetworzonych danych w celu dalszej ich analizy w systemach trzecich.</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Szczeg&oacute;ły proponowanych działań:&nbsp;&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">rozwiązanie powinno dostarczać danych umożliwiających automatyzację procesu zarządzania hodowlą</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">dostęp do danych w czasie rzeczywistym</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wdrożenie środowiska do analizy danych (Data Science)</span></li>
</ul>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologie proponowane do wykorzystania w projekcie:</span></strong></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wiodące rynkowe technologie, narzędzia i platformy wspierające tworzenie rozwiązań z obszaru IoT</span></p>
<p>&nbsp;</p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</span></strong></p>
<p>&nbsp;</p>
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">obniżenie koszt&oacute;w zarządzania hodowlą&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">wzrost efektywności hodowli (spadek śmiertelności liczby rybek)&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">podniesienie innowacyjności hodowli i wizerunku firmy </span></li>
</ul>`;

export default html;
