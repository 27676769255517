import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Add_EditUserGameType from "../interfaces/game/Add_EditUserGameType";
import AverageScore from "../interfaces/game/AverageScore";
import CategoryMockType from "../interfaces/game/CategoryMockType";
import CategoryType from "../interfaces/game/CategoryType";
import DeleteGameRequest from "../interfaces/game/DeleteGameRequest";
import GameReducer from "../interfaces/game/GameReducer";
import GameType from "../interfaces/game/GameType";
import UserGameScoreType from "../interfaces/game/UserGameScoreType";
import UserGameType from "../interfaces/game/UserGameType";

const initialState: GameReducer = {
  isFetching: false,
  isFetchingGames: false,
  isFetchingUserGameScore: false,
  isFetchingUserGames: false,
  isAddingGame: false,
  error: null,
  games: null,
  categories: [],
  userGames: [],
  userGameScore: null,
  game: null,
  gamesData: [],
  categoriesNames: {},
  averageScores: [],
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    clearGameReducer(state) {
      state.games = null;
      state.categories = [];
      state.userGames = [];
      state.game = null;
      state.gamesData = [];
      state.categoriesNames = {};
      state.averageScores = [];
    },
    setGamesData(state, action: PayloadAction<CategoryMockType[]>) {
      state.gamesData = action.payload;
      let categoriesNames: { [key: string]: string } = {};
      action.payload.forEach((item) => {
        categoriesNames[item.key] = item.categoryName;
      });
      state.categoriesNames = categoriesNames;
    },
    getGamesPending(state) {
      state.isFetchingGames = true;
      state.error = "";
    },
    getGamesFulfilled(state, action: PayloadAction<CategoryType[]>) {
      state.isFetchingGames = false;
      const mappedCategories = action.payload.map((category: CategoryType) => ({
        ...category,
        games: category.games.map((game: GameType) => ({
          ...game,
          categoryKey: category.key,
        })),
      }));
      const games = mappedCategories
        .map((category) => category.games.flat())
        .flat();
      state.categories = mappedCategories.sort((a, b) =>
        a.key > b.key ? 1 : -1
      );
      state.games = [...games.sort((a, b) => (a.key > b.key ? 1 : -1))];
    },
    getGamesRejected(state, action: PayloadAction<string>) {
      state.isFetchingGames = false;
      state.error = action.payload;
    },
    getUserGamesPending(state) {
      state.isFetchingUserGames = true;
      state.error = "";
    },
    getUserGamesFulfilled(state, action: PayloadAction<UserGameType[]>) {
      state.isFetchingUserGames = false;
      state.userGames = action.payload;
    },
    getUserGamesRejected(state, action: PayloadAction<string>) {
      state.isFetchingUserGames = false;
      state.error = action.payload;
    },
    getUserGamePending(state) {
      state.isFetchingUserGameScore = true;
      state.error = "";
    },
    getUserGameFulfilled(state, action: PayloadAction<UserGameScoreType>) {
      state.isFetchingUserGameScore = false;
      state.userGameScore = action.payload;
    },
    getUserGameRejected(state, action: PayloadAction<string>) {
      state.isFetchingUserGameScore = false;
      state.error = action.payload;
    },
    setUserGameScore(state, action: PayloadAction<UserGameScoreType | null>) {
      state.userGameScore = action.payload;
    },
    getGamePending(state) {
      state.isFetching = true;
      state.error = "";
    },
    getGameFulfilled(state, action: PayloadAction<GameType>) {
      state.isFetching = false;
      state.game = action.payload;
    },
    getGameRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    getAverageSubtaskResultsPending(state) {
      state.isFetching = true;
      state.error = "";
    },
    getAverageSubtaskResultsFulfilled(
      state,
      action: PayloadAction<AverageScore[]>
    ) {
      state.isFetching = false;
      state.averageScores = action.payload;
    },
    getAverageSubtaskResultsRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    add_editUserGamePending(state) {
      state.isFetching = true;
      state.error = "";
    },
    add_editUserGameFulfilled(
      state,
      action: PayloadAction<Add_EditUserGameType>
    ) {
      const updatedGame = action.payload;
      state.isFetching = false;
      state.userGames = state.userGames.map((game) =>
        game.gameId === updatedGame.gameId ? { ...game, ...updatedGame } : game
      );

      //@ts-ignore
      state.games = state.games
        ? state.games.map((game, idx) =>
            game.id === updatedGame.gameId
              ? {
                  ...game,
                  userGame: updatedGame,
                }
              : game
          )
        : null;
    },
    add_editUserGameRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    addGamePending(state) {
      state.isAddingGame = true;
      state.error = "";
    },
    addGameFulfilled(state, action: PayloadAction<GameType>) {
      state.isAddingGame = false;
      const games = state.games ? state.games : [];
      state.games = [...games, action.payload];
    },
    addGameRejected(state, action: PayloadAction<string>) {
      state.isAddingGame = false;
      state.error = action.payload;
    },
    deleteGamePending(state) {
      state.isFetching = true;
      state.error = "";
    },
    deleteGameFulfilled(state, action: PayloadAction<DeleteGameRequest>) {
      state.isFetching = false;
      const games = state.games ? state.games : [];
      state.games = games.filter((game) => game.id !== action.payload.id);
    },
    deleteGameRejected(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.error = action.payload;
    },
  },
});

export const {
  clearGameReducer,
  getGamesPending,
  getGamesFulfilled,
  getGamesRejected,
  getUserGamesFulfilled,
  getUserGamesPending,
  getUserGamesRejected,
  getGameFulfilled,
  getGamePending,
  getGameRejected,
  setGamesData,
  getAverageSubtaskResultsFulfilled,
  getAverageSubtaskResultsPending,
  getAverageSubtaskResultsRejected,
  add_editUserGameFulfilled,
  add_editUserGamePending,
  add_editUserGameRejected,
  addGameFulfilled,
  addGamePending,
  addGameRejected,
  getUserGameFulfilled,
  getUserGamePending,
  getUserGameRejected,
  setUserGameScore,
  deleteGameFulfilled,
  deleteGamePending,
  deleteGameRejected,
} = gameSlice.actions;

export default gameSlice.reducer;
