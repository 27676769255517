const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Chmura obliczeniowa to model dostarczania usług informatycznych przez Internet. W modelu tym, zamiast korzystać z lokalnych zasob&oacute;w informatycznych, takich jak serwery i magazyny danych, użytkownicy korzystają z zasob&oacute;w udostępnianych przez dostawc&oacute;w chmury obliczeniowej.</p>
<p style="text-align: justify;">Chmura obliczeniowa oferuje użytkownikom elastyczny i skalowalny (możliwy do szybkiego zwiększenia) dostęp do zasob&oacute;w informatycznych, takich jak moc obliczeniowa, pamięć i przestrzeń dyskowa. Zasoby te są dostępne na żądanie. Użytkownicy płacą tylko za te zasoby, kt&oacute;re zostały wykorzystane.&nbsp;</p>
<p style="text-align: justify;"><strong>Chmura obliczeniowa oferuje następujące modele usług:</strong></p>
<p style="text-align: justify;"><strong>IaaS (Infrastructure as a Service) </strong>- dostawca chmury obliczeniowej udostępnia infrastrukturę IT, taką jak serwery, sieci, pamięć masową i inne zasoby, kt&oacute;re użytkownik może odpowiednio konfigurować.</p>
<p style="text-align: justify;"><strong>PaaS (Platform as a Service) </strong>-&nbsp;dostawca chmury obliczeniowej udostępnia platformę programistyczną, taką jak środowisko uruchomieniowe, biblioteki i narzędzia, kt&oacute;re użytkownik może użyć do tworzenia, testowania i wdrażania aplikacji.</p>
<p style="text-align: justify;"><strong>SaaS (Software as a Service)</strong> -&nbsp;dostawca chmury obliczeniowej udostępnia aplikacje biznesowe, takie jak programy do zarządzania relacjami z klientami (CRM), systemy zarządzania zasobami przedsiębiorstwa (ERP), aplikacje do wizualizacji danych binzesowych (BI)</p>
<p style="text-align: justify;">Gł&oacute;wne zalety chmury obliczeniowej to: łatwy dostęp do zasob&oacute;w informatycznych, niskie koszty wdrożenia, elastyczność i skalowalność. Wielu przedsiębiorstw korzysta z chmury obliczeniowej, aby usprawnić swoje procesy biznesowe, zwiększyć efektywność i redukować koszty.</p>
<p style="text-align: justify;"><strong>Przykłady:</strong>&nbsp;Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform, IMB Cloud&nbsp;</p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Obsługa konfigurator&oacute;w usług poszczeg&oacute;łnych dostawc&oacute;w, znajomość podstaw CI/CD oraz podejścia Infrastructure as a code.&nbsp;</p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Każdy dostawca oferuje r&oacute;żne modele usług, narzędzia i funkcjonalności, a wyb&oacute;r najlepszego dostawcy zależy od potrzeb i wymagań użytkownika. </p>`;

export default html;
