const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Pierwsze etapy konfiguracji usług chmurowych</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Rejestracja i logowanie do panelu administratora</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po wybraniu dostawcy usługi chmurowej, należy zarejestrować się w panelu administratora i zalogować się na swoje konto.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wyb&oacute;r planu usługi</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Należy wybrać plan usługi, kt&oacute;ry najlepiej odpowiada potrzebom firmy lub organizacji. Zazwyczaj istnieją r&oacute;żne opcje, kt&oacute;re r&oacute;żnią się ceną, pojemnością dyskową, przepustowością sieciową i innymi parametrami.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Konfiguracja konta użytkownika</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Po wybraniu planu usługi, należy skonfigurować konto użytkownika, w tym wybrać nazwę użytkownika i hasło, a także wprowadzić informacje kontaktowe.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Konfiguracja środowiska chmurowego na kt&oacute;rym będzie działać aplikacja lub serwer</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 8pt; margin-bottom: 8pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W zależności od dostawcy, może to obejmować wyb&oacute;r systemu operacyjnego, wdrożenie aplikacji, konfigurację sieciową, itp.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
