const html = `<h3 style="text-align: center;"><strong>Case study projektu 2'</strong></h3>
<p><strong>Tytuł projektu:&nbsp; </strong><span style="font-weight: 400;">Modernizacja data center (centrum przetwarzania danych , tj. pomieszczenie gdzie zainstalowane są m.in. urządzenia sieciowe i serwery na kt&oacute;rych uruchamiane są aplikacje firmowej) firmy z branży automotive</span></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Starzejące się serwery na kt&oacute;rych kończy się wsparcie i leasing, w związku z czym należało podjąć decyzję czy kupować nowe serwery czy też przejść na infrastrukturę chmurową. Zdecydowano się na architekturę chmurową gł&oacute;wnie ze względu na dużą możliwość skalowania tj. dostosowania infrastruktury do szybko zmieniających się potrzeb firmy.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Migracja system&oacute;w i aplikacji wykorzystywanych w organizacji na infrastrukturę chmurową w modelu IaaS &ndash; Infrastructure as a Service (sieć wirtualna, serwery wirtualne, usługi sieciowe&nbsp; - np. loadbalancer [przenosi klient&oacute;w aplikacji do konkretnego serwera wirtualnego, tak by zr&oacute;wnoważyć ruch dla wszystkich serwer&oacute;w, dzięki czemu aplikacja działa wydajniej)</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Brak konieczności instalowania i konfigurowania nowych urządzeń w data center, a w przyszłości brak konieczności utrzymywania urządzeń i data center, co wiązać się będzie ze zwolnieniem pomieszczeń dotychczas wykorzystywanych w ramach data center. Możliwość skalowania platformy sprzedażowej [zwiększenie zasob&oacute;w obliczeniowych niezbędnych do poprawnego działania systemu &ndash; obsługi większej liczby użytkownik&oacute;w] w przypadku sezonowych wzrost&oacute;w popytu na sprzedawane produkty.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie:</strong><span style="font-weight: 400;"> Platforma chmurowa z usługami takimi jak, m.in. : wirtualne maszyny, load balancery, wirtualne sieci, system do uwierzytelniania użytkownik&oacute;w nadawania uprawnień, storage</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">1 miesiąc</span></p>
<p><strong>Zesp&oacute;ł:</strong><span style="font-weight: 400;"> Inż.chmurowy, Architekt/PM</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">Koszt wdrożenia: 2x 20 MD =40 MD x 2 tys. = 80 tys. + koszt usług subskrypcyjnych w zależności od wykorzystania (ok 3000 zł//msc)</span></p>`;

export default html;
