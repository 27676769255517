const html = `<h3 style="text-align: center;"><strong>Case study projektu 1'</strong></h3>
<p><strong>Tytuł projektu: </strong><span style="font-weight: 400;">Wdrożenie platformy komunikacji do komunikacji zdalnej w banku</span></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Brak możliwości szybkiej i efektywnej zdalnej komunikacji w zespole i z klientami w warunkach braku możliwości lub chęci kontaktu fizycznego (pandemia, lockdown, preferencje pracownik&oacute;w i klient&oacute;w)</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt zakładał wdrożenie aplikacji hostowanej w chmurze, kt&oacute;ra umożliwia prowadzenie wideokonferencji i używanie komunikatora/chata [typu instant messaging] do szybkiej komunikacji</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Ułatwienie przekazywania zadań i zarządzania zespołem w warunkach pracy zdalnej. Zwiększenie efektywności komunikacji pomiędzy pracownikami w zespole rozproszonym. Utrzymanie zaangażowania zespołu w warunkach wymuszonej pracy zdalnej.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Gotowa aplikacja (out of the box) oferowana w modelu SaaS, posiadająca funkcjonalność chat i telekonferencji</span></p>
<p><strong>Czas trwania projektu: </strong><span style="font-weight: 400;">2 miesiące</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">PM, Architekt cyberbezpieczeństwa, Architekt sieci, Architekt rozwiązań typu modern workplace)</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">400 tys. zł (40 MD x 4 pracownik&oacute;w x 2500 zł/stawka za dzień pracy członka zespołu) + 600 tys. zł (120 zł/osoba (koszt subskrypcji miesięcznej) x 5 tys pracownik&oacute;w</span></p>`;

export default html;
