import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import CheckIcon from "../../../assets/images/icons/check_primary_icon.svg";
import { getLastPayment } from "../../../services/payment.service";
import PaymentType from "../../../interfaces/payment/PaymentType";
import { PaymentStatusType } from "../../../enums/PaymentStatusType";
import { getUser } from "../../../services/user.service";

interface Props {}

const PaymentStatusScreen = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const lastPayment: PaymentType | null = useSelector(
    (state: AppState) => state.paymentReducer.lastPayment
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  useEffect(() => {
    dispatch(getLastPayment(userId));
  }, []);

  useEffect(() => {
    if (userId && dispatch && lastPayment) {
      dispatch(getUser(userId));
    }
  }, [lastPayment, userId, dispatch]);

  const getStatusText = (status: PaymentStatusType) => {
    switch (status) {
      case PaymentStatusType.COMPLETED:
        return "Transakcja przebiegła pomyślnie";
      case PaymentStatusType.CANCELED:
        return "Transakcja została odrzucona";
      case PaymentStatusType.PENDING:
        return "Oczekiwanie na potwierdzenie transakcji";
      default:
        return "Brak danych o transakcji";
    }
  };

  return (
    <div className="buyProductScreen">
      <div className="componentHeader paymentStatus">
        {lastPayment && lastPayment.status === PaymentStatusType.COMPLETED && (
          <img className="selectedIcon" src={CheckIcon} alt="selectedIcon" />
        )}
        {!lastPayment && "Brak danych o transakcji"}
        {lastPayment && <span>{getStatusText(lastPayment.status)}</span>}
      </div>
      {lastPayment && lastPayment.planLabel && (
        <div className="productComponent">
          <span className="label">{lastPayment.planLabel}</span>
        </div>
      )}
    </div>
  );
};

export default PaymentStatusScreen;
