import { TableCell, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppDispatch, AppState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import GroupUser from "../../../../../interfaces/group/GroupUser";
import StyledTextButtonWithIcon from "../../../../components/StyledTextButtonWithIcon";
import MainContext from "../../../../../context/MainContext";
import { DateTime } from "luxon";
import { usePDF } from "@react-pdf/renderer";
import UserGameReport from "../../../../../pdfs/UserGameReport";
import { getUserGameScore } from "../../../../../services/game.service";
import { setUserGameScore } from "../../../../../reducers/game.reducer";
import UserGameScoreType from "../../../../../interfaces/game/UserGameScoreType";
import GameMockType from "../../../../../interfaces/game/GameMockType";
import AverageScore from "../../../../../interfaces/game/AverageScore";
import * as htmlToImage from "html-to-image";
import DigitalProfileReport from "../../../../../pdfs/DigitalProfileReport";
import {
  getDigitalProfile,
  sendDigitalProfileMail,
} from "../../../../../services/user.service";
import UserType from "../../../../../interfaces/user/UserType";
import { setDigitalProfile } from "../../../../../reducers/user.reducer";

interface Props {
  row: GroupUser;
  gameData: GameMockType | undefined;
}

interface UserGameReportPDFButtonProps {
  row: GroupUser;
  gameData: GameMockType | undefined;
  afterDownload: () => void;
}

interface DigitalProfileReportPDFButtonProps {
  row: GroupUser;
  afterDownload: () => void;
}

const UserGameReportPDFButton = (props: UserGameReportPDFButtonProps) => {
  const { row, gameData, afterDownload } = props;
  const mainContext = useContext(MainContext);
  const dispatch: AppDispatch = useDispatch();
  const { filtersSelectedGame } = mainContext;

  const userGameScore: UserGameScoreType | null = useSelector(
    (state: AppState) => state.gameReducer.userGameScore
  );

  const averageScores: AverageScore[] = useSelector(
    (state: AppState) => state.gameReducer.averageScores
  );

  const [instance, updateInstance] = usePDF({
    document: (
      <UserGameReport
        userGameScore={userGameScore}
        gameData={gameData}
        userName={`${row.firstName} ${row.lastName}`}
        averageScores={averageScores}
      />
    ),
  });

  useEffect(() => {
    dispatch(getUserGameScore(row.id, filtersSelectedGame));
  }, []);

  useEffect(() => {
    updateInstance();
  }, [userGameScore]);

  useEffect(() => {
    if (instance.url && !instance.loading && userGameScore) downloadPDF();
  }, [instance]);

  const downloadPDF = async () => {
    var a = document.createElement("a");
    a.href = instance.url!;
    a.download = "Raport z wynikami gracza.pdf";
    a.click();
    await dispatch(setUserGameScore(null));
    afterDownload();
  };

  return <StyledTextButtonWithIcon label={"Ładowanie"} />;
};

export const UserRow = (props: Props) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { row, gameData } = props;
  const [hasActionButtons, setHasActionButtons] = useState<boolean>(false);

  const [isDownloadingUserGamePDF, setIsDownloadingUserGamePDF] =
    useState<boolean>(false);

  const digitalProfile = useSelector(
    (state: AppState) => state.userReducer.digitalProfile
  );

  useEffect(() => {
    const hasActionButtons =
      !!row.digitalProfileId ||
      (row.gameResults.length > 0 && row.gameResults[0] !== null);
    setHasActionButtons(hasActionButtons);
  }, [row]);

  useEffect(() => {
    //TODO pobrać digital profile przy kliknięciu i jak się pobierze wywołać downloadPDF()
    if (digitalProfile && row.id === digitalProfile.userId) downloadPDF();
  }, [digitalProfile]);

  const downloadPDF = async () => {
    var a = document.createElement("a");
    a.href = "data:image/png;base64," + digitalProfile!.content;
    a.download = "Profil cyfrowy.pdf";
    a.click();
    dispatch(setDigitalProfile(null));
  };

  return (
    <React.Fragment>
      <TableRow className="userRow">
        <TableCell>{row.firstName}</TableCell>
        <TableCell>{row.lastName}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          {row.gameResults.length > 0 && row.gameResults[0]
            ? `${row.gameResults[0].score}%`
            : "-"}
        </TableCell>
        <TableCell>
          {row.gameResults.length > 0 && row.gameResults[0]
            ? DateTime.fromISO(row.gameResults[0].userGameDate, {
                zone: "UTC",
              }).toFormat("dd.LL.yyyy")
            : "-"}
        </TableCell>
        <TableCell align="right" className="actionsTC">
          <div
            className={`buttons ${
              hasActionButtons ? "" : "withoutActionButtons"
            }`}
          >
            {row.gameResults.length > 0 &&
              row.gameResults[0] &&
              (!isDownloadingUserGamePDF ? (
                <StyledTextButtonWithIcon
                  onClick={() => setIsDownloadingUserGamePDF(true)}
                  label={"Pobierz raport gracza"}
                />
              ) : (
                <UserGameReportPDFButton
                  row={row}
                  gameData={gameData}
                  afterDownload={() => setIsDownloadingUserGamePDF(false)}
                />
              ))}
            {/* {!isDownloadingDigitalProfilePDF ? ( */}
            {row.digitalProfileId ? (
              <StyledTextButtonWithIcon
                label="Pobierz profil cyfrowy"
                // onClick={() => setIsDownloadingDigitalProfilePDF(true)}
                onClick={() => dispatch(getDigitalProfile(row.id))}
              />
            ) : (
              <div style={{ height: "36px" }} />
            )}
            {!hasActionButtons && (
              <span style={{ textAlign: "center" }}>-</span>
            )}
            {/* ) : (
              <DigitalProfileReportPDFButton
                row={row}
                // gameData={gameData}
                afterDownload={() => setIsDownloadingDigitalProfilePDF(false)}
              />
            )} */}
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
