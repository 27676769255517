const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Podstawy prognozowania z wykorzystaniem modeli ML/AI</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Prognozowanie a inne problemy maszynowego uczenia</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Prognozowanie r&oacute;żni się od innych problem&oacute;w maszynowego uczenia takich, jak scoring, rozpoznawanie obraz&oacute;w czy też generowanie tekstu. W przypadku prognozowania cel stawiany algorytmowi to np. oszacowanie wartości popytu lub wielkości rynku w przyszłości. Oznacza to, że wynikiem modelu prognostycznego jest nie jedna liczba, ale ciąg wartości dla kolejnych kwartał&oacute;w, miesięcy czy też tygodni. Im dalej w przyszłość dokonywana jest prognoza, tym większym będzie obarczona błędem. Wartości prognozy, kt&oacute;re są najbliższe czasowo są zarazem najpewniejsze.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Algorytmy prognozowania</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Prognozowanie może być realizowane za pomocą r&oacute;żnych algorytm&oacute;w - mogą to być modele autoregresyjne, drzewa losowe a w ostatnich latach coraz częściej modele głębokiego uczenia. To jaki model zostanie zastosowany jest powiązane z ilością oraz rodzajem danych. Jest tak ponieważ prognozowanie nie jest konkretnym algorytmem, lecz zadaniem kt&oacute;re stawiamy algorytmowi. A zadaniem tym jest przewidywanie wartości interesującej nas zmiennej w przyszłości.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Inne problemy maszynowego uczenia</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Inne problemy uczenia maszynowego to np. scoring, gdzie algorytm musi odpowiedzieć na pytanie jakie jest prawdopodobieństwo jakiegoś zdarzenia (np. niespłacenia kredytu). Maszynowe uczenie pozwala nam r&oacute;wnież rozpoznawać obrazy, co jest przykładowo wykorzystywane w autonomicznych autach. Algorytmy pozwalają r&oacute;wnież na automatyczne generowanie tekstu lub jego tłumaczenie. Co istotne, w przypadku prognozowania wykorzystujemy bardzo podobne algorytmy. To co r&oacute;żni prognozowanie od powyższych przykład&oacute;w to cel stawiany algorytmowi.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
