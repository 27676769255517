const html = `<h3 style="text-align: center;"><strong>Case study 2</strong></h3>
<p><strong>Tytuł projektu: Zdecentralizowana giełda obrotu kryptoaktywami&nbsp;</strong></p>
<p><strong>Gł&oacute;wny problem klienta: </strong><span style="font-weight: 400;">Obecnie wykorzystywane aplikacje do potwierdzania oryginalności produkt&oacute;w nie dawały konsumentom pewności, że kupowany przez nich przedmiot faktycznie jest oryginalny, a dostęp do szczeg&oacute;łowych informacji był utrudniony.</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">W ramach projektu stworzono aplikację wykorzystującą publiczną sieć blockchain, w kt&oacute;rej rejestrowane były wszyscy zdarzenia oraz dokumenty powiązane z produktami oferowanymi przez producenta. Klienci korzystając z dedykowanej aplikacji lub skanując kod QR dołączony do produktu otrzymali możliwość zweryfikowania cyklu życia kupowanego przedmiotu.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Wykorzystując technologię blockchain klient był w stanie stworzyć aplikację, kt&oacute;ra dostarcza klientom niepodważalne dane o kupowanym produkcie, dzięki czemu mogą oni zweryfikować jego oryginalność, pochodzenie oraz zostają przypisani jako właściciele do zakupionego przedmiotu. Pozwala to r&oacute;wnież otworzyć się na rynek wt&oacute;rny, gdzie będą oni mogli przyszłym zainteresowanym osobom, potwierdzić że byli jedynymi właścicielami danego przedmiotu.</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">Publiczna sieć blockchain Ethereum, aplikacja webowa wytworzona z wykorzystaniem React.JS oraz natywne aplikacje iOS i Android.</span></p>
<p><strong>Czas trwania projektu: </strong>6 miesięcy</p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Project Manager, Analityk biznesowy, Architekt IT, Developer front-end, Developer back-end, Developer warstwy smart-contract, Dev-Ops</span></p>
<p><strong>Budżet projektu: </strong>1,3 mln zł</p>`;

export default html;
