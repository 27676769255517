import React from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";

interface Props {
  onChange: (value: string) => void;
  options: { value: string; text: string }[];
  value: string | undefined;
}

const StyledSelect = (props: Props) => {
  const { t } = useTranslation();
  const { onChange, options, value } = props;

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      className="styledSelect"
      // IconComponent={<img src={ArrowDownIcon} alt="" />}
      onChange={(event) => onChange(event.target.value)}
    >
      {options.map((option) => (
        <MenuItem value={option.value}>{option.text}</MenuItem>
      ))}
    </Select>
  );
};

export default StyledSelect;
