const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Data Lake to tzw. jezioro danych, to scentralizowane repozytorium, kt&oacute;re pozwala na przechowywanie wszystkich ustrukturyzowanych i nieustrukturyzowanych danych w dowolnej skali. Budowa rozwiązania wymaga odpowiednich zasob&oacute;w pamięci (tzw. data storage). Dane w data lake są przechowywane w surowym formacie, bez wcześniejszego przetworzenia, organizacji lub struktury, co pozwala na elastyczność i skalowalność. Jeziora danych zawdzięczają nazwę idei, kt&oacute;ra stawia je w opozycji wobec hurtowni danych, w kt&oacute;rych dane muszą być zgodne z g&oacute;ry przyjętą, narzuconą strukturą. Dane, podobnie jak woda w jeziorze znajdują się w swojej naturalnej formie i nie są podawane takim procesom standaryzacji. Kluczowe przypadki użycia usługi Data Lake to m.in.: przechowywanie lokalne danych generowanych w ramach IoT, przetwarzanie danych big data, lokalne przenoszenie danych, przechowywanie danych testowych do trenowania modeli ML/AI.&nbsp;</p>
<p style="text-align: justify;">Cloud Storage to usługa udostępniania przestrzeni do przechowywania dużej ilości danych,&nbsp;w chmurze, stanowiąca fundament data lake.&nbsp;Określana jest jako&nbsp;usługa pamięci masowej do pracy z danymi, zwłaszcza danymi nieustrukturyzowanymi. Umożliwia m.in. składowanie danych nieustrukturyzowanych, w formie obiekt&oacute;w (object storage). Dane obsługiwane być mogą przez bazy typu NoSQL/Hadoop. Cloud Storage może zostać zastosowane do przechowywania i ochrony danych klient&oacute;w zgodnie z wymogami prawa, do tworzenia warstwy data lake w projektach Big Data i Data Analytics, odzyskiwania danych w ramach plan&oacute;w Disaster Recovery.&nbsp;Rozwiązanie zabezpieczone jest kluczami szyfrującymi i innymi środkami ochrony danych.</p>
<p style="text-align: justify;">Spos&oacute;b organizacji danych w pamięci masowej dzieli dane na tzw. gorące (Hot), dane te często odczytywane, dane zimne (Cold), dane te odczytywane są sporadycznie oraz dane archiwalne. Obiekty mogą być &ldquo;przenoszone&rdquo; pomiędzy r&oacute;żnymi rodzajami danych.</p>
<p style="text-align: justify;"><strong>Przykłady narzędzi/rozwiązań do zastosowania</strong>AWS Data Lake, Cloudera Data Platform, Google Data Lake (Big Query), Snowflake Cloud Data Platform,&nbsp;Google Cloud Storage,&nbsp;Azure Data Lake Storage Gen2</p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Obsługa SQL Server Management Studio, znajomość język&oacute;w &nbsp;SQL, DAX, MDX, ASSL, XMLA.</p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Brak schematu lub metadanych opisowych może utrudnić korzystanie z danych lub wykonywanie zapytań.</p>`;

export default html;
