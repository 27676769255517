const html = `<h3 style="text-align: center;"><strong>Case study projektu 3'</strong></h3>
<p><strong>Tytuł projektu: </strong><span style="font-weight: 400;">Skonfigurowanie platformy DevOps dla start-upu tworzącego aplikację do płatności online</span></p>
<p><strong>Gł&oacute;wny problem klienta:</strong><span style="font-weight: 400;"> Startup ma pomysł na aplikację, kt&oacute;ra ma zrewolucjonizować rynek płatności. W skład zespołu wchodzą wyłącznie developerzy, kt&oacute;rzy chcą się skupić wyłącznie na tworzeniu nowego produktu. W związku z tym potrzebują narzędzi do tworzenia aplikacji [środowisko developerskie gdzie tworzy kod, serwery &ndash; zapewniają moc obliczeniową, narzędzia do testowania i uruchomienia kodu, środowisko CI/CD], kt&oacute;rych utrzymywaniem [konfiguracja, monitorowanie środowiska, dostęp prądu i klimatyzacji, potrzeba zakupu dodatkowych serwer&oacute;w], nie będą musieli się zajmować.&nbsp;</span></p>
<p><strong>Kr&oacute;tki opis projektu: </strong><span style="font-weight: 400;">Projekt polega na przygotowaniu platformy dla developer&oacute;w umożliwiającej tworzenie i uruchamianie aplikacji wraz z procesami CI/CD, kt&oacute;re umożliwią płynne wdrażanie nowej wersji kodu oraz usprawnią pracę zespołową między developerami, kt&oacute;rzy pracują w metodyce DevOps. Gł&oacute;wne zadania w projekcie polegała na uruchomieniu usługi chmurowej kt&oacute;ra umożliwia hostowanie aplikacji oraz wdrożenie platformy DevOps, tym powołanie organizacji, skonfigurowanie wstępne (nadanie uprawnień, stworzenie projekt&oacute;w, skonfigurowanie pipeline&oacute;w.</span></p>
<p><strong>Korzyści osiągnięte z wdrożenia/zastosowania: </strong><span style="font-weight: 400;">Szybkie rozpoczęcie prac developerskich zespołu</span><strong>. </strong><span style="font-weight: 400;">Odciążenie developer&oacute;w od konieczności zarządzania infrastrukturą niezbędną do utrzymania platformy oraz samej platformy, dzięki czemu aplikacja tworzona może być szybciej</span></p>
<p><strong>Narzędzia/systemy/technologie wykorzystane w projekcie: </strong><span style="font-weight: 400;">platforma DevOps (SaaS), narzędzie do zarządzania tożsamością (stworzenie kont i haseł dostępu do platformy), włączenie MFA (dodatkowy składnik uwierzytelniania), platforma do hostowania aplikacji (moc obliczeniowa narzędzia do przenoszenie i testowania kodu, PaaS)</span></p>
<p><strong>Czas trwania projektu:</strong><span style="font-weight: 400;">1 tydz.</span></p>
<p><strong>Zesp&oacute;ł: </strong><span style="font-weight: 400;">Inż. DevOps</span></p>
<p><strong>Budżet projektu: </strong><span style="font-weight: 400;">5 MDx 2 tys.zł =10tys. + koszt usług subskrypcyjnych w zależności od wykorzystania (ok 450 zł//msc)</span></p>`;

export default html;
