import { Dispatch } from "@reduxjs/toolkit";
import i18n from "../i18n/i18n";
import {
  getGroupUsersFulfilled,
  getGroupUsersPending,
  getGroupUsersRejected,
  getGroupsFulfilled,
  getGroupsPending,
  getGroupsRejected,
  deleteGroupPending,
  deleteGroupFulfilled,
  deleteGroupRejected,
  addGroupPending,
  addGroupFulfilled,
  addGroupRejected,
  deleteUserPending,
  deleteUserFulfilled,
  sendGroupReportMailPending,
  sendGroupReportMailFulfilled,
  sendGroupReportMailRejected,
} from "../reducers/group.reducer";
import { setErrorMessage, setInfoMessage } from "../reducers/message.reducer";
import { errorHandler } from "../utils/error.utils";
import { axiosService } from "./axios.service";
import { store } from "../store/store";
import DeleteGroupRequest from "../interfaces/group/DeleteGroupRequest";
import AddGroupRequest from "../interfaces/group/AddGroupRequest";
import SendGroupReportRequest from "../interfaces/group/SendGroupReportRequest";

const userService = "/user";
const mailService = "/mail";

export const getGroups = () => async (dispatch: Dispatch | any) => {
  try {
    const { userId } = store.getState().userReducer;
    dispatch(getGroupsPending());
    const response = await axiosService.get(
      `${userService}/users/${userId}/groups`
    );
    dispatch(getGroupsFulfilled(response.data));
  } catch (e) {
    dispatch(
      setErrorMessage(errorHandler(e, i18n.t("error.getGamesException")))
    );
    //@ts-ignore
    dispatch(getGroupsRejected(e.message));
  }
};

export const getGroupUsers =
  (groupId: string, gameId?: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(getGroupUsersPending());
      const response = await axiosService.get(
        `${userService}/users/findByGroup/${groupId}${
          gameId ? `?gameId=${gameId}` : ""
        }`
      );
      dispatch(getGroupUsersFulfilled(response.data));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.getGamesException")))
      );
      //@ts-ignore
      dispatch(getGroupUsersRejected(e.message));
    }
  };

export const deleteGroup =
  (data: DeleteGroupRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(deleteGroupPending());
      await axiosService.delete(`${userService}/users/deleteGroup`, { data });
      dispatch(deleteGroupFulfilled());
      dispatch(getGroups());
      dispatch(setInfoMessage(i18n.t("global.deleteGroupSuccess")));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.deleteGroupException")))
      );
      //@ts-ignore
      dispatch(deleteGroupRejected(e.message));
    }
  };

export const addGroup =
  (data: AddGroupRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(addGroupPending());
      await axiosService.post(`${userService}/users/addGroup`, data);
      dispatch(addGroupFulfilled());
      dispatch(getGroups());
      dispatch(setInfoMessage(i18n.t("global.addGroupSuccess")));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.addGroupException")))
      );
      //@ts-ignore
      dispatch(addGroupRejected(e.message));
    }
  };

export const deleteUser =
  (userId: string) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(deleteUserPending());
      await axiosService.delete(`${userService}/users/delete`, {
        data: { id: userId },
      });
      dispatch(deleteUserFulfilled(userId));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.deleteUserException")))
      );
      //@ts-ignore
      dispatch(deleteGroupRejected(e.message));
    }
  };

export const sendGroupReportMail =
  (data: SendGroupReportRequest) => async (dispatch: Dispatch | any) => {
    try {
      dispatch(sendGroupReportMailPending());
      await axiosService.post(`${mailService}/mails/sendGroupReportMail`, data);
      dispatch(sendGroupReportMailFulfilled());
      dispatch(setInfoMessage(i18n.t("global.sendGroupReportMailSuccess")));
    } catch (e) {
      dispatch(
        setErrorMessage(errorHandler(e, i18n.t("error.deleteUserException")))
      );
      //@ts-ignore
      dispatch(sendGroupReportMailRejected(e.message));
    }
  };
