import React from "react";
import { useTranslation } from "react-i18next";
import GameMockType from "../../../interfaces/game/GameMockType";
import Accordion from "./Accordion";
import { accordionHeader } from "./GameScreen";
import ControllerIcon from "../../../assets/images/icons/controller_icon.svg";
import GameType from "../../../interfaces/game/GameType";
import { AppState } from "../../../store/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface Props {
  gameData: GameMockType | null;
  isConfigured: boolean;
}

const EducationalGoalsAccordion = (props: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { gameData, isConfigured } = props;

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const renderSubtasks = () => {
    const game = games && games.find((game) => game.id === id);
    const subtasks =
      game && gameData
        ? gameData.subtasks.filter((item) =>
            game.gameSubtasks.includes(item.key)
          )
        : null;
    if (subtasks)
      return subtasks.map((subtask) => {
        return (
          <div className={`subtask`} key={subtask.key}>
            <div className="subtask_title">
              {isConfigured ? subtask.task : subtask.title}
            </div>
            <div className="subtask_knowledge">
              <span className="subtask_section_title">
                {"Wiedza [Zna...]:"}
              </span>
              <ul>
                {subtask.knowledge.map((item) => (
                  <li key={item.label}>{item.label}</li>
                ))}
              </ul>
            </div>
            <div className="subtask_skills">
              <span className="subtask_section_title">
                {"Umiejętności [Potrafi opracować/wskazać...]:"}
              </span>
              <ul>
                {subtask.skills.map((item) => (
                  <li key={item.label}>{item.label}</li>
                ))}
              </ul>
            </div>
            <div className="subtask_task">
              <img src={ControllerIcon} alt="controller_icon" />
              <span className="bold">
                {"Zadanie w grze umożliwiające rozwój kompetencji: "}
                {isConfigured ? subtask.title : subtask.task}
              </span>
            </div>
          </div>
        );
      });
    else
      return <div className="noUserGame">{"Brak danych do wyświetlenia"}</div>;
  };

  return (
    <div className="educationalGoalsAccordion">
      <Accordion
        HeaderComponent={accordionHeader(
          "Cele edukacyjne realizowane przez grę"
        )}
        BodyComponent={
          <div className="bodyWrapper">{gameData && renderSubtasks()}</div>
        }
      />
    </div>
  );
};

export default EducationalGoalsAccordion;
