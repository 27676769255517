import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

interface Props {
  onClose: () => void;
  message: string;
  type: "success" | "error";
  open: boolean;
}

const StyledSnackbar = (props: Props) => {
  const { t } = useTranslation();
  const { message, onClose, type, open } = props;
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (message) setText(message);
  }, [message]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={type}
        sx={{ width: "100%" }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default StyledSnackbar;
