const html = `<h3><strong>Oferta na rozwiązanie oparte na technologii Big Data</strong></h3>
<p>&nbsp;</p>
<p><strong>Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki zastosowaniu technologii Big Data:</strong></p>
<ul>
<li><span style="font-weight: 400;">architektura obecnie wykorzystywanych narzędzi do analizy danych tj. hurtowania danych i narzędzie ETL (rozbudowywane przez ostatnie 30 lat uniemożliwia przetwarzanie dużych zbior&oacute;w danych (Big Data) z dużą wydajnością</span></li>
<li><span style="font-weight: 400;">obecny spos&oacute;b dostępu do danych nie jest wystarczająco elastyczny</span></li>
<li><span style="font-weight: 400;">moc obliczeniowa jest często niewystarczająca, zdarza się brak miejsca na zapisanie danych</span></li>
</ul>
<p>&nbsp;</p>
<p><strong>Koncepcja rozwiązania:</strong><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Proponowana do wdrożenia platforma Big Data, oparte będzie jezioro danych oraz wspierające aplikacje odpowiadające na indywidualne potrzeby Data Scientist&oacute;w. Rozwiązanie umożliwi szybki, prosty i bezpieczny dostęp do zintegrowanych i odpowiednio przygotowanych dużych zbior&oacute;w zintegrowanych danych. Rozwiązanie umożliwia automatyczne przetworzenie danych na potrzeby indywidualnych analiz. Rozwiązanie zintegrowane zostanie z obecnymi systemami do wizualizacji i raportowania, tworząc sp&oacute;jny ekosystem. Data lake gromadzić będzie zar&oacute;wno dane ustrukturyzowanych jak i nieustrukturyzowanych w efektywny kosztowo spos&oacute;b.</span></p>
<p>&nbsp;</p>
<p><strong>Szczeg&oacute;ły proponowanego rozwiązania:&nbsp;&nbsp;</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp z jednego miejsca do danych z wybranych system&oacute;w firmowych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp do narzędzi typu open source umożliwiających szybki dostęp do danych przetworzonych zgodnie z aktualnymi wymaganiami i potrzebami analityka/Data Scientista</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp do narzędzia umożliwiającego automatyczne odszyfrowywanie danych wrażliwych</span></li>
</ul>
<p>&nbsp;</p>
<p><strong>Technologie proponowane do wykorzystania w projekcie:</strong></p>
<p><span style="font-weight: 400;">Wiodące rynkowe technologie, narzędzia i platformy wspierające analizę danych.&nbsp;</span></p>
<p>&nbsp;</p>
<p><strong>Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia&nbsp;</strong></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">wykorzystanie danych jako źr&oacute;dła przewagi konkurencyjnej (wygenerowanie wartości z danych)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">wejść na wyższy poziom cyfryzacji/transformacji cyfrowej</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">dostęp do platformy analitycznej i zintegrowanych narzędzi do przetwarzania danych&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">zapewnienie przystępnego sposobu dostępu do danych (łatwa obsługa, prekonfigurowanie) wszystkim zainteresowanym pracownik&oacute;w (&nbsp; demokratyzacja dostepu do danych)</span></li>
</ul>`;

export default html;
