import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CheckIcon from "../../../../assets/images/icons/check_primary_icon.svg";
import { categoriesImages } from "../../../../constants/games";
import CategoryMockType from "../../../../interfaces/game/CategoryMockType";
import CategoryType from "../../../../interfaces/game/CategoryType";
import { AppState } from "../../../../store/store";

interface Props {
  categoryKey: string;
  onChange: (value: string) => void;
  industry: string;
}

const DigitalTechnologySection = (props: Props) => {
  const { t } = useTranslation();
  const { categoryKey, onChange, industry } = props;
  const [availableCategories, setAvailableCategories] = useState<
    CategoryType[]
  >([]);

  const categories: CategoryType[] = useSelector(
    (state: AppState) => state.gameReducer.categories
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  useEffect(() => {
    if (categories) {
      if (!industry) setAvailableCategories(categories);
      else {
        const availableCategoriesData = gamesData.filter((category) =>
          category.sectorKeys.includes(industry)
        );
        const availableCategories = categories.filter((category) =>
          availableCategoriesData.some((item) => item.key === category.key)
        );
        setAvailableCategories(availableCategories);
      }
    }
  }, [industry, categories]);

  const renderCategory = (key: string) => {
    const category = gamesData.find((category) => category.key === key)!;

    return (
      <div
        className={`bubble ${categoryKey === key ? "selected" : ""}`}
        key={key}
        onClick={() => onChange(key)}
      >
        {categoryKey === key && (
          <img className="selectedIcon" src={CheckIcon} alt="selectedIcon" />
        )}
        {categoriesImages[key] && (
          <img className="categoryIcon" src={categoriesImages[key]} alt={key} />
        )}
        {category.categoryName}
      </div>
    );
  };
  return (
    <div className="digitalTechnologySection">
      <div className="sectionsTitle">
        {"Wybierz technologię cyfrową, której dotyczyć ma konfigurowana gra:"}
      </div>
      <div className="bubbles">
        {availableCategories.map((category) => renderCategory(category.key))}
      </div>
    </div>
  );
};

export default DigitalTechnologySection;
