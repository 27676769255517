const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<h2><strong>Rodzaje danych cyfrowych</strong></h2>
</td>
</tr>
<tr>
<td>
<p><strong>Rodzaj danych cyfrowych&nbsp;</strong></p>
</td>
<td>
<p><strong>Przykłady zbior&oacute;w danych&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dane ustrukturyzowane (dane o stałej strukturze)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Relacyjne bazy danych</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Pliki csv o stałej strukturze</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Pliki Excel</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dane częściowo ustrukturyzowane (dane zawierające pewną strukturę, kt&oacute;ra jednak nie jest jasno określona i może się zmieniać)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Bazy danych NoSQL</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dane z API (np. w postaci JSON lub XML)</span></li>
</ul>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Dane nieustrukturyzowane&nbsp;(muszą być poddane obr&oacute;bce by wyciągnąć z nich informacje)</span></p>
</td>
<td>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;Email&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;Nagrania audio &ndash; video</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;Pliki tekstowe</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;Strony internetowe</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
