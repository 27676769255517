const html = `<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki zastosowaniu technologii BI:</strong></span></span> <br /><br />&bull; brak aktualnej i łatwo dostępnej informacji o jakości produkcji (ilość wadliwych produkt&oacute;w w danej partii)<br />&bull; duże prawdopodobieństwo przeoczenia ważnych informacji, co skutkuje przekazaniem wadliwych produkt&oacute;w klientowi <br />&bull; niska wiarygodność raportowanych danych i długi proces ich weryfikacji</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong> Koncepcja rozwiązania:</strong></span></span></p>
<p>Proponowane rozwiązanie BI, oparte będzie o hurtownię danych (jedno źr&oacute;dło prawdy). Rozwiązanie umożliwi pobieranie i integrację danych z wielu źr&oacute;deł oraz udostępni odpowiednie dane aplikacjom typu BI, w kt&oacute;rych tworzone będą odpowiednio skonfigurowane raporty analityczne. Hurtownia w razie potrzeby udostępni dane także innym systemom. Prosta w obsłudze i zweryfikowana przez rynek aplikacja BI (typu SaaS), jednego z wiodących dostawc&oacute;w, umożliwi relatywnie szybką realizację projektu i wysoki poziom użyteczności narzędzia z punktu widzenia odbiorc&oacute;w końcowych (pracownik&oacute;w firmy). Aplikacja automatycznie generować będzie przejrzyste i estetycznie wyglądające raporty zawierające dane o jakości produkcji oraz umożliwi w przyszłości, szybkie tworzenie nowych dashboard&oacute;w analitycznych. Raporty dostępne będą także z urządzeń mobilnych i prezentować będą aktualizowane raz dziennie (daily loaded) raporty analityczne, oparte o jasno określone wskaźniki, wyliczone zgodnie z wymaganiami biznesowymi przez model analityczny.</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Szczeg&oacute;ły proponowanego rozwiązania:</strong></span></span></p>
<p>&bull; możliwość eksportowania danych w formie pliku PDF <br />&bull; możliwość dezagregacji (rozbicia) wskaźnik&oacute;w z raport&oacute;w na szczeg&oacute;łowe dane oraz tzw. drill down, tj. tworzenia hierarchii danych <br />&bull; dostęp do określonej liczby raport&oacute;w zawierającej wskaźniki wskazane przez użytkownik&oacute;w biznesowych</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Technologie proponowane do wykorzystania w projekcie:</strong></span></span></p>
<p>Wiodące rynkowe technologie, narzędzia i platformy wspierajace analizę danych</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia BI, w tym hurtowni danych:</strong></span></span></p>
<p>&bull; podniesienie jakości produkcji dzięki dostępowi do wiedzy opartej na danych <br />&bull; odciążenie w pracy analityk&oacute;w <br />&bull; umożliwienie szybkiej modyfikacji i tworzenia nowych rodzaj&oacute;w raport&oacute;w wykorzystujących nowe rodzaje danych <br />&bull; zwiększenie estetyki i przejrzystości raport&oacute;w</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Dodatkowe szanse biznesowe możliwe do wykorzystania przez firmę, dzięki wdrożeniu proponowanego narzędzia BI, w tym hurtowni danych:</strong></span></span></p>
<p>&bull; przyrost wolumentu danych w firmie pozwala na podnoszenie jakości raportowania zarządczego <br />&bull; tendencje rynkowe w zakresie spadku koszt&oacute;w infrastruktury chmurowej <br />&bull; uzyskanie dodatkowych korzyści biznesowych z monetyzacji gromadzonych danych</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Zakładany czas realizacji prac:</strong></span></span> 21 tygodni od momentu podpisania umowy</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><span style="font-size: 13pt;"><strong>Cena realizacji prac:</strong></span></span> 350 tys. zł</p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><strong>Proponowany model wyceny realizacji prac:</strong></span> Fixed Price</p>`;

export default html;
