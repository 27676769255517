import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupRow } from "./GroupRow";
import EmptyInfoComponent from "./EmptyInfoComponent";
import GroupType from "../../../../../interfaces/group/GroupType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../../../store/store";
import { getGroupUsers } from "../../../../../services/group.service";
import StyledButton from "../../../../components/StyledButton";

interface Props {
  setIsGroupModalOpen: () => void;
}

const Groups = (props: Props) => {
  const { t } = useTranslation();
  const { setIsGroupModalOpen } = props;
  const dispatch: AppDispatch = useDispatch();
  const [openGroup, setOpenGroup] = useState<string>("");

  const groups: GroupType[] = useSelector(
    (state: AppState) => state.groupReducer.groups
  );

  const isFetchingGroups: boolean = useSelector(
    (state: AppState) => state.groupReducer.isFetchingGroups
  );

  useEffect(() => {
    if (openGroup) dispatch(getGroupUsers(openGroup));
  }, [openGroup, dispatch]);

  return (
    <div className="groupsTab">
      {groups.length > 0 && (
        <StyledButton
          label={t("organizationPanelScreen.createGroup")}
          onClick={setIsGroupModalOpen}
          type="contained"
          color="dark"
          className="createGroupButton"
        />
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="headerTc main">
              <span>{"Nazwa grupy"}</span>
            </TableCell>
            <TableCell scope="row" className="headerTc small">
              <span>{"Akcje"}</span>
            </TableCell>
          </TableRow>
          {groups.length > 0 &&
            groups.map((group: GroupType) => (
              <GroupRow
                open={openGroup === group.id}
                onRowOpen={setOpenGroup}
                row={group}
              />
            ))}
        </TableBody>
      </Table>
      {groups.length === 0 && (
        <EmptyInfoComponent
          text={t("organizationPanelScreen.noGroups")}
          withButton={true}
          onClick={setIsGroupModalOpen}
        />
      )}
    </div>
  );
};

export default Groups;
