const html = `<p style="text-align: justify;">Oferta na realizację usługi z obszaru marketingu cyfrowego&nbsp;</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Na etapie analizy przed ofertowej zidentyfikowano następujące problemy biznesowe klienta, kt&oacute;rych rozwiązywanie możliwe jest dzięki realizacji usługi marketingu cyfrowego z wykorzystaniem medi&oacute;w społecznościowych:</p>
<p style="text-align: justify;"><br></p>
<ul>
    <li>
        <p style="text-align: justify;">brak świadomości rynku na temat istnienia produktu klienta&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">brak kompetencji in house (w zespole klienta) w zakresie planowania, koordynowania i skutecznej egzekucji działań z zakresu marketingu cyfrowego&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">brak narzędzi technologicznych do analityki internetowej i wsparcia efektywności działań w obszarze marketingu cyfrowego&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">brak umiejętności weryfikowania skuteczności prowadzonych działań marketingowych&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Koncepcja realizacji usługi:</p>
<p style="text-align: justify;">&nbsp;</p>
<p style="text-align: justify;">Koncepcja działań zakłada stworzenie solidnych fundament&oacute;w strategicznych p&oacute;źniejszych działań marketingowych (od audytu dotychczasowych działań i zasob&oacute;w po opracowanie planu działań na 6 miesięcy). Plan zostanie rozszerzony o wdrożenie niezbędnych narzędzi, zaangażowanie zewnętrznych wykonawc&oacute;w oraz koordynowanie ich działań przez cały czas trwania projektu. Wdrożona strategia, po końcowej ewaluacji będzie mogła być kontynuowana przez Zamawiającego samodzielnie lub w dalszej wsp&oacute;łpracy z naszym zespołem. Szczeg&oacute;ły planowanych prac zawarto w harmonogramie.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">&nbsp; &nbsp; Szczeg&oacute;ły proponowanych działań: &nbsp;</p>
<p style="text-align: justify;"><br></p>
<ul>
    <li>
        <p style="text-align: justify;">opracowanie strategii komunikacji i działań w obszarze marketingu cyfrowego</p>
    </li>
</ul>
<p style="text-align: justify;"><br></p>
<ul>
    <li>
        <p style="text-align: justify;">opracowanie taktyki działań na okres 6 miesięcy&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><br></p>
<ul>
    <li>
        <p style="text-align: justify;">wyb&oacute;r i wdrożenie narzędzi do usprawnienia proces&oacute;w marketingu cyfrowego i analityki internetowej&nbsp;</p>
    </li>
</ul>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Technologie proponowane do wykorzystania w projekcie:</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Wiodące rynkowe technologie, narzędzia i platformy wspierające działania z obszaru marketingu cyfrowego.&nbsp;</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Wartość biznesowa dostarczona klientowi w efekcie wdrożenia narzędzia:</p>
<p style="text-align: justify;"><br></p>
<ul>
    <li>
        <p style="text-align: justify;">rozw&oacute;j kompetencji zespołu wewnętrznego w obszarze marketingu cyfrowego</p>
    </li>
    <li>
        <p style="text-align: justify;">sprzedaż 1000 płatnych subskrypcji rocznych aplikacji w ciągu 6 miesięcy trwania projektu&nbsp;</p>
    </li>
    <li>
        <p style="text-align: justify;">pozyskanie 7000 użytkownik&oacute;w kont bezpłatnych aplikacji w ciągu 6 miesięcy trwania projektu </p>
    </li>
</ul>`;

export default html;
