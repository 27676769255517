const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;" colspan="2">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Definicje termin&oacute;w związanych z technologią blockchain&nbsp;</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Baza danych off-chain</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Dedykowana baza danych lub repozytorium w kt&oacute;rym przechowywane są informacje i dane, kt&oacute;re z r&oacute;żnych powod&oacute;w nie mogły zostać zapisane w bazie danych blockchain. Powodem wykorzystania bazy off-chain może być konieczność przechowywania danych wrażliwych w tym osobowych, kt&oacute;re wymagają spełnienia wymog&oacute;w RODO (prawo do zapomnienia), lub ze względu na swoje rozmiary (dokumenty pdf, pliki ze zdjęciami lub video) byłyby zbyt duże aby przechowywać je bezpośrednio w blockchain.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Protok&oacute;ł sieci blockchain</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jest rodzajem protokołu komunikacji wykorzystywanym przez sieć blockchain. Zgodnie z definicją (wikipedia) protok&oacute;ł komunikacyjny jest to zbi&oacute;r ścisłych reguł i krok&oacute;w postępowania, kt&oacute;re są automatycznie wykonywane przez urządzenia komunikacyjne w celu nawiązania łączności i wymiany danych. Oznacza to, że w przypadku blockchain poszczeg&oacute;lne sieci wykorzystują r&oacute;żne reguły i zasady w celu tworzenia i zatwierdzania transakcji. Wynika z tego że sieć Bitcoin, Ethereum lub sieci zbudowane w oparciu o protokoły Hyperledger Fabric czy Corda będą działać w inny spos&oacute;b od strony technicznej.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Węzeł (node)</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Punkt komunikacji (komputer, serwer, maszyna wirtualna) wykorzystywany w ramach sieci. Każdy z węzł&oacute;w komunikuje się z innymi węzłami z wykorzystaniem odpowiednich protokoł&oacute;w komunikacji. Węzły działają na zasadzie peer-to-peer, oznacza, to że każdy ma połączenie z każdym. W ramach komunikacji węzły wymieniają się informacjami o transakcjach oraz utworzonych blokach.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Portfel (wallet)</span></strong></p>
</td>
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2950004; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Oprogramowanie służące do przechowywania posiadanych aktyw&oacute;w (kryptowalut, token&oacute;w, asset&oacute;w). Użytkownik może stworzyć sw&oacute;j portfel wykorzystując do tego dedykowane oprogramowanie dostępne w internecie lub nawet urządzenia hardware&rsquo;owe. Po stworzeniu swojego portfela nadawane jest mu unikalne ID (klucz publiczny), będące odpowiednikiem numeru konta bankowego.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
