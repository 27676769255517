const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="2" style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Zadania szczeg&oacute;łowe członk&oacute;w zespołu realizującego projekt z obszaru marketingu cyfrowego</strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Stanowisko</strong></p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Zadania</strong></p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Senior HR Specialist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Spotkanie/spotkania - zebranie danych o kompetencjach członk&oacute;w zespołu klienta.</p>
                    <p>Opracowanie raportu z audytu kompetencji klienta.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Digital Strategist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Analiza wykorzystywanych założeń strategicznych: grupy docelowej, komunikat&oacute;w marki, identyfikacji marki.</p><br>
                    <p>Analiza jakości działań marketingowych: strony www, PR, medi&oacute;w społecznościowych, kampanii płatnych.</p><br>
                    <p>Analiza rezultat&oacute;w wcześniejszych działań marketingowych.</p><br>
                    <p>Opracowanie raportu z audytu działań i zasob&oacute;w marketingowych.</p><br>
                    <p>Zdefiniowanie i uszczeg&oacute;łowienie grupy docelowej, jej segmentacja i ustalenie priorytet&oacute;w dla poszczeg&oacute;lnych segment&oacute;w.</p><br>
                    <p>Wyznaczenie cel&oacute;w działań dla poszczeg&oacute;lnych etap&oacute;w egzekucji oraz zdefiniowanie KPI [Key Performance Indicators - Kluczowych Wskaźnik&oacute;w Efektywności, czyli element&oacute;w mierzonych w celu p&oacute;źniejszej oceny postęp&oacute;w pracy oraz realizacji cel&oacute;w].</p><br>
                    <p>Sformułowanie gł&oacute;wnego przekazu marki - specjalizacji produktu i gł&oacute;wnych cech wyr&oacute;żniających [inaczej USP czyli Unique Selling Proposition].</p><br>
                    <p>Sformułowanie gł&oacute;wnych wartości marki [inaczej brand equity], języka marki i jej archetypu.</p><br>
                    <p>3 x Wyegzekwowanie raportu okresowego od Social Media Managera (odpowiedzialnego za zebranie informacji cząstkowych).</p><br>
                    <p>3 x Skonsultowanie raportu z zespołem i klientem - zgromadzenie danych o niezbędnych zmianach w działaniu.</p><br>
                    <p>3 x Debriefing wykonawc&oacute;w i wyegzekwowanie raportu końcowego od Social Media Managera (odpowiedzialnego za zebranie informacji cząstkowych).</p><br>
                    <p>Uzupełnienie otrzymanych danych o własną interpretację analityczną, zebranie wniosk&oacute;w i rekomendacji.</p><br>
                    <p>Skonsultowanie raportu z zespołem i klientem - zgromadzenie i prezentacja danych o niezbędnych zmianach w działaniu.</p><br>
                    <p>Zamknięcie i rozliczenie wykonawc&oacute;w</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Senior Digital Marketing Specialist</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Dob&oacute;r kanał&oacute;w komunikacji.</p><br>
                    <p>Zdefiniowanie gł&oacute;wnych lejk&oacute;w sprzedażowych.</p><br>
                    <p>Budżetowanie wydatk&oacute;w.</p><br>
                    <p>Opracowanie ramowego kalendarza działań i realizowanych cel&oacute;w.</p><br>
                    <p>Opracowanie zapotrzebowania na wykonawc&oacute;w i kompetencje zewnętrzne.</p>
                    <p>Opracowanie rekomendacji narzędzi.</p><br>
                    <p>Rekrutacja wykonawc&oacute;w usług zewnętrznych.</p><br>
                    <p>Zakup i konfiguracja narzędzi wspierających działania marketingu cyfrowego.</p><br>
                    <p>Modyfikacje na stronie www (system stron lądowania, poprawa procesu zakupowego).</p><br>
                    <p>Uzupełnienie i konfiguracja profili społecznościowych.</p><br>
                    <p>Konfiguracja paneli biznesowych i reklamowych (Social Ads).</p><br>
                    <p>Podpięcie kod&oacute;w śledzących i konfiguracja Google Analytics.</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Project Manager</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zlecenie stworzenia content plan&oacute;w [szczeg&oacute;łowy kalendarz publikacji treści we wszystkich kanałach komunikacji marki, np. na wszystkich profilach społecznościowych] (cyklicznie, raz w miesiącu).&nbsp;</p><br>
                    <p>Uwagi do content planu i akceptacja.</p><br>
                    <p>Koordynowanie produkcji treści tekstowych - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem.</p><br>
                    <p>Koordynowanie produkcji treści graficznych/zdjęciowych - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem.</p><br>
                    <p>Koordynowanie produkcji treści audio/video - dopilnowanie termin&oacute;w i zgodności z zatwierdzonym wcześniej content planem.</p><br>
                    <p>Przekazanie niezbędnych uprawnień i kontrola polityki bezpieczeństwa danych (jednorazowo).</p><br>
                    <p>Kontakt z wykonawcami - odpowiedzi na pytania, rozwiązywanie konflikt&oacute;w, dostarczanie niezbędnych materiał&oacute;w (w miarę potrzeb).</p><br>
                    <p>Rozliczenie faktur, dopilnowanie płatności (raz w miesiącu).</p><br>
                    <p>Kontrola zgodności publikacji z założonym planem - raz/dwa razy w tygodniu. Zgłoszenie uwag.</p><br>
                    <p>Przekazanie niezbędnych uprawnień i kontrola polityki bezpieczeństwa danych (jednorazowo).</p><br>
                    <p>Kontakt z wykonawcami - odpowiedzi na pytania, rozwiązywanie konflikt&oacute;w, dostarczanie niezbędnych materiał&oacute;w (w miarę potrzeb).</p><br>
                    <p>Rozliczenie faktur za kampanie płatne, dopilnowanie płatności (raz w miesiącu).&nbsp;</p><br>
                    <p>Kontrola zgodności ustawień kampanii płatnych z założonym planem - raz/dwa razy w tygodniu.&nbsp;</p><br>
                    <p>Zgłoszenie uwag.</p><br>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
