const html = `<table border="1" cellpadding="5">
<tbody>
<tr>
<td colspan="2">
<h3 style="text-align: center;"><strong>Skład zespołu realizującego projekt z obszaru marketingu cyfrowego</strong></h3>
</td>
</tr>
<tr>
<td>
<p><strong>Stanowisko&nbsp;</strong></p>
</td>
<td>
<p><strong>Zakres obowiązk&oacute;w&nbsp;</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Lead Designer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Koncepcja kreatywna, elementy graficzne do publikacji w social mediach, storyboardy do video, prototypowanie i projekt graficzny landing page sprzedażowego.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Social Media Manager</span><span style="font-weight: 400;"><br /></span></p>
</td>
<td>
<p><span style="font-weight: 400;">Opracowanie content plan&oacute;w na podstawie strategii, prowadzenie komunikacji, moderacja komentarzy fan&oacute;w, konfiguracja kampanii płatnych, raportowanie.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Frontend Developer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Wdrożenie, testowanie i instalacja na serwerze produkcyjnym landing page sprzedażowego.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Digital strategist /Strateg medi&oacute;w cyfrowych</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Stworzenie strategii marketingowej i gł&oacute;wnych założeń komunikacji oraz taktyki działań marketingowych&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
