import axios, { AxiosRequestConfig } from "axios";
import i18n from "../i18n/i18n";
import { clearGameReducer } from "../reducers/game.reducer";
import { clearAuth } from "../reducers/user.reducer";
import { store } from "../store/store";
import { getFormData } from "../utils/dataTransform.utils";
import { errorHandler } from "../utils/error.utils";
import { doesTokenExists, isTokenExpired } from "../utils/token.utils";
import { refreshTokenRequest } from "./user.service";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const headersWithoutAuth = {
  Accept: "application/json",
  "Content-Type": "application/json",
  client_id: process.env.REACT_APP_CLIENT_ID!,
  client_secret: process.env.REACT_APP_CLIENT_SECRET!,
};

const headersWithoutAuthFormData = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
  timeout: 10000,
});

const axiosServiceWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: headersWithoutAuth,
  timeout: 10000,
});

const axiosServiceWithoutAuthFormData = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: headersWithoutAuthFormData,
  timeout: 10000,
});

//TODO
const requestConfig = (config: AxiosRequestConfig) => {
  const userReducer = store.getState().userReducer;
  const token = userReducer.token;
  const refreshToken = userReducer.refreshToken;
  return doesTokenExists(token)
    .then(async (token) => {
      if (isTokenExpired(token)) {
        return doesTokenExists(refreshToken).then((refreshToken) => {
          if (isTokenExpired(refreshToken)) {
            store.dispatch(clearAuth());
            store.dispatch(clearGameReducer());
            window.location.reload();
            return Promise.reject(
              new Error(i18n.t("error.tokenException").toString())
            );
          }
          return Promise.resolve(
            refreshTokenRequest({
              grant_type: "refresh_token",
              refresh_token: refreshToken,
            })
          );
        });
      }
      return Promise.resolve(token);
    })
    .then((token) => {
      config!.headers!.Authorization = `Bearer ${token}`;
      return Promise.resolve(config);
    })
    .catch((error) => {
      console.log(error);
    });
};

// const requestConfigWithoutAuth = (config: AxiosRequestConfig) => {
//   config = {
//     ...config,
//     transformRequest: [
//       (data) => {
//         data = {
//           ...data,
//           client_id: process.env.REACT_APP_CLIENT_ID!,
//           client_secret: process.env.REACT_APP_CLIENT_SECRET!,
//         };
//         console.log(data);
//         return JSON.stringify(data);
//       },
//     ],
//   };
//   return config;
// };

const requestConfigWithoutAuthFormData = (config: AxiosRequestConfig) => {
  config = {
    ...config,
    transformRequest: [
      (data) => {
        data = getFormData({
          ...data,
          client_id: process.env.REACT_APP_CLIENT_ID!,
          client_secret: process.env.REACT_APP_CLIENT_SECRET!,
        });
        return data;
      },
    ],
  };
  return config;
};

axiosService.interceptors.request.use(
  (config) => requestConfig(config) as AxiosRequestConfig,
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axiosServiceWithoutAuth.interceptors.request.use(
  (config) => config,
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosServiceWithoutAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axiosServiceWithoutAuthFormData.interceptors.request.use(
  (config) => requestConfigWithoutAuthFormData(config),
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosServiceWithoutAuthFormData.interceptors.response.use(
  (response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export {
  axiosService,
  axiosServiceWithoutAuth,
  axiosServiceWithoutAuthFormData,
};
