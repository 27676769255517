const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Informacje, kt&oacute;re należy przekazać pracownikom klienta po zrealizowaniu projektu z obszaru marketingu cyfrowego</strong></p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Kiedy zaczynaliśmy, strona www nie była wystarczająco dobrze zoptymalizowanym narzędziem sprzedażowym. Dopracowaliśmy wszystkie szczeg&oacute;ły, więc dziś jest elementem, kt&oacute;ry sprzedaje wymagając przy tym minimalnej obsługi. Profile w mediach społecznościowych mają już wypracowane dobre praktyki, kt&oacute;re wystarczy kontynuować, by dalej rozwijać społeczność fan&oacute;w, obserwujących i klient&oacute;w. Know-how opisane w raportach końcowych jest wiedzą, opartą na danych i licznych przeprowadzonych testach i eksperymentach. Mamy nadzieję, że będzie to fundamentem do dalszego, dynamicznego rozwoju Waszej marki.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zesp&oacute;ł marketingowy powinien świadomie korzystać z danych analitycznych dostarczanych przez statystyki odwiedzalności i sprzedaży na stronie www</p><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Po kilku miesiącach aktywnego tworzenia strategii, mamy już mn&oacute;stwo danych. Dalszy monitoring może być prowadzony w spos&oacute;b ciągły lub okresowy - raporty mogą być tworzone np. raz na kwartał, by zaobserwować zmiany w tym jak w internecie postrzegany jest nasz produkt, produkty konkurencji oraz jakie są trendy w naszej branży.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Wszystkie kanały społecznościowe są profesjonalnie skonfigurowane i mają pełne profile biznesowe. Klient powinien zadbać, by ten porządek utrzymać - nie tworzyć nowych, niepotrzebnych kont, kontrolować uprawnienia, publikować treści przez dedykowane do tego narzędzia.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
