const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Tematy kt&oacute;re należy poruszyć na spotkaniu szkoleniowym po wdrożeniu technologii IoT</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Ważne jest byście poinformowali pracownik&oacute;w o nowym narzędziu i zachęcili do korzystania. Proces adopcji rozwiązania przez pracownik&oacute;w może być trudny, jeśli kultura organizacji nie promuje wdrażania innowacji. Jesteście Power Userami (przedstawiciele firmy, kt&oacute;rzy dalej szkolą innych - model szkoleniowy typu train the trainers) i od Was zależy sukces wdrożenia.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Om&oacute;wię teraz zasady działania platformy, w tym dostępne warstwy danych oraz dostępne funkcjonalności i narzędzia do analizy danych. Powiem też o zasadach podłączania nowych źr&oacute;deł danych do platformy i zarządzaniu uprawnieniami.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Om&oacute;wię teraz najczęstsze błędy i problemy, kt&oacute;re mogą pojawić się w trakcie korzystania z platformy (tzw. troubleshooting) oraz zasady optymalizacji koszt&oacute;w działania platformy.</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Stworzyliśmy dla Państwa bazę wiedzy, w kt&oacute;rej znajdują się wszystkie materiały wykorzystane na szkoleniach. Link do bazy prześlę Państwu po szkoleniu. W razie problem&oacute;w z korzystaniem z narzędzia proszę o kontakt z firmowym działem IT.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
