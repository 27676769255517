const html = `<div align="left">
    <table style="border-collapse: collapse; border: 2px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p><strong>Zadanie/etap prac (w odpowiedniej kolejności)</strong></p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Czas rozpoczęcia realizacji zadania (tydz)</strong></p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Czas trwania zadania (tyg)</strong></p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p><strong>Osoby odpowiedzialne/realizujące (zesp&oacute;ł)</strong></p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p><strong>Efekt realizacji zadania</strong></p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p>Analiza biznesowa&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>1</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>1-3 (w zależności od ilości raport&oacute;w i system&oacute;w źr&oacute;dłowych)</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p>Architekt BI/BD</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Dokument zawierający opis struktury modelu analitycznego, w tym definicję miar (wskaźnik&oacute;w do raport&oacute;w) w raportach.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p>Pobranie danych ze źr&oacute;deł do warstwy STG&nbsp;</p><br>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>ok.3</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>4-6 (w zależności od liczby źr&oacute;deł )</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p>Programista ETL</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Poprawny, powtarzany codziennie proces ładowania właściwych danych ze źr&oacute;deł do warstwy STG&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p>Budowa modelu hurtowni danych/warstwy data warehouse</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>ok. 10</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>4 (w zależności od jakości danych źr&oacute;dłowych )</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p>Programista ETL</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Codzienny, zgodny z wytycznymi, &nbsp;proces przeliczania danych przez warstwę DWH. Możliwość testowania dostępu do danych przez klienta. &nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p>Budowa modelu analitycznego (semantycznego)</p><br>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>ok. 10</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>4-6 (w zależności od liczby źr&oacute;deł )</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p>Programista ETL, Programista BI&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Odświeżany codziennie model analityczny daje możliwość testowania poprawności danych generowanych przez model (w formie tabeli przestawnej, lub w aplikacji do tworzenia wizualizacji).&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p>Budowa warstwy wizualnej raport&oacute;w w gotowej do wdrożenia aplikacji BI&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>ok.13</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>3-5(w zależności od ilości raport&oacute;w)</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p>Programista BI</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Dostęp do atrakcyjnych wizualnie raport&oacute;w zawierających oczekiwane dane. Możliwość testowania finalnego produktu. Podpisanie i przekazanie dokumentacji.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 2px solid rgb(0, 0, 0); width: 20.122%;">
                    <p>Przekazanie produktu klientowi do testowania, naprawa błęd&oacute;w, wdrożenie produkcyjne</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>ok.17</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>3-5(w zależności od liczby raport&oacute;w i dostępności klienta)</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0); width: 24.1463%;">
                    <p>Programista ETL, Programista BI, Architekt BI/BD&nbsp;</p>
                </td>
                <td style="border: 2px solid rgb(0, 0, 0);">
                    <p>Raport z test&oacute;w i protok&oacute;ł odbioru prac.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
