const html = `<table style="border-collapse: collapse; width: 100%; height: 72px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 49.6018%; height: 18px;"><strong>Rodzaj danych cyfrowych</strong></td>
<td style="width: 49.6018%; height: 18px;"><strong>Przykłady zbior&oacute;w danych</strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6018%; height: 18px;">Dane ustrukturyzowane (dane o stałej strukturze)</td>
<td style="width: 49.6018%; height: 18px;">
<p>&bull; relacyjne bazy danych</p>
<p>&bull; pliki csv o stałej strukturze</p>
<p>&bull; pliki Excel</p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6018%; height: 18px;">Dane częściowo ustrukturyzowane (dane zawierające pewną strukturę, kt&oacute;ra jednak nie jest jasno określona i może się zmieniać)</td>
<td style="width: 49.6018%; height: 18px;">
<p>&bull; bazy danych NoSQL</p>
<p>&bull; dane z API (np. w postaci JSON lub XML)</p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6018%; height: 18px;">Dane nieustrukturyzowane (muszą być poddane obr&oacute;bce by wyciągnąć z nich informacje)</td>
<td style="width: 49.6018%; height: 18px;">
<p>&bull; e-maile</p>
<p>&bull; nagrania audio &ndash; video</p>
<p>&bull; pliki tekstowe</p>
<p>&bull; strony internetowe</p>
</td>
</tr>
</tbody>
</table>`;

export default html;
