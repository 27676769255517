const html = `<p>Kick-off meeting stanowi spotkanie będące oficjalnym startem projektu. Jest to pierwsze spotkanie całego zespołu projektowego i zespołu po stronie klienta. Kick off meeting organizowany jest przez PM projektu, po zawarciu umowy. Kwestie, kt&oacute;re najczęściej poruszane są podczas spotkania to m.in.:</p>
<ul>
    <li>
        <p>zapoznanie członk&oacute;w zespołu</p>
    </li>
    <li>
        <p>om&oacute;wienie gł&oacute;wnych cel&oacute;w projektu</p>
    </li>
    <li>
        <p>om&oacute;wienie harmonogramu projektu</p>
    </li>
    <li>
        <p>podział pracy i określenie zakres&oacute;w odpowiedzialności w projekcie (macierz RACI)</p>
    </li>
    <li>
        <p>harmonogram i zasady spotkań projektowych&nbsp;</p>
    </li>
    <li>
        <p>narzędzie do komunikacji w zespole i zarządzania zadaniami w projekcie np. teams, jira&nbsp;</p>
    </li>
    <li>
        <p>zasady udzielenia dostęp&oacute;w członk&oacute;w zespołu wykonawcy do system&oacute;w, infrastruktury IT klienta (np. baza danych, zasoby chmurowe)</p>
    </li>
    <li>
        <p>polityka cyberbezpieczeństwa i ochrony danych osobowych stosowane przez klienta&nbsp;</p>
    </li>
</ul>`;

export default html;
