import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import GameType from "../../../../interfaces/game/GameType";
import { AppState } from "../../../../store/store";
import CategoryMockType from "../../../../interfaces/game/CategoryMockType";
import ArrowDownIcon from "../../../../assets/images/icons/arrow_down_icon.svg";
import GameMockType from "../../../../interfaces/game/GameMockType";
import CheckIcon from "../../../../assets/images/icons/check_primary_icon.svg";
import CategoryType from "../../../../interfaces/game/CategoryType";
import ClockIcon from "../../../../assets/images/icons/clock_icon.svg";

interface Props {
  gameKey: string;
  onGameChange: (gameKey: string) => void;
  subtaskKey: string;
  onSubtaskChange: (subtaskKey: string) => void;
  categoryKey: string;
  industry: string;
}

const EducationalGoalsSection = (props: Props) => {
  const { t } = useTranslation();
  const {
    gameKey,
    onGameChange,
    subtaskKey,
    onSubtaskChange,
    categoryKey,
    industry,
  } = props;
  const [gameData, setGameData] = useState<GameMockType | undefined>(undefined);
  const [availableGames, setAvailableGames] = useState<GameType[]>([]);

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const categories: CategoryType[] = useSelector(
    (state: AppState) => state.gameReducer.categories
  );

  useEffect(() => {
    if (categories) {
      let availableCategories = [];
      if (categoryKey)
        availableCategories = [
          categories.find((item) => item.key === categoryKey)!,
        ];
      else if (!industry) availableCategories = categories;
      else {
        const availableCategoriesData = gamesData.filter((category) =>
          category.sectorKeys.includes(industry)
        );
        availableCategories = categories.filter((category) =>
          availableCategoriesData.some((item) => item.key === category.key)
        );
      }

      let availableGames = availableCategories.map((item) => item.games).flat();

      if (industry)
        availableGames = availableGames.filter(
          (item) => item.sectorKey === industry && item.userCreatorId === null
        );
      else
        availableGames = availableGames.filter(
          (item) => item.userCreatorId === null
        );
      setAvailableGames(availableGames);
      if (availableGames.length > 0) onGameChange(availableGames[0].key);
    }
  }, [categoryKey, industry, categories, gamesData, onGameChange]);

  useEffect(() => {
    if (gameKey && availableGames && games) {
      const game = availableGames.find((item) => item.key === gameKey)!;
      if (game) {
        const category = gamesData.find(
          (category) => category.key === game.categoryKey
        )!;
        const gameData = category.games.find((item) => item.key === game.key)!;
        setGameData(gameData);
      }
    }
  }, [gameKey, games, gamesData, availableGames]);

  const renderGame = (game: GameType) => {
    const category = gamesData.find(
      (category) => category.key === game.categoryKey
    )!;
    const gameData = category.games.find((item) => item.key === game.key)!;
    return (
      gameData && (
        <MenuItem value={game.key}>{gameData.educationalGoal}</MenuItem>
      )
    );
  };

  const renderSubtasks = () => {
    return gameData!.subtasks.map((subtask) => {
      const selected = subtaskKey === subtask.key;
      return (
        <div
          className={`subtask ${selected ? "selected" : ""}`}
          key={subtask.key}
          onClick={() => onSubtaskChange(subtask.key)}
        >
          <div className="subtask_title">
            {selected && (
              <img
                className="selectedIcon"
                src={CheckIcon}
                alt="selectedIcon"
              />
            )}
            {subtask.task}
          </div>
          {selected && (
            <>
              <div className="subtask_knowledge">
                <span className="subtask_section_title">
                  {"Wiedza [Zna...]:"}
                </span>
                <ul>
                  {subtask.knowledge.map((item) => (
                    <li>{item.label}</li>
                  ))}
                </ul>
              </div>
              <div className="subtask_skills">
                <span className="subtask_section_title">
                  {"Umiejętności [Potrafi opracować/wskazać...]:"}
                </span>
                <ul>
                  {subtask.skills.map((item) => (
                    <li>{item.label}</li>
                  ))}
                </ul>
              </div>
              <div className="time">
                <img className="clockIcon" src={ClockIcon} alt="clock_icon" />
                <span>{"Czas realizacji celu:"}</span>
                <span className="">{"30min"}</span>
              </div>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <div className="educationalGoalsSection">
      <div className="sectionsTitle">
        {
          "Wybierz główną kompetencję cyfrową, która ma zostać rozwinięta lub zweryfikowana w efekcie rozegrania konfigurowanej gry:"
        }
      </div>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={gameKey}
        // IconComponent={<img src={ArrowDownIcon} alt="" />}
        onChange={(event) => {
          onGameChange(event.target.value);
          onSubtaskChange("");
        }}
      >
        {availableGames.map((game) => renderGame(game))}
      </Select>
      <div className="sectionsTitle">
        {
          "Wybierz jedną, szczegółową kompetencję cyfrową, która ma zostać rozwinięta lub zweryfikowana w efekcie rozegrania konfigurowanej gry:"
        }
      </div>
      {gameData && renderSubtasks()}
    </div>
  );
};

export default EducationalGoalsSection;
