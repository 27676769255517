const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td colspan="2" style="border: 1px solid rgb(0, 0, 0);">
                    <p>Korzyści biznesowe związane z realizację usługi z obszaru marketingu cyfrowego</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Obszar działalności klienta</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Wpływ realizacji usługi na dany obszar</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Przychody/zyski</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Uzyskano 1500 płatnych subskrypcji rocznych i ok 10% stały miesięczny organiczny wzrost subskrybent&oacute;w.<br>Zapewnia to płynność finansową przedsięwzięciu i stałe przychody na poziomie 60 000-90 000 zł miesięcznie.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Koszty</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zmiana struktury budżetu marketingowego.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Pozyskanie nowych klient&oacute;w</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Pozyskano 10 000 nowych podmiot&oacute;w gospodarczych korzystających z aplikacji (większość z wersji bezpłatnej). Baza danych użytkownik&oacute;w jest wykorzystywana do wsparcia sprzedaży innych produkt&oacute;w technologicznych klienta.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Strategia rozwoju</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zesp&oacute;ł rozwijał kompetencje, planuje powiększenie składu i zatrudnienie zespołu do obsługi social media in-house.<br>Firma, zachęcona sukcesem kampanii i wysokim zwrotem z inwestycji postanowiła przyspieszyć ekspansję na kolejne rynki zagraniczne.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Poziom zatrudnienia&nbsp;</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Rozbudowano dział marketingu o Social Media Managera.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Og&oacute;lna ocena wsp&oacute;łpracy z wykonawcą</p>
                </td>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Klient jest bardzo zadowolony ze wsp&oacute;łpracy z wykonawcą, ale sugeruje, że czułby się pewniej, gdyby całość prac egzekucyjnych prowadzona była w naszej firmie, a nie podzlecana zewnętrznym wykonawcom.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
