import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import StyledButton from "./StyledButton";
import { useFormContext } from "react-hook-form";

interface Props {
  onCancel: () => void;
  onSubmit: (data?: any) => void;
  submitLabel: string;
  header: string;
  children: ReactElement;
}

const CustomModal = (props: Props) => {
  const { t } = useTranslation();
  const { onCancel, onSubmit, submitLabel, header, children } = props;

  const defaultModalOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const defaultModalOnSubmit = (data: any) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <form onSubmit={defaultModalOnSubmit}>
      <div className="modalBackground" />
      <div className="customModal">
        <div className="headerWrapper">
          <span className="header">{header}</span>
        </div>
        <div className="mainContentWrapper">{children}</div>
        <div className="buttons">
          <StyledButton
            type="contained"
            color="white"
            onClick={defaultModalOnCancel}
            label={"Anuluj"}
          />
          <StyledButton
            type="contained"
            color="dark"
            buttonType="submit"
            label={submitLabel}
          />
        </div>
      </div>
    </form>
  );
};

export default CustomModal;
