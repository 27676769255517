const html = `<p style="text-align: justify;"><strong>Cel projektu:</strong>&nbsp;Stowrzenie rozwiązania, kt&oacute;re umożliwi zespołowi analityk&oacute;w i Data Scientis&oacute;w szybki i efektywny dostęp do wszystkich danych firmowych z jednego miejsca&nbsp;</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Czas trwania projektu:</strong>&nbsp;12 miesięcy&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<strong>Budżet projektu:</strong>&nbsp;1 mln &nbsp;zł</p>
<p style="text-align: justify;"><strong>Zesp&oacute;ł projektowy:</strong>&nbsp;Architekt BI/BD (1FTE), Programista ETL (2 FTE), Programista Python (3FTE), Data Scientist (1FTE)</p>
<p style="text-align: justify;"><strong>Gł&oacute;wny problem klienta</strong></p>
<p style="text-align: justify;">Trudny dostęp do danych w hurtowni. Brak warstwy umożliwiającej swobodną i bezpieczną mainpulację i transformację danych przez Data Scientist&oacute;w. Czasochłonne procesy wnisokowania o dane i pobierania danych za pośrednictwem deweloper&oacute;w.&nbsp;</p>
<p style="text-align: justify;"><strong>Kr&oacute;tki opis projektu</strong></p>
<p style="text-align: justify;">Projekt objął wdrożenie platformy Big Data, umożliwiającej organizacji nie tylko składowanie ale też przetwarzanie i analizowanie dużej ilości danych, kt&oacute;re są zazwyczaj zbyt wielkie, złożone lub zr&oacute;żnicowane, aby można było je przetworzyć tradycyjnymi metodami. Platforma Big Data korzystała z rozproszonego przetwarzania danych z wykorzystaniem &nbsp;Hadoop, co umożliwiło wykorzystanie wielu węzł&oacute;w serwerowych do przetwarzania danych r&oacute;wnolegle. Wdrożono także narzędzia analityczne, takie jak narzędzie do wizualizacji danych i uczenia maszynowego, kt&oacute;re pozwalają na zrozumienie i wykorzystanie danych w bardziej efektywny spos&oacute;b.&nbsp;</p>
<p style="text-align: justify;"><strong>Korzyści klienta, osiągnięte w efekcie wdrożenia</strong></p>
<p style="text-align: justify;">Zwiększono efektywność proces&oacute;w analityczny i raportowych. Zoptymalizowano procesy w firmie &ndash; w oparciu o wnioski z analizy Big Data, w tym modele ML.&nbsp;</p>
<p style="text-align: justify;"><strong>Narzędzia/systemy/technologie wykorzystane w projekcie</strong></p>
<p style="text-align: justify;">Hadoop, aplikacja BI, BigQuery</p>`;

export default html;
