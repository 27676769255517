const html = `<table style="border-collapse: collapse; width: 100%;" border="1">
<tbody>
<tr>
<td style="width: 49.6018%;"><strong>Obszar działalności klienta</strong></td>
<td style="width: 49.6018%;"><strong>Wpływ wdrożenia rozwiązania na dany obszar</strong></td>
</tr>
<tr>
<td style="width: 49.6018%;">Zatrudnienie</td>
<td style="width: 49.6018%;">Brak wpływu</td>
</tr>
<tr>
<td style="width: 49.6018%;">Przychody/zyski</td>
<td style="width: 49.6018%;">Podniesienie jakości produkcji w wyniku dostępu do danych o fabrykach generujących najwięcej uszkodzeń, pozwoliło na wzrost przychod&oacute;w w efekcie usprawnienia procesu produkcji</td>
</tr>
<tr>
<td style="width: 49.6018%;">Zarządzanie</td>
<td style="width: 49.6018%;">Lepszy wgląd w działalność sp&oacute;łki, co stwarza możliwości optymalizacji koszt&oacute;w i przychod&oacute;w</td>
</tr>
<tr>
<td style="width: 49.6018%;">Koszty/struktura koszt&oacute;w</td>
<td style="width: 49.6018%;">Zmniejszenie koszt&oacute;w produkcji w efekcie zmniejszenia liczby uszkodzonych produkt&oacute;w</td>
</tr>
<tr>
<td style="width: 49.6018%;">Zadowolenie pracownik&oacute;w</td>
<td style="width: 49.6018%;">Osoby tworząc raporty oszczędzają czas, dzięki temu mogą wykonywać zadania analityczne nie techniczne, co zwiększa ich satysfakcję z pracy. Szczeg&oacute;lnie zadowolone są też osoby odpowiedzialne za przesyłanie raport&oacute;w, gdyż obecnie nie muszą tego robić. Kadra zarządzająca ceni sobie bezpośredni dostęp do narzędzia w każdym miejscu i czasie</td>
</tr>
<tr>
<td style="width: 49.6018%;">Og&oacute;lna ocena wsp&oacute;łpracy z wykonawcą/obszary do poprawy</td>
<td style="width: 49.6018%;">Wsp&oacute;łpraca oceniana jest dobrze. Klient, nie sądził jednak, że realizacja projektu pochłonie tak wiele godzin pracy wewnętrznego zespołu</td>
</tr>
</tbody>
</table>`;

export default html;
