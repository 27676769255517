const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Baza danych to zorganizowany zbi&oacute;r informacji lub danych, kt&oacute;re są przechowywane w spos&oacute;b umożliwiający łatwy dostęp, manipulowanie i analizowanie tych danych. Bazy danych są używane do przechowywania i organizowania r&oacute;żnych typ&oacute;w danych, takich jak tekst, liczby, obrazy i dźwięk, w spos&oacute;b uporządkowany i systematyczny.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;">Bazy danych służą do przechowywania dużych ilości danych, kt&oacute;re mogą być łatwo wyszukiwane, sortowane i filtrowane. Mogą one r&oacute;wnież zawierać mechanizmy do ochrony danych przed nieautoryzowanym dostępem i zapewnienia integralności danych, czyli ich poprawności i sp&oacute;jności.&nbsp;</p>
<p style="text-align: justify;"><br></p>
<h5 style="text-align: justify;">Ze względu na zastosowany model języka i spos&oacute;b przetwarzania danych wyr&oacute;żnić możemy:</h5>
<ul>
    <li>
        <p style="text-align: justify;">Relacyjne bazy danych</p>
    </li>
    <li>
        <p style="text-align: justify;">Obiektowe bazy danych</p>
    </li>
    <li>
        <p style="text-align: justify;">Obiektowo-relacyjne bazy danych</p>
    </li>
</ul>
<p style="text-align: justify;">Bazy danych NoSQL są zamiennie nazywane &bdquo;nierelacyjnymi&rdquo; lub &bdquo;nie SQL&rdquo;, aby podkreślić fakt, że potrafią obsłużyć ogromne ilości, nieustrukturyzowanych danych innymi sposobami niż relacyjna (SQL) baza danych.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Obsługa SQL Server Management Studio, znajomość języka T-SQL/ SQL.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Przykłady popularnych system&oacute;w zarządzania bazami danych (DBMS):</strong>&nbsp;Oracle, MySQL, Microsoft SQL Server, PostgreSQL, MongoDB.</p>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Bazy danych są powszechnie używane w r&oacute;żnych dziedzinach, takich jak biznes, nauka, medycyna i edukacja.</p>
<p style="text-align: justify;">&nbsp;</p>`;

export default html;
