const html = `<div align="left">
    <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0);">
        <tbody>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p><strong>Informacje, kt&oacute;re należy przekazać i uzyskać podczas spotkania rozpoczynającego realizację projektu z obszaru marketingu cyfrowego</strong></p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Miło nam Państwa poznać. Oto nasz zesp&oacute;ł. Wszyscy będziemy się z Państwem spotkać, ale na r&oacute;żnych etapach projektu. Chcemy też poznać osoby z Państwa firmy, z kt&oacute;rymi będziemy pracować. Opowiedzcie nam proszę o Waszym produkcie i potrzebach i wyzwaniach marketingowych.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Podsumujmy cele projektu, kt&oacute;re na etapie analizy przed ofertowej wsp&oacute;lnie ustaliliśmy. Sprawdźmy, czy wszyscy rozumiemy je tak samo i czy nic się w między czasie nie zmieniło.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Nasz projekt będzie się składać z kilku etap&oacute;w, kt&oacute;re planujemy zrealizować według następującego harmonogramu. Jeśli chodzi o podwykonawc&oacute;w, zostali wstępnie wybrani.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Chciałbym pokazać Państwu macierz RACI, tak by każdy wiedział czego się od niego oczekuje i za co jest odpowiedzialny.&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Potrzebujemy następujących rzeczy:</p>
                    <ol>
                        <li>
                            <p>Brandbook [dokument opisujący identyfikację wizualną marki, tj. logo i sposoby jego zastosowania, graficzne elementy charakterystyczne pojawiające się w komunikacji &ndash; np. czcionki, kolorystyka, elementy graficzne]&nbsp;</p>
                        </li>
                        <li>
                            <p>Dostępy do medi&oacute;w społecznościowych i strony www i ewentualnie do serwera&nbsp;</p>
                        </li>
                        <li>
                            <p>Dane kontaktowe do wszystkich członk&oacute;w zespołu</p>
                        </li>
                        <li>
                            <p>Dostępność czasowa os&oacute;b, kt&oacute;rych kompetencje mają być audytowane&nbsp;</p>
                        </li>
                    </ol><br>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Bardzo ważne jest, byśmy na każdym spotkaniu ustalali i zapisywać listę rzeczy kt&oacute;rych Państwo potrzebują od nas. Czego potrzebują Państwo z naszej strony na tym etapie projektu?&nbsp;</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Zakładam grupę na komunikatorze firmowym, to będzie narzędzie do bieżącej komunikacji. W narzędziu do zarządzania projektami dodałem nasz projekt, gdzie będą dodawane taski do wykonania. Zapewni to możliwość śledzenia postęp&oacute;w prac.</p>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid rgb(0, 0, 0);">
                    <p>Musimy zdawać sobie sprawę z tego, że projekt będzie dzielił się na kilka dłuższych etap&oacute;w. Każdy z nich zakończy się konkretnymi produktami. Od sprawności akceptacji przez Państwa produkt&oacute;w, zależy utrzymanie zakładanego harmonogramu etap&oacute;w projektu.&nbsp;</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>`;

export default html;
