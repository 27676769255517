import reports from "./reports_import";

const reportMap: {
  [key: string]: { [key: string]: { key: string; report: string }[] };
} = {
  ai_ml_1: {
    ai_ml_1_subtask_1: [
      {
        key: "ai_ml_1_subtask_1_report_knowledge_1",
        report: reports.ai_ml_1_report_01,
      },
      {
        key: "ai_ml_1_subtask_1_report_skill_1",
        report: reports.ai_ml_1_report_02,
      },
      {
        key: "ai_ml_1_subtask_1_report_skill_2",
        report: reports.ai_ml_1_report_03,
      },
    ],
    ai_ml_1_subtask_2: [
      {
        key: "ai_ml_1_subtask_2_report_knowledge_1",
        report: reports.ai_ml_1_report_04,
      },
      {
        key: "ai_ml_1_subtask_2_report_knowledge_2",
        report: reports.ai_ml_1_report_05,
      },
      {
        key: "ai_ml_1_subtask_2_report_skill_1",
        report: reports.ai_ml_1_report_06,
      },
      {
        key: "ai_ml_1_subtask_2_report_knowledge_3",
        report: reports.ai_ml_1_report_07,
      },
      {
        key: "ai_ml_1_subtask_2_report_skill_2",
        report: reports.ai_ml_1_report_08,
      },
      {
        key: "ai_ml_1_subtask_2_report_skill_3",
        report: reports.ai_ml_1_report_09,
      },
      {
        key: "ai_ml_1_subtask_2_report_skill_4",
        report: reports.ai_ml_1_report_10,
      },
    ],
    ai_ml_1_subtask_3: [
      {
        key: "ai_ml_1_subtask_3_report_knowledge_1",
        report: reports.ai_ml_1_report_11,
      },
      {
        key: "ai_ml_1_subtask_3_report_skill_1",
        report: reports.ai_ml_1_report_12,
      },
      {
        key: "ai_ml_1_subtask_3_report_skill_2",
        report: reports.ai_ml_1_report_13,
      },
    ],
    ai_ml_1_subtask_4: [
      {
        key: "ai_ml_1_subtask_4_report_knowledge_1",
        report: reports.ai_ml_1_report_14,
      },
      {
        key: "ai_ml_1_subtask_4_report_skill_1",
        report: reports.ai_ml_1_report_15,
      },
      {
        key: "ai_ml_1_subtask_4_report_skill_2",
        report: reports.ai_ml_1_report_16,
      },
    ],
    ai_ml_1_subtask_5: [
      {
        key: "ai_ml_1_subtask_5_report_knowledge_1",
        report: reports.ai_ml_1_report_17,
      },
      {
        key: "ai_ml_1_subtask_5_report_skill_1",
        report: reports.ai_ml_1_report_18,
      },
      {
        key: "ai_ml_1_subtask_5_report_skill_2",
        report: reports.ai_ml_1_report_19,
      },
      {
        key: "ai_ml_1_subtask_5_report_knowledge_2",
        report: reports.ai_ml_1_report_20,
      },
    ],
  },
  ai_ml_2: {
    ai_ml_2_subtask_1: [
      {
        key: "ai_ml_2_subtask_1_report_knowledge_1",
        report: reports.ai_ml_2_report_01,
      },
      {
        key: "ai_ml_2_subtask_1_report_skill_1",
        report: reports.ai_ml_2_report_02,
      },
      {
        key: "ai_ml_2_subtask_1_report_skill_2",
        report: reports.ai_ml_2_report_03,
      },
    ],
    ai_ml_2_subtask_2: [
      {
        key: "ai_ml_2_subtask_2_report_knowledge_1",
        report: reports.ai_ml_2_report_04,
      },
      {
        key: "ai_ml_2_subtask_2_report_knowledge_2",
        report: reports.ai_ml_2_report_05,
      },
      {
        key: "ai_ml_2_subtask_2_report_skill_1",
        report: reports.ai_ml_2_report_06,
      },
      {
        key: "ai_ml_2_subtask_2_report_knowledge_3",
        report: reports.ai_ml_2_report_07,
      },
      {
        key: "ai_ml_2_subtask_2_report_skill_2",
        report: reports.ai_ml_2_report_08,
      },
      {
        key: "ai_ml_2_subtask_2_report_skill_3",
        report: reports.ai_ml_2_report_09,
      },
      {
        key: "ai_ml_2_subtask_2_report_skill_4",
        report: reports.ai_ml_2_report_10,
      },
    ],
    ai_ml_2_subtask_3: [
      {
        key: "ai_ml_2_subtask_3_report_knowledge_1",
        report: reports.ai_ml_2_report_11,
      },
      {
        key: "ai_ml_2_subtask_3_report_skill_1",
        report: reports.ai_ml_2_report_12,
      },
      {
        key: "ai_ml_2_subtask_3_report_skill_2",
        report: reports.ai_ml_2_report_13,
      },
    ],
    ai_ml_2_subtask_4: [
      {
        key: "ai_ml_2_subtask_4_report_knowledge_1",
        report: reports.ai_ml_2_report_14,
      },
      {
        key: "ai_ml_2_subtask_4_report_skill_1",
        report: reports.ai_ml_2_report_15,
      },
      {
        key: "ai_ml_2_subtask_4_report_skill_2",
        report: reports.ai_ml_2_report_16,
      },
    ],
    ai_ml_2_subtask_5: [
      {
        key: "ai_ml_2_subtask_5_report_knowledge_1",
        report: reports.ai_ml_2_report_17,
      },
      {
        key: "ai_ml_2_subtask_5_report_skill_1",
        report: reports.ai_ml_2_report_18,
      },
      {
        key: "ai_ml_2_subtask_5_report_skill_2",
        report: reports.ai_ml_2_report_19,
      },
      {
        key: "ai_ml_2_subtask_5_report_knowledge_2",
        report: reports.ai_ml_2_report_20,
      },
    ],
  },
  bi_1: {
    bi_1_subtask_1: [
      {
        key: "bi_1_subtask_1_report_knowledge_1",
        report: reports.bi_1_report_01,
      },
      {
        key: "bi_1_subtask_1_report_skill_1",
        report: reports.bi_1_report_02,
      },
      {
        key: "bi_1_subtask_1_report_skill_2",
        report: reports.bi_1_report_03,
      },
    ],
    bi_1_subtask_2: [
      {
        key: "bi_1_subtask_2_report_knowledge_1",
        report: reports.bi_1_report_04,
      },
      {
        key: "bi_1_subtask_2_report_knowledge_2",
        report: reports.bi_1_report_05,
      },
      {
        key: "bi_1_subtask_2_report_skill_1",
        report: reports.bi_1_report_06,
      },
      {
        key: "bi_1_subtask_2_report_knowledge_3",
        report: reports.bi_1_report_07,
      },
      {
        key: "bi_1_subtask_2_report_skill_2",
        report: reports.bi_1_report_08,
      },
      {
        key: "bi_1_subtask_2_report_skill_3",
        report: reports.bi_1_report_09,
      },
      {
        key: "bi_1_subtask_2_report_skill_4",
        report: reports.bi_1_report_10,
      },
    ],
    bi_1_subtask_3: [
      {
        key: "bi_1_subtask_3_report_knowledge_1",
        report: reports.bi_1_report_11,
      },
      {
        key: "bi_1_subtask_3_report_skill_1",
        report: reports.bi_1_report_12,
      },
      {
        key: "bi_1_subtask_3_report_skill_2",
        report: reports.bi_1_report_13,
      },
    ],
    bi_1_subtask_4: [
      {
        key: "bi_1_subtask_4_report_knowledge_1",
        report: reports.bi_1_report_14,
      },
      {
        key: "bi_1_subtask_4_report_skill_1",
        report: reports.bi_1_report_15,
      },
      {
        key: "bi_1_subtask_4_report_skill_2",
        report: reports.bi_1_report_16,
      },
    ],
    bi_1_subtask_5: [
      {
        key: "bi_1_subtask_5_report_knowledge_1",
        report: reports.bi_1_report_17,
      },
      {
        key: "bi_1_subtask_5_report_skill_1",
        report: reports.bi_1_report_18,
      },
      {
        key: "bi_1_subtask_5_report_skill_2",
        report: reports.bi_1_report_19,
      },
      {
        key: "bi_1_subtask_5_report_knowledge_2",
        report: reports.bi_1_report_20,
      },
    ],
  },
  bi_2: {
    bi_2_subtask_1: [
      {
        key: "bi_2_subtask_1_report_knowledge_1",
        report: reports.bi_2_report_01,
      },
      {
        key: "bi_2_subtask_1_report_skill_1",
        report: reports.bi_2_report_02,
      },
      {
        key: "bi_2_subtask_1_report_skill_2",
        report: reports.bi_2_report_03,
      },
    ],
    bi_2_subtask_2: [
      {
        key: "bi_2_subtask_2_report_knowledge_1",
        report: reports.bi_2_report_04,
      },
      {
        key: "bi_2_subtask_2_report_knowledge_2",
        report: reports.bi_2_report_05,
      },
      {
        key: "bi_2_subtask_2_report_skill_1",
        report: reports.bi_2_report_06,
      },
      {
        key: "bi_2_subtask_2_report_knowledge_3",
        report: reports.bi_2_report_07,
      },
      {
        key: "bi_2_subtask_2_report_skill_2",
        report: reports.bi_2_report_08,
      },
      {
        key: "bi_2_subtask_2_report_skill_3",
        report: reports.bi_2_report_09,
      },
      {
        key: "bi_2_subtask_2_report_skill_4",
        report: reports.bi_2_report_10,
      },
    ],
    bi_2_subtask_3: [
      {
        key: "bi_2_subtask_3_report_knowledge_1",
        report: reports.bi_2_report_11,
      },
      {
        key: "bi_2_subtask_3_report_skill_1",
        report: reports.bi_2_report_12,
      },
      {
        key: "bi_2_subtask_3_report_skill_2",
        report: reports.bi_2_report_13,
      },
    ],
    bi_2_subtask_4: [
      {
        key: "bi_2_subtask_4_report_knowledge_1",
        report: reports.bi_2_report_14,
      },
      {
        key: "bi_2_subtask_4_report_skill_1",
        report: reports.bi_2_report_15,
      },
      {
        key: "bi_2_subtask_4_report_skill_2",
        report: reports.bi_2_report_16,
      },
    ],
    bi_2_subtask_5: [
      {
        key: "bi_2_subtask_5_report_knowledge_1",
        report: reports.bi_2_report_17,
      },
      {
        key: "bi_2_subtask_5_report_skill_1",
        report: reports.bi_2_report_18,
      },
      {
        key: "bi_2_subtask_5_report_skill_2",
        report: reports.bi_2_report_19,
      },
      {
        key: "bi_2_subtask_5_report_knowledge_2",
        report: reports.bi_2_report_20,
      },
    ],
  },
  blockchain_1: {
    blockchain_1_subtask_1: [
      {
        key: "blockchain_1_subtask_1_report_knowledge_1",
        report: reports.blockchain_1_report_01,
      },
      {
        key: "blockchain_1_subtask_1_report_skill_1",
        report: reports.blockchain_1_report_02,
      },
      {
        key: "blockchain_1_subtask_1_report_skill_2",
        report: reports.blockchain_1_report_03,
      },
    ],
    blockchain_1_subtask_2: [
      {
        key: "blockchain_1_subtask_2_report_knowledge_1",
        report: reports.blockchain_1_report_04,
      },
      {
        key: "blockchain_1_subtask_2_report_knowledge_2",
        report: reports.blockchain_1_report_05,
      },
      {
        key: "blockchain_1_subtask_2_report_skill_1",
        report: reports.blockchain_1_report_06,
      },
      {
        key: "blockchain_1_subtask_2_report_knowledge_3",
        report: reports.blockchain_1_report_07,
      },
      {
        key: "blockchain_1_subtask_2_report_skill_2",
        report: reports.blockchain_1_report_08,
      },
      {
        key: "blockchain_1_subtask_2_report_skill_3",
        report: reports.blockchain_1_report_09,
      },
      {
        key: "blockchain_1_subtask_2_report_skill_4",
        report: reports.blockchain_1_report_10,
      },
    ],
    blockchain_1_subtask_3: [
      {
        key: "blockchain_1_subtask_3_report_knowledge_1",
        report: reports.blockchain_1_report_11,
      },
      {
        key: "blockchain_1_subtask_3_report_skill_1",
        report: reports.blockchain_1_report_12,
      },
      {
        key: "blockchain_1_subtask_3_report_skill_2",
        report: reports.blockchain_1_report_13,
      },
    ],
    blockchain_1_subtask_4: [
      {
        key: "blockchain_1_subtask_4_report_knowledge_1",
        report: reports.blockchain_1_report_14,
      },
      {
        key: "blockchain_1_subtask_4_report_skill_1",
        report: reports.blockchain_1_report_15,
      },
      {
        key: "blockchain_1_subtask_4_report_skill_2",
        report: reports.blockchain_1_report_16,
      },
    ],
    blockchain_1_subtask_5: [
      {
        key: "blockchain_1_subtask_5_report_knowledge_1",
        report: reports.blockchain_1_report_17,
      },
      {
        key: "blockchain_1_subtask_5_report_skill_1",
        report: reports.blockchain_1_report_18,
      },
      {
        key: "blockchain_1_subtask_5_report_skill_2",
        report: reports.blockchain_1_report_19,
      },
      {
        key: "blockchain_1_subtask_5_report_knowledge_2",
        report: reports.blockchain_1_report_20,
      },
    ],
  },
  blockchain_2: {
    blockchain_2_subtask_1: [
      {
        key: "blockchain_2_subtask_1_report_knowledge_1",
        report: reports.blockchain_2_report_01,
      },
      {
        key: "blockchain_2_subtask_1_report_skill_1",
        report: reports.blockchain_2_report_02,
      },
      {
        key: "blockchain_2_subtask_1_report_skill_2",
        report: reports.blockchain_2_report_03,
      },
    ],
    blockchain_2_subtask_2: [
      {
        key: "blockchain_2_subtask_2_report_knowledge_1",
        report: reports.blockchain_2_report_04,
      },
      {
        key: "blockchain_2_subtask_2_report_knowledge_2",
        report: reports.blockchain_2_report_05,
      },
      {
        key: "blockchain_2_subtask_2_report_skill_1",
        report: reports.blockchain_2_report_06,
      },
      {
        key: "blockchain_2_subtask_2_report_knowledge_3",
        report: reports.blockchain_2_report_07,
      },
      {
        key: "blockchain_2_subtask_2_report_skill_2",
        report: reports.blockchain_2_report_08,
      },
      {
        key: "blockchain_2_subtask_2_report_skill_3",
        report: reports.blockchain_2_report_09,
      },
      {
        key: "blockchain_2_subtask_2_report_skill_4",
        report: reports.blockchain_2_report_10,
      },
    ],
    blockchain_2_subtask_3: [
      {
        key: "blockchain_2_subtask_3_report_knowledge_1",
        report: reports.blockchain_2_report_11,
      },
      {
        key: "blockchain_2_subtask_3_report_skill_1",
        report: reports.blockchain_2_report_12,
      },
      {
        key: "blockchain_2_subtask_3_report_skill_2",
        report: reports.blockchain_2_report_13,
      },
    ],
    blockchain_2_subtask_4: [
      {
        key: "blockchain_2_subtask_4_report_knowledge_1",
        report: reports.blockchain_2_report_14,
      },
      {
        key: "blockchain_2_subtask_4_report_skill_1",
        report: reports.blockchain_2_report_15,
      },
      {
        key: "blockchain_2_subtask_4_report_skill_2",
        report: reports.blockchain_2_report_16,
      },
    ],
    blockchain_2_subtask_5: [
      {
        key: "blockchain_2_subtask_5_report_knowledge_1",
        report: reports.blockchain_2_report_17,
      },
      {
        key: "blockchain_2_subtask_5_report_skill_1",
        report: reports.blockchain_2_report_18,
      },
      {
        key: "blockchain_2_subtask_5_report_skill_2",
        report: reports.blockchain_2_report_19,
      },
      {
        key: "blockchain_2_subtask_5_report_knowledge_2",
        report: reports.blockchain_2_report_20,
      },
    ],
  },
  cloud_1: {
    cloud_1_subtask_1: [
      {
        key: "cloud_1_subtask_1_report_knowledge_1",
        report: reports.cloud_1_report_01,
      },
      {
        key: "cloud_1_subtask_1_report_skill_1",
        report: reports.cloud_1_report_02,
      },
      {
        key: "cloud_1_subtask_1_report_skill_2",
        report: reports.cloud_1_report_03,
      },
    ],
    cloud_1_subtask_2: [
      {
        key: "cloud_1_subtask_2_report_knowledge_1",
        report: reports.cloud_1_report_04,
      },
      {
        key: "cloud_1_subtask_2_report_knowledge_2",
        report: reports.cloud_1_report_05,
      },
      {
        key: "cloud_1_subtask_2_report_skill_1",
        report: reports.cloud_1_report_06,
      },
      {
        key: "cloud_1_subtask_2_report_knowledge_3",
        report: reports.cloud_1_report_07,
      },
      {
        key: "cloud_1_subtask_2_report_skill_2",
        report: reports.cloud_1_report_08,
      },
      {
        key: "cloud_1_subtask_2_report_skill_3",
        report: reports.cloud_1_report_09,
      },
      {
        key: "cloud_1_subtask_2_report_skill_4",
        report: reports.cloud_1_report_10,
      },
    ],
    cloud_1_subtask_3: [
      {
        key: "cloud_1_subtask_3_report_knowledge_1",
        report: reports.cloud_1_report_11,
      },
      {
        key: "cloud_1_subtask_3_report_skill_1",
        report: reports.cloud_1_report_12,
      },
      {
        key: "cloud_1_subtask_3_report_skill_2",
        report: reports.cloud_1_report_13,
      },
    ],
    cloud_1_subtask_4: [
      {
        key: "cloud_1_subtask_4_report_knowledge_1",
        report: reports.cloud_1_report_14,
      },
      {
        key: "cloud_1_subtask_4_report_skill_1",
        report: reports.cloud_1_report_15,
      },
      {
        key: "cloud_1_subtask_4_report_skill_2",
        report: reports.cloud_1_report_16,
      },
    ],
    cloud_1_subtask_5: [
      {
        key: "cloud_1_subtask_5_report_knowledge_1",
        report: reports.cloud_1_report_17,
      },
      {
        key: "cloud_1_subtask_5_report_skill_1",
        report: reports.cloud_1_report_18,
      },
      {
        key: "cloud_1_subtask_5_report_skill_2",
        report: reports.cloud_1_report_19,
      },
      {
        key: "cloud_1_subtask_5_report_knowledge_2",
        report: reports.cloud_1_report_20,
      },
    ],
  },
  cloud_2: {
    cloud_2_subtask_1: [
      {
        key: "cloud_2_subtask_1_report_knowledge_1",
        report: reports.cloud_2_report_01,
      },
      {
        key: "cloud_2_subtask_1_report_skill_1",
        report: reports.cloud_2_report_02,
      },
      {
        key: "cloud_2_subtask_1_report_skill_2",
        report: reports.cloud_2_report_03,
      },
    ],
    cloud_2_subtask_2: [
      {
        key: "cloud_2_subtask_2_report_knowledge_1",
        report: reports.cloud_2_report_04,
      },
      {
        key: "cloud_2_subtask_2_report_knowledge_2",
        report: reports.cloud_2_report_05,
      },
      {
        key: "cloud_2_subtask_2_report_skill_1",
        report: reports.cloud_2_report_06,
      },
      {
        key: "cloud_2_subtask_2_report_knowledge_3",
        report: reports.cloud_2_report_07,
      },
      {
        key: "cloud_2_subtask_2_report_skill_2",
        report: reports.cloud_2_report_08,
      },
      {
        key: "cloud_2_subtask_2_report_skill_3",
        report: reports.cloud_2_report_09,
      },
      {
        key: "cloud_2_subtask_2_report_skill_4",
        report: reports.cloud_2_report_10,
      },
    ],
    cloud_2_subtask_3: [
      {
        key: "cloud_2_subtask_3_report_knowledge_1",
        report: reports.cloud_2_report_11,
      },
      {
        key: "cloud_2_subtask_3_report_skill_1",
        report: reports.cloud_2_report_12,
      },
      {
        key: "cloud_2_subtask_3_report_skill_2",
        report: reports.cloud_2_report_13,
      },
    ],
    cloud_2_subtask_4: [
      {
        key: "cloud_2_subtask_4_report_knowledge_1",
        report: reports.cloud_2_report_14,
      },
      {
        key: "cloud_2_subtask_4_report_skill_1",
        report: reports.cloud_2_report_15,
      },
      {
        key: "cloud_2_subtask_4_report_skill_2",
        report: reports.cloud_2_report_16,
      },
    ],
    cloud_2_subtask_5: [
      {
        key: "cloud_2_subtask_5_report_knowledge_1",
        report: reports.cloud_2_report_17,
      },
      {
        key: "cloud_2_subtask_5_report_skill_1",
        report: reports.cloud_2_report_18,
      },
      {
        key: "cloud_2_subtask_5_report_skill_2",
        report: reports.cloud_2_report_19,
      },
      {
        key: "cloud_2_subtask_5_report_knowledge_2",
        report: reports.cloud_2_report_20,
      },
    ],
  },
  vr_ar_1: {
    vr_ar_1_subtask_1: [
      {
        key: "vr_ar_1_subtask_1_report_knowledge_1",
        report: reports.vr_ar_1_report_01,
      },
      {
        key: "vr_ar_1_subtask_1_report_skill_1",
        report: reports.vr_ar_1_report_02,
      },
      {
        key: "vr_ar_1_subtask_1_report_skill_2",
        report: reports.vr_ar_1_report_03,
      },
    ],
    vr_ar_1_subtask_2: [
      {
        key: "vr_ar_1_subtask_2_report_knowledge_1",
        report: reports.vr_ar_1_report_04,
      },
      {
        key: "vr_ar_1_subtask_2_report_knowledge_2",
        report: reports.vr_ar_1_report_05,
      },
      {
        key: "vr_ar_1_subtask_2_report_skill_1",
        report: reports.vr_ar_1_report_06,
      },
      {
        key: "vr_ar_1_subtask_2_report_knowledge_3",
        report: reports.vr_ar_1_report_07,
      },
      {
        key: "vr_ar_1_subtask_2_report_skill_2",
        report: reports.vr_ar_1_report_08,
      },
      {
        key: "vr_ar_1_subtask_2_report_skill_3",
        report: reports.vr_ar_1_report_09,
      },
      {
        key: "vr_ar_1_subtask_2_report_skill_4",
        report: reports.vr_ar_1_report_10,
      },
    ],
    vr_ar_1_subtask_3: [
      {
        key: "vr_ar_1_subtask_3_report_knowledge_1",
        report: reports.vr_ar_1_report_11,
      },
      {
        key: "vr_ar_1_subtask_3_report_skill_1",
        report: reports.vr_ar_1_report_12,
      },
      {
        key: "vr_ar_1_subtask_3_report_skill_2",
        report: reports.vr_ar_1_report_13,
      },
    ],
    vr_ar_1_subtask_4: [
      {
        key: "vr_ar_1_subtask_4_report_knowledge_1",
        report: reports.vr_ar_1_report_14,
      },
      {
        key: "vr_ar_1_subtask_4_report_skill_1",
        report: reports.vr_ar_1_report_15,
      },
      {
        key: "vr_ar_1_subtask_4_report_skill_2",
        report: reports.vr_ar_1_report_16,
      },
    ],
    vr_ar_1_subtask_5: [
      {
        key: "vr_ar_1_subtask_5_report_knowledge_1",
        report: reports.vr_ar_1_report_17,
      },
      {
        key: "vr_ar_1_subtask_5_report_skill_1",
        report: reports.vr_ar_1_report_18,
      },
      {
        key: "vr_ar_1_subtask_5_report_skill_2",
        report: reports.vr_ar_1_report_19,
      },
      {
        key: "vr_ar_1_subtask_5_report_knowledge_2",
        report: reports.vr_ar_1_report_20,
      },
    ],
  },
  vr_ar_2: {
    vr_ar_2_subtask_1: [
      {
        key: "vr_ar_2_subtask_1_report_knowledge_1",
        report: reports.vr_ar_2_report_01,
      },
      {
        key: "vr_ar_2_subtask_1_report_skill_1",
        report: reports.vr_ar_2_report_02,
      },
      {
        key: "vr_ar_2_subtask_1_report_skill_2",
        report: reports.vr_ar_2_report_03,
      },
    ],
    vr_ar_2_subtask_2: [
      {
        key: "vr_ar_2_subtask_2_report_knowledge_1",
        report: reports.vr_ar_2_report_04,
      },
      {
        key: "vr_ar_2_subtask_2_report_knowledge_2",
        report: reports.vr_ar_2_report_05,
      },
      {
        key: "vr_ar_2_subtask_2_report_skill_1",
        report: reports.vr_ar_2_report_06,
      },
      {
        key: "vr_ar_2_subtask_2_report_knowledge_3",
        report: reports.vr_ar_2_report_07,
      },
      {
        key: "vr_ar_2_subtask_2_report_skill_2",
        report: reports.vr_ar_2_report_08,
      },
      {
        key: "vr_ar_2_subtask_2_report_skill_3",
        report: reports.vr_ar_2_report_09,
      },
      {
        key: "vr_ar_2_subtask_2_report_skill_4",
        report: reports.vr_ar_2_report_10,
      },
    ],
    vr_ar_2_subtask_3: [
      {
        key: "vr_ar_2_subtask_3_report_knowledge_1",
        report: reports.vr_ar_2_report_11,
      },
      {
        key: "vr_ar_2_subtask_3_report_skill_1",
        report: reports.vr_ar_2_report_12,
      },
      {
        key: "vr_ar_2_subtask_3_report_skill_2",
        report: reports.vr_ar_2_report_13,
      },
    ],
    vr_ar_2_subtask_4: [
      {
        key: "vr_ar_2_subtask_4_report_knowledge_1",
        report: reports.vr_ar_2_report_14,
      },
      {
        key: "vr_ar_2_subtask_4_report_skill_1",
        report: reports.vr_ar_2_report_15,
      },
      {
        key: "vr_ar_2_subtask_4_report_skill_2",
        report: reports.vr_ar_2_report_16,
      },
    ],
    vr_ar_2_subtask_5: [
      {
        key: "vr_ar_2_subtask_5_report_knowledge_1",
        report: reports.vr_ar_2_report_17,
      },
      {
        key: "vr_ar_2_subtask_5_report_skill_1",
        report: reports.vr_ar_2_report_18,
      },
      {
        key: "vr_ar_2_subtask_5_report_skill_2",
        report: reports.vr_ar_2_report_19,
      },
      {
        key: "vr_ar_2_subtask_5_report_knowledge_2",
        report: reports.vr_ar_2_report_20,
      },
    ],
  },
  iot_1: {
    iot_1_subtask_1: [
      {
        key: "iot_1_subtask_1_report_knowledge_1",
        report: reports.iot_1_report_01,
      },
      {
        key: "iot_1_subtask_1_report_skill_1",
        report: reports.iot_1_report_02,
      },
      {
        key: "iot_1_subtask_1_report_skill_2",
        report: reports.iot_1_report_03,
      },
    ],
    iot_1_subtask_2: [
      {
        key: "iot_1_subtask_2_report_knowledge_1",
        report: reports.iot_1_report_04,
      },
      {
        key: "iot_1_subtask_2_report_knowledge_2",
        report: reports.iot_1_report_05,
      },
      {
        key: "iot_1_subtask_2_report_skill_1",
        report: reports.iot_1_report_06,
      },
      {
        key: "iot_1_subtask_2_report_knowledge_3",
        report: reports.iot_1_report_07,
      },
      {
        key: "iot_1_subtask_2_report_skill_2",
        report: reports.iot_1_report_08,
      },
      {
        key: "iot_1_subtask_2_report_skill_3",
        report: reports.iot_1_report_09,
      },
      {
        key: "iot_1_subtask_2_report_skill_4",
        report: reports.iot_1_report_10,
      },
    ],
    iot_1_subtask_3: [
      {
        key: "iot_1_subtask_3_report_knowledge_1",
        report: reports.iot_1_report_11,
      },
      {
        key: "iot_1_subtask_3_report_skill_1",
        report: reports.iot_1_report_12,
      },
      {
        key: "iot_1_subtask_3_report_skill_2",
        report: reports.iot_1_report_13,
      },
    ],
    iot_1_subtask_4: [
      {
        key: "iot_1_subtask_4_report_knowledge_1",
        report: reports.iot_1_report_14,
      },
      {
        key: "iot_1_subtask_4_report_skill_1",
        report: reports.iot_1_report_15,
      },
      {
        key: "iot_1_subtask_4_report_skill_2",
        report: reports.iot_1_report_16,
      },
    ],
    iot_1_subtask_5: [
      {
        key: "iot_1_subtask_5_report_knowledge_1",
        report: reports.iot_1_report_17,
      },
      {
        key: "iot_1_subtask_5_report_skill_1",
        report: reports.iot_1_report_18,
      },
      {
        key: "iot_1_subtask_5_report_skill_2",
        report: reports.iot_1_report_19,
      },
      {
        key: "iot_1_subtask_5_report_knowledge_2",
        report: reports.iot_1_report_20,
      },
    ],
  },
  iot_2: {
    iot_2_subtask_1: [
      {
        key: "iot_2_subtask_1_report_knowledge_1",
        report: reports.iot_2_report_01,
      },
      {
        key: "iot_2_subtask_1_report_skill_1",
        report: reports.iot_2_report_02,
      },
      {
        key: "iot_2_subtask_1_report_skill_2",
        report: reports.iot_2_report_03,
      },
    ],
    iot_2_subtask_2: [
      {
        key: "iot_2_subtask_2_report_knowledge_1",
        report: reports.iot_2_report_04,
      },
      {
        key: "iot_2_subtask_2_report_knowledge_2",
        report: reports.iot_2_report_05,
      },
      {
        key: "iot_2_subtask_2_report_skill_1",
        report: reports.iot_2_report_06,
      },
      {
        key: "iot_2_subtask_2_report_knowledge_3",
        report: reports.iot_2_report_07,
      },
      {
        key: "iot_2_subtask_2_report_skill_2",
        report: reports.iot_2_report_08,
      },
      {
        key: "iot_2_subtask_2_report_skill_3",
        report: reports.iot_2_report_09,
      },
      {
        key: "iot_2_subtask_2_report_skill_4",
        report: reports.iot_2_report_10,
      },
    ],
    iot_2_subtask_3: [
      {
        key: "iot_2_subtask_3_report_knowledge_1",
        report: reports.iot_2_report_11,
      },
      {
        key: "iot_2_subtask_3_report_skill_1",
        report: reports.iot_2_report_12,
      },
      {
        key: "iot_2_subtask_3_report_skill_2",
        report: reports.iot_2_report_13,
      },
    ],
    iot_2_subtask_4: [
      {
        key: "iot_2_subtask_4_report_knowledge_1",
        report: reports.iot_2_report_14,
      },
      {
        key: "iot_2_subtask_4_report_skill_1",
        report: reports.iot_2_report_15,
      },
      {
        key: "iot_2_subtask_4_report_skill_2",
        report: reports.iot_2_report_16,
      },
    ],
    iot_2_subtask_5: [
      {
        key: "iot_2_subtask_5_report_knowledge_1",
        report: reports.iot_2_report_17,
      },
      {
        key: "iot_2_subtask_5_report_skill_1",
        report: reports.iot_2_report_18,
      },
      {
        key: "iot_2_subtask_5_report_skill_2",
        report: reports.iot_2_report_19,
      },
      {
        key: "iot_2_subtask_5_report_knowledge_2",
        report: reports.iot_2_report_20,
      },
    ],
  },
  sm_1: {
    sm_1_subtask_1: [
      {
        key: "sm_1_subtask_1_report_knowledge_1",
        report: reports.sm_1_report_01,
      },
      {
        key: "sm_1_subtask_1_report_skill_1",
        report: reports.sm_1_report_02,
      },
      {
        key: "sm_1_subtask_1_report_skill_2",
        report: reports.sm_1_report_03,
      },
    ],
    sm_1_subtask_2: [
      {
        key: "sm_1_subtask_2_report_knowledge_1",
        report: reports.sm_1_report_04,
      },
      {
        key: "sm_1_subtask_2_report_knowledge_2",
        report: reports.sm_1_report_05,
      },
      {
        key: "sm_1_subtask_2_report_skill_1",
        report: reports.sm_1_report_06,
      },
      {
        key: "sm_1_subtask_2_report_knowledge_3",
        report: reports.sm_1_report_07,
      },
      {
        key: "sm_1_subtask_2_report_skill_2",
        report: reports.sm_1_report_08,
      },
      {
        key: "sm_1_subtask_2_report_skill_3",
        report: reports.sm_1_report_09,
      },
      {
        key: "sm_1_subtask_2_report_skill_4",
        report: reports.sm_1_report_10,
      },
    ],
    sm_1_subtask_3: [
      {
        key: "sm_1_subtask_3_report_knowledge_1",
        report: reports.sm_1_report_11,
      },
      {
        key: "sm_1_subtask_3_report_skill_1",
        report: reports.sm_1_report_12,
      },
      {
        key: "sm_1_subtask_3_report_skill_2",
        report: reports.sm_1_report_13,
      },
    ],
    sm_1_subtask_4: [
      {
        key: "sm_1_subtask_4_report_knowledge_1",
        report: reports.sm_1_report_14,
      },
      {
        key: "sm_1_subtask_4_report_skill_1",
        report: reports.sm_1_report_15,
      },
      {
        key: "sm_1_subtask_4_report_skill_2",
        report: reports.sm_1_report_16,
      },
    ],
    sm_1_subtask_5: [
      {
        key: "sm_1_subtask_5_report_knowledge_1",
        report: reports.sm_1_report_17,
      },
      {
        key: "sm_1_subtask_5_report_skill_1",
        report: reports.sm_1_report_18,
      },
      {
        key: "sm_1_subtask_5_report_skill_2",
        report: reports.sm_1_report_19,
      },
      {
        key: "sm_1_subtask_5_report_knowledge_2",
        report: reports.sm_1_report_20,
      },
    ],
  },
  sm_2: {
    sm_2_subtask_1: [
      {
        key: "sm_2_subtask_1_report_knowledge_1",
        report: reports.sm_2_report_01,
      },
      {
        key: "sm_2_subtask_1_report_skill_1",
        report: reports.sm_2_report_02,
      },
      {
        key: "sm_2_subtask_1_report_skill_2",
        report: reports.sm_2_report_03,
      },
    ],
    sm_2_subtask_2: [
      {
        key: "sm_2_subtask_2_report_knowledge_1",
        report: reports.sm_2_report_04,
      },
      {
        key: "sm_2_subtask_2_report_knowledge_2",
        report: reports.sm_2_report_05,
      },
      {
        key: "sm_2_subtask_2_report_skill_1",
        report: reports.sm_2_report_06,
      },
      {
        key: "sm_2_subtask_2_report_knowledge_3",
        report: reports.sm_2_report_07,
      },
      {
        key: "sm_2_subtask_2_report_skill_2",
        report: reports.sm_2_report_08,
      },
      {
        key: "sm_2_subtask_2_report_skill_3",
        report: reports.sm_2_report_09,
      },
      {
        key: "sm_2_subtask_2_report_skill_4",
        report: reports.sm_2_report_10,
      },
    ],
    sm_2_subtask_3: [
      {
        key: "sm_2_subtask_3_report_knowledge_1",
        report: reports.sm_2_report_11,
      },
      {
        key: "sm_2_subtask_3_report_skill_1",
        report: reports.sm_2_report_12,
      },
      {
        key: "sm_2_subtask_3_report_skill_2",
        report: reports.sm_2_report_13,
      },
    ],
    sm_2_subtask_4: [
      {
        key: "sm_2_subtask_4_report_knowledge_1",
        report: reports.sm_2_report_14,
      },
      {
        key: "sm_2_subtask_4_report_skill_1",
        report: reports.sm_2_report_15,
      },
      {
        key: "sm_2_subtask_4_report_skill_2",
        report: reports.sm_2_report_16,
      },
    ],
    sm_2_subtask_5: [
      {
        key: "sm_2_subtask_5_report_knowledge_1",
        report: reports.sm_2_report_17,
      },
      {
        key: "sm_2_subtask_5_report_skill_1",
        report: reports.sm_2_report_18,
      },
      {
        key: "sm_2_subtask_5_report_skill_2",
        report: reports.sm_2_report_19,
      },
      {
        key: "sm_2_subtask_5_report_knowledge_2",
        report: reports.sm_2_report_20,
      },
    ],
  },
  e_commerce_1: {
    e_commerce_1_subtask_1: [
      {
        key: "e_commerce_1_subtask_1_report_knowledge_1",
        report: reports.e_commerce_1_report_01,
      },
      {
        key: "e_commerce_1_subtask_1_report_skill_1",
        report: reports.e_commerce_1_report_02,
      },
      {
        key: "e_commerce_1_subtask_1_report_skill_2",
        report: reports.e_commerce_1_report_03,
      },
    ],
    e_commerce_1_subtask_2: [
      {
        key: "e_commerce_1_subtask_2_report_knowledge_1",
        report: reports.e_commerce_1_report_04,
      },
      {
        key: "e_commerce_1_subtask_2_report_knowledge_2",
        report: reports.e_commerce_1_report_05,
      },
      {
        key: "e_commerce_1_subtask_2_report_skill_1",
        report: reports.e_commerce_1_report_06,
      },
      {
        key: "e_commerce_1_subtask_2_report_knowledge_3",
        report: reports.e_commerce_1_report_07,
      },
      {
        key: "e_commerce_1_subtask_2_report_skill_2",
        report: reports.e_commerce_1_report_08,
      },
      {
        key: "e_commerce_1_subtask_2_report_skill_3",
        report: reports.e_commerce_1_report_09,
      },
      {
        key: "e_commerce_1_subtask_2_report_skill_4",
        report: reports.e_commerce_1_report_10,
      },
    ],
    e_commerce_1_subtask_3: [
      {
        key: "e_commerce_1_subtask_3_report_knowledge_1",
        report: reports.e_commerce_1_report_11,
      },
      {
        key: "e_commerce_1_subtask_3_report_skill_1",
        report: reports.e_commerce_1_report_12,
      },
      {
        key: "e_commerce_1_subtask_3_report_skill_2",
        report: reports.e_commerce_1_report_13,
      },
    ],
    e_commerce_1_subtask_4: [
      {
        key: "e_commerce_1_subtask_4_report_knowledge_1",
        report: reports.e_commerce_1_report_14,
      },
      {
        key: "e_commerce_1_subtask_4_report_skill_1",
        report: reports.e_commerce_1_report_15,
      },
      {
        key: "e_commerce_1_subtask_4_report_skill_2",
        report: reports.e_commerce_1_report_16,
      },
    ],
    e_commerce_1_subtask_5: [
      {
        key: "e_commerce_1_subtask_5_report_knowledge_1",
        report: reports.e_commerce_1_report_17,
      },
      {
        key: "e_commerce_1_subtask_5_report_skill_1",
        report: reports.e_commerce_1_report_18,
      },
      {
        key: "e_commerce_1_subtask_5_report_skill_2",
        report: reports.e_commerce_1_report_19,
      },
      {
        key: "e_commerce_1_subtask_5_report_knowledge_2",
        report: reports.e_commerce_1_report_20,
      },
    ],
  },
  e_commerce_2: {
    e_commerce_2_subtask_1: [
      {
        key: "e_commerce_2_subtask_1_report_knowledge_1",
        report: reports.e_commerce_2_report_01,
      },
      {
        key: "e_commerce_2_subtask_1_report_skill_1",
        report: reports.e_commerce_2_report_02,
      },
      {
        key: "e_commerce_2_subtask_1_report_skill_2",
        report: reports.e_commerce_2_report_03,
      },
    ],
    e_commerce_2_subtask_2: [
      {
        key: "e_commerce_2_subtask_2_report_knowledge_1",
        report: reports.e_commerce_2_report_04,
      },
      {
        key: "e_commerce_2_subtask_2_report_knowledge_2",
        report: reports.e_commerce_2_report_05,
      },
      {
        key: "e_commerce_2_subtask_2_report_skill_1",
        report: reports.e_commerce_2_report_06,
      },
      {
        key: "e_commerce_2_subtask_2_report_knowledge_3",
        report: reports.e_commerce_2_report_07,
      },
      {
        key: "e_commerce_2_subtask_2_report_skill_2",
        report: reports.e_commerce_2_report_08,
      },
      {
        key: "e_commerce_2_subtask_2_report_skill_3",
        report: reports.e_commerce_2_report_09,
      },
      {
        key: "e_commerce_2_subtask_2_report_skill_4",
        report: reports.e_commerce_2_report_10,
      },
    ],
    e_commerce_2_subtask_3: [
      {
        key: "e_commerce_2_subtask_3_report_knowledge_1",
        report: reports.e_commerce_2_report_11,
      },
      {
        key: "e_commerce_2_subtask_3_report_skill_1",
        report: reports.e_commerce_2_report_12,
      },
      {
        key: "e_commerce_2_subtask_3_report_skill_2",
        report: reports.e_commerce_2_report_13,
      },
    ],
    e_commerce_2_subtask_4: [
      {
        key: "e_commerce_2_subtask_4_report_knowledge_1",
        report: reports.e_commerce_2_report_14,
      },
      {
        key: "e_commerce_2_subtask_4_report_skill_1",
        report: reports.e_commerce_2_report_15,
      },
      {
        key: "e_commerce_2_subtask_4_report_skill_2",
        report: reports.e_commerce_2_report_16,
      },
    ],
    e_commerce_2_subtask_5: [
      {
        key: "e_commerce_2_subtask_5_report_knowledge_1",
        report: reports.e_commerce_2_report_17,
      },
      {
        key: "e_commerce_2_subtask_5_report_skill_1",
        report: reports.e_commerce_2_report_18,
      },
      {
        key: "e_commerce_2_subtask_5_report_skill_2",
        report: reports.e_commerce_2_report_19,
      },
      {
        key: "e_commerce_2_subtask_5_report_knowledge_2",
        report: reports.e_commerce_2_report_20,
      },
    ],
  },
  rpa_1: {
    rpa_1_subtask_1: [
      {
        key: "rpa_1_subtask_1_report_knowledge_1",
        report: reports.rpa_1_report_01,
      },
      {
        key: "rpa_1_subtask_1_report_skill_1",
        report: reports.rpa_1_report_02,
      },
      {
        key: "rpa_1_subtask_1_report_skill_2",
        report: reports.rpa_1_report_03,
      },
    ],
    rpa_1_subtask_2: [
      {
        key: "rpa_1_subtask_2_report_knowledge_1",
        report: reports.rpa_1_report_04,
      },
      {
        key: "rpa_1_subtask_2_report_knowledge_2",
        report: reports.rpa_1_report_05,
      },
      {
        key: "rpa_1_subtask_2_report_skill_1",
        report: reports.rpa_1_report_06,
      },
      {
        key: "rpa_1_subtask_2_report_knowledge_3",
        report: reports.rpa_1_report_07,
      },
      {
        key: "rpa_1_subtask_2_report_skill_2",
        report: reports.rpa_1_report_08,
      },
      {
        key: "rpa_1_subtask_2_report_skill_3",
        report: reports.rpa_1_report_09,
      },
      {
        key: "rpa_1_subtask_2_report_skill_4",
        report: reports.rpa_1_report_10,
      },
    ],
    rpa_1_subtask_3: [
      {
        key: "rpa_1_subtask_3_report_knowledge_1",
        report: reports.rpa_1_report_11,
      },
      {
        key: "rpa_1_subtask_3_report_skill_1",
        report: reports.rpa_1_report_12,
      },
      {
        key: "rpa_1_subtask_3_report_skill_2",
        report: reports.rpa_1_report_13,
      },
    ],
    rpa_1_subtask_4: [
      {
        key: "rpa_1_subtask_4_report_knowledge_1",
        report: reports.rpa_1_report_14,
      },
      {
        key: "rpa_1_subtask_4_report_skill_1",
        report: reports.rpa_1_report_15,
      },
      {
        key: "rpa_1_subtask_4_report_skill_2",
        report: reports.rpa_1_report_16,
      },
    ],
    rpa_1_subtask_5: [
      {
        key: "rpa_1_subtask_5_report_knowledge_1",
        report: reports.rpa_1_report_17,
      },
      {
        key: "rpa_1_subtask_5_report_skill_1",
        report: reports.rpa_1_report_18,
      },
      {
        key: "rpa_1_subtask_5_report_skill_2",
        report: reports.rpa_1_report_19,
      },
      {
        key: "rpa_1_subtask_5_report_knowledge_2",
        report: reports.rpa_1_report_20,
      },
    ],
  },
  rpa_2: {
    rpa_2_subtask_1: [
      {
        key: "rpa_2_subtask_1_report_knowledge_1",
        report: reports.rpa_2_report_01,
      },
      {
        key: "rpa_2_subtask_1_report_skill_1",
        report: reports.rpa_2_report_02,
      },
      {
        key: "rpa_2_subtask_1_report_skill_2",
        report: reports.rpa_2_report_03,
      },
    ],
    rpa_2_subtask_2: [
      {
        key: "rpa_2_subtask_2_report_knowledge_1",
        report: reports.rpa_2_report_04,
      },
      {
        key: "rpa_2_subtask_2_report_knowledge_2",
        report: reports.rpa_2_report_05,
      },
      {
        key: "rpa_2_subtask_2_report_skill_1",
        report: reports.rpa_2_report_06,
      },
      {
        key: "rpa_2_subtask_2_report_knowledge_3",
        report: reports.rpa_2_report_07,
      },
      {
        key: "rpa_2_subtask_2_report_skill_2",
        report: reports.rpa_2_report_08,
      },
      {
        key: "rpa_2_subtask_2_report_skill_3",
        report: reports.rpa_2_report_09,
      },
      {
        key: "rpa_2_subtask_2_report_skill_4",
        report: reports.rpa_2_report_10,
      },
    ],
    rpa_2_subtask_3: [
      {
        key: "rpa_2_subtask_3_report_knowledge_1",
        report: reports.rpa_2_report_11,
      },
      {
        key: "rpa_2_subtask_3_report_skill_1",
        report: reports.rpa_2_report_12,
      },
      {
        key: "rpa_2_subtask_3_report_skill_2",
        report: reports.rpa_2_report_13,
      },
    ],
    rpa_2_subtask_4: [
      {
        key: "rpa_2_subtask_4_report_knowledge_1",
        report: reports.rpa_2_report_14,
      },
      {
        key: "rpa_2_subtask_4_report_skill_1",
        report: reports.rpa_2_report_15,
      },
      {
        key: "rpa_2_subtask_4_report_skill_2",
        report: reports.rpa_2_report_16,
      },
    ],
    rpa_2_subtask_5: [
      {
        key: "rpa_2_subtask_5_report_knowledge_1",
        report: reports.rpa_2_report_17,
      },
      {
        key: "rpa_2_subtask_5_report_skill_1",
        report: reports.rpa_2_report_18,
      },
      {
        key: "rpa_2_subtask_5_report_skill_2",
        report: reports.rpa_2_report_19,
      },
      {
        key: "rpa_2_subtask_5_report_knowledge_2",
        report: reports.rpa_2_report_20,
      },
    ],
  },
  "3d_1": {
    "3d_1_subtask_1": [
      {
        key: "3d_1_subtask_1_report_knowledge_1",
        report: reports._3d_1_report_01,
      },
      {
        key: "3d_1_subtask_1_report_skill_1",
        report: reports._3d_1_report_02,
      },
      {
        key: "3d_1_subtask_1_report_skill_2",
        report: reports._3d_1_report_03,
      },
    ],
    "3d_1_subtask_2": [
      {
        key: "3d_1_subtask_2_report_knowledge_1",
        report: reports._3d_1_report_04,
      },
      {
        key: "3d_1_subtask_2_report_knowledge_2",
        report: reports._3d_1_report_05,
      },
      {
        key: "3d_1_subtask_2_report_skill_1",
        report: reports._3d_1_report_06,
      },
      {
        key: "3d_1_subtask_2_report_knowledge_3",
        report: reports._3d_1_report_07,
      },
      {
        key: "3d_1_subtask_2_report_skill_2",
        report: reports._3d_1_report_08,
      },
      {
        key: "3d_1_subtask_2_report_skill_3",
        report: reports._3d_1_report_09,
      },
      {
        key: "3d_1_subtask_2_report_skill_4",
        report: reports._3d_1_report_10,
      },
    ],
    "3d_1_subtask_3": [
      {
        key: "3d_1_subtask_3_report_knowledge_1",
        report: reports._3d_1_report_11,
      },
      {
        key: "3d_1_subtask_3_report_skill_1",
        report: reports._3d_1_report_12,
      },
      {
        key: "3d_1_subtask_3_report_skill_2",
        report: reports._3d_1_report_13,
      },
    ],
    "3d_1_subtask_4": [
      {
        key: "3d_1_subtask_4_report_knowledge_1",
        report: reports._3d_1_report_14,
      },
      {
        key: "3d_1_subtask_4_report_skill_1",
        report: reports._3d_1_report_15,
      },
      {
        key: "3d_1_subtask_4_report_skill_2",
        report: reports._3d_1_report_16,
      },
    ],
    "3d_1_subtask_5": [
      {
        key: "3d_1_subtask_5_report_knowledge_1",
        report: reports._3d_1_report_17,
      },
      {
        key: "3d_1_subtask_5_report_skill_1",
        report: reports._3d_1_report_18,
      },
      {
        key: "3d_1_subtask_5_report_skill_2",
        report: reports._3d_1_report_19,
      },
      {
        key: "3d_1_subtask_5_report_knowledge_2",
        report: reports._3d_1_report_20,
      },
    ],
  },
  "3d_2": {
    "3d_2_subtask_1": [
      {
        key: "3d_2_subtask_1_report_knowledge_1",
        report: reports._3d_2_report_01,
      },
      {
        key: "3d_2_subtask_1_report_skill_1",
        report: reports._3d_2_report_02,
      },
      {
        key: "3d_2_subtask_1_report_skill_2",
        report: reports._3d_2_report_03,
      },
    ],
    "3d_2_subtask_2": [
      {
        key: "3d_2_subtask_2_report_knowledge_1",
        report: reports._3d_2_report_04,
      },
      {
        key: "3d_2_subtask_2_report_knowledge_2",
        report: reports._3d_2_report_05,
      },
      {
        key: "3d_2_subtask_2_report_skill_1",
        report: reports._3d_2_report_06,
      },
      {
        key: "3d_2_subtask_2_report_knowledge_3",
        report: reports._3d_2_report_07,
      },
      {
        key: "3d_2_subtask_2_report_skill_2",
        report: reports._3d_2_report_08,
      },
      {
        key: "3d_2_subtask_2_report_skill_3",
        report: reports._3d_2_report_09,
      },
      {
        key: "3d_2_subtask_2_report_skill_4",
        report: reports._3d_2_report_10,
      },
    ],
    "3d_2_subtask_3": [
      {
        key: "3d_2_subtask_3_report_knowledge_1",
        report: reports._3d_2_report_11,
      },
      {
        key: "3d_2_subtask_3_report_skill_1",
        report: reports._3d_2_report_12,
      },
      {
        key: "3d_2_subtask_3_report_skill_2",
        report: reports._3d_2_report_13,
      },
    ],
    "3d_2_subtask_4": [
      {
        key: "3d_2_subtask_4_report_knowledge_1",
        report: reports._3d_2_report_14,
      },
      {
        key: "3d_2_subtask_4_report_skill_1",
        report: reports._3d_2_report_15,
      },
      {
        key: "3d_2_subtask_4_report_skill_2",
        report: reports._3d_2_report_16,
      },
    ],
    "3d_2_subtask_5": [
      {
        key: "3d_2_subtask_5_report_knowledge_1",
        report: reports._3d_2_report_17,
      },
      {
        key: "3d_2_subtask_5_report_skill_1",
        report: reports._3d_2_report_18,
      },
      {
        key: "3d_2_subtask_5_report_skill_2",
        report: reports._3d_2_report_19,
      },
      {
        key: "3d_2_subtask_5_report_knowledge_2",
        report: reports._3d_2_report_20,
      },
    ],
  },
};

export default reportMap;
