const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Potrzeby i wymagania biznesowe na kt&oacute;re odpowiada rozwiązanie wykorzystujące blockchain (publiczny)</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 48px;">
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zwiększenie bezpieczeństwa repozytorium danych zawierającego oprogramowanie do aktualizacji urządzeń.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wyeliminowanie czasowych przeciążeń serwera/zator&oacute;w sieciowych (blockchain pozwala urządzeniom pobierać oprogramowanie z wielu źr&oacute;deł w związku z czym nie nastąpi przeciążenie sieci w momencie jednoczesnego pobierania oprogramowania przez wiele urządzeń).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zastąpienie centralnej bazy danych, opartej na tabelach, blokami danych.&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Wdrożenie funkcji walidowania (sprawdzania) zawartości plik&oacute;w przez urządzenia, przed instalacją (blockchain utrudnia podmianę oprogramowania przez hakera na serwerach producenta, gdyż wymagana będzie dodatkowa weryfikacja oprogramowania przez administratora, co musi być potwierdzone dodaniem nowego zdarzenia do sieci blockchain).</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Niski pr&oacute;g wejścia do sieci blockchain (ograniczony budżet klienta oraz jednoczesny brak wymagań w zakresie ograniczenia liczby partner&oacute;w/podmiot&oacute;w kt&oacute;rzy mogliby mieć dostęp do sieci (potrzeby te spełnia blobckahin typu permissionless/publiczny, np. Ethereum).&nbsp;</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Umożliwienie urządzeniom IoT dostępu do bazy blockchain poprzez backend aplikacji, stanowiącej gateway (tj. aplikację kt&oacute;ra komunikuje się z siecią blockchain). Pozwoli to uniknąć łączenia każdego urządzenia bezpośrednio z siecią. Wszystkie urządzenia mające dostęp do aplikacji uzyskają poprzez gateway dostęp do bazy danych blockchain.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Zintegrowanie bazy danych blockchain z aplikacją napisaną w języku C.</span></li>
<li aria-level="1"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Możliwość zlecenia zewnętrznej firmie przeprowadzania test&oacute;w bezpieczeństwa oprogramowania (outsourcing procesu), bez obaw o bezpieczeństwo danych.&nbsp;</span></li>
</ul>
</td>
</tr>
</tbody>
</table>`;

export default html;
