import articles from "./knowledge_bases_import";

const articlesMap: {
  [key: string]: { [key: string]: { key: string; article: string }[] };
} = {
  ai_ml: {
    ai_ml_knowledge_base_1: [
      {
        key: "ai_ml_knowledge_base_1_article_01",
        article: articles.ai_ml_1_article_01,
      },
      {
        key: "ai_ml_knowledge_base_1_article_02",
        article: articles.ai_ml_1_article_02,
      },
      {
        key: "ai_ml_knowledge_base_1_article_03",
        article: articles.ai_ml_1_article_03,
      },
    ],
    ai_ml_knowledge_base_2: [
      {
        key: "ai_ml_knowledge_base_2_article_01",
        article: articles.ai_ml_2_article_01,
      },
    ],
    ai_ml_knowledge_base_3: [
      {
        key: "ai_ml_knowledge_base_3_article_01",
        article: articles.ai_ml_3_article_01,
      },
    ],
    ai_ml_knowledge_base_4: [
      {
        key: "ai_ml_knowledge_base_4_article_01",
        article: articles.ai_ml_4_article_01,
      },
      {
        key: "ai_ml_knowledge_base_4_article_02",
        article: articles.ai_ml_4_article_02,
      },
      {
        key: "ai_ml_knowledge_base_4_article_03",
        article: articles.ai_ml_4_article_03,
      },
    ],
    ai_ml_knowledge_base_5: [
      {
        key: "ai_ml_knowledge_base_5_article_01",
        article: articles.ai_ml_5_article_01,
      },
      {
        key: "ai_ml_knowledge_base_5_article_02",
        article: articles.ai_ml_5_article_02,
      },
      {
        key: "ai_ml_knowledge_base_5_article_03",
        article: articles.ai_ml_5_article_03,
      },
      {
        key: "ai_ml_knowledge_base_5_article_04",
        article: articles.ai_ml_5_article_04,
      },
      {
        key: "ai_ml_knowledge_base_5_article_05",
        article: articles.ai_ml_5_article_05,
      },
      {
        key: "ai_ml_knowledge_base_5_article_06",
        article: articles.ai_ml_5_article_06,
      },
      {
        key: "ai_ml_knowledge_base_5_article_07",
        article: articles.ai_ml_5_article_07,
      },
      {
        key: "ai_ml_knowledge_base_5_article_08",
        article: articles.ai_ml_5_article_08,
      },
      {
        key: "ai_ml_knowledge_base_5_article_09",
        article: articles.ai_ml_5_article_09,
      },
      {
        key: "ai_ml_knowledge_base_5_article_10",
        article: articles.ai_ml_5_article_10,
      },
      {
        key: "ai_ml_knowledge_base_5_article_11",
        article: articles.ai_ml_5_article_11,
      },
      {
        key: "ai_ml_knowledge_base_5_article_12",
        article: articles.ai_ml_5_article_12,
      },
    ],
    ai_ml_knowledge_base_6: [
      {
        key: "ai_ml_knowledge_base_6_article_01",
        article: articles.ai_ml_6_article_01,
      },
    ],
  },
  bi: {
    bi_knowledge_base_1: [
      {
        key: "bi_knowledge_base_1_article_01",
        article: articles.bi_1_article_01,
      },
      {
        key: "bi_knowledge_base_1_article_02",
        article: articles.bi_1_article_02,
      },
      {
        key: "bi_knowledge_base_1_article_03",
        article: articles.bi_1_article_03,
      },
    ],
    bi_knowledge_base_2: [
      {
        key: "bi_knowledge_base_2_article_01",
        article: articles.bi_2_article_01,
      },
      {
        key: "bi_knowledge_base_2_article_02",
        article: articles.bi_2_article_02,
      },
      {
        key: "bi_knowledge_base_2_article_03",
        article: articles.bi_2_article_03,
      },
      {
        key: "bi_knowledge_base_2_article_04",
        article: articles.bi_2_article_04,
      },
    ],
    bi_knowledge_base_3: [
      {
        key: "bi_knowledge_base_3_article_01",
        article: articles.bi_3_article_01,
      },
      {
        key: "bi_knowledge_base_3_article_02",
        article: articles.bi_3_article_02,
      },
      {
        key: "bi_knowledge_base_3_article_03",
        article: articles.bi_3_article_03,
      },
      {
        key: "bi_knowledge_base_3_article_04",
        article: articles.bi_3_article_04,
      },
      {
        key: "bi_knowledge_base_3_article_05",
        article: articles.bi_3_article_05,
      },
      {
        key: "bi_knowledge_base_3_article_06",
        article: articles.bi_3_article_06,
      },
      {
        key: "bi_knowledge_base_3_article_07",
        article: articles.bi_3_article_07,
      },
      {
        key: "bi_knowledge_base_3_article_08",
        article: articles.bi_3_article_08,
      },
      {
        key: "bi_knowledge_base_3_article_09",
        article: articles.bi_3_article_09,
      },
      {
        key: "bi_knowledge_base_3_article_10",
        article: articles.bi_3_article_10,
      },
    ],
    bi_knowledge_base_4: [
      {
        key: "bi_knowledge_base_4_article_01",
        article: articles.bi_4_article_01,
      },
      {
        key: "bi_knowledge_base_4_article_02",
        article: articles.bi_4_article_02,
      },
      {
        key: "bi_knowledge_base_4_article_03",
        article: articles.bi_4_article_03,
      },
    ],
    bi_knowledge_base_5: [
      {
        key: "bi_knowledge_base_5_article_01",
        article: articles.bi_5_article_01,
      },
      {
        key: "bi_knowledge_base_5_article_02",
        article: articles.bi_5_article_02,
      },
      {
        key: "bi_knowledge_base_5_article_03",
        article: articles.bi_5_article_03,
      },
      {
        key: "bi_knowledge_base_5_article_04",
        article: articles.bi_5_article_04,
      },
      {
        key: "bi_knowledge_base_5_article_05",
        article: articles.bi_5_article_05,
      },
      {
        key: "bi_knowledge_base_5_article_06",
        article: articles.bi_5_article_06,
      },
      {
        key: "bi_knowledge_base_5_article_07",
        article: articles.bi_5_article_07,
      },
      {
        key: "bi_knowledge_base_5_article_08",
        article: articles.bi_5_article_08,
      },
      {
        key: "bi_knowledge_base_5_article_09",
        article: articles.bi_5_article_09,
      },
      {
        key: "bi_knowledge_base_5_article_10",
        article: articles.bi_5_article_10,
      },
      {
        key: "bi_knowledge_base_5_article_11",
        article: articles.bi_5_article_11,
      },
      {
        key: "bi_knowledge_base_5_article_12",
        article: articles.bi_5_article_12,
      },
    ],
    bi_knowledge_base_6: [
      {
        key: "bi_knowledge_base_6_article_01",
        article: articles.bi_6_article_01,
      },
      {
        key: "bi_knowledge_base_6_article_02",
        article: articles.bi_6_article_02,
      },
    ],
  },
  blockchain: {
    blockchain_knowledge_base_1: [
      {
        key: "blockchain_knowledge_base_1_article_01",
        article: articles.blockchain_1_article_01,
      },
      {
        key: "blockchain_knowledge_base_1_article_02",
        article: articles.blockchain_1_article_02,
      },
    ],
    blockchain_knowledge_base_2: [
      {
        key: "blockchain_knowledge_base_2_article_01",
        article: articles.blockchain_2_article_01,
      },
    ],
    blockchain_knowledge_base_3: [
      {
        key: "blockchain_knowledge_base_3_article_01",
        article: articles.blockchain_3_article_01,
      },
    ],
    blockchain_knowledge_base_4: [
      {
        key: "blockchain_knowledge_base_4_article_01",
        article: articles.blockchain_4_article_01,
      },
      {
        key: "blockchain_knowledge_base_4_article_02",
        article: articles.blockchain_4_article_02,
      },
    ],
    blockchain_knowledge_base_5: [
      {
        key: "blockchain_knowledge_base_5_article_01",
        article: articles.blockchain_5_article_01,
      },
      {
        key: "blockchain_knowledge_base_5_article_02",
        article: articles.blockchain_5_article_02,
      },
      {
        key: "blockchain_knowledge_base_5_article_03",
        article: articles.blockchain_5_article_03,
      },
      {
        key: "blockchain_knowledge_base_5_article_04",
        article: articles.blockchain_5_article_04,
      },
      {
        key: "blockchain_knowledge_base_5_article_05",
        article: articles.blockchain_5_article_05,
      },
      {
        key: "blockchain_knowledge_base_5_article_06",
        article: articles.blockchain_5_article_06,
      },
      {
        key: "blockchain_knowledge_base_5_article_07",
        article: articles.blockchain_5_article_07,
      },
      {
        key: "blockchain_knowledge_base_5_article_08",
        article: articles.blockchain_5_article_08,
      },
      {
        key: "blockchain_knowledge_base_5_article_09",
        article: articles.blockchain_5_article_09,
      },
      {
        key: "blockchain_knowledge_base_5_article_10",
        article: articles.blockchain_5_article_10,
      },
      {
        key: "blockchain_knowledge_base_5_article_11",
        article: articles.blockchain_5_article_11,
      },
      {
        key: "blockchain_knowledge_base_5_article_12",
        article: articles.blockchain_5_article_12,
      },
    ],
    blockchain_knowledge_base_6: [
      {
        key: "blockchain_knowledge_base_6_article_01",
        article: articles.blockchain_6_article_01,
      },
    ],
  },
  cloud: {
    cloud_knowledge_base_1: [
      {
        key: "cloud_knowledge_base_1_article_01",
        article: articles.cloud_1_article_01,
      },
      {
        key: "cloud_knowledge_base_1_article_02",
        article: articles.cloud_1_article_02,
      },
    ],
    cloud_knowledge_base_2: [
      {
        key: "cloud_knowledge_base_2_article_01",
        article: articles.cloud_2_article_01,
      },
    ],
    cloud_knowledge_base_3: [
      {
        key: "cloud_knowledge_base_3_article_01",
        article: articles.cloud_3_article_01,
      },
    ],
    cloud_knowledge_base_4: [
      {
        key: "cloud_knowledge_base_4_article_01",
        article: articles.cloud_4_article_01,
      },
      {
        key: "cloud_knowledge_base_4_article_02",
        article: articles.cloud_4_article_02,
      },
      {
        key: "cloud_knowledge_base_4_article_03",
        article: articles.cloud_4_article_03,
      },
    ],
    cloud_knowledge_base_5: [
      {
        key: "cloud_knowledge_base_5_article_01",
        article: articles.cloud_5_article_01,
      },
      {
        key: "cloud_knowledge_base_5_article_02",
        article: articles.cloud_5_article_02,
      },
      {
        key: "cloud_knowledge_base_5_article_03",
        article: articles.cloud_5_article_03,
      },
      {
        key: "cloud_knowledge_base_5_article_04",
        article: articles.cloud_5_article_04,
      },
      {
        key: "cloud_knowledge_base_5_article_05",
        article: articles.cloud_5_article_05,
      },
      {
        key: "cloud_knowledge_base_5_article_06",
        article: articles.cloud_5_article_06,
      },
      {
        key: "cloud_knowledge_base_5_article_07",
        article: articles.cloud_5_article_07,
      },
      {
        key: "cloud_knowledge_base_5_article_08",
        article: articles.cloud_5_article_08,
      },
      {
        key: "cloud_knowledge_base_5_article_09",
        article: articles.cloud_5_article_09,
      },
      {
        key: "cloud_knowledge_base_5_article_10",
        article: articles.cloud_5_article_10,
      },
      {
        key: "cloud_knowledge_base_5_article_11",
        article: articles.cloud_5_article_11,
      },
      {
        key: "cloud_knowledge_base_5_article_12",
        article: articles.cloud_5_article_12,
      },
    ],
    cloud_knowledge_base_6: [
      {
        key: "cloud_knowledge_base_6_article_01",
        article: articles.cloud_6_article_01,
      },
    ],
  },
  vr_ar: {
    vr_ar_knowledge_base_1: [
      {
        key: "vr_ar_knowledge_base_1_article_01",
        article: articles.vr_ar_1_article_01,
      },
      {
        key: "vr_ar_knowledge_base_1_article_02",
        article: articles.vr_ar_1_article_02,
      },
    ],
    vr_ar_knowledge_base_2: [
      {
        key: "vr_ar_knowledge_base_2_article_01",
        article: articles.vr_ar_2_article_01,
      },
    ],
    vr_ar_knowledge_base_3: [
      {
        key: "vr_ar_knowledge_base_3_article_01",
        article: articles.vr_ar_3_article_01,
      },
    ],
    vr_ar_knowledge_base_4: [
      {
        key: "vr_ar_knowledge_base_4_article_01",
        article: articles.vr_ar_4_article_01,
      },
      {
        key: "vr_ar_knowledge_base_4_article_02",
        article: articles.vr_ar_4_article_02,
      },
      {
        key: "vr_ar_knowledge_base_4_article_03",
        article: articles.vr_ar_4_article_03,
      },
    ],
    vr_ar_knowledge_base_5: [
      {
        key: "vr_ar_knowledge_base_5_article_01",
        article: articles.vr_ar_5_article_01,
      },
      {
        key: "vr_ar_knowledge_base_5_article_02",
        article: articles.vr_ar_5_article_02,
      },
      {
        key: "vr_ar_knowledge_base_5_article_03",
        article: articles.vr_ar_5_article_03,
      },
      {
        key: "vr_ar_knowledge_base_5_article_04",
        article: articles.vr_ar_5_article_04,
      },
      {
        key: "vr_ar_knowledge_base_5_article_05",
        article: articles.vr_ar_5_article_05,
      },
      {
        key: "vr_ar_knowledge_base_5_article_06",
        article: articles.vr_ar_5_article_06,
      },
      {
        key: "vr_ar_knowledge_base_5_article_07",
        article: articles.vr_ar_5_article_07,
      },
      {
        key: "vr_ar_knowledge_base_5_article_08",
        article: articles.vr_ar_5_article_08,
      },
      {
        key: "vr_ar_knowledge_base_5_article_09",
        article: articles.vr_ar_5_article_09,
      },
      {
        key: "vr_ar_knowledge_base_5_article_10",
        article: articles.vr_ar_5_article_10,
      },
      {
        key: "vr_ar_knowledge_base_5_article_11",
        article: articles.vr_ar_5_article_11,
      },
      {
        key: "vr_ar_knowledge_base_5_article_12",
        article: articles.vr_ar_5_article_12,
      },
    ],
    vr_ar_knowledge_base_6: [
      {
        key: "vr_ar_knowledge_base_6_article_01",
        article: articles.vr_ar_6_article_01,
      },
    ],
  },
  iot: {
    iot_knowledge_base_1: [
      {
        key: "iot_knowledge_base_1_article_01",
        article: articles.iot_1_article_01,
      },
      {
        key: "iot_knowledge_base_1_article_02",
        article: articles.iot_1_article_02,
      },
    ],
    iot_knowledge_base_2: [
      {
        key: "iot_knowledge_base_2_article_01",
        article: articles.iot_2_article_01,
      },
    ],
    iot_knowledge_base_3: [
      {
        key: "iot_knowledge_base_3_article_01",
        article: articles.iot_3_article_01,
      },
    ],
    iot_knowledge_base_4: [
      {
        key: "iot_knowledge_base_4_article_01",
        article: articles.iot_4_article_01,
      },
      {
        key: "iot_knowledge_base_4_article_02",
        article: articles.iot_4_article_02,
      },
      {
        key: "iot_knowledge_base_4_article_03",
        article: articles.iot_4_article_03,
      },
    ],
    iot_knowledge_base_5: [
      {
        key: "iot_knowledge_base_5_article_01",
        article: articles.iot_5_article_01,
      },
      {
        key: "iot_knowledge_base_5_article_02",
        article: articles.iot_5_article_02,
      },
      {
        key: "iot_knowledge_base_5_article_03",
        article: articles.iot_5_article_03,
      },
      {
        key: "iot_knowledge_base_5_article_04",
        article: articles.iot_5_article_04,
      },
      {
        key: "iot_knowledge_base_5_article_05",
        article: articles.iot_5_article_05,
      },
      {
        key: "iot_knowledge_base_5_article_06",
        article: articles.iot_5_article_06,
      },
      {
        key: "iot_knowledge_base_5_article_07",
        article: articles.iot_5_article_07,
      },
      {
        key: "iot_knowledge_base_5_article_08",
        article: articles.iot_5_article_08,
      },
      {
        key: "iot_knowledge_base_5_article_09",
        article: articles.iot_5_article_09,
      },
      {
        key: "iot_knowledge_base_5_article_10",
        article: articles.iot_5_article_10,
      },
      {
        key: "iot_knowledge_base_5_article_11",
        article: articles.iot_5_article_11,
      },
      {
        key: "iot_knowledge_base_5_article_12",
        article: articles.iot_5_article_12,
      },
    ],
    iot_knowledge_base_6: [
      {
        key: "iot_knowledge_base_6_article_01",
        article: articles.iot_6_article_01,
      },
    ],
  },
  sm: {
    sm_knowledge_base_1: [
      {
        key: "sm_knowledge_base_1_article_01",
        article: articles.sm_1_article_01,
      },
      {
        key: "sm_knowledge_base_1_article_02",
        article: articles.sm_1_article_02,
      },
      {
        key: "sm_knowledge_base_1_article_03",
        article: articles.sm_1_article_03,
      },
    ],
    sm_knowledge_base_2: [
      {
        key: "sm_knowledge_base_2_article_01",
        article: articles.sm_2_article_01,
      },
    ],
    sm_knowledge_base_3: [
      {
        key: "sm_knowledge_base_3_article_01",
        article: articles.sm_3_article_01,
      },
    ],
    sm_knowledge_base_4: [
      {
        key: "sm_knowledge_base_4_article_01",
        article: articles.sm_4_article_01,
      },
      {
        key: "sm_knowledge_base_4_article_02",
        article: articles.sm_4_article_02,
      },
      {
        key: "sm_knowledge_base_4_article_03",
        article: articles.sm_4_article_03,
      },
    ],
    sm_knowledge_base_5: [
      {
        key: "sm_knowledge_base_5_article_01",
        article: articles.sm_5_article_01,
      },
      {
        key: "sm_knowledge_base_5_article_02",
        article: articles.sm_5_article_02,
      },
      {
        key: "sm_knowledge_base_5_article_03",
        article: articles.sm_5_article_03,
      },
      {
        key: "sm_knowledge_base_5_article_04",
        article: articles.sm_5_article_04,
      },
      {
        key: "sm_knowledge_base_5_article_05",
        article: articles.sm_5_article_05,
      },
      {
        key: "sm_knowledge_base_5_article_06",
        article: articles.sm_5_article_06,
      },
      {
        key: "sm_knowledge_base_5_article_07",
        article: articles.sm_5_article_07,
      },
      {
        key: "sm_knowledge_base_5_article_08",
        article: articles.sm_5_article_08,
      },
      {
        key: "sm_knowledge_base_5_article_09",
        article: articles.sm_5_article_09,
      },
      {
        key: "sm_knowledge_base_5_article_10",
        article: articles.sm_5_article_10,
      },
      {
        key: "sm_knowledge_base_5_article_11",
        article: articles.sm_5_article_11,
      },
      {
        key: "sm_knowledge_base_5_article_12",
        article: articles.sm_5_article_12,
      },
    ],
    sm_knowledge_base_6: [
      {
        key: "sm_knowledge_base_6_article_01",
        article: articles.sm_6_article_01,
      },
    ],
  },
  e_commerce: {
    e_commerce_knowledge_base_1: [
      {
        key: "e_commerce_knowledge_base_1_article_01",
        article: articles.e_commerce_1_article_01,
      },
      {
        key: "e_commerce_knowledge_base_1_article_02",
        article: articles.e_commerce_1_article_02,
      },
    ],
    e_commerce_knowledge_base_2: [
      {
        key: "e_commerce_knowledge_base_2_article_01",
        article: articles.e_commerce_2_article_01,
      },
    ],
    e_commerce_knowledge_base_3: [
      {
        key: "e_commerce_knowledge_base_3_article_01",
        article: articles.e_commerce_3_article_01,
      },
    ],
    e_commerce_knowledge_base_4: [
      {
        key: "e_commerce_knowledge_base_4_article_01",
        article: articles.e_commerce_4_article_01,
      },
      {
        key: "e_commerce_knowledge_base_4_article_02",
        article: articles.e_commerce_4_article_02,
      },
      {
        key: "e_commerce_knowledge_base_4_article_03",
        article: articles.e_commerce_4_article_03,
      },
    ],
    e_commerce_knowledge_base_5: [
      {
        key: "e_commerce_knowledge_base_5_article_01",
        article: articles.e_commerce_5_article_01,
      },
      {
        key: "e_commerce_knowledge_base_5_article_02",
        article: articles.e_commerce_5_article_02,
      },
      {
        key: "e_commerce_knowledge_base_5_article_03",
        article: articles.e_commerce_5_article_03,
      },
      {
        key: "e_commerce_knowledge_base_5_article_04",
        article: articles.e_commerce_5_article_04,
      },
      {
        key: "e_commerce_knowledge_base_5_article_05",
        article: articles.e_commerce_5_article_05,
      },
      {
        key: "e_commerce_knowledge_base_5_article_06",
        article: articles.e_commerce_5_article_06,
      },
      {
        key: "e_commerce_knowledge_base_5_article_07",
        article: articles.e_commerce_5_article_07,
      },
      {
        key: "e_commerce_knowledge_base_5_article_08",
        article: articles.e_commerce_5_article_08,
      },
      {
        key: "e_commerce_knowledge_base_5_article_09",
        article: articles.e_commerce_5_article_09,
      },
      {
        key: "e_commerce_knowledge_base_5_article_10",
        article: articles.e_commerce_5_article_10,
      },
      {
        key: "e_commerce_knowledge_base_5_article_11",
        article: articles.e_commerce_5_article_11,
      },
      {
        key: "e_commerce_knowledge_base_5_article_12",
        article: articles.e_commerce_5_article_12,
      },
    ],
    e_commerce_knowledge_base_6: [
      {
        key: "e_commerce_knowledge_base_6_article_01",
        article: articles.e_commerce_6_article_01,
      },
    ],
  },
  rpa: {
    rpa_knowledge_base_1: [
      {
        key: "rpa_knowledge_base_1_article_01",
        article: articles.rpa_1_article_01,
      },
      {
        key: "rpa_knowledge_base_1_article_02",
        article: articles.rpa_1_article_02,
      },
    ],
    rpa_knowledge_base_2: [
      {
        key: "rpa_knowledge_base_2_article_01",
        article: articles.rpa_2_article_01,
      },
    ],
    rpa_knowledge_base_3: [
      {
        key: "rpa_knowledge_base_3_article_01",
        article: articles.rpa_3_article_01,
      },
    ],
    rpa_knowledge_base_4: [
      {
        key: "rpa_knowledge_base_4_article_01",
        article: articles.rpa_4_article_01,
      },
      {
        key: "rpa_knowledge_base_4_article_02",
        article: articles.rpa_4_article_02,
      },
      {
        key: "rpa_knowledge_base_4_article_03",
        article: articles.rpa_4_article_03,
      },
    ],
    rpa_knowledge_base_5: [
      {
        key: "rpa_knowledge_base_5_article_01",
        article: articles.rpa_5_article_01,
      },
      {
        key: "rpa_knowledge_base_5_article_02",
        article: articles.rpa_5_article_02,
      },
      {
        key: "rpa_knowledge_base_5_article_03",
        article: articles.rpa_5_article_03,
      },
      {
        key: "rpa_knowledge_base_5_article_04",
        article: articles.rpa_5_article_04,
      },
      {
        key: "rpa_knowledge_base_5_article_05",
        article: articles.rpa_5_article_05,
      },
      {
        key: "rpa_knowledge_base_5_article_06",
        article: articles.rpa_5_article_06,
      },
      {
        key: "rpa_knowledge_base_5_article_07",
        article: articles.rpa_5_article_07,
      },
      {
        key: "rpa_knowledge_base_5_article_08",
        article: articles.rpa_5_article_08,
      },
      {
        key: "rpa_knowledge_base_5_article_09",
        article: articles.rpa_5_article_09,
      },
      {
        key: "rpa_knowledge_base_5_article_10",
        article: articles.rpa_5_article_10,
      },
      {
        key: "rpa_knowledge_base_5_article_11",
        article: articles.rpa_5_article_11,
      },
      {
        key: "rpa_knowledge_base_5_article_12",
        article: articles.rpa_5_article_12,
      },
    ],
    rpa_knowledge_base_6: [
      {
        key: "rpa_knowledge_base_6_article_01",
        article: articles.rpa_6_article_01,
      },
    ],
  },
  "3d": {
    "3d_knowledge_base_1": [
      {
        key: "3d_knowledge_base_1_article_01",
        article: articles._3d_1_article_01,
      },
      {
        key: "3d_knowledge_base_1_article_02",
        article: articles._3d_1_article_02,
      },
    ],
    "3d_knowledge_base_2": [
      {
        key: "3d_knowledge_base_2_article_01",
        article: articles._3d_2_article_01,
      },
    ],
    "3d_knowledge_base_3": [
      {
        key: "3d_knowledge_base_3_article_01",
        article: articles._3d_3_article_01,
      },
    ],
    "3d_knowledge_base_4": [
      {
        key: "3d_knowledge_base_4_article_01",
        article: articles._3d_4_article_01,
      },
    ],
    "3d_knowledge_base_5": [
      {
        key: "3d_knowledge_base_5_article_01",
        article: articles._3d_5_article_01,
      },
      {
        key: "3d_knowledge_base_5_article_02",
        article: articles._3d_5_article_02,
      },
      {
        key: "3d_knowledge_base_5_article_03",
        article: articles._3d_5_article_03,
      },
      {
        key: "3d_knowledge_base_5_article_04",
        article: articles._3d_5_article_04,
      },
      {
        key: "3d_knowledge_base_5_article_05",
        article: articles._3d_5_article_05,
      },
      {
        key: "3d_knowledge_base_5_article_06",
        article: articles._3d_5_article_06,
      },
      {
        key: "3d_knowledge_base_5_article_07",
        article: articles._3d_5_article_07,
      },
      {
        key: "3d_knowledge_base_5_article_08",
        article: articles._3d_5_article_08,
      },
      {
        key: "3d_knowledge_base_5_article_09",
        article: articles._3d_5_article_09,
      },
      {
        key: "3d_knowledge_base_5_article_10",
        article: articles._3d_5_article_10,
      },
      {
        key: "3d_knowledge_base_5_article_11",
        article: articles._3d_5_article_11,
      },
      {
        key: "3d_knowledge_base_5_article_12",
        article: articles._3d_5_article_12,
      },
    ],
    "3d_knowledge_base_6": [
      {
        key: "3d_knowledge_base_6_article_01",
        article: articles._3d_6_article_01,
      },
    ],
  },
};

export default articlesMap;
