const html = `<table style="border-collapse: collapse; width: 100%; height: 126px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;"><strong>Nazwa zadania projektowego (kamienie milowe projektu)</strong></td>
<td style="width: 19.6353%; height: 18px;"><strong>Nr tygodnia, w kt&oacute;rym następuje rozpoczęcie zadania</strong></td>
<td style="width: 19.6353%; height: 18px;"><strong>Ilość tygodni trwania zadania</strong></td>
<td style="width: 19.6353%; height: 18px;"><strong>Skład osobowy zespołu realizującego zadanie</strong></td>
<td style="width: 19.6388%; height: 18px;"><strong>Efekt realizacji zadania</strong></td>
</tr>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;">Analiza biznesowa</td>
<td style="width: 19.6353%; height: 18px;">1</td>
<td style="width: 19.6353%; height: 18px;">4</td>
<td style="width: 19.6353%; height: 18px;">Architekt BI/BD</td>
<td style="width: 19.6388%; height: 18px;">Dokument zawierający opis struktury modelu analitycznego, w tym definicje miar (wskaźnik&oacute;w do raport&oacute;w) w raportach</td>
</tr>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;">Pobranie danych ze źr&oacute;deł do warstwy STG</td>
<td style="width: 19.6353%; height: 18px;">5</td>
<td style="width: 19.6353%; height: 18px;">4</td>
<td style="width: 19.6353%; height: 18px;">Programista ETL</td>
<td style="width: 19.6388%; height: 18px;">Poprawny, powtarzany codziennie proces ładowania właściwych danych ze źr&oacute;deł do warstwy STG</td>
</tr>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;">Budowa modelu hurtowni danych (tzw. warstwy data warehouse, DWH)</td>
<td style="width: 19.6353%; height: 18px;">10</td>
<td style="width: 19.6353%; height: 18px;">4</td>
<td style="width: 19.6353%; height: 18px;">Programista ETL</td>
<td style="width: 19.6388%; height: 18px;">Codzienny, zgodny z wytycznymi, proces odświeżania danych przez warstwę DWH. Możliwość testowania dostępu do danych przez klienta</td>
</tr>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;">Budowa modelu analitycznego (Online Analytical Processing, OLAP)</td>
<td style="width: 19.6353%; height: 18px;">10</td>
<td style="width: 19.6353%; height: 18px;">4</td>
<td style="width: 19.6353%; height: 18px;">Programista ETL/Programista BI</td>
<td style="width: 19.6388%; height: 18px;">Odświeżany codziennie model analityczny, dający możliwość testowania poprawności danych generowanych przez model (w formie tabeli przestawnej, lub w aplikacji do tworzenia wizualizacji danych) </td>
</tr>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;">Budowa warstwy wizualnej raport&oacute;w w aplikacji BI oferowanej w modelu SaaS</td>
<td style="width: 19.6353%; height: 18px;">13</td>
<td style="width: 19.6353%; height: 18px;">4</td>
<td style="width: 19.6353%; height: 18px;">Programista BI</td>
<td style="width: 19.6388%; height: 18px;">Dostęp do atrakcyjnych wizualnie raportów zawierających oczekiwane dane. Możliwość testowania finalnego produktu. Podpisanie i przekazanie dokumentacji</td>
</tr>
<tr style="height: 18px;">
<td style="width: 19.6353%; height: 18px;">Przekazanie produktu klientowi do testowania, naprawa błęd&oacute;w, wdrożenie produkcyjne</td>
<td style="width: 19.6353%; height: 18px;">17</td>
<td style="width: 19.6353%; height: 18px;">4</td>
<td style="width: 19.6353%; height: 18px;">Programista ETL/Programista BI</td>
<td style="width: 19.6388%; height: 18px;">Raport z test&oacute;w i protok&oacute;ł odbioru prac</td>
</tr>
</tbody>
</table>`;

export default html;
