const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Przykładowe informacje, kt&oacute;re należy przekazać klientowi po wdrożeniu blockchain</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Gł&oacute;wnym problemem, kt&oacute;ry chcieliśmy rozwiązać poprzez realizację projektu, był nieefektywny dostęp do aktualnych danych na temat cyklu życia produktu. W efekcie wdrożenia rozwiązania opartego o technologię blockchain udało się skr&oacute;cić proces dostępu do informacji o produktach. Obecnie uzyskanie wiarygodnych danych o produkcie jest możliwe od razu po wpisaniu jego identyfikatora lub zeskanowaniu kodu QR produktu za pomocą telefonu.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Funkcjonalności rozwiązania, poza dostępem do danych w czasie rzeczywistym obejmują m.in.:</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość dodawania nowych uczestnik&oacute;w sieci</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość definiowania/wybierania obiekt&oacute;w (facilities) przynależących do poszczeg&oacute;lnych uczestnik&oacute;w sieci (np. sklep danej sieci, centrum dystrybucyjne danej firmy logistycznej</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość definiowania produktu (np. dodanie jabłek przez danego rolnika)&nbsp;</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość zdefiniowania cyklu życia produktu (etapu ścieżki życia produktu)&nbsp;</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość dodawania event&oacute;w (zdarzeń) przez poszczeg&oacute;lnych użytkownik&oacute;w sieci (np. wysłanie jabłek, zebranie jabłek)&nbsp;</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość śledzenie zdarzeń</span></p>
<p style="line-height: 1.2; text-align: justify; margin-top: 0pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">-możliwość generowania raport&oacute;w&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Do stworzenia rozwiązania wykorzystaliśmy protok&oacute;ł blockchain, typu permissioned. Dzięki temu podmioty zarządzające siecią mogą decydować o dopuszczeniu nowych podmiot&oacute;w do sieci. Aplikacja, typu SPA (single page application) powstała z wykorzystaniem platformy i frameworka Angular. Technologia ta zapewnia wsparcie dla przeglądarek mobilnych. W związku z tym zapewniona zostanie wysoko jakość rozwiązania przy korzystaniu z urządzeń mobilnych i czytnik&oacute;w kod&oacute;w QR. Sposoby integrowania rozwiązania z systemami zewnętrznymi to interfejs REST API.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W stosunku do wcześniejszego procesu gromadzenia i dostępu do informacji o cyklu życia produktu, wdrożona aplikacja umożliwia dostęp do danych w czasie rzeczywistym i zapewnia każdemu uczestnikowi sieci możliwość dodawania i kontrolowania informacji. Żaden z uczestnik&oacute;w nie jest centralnym administratorem danych w związku z czym nie ma możliwości jednostronnej modyfikacji informacji bez zgody pozostałych uczestnik&oacute;w sieci. Każdy z użytkownik&oacute;w posiada pełny wgląd w całą historię cyklu życia produktu. Zapewnia to niepodważalność i gwarancję pochodzenia danego produktu.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
