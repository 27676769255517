const html = `<table border="1" cellpadding="5">
<tbody>
<tr style="height: 48px;">
<td style="height: 48px;" colspan="3">
<h3 style="text-align: center;"><strong>Technologie i narzędzia wspierające marketing cyfrowy&nbsp;</strong></h3>
</td>
</tr>
<tr style="height: 35px;">
<td style="height: 35px;">
<p><strong>Technologia&nbsp;</strong></p>
</td>
<td style="height: 35px;">
<p><strong>Funkcja&nbsp;</strong></p>
</td>
<td style="height: 35px;">
<p><strong>Wymagane kompetencje</strong></p>
</td>
</tr>
<tr style="height: 161px;">
<td style="height: 161px;">
<p><span style="font-weight: 400;">Narzędzie do analityki ruchu na stronach internetowych&nbsp;</span></p>
</td>
<td style="height: 161px;">
<p><span style="font-weight: 400;">Umożliwia analizę aktywności użytkownik&oacute;w na stronie www, popularność poszczeg&oacute;lnych treści, <em>pomiar konwersji</em> [poziom zrealizowanych cel&oacute;w, np. sprzedażowych] i skuteczność zewnętrznych kampanii promocyjnych kierujących do stronę www. Zewnętrzne kampanie promocyjne to np. kampanie płatne w social media, <em>kampania display</em> [kampania banerowa, polegająca na wykupieniu powierzchni reklamowych np. na portalach internetowych], działania <em>PR</em> [public relations] linkujące do naszej strony internetowej.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Google Analytics 4,&nbsp;</span></p>
</td>
<td style="height: 161px;">
<p><span style="font-weight: 400;">Obsługa i konfiguracja narzędzia Google Analytics 4. Poznanie gł&oacute;wnych funkcjonalności narzędzia wymaga przejścia kilkugodzinnego szkolenia.</span></p>
<p><span style="font-weight: 400;">Właściwa (optymalna) konfiguracja narzędzia wymaga doświadczenia i dodatkowych umiejętności technicznych.</span></p>
</td>
</tr>
<tr style="height: 197px;">
<td style="height: 197px;"><br />
<p><span style="font-weight: 400;">Narzędzie do obsługi kont biznesowych na portalach społecznościowych (Business Manager/Ads Manager)</span></p>
</td>
<td style="height: 197px;">
<p><span style="font-weight: 400;">Umożliwia odpowiednią konfigurację kont biznesowych na platformach <em>social media</em> [aplikacje społecznościowe takie jak Facebook, Instagram, LinkedIn, TikTok, Twitter]. Pozwala na bezpieczne zarządzanie zasobami (treściami, kontami, dostępami pracownik&oacute;w), realizację działań płatnych, zaawansowaną analitykę i udostępnianie zarządzania kanałami społecznościowymi firmy partnerom takim jak agencje reklamowe (udostępnienie agencjom możliwości publikowania treści i tworzenia reklam np. na <em>fanpage&rsquo;u firmy</em> [Strona, czyli wizyt&oacute;wka firmy na platformie social media].</span></p>
<p>&nbsp;</p>
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Meta Business Manager/ Menadżer Firmy Meta, TikTok Business Center etc.</span></p>
</td>
<td style="height: 197px;">
<p><span style="font-weight: 400;">Obsługa i konfiguracja narzędzi. Poznanie gł&oacute;wnych funkcjonalności narzędzia wymaga przejścia kilkunastogodzinnej szkolenia, kt&oacute;re umożliwi pracownikowi firmy zarządzanie narzędziami i analizę działań w SM. Na etapie konfiguracji rekomendowane jest wsparcie zewnętrznego konsultanta.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 113px;">
<td style="height: 113px;">
<p><span style="font-weight: 400;">System zarządzania treścią (CMS) stron internetowych&nbsp;</span></p>
</td>
<td style="height: 113px;">
<p><span style="font-weight: 400;"> Umożliwia szybkie budowanie stron internetowych&nbsp; i ich p&oacute;źniejszą edycję także przez osoby, kt&oacute;re nie posiadają kompetencji programistycznych.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> WordPress, Joomla&nbsp;</span></p>
</td>
<td style="height: 113px;">
<p><span style="font-weight: 400;">Pierwsza instalacji i konfiguracja systemu CMS powinna być wykonana przez programistę, kt&oacute;ry zadba o bezpieczeństwo wdrażanej strony, wykazuje się znajomością JS, HTML, CSS, PHP, zna zasady cyberbezpieczeństwa. Dalsza rozbudowa i edycja strony z wykorzystaniem CMS wymaga kilkugodzinnego szkolenia i opiera się na wykorzystaniu wizualnego konfiguratora.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 135px;">
<td style="height: 135px;">
<p><span style="font-weight: 400;">Narzędzie do monitoringu treści umieszczanych przez użytkownik&oacute;w na forach, platformach internetowych, w social media</span></p>
</td>
<td style="height: 135px;">
<p><span style="font-weight: 400;">Umożliwia monitoring wszystkich publikacji o danej marce i związanych z nią tematach w mediach społecznościowych, serwisach informacyjnych, blogach, forach, stronach z recenzjami i innych mediach. Aplikacja, ze wskazaną częstotliwością,&nbsp; tworzy raporty zawierające wszystkie wzmianki o firmie pojawiające się w internecie, co m.in. pozwala firmie szybko reagować na pojawiające się treści.&nbsp;</span></p>
<br />
<p><strong>Przykłady:</strong><span style="font-weight: 400;"> Brand24, Sentione&nbsp;</span></p>
</td>
<td style="height: 135px;">
<p><span style="font-weight: 400;">Przejście procesu onboardingu w aplikacji oraz podstawowa wiedza w zakresie strategii marketingowej i biznesowej firmy.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
