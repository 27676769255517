const html = `<h3 style="text-align: center;">Modelowy harmonogram projektu wdrożenia RPA</h3>
<table border="1" cellpadding="5">
<tbody>
<tr>
<td>
<p><strong>Zadanie</strong></p>
</td>
<td>
<p><strong>Czas rozpoczęcia zad. (tyg)</strong></p>
</td>
<td>
<p><strong>Czas trwania zad. (tyg)</strong></p>
</td>
<td>
<p><strong>Osoba odpowiedzialna</strong></p>
</td>
<td>
<p><strong>Efekt realizacji zadania</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Analiza procesu biznesowego, kt&oacute;ry ma zostać zautomatyzowany</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 dzień &ndash; 2 tygodnie </span><span style="font-weight: 400;">(w zależności od poziomu skomplikowania procesu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Architekt</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokumentacja biznesowa (Process Design Document, PDD)</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie środowisk programistycznych (dev, test, prod), stworzenie dostęp&oacute;w do system&oacute;w używanych w przebiegu automatyzowanego procesu</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 dzień &ndash; 4 tygodnie </span><span style="font-weight: 400;">(w zależności od poziomu skomplikowania procesu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dział IT klienta</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dostęp do środowisk i system&oacute;w klienta</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Design to develop (stworzenie szczeg&oacute;łowej makiety procesu i oszacowanie czasu developmentu poszczeg&oacute;lnych moduł&oacute;w/element&oacute;w procesu, dodanie task&oacute;w do backlogu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po zakończeniu etapu analizy</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 dzień</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Makieta, backlog prac w narzędziu do zarządzania projektami IT</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Programowanie i cykliczne testowanie kolejnych moduł&oacute;w (budowanie robota)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po zakończeniu etapu design to development</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Case specific </span><span style="font-weight: 400;">(w zależności od złożoności procesu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Zbudowany, działający robot</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Quality Assurance [sprawdzenie techniczne]</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po zakończeniu etapu programowania</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Case specific </span><span style="font-weight: 400;">(w zależności od złożoności procesu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Senior RPA Developer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport z informacjami o zgodności działania robota z zasadami dobrych praktyk i wymogami</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Testy z klientem</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po zakończeniu etapu Quality Assurance</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Case specific </span><span style="font-weight: 400;">(w zależności od złożoności procesu)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Raport z test&oacute;w</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Poprawa błęd&oacute;w</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po testach z klientem</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Case specific </span><span style="font-weight: 400;">(w zależności od złożoności poprawek)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Robot działający zgodnie z wymaganiami klienta</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Uruchomienie robota na środowisku produkcyjnym (deployment)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po testach z klientem</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 dzień</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Robot działający zgodnie z wymaganiami klienta na środowisku produkcyjnym klienta</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Przygotowanie dokumentacji technicznej (Solution Design Document)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po testach z klientem</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1-2 dni</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dokumentacja techniczna działania robota</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Hyper Care (okres świadczenia pełnego wsparcia technicznego)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Po uruchomieniu produkcyjnym robota</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2 tyg</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Developer RPA</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Robot działający zgodnie z ostatecznymi wytycznymi (po naprawie błęd&oacute;w wykrytych na etapie użytkownia)</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
