const html = `<table style="border: none; border-collapse: collapse;">
<tbody>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: center; margin-top: 10pt; margin-bottom: 10pt;"><strong><span style="font-size: 12pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Niezbędne informacje, kt&oacute;re należy przekazać klientowi po wdrożeniu RPA</span></strong></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Istotą technologii RPA jest zastąpienie powtarzalnej pracy człowieka. Technologia ta ma zastosowanie, gdy ze względu na bezpieczeństwo i koszty, użycie innych technologii (tradycyjne programowanie) nie ma zastosowania.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Technologia RPA ma zastosowanie gł&oacute;wnie do automatyzacji powtarzalnych czynności., czasochłonnych z punktu widzenia pracownik&oacute;w.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Czas programowania robota automatyzującego dany proces zależy od jego poziomu skomplikowania. Np. w przypadku robota , kt&oacute;ry ma zapisywać załączniki z maili do excela, całość konfiguracji trwa około 5 min.&nbsp;</span></p>
</td>
</tr>
<tr style="height: 0pt;">
<td style="vertical-align: top; padding: 0.75pt 0.75pt 0.75pt 0.75pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 0.5pt;">
<p style="line-height: 1.2; text-align: justify; margin-top: 10pt; margin-bottom: 10pt;"><span style="font-size: 11pt; font-family: Roboto,sans-serif; color: #000000; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W zależności od tego jakie narzędzie RPA zostanie wybrane, aplikacje do zarządzania r&oacute;żnią się. Aplikacja umożliwiająca zarządzanie pracą robot&oacute;w w prosty i szybki spos&oacute;b, poprzez wyb&oacute;r preferowanych opcji spośr&oacute;d dostępnych. Pozwala ona na wskazanie czasu uruchomienia robot&oacute;w, przypisanie robot&oacute;w do realizacji poszczeg&oacute;lnych proces&oacute;w, wskazanie maszyn wirtualnych, zmianę lokalizacji plik&oacute;w źr&oacute;dłowych.&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
