const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Silnik analityczny to oprogramowanie służące do tworzenia modeli analitycznych ułatwiających analizy danych biznesowych w celu generowania informacji i raport&oacute;w. Silnik analityczny umożliwia budowanie modeli analitycznych (przeliczających dane w określony spos&oacute;b), zawierających obiekty biznesowe (miary i wymiary). Silnik może przetwarzać r&oacute;żne typy danych, w r&oacute;żnych wymiarach. Dzięki temu umożliwia użytkownikom analizowanie danych biznesowych na r&oacute;żnych poziomach, w tym np. na poziomie oddział&oacute;w, region&oacute;w, kategorii produkt&oacute;w, miesięcy, tygodni itp., a po podłączeniu do aplikacji BI, generowanie raport&oacute;w, wizualizacji. Umożliwia szybki dostęp do danych użytkownikom biznesowym, nie wymagając od nich wiedzy technicznej o bazach danych oraz umiejętności programowania (np. w języku SQL).&nbsp;</p>
<p style="text-align: justify;"><strong>Przykłady narzędzi/rozwiązań do zastosowania</strong></p>
<p style="text-align: justify;">Azure Analysis Services, AWS Cloud Analytics Solutions, IBM Analytics Engine</p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Tworzenie modeli w silniku oparte jest na konfiguratorze drag and drop (tj. przytrzymaj i upuść), nie wymaga kodowania (choć jest to możliwe w języku DAX, MDX etc.). Pomaga zatem znajomość:&nbsp;SQL Server Data Tools, DAX, MDX, ASSL, XMLA.</p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Aby podłączyć systemy do analizy i wizualizacji danych do silnika (np. aplikację BI, &nbsp;program excel), wymogiem jest posiadanie connectora (connector to rozwiązanie pozwalające na pobranie danych przeliczonych w silniku).</p>
<p style="text-align: justify;">&nbsp;</p>`;

export default html;
