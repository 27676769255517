const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Hurtownia danych (ang. data warehouse) to wyspecjalizowany system, kt&oacute;ry służy do przechowywania dużych ilości danych z r&oacute;żnych źr&oacute;deł, a następnie ich przetwarzania, analizy i wykorzystania w celach biznesowych. Hurtownie danych umożliwiają agregowania i integrację danych z wielu źr&oacute;deł, a następnie łatwy i efektywny dostęp do danych m.in. przez systemy raportujące.</p>
<p style="text-align: justify;">Cechą charakterystyczną hurtowni danych jest to, że dane w niej zgromadzone są skoncentrowane wok&oacute;ł temat&oacute;w biznesowych, a nie proces&oacute;w technicznych, co ułatwia ich zrozumienie i wykorzystanie przez użytkownik&oacute;w biznesowych. Dane w hurtowniach są przechowywane w postaci zoptymalizowanej pod kątem wyszukiwania i analizy, co umożliwia szybkie wykonywanie złożonych zapytań.</p>
<p style="text-align: justify;">Hurtownie danych umożliwiają podejmowanie lepszych decyzji biznesowych, poprzez dostarczenie dokładnych, sp&oacute;jnych i aktualnych informacji, kt&oacute;re pomagają w zrozumieniu trend&oacute;w, identyfikacji nowych szans i zagrożeń, a także w monitorowaniu i ocenie wynik&oacute;w biznesowych. Hurtownie danych są powszechnie stosowane w dziedzinach takich jak biznes, finanse, medycyna, nauka i wiele innych.</p>
<p style="text-align: justify;"><strong>Przykłady:</strong>&nbsp;Microsoft SQL Server, IBM InfoSphere Warehouse, Teradata, Amazon Redshift, Google BigQuery&nbsp;</p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong></p>
<p style="text-align: justify;">Obsługa SQL Server Management Studio, znajomość języka T-SQL/ SQL SQL Server Data Tools, DAX, MDX, ASSL, XMLA.</p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Mimo trendu by budować data lakes, to hurtownie danych, ze względu na niższy koszt wdrożenia, są wciąż często wybieranym rozwiązaniem</p>`;

export default html;
