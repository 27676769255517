const html = `<p style="text-align: justify;"><strong>Opis technologii</strong></p>
<p style="text-align: justify;">Serwerownia to pomieszczenie najczęściej mające od kilku do kilkunastu metr&oacute;w kwadratowych, w kt&oacute;rym znajduje się szafa/szafy serwerowe, na kt&oacute;rych zapisywane są dane. Takie pomieszczenie, opr&oacute;cz drzwi zamkniętych na klucz (co nie zawsze jest spełnione), nie jest porządnie chronione przed osobami niepożądanymi.&nbsp;</p>
<p style="text-align: justify;">W dedykowanym pomieszczeniu na serwerownię powinny się znaleźć: klimatyzator i wentylacja, system kontroli dostępu, system gaśniczy, instalacje alarmu i monitoringu.</p>
<p style="text-align: justify;">Właściciele dużych firm cenią sobie bliskość serwer&oacute;w, możliwość pełnej kontroli danych i analizy efektywności proces&oacute;w. Własna serwerownia to także szybsze połączenie między stacjami roboczymi a serwerem, a także możliwość dopasowania wszystkich element&oacute;w do indywidualnych potrzeb przedsiębiorstwa</p>
<p style="text-align: justify;"><strong>Przykłady narzędzi/rozwiązań do zastosowania: nie dotyczy </strong></p>
<p style="text-align: justify;"><strong>Wiedza i umiejętności wymagane do użycia/zastosowania technologii:</strong>&nbsp;administrowanie sieciami IT</p>
<p style="text-align: justify;"><strong>Uwaga! </strong></p>
<p style="text-align: justify;">Serwerownia, w tym stworzenie własnego Data Center, jest wydatkiem niemal nieosiągalnym dla małych firm czy startup&oacute;w, z kolei 90% dużych firm posiada własne serwerownie.</p>`;

export default html;
