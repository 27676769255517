const html = `<table style="width: 100%; border-collapse: collapse; border: 1px solid #000000;">
<tbody>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>Zadanie</strong></td>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>Architekt</strong></td>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>Programista ETL</strong></td>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>Programista BI</strong></td>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>PM klienta</strong></td>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>Dyr. ds. jakości produkcji</strong></td>
<td style="width: 14.2857%; border: 1px solid #000000;"><strong>Admin IT</strong></td>
</tr>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;">Analiza biznesowa</td>
<td style="width: 14.2857%; border: 1px solid #000000;">R</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
</tr>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;">Pobranie danych ze źr&oacute;deł</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">R</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
</tr>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;">Budowa modelu hurtowni danych</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">R</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
</tr>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;">Budowa modelu analitycznego</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
<td style="width: 14.2857%; border: 1px solid #000000;">R</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
</tr>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;">Budowa warstwy wizualnej</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">R</td>
<td style="width: 14.2857%; border: 1px solid #000000;">I</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
</tr>
<tr>
<td style="width: 14.2857%; border: 1px solid #000000;">Testy u klienta i wdrożenie produkcyjne</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
<td style="width: 14.2857%; border: 1px solid #000000;">R</td>
<td style="width: 14.2857%; border: 1px solid #000000;">A</td>
<td style="width: 14.2857%; border: 1px solid #000000;">C</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>`;

export default html;
