import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import RightArrowIcon from "../../../assets/images/icons/right_arrow_icon.svg";
import DoneConfIcon from "../../../assets/images/icons/done_conf_icon.svg";
import IndustrySection from "./sections/IndustrySection";
import DigitalTechnologySection from "./sections/DigitalTechnologySection";
import EducationalGoalsSection from "./sections/EducationalGoalsSection";
import GameModeSection from "./sections/GameModeSection";
import { addGame, getGames } from "../../../services/game.service";
import { AppDispatch, AppState } from "../../../store/store";
import { gamesModes } from "../../../constants/mocks";
import { RoleType } from "../../../enums/RoleType";
import CategoryMockType from "../../../interfaces/game/CategoryMockType";
import { GameModeType } from "../../../enums/GameModeType";
import GameType from "../../../interfaces/game/GameType";
import { setErrorMessage } from "../../../reducers/message.reducer";

const sectionsHeaders = [
  "Branża",
  "Technologia cyfrowa",
  "Kompetencje cyfrowe",
  "Tryb gry",
];

const GameConfiguratorScreen = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isAddingGameRef = useRef<boolean>(false);
  const [sectionId, setSectionId] = useState<number>(0);
  const [industry, setIndustry] = useState<string>("");
  const [categoryKey, setCategoryKey] = useState<string>("");
  const [gameKey, setGameKey] = useState<string>("");
  const [subtaskKey, setSubtaskKey] = useState<string>("");
  const [gameMode, setGameMode] = useState<string>(gamesModes[0].key);
  const [description, setDescription] = useState<string>("");

  const role: RoleType | null = useSelector(
    (state: AppState) => state.userReducer.role
  );

  const userId: string = useSelector(
    (state: AppState) => state.userReducer.userId
  );

  const gamesData: CategoryMockType[] = useSelector(
    (state: AppState) => state.gameReducer.gamesData
  );

  const games: GameType[] | null = useSelector(
    (state: AppState) => state.gameReducer.games
  );

  const isAddingGame: boolean = useSelector(
    (state: AppState) => state.gameReducer.isAddingGame
  );

  useEffect(() => {
    dispatch(getGames(true, true));
  }, []);

  useEffect(() => {
    if (!isAddingGame && isAddingGameRef.current && navigate) {
      navigate("/");
      isAddingGameRef.current = isAddingGame;
    }
    isAddingGameRef.current = isAddingGame;
  }, [isAddingGame, navigate]);

  const renderSection = () => {
    switch (sectionId) {
      case 0:
        return <IndustrySection onChange={setIndustry} industry={industry} />;
      case 1:
        return (
          <DigitalTechnologySection
            onChange={setCategoryKey}
            categoryKey={categoryKey}
            industry={industry}
          />
        );
      case 2:
        return (
          <EducationalGoalsSection
            gameKey={gameKey}
            onGameChange={setGameKey}
            subtaskKey={subtaskKey}
            onSubtaskChange={setSubtaskKey}
            industry={industry}
            categoryKey={categoryKey}
          />
        );
      case 3:
        return (
          <GameModeSection
            gameMode={gameMode}
            setGameMode={setGameMode}
            description={description}
            setDescription={setDescription}
          />
        );
      default:
        return <IndustrySection onChange={setIndustry} industry={industry} />;
    }
  };

  const renderHeaders = () => {
    return sectionsHeaders.map((header, idx) => {
      const active = sectionId === idx;
      const withArrow = idx < sectionsHeaders.length - 1;

      return (
        <div
          className={`sectionHeaderWrapper ${active ? "active" : ""}`}
          key={header}
        >
          <div className="sectionId">
            {sectionId > idx ? (
              <img src={DoneConfIcon} alt="doneIcon" />
            ) : (
              idx + 1
            )}
          </div>
          <span>{header}</span>
          {withArrow && (
            <img src={RightArrowIcon} alt="nextIcon" className="nextIcon" />
          )}
        </div>
      );
    });
  };

  const getIsNextButtonDisabled = () => {
    switch (sectionId) {
      case 0:
        if (industry) return false;
        return true;
      case 1:
        if (categoryKey) return false;
        return true;
      case 2:
        if (subtaskKey) return false;
        return true;
      case 3:
        return false;
      default:
        return false;
    }
  };

  const getBackButtonOnClick = () => {
    switch (sectionId) {
      case 0:
        break;
      case 1:
        setIndustry("");
        setSectionId(sectionId - 1);
        break;
      case 2:
        setCategoryKey("");
        setSectionId(sectionId - 1);
        break;
      case 3:
        setSubtaskKey("");
        setGameKey("");
        setSectionId(sectionId - 1);
        break;
      default:
        setSectionId(sectionId - 1);
        break;
    }
  };

  const createGame = () => {
    const categoryData = gamesData.find((categoryData) =>
      categoryData.games.some((gameData) => gameData.key === gameKey)
    );
    if (userId && gameKey && gameMode && categoryData && subtaskKey && games) {
      const gameData = categoryData.games.find((game) => game.key === gameKey);
      if (gameData) {
        const isThereIdenticalGame = games.find(
          (game) =>
            game.userCreatorId === userId &&
            game.key === gameKey &&
            game.gameSubtasks[0] === subtaskKey &&
            game.gameMode === gameMode
        );
        if (isThereIdenticalGame)
          dispatch(setErrorMessage(t("global.identicalGameAlreadyExists")));
        else
          dispatch(
            addGame({
              userCreatorId: userId,
              categoryKey: categoryData.key,
              gameKey,
              sectorKey: gameData.sectorKey,
              gameMode: gameMode as GameModeType,
              subtasks: [subtaskKey],
              description,
            })
          );
      }
    }
  };

  const handleSkipButton = () => {
    if (sectionId === 0) setIndustry("");
    if (sectionId === 1) setCategoryKey("");
    setSectionId(sectionId + 1);
  };

  return (
    <div className="gameConfiguratorScreen">
      <div className="header">
        {t("gameConfiguratorScreen.gameConfigurator")}
      </div>
      <div className="sectionsWrapper">
        <div className="sectionsHeaders">{renderHeaders()}</div>
        <div className="sectionBody">{renderSection()}</div>

        <div className="buttons">
          {sectionId > 0 && (
            <StyledButton
              type="text"
              label="Wstecz"
              onClick={() => getBackButtonOnClick()}
            />
          )}
          {sectionId < 2 && (
            <StyledButton
              type="text"
              label="Pomiń"
              onClick={handleSkipButton}
            />
          )}
          <StyledButton
            type="contained"
            label={
              sectionId === 3
                ? role === RoleType.USER
                  ? "Generuj grę"
                  : "Dodaj link do gry"
                : "Dalej"
            }
            disabled={getIsNextButtonDisabled()}
            onClick={() =>
              sectionId === 3 ? createGame() : setSectionId(sectionId + 1)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GameConfiguratorScreen;
