const html = `<table border="1" cellpadding="10">
<tbody>
<tr>
<td colspan="2">
<h2><strong>Zadania członk&oacute;w zespołu realizującego projekt z obszaru Big Data</strong></h2>
</td>
</tr>
<tr>
<td>
<p><strong>STANOWISKO&nbsp;</strong></p>
</td>
<td>
<p><strong>ZADANIA</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Programista Spark</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Analiza potrzeb analityk&oacute;w danych i Data Scientist&oacute;w w zakresie potrzeb i planowanego sposobu korzystania z narzędzia.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Wypracowanie architektury rozwiązania, określającej jakie usługi, aplikacje, technologie będą wykorzystywane przez narzędzie (Python/Spark, scheduler AirFlow).</span></p>
<br />
<p><span style="font-weight: 400;">Przygotowanie infrastruktury IT (skonfigurowanie i uruchomienie usług chmurowych w tym zasad monitorowania, security).</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Przygotowanie skrypt&oacute;w (Python) automatyzujących tworzenie odpowiednich katalog&oacute;w (w repozytorium Git), wykorzystywanych przez narzędzie.</span></p>
<br />
<p><span style="font-weight: 400;">Przygotowanie skryptu (Sparku) automatyzującego proces przetwarzania plik&oacute;w zawierających zapytania SQL, zgodnie z harmonogramem wskazanym przez analityka/Data Scientista.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skrypt&oacute;w testujących poprawność działania narzędzia.</span></p>
<br />
<p><span style="font-weight: 400;">Analiza potrzeb analityk&oacute;w danych i Data Scientist&oacute;w w zakresie potrzeb i planowanego sposobu korzystania z narzędzia.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Wypracowanie architektury rozwiązania, określającej jakie usługi, aplikacje, technologie będą wykorzystywane przez narzędzie (excel, scheduler AirFlow).</span></p>
<br />
<p><span style="font-weight: 400;">Przygotowanie skrypt&oacute;w (Spark) rozszerzających zakres funkcjonalności schedulera o kolejne działania, w tym przetwarzanie danych z pliku JSON zgodnie z wytycznymi.</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skrypt&oacute;w testujących poprawność działania narzędzia.</span></p>
<br />
<p><span style="font-weight: 400;">Code review.&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><strong>Programista ETL</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Przygotowanie infrastruktury IT (skonfigurowanie i uruchomienie usług chmurowych i katalog&oacute;w).</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie w języku Python (w wykorzystaniem frameworka Spark) kodu umożliwiającego pobranie &bdquo;surowych&rdquo; danych ze źr&oacute;deł. W przypadku systemu CRM pobranie następuje z wykorzystaniem API.</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skrypt&oacute;w testujących poprawność ładowania danych ze źr&oacute;deł do warstwy ROW</span></p>
<br />
<p><span style="font-weight: 400;">Konfiguracja usług chmurowych, w tym storage.</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skryptu umożliwiającego oczyszczenie, uporządkowanie i ewentualne wzbogacenie (np. o unikatowe klucze) danych z warstwy ROW. Umożliwi to m.in. łączenie danych z r&oacute;żnych źr&oacute;deł na potrzeby analiz.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie w języku Python (w wykorzystaniem frameworka Spark) kodu umożliwiającego pobranie z warstwy ROW do warstwy CLEANSED.</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skrypt&oacute;w testujących poprawność przetwarzania i ładowania danych z warstwy ROW do CLEANSED.</span></p>
<br />
<p><span style="font-weight: 400;">Zaprojektowanie i wdrożenie modelu danych (struktura tabel, kolumn) i tzw. data marts, zgodnie z wymaganiami biznesowymi, celem przygotowania danych pod raportowanie.</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie kodu umożliwiającego pobranie ze warstwy CLEANSED do warstwy DWH.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skrypt&oacute;w testujących poprawność ładowania danych z warstwy CLEANSED do DWH.</span></p>
<br />
<p><span style="font-weight: 400;">Napisanie skrypt&oacute;w testujących poprawność ładowania danych w ramach całego procesu przepływu danych, tj.&nbsp; warstwy ROW do DWH.</span></p>
<br />
<p><span style="font-weight: 400;">Code review.</span></p>
</td>
</tr>
</tbody>
</table>`;

export default html;
